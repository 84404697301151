<div class="title">
  <span>{{ title }}</span>
</div>
<div>
  <span>Sample:</span>
  {{ model.sampleName }}
</div>
<div>
  <span>User:</span>
  {{ model.user }}
</div>
<div>
  <span>Description:</span>
  {{ model.requestorMessage }}
</div>
<div class="createdAt">
  <span>Created:</span>
  {{ model.createdAt | date: 'M/d/yyyy hh:mm:ss a' }}
</div>
<div class="actions">
  <button
    class="btn btn-primary btn-small"
    (click)="approve()"
    id="sample-access-request-approve-btn"
  >
    Approve
  </button>
  <button
    class="btn btn-secondary btn-small"
    (click)="reject()"
    id="location-access-request-reject-btn"
  >
    Reject
  </button>
</div>
