import {
  BehaviorSubject,
  catchError,
  finalize,
  MonoTypeOperatorFunction,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CollectionViewer, DataSource } from '@angular/cdk/collections';

import { LookupWellsService } from '../lookup-wells.service';
import { WellSearch, WellSearchResult } from '../models/well-search.model';
import { WellSearchRequest } from '../models/well-search-request.model';

export class WellsDataSource implements DataSource<WellSearch> {
  private wellsSubject = new BehaviorSubject<WellSearch[]>([]);
  private loadingCounterSubject = new BehaviorSubject<number>(0);
  private countSubject = new BehaviorSubject<number>(0);
  private loadWellsStartedSubject = new Subject<void>();

  loadingCounter$ = this.loadingCounterSubject.asObservable();
  count$ = this.countSubject.asObservable();
  loadWellsStarted$ = this.loadWellsStartedSubject.asObservable();

  get data(): WellSearch[] {
    return this.wellsSubject.getValue();
  }

  constructor(
    private lookupWellsService: LookupWellsService,
    private takeUntilDestroyed: <T>() => MonoTypeOperatorFunction<T>,
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<WellSearch[]> {
    return this.wellsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.wellsSubject.complete();
    this.loadingCounterSubject.complete();
    this.countSubject.complete();
    this.loadWellsStartedSubject.complete();
  }

  loadWells(filterData: WellSearchRequest): void {
    this.addLoadingRequest();
    this.loadWellsStartedSubject.next();

    this.lookupWellsService
      .search(filterData)
      .pipe(
        catchError(() =>
          of({ data: [], pageIndex: 0, pageSize: 10, count: 0 } as WellSearchResult),
        ),
        finalize(() => this.removeLoadingRequest()),
        takeUntil(this.loadWellsStarted$),
        this.takeUntilDestroyed(),
      )
      .subscribe((wells) => {
        this.countSubject.next(wells.count);
        this.wellsSubject.next(wells.data);
      });
  }

  private addLoadingRequest(): void {
    this.loadingCounterSubject.next(this.loadingCounterSubject.getValue() + 1);
  }

  private removeLoadingRequest(): void {
    this.loadingCounterSubject.next(this.loadingCounterSubject.getValue() - 1);
  }
}
