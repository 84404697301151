export class ThinSectionInformation {
  sampleNo?: string;
  sectionTypeId: string;
  depthTop: number;
  phyDimX: number;
  phyDimY: number;
  phyDimZ: number;
  phyDimLengthUnitId: string;
  depthBottom: number;
  stainingId: string;
  sectionNumber: number;
  orientationId: string;
  sectionOriginId: string;
  depthUnitId: string;

  constructor(thinSectionInformation: ThinSectionInformation) {
    this.sectionTypeId = thinSectionInformation.sectionTypeId;
    this.depthTop = thinSectionInformation.depthTop;
    this.phyDimX = thinSectionInformation.phyDimX;
    this.phyDimY = thinSectionInformation.phyDimY;
    this.phyDimZ = thinSectionInformation.phyDimZ;
    this.phyDimLengthUnitId = thinSectionInformation.phyDimLengthUnitId;
    this.depthBottom = thinSectionInformation.depthBottom;
    this.stainingId = thinSectionInformation.stainingId;
    this.sectionNumber = thinSectionInformation.sectionNumber;
    this.orientationId = thinSectionInformation.orientationId;
    this.sectionOriginId = thinSectionInformation.sectionOriginId;
    this.depthUnitId = thinSectionInformation.depthUnitId;
  }
}
