import { integerOnlyError } from '../../../features/work-order/form/integer-only.validator';
import { numericRangeError } from '../../../features/work-order/form/numeric-range.validator';
import { textOnlyError } from '../../../features/work-order/form/text-only.validator';

export type ErrorMessageFunction = (error: any, fieldName: string) => string;
export type ErrorMessageConfig = Record<string, ErrorMessageFunction>;

export const REQUIRED_ERROR_MESSAGE: ErrorMessageConfig = {
  required: (error: any, fieldName: string) => `${fieldName} should not be empty`,
};

export const MAX_LENGTH_ERROR_MESSAGE: ErrorMessageConfig = {
  maxlength: (error: any, fieldName: string) =>
    `Max allowed length of ${fieldName}: ${error.requiredLength}. Actual length: ${error.actualLength}`,
};

export const EMAIL_ERROR_MESSAGE: ErrorMessageConfig = {
  email: (error: any, fieldName: string) => `${fieldName} should contain valid email address`,
};
export const PATTERN_ERROR_MESSAGE: ErrorMessageConfig = {
  pattern: (error: any, fieldName: string) =>
    `${fieldName} contains invalid characters or signature`,
};

export const MIN_OR_ZERO_ERROR_MESSAGE: ErrorMessageConfig = {
  minOrZero: (error: any, fieldName: string) =>
    `${fieldName} must be either 0, or greater than or equal to ${error.min}`,
};

export const MIN_ERROR_MESSAGE: ErrorMessageConfig = {
  min: (error: any, fieldName: string) =>
    `Min allowed value of ${fieldName}: ${error.min}. Actual value: ${error.actual}`,
};

export const MAX_ERROR_MESSAGE: ErrorMessageConfig = {
  max: (error: any, fieldName: string) =>
    `Max allowed value of ${fieldName}: ${error.max}. Actual value: ${error.actual}`,
};

export const TEXT_ONLY_ERROR_MESSAGE: ErrorMessageConfig = {
  [textOnlyError]: () => 'Numeric values are not allowed',
};

export const INTEGER_ONLY_ERROR_MESSAGE: ErrorMessageConfig = {
  [integerOnlyError]: (error: any, fieldName: string) =>
    `Only integer values are allowed for ${fieldName} property`,
};

export const NUMERIC_RANGE_ERROR_MESSAGE: ErrorMessageConfig = {
  [numericRangeError]: (error: any, fieldName: string) =>
    `${fieldName} must be between ${error.min} and ${error.max}`,
};
