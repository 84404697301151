import { DatePipe } from '@angular/common';

export function formatDate(date: Date | string, startDate?: boolean, endDate?: boolean): string {
  const datePipe = new DatePipe('en-Us');
  const newDate = new Date(date || new Date());
  let result = '';

  switch (true) {
    case startDate:
      newDate.setHours(0, 0, 59, 0);
      result = datePipe.transform(newDate, 'yyyy-MM-ddTHH:mm:ss.SSSZ') ?? '';
      break;
    case endDate:
      newDate.setHours(23, 59, 59, 0);
      result = datePipe.transform(newDate, 'yyyy-MM-ddTHH:mm:ss.SSSZ') ?? '';
      break;
    default:
      result = '';
      break;
  }
  return result;
}
