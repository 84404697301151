export class CuttingsIntervalInformation {
  depthTop: number;
  depthBottom: number;
  depthUnitId: string;
  preservationId: string;

  constructor(cuttingsIntervalInformation: CuttingsIntervalInformation) {
    this.depthTop = cuttingsIntervalInformation.depthTop;
    this.depthBottom = cuttingsIntervalInformation.depthBottom;
    this.depthUnitId = cuttingsIntervalInformation.depthUnitId;
    this.preservationId = cuttingsIntervalInformation.preservationId;
  }
}
