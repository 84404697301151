export enum CoreAnalysisWizardEnum {
  ID = 'id',
  NAME = 'name',
  PARENT_ID = 'parentId',
  TYPE = 'type',
  WELLNAME = 'wellName',
  DEPTH = 'depth',
  EXPECTED_DATE = 'expectedDate',
  PROGRESS = 'progress',
  SIZE = 'size',
  RESOLUTION = 'resolution',
  NUMBER_OF_GPU = 'numberOfGPU',
  NUMBER_OF_CPU = 'numberOfCPU',
  TITLE = 'title',
  CLEANING_METHOD = 'cleaningMethod',
  EFFLUENT_COLOR = 'effluentColor',
  NOTE = 'note',
  BRINE_CONDITION = 'brineCondition',
  FRF = 'frf',
  M = 'm',
  KW = 'kw',
  PCRI_INDEX = 'pcriIndex',
  PCRI = 'pcRI',
  SATURATION_POINT = 'saturationPoint',
  DESCRIPTION = 'description',
  PROPONENT_EMAIL = 'proponentEmail',
  PROPONENT_NAME = 'proponentName',
  START_DATE = 'startDate',
  EXPECT_DATE = 'expectDate',
  COMPLETION_DATE = 'completionDate',
  COMPLETED = 'completed',
  EXPERIMENT_FIELDS = 'experimentFields',
  EXPERIMENT_TYPE_ID = 'experimentTypeId',
  SAMPLE_ID = 'sampleId',
  PARENT_WORK_ORDER_ID = 'parentWorkOrderId',
}
