import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { NgControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import {
  MatLegacySelectChange as MatSelectChange,
  MatLegacySelectModule as MatSelectModule,
} from '@angular/material/legacy-select';

import { IdName } from '@core/models/id-name.model';
import { Disableable } from '@core/utils/mixins/disableable.mixin';

import { SAMPLE_OPTIONS_COMPARE_FN } from '../../features/sample/consts/sample-options-compare-fn.const';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent extends Disableable(Object) implements OnInit {
  @Input() label!: string;
  @Input() selectOptions = new Array<IdName>();
  @Input() multiSelect: boolean = false;
  @Input() requiredField: boolean = false;
  @Input() clearOption: boolean = false;
  @Input() returnIdValue: boolean = true;

  @Output() selectionChange = new EventEmitter<MatSelectChange>();
  readonly optionsCompareFn = SAMPLE_OPTIONS_COMPARE_FN;
  control = new UntypedFormControl();
  value: any;

  onTouched!: (value: any) => void;
  onChange!: (value: any) => void;

  constructor(private ngControl: NgControl, private cd: ChangeDetectorRef) {
    super();
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.setUpNgControl();
  }

  onOptionSelection(): void {
    this.selectionChange.emit();
  }

  private setUpNgControl(): void {
    this.ngControl.control!.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
    this.ngControl.control!.reset = (value, options) => {
      this.control.reset(value, options);
      this.cd.markForCheck();
    };
  }

  writeValue(val: string): void {
    this.control.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

@NgModule({
  declarations: [MultiSelectComponent],
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, ReactiveFormsModule],
  exports: [MultiSelectComponent],
})
export class MultiSelectModule {}
