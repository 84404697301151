import { AbstractControl } from '@angular/forms';

import { GenericConstructor } from '@core/utils/mixins/generic-constructor.type';

export interface WithAbilityToDisableState {
  setDisabledState(isDisabled: boolean): void;
}

export function Disableable<TBase extends GenericConstructor>(
  Base: TBase,
): TBase & GenericConstructor<WithAbilityToDisableState> {
  abstract class DisableableClass extends Base implements WithAbilityToDisableState {
    abstract control: AbstractControl;

    setDisabledState(isDisabled: boolean): void {
      if (isDisabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  return DisableableClass;
}
