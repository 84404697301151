export enum SampleCheckInFormField {
  NAME = 'name',
  SAMPLE_LOCATION = 'sampleLocation',
  PARENT_SAMPLE = 'parentSample',
  RESTRICTION = 'restriction',
  BARCODE = 'barcode',
  QRCODE = 'qrCode',
  DESCRIPTION = 'description',
  OWNER = 'owner',
  CUSTODIAN = 'custodian',
}

export enum PlugSampleInformationFormField {
  DEPTH = 'depth',
  ORIENTATION = 'orientationId',
  PRESERVATION_TYPE = 'preservationType',
}

export enum CoreSampleInformationFormField {
  DEPTH_TOP = 'depthTop',
  DEPTH_BOTTOM = 'depthBottom',
  PRESERVATION_TYPE = 'preservationType',
}

export enum CuttingsSampleInformationFormField {
  DEPTH_TOP = 'depthTop',
  DEPTH_BOTTOM = 'depthBottom',
  PRESERVATION_TYPE = 'preservationType',
}

export enum FluidSampleInformationFormField {
  DEPTH = 'depth',
}

export enum UncategorizedSampleInformationFormField {
  DEPTH = 'depth',
}

export enum ThinSectionSampleInformationFormField {
  SECTION_TYPE = 'sectionTypeId',
  DEPTH_TOP = 'depthTop',
  SECTION_DIMENSION_X = 'phyDimX',
  SECTION_DIMENSION_Y = 'phyDimY',
  SECTION_DIMENSION_Z = 'phyDimZ',
  SECTION_DIMENSION_UNIT_ID = 'phyDimLengthUnitId',
  DEPTH_BOTTOM = 'depthBottom',
  STAINING = 'stainingId',
  SECTION_NUMBER = 'sectionNumber',
  ORIENTATION = 'orientationId',
  SECTION_ORIGIN = 'sectionOriginId',
}

export enum WellInformationFormField {
  WELL_ID = 'wellId',
  WELL_LOCATION = 'wellLocation',
  FIELD = 'field',
  RESERVOIR = 'reservoir',
}
