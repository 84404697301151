import { filter, map } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { extractDepthValueFromGetById } from '@core/utils/common/extract-depth-value-from-get-by-id.util.';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { SampleSearch } from 'src/app/features/data-access/lookup-sample/models/sample-search.model';

import { SampleGetById } from '../../features/sample/models/sample-get-by-id.model';
import { SampleService } from '../../services/api/sample.service';
import { DisabledElementModule } from '../directives/disabled-element.directive';
import { StopPropagationOnClickModule } from '../directives/stop-propagation-on-click.directive';
import { SampleIdDisplayName } from './models/sample-display-name.interface';
import { SampleDisplayNameModule, SampleDisplayNamePipe } from './pipes/sample-display-name.pipe';
import { SampleTableSelectorPopupComponent } from './sample-table-selector-popup/sample-table-selector-popup.component';

@Component({
  selector: 'app-sample-table-selector-popup-field',
  templateUrl: './sample-table-selector-popup-field.component.html',
  styleUrls: ['./sample-table-selector-popup-field.component.scss'],
  providers: [SampleDisplayNamePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleTableSelectorPopupFieldComponent
  extends Destroyable(Object)
  implements ControlValueAccessor, OnInit
{
  @Input() label!: string;
  @Input() getErrorMessage: () => string = () => '';
  @Input() required = false;
  @Input() allowedSampleTypes?: string[];
  @Output() changesSelectedSample = new EventEmitter<SampleIdDisplayName>();
  control = new UntypedFormControl();
  selectedSample: SampleIdDisplayName | null = null;

  sampleNameDisplayFn = (): string => this.sampleDisplayNamePipe.transform(this.selectedSample);
  onTouched!: (value: any) => void;
  onChange!: (value: any) => void;

  constructor(
    private ngControl: NgControl,
    private sampleDisplayNamePipe: SampleDisplayNamePipe,
    private matDialog: MatDialog,
    private cd: ChangeDetectorRef,
    private sampleService: SampleService,
  ) {
    super();
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.setValidators();
    this.setUpNgControl();
  }

  clearField(): void {
    this.selectedSample = null;
    this.control.setValue(null);
  }

  openSampleTableSelectorPopup(): void {
    this.matDialog
      .open<SampleTableSelectorPopupComponent, any, SampleSearch | null>(
        SampleTableSelectorPopupComponent,
        {
          minWidth: 1000,
          minHeight: 500,
          height: '80%',
          autoFocus: false,
          restoreFocus: false,
          data: {
            allowedSampleTypes: this.allowedSampleTypes,
          },
        },
      )
      .afterClosed()
      .pipe(
        filter((selectedSample) => !!selectedSample),
        this.takeUntilDestroyed(),
      )
      .subscribe((selectedSample) => {
        this.selectedSample = selectedSample!;
        this.control.setValue(selectedSample!.id);
        this.changesSelectedSample.emit(this.selectedSample);
      });
  }

  writeValue(val: string): void {
    this.defineSelectedSample(val);
    this.control.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private setValidators(): void {
    const validators = [
      this.ngControl.control?.validator
        ? this.ngControl.control?.validator
        : Validators.nullValidator,
    ];

    if (this.required) {
      validators.push(Validators.required);
      this.ngControl.control?.setValidators(validators);
      this.ngControl.control?.updateValueAndValidity({ emitEvent: false });
    }

    this.control.setValidators(validators);
    this.control.updateValueAndValidity({ emitEvent: false });
  }

  private setUpNgControl(): void {
    this.ngControl.control!.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
    this.ngControl.control!.reset = (value, options) => {
      this.control.reset(value, options);
      this.selectedSample = null;
    };
    this.ngControl.control!.disable = (options) => {
      this.control.disable(options);
      this.cd.markForCheck();
    };
    this.ngControl.control!.enable = (options) => {
      this.control.enable(options);
      this.cd.markForCheck();
    };
  }

  private defineSelectedSample(val: string | null) {
    if (!val) {
      this.selectedSample = null;
      return;
    }

    this.sampleService
      .getById(val)
      .pipe(
        map((sampleCheckIn) => this.mapToSampleIdDisplayName(val, sampleCheckIn)),
        this.takeUntilDestroyed(),
      )
      .subscribe((sample) => {
        this.selectedSample = sample;
        this.control.setValue(this.control.value, { emitEvent: false });
        this.cd.markForCheck();
      });
  }

  private mapToSampleIdDisplayName(id: string, sample: SampleGetById): SampleIdDisplayName {
    return {
      id,
      type: sample.type,
      depth: extractDepthValueFromGetById(sample),
      sampleName: sample.name,
      wellName: sample.wellInformation?.name ?? '',
    };
  }
}

@NgModule({
  declarations: [SampleTableSelectorPopupFieldComponent],
  exports: [SampleTableSelectorPopupFieldComponent],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    SampleDisplayNameModule,
    CommonModule,
    DisabledElementModule,
    StopPropagationOnClickModule,
  ],
})
export class SampleTableSelectorPopupFieldModule {}
