<button
  mat-button
  [matMenuTriggerFor]="sampleCheckInMenu"
  class="btn create"
  id="sample-check-in-add-btn"
>
  <mat-icon>add</mat-icon>
  <span>{{ label }}</span>
</button>

<mat-menu #sampleCheckInMenu="matMenu" yPosition="above">
  <button
    *ngFor="let item of menuItems"
    mat-menu-item
    (click)="menuItemClick.emit(item)"
    id="sample-check-in-btn"
  >
    <span class="menu-item">{{ item | capitalizeString }}</span>
  </button>
</mat-menu>
