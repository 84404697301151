import { debounceTime, first, merge, tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import {
  MatLegacyPaginator as MatPaginator,
  MatLegacyPaginatorModule as MatPaginatorModule,
} from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { DEFAULT_DEBOUNCE_TIME } from '@core/constants/consts';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { SearchInputModule } from 'src/app/common/search-input/search-input.component';

import { LookupWellsService } from './lookup-wells.service';
import { WellSearch } from './models/well-search.model';
import { WellSearchRequest } from './models/well-search-request.model';
import { WellsDataSource } from './utils/wells-data-source.class';

@Component({
  selector: 'app-lookup-well',
  templateUrl: './lookup-well.component.html',
  styleUrls: ['./lookup-well.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookupWellComponent extends Destroyable(Object) implements AfterViewInit, OnInit {
  @Input() isSelector = false;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  selectedWell: WellSearch | null = null;
  searchPlaceholder: 'Well name' | 'Location' | 'Field' | 'Reservoir' = 'Well name';

  dataSource!: WellsDataSource;
  filterData: WellSearchRequest = {
    filterType: 'byName',
    searchString: '',
    sortColumnName: 'noSort',
    sortDirection: '',
    page: 1,
    pageSize: 10,
  };

  searchByFields = [
    { label: 'Name', value: 'byName' },
    { label: 'Location', value: 'byLocationName' },
    { label: 'Field', value: 'byField' },
    { label: 'Reservoir', value: 'byReservoir' },
  ];

  displayedColumns: Array<keyof WellSearch> = ['name', 'field', 'location', 'reservoir'];

  private get routerAppPrefix(): string {
    return `/${/(?<=\/)(.*?)(?=\/)/.exec(this.router.url)?.[0]}`;
  }

  constructor(
    private lookupWellsService: LookupWellsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataSource = new WellsDataSource(this.lookupWellsService, this.takeUntilDestroyed);

    if (!this.isSelector) {
      this.handleUrlParams();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.applySearch());
    this.setupSortAndPaginationChangeListeners();
  }

  onSearch(term: string): void {
    this.filterData.searchString = term.trim();
    this.applySearch(true);
  }

  onByFieldChanged(index: string): void {
    this.setPlaceholder(index);
    this.applySearch(true);
  }

  onWellRowClick(well: WellSearch): void {
    if (this.isSelector) {
      this.selectedWell = well;
      return;
    }

    this.router.navigate([
      `${this.routerAppPrefix}/data-access/well/well-information`,
      { data: JSON.stringify(well) },
    ]);
  }

  onCreateWellClick(): void {
    this.router.navigate([`${this.routerAppPrefix}/data-access/well/well-information`]);
  }

  private setupSortAndPaginationChangeListeners(): void {
    const sortChange$ = this.sort.sortChange.pipe(
      tap(() => {
        this.paginator.pageIndex = 0;
        this.filterData = {
          ...this.filterData,
          page: this.paginator.pageIndex + 1,
        };
      }),
    );
    const pageChange$ = this.paginator.page.pipe(debounceTime(DEFAULT_DEBOUNCE_TIME));

    merge(sortChange$, pageChange$)
      .pipe(
        tap(() => {
          this.applySearch();
        }),
      )
      .subscribe();
  }

  private handleUrlParams(): void {
    this.route.queryParamMap.pipe(first(), this.takeUntilDestroyed()).subscribe((data) => {
      const term = data.get('searchString');
      const params = {
        filterType: data.get('filterType'),
        sortColumnName: data.get('sortColumnName'),
        sortDirection: data.get('sortDirection'),
        page: Number(data.get('page')),
        pageSize: Number(data.get('pageSize')),
      };

      if (params.page && params.pageSize) {
        this.filterData = {
          ...this.filterData,
          page: params.page,
          pageSize: params.pageSize,
        };
      }

      if (term) {
        this.filterData = {
          ...this.filterData,
          searchString: term,
        };
      }

      if (params.filterType) {
        this.filterData = {
          ...this.filterData,
          filterType: params.filterType,
        };
      }

      if (params.sortColumnName && params.sortDirection) {
        this.filterData = {
          ...this.filterData,
          sortDirection: params.sortDirection as SortDirection,
          sortColumnName: params.sortColumnName,
        };
      }
    });
  }

  private setPlaceholder(index: string): void {
    switch (index) {
      case 'byLocationName':
        this.searchPlaceholder = 'Location';
        break;
      case 'byField':
        this.searchPlaceholder = 'Field';
        break;
      case 'byReservoir':
        this.searchPlaceholder = 'Reservoir';
        break;
      default:
        this.searchPlaceholder = 'Well name';
        break;
    }
  }

  private modifyUrlForNavigatingBack(): void {
    if (this.isSelector) {
      return;
    }

    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams['searchString'] = this.filterData.searchString;
    urlTree.queryParams['filterType'] = this.filterData.filterType;
    urlTree.queryParams['sortColumnName'] = this.filterData.sortColumnName;
    urlTree.queryParams['sortDirection'] = this.filterData.sortDirection;
    urlTree.queryParams['page'] = this.filterData.page;
    urlTree.queryParams['pageSize'] = this.filterData.pageSize;

    this.router.navigateByUrl(urlTree);
  }

  private applySearch(removeSortingAndPagination = false): void {
    this.filterData = {
      ...this.filterData,
      sortColumnName:
        removeSortingAndPagination || this.sort.direction === '' ? 'noSort' : this.sort.active,
      sortDirection: removeSortingAndPagination ? '' : this.sort.direction,
      pageSize: this.paginator.pageSize,
      page: removeSortingAndPagination ? 1 : this.paginator.pageIndex + 1,
    };

    const filterData: WellSearchRequest = {
      ...this.filterData,
      sortDirection: this.filterData.sortDirection || 'asc',
    };

    if (!this.isSelector) {
      this.modifyUrlForNavigatingBack();
    }

    this.dataSource.loadWells(filterData);
  }
}

@NgModule({
  imports: [
    CommonModule,
    SearchInputModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatCardModule,
    MatLegacyProgressSpinnerModule,
    MatLegacyTooltipModule,
  ],
  declarations: [LookupWellComponent],
  exports: [LookupWellComponent],
  providers: [LookupWellsService],
})
export class LookupWellModule {}
