import OktaAuth from '@okta/okta-auth-js';

import { environment } from '../../../environments/environment';

const appConfigJson = require('../../../assets/config/app.config.json');

export const oktaAuth = new OktaAuth(environment.oktaConfig);
export const oktaStubAuth = new OktaAuth({
  issuer: 'https://your-okta-issuer-url',
  clientId: 'your-okta-client-id',
  redirectUri: 'https://your-app-callback-url',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
});

export const API_SERVER_URL = `${appConfigJson.apiServerUrl}`;
export const BASE_API_URL = `${API_SERVER_URL}/corememory-api/api`;

export const API_SERVER_ERROR_MESSAGE = 'Internal server error';
export const API_AUTHENTICATION_ERROR_MESSAGE = 'Wrong authentication data';
export const USER_NOT_CREATED_ERROR_MESSAGE = 'User is not created. Please, ask admin to create it';
export const API_UNKNOWN_ERROR_MESSAGE = 'Something went wrong. Please try again later';

export const X_USER_NOT_CREATED_HEADER_NAME = 'X-User-Not-Created';
export const TOKEN_EXPIRED_HEADER_NAME = 'Token-Expired';
export const PASS_ERROR_HEADER = 'PassError';
export const SKIP_TOKEN = 'skip_token';
export const CORE_MEMORY_API = '/corememory-api/api';
export const FILE_SERVICE_API = '/fileservice/api';
export const SIMULATION_API = '/simulation-api';
export const NOTIFICATION_API = '/notification-api';
