<mat-form-field class="sample-form-field" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [formControl]="control"
    autocomplete="off"
    readonly
    [matAutocomplete]="auto"
    (focus)="openSampleTableSelectorPopup()"
    id="sample-table-selector-input"
  />
  <mat-icon
    data-ref="close-icon"
    matSuffix
    appStopPropagationOnClick
    appDisabledElement
    [disabled]="control.disabled"
    (click)="clearField()"
    id="sample-table-clear-btn"
    >close</mat-icon
  >
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="sampleNameDisplayFn">
    <mat-option *ngFor="let sample of [selectedSample]" [value]="sample?.id">
      {{ sample | sampleDisplayName }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control.errors">{{ getErrorMessage() }}</mat-error>
</mat-form-field>
