<app-sample-header
  *ngIf="!embedded && !isReadOnly"
  [title]="(isEdit ? 'Edit ' : 'Create ') + ' Composite Sample'"
  [showBackButton]="isEdit"
  (backClicked)="navigateToSampleLookup()"
></app-sample-header>

<form
  *ngIf="sampleFormGroup"
  [formGroup]="sampleFormGroup"
  autocomplete="off"
  (keydown.enter)="$event.preventDefault()"
>
  <mat-accordion multi>
    <mat-expansion-panel *ngIf="generalFormGroup" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>General Information</mat-panel-title>
      </mat-expansion-panel-header>
      <app-sample-general-form
        [formGroup]="generalFormGroup"
        [picture]="picture"
        [sampleLocations]="sampleLocations"
        [isReadOnly]="isReadOnly"
      ></app-sample-general-form>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="compositeFormArray">
      <mat-expansion-panel-header>
        <mat-panel-title>Composite Sample Information</mat-panel-title>
      </mat-expansion-panel-header>
      <app-sample-composite-form
        [isSampleDetails]="isEdit"
        [isReadOnly]="isReadOnly"
        [formArray]="compositeFormArray"
      ></app-sample-composite-form>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="isEdit || isReadOnly">
      <mat-expansion-panel-header>
        <mat-panel-title>Sample Access</mat-panel-title>
      </mat-expansion-panel-header>
      <app-sample-access-table
        [isReadOnly]="isReadOnly"
        [sampleAccess]="sampleAccess"
        [isOwner]="isOwner"
      >
      </app-sample-access-table>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="isEdit || isReadOnly">
      <mat-expansion-panel-header>
        <mat-panel-title>Attached files</mat-panel-title>
      </mat-expansion-panel-header>
      <app-attached-files-block [isReadOnly]="isReadOnly" [sampleId]="sampleId || ''">
      </app-attached-files-block>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="(isEdit || isReadOnly) && existingWorkOrders.length">
      <mat-expansion-panel-header>
        <mat-panel-title>Existing work orders</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <app-existing-work-orders
          [existingWorkOrderList]="existingWorkOrders"
          [vendorNameList]="vendorNames"
          [experimentTypeList]="experimentTypes"
          [sampleId]="sampleId!"
          [isEditable]="!isReadOnly"
          (save)="save($event.throttleClick, $event.component)"
        ></app-existing-work-orders>
      </ng-template>
    </mat-expansion-panel>

    <ng-container *ngIf="(isEdit || isReadOnly) && experimentDataList.length">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Raw data</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-accordion>
          <ng-template matExpansionPanelContent>
            <mat-expansion-panel class="raw-data" *ngFor="let expData of experimentDataList">
              <mat-expansion-panel-header>
                <mat-panel-title>{{ expData.projectNumber }}</mat-panel-title>
                <mat-panel-description> {{ expData.experimentName }}</mat-panel-description>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <ng-container *ngFor="let table of expData.tables">
                  <h1 class="experiment-data-table-title">{{ table.title }}</h1>
                  <app-experiment-data-render-table
                    [table]="table"
                  ></app-experiment-data-render-table>
                </ng-container>
              </ng-template>
            </mat-expansion-panel>
          </ng-template>
        </mat-accordion>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</form>

<app-sample-actions
  *ngIf="!embedded && !isReadOnly"
  #sampleActionsComponent
  [showPrintBarcodeMenu]="!!sampleFormGroup"
  [barcode]="sampleFormGroup && barcodeFormControl.value"
  [qrCode]="sampleFormGroup && qrcodeFormControl.value"
  [saveButtonLabel]="sampleId ? 'Update' : 'Create'"
  (cancelClicked)="onCancelClicked()"
  (saveClicked)="submitForm(sampleActionsComponent)"
></app-sample-actions>
