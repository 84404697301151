<mat-dialog-content>
  <app-digital-micp-simulation
    [physicalSampleId]="data.sampleId"
    [hideSampleSearch]="true"
    [isPtsd]="data.isPtsd"
    #micpSimulationComponent
  ></app-digital-micp-simulation>
</mat-dialog-content>

<mat-dialog-actions *ngIf="micpSimulationComponent.tableData.length">
  <button class="btn btn-secondary btn-big" [mat-dialog-close]="null">Cancel</button>
  <button class="btn btn-primary btn-big" (click)="save(micpSimulationComponent)">Submit</button>
</mat-dialog-actions>
