export enum WorkOrderVendorInformationFormField {
  VENDOR_NAME = 'vendorName',
  VENDOR_CONTACT_EMAIL = 'vendorContactEmail',
  VENDOR_CONTACT_NAME = 'vendorContactName',
  VENDOR_CONTACT_INFO = 'vendorContactInfo',
}

export const WORK_ORDER_VENDOR_INFORMATION_NAME_TO_DISPLAY: Record<
  WorkOrderVendorInformationFormField,
  string
> = {
  [WorkOrderVendorInformationFormField.VENDOR_NAME]: 'Vendor name',
  [WorkOrderVendorInformationFormField.VENDOR_CONTACT_INFO]: 'Vendor contact info',
  [WorkOrderVendorInformationFormField.VENDOR_CONTACT_NAME]: 'Vendor contact name',
  [WorkOrderVendorInformationFormField.VENDOR_CONTACT_EMAIL]: 'Vendor contact email',
};
