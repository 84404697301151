import { distinctUntilChanged } from 'rxjs';

import { Directive, Input, NgModule, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { UnitInputField } from '../unit-input/enums/unit-input-field.enum';
import { UnitInputComponent } from '../unit-input/unit-input.component';

@Directive({
  selector: '[appSynchronizeDepthUnits]',
})
export class SynchronizeDepthUnitsDirective extends Destroyable(Object) implements OnInit {
  @Input() config!: {
    primaryControlName: string;
    secondaryComponents: UnitInputComponent[];
  };

  constructor(private formGroupDirective: FormGroupDirective) {
    super();
  }

  ngOnInit(): void {
    const { primaryControlName, secondaryComponents } = this.config;

    this.formGroupDirective.form
      .get(primaryControlName)!
      .valueChanges.pipe(
        distinctUntilChanged(
          (prev, curr) => prev?.[UnitInputField.UNIT_ID] === curr?.[UnitInputField.UNIT_ID],
        ),
        this.takeUntilDestroyed(),
      )
      .subscribe((val) => {
        secondaryComponents.forEach((component) => {
          component.control.setValue({
            ...component.control.value,
            [UnitInputField.UNIT_ID]: val?.[UnitInputField.UNIT_ID] || null,
          });
          component.detectChanges();
        });
      });
  }
}

@NgModule({
  declarations: [SynchronizeDepthUnitsDirective],
  exports: [SynchronizeDepthUnitsDirective],
})
export class SynchronizeDepthUnitsModule {}
