export enum OrganizationFormField {
  APP_NAME = 'appName',
  APP_SLOGAN = 'appSlogan',
  APP_DESCRIPTION = 'appDescription',
  ORGANIZATION_NAME = 'organizationName',

  PRIMARY_COLOR = 'primaryColor',
  SECONDARY_COLOR = 'secondaryColor',

  LANDING_PAGE_IMAGE_PATH = 'landingPageBackgroundImagePath',
  ORGANIZATION_FULL_ICON_PATH = 'organizationFullIconImagePath',
  ORGANIZATION_SMALL_ICON_PATH = 'organizationSmallIconImagePath',
}
