<button
  mat-button
  [matMenuTriggerFor]="printMenu"
  class="btn btn-primary print-menu"
  id="print-menu-btn"
>
  <mat-icon>print</mat-icon>
  <span class="print-span">Print {{ printName }} Barcode</span>
</button>
<mat-menu #printMenu="matMenu" yPosition="above">
  <button
    mat-menu-item
    class="print-menu-item"
    [disabled]="!qrCodeValue?.length"
    (click)="printQRCode()"
    id="print-menu-qr-btn"
  >
    <fa-icon [icon]="faQrcode"></fa-icon>
    <span class="print-span">QR code</span>
  </button>
  <button
    mat-menu-item
    class="print-menu-item"
    [disabled]="!barcodeValue?.length"
    (click)="printBarcode()"
    id="print-menu-barcode-btn"
  >
    <fa-icon [icon]="faBarcode"></fa-icon>
    <span class="print-span">Barcode</span>
  </button>
</mat-menu>
