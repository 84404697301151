import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { PASS_ERROR_HEADER, SIMULATION_API } from '@core/constants/api.consts';

import {
  SimulationRun,
  SimulationRunResult,
} from '../../features/digital-micp-simulation/models/micp-simulation.model';
import { SimulationStatus } from '../../features/sample/models/simulation-status.model';

@Injectable({
  providedIn: 'root',
})
export class SimulationService {
  private url = `${SIMULATION_API}/Simulation`;
  private simulationRunUrl = '/run';

  constructor(private httpClient: HttpClient) {}

  runSimulation(simulation: SimulationRun): Observable<SimulationRunResult> {
    return this.httpClient.post<SimulationRunResult>(
      `${this.url}${this.simulationRunUrl}`,
      simulation,
      {
        headers: { [PASS_ERROR_HEADER]: '' },
      },
    );
  }

  getSimulationStatuses(sampleId: string): Observable<SimulationStatus[]> {
    return this.httpClient.get<SimulationStatus[]>(`${this.url}/simulationStatus`, {
      params: new HttpParams().set('sampleId', sampleId),
    });
  }
}
