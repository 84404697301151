import { Directive, HostListener, Input, NgModule } from '@angular/core';

@Directive({
  selector: '[appClearObjectProperty]',
})
export class ClearObjectPropertyDirective {
  @Input() object!: Record<string, Object>;
  @Input() prop!: string;

  @HostListener('click') clearProp(): void {
    this.object[this.prop] = '';
  }
}

@NgModule({
  declarations: [ClearObjectPropertyDirective],
  exports: [ClearObjectPropertyDirective],
})
export class ClearObjectPropertyModule {}
