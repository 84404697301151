import { filter, fromEvent } from 'rxjs';

import { Directive, ElementRef, HostBinding, Input, NgModule } from '@angular/core';

import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

@Directive({
  selector: '[appDisabledElement]',
})
export class DisabledElementDirective extends Destroyable(Object) {
  @Input() disabled = false;

  @HostBinding('class.disabled-element') get disabledElementCssClass(): boolean {
    return this.disabled;
  }

  constructor(private elementRef: ElementRef) {
    super();
    fromEvent<MouseEvent>(this.elementRef.nativeElement, 'click')
      .pipe(
        filter(() => this.disabled),
        this.takeUntilDestroyed(),
      )
      .subscribe((e) => {
        e.stopImmediatePropagation();
      });
  }
}

@NgModule({
  declarations: [DisabledElementDirective],
  exports: [DisabledElementDirective],
})
export class DisabledElementModule {}
