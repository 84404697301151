import {
  ErrorMessageConfig,
  INTEGER_ONLY_ERROR_MESSAGE,
  MAX_ERROR_MESSAGE,
  MIN_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
} from '@core/utils/form/error-message.config';

import { depthIsOutOfRangeErrorName } from './depth-in-range.validator';

export const DEPTH_IS_OUT_OF_RANGE_ERROR_MESSAGE: ErrorMessageConfig = {
  [depthIsOutOfRangeErrorName]: (error: any, fieldName: string) =>
    `Child's depth value should be in range of parent's depth value (${error.parentTopValue}-${error.parentBottomValue})`,
};

export const SAMPLE_CHECK_IN_DEPTH_VALIDATION_ERROR_MESSAGE_CONFIG: ErrorMessageConfig = {
  ...MAX_ERROR_MESSAGE,
  ...MIN_ERROR_MESSAGE,
  ...REQUIRED_ERROR_MESSAGE,
  ...DEPTH_IS_OUT_OF_RANGE_ERROR_MESSAGE,
  ...INTEGER_ONLY_ERROR_MESSAGE,
};
