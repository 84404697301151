import { Subject } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  NgModule,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { IdName } from '@core/models/id-name.model';
import { FlatFormValidationMessage } from '@core/utils/form/flat-form-validation-message.class';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { SynchronizeDepthUnitsModule } from 'src/app/common/directives/synchronize-depth-units.directive';
import { SampleType } from 'src/app/common/sample-check-in-menu/enums/sample-type.enum';
import { UnitInputField } from 'src/app/common/unit-input/enums/unit-input-field.enum';
import {
  UnitInputComponent,
  UnitInputModule,
} from 'src/app/common/unit-input/unit-input.component';

import { SAMPLE_ERROR_MESSAGES } from '../consts/sample-error-messages.const';
import { SAMPLE_ERROR_TYPES } from '../consts/sample-error-types.const';
import { SAMPLE_OPTIONS_COMPARE_FN } from '../consts/sample-options-compare-fn.const';
import { SAMPLE_CHECK_IN_DEPTH_VALIDATION_ERROR_MESSAGE_CONFIG } from '../form/sample-check-in-depth-validation-error-message.config';
import {
  CoreSampleInformationFormField,
  CuttingsSampleInformationFormField,
  FluidSampleInformationFormField,
  PlugSampleInformationFormField,
  ThinSectionSampleInformationFormField,
  UncategorizedSampleInformationFormField,
} from '../form/sample-check-in-form-field.enum';
import {
  CORE_SAMPLE_INFORMATION_FORM_LABEL,
  CUTTINGS_SAMPLE_INFORMATION_FORM_LABEL,
  FLUID_SAMPLE_INFORMATION_FORM_LABEL,
  PLUG_SAMPLE_INFORMATION_FORM_LABEL,
  UNCATEGORIZED_SAMPLE_INFORMATION_FORM_LABEL,
} from '../form/sample-check-in-form-labels';

@Component({
  selector: 'app-sample-typed-form',
  templateUrl: './sample-typed-form.component.html',
  styleUrls: ['./sample-typed-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleTypedFormComponent extends Destroyable(Object) implements OnInit, AfterViewInit {
  @Input() formGroup!: UntypedFormGroup;
  @Input() sampleType!: SampleType;
  @Input() lengthUnits: IdName[] = [];
  @Input() orientations = new Array<IdName>();
  @Input() preservationTypes = new Array<IdName>();
  @Input() resetFormSubject!: Subject<void>;
  @Input() sectionDimensionUnitList: IdName[] = [];
  @Input() sectionTypes = new Array<IdName>();
  @Input() stainings = new Array<IdName>();
  @Input() sectionOrigins = new Array<IdName>();

  depthValidationMessage: FlatFormValidationMessage<Record<string, string>> | null = null;

  readonly SAMPLE_TYPE = SampleType;
  readonly plugSampleInformationFormLabel = PLUG_SAMPLE_INFORMATION_FORM_LABEL;
  readonly coreSampleInformationFormLabel = CORE_SAMPLE_INFORMATION_FORM_LABEL;
  readonly cuttingsSampleInformationFormLabel = CUTTINGS_SAMPLE_INFORMATION_FORM_LABEL;
  readonly fluidSampleInformationFormLabel = FLUID_SAMPLE_INFORMATION_FORM_LABEL;
  readonly uncategorizedSampleInformationFormLabel = UNCATEGORIZED_SAMPLE_INFORMATION_FORM_LABEL;
  readonly plugSampleInformationFormField = PlugSampleInformationFormField;
  readonly coreSampleInformationFormField = CoreSampleInformationFormField;
  readonly cuttingsSampleInformationFormField = CuttingsSampleInformationFormField;
  readonly fluidSampleInformationFormField = FluidSampleInformationFormField;
  readonly uncategorizedSampleInformationFormField = UncategorizedSampleInformationFormField;
  readonly thinSectionSampleInformationFormField = ThinSectionSampleInformationFormField;
  readonly errorTypes = SAMPLE_ERROR_TYPES;
  readonly errorMessages = SAMPLE_ERROR_MESSAGES;
  readonly optionsCompareFn = SAMPLE_OPTIONS_COMPARE_FN;

  readonly compareWith = (o1: string | null, o2: string) => o1?.toLowerCase() === o2.toLowerCase();

  @ViewChildren(UnitInputComponent)
  private unitInputComponents!: QueryList<UnitInputComponent>;

  ngOnInit(): void {
    this.setupDepthValidationMessage();
    this.resetFormSubject.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.unitInputComponents.forEach((element) => {
        element.control.markAsUntouched();
        element.detectChanges();
      });
    });
  }

  ngAfterViewInit(): void {
    this.formGroup.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((_) => {
      this.markAllAsTouched();
    });
  }

  get preservationTypeFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.plugSampleInformationFormField.PRESERVATION_TYPE,
    ) as UntypedFormControl;
  }

  get sectionNumberFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.thinSectionSampleInformationFormField.SECTION_NUMBER,
    ) as UntypedFormControl;
  }

  get phyXFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.thinSectionSampleInformationFormField.SECTION_DIMENSION_X,
    ) as UntypedFormControl;
  }

  get phyYFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.thinSectionSampleInformationFormField.SECTION_DIMENSION_Y,
    ) as UntypedFormControl;
  }

  get phyZFormControl(): UntypedFormControl {
    return this.formGroup.get(
      this.thinSectionSampleInformationFormField.SECTION_DIMENSION_Z,
    ) as UntypedFormControl;
  }

  extractControl(name: string): UntypedFormControl {
    return this.formGroup.get([name]) as UntypedFormControl;
  }

  nonZeroFieldValueOrNull(unitInputControlName: string): number | null {
    return (
      Number(this.extractControl(unitInputControlName)!.value[UnitInputField.FIELD_VALUE]) || null
    );
  }

  errorMessageFunctionForDepth(fieldName: string): () => string {
    return () => this.depthValidationMessage!.getMessageFor(fieldName);
  }

  markAllAsTouched(): void {
    this.unitInputComponents.forEach((element) => {
      element.control.markAllAsTouched();
      element.detectChanges();
    });
  }

  private setupDepthValidationMessage(): void {
    this.depthValidationMessage = new FlatFormValidationMessage(
      this.formGroup,
      {
        depth: 'Depth',
        depthTop: 'Depth top',
        depthBottom: 'Depth bottom',
        sectionNumber: 'Section number',
      },
      {
        ...SAMPLE_CHECK_IN_DEPTH_VALIDATION_ERROR_MESSAGE_CONFIG,
      },
    );
  }
}

@NgModule({
  declarations: [SampleTypedFormComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    SynchronizeDepthUnitsModule,
    UnitInputModule,
    MatButtonToggleModule,
  ],
  exports: [SampleTypedFormComponent],
})
export class SampleTypedFormModule {}
