<h1 class="title">Return sample</h1>
<form [formGroup]="returnSampleForm" *ngIf="returnSampleForm" class="form">
  <div class="fields-line">
    <app-sample-table-selector-popup-field
      [formControl]="sampleIdFormControl"
      [required]="true"
      [label]="sampleReturnLabel[sampleReturnField.SAMPLE]"
      [getErrorMessage]="getSampleIdErrorMessage.bind(this)"
      (ngModelChange)="onSampleChanges($event)"
      id="sample-return-sample-input"
    >
    </app-sample-table-selector-popup-field>

    <div class="fields-line">
      <app-tree-view-selector-popup-field
        [formControlName]="sampleReturnField.LOCATION"
        [required]="true"
        [label]="sampleReturnLabel[sampleReturnField.LOCATION]"
        [treeViewNodeList]="locationNodes"
        [filterComponent]="locationTreeFilterComponent"
        [filterModule]="locationTreeFilterModule"
        [displayNamePipe]="locationDisplayNamePipe"
        id="sample-return-location-input"
      ></app-tree-view-selector-popup-field>
    </div>
  </div>
</form>
<div class="sample-info">
  <div class="sample-return-actions">
    <div class="form-manipulation-buttons">
      <button class="btn btn-secondary btn-big" (click)="resetForm()" id="sample-return-cancel-btn">
        Cancel
      </button>
      <button
        class="btn btn-primary btn-big"
        (click)="validateAndReturnSample()"
        id="sample-return-save-btn"
      >
        Return
      </button>
    </div>
  </div>

  <ng-container *ngIf="sampleId">
    <hr />
    <div [ngSwitch]="sample.type">
      <div *ngSwitchCase="'composite'">
        <app-composite-sample [sample]="sample" [isReadOnly]="true"></app-composite-sample>
      </div>
      <div *ngSwitchDefault>
        <app-sample [isReadOnly]="true" [sample]="sample"></app-sample>
      </div>
    </div>
  </ng-container>
</div>
