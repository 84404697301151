<h1 mat-dialog-title>Create Composite Sample</h1>

<mat-dialog-content>
  <app-composite-sample
    [embedded]="true"
    (compositeSampleCreated)="onCompositeSampleCreated($event)"
  >
  </app-composite-sample>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="btn btn-secondary btn-big" mat-dialog-close id="composite-sample-popup-cancel-btn">
    Cancel
  </button>
  <button
    class="btn btn-primary btn-big btn-with-spinner"
    (click)="initiateSaving()"
    [disabled]="isSaveBtnSpinnerVisible"
    id="composite-sample-popup-create-btn"
  >
    Create
    <mat-icon *ngIf="isSaveBtnSpinnerVisible"><mat-spinner diameter="20"> </mat-spinner></mat-icon>
  </button>
</mat-dialog-actions>
