import { ThrottleClickDirective } from '../../../common/directives/throttle-click.directive';

export function throttleClick() {
  return (target: Object, key: string | symbol, descriptor: PropertyDescriptor) => {
    const originalFunction = descriptor.value;

    descriptor.value = function (...args: any[]): any {
      const throttleClickDirective = args[0] as ThrottleClickDirective;

      if (!throttleClickDirective.isNextClickAllowed$.getValue()) {
        return;
      }

      throttleClickDirective.isNextClickAllowed$.next(false);

      // eslint-disable-next-line consistent-return
      return originalFunction.apply(this, args);
    };

    return descriptor;
  };
}
