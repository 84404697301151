import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { CheckboxListFieldModule } from 'src/app/common/checkbox-list-field/checkbox-list-field.component';
import { StopPropagationOnClickModule } from 'src/app/common/directives/stop-propagation-on-click.directive';
import { MultiSelectModule } from 'src/app/common/multi-select/multi-select.component';

import { ClearObjectPropertyModule } from '../directives/clear-object-property.directive';
import { DashboardWidgetOptionsPopupTab } from '../enums/dashboard-widget-options-popup-tab.enum';
import { DashboardWidgetOptionsPopupLists } from '../models/dashboard-widget-options-popup-lists.model';
import { DashboardWidgetOptionsPopupState } from '../models/dashboard-widget-options-popup-state.model';

@Component({
  selector: 'app-dashboard-widget-options-popup-team-member-tab',
  templateUrl: './dashboard-widget-options-popup-team-member-tab.component.html',
  styleUrls: ['./dashboard-widget-options-popup-team-member-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWidgetOptionsPopupTeamMemberTabComponent {
  @Input() filterState!: DashboardWidgetOptionsPopupState;
  @Input() filterLists!: DashboardWidgetOptionsPopupLists;

  readonly dashboardWidgetOptionsPopupTab = DashboardWidgetOptionsPopupTab;
}

@NgModule({
  declarations: [DashboardWidgetOptionsPopupTeamMemberTabComponent],
  exports: [DashboardWidgetOptionsPopupTeamMemberTabComponent],
  imports: [
    CheckboxListFieldModule,
    FormsModule,
    MultiSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    StopPropagationOnClickModule,
    MatIconModule,
    ClearObjectPropertyModule,
  ],
})
export class DashboardWidgetOptionsPopupTeamMemberTabModule {}
