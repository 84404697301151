export const FILE_ATTACH_BLOCK_EXT = [
  '.jpeg',
  '.jpg',
  '.png',
  '.raw',
  '.ibf',
  '.docx',
  '.xlsx',
  '.pdf',
  '.csv',
  '.tif',
  '.las',
  '.dlis',
  '.zipraw',
];
