<div class="table-wrapper">
  <table class="table custom-table" mat-table matSort [dataSource]="relatedSamples">
    <ng-container matColumnDef="wellName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Well Name</th>
      <td mat-cell *matCellDef="let row">
        {{ row.type === 'composite' ? (row.wellName | replaceEmptyString) : row.wellName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="relationship">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Relationship</th>
      <td mat-cell *matCellDef="let row">{{ row.relationship }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let row">{{ row.type }}</td>
    </ng-container>

    <ng-container matColumnDef="depth">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Depth</th>
      <td mat-cell *matCellDef="let row">
        {{ row.type === 'composite' ? (row.depth | replaceEmptyString) : row.depth }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sampleName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">{{ row.sampleName }}</td>
    </ng-container>

    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Owner</th>
      <td mat-cell *matCellDef="let row">{{ row.owner }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <mat-icon
          class="description-icon"
          matTooltipClass="description-text"
          [matTooltip]="row.description || 'No description'"
          >info</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="stateName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="access">Access</th>
      <td mat-cell *matCellDef="let row">
        <span
          class="state-name"
          *ngIf="
            row.stateName !== stateName.NO_ACCESS && row.stateName !== stateName.REJECTED;
            else accessElse
          "
          [ngClass]="row.stateName.toLowerCase()"
        >
          {{ row.stateName }}
        </span>
        <ng-template #accessElse>
          <button
            [disabled]="isReadOnly"
            class="btn btn-success btn-small"
            (click)="onRequestClick($event, row)"
            id="related-samples-request-btn"
          >
            Request
          </button>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.access]="row.stateName === stateName.OWN && !isReadOnly"
      (click)="navigateToSample(row)"
    ></tr>
    <tr *matNoDataRow class="mat-row table-row">
      <td colspan="8" class="mat-cell">
        <p>No related samples.</p>
      </td>
    </tr>
  </table>
</div>
