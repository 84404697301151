import { Injectable } from '@angular/core';

import { Token } from '@core/models/token.model';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  readonly accessTokenKey = 'accessToken';
  readonly refreshTokenKey = 'refreshToken';

  getAccessToken(): string | null {
    return localStorage.getItem(this.accessTokenKey);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenKey);
  }

  saveTokens({ accessToken, refreshToken }: Token): void {
    this.saveAccessToken(accessToken);
    this.saveRefreshToken(refreshToken);
  }

  removeTokens(): void {
    this.removeAccessToken();
    this.removeRefreshToken();
  }

  private saveAccessToken(accessToken: string): void {
    localStorage.setItem(this.accessTokenKey, accessToken);
  }

  private saveRefreshToken(refreshToken: string): void {
    localStorage.setItem(this.refreshTokenKey, refreshToken);
  }

  private removeAccessToken(): void {
    localStorage.removeItem(this.accessTokenKey);
  }

  private removeRefreshToken(): void {
    localStorage.removeItem(this.refreshTokenKey);
  }
}
