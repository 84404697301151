export enum WorkOrderExperimentDetailFormField {
  EXPERIMENT_TYPE = 'experimentType',
}

export const WORK_ORDER_EXPERIMENT_DETAIL_NAME_TO_DISPLAY: Record<
  WorkOrderExperimentDetailFormField,
  string
> = {
  [WorkOrderExperimentDetailFormField.EXPERIMENT_TYPE]: 'Experiment type',
};
