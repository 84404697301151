import { filter } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  ReactiveFormsModule,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { IdName } from '@core/models/id-name.model';
import { Disableable } from '@core/utils/mixins/disableable.mixin';
import { UnitInputField } from 'src/app/common/unit-input/enums/unit-input-field.enum';
import { UnitPopupData } from 'src/app/common/unit-input/models/unit-popup-data.model';
import { ParseUnitModule } from 'src/app/common/unit-input/parse-unit.pipe';
import {
  UnitPopupComponent,
  UnitPopupModule,
} from 'src/app/common/unit-input/unit-popup/unit-popup.component';
import { HAS_INNER_CONTROLS } from 'src/app/features/work-order/form/has-inner-controls.token';
import { CanDetectChanges } from 'src/app/features/work-order/models/can-detect-changes.model';
import { HasInnerControls } from 'src/app/features/work-order/models/has-inner-controls.model';

import { DisabledElementModule } from '../directives/disabled-element.directive';
import { StopPropagationOnClickModule } from '../directives/stop-propagation-on-click.directive';
import { pierceUnitInputValidator } from './utils/prierce-unit-input-validator';

@Component({
  selector: 'app-unit-input',
  templateUrl: './unit-input.component.html',
  styleUrls: ['./unit-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: HAS_INNER_CONTROLS,
      useExisting: UnitInputComponent,
    },
  ],
})
export class UnitInputComponent
  extends Disableable(Object)
  implements ControlValueAccessor, OnChanges, OnInit, HasInnerControls
{
  @Input() label!: string;
  @Input() dialogTitle!: string;
  @Input() type!: 'text' | 'number';
  @Input() defaultUnitId = '';
  @Input() getErrorMessage: () => string = () => '';
  @Input() formFieldName!: string;
  @Input() required = false;
  @Input() min: number | null = null;
  @Input() max: number | null = null;
  @Input() isHigherValueAsZeroAllowed = false;
  @Input() set options(opts: IdName[]) {
    this.displayOptions = opts.reduce(
      (acc, opt) => ({ ...acc, [opt.id.toLowerCase()]: opt.name }),
      {},
    );
    this._options = opts.map((opt) => ({ ...opt, id: opt.id.toLowerCase() }));
    setTimeout(() => {
      this.cd.markForCheck();
    });
  }

  get options(): IdName[] {
    return this._options;
  }

  readonly unitInputField = UnitInputField;

  displayOptions!: Record<string, string>;

  control = this.fb.group({
    [UnitInputField.FIELD_VALUE]: this.type === 'text' ? '' : null,
    [UnitInputField.UNIT_ID]: '',
  });

  onTouched!: (value: any) => void;
  onChange!: (value: any) => void;

  private _options!: IdName[];
  private initialNgControlValidators!: ValidatorFn[];
  private initialFieldValueValidators!: ValidatorFn[];

  get componentsWithInnerFormControls(): CanDetectChanges[] {
    return [this];
  }

  constructor(
    private ngControl: NgControl,
    private matDialog: MatDialog,
    private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
  ) {
    super();
    this.ngControl.valueAccessor = this;
  }

  detectChanges(): void {
    this.cd.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const minChanges = changes['min'];
    if (minChanges && !minChanges.firstChange) {
      if (!minChanges.currentValue) {
        this.resetValidators();
      } else {
        this.setMinValidator(Number(minChanges.currentValue));
      }
    }

    const maxChanges = changes['max'];
    if (maxChanges && !maxChanges.firstChange) {
      if (!maxChanges.currentValue) {
        this.resetValidators();
      } else {
        this.setMaxValidator(Number(maxChanges.currentValue));
      }
    }
  }

  ngOnInit(): void {
    this.setUnitIdDefaultValue();
    this.initializeValidators();
    this.setUpNgControl();
  }

  selectUnit(): void {
    this.matDialog
      .open<UnitPopupComponent, UnitPopupData>(UnitPopupComponent, {
        data: {
          title: this.dialogTitle || `Unit for ${this.label}`,
          options: this.options,
          displayOptions: this.displayOptions,
          currentValue: this.ngControl.control!.value[UnitInputField.UNIT_ID],
        },
        minWidth: 500,
      })
      .afterClosed()
      .pipe(filter((val) => !!val))
      .subscribe((unitId) => {
        this.control.get(UnitInputField.UNIT_ID)!.setValue(unitId);
        this.cd.markForCheck();
      });
  }

  writeValue(obj: any): void {
    if (!obj) {
      return;
    }

    this.control.setValue(obj, { emitEvent: false });
    this.cd.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private initializeValidators(): void {
    const fieldValueValidators = [
      this.ngControl.control?.validator
        ? this.ngControl.control?.validator
        : Validators.nullValidator,
    ];

    const ngControlValidators = [...fieldValueValidators].map(pierceUnitInputValidator);

    if (this.required) {
      ngControlValidators.push(pierceUnitInputValidator(Validators.required));
      fieldValueValidators.push(Validators.required);
    }

    this.initialNgControlValidators = [...ngControlValidators];
    this.initialFieldValueValidators = [...fieldValueValidators];

    if (this.min !== null) {
      ngControlValidators.push(pierceUnitInputValidator(this.getAppropriateMinValidator(this.min)));
      fieldValueValidators.push(this.getAppropriateMinValidator(this.min));
    }

    if (this.max !== null) {
      ngControlValidators.push(pierceUnitInputValidator(Validators.max(this.max)));
      fieldValueValidators.push(Validators.max(this.max));
    }

    this.setValidators(ngControlValidators, fieldValueValidators);
  }

  private resetValidators(): void {
    const ngControlValidators = [...this.initialNgControlValidators];
    const fieldValueValidators = [...this.initialFieldValueValidators];
    this.setValidators(ngControlValidators, fieldValueValidators);
  }

  private setMinValidator(minValue: number): void {
    const ngControlValidators = [
      ...this.initialNgControlValidators,
      pierceUnitInputValidator(this.getAppropriateMinValidator(minValue)),
    ];
    const fieldValueValidators = [
      ...this.initialFieldValueValidators,
      this.getAppropriateMinValidator(minValue),
    ];
    this.setValidators(ngControlValidators, fieldValueValidators);
  }

  private setMaxValidator(maxValue: number): void {
    const ngControlValidators = [
      ...this.initialNgControlValidators,
      pierceUnitInputValidator(Validators.max(maxValue)),
    ];
    const fieldValueValidators = [...this.initialFieldValueValidators, Validators.max(maxValue)];
    this.setValidators(ngControlValidators, fieldValueValidators);
  }

  private setValidators(
    ngControlValidators: ValidatorFn[],
    fieldValueValidators: ValidatorFn[],
  ): void {
    this.ngControl.control?.setValidators(ngControlValidators);
    this.ngControl.control?.updateValueAndValidity({ emitEvent: false });

    this.control.get([UnitInputField.FIELD_VALUE])!.setValidators(fieldValueValidators);
    this.control.get([UnitInputField.FIELD_VALUE])!.updateValueAndValidity({ emitEvent: false });

    this.cd.markForCheck();
  }

  private setUnitIdDefaultValue(): void {
    if (!this.ngControl.control!.value[UnitInputField.UNIT_ID]) {
      this.control.get(UnitInputField.UNIT_ID)!.setValue(this.defaultUnitId || this.options[0].id);
    }
  }

  private setUpNgControl(): void {
    this.ngControl.control!.markAsTouched = () => {
      this.control.markAllAsTouched();
    };
    this.ngControl.control!.reset = (value?: any, options?: Object) => {
      const defaultValue = {
        [UnitInputField.UNIT_ID]: this.defaultUnitId || this.options[0].id,
        [UnitInputField.FIELD_VALUE]: '',
      };

      if (value || options) {
        this.control.reset(value ?? defaultValue, options);
        return;
      }

      this.control.reset(defaultValue);
    };
    const ngControlSetErrors = this.ngControl.control!.setErrors.bind(this.ngControl.control!);
    this.ngControl.control!.setErrors = (
      ...args: [
        ValidationErrors | null,
        {
          emitEvent?: boolean;
        },
      ]
    ) => {
      setTimeout(() => {
        ngControlSetErrors(...args);
        this.control.get([UnitInputField.FIELD_VALUE])!.setErrors(...args);
        this.cd.markForCheck();
      });
    };
  }

  private getAppropriateMinValidator(min: number): ValidatorFn {
    return this.isHigherValueAsZeroAllowed ? this.minOrZeroValidator(min) : Validators.min(min);
  }

  private minOrZeroValidator(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const val = Number(control.value);
      return val >= min || val === 0 ? null : { minOrZero: { min } };
    };
  }
}

@NgModule({
  declarations: [UnitInputComponent],
  exports: [UnitInputComponent],
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    UnitPopupModule,
    MatInputModule,
    ParseUnitModule,
    CommonModule,
    DisabledElementModule,
    StopPropagationOnClickModule,
  ],
})
export class UnitInputModule {}
