import { LocationFormField } from 'src/app/features/location/form/location-form-field.enum';

export const LOCATION_FORM_FIELD_NAME_TO_DISPLAY: Record<LocationFormField, string> = {
  [LocationFormField.NAME]: 'Location name',
  [LocationFormField.DESCRIPTION]: 'Location description',
  [LocationFormField.LOCATION_TYPE_ID]: 'Location Type',
  [LocationFormField.LOCATION_RESTRICTION_IDS]: 'Location Restrictions',
  [LocationFormField.PARENT_LOCATION_ID]: 'Parent location name',
  [LocationFormField.BARCODE_ID]: 'Barcode',
  [LocationFormField.QR_CODE_ID]: 'QR code',
};
