export enum DigitalVolumeInfoFormField {
  ID = 'id',
  FILE_NAME = 'fileName',
  FILE_ID = 'fileId',
  PROCESSED_FILE_ID = 'processedFileId',
  DIGITAL_VOLUME_NAME = 'digitalVolumeName',
  DEPTH = 'depth',
  SAMPLE_TYPE_ID = 'sampleTypeId',
  SUB_SAMPLE_NUMBER = 'subSampleNumber',
  ORIENTATION_ID = 'orientationId',
  VOLUME_TYPE_ID = 'volumeTypeId',
  ORIGIN_LOCATION_X = 'originLocationX',
  ORIGIN_LOCATION_Y = 'originLocationY',
  ORIGIN_LOCATION_Z = 'originLocationZ',
  ORIGIN_LOCATION_UNIT_ID = 'originLocationUnitId',
  VOXEL_SIZE_X = 'voxelSizeX',
  VOXEL_SIZE_Y = 'voxelSizeY',
  VOXEL_SIZE_Z = 'voxelSizeZ',
  VOXEL_SIZE_UNIT_ID = 'voxelSizeUnitId',
  GRID_NUMBER_X = 'gridNumberX',
  GRID_NUMBER_Y = 'gridNumberY',
  GRID_NUMBER_Z = 'gridNumberZ',
  DATA_FORMAT_ID = 'dataFormatId',
  LABORATORY_INFORMATION = 'laboratoryInformation',
}

export const DIGITAL_VOLUME_INFO_NAME_TO_DISPLAY: Record<DigitalVolumeInfoFormField, string> = {
  [DigitalVolumeInfoFormField.ID]: 'ID',
  [DigitalVolumeInfoFormField.FILE_NAME]: 'Sample filename',
  [DigitalVolumeInfoFormField.FILE_ID]: 'Sample filename',
  [DigitalVolumeInfoFormField.PROCESSED_FILE_ID]: 'Processed file ID',
  [DigitalVolumeInfoFormField.DIGITAL_VOLUME_NAME]: 'Sample name',
  [DigitalVolumeInfoFormField.DEPTH]: 'Depth',
  [DigitalVolumeInfoFormField.SAMPLE_TYPE_ID]: 'Sample type',
  [DigitalVolumeInfoFormField.SUB_SAMPLE_NUMBER]: 'Subsample number',
  [DigitalVolumeInfoFormField.ORIENTATION_ID]: 'Orientation',
  [DigitalVolumeInfoFormField.VOLUME_TYPE_ID]: 'Volume type',
  [DigitalVolumeInfoFormField.ORIGIN_LOCATION_X]: 'X',
  [DigitalVolumeInfoFormField.ORIGIN_LOCATION_Y]: 'Y',
  [DigitalVolumeInfoFormField.ORIGIN_LOCATION_Z]: 'Z',
  [DigitalVolumeInfoFormField.ORIGIN_LOCATION_UNIT_ID]: '',
  [DigitalVolumeInfoFormField.VOXEL_SIZE_X]: 'X',
  [DigitalVolumeInfoFormField.VOXEL_SIZE_Y]: 'Y',
  [DigitalVolumeInfoFormField.VOXEL_SIZE_Z]: 'Z',
  [DigitalVolumeInfoFormField.VOXEL_SIZE_UNIT_ID]: '',
  [DigitalVolumeInfoFormField.GRID_NUMBER_X]: 'X',
  [DigitalVolumeInfoFormField.GRID_NUMBER_Y]: 'Y',
  [DigitalVolumeInfoFormField.GRID_NUMBER_Z]: 'Z',
  [DigitalVolumeInfoFormField.DATA_FORMAT_ID]: 'Data format',
  [DigitalVolumeInfoFormField.LABORATORY_INFORMATION]: 'Vendor Information',
};
