<div class="search-wrapper" [class.is-selector]="isSelector">
  <div class="search-by-wrapper">
    <mat-form-field>
      <mat-label>Search By</mat-label>
      <mat-select
        [(ngModel)]="filterData.filterType"
        (ngModelChange)="onByFieldChanged($event)"
        id="sample-search-by-filter-select"
      >
        <mat-option *ngFor="let field of searchByFields" [value]="field.value">
          {{ field.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <app-search-input
    class="app-search-input"
    [label]="'Scan or type to search'"
    [placeholder]="searchPlaceholder"
    [value]="filterData.searchString"
    (searchEvent)="onSearch($event)"
    (barcodeClick)="searchByBarcode($event)"
    id="sample-search-input"
  ></app-search-input>
  <span class="search-spacer"></span>
  <div class="filter-button-wrapper">
    <button class="btn btn-square btn-transparent" (click)="onFilterClick()" id="sample-filter-btn">
      <mat-icon>tune</mat-icon>
    </button>
  </div>
</div>

<div class="table-wrapper" [class.is-selector]="isSelector">
  <div class="spinner-container" *ngIf="dataSource.loadingCounter$ | async">
    <mat-spinner
      class="loading-icon"
      matSuffix
      [diameter]="150"
      [matTooltip]="'Loading...'"
    ></mat-spinner>
  </div>

  <table
    class="table custom-table"
    mat-table
    matSort
    [dataSource]="dataSource"
    [matSortActive]="filterData.sortColumnName"
    [matSortDirection]="filterData.sortDirection"
  >
    <ng-container matColumnDef="wellName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Well Name</th>
      <td mat-cell *matCellDef="let row">
        {{ row.type === 'composite' ? (row.wellName | replaceEmptyString) : row.wellName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td
        mat-cell
        *matCellDef="let row"
        class="sample-type"
        [class.sample-type__allowed-for-select]="isSelector && isSampleTypeAllowedForSelect(row)"
      >
        {{ row.type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="depth">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Depth</th>
      <td mat-cell *matCellDef="let row">
        {{ row.type === 'composite' ? (row.depth | replaceEmptyString) : row.depth }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sampleName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">{{ row.sampleName }}</td>
    </ng-container>

    <ng-container matColumnDef="locationName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
      <td mat-cell *matCellDef="let row">{{ row.locationName }}</td>
    </ng-container>

    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Owner</th>
      <td mat-cell *matCellDef="let row">{{ row.owner }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef class="no-border"></th>
      <td mat-cell *matCellDef="let row" class="no-border">
        <mat-icon
          class="description-icon"
          matTooltipClass="description-text"
          [matTooltip]="row.description || 'No description'"
          >info</mat-icon
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="stateName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="access">Access</th>
      <td mat-cell *matCellDef="let row">
        <span
          class="state-name"
          *ngIf="
            isSelector ||
              (row.stateName !== stateName.NO_ACCESS && row.stateName !== stateName.REJECTED);
            else accessElse
          "
          [ngClass]="row.stateName.toLowerCase()"
        >
          {{ row.stateName }}
        </span>

        <ng-template #accessElse>
          <button
            class="btn btn-success btn-small"
            (click)="onRequestClick($event, row)"
            id="sample-access-request-btn"
          >
            Request
          </button>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.selected]="row.id === selectedSample?.id"
      [class.access]="hasAccessToSample(row)"
      [class.can-select]="isSelector && hasAccessToSample(row) && isSampleTypeAllowedForSelect(row)"
      (click)="onSampleRowClick(row)"
    ></tr>

    <tr class="mat-row table-row" *matNoDataRow>
      <td class="mat-cell" colspan="8">
        {{
          !dataSource.data.length
            ? 'No data. Start typing in the search box above'
            : 'No data matching the filter value'
        }}
      </td>
    </tr>
  </table>

  <mat-card>
    <ng-container *ngIf="!isSelector">
      <app-sample-check-in-menu (menuItemClick)="navigateToSampleCheckInPage($event)">
      </app-sample-check-in-menu>
    </ng-container>

    <mat-paginator
      class="mat-paginator"
      aria-label="Select page"
      [length]="(dataSource.count$ | async) || 0"
      [pageIndex]="filterData.page - 1"
      [pageSize]="filterData.pageSize"
      [pageSizeOptions]="[5, 10, 20, 30]"
    ></mat-paginator>
  </mat-card>
</div>
