import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-not-implemented-information-popup',
  templateUrl: './not-implemented-information-popup.component.html',
  styleUrls: ['./not-implemented-information-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotImplementedInformationPopupComponent {
  public readonly title = 'Information';
  public message = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {
    this.message = data;
  }
}

@NgModule({
  declarations: [NotImplementedInformationPopupComponent],
  exports: [NotImplementedInformationPopupComponent],
  imports: [MatButtonModule, MatIconModule, MatDialogModule],
})
export class NotImplementedInformationPopupModule {}
