import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export class FlatFormValidationMessage<T extends { [key: string]: string }> {
  constructor(
    private formGroup: UntypedFormGroup,
    private formFieldNameToDisplay: Record<T[keyof T], string>,
    private formFieldErrorMessage: Record<string, (error: any, fieldName: string) => string>,
  ) {}

  hasError(): boolean {
    return (
      this.formGroup.invalid &&
      Object.values(this.formGroup.controls).some((control) => !!control.errors)
    );
  }

  getErrorsBy(formField: T[keyof T]): Validators | null | undefined {
    return this.formGroup.get(formField)?.errors;
  }

  getFirstMessage(notificationSpecificFieldName?: Partial<Record<T[keyof T], string>>): string {
    const controlWithError = Object.entries(this.formGroup.controls).find(
      ([_, control]) => !!control.errors,
    );

    const [formField, control] = controlWithError! as [T[keyof T], AbstractControl];
    const [errorName, error] = Object.entries(control.errors!)[0];

    return this.formFieldErrorMessage[errorName](
      error,
      notificationSpecificFieldName?.[formField] ?? this.formFieldNameToDisplay[formField],
    );
  }

  getMessageFor(formField: T[keyof T]): string {
    const control = this.formGroup.get(formField) as UntypedFormControl;
    const [errorName, error] = Object.entries(control.errors!)[0];

    return this.formFieldErrorMessage[errorName](error, this.formFieldNameToDisplay[formField]);
  }
}
