import { Observable, Subject, take } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API, SKIP_TOKEN } from '@core/constants/api.consts';
import { CACHE_KEYS } from '@core/constants/cache-keys.const';
import { Organization } from '@core/models/organization.model';

import { CacheService } from '../cache.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  currentOrganizationData!: Organization;
  currentOrganizationDataChangedSubject = new Subject<Organization>();

  private readonly url = `${CORE_MEMORY_API}/Organizations`;

  constructor(private httpClient: HttpClient, private cacheService: CacheService) {
    this.getOrganizations()
      .pipe(take(1))
      .subscribe((x: Organization[]) => {
        const [firstOrganization] = x;
        this.currentOrganizationData = firstOrganization;
        this.applyOrganization();
      });
  }

  getOrganizations(): Observable<Organization[]> {
    return this.cacheService.getCachedObservable(
      CACHE_KEYS.ORGANIZATIONS,
      this.httpClient.get<Organization[]>(`${this.url}`, { headers: { [SKIP_TOKEN]: '' } }),
    );
  }

  getCurrentOrganization(): Observable<Organization> {
    return this.cacheService.getCachedObservable(
      CACHE_KEYS.CURRENT_ORGANIZATION,
      this.httpClient.get<Organization>(`${this.url}/organizationByUser`),
    );
  }

  updateCurrentOrganization(id: string, data: Organization): Observable<Organization> {
    const formData = new FormData();
    formData.append('appDescription', data.appDescription);
    formData.append('appName', data.appName);
    formData.append('appSlogan', data.appSlogan);
    formData.append('landingPageBackgroundImagePath', data.landingPageBackgroundImagePath);
    formData.append('organizationFullIconImagePath', data.organizationFullIconImagePath);
    formData.append('organizationName', data.organizationName);
    formData.append('organizationSmallIconImagePath', data.organizationSmallIconImagePath);
    formData.append('primaryColor', data.primaryColor);
    formData.append('secondaryColor', data.secondaryColor);

    return this.httpClient.put<Organization>(`${this.url}/${id}`, formData, {
      headers: { accept: 'multipart/form-data' },
    });
  }

  applyOrganization(): void {
    this.currentOrganizationDataChangedSubject.next(this.currentOrganizationData);
    const primaryColor = `#${this.currentOrganizationData.primaryColor}`;
    const secondaryColor = `#${this.currentOrganizationData.secondaryColor}`;
    const secondaryColorAccent = `#${this.currentOrganizationData.secondaryColor}80`;
    const root = document.documentElement;
    root.style.setProperty('--primary-color-seven', primaryColor);
    root.style.setProperty('--primary-color-eight', secondaryColor);
    root.style.setProperty('--primary-color-eight-accent', secondaryColorAccent);
  }
}
