import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import {
  distinctUntilChanged,
  filter,
  finalize,
  forkJoin,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnInit,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { IdName } from '@core/models/id-name.model';
import { ValidationState } from '@core/models/validation-state.model';
import { throttleClick } from '@core/utils/decorators/throttle-click.decorator';
import { ErrorMessageConfig, REQUIRED_ERROR_MESSAGE } from '@core/utils/form/error-message.config';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { CompositeSamplePopupComponent } from 'src/app/common/composite-sample-popup/composite-sample-popup.component';
import { SampleDisplayNameModule } from 'src/app/common/sample-table-selector-popup-field/pipes/sample-display-name.pipe';
import { UnitInputField } from 'src/app/common/unit-input/enums/unit-input-field.enum';
import { ExistingWorkOrdersModule } from 'src/app/features/work-order/existing-work-orders/existing-work-orders.component';
import { WorkOrderFormField } from 'src/app/features/work-order/form/field-configs/work-order.field-config';
import { WorkOrderGeneralInformationFormField } from 'src/app/features/work-order/form/field-configs/work-order-general-information.field-config';
import { generateWorkOrderForm } from 'src/app/features/work-order/form/generate-work-order-form.util';
import { ExperimentField } from 'src/app/features/work-order/models/experiment-field.model';
import { WorkOrderImpl } from 'src/app/features/work-order/models/work-order.model';
import {
  WorkOrderFormShape,
  WorkOrderFormShapeImpl,
} from 'src/app/features/work-order/models/work-order-form-shape.model';
import { ExperimentTypeService } from 'src/app/features/work-order/services/experiment-type.service';
import { VendorNameService } from 'src/app/features/work-order/services/vendor-name.service';
import { WorkOrderService } from 'src/app/features/work-order/services/work-order.service';
import {
  WorkOrderFormComponent,
  WorkOrderFormModule,
} from 'src/app/features/work-order/work-order-form/work-order-form.component';
import { WorkOrderLogicalBlockModule } from 'src/app/features/work-order/work-order-logical-block/work-order-logical-block.component';

import {
  ThrottleClickDirective,
  ThrottleClickModule,
} from '../../common/directives/throttle-click.directive';
import { SampleTableSelectorPopupFieldModule } from '../../common/sample-table-selector-popup-field/sample-table-selector-popup-field.component';
import { NotificationService } from '../../services/notification.service';
import { NewExperimentType, newExperimentTypeFields } from './mocks/experiment-type-temporary.mock';
import { WorkOrderDisplayNamePipe } from './pipes/work-order-display-name.pipe';

@Component({
  selector: 'app-work-order',
  templateUrl: './work-order.component.html',
  styleUrls: ['./work-order.component.scss'],
  providers: [WorkOrderDisplayNamePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkOrderComponent extends Destroyable(Object) implements OnInit {
  readonly workOrderFormField = WorkOrderFormField;
  readonly workOrderGeneralInformationFormField = WorkOrderGeneralInformationFormField;
  readonly faQrcode = faQrcode;
  readonly sampleControlDisplayName = 'Sample';
  readonly sampleControlErrorMessageConfig: ErrorMessageConfig = {
    ...REQUIRED_ERROR_MESSAGE,
  };

  sampleIdFormControl = new UntypedFormControl(null, [Validators.required]);
  workOrderFormArray = this.formBuilder.array([]);

  existingWorkOrders$!: Observable<WorkOrderFormShape[]>;
  vendorNames: IdName[] = [];
  experimentTypes: IdName[] = [];
  private sampleId$!: Observable<string>;

  get workOrderFormArrayControls(): UntypedFormGroup[] {
    return this.workOrderFormArray.controls as UntypedFormGroup[];
  }

  get canAddNextWorkOrder(): boolean {
    return this.workOrderFormArrayControls.every(
      (workOrderFrom) =>
        !!workOrderFrom.get([
          WorkOrderFormField.GENERAL_INFORMATION,
          WorkOrderGeneralInformationFormField.ID,
        ])?.value,
    );
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private workOrderService: WorkOrderService,
    private vendorNameService: VendorNameService,
    private experimentTypeService: ExperimentTypeService,
    private cd: ChangeDetectorRef,
    private matDialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.defineSampleIdListener();
    this.addWorkOrderForm();
    this.getOptionsForSelectorsAndSetUpExistingWorkOrdersStream();
    this.setUpWorkOrderFormResetLogic();
  }

  getOptionsForSelectorsAndSetUpExistingWorkOrdersStream(): void {
    forkJoin([this.vendorNameService.getAll(), this.experimentTypeService.getAll()])
      .pipe(
        finalize(() => {
          this.setUpExistingWorkOrdersStream();
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe(([vendorNames, experimentTypes]) => {
        this.vendorNames = vendorNames;
        this.experimentTypes = experimentTypes;
      });
  }

  addWorkOrderForm(): void {
    this.workOrderFormArray.push(
      generateWorkOrderForm(this.formBuilder, new WorkOrderFormShapeImpl()),
    );
  }

  resetForm(component: WorkOrderFormComponent): void {
    component.workOrderFormGroup.reset(new WorkOrderFormShapeImpl());
  }

  @throttleClick()
  save(throttleClickDirective: ThrottleClickDirective, component: WorkOrderFormComponent): void {
    const validationState = this.checkIfFormIsValid(component);

    if (!validationState.isValid) {
      this.sampleIdFormControl.markAsTouched();
      component.workOrderFormGroup.markAllAsTouched();
      component.componentsWithInnerFormControls.forEach((cmp) => cmp.detectChanges());
      this.notificationService.notifyError(validationState.errorMessage);
      throttleClickDirective.isNextClickAllowed$.next(true);
      return;
    }

    const experimentFields = this.getExperimentFields(component);
    const payload = new WorkOrderImpl({
      ...component.workOrderFormGroup.getRawValue(),
      sampleId: this.sampleIdFormControl.value,
      experimentFields,
    });
    const method = payload.id ? 'update' : 'create';

    (this.workOrderService[method](payload) as Observable<{ id: string } | null>)
      .pipe(throttleClickDirective.allowNextClickAfterFinalized(), this.takeUntilDestroyed())
      .subscribe((resp) => {
        const notificationMessage = `Experiment ${resp?.id ? 'created' : 'updated'}`;
        this.notificationService.notifySuccess(notificationMessage);

        if (resp?.id) {
          this.updateWorkOrderIdControl(component, resp.id);
        }

        this.cd.markForCheck();
        component.detectChanges();
      });
  }

  getSampleIdErrorMessage(): string {
    const [errorName, error] = Object.entries(this.sampleIdFormControl.errors!)[0];
    return this.sampleControlErrorMessageConfig[errorName](error, this.sampleControlDisplayName);
  }

  createCompositeSample(): void {
    this.matDialog
      .open<CompositeSamplePopupComponent, any, string>(CompositeSamplePopupComponent, {
        minWidth: 1000,
        minHeight: 500,
        height: '80%',
      })
      .afterClosed()
      .pipe(
        filter((compositeSampleId) => !!compositeSampleId),
        this.takeUntilDestroyed(),
      )
      .subscribe((compositeSampleId) => {
        this.sampleIdFormControl.setValue(compositeSampleId);
      });
  }

  private updateWorkOrderIdControl(component: WorkOrderFormComponent, id: string) {
    const workOrderIdControl = component.workOrderFormGroup.get([
      WorkOrderFormField.GENERAL_INFORMATION,
      WorkOrderGeneralInformationFormField.ID,
    ])!;
    workOrderIdControl.setValue(id);
    workOrderIdControl.disable({ emitEvent: false });
  }

  private defineSampleIdListener(): void {
    this.sampleId$ = this.sampleIdFormControl.valueChanges.pipe(
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  private setUpExistingWorkOrdersStream(): void {
    this.existingWorkOrders$ = this.sampleId$.pipe(
      switchMap((sampleId) => {
        if (sampleId) {
          return this.workOrderService.getAllBy(sampleId).pipe(
            map((workOrders) =>
              workOrders.map((workOrder) => new WorkOrderFormShapeImpl(workOrder)),
            ),
            map((workOrders) =>
              workOrders.map((workOrder) => {
                const experimentTypeId = workOrder.experimentDetail.experimentType;

                if (!experimentTypeId) {
                  return workOrder;
                }

                const experimentType = this.experimentTypes.find(
                  (expType) => expType.id === experimentTypeId,
                )!;

                const isNewExperimentType = Object.values(NewExperimentType).includes(
                  experimentType.name as NewExperimentType,
                );

                if (isNewExperimentType) {
                  return {
                    ...workOrder,
                    experimentDetail: {
                      ...workOrder.experimentDetail,
                      experimentFields: [...newExperimentTypeFields],
                    },
                  };
                }

                return workOrder;
              }),
            ),
          );
        }

        return of([]);
      }),
    );
  }

  private checkIfFormIsValid(component: WorkOrderFormComponent): ValidationState {
    if (this.sampleIdFormControl.errors) {
      return {
        isValid: false,
        errorMessage: this.getSampleIdErrorMessage(),
      };
    }

    if (component.workOrderFormGroup.status === 'VALID') {
      return { isValid: true };
    }

    const validationMessage = component.dynamicValidationMessageList.find((valMessage) =>
      valMessage.hasError(),
    )!;

    return {
      isValid: false,
      errorMessage: validationMessage.getFirstMessage(),
    };
  }

  private getExperimentFields(component: WorkOrderFormComponent): ExperimentField[] {
    const { experimentFieldsMap, dynamicFieldFormGroup } =
      component.dynamicExperimentTemplateComponent;

    const experimentFields = Object.values(experimentFieldsMap).map((experimentField) => {
      const fieldValue = dynamicFieldFormGroup.value[experimentField.fieldName];

      if (fieldValue === null || typeof fieldValue === 'string' || typeof fieldValue === 'number') {
        return { ...experimentField, fieldValue };
      }

      return {
        ...experimentField,
        fieldValue: fieldValue[UnitInputField.FIELD_VALUE],
        unitValue: fieldValue[UnitInputField.UNIT_ID],
      };
    });

    return experimentFields;
  }

  private setUpWorkOrderFormResetLogic(): void {
    this.sampleId$.pipe(this.takeUntilDestroyed()).subscribe(() => {
      const isAnyCreatedWorkOrderExist = this.workOrderFormArrayControls.some(
        (workOrderFormGroup) =>
          !!workOrderFormGroup
            .get(WorkOrderFormField.GENERAL_INFORMATION)!
            .get(WorkOrderGeneralInformationFormField.ID)!.value,
      );

      if (isAnyCreatedWorkOrderExist) {
        this.resetWorkOrderForm();
      }
    });
  }

  private resetWorkOrderForm(): void {
    this.workOrderFormArray.clear();
    this.addWorkOrderForm();
  }
}

@NgModule({
  declarations: [WorkOrderComponent],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    FontAwesomeModule,
    CommonModule,
    ReactiveFormsModule,
    ExistingWorkOrdersModule,
    WorkOrderLogicalBlockModule,
    MatExpansionModule,
    WorkOrderFormModule,
    MatAutocompleteModule,
    SampleDisplayNameModule,
    SampleTableSelectorPopupFieldModule,
    ThrottleClickModule,
  ],
  exports: [WorkOrderComponent],
  providers: [WorkOrderService, VendorNameService, ExperimentTypeService],
})
export class WorkOrderModule {}
