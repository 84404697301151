import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const numericRangeError = 'numericRangeError';

export function numericRange(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const { value } = control as UntypedFormControl;
    const isValid = value >= min && value <= max;

    return isValid ? null : { [numericRangeError]: { min, max } };
  };
}
