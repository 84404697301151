import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnInit,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { LocationTreeViewNode } from '@core/models/location-tree-view-node.model';
import { ErrorMessageConfig, REQUIRED_ERROR_MESSAGE } from '@core/utils/form/error-message.config';
import { ConfirmationPopupComponent } from 'src/app/common/confirmation-popup/confirmation-popup.component';
import {
  LocationTreeFilterComponent,
  LocationTreeFilterModule,
} from 'src/app/common/location-tree-filter/location-tree-filter.component';
import { TreeViewSelectorPopupFieldModule } from 'src/app/common/tree-view-selector-popup-field/tree-view-selector-popup-field.component';
import { NotificationService } from 'src/app/services/notification.service';

import { SampleTableSelectorPopupFieldModule } from '../../../common/sample-table-selector-popup-field/sample-table-selector-popup-field.component';
import { Destroyable } from '../../../core/utils/mixins/destroyable.mixin';
import { LocationTreeService } from '../../../services/api/location-tree.service';
import { SampleService } from '../../../services/api/sample.service';
import { LocationDisplayNamePipe } from '../../location/pipes/location-display-name.pipe';
import { CompositeSampleModule } from '../composite-sample/composite-sample.component';
import { SampleMovementStatus } from '../enums/sample-movement-status.enum';
import { SampleReturnFormFields } from '../form/sample-return-form-field.enum';
import { SAMPLE_RETURN_FORM_LABEL } from '../form/sample-return-form-labels';
import { ReturnSampleRequest } from '../models/return-sample-request.model';
import { SampleModule } from '../sample.component';

@Component({
  selector: 'app-sample-return',
  templateUrl: './sample-return.component.html',
  styleUrls: ['./sample-return.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LocationDisplayNamePipe],
})
export class SampleReturnComponent extends Destroyable(Object) implements OnInit {
  readonly sampleReturnField = SampleReturnFormFields;
  readonly sampleReturnLabel = SAMPLE_RETURN_FORM_LABEL;
  readonly locationTreeFilterComponent = LocationTreeFilterComponent;
  readonly locationTreeFilterModule = LocationTreeFilterModule;
  readonly sampleControlDisplayName = SAMPLE_RETURN_FORM_LABEL;
  readonly sampleControlErrorMessageConfig: ErrorMessageConfig = {
    ...REQUIRED_ERROR_MESSAGE,
  };

  sampleId!: string;
  sample: any;
  returnSampleForm!: UntypedFormGroup;
  sampleIdFormControl = new UntypedFormControl('');
  locationNodes: LocationTreeViewNode[] = [];

  constructor(
    private locationTreeService: LocationTreeService,
    private changeDetectorRef: ChangeDetectorRef,
    private sampleService: SampleService,
    private notificationService: NotificationService,
    public locationDisplayNamePipe: LocationDisplayNamePipe,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getLocationNodes();
  }

  getSampleIdErrorMessage(): string {
    const [errorName, error] = Object.entries(this.sampleIdFormControl.errors!)[0];
    return this.sampleControlErrorMessageConfig[errorName](
      error,
      this.sampleReturnLabel[this.sampleReturnField.SAMPLE],
    );
  }

  initializeForm(): void {
    this.returnSampleForm = new UntypedFormGroup({
      [this.sampleReturnField.SAMPLE]: this.sampleIdFormControl,
      [this.sampleReturnField.LOCATION]: new UntypedFormControl(''),
    });
  }

  onSampleChanges(sampleId: string): void {
    this.sampleId = '';

    if (sampleId) {
      this.sampleService
        .getById(sampleId)
        .pipe(this.takeUntilDestroyed())
        .subscribe((sample) => {
          this.sample = sample;
          this.sampleId = sampleId;
          this.changeDetectorRef.detectChanges();
        });
    } else {
      this.returnSampleForm.get(this.sampleReturnField.LOCATION)?.setValue('');
    }
  }

  getLocationNodes(): void {
    this.locationTreeService
      .getLocationNodes()
      .pipe(this.takeUntilDestroyed())
      .subscribe((nodes) => {
        this.locationNodes = nodes;
        this.initializeForm();
        this.changeDetectorRef.markForCheck();
      });
  }

  resetForm(): void {
    this.returnSampleForm.reset();
  }

  validateAndReturnSample(): void {
    if (this.returnSampleForm.invalid) {
      this.returnSampleForm.markAllAsTouched();
      const validationErrorMessage = this.getFirstMessage();
      this.notificationService.notifyError(validationErrorMessage);
      return;
    }

    const model = this.returnSampleForm.getRawValue();
    const returnModel: ReturnSampleRequest = {
      sampleId: model.sampleId,
      locationId: model.locationId,
    };

    let dialogOption!: string;

    if (this.sample.movementStatus === SampleMovementStatus.CHECKIN) {
      dialogOption = `Sample is not checked out. Do you want to proceed?`;
    } else if (this.sample.movementStatus === SampleMovementStatus.RETURN) {
      dialogOption = `Sample is already returned by ${this.sample.returnByUser}. Do you want to proceed?`;
    }

    if (dialogOption) {
      this.dialog
        .open(ConfirmationPopupComponent, {
          width: '400px',
          data: {
            action: dialogOption,
            buttonName: 'Proceed',
          },
        })
        .afterClosed()
        .subscribe((data) => {
          if (data) {
            this.returnSample(returnModel);
          }
        });
    } else {
      this.returnSample(returnModel);
    }
  }

  private returnSample(returnModel: ReturnSampleRequest) {
    this.sampleService
      .returnRequest(returnModel)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_) => {
        this.notificationService.notifySuccess(`Sample has been successfully returned`);
        this.resetForm();
      });
  }

  private getFirstMessage(): string {
    const controls = {
      ...this.returnSampleForm.controls,
    };
    const controlWithError = Object.entries(controls).find(([, control]) => !!control.errors);
    const controlErrors = controlWithError?.[1].errors;

    if (controlErrors?.['required']) {
      return `${
        SAMPLE_RETURN_FORM_LABEL[controlWithError?.[0] as SampleReturnFormFields]
      } should not be empty.`;
    }

    return 'Unhandled validation error';
  }
}

@NgModule({
  declarations: [SampleReturnComponent],
  imports: [
    SampleTableSelectorPopupFieldModule,
    ReactiveFormsModule,
    TreeViewSelectorPopupFieldModule,
    CommonModule,
    CompositeSampleModule,
    SampleModule,
  ],
  exports: [SampleReturnComponent],
})
export class SampleReturnModule {}
