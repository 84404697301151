export enum PcriFormField {
  GENERAL_INFORMATION = 'generalInformation',
  STATUS = 'status',
  EXPERIMENT_DETAIL = 'experimentDetail',
}

export const PCRI_LABEL: Record<PcriFormField, string> = {
  [PcriFormField.GENERAL_INFORMATION]: 'General Information',
  [PcriFormField.STATUS]: 'Status',
  [PcriFormField.EXPERIMENT_DETAIL]: 'Experiment Information',
};
