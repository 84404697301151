import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { addError } from '@core/utils/form/add-error.util';
import { removeError } from '@core/utils/form/remove-error.util';

import { SampleIdDisplayName } from '../../../common/sample-table-selector-popup-field/models/sample-display-name.interface';

export const depthIsOutOfRangeErrorName = 'depthIsOutOfRange';

export function depthInRange(
  pathToTopValue: string[],
  pathToBottomValue: string[],
  parentSample: { value: SampleIdDisplayName | null },
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const ownTopDepthControl = control.get(pathToTopValue)!;
    const ownTopDepthValue = Number(control.get(pathToTopValue)!.value?.fieldValue);
    const ownBottomDepthControl = control.get(pathToBottomValue)!;
    const ownBottomDepthValue = Number(control.get(pathToBottomValue)!.value?.fieldValue);
    const controls = [ownTopDepthControl, ownBottomDepthControl];
    const isErrorApplied = controls.some((c) => !!c.errors?.[depthIsOutOfRangeErrorName]);

    let parentSampleDepthTopValue = null;
    let parentSampleDepthBottomValue = null;

    if (parentSample.value?.depth) {
      const depthWithoutUnits = parentSample.value?.depth?.replace(/[^\d.-]/g, '');
      const depthArray = depthWithoutUnits.split('-');

      if (depthArray.length === 1) {
        parentSampleDepthTopValue = Number(depthArray[0]);
        parentSampleDepthBottomValue = Number(depthArray[0]);
      }

      if (depthArray.length === 2) {
        parentSampleDepthTopValue = Number(depthArray[0]);
        parentSampleDepthBottomValue = Number(depthArray[1]);
      }

      const isError =
        ownTopDepthValue < Number(parentSampleDepthTopValue) ||
        ownBottomDepthValue > Number(parentSampleDepthBottomValue);

      if (isError && !isErrorApplied) {
        const isOwnTopDepthValueError =
          ownTopDepthValue < Number(parentSampleDepthTopValue) ||
          ownTopDepthValue > Number(parentSampleDepthBottomValue);
        const isOwnBottomValueError =
          ownBottomDepthValue < Number(parentSampleDepthTopValue) ||
          ownBottomDepthValue > Number(parentSampleDepthBottomValue);

        if (isOwnTopDepthValueError) {
          addError(ownTopDepthControl, depthIsOutOfRangeErrorName, {
            parentTopValue: parentSampleDepthTopValue,
            parentBottomValue: parentSampleDepthBottomValue,
          });
        }

        if (isOwnBottomValueError) {
          addError(ownBottomDepthControl, depthIsOutOfRangeErrorName, {
            parentTopValue: parentSampleDepthTopValue,
            parentBottomValue: parentSampleDepthBottomValue,
          });
        }

        return null;
      }

      if (!isError && isErrorApplied) {
        controls.forEach((c) => {
          removeError(c, depthIsOutOfRangeErrorName);
        });
      }

      return null;
    }

    if (isErrorApplied) {
      controls.forEach((c) => {
        removeError(c, depthIsOutOfRangeErrorName);
      });
    }

    return null;
  };
}
