import {
  ErrorMessageConfig,
  MAX_LENGTH_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
} from '@core/utils/form/error-message.config';

export const SAMPLE_CHECK_OUT_ERROR_MESSAGE_CONFIG: ErrorMessageConfig = {
  ...REQUIRED_ERROR_MESSAGE,
  ...MAX_LENGTH_ERROR_MESSAGE,
};
