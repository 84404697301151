<mat-accordion *ngIf="sampleId">
  <mat-expansion-panel
    *ngFor="let workOrderFormGroup of existingWorkOrderFormGroupList; let workOrderIndex = index"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>{{
        workOrderFormGroup.get([
          workOrderFormField.GENERAL_INFORMATION,
          workOrderGeneralInformationFormField.PROJECT_NUMBER
        ])!.value
      }}</mat-panel-title>
      <mat-panel-description>
        {{
          existingWorkOrderList[workOrderIndex].experimentDetail.experimentName
        }}</mat-panel-description
      >
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <app-work-order-form
        #appWorkOrderForm
        [workOrderFormGroup]="workOrderFormGroup"
        [vendorNameList]="vendorNameList"
        [experimentTypeList]="experimentTypeList"
        [defaultExperimentFields]="
          existingWorkOrderList[workOrderIndex].experimentDetail.experimentFields ?? []
        "
        [sampleId]="sampleId"
      ></app-work-order-form>

      <div *ngIf="isEditable" class="work-order-actions">
        <div class="form-manipulation-buttons">
          <button
            class="btn btn-primary btn-big"
            appThrottleClick
            #throttleClick="throttleClick"
            (click)="update(throttleClick, appWorkOrderForm)"
            id="existing-work-order-save-btn"
          >
            Save
          </button>
        </div>
      </div>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
