import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from '@core/constants/consts';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { PreventPasswordAutoFillingModule } from '../../../common/directives/prevent-password-auto-filling.directive';
import { AuthenticationService } from '../../../services/api/authentication.service';
import { NotificationService } from '../../../services/notification.service';
import { ChangePasswordFormField } from '../form/change-password-form-field.enum';
import { sameValue, sameValueNotAllowed } from '../form/same-value-not-allowed.validator';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordFormComponent extends Destroyable(Object) implements OnInit {
  readonly passwordMinLength = PASSWORD_MIN_LENGTH;
  readonly oldPasswordInvalidMessage = 'Wrong old password.';

  hideOldPassword = true;
  hideNewPassword = true;
  isOldPasswordInvalid = false;

  changePasswordFormGroup!: UntypedFormGroup;

  get oldPasswordFormControl(): UntypedFormControl {
    return this.changePasswordFormGroup.get(
      ChangePasswordFormField.OLD_PASSWORD,
    ) as UntypedFormControl;
  }

  get newPasswordFormControl(): UntypedFormControl {
    return this.changePasswordFormGroup.get(
      ChangePasswordFormField.NEW_PASSWORD,
    ) as UntypedFormControl;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.createChangePasswordForm();
  }

  resetChangePasswordForm(): void {
    this.changePasswordFormGroup.reset({
      [ChangePasswordFormField.OLD_PASSWORD]: '',
      [ChangePasswordFormField.NEW_PASSWORD]: '',
    });
    this.isOldPasswordInvalid = false;
    this.cd.detectChanges();
  }

  getPasswordErrorMessage(password: UntypedFormControl): string {
    if (password.hasError('required')) {
      return 'You must enter a value';
    }

    if (password.hasError('minlength')) {
      return `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`;
    }

    if (password.hasError('pattern')) {
      return 'Password must have at least one digit (0-9)';
    }

    if (password.hasError(sameValue)) {
      return 'New password cannot be the same as old password';
    }

    if (password.hasError('oldPasswordInvalid')) {
      return this.oldPasswordInvalidMessage;
    }

    return '';
  }

  changePassword(): void {
    this.isOldPasswordInvalid = false;

    this.changePasswordFormGroup.controls[
      ChangePasswordFormField.OLD_PASSWORD
    ].updateValueAndValidity();

    if (this.changePasswordFormGroup.invalid) {
      this.changePasswordFormGroup.markAllAsTouched();
      this.cd.detectChanges();
      this.notificationService.notifyError('Change password form is not valid');

      return;
    }

    this.authenticationService
      .changePassword({
        currentPassword: this.oldPasswordFormControl.value,
        newPassword: this.newPasswordFormControl.value,
      })
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (_) => {
          this.notificationService.notifySuccess('Password has been successfully changed');
        },
        error: (error) => {
          this.isOldPasswordInvalid = true;
          this.changePasswordFormGroup.controls[
            ChangePasswordFormField.OLD_PASSWORD
          ].updateValueAndValidity();
          this.cd.detectChanges();
        },
      });
  }

  private oldPasswordInvalidValidation(__: boolean): ValidatorFn {
    return (_: AbstractControl): ValidationErrors | null => {
      return this.isOldPasswordInvalid ? { oldPasswordInvalid: true } : null;
    };
  }

  private createChangePasswordForm(): void {
    this.changePasswordFormGroup = new UntypedFormGroup(
      {
        [ChangePasswordFormField.OLD_PASSWORD]: new UntypedFormControl('', {
          validators: [
            Validators.minLength(PASSWORD_MIN_LENGTH),
            Validators.pattern(PASSWORD_REGEX),
            Validators.required,
            this.oldPasswordInvalidValidation(this.isOldPasswordInvalid).bind(this),
          ],
        }),
        [ChangePasswordFormField.NEW_PASSWORD]: new UntypedFormControl('', [
          Validators.minLength(PASSWORD_MIN_LENGTH),
          Validators.pattern(PASSWORD_REGEX),
          Validators.required,
        ]),
      },
      {
        validators: sameValueNotAllowed([
          ChangePasswordFormField.OLD_PASSWORD,
          ChangePasswordFormField.NEW_PASSWORD,
        ]),
      },
    );
  }
}

@NgModule({
  declarations: [ChangePasswordFormComponent],
  exports: [ChangePasswordFormComponent],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    CommonModule,
    PreventPasswordAutoFillingModule,
    MatButtonModule,
  ],
})
export class ChangePasswordFormModule {}
