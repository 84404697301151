import { Observable, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { UserInfo } from '@core/models/user-info.model';

import { UserService } from '../../services/api/user.service';
import { UserNotificationService } from '../../services/api/user-notification.service';
import { UserManagementService } from '../../services/user-management.service';

@Injectable({
  providedIn: 'root',
})
export class AppLevelInfoResolver implements Resolve<UserInfo> {
  constructor(
    private userService: UserService,
    private userManagementService: UserManagementService,
    private userNotificationService: UserNotificationService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserInfo> {
    return this.userService.getCurrentUserInfo().pipe(
      tap((userInfo) => {
        this.userManagementService.addUserInfo(userInfo);
        this.userNotificationService.getNotifications();
      }),
    );
  }
}
