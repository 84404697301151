import * as d3 from 'd3';
import { OrgChart } from 'd3-org-chart';
import { concatMap, debounceTime, filter, forkJoin, from, fromEvent, switchMap, tap } from 'rxjs';

import { DatePipe, DOCUMENT, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { DEFAULT_DEBOUNCE_TIME } from '@core/constants/consts';
import { IdName } from '@core/models/id-name.model';
import { WINDOW } from '@core/tokens/window.token';
import { extractDepthValueFromGetById } from '@core/utils/common/extract-depth-value-from-get-by-id.util.';
import { ErrorMessageConfig, REQUIRED_ERROR_MESSAGE } from '@core/utils/form/error-message.config';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { MultiSelectModule } from '../../common/multi-select/multi-select.component';
import { SampleTableSelectorPopupFieldModule } from '../../common/sample-table-selector-popup-field/sample-table-selector-popup-field.component';
import { SampleService } from '../../services/api/sample.service';
import { NotificationService } from '../../services/notification.service';
import { Pcri, PcriImpl } from '../pcri-form/models/pcri.model';
import { PcriFormShapeImpl } from '../pcri-form/models/pcri-form-shape.model';
import { SampleGetById } from '../sample/models/sample-get-by-id.model';
import {
  NewExperimentType,
  newExperimentTypeFields,
} from '../work-order/mocks/experiment-type-temporary.mock';
import { ExperimentField } from '../work-order/models/experiment-field.model';
import { WorkOrder } from '../work-order/models/work-order.model';
import { ExperimentFieldService } from '../work-order/services/experiment-field.service';
import { ExperimentTypeService } from '../work-order/services/experiment-type.service';
import { VendorNameService } from '../work-order/services/vendor-name.service';
import { WorkOrderService } from '../work-order/services/work-order.service';
import { CoreAnalysisWizardEnum } from './core-analysis-wizard.enum';
import { CORE_ANAIYSIS_WIZARD_MICP_LABEL } from './core-analysis-wizard-labels';
import {
  CoreAnalysisWizardMicpNodeMock,
  CoreAnalysisWizardPcRiNodeMock,
  CoreAnalysisWizardPTSDNodeMock,
  SimulationTypeMocks,
} from './mocks/simulation-type.mocks';
import { CoreAnalysisWizardNode } from './models/core-analysis-wizard-node.model';
import { WorkOrderPopupData } from './models/work-order-popup-data.model';
import { WorkFlowPopupMicpComponent } from './work-flow-popup-micp/work-flow-popup-micp.component';
import { WorkFlowPopupPcriComponent } from './work-flow-popup-pcri/work-flow-popup-pcri.component';
import { WorkOrderPopupComponent } from './work-order-popup/work-order-popup.component';

@Component({
  selector: 'app-core-analysis-wizard',
  templateUrl: './core-analysis-wizard.component.html',
  styleUrls: ['./core-analysis-wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreAnalysisWizardComponent extends Destroyable(Object) implements OnInit {
  @ViewChild('chartContainer') chartContainer!: ElementRef;
  @ViewChild('nodebutton') nodebutton!: ElementRef;

  readonly sampleControlDisplayName = 'Sample';
  readonly simulationLabel = 'Workflow';
  readonly sampleControlErrorMessageConfig: ErrorMessageConfig = {
    ...REQUIRED_ERROR_MESSAGE,
  };

  sampleIdFormControl = new UntypedFormControl(null);
  treeNodes: CoreAnalysisWizardNode[] = [];
  simulationType = SimulationTypeMocks;
  workFlowFormControl = new UntypedFormControl(null);
  pcriEditBtnListeners: HTMLElement[] = [];
  micpEditBtnListeners: HTMLElement[] = [];
  coreAnalysisWizardPcRi: Pcri[] = [];
  showSpinner = false;
  saveBtnDisable = true;

  private orgChart: OrgChart<CoreAnalysisWizardNode> | null = null;
  private compactIndex = 0;
  private vendorNames: IdName[] = [];
  private experimentTypes: IdName[] = [];
  private workOrders: WorkOrder[] = [];
  private editBtnListeners: HTMLElement[] = [];
  private sampleData!: SampleGetById;
  private workOrdersData!: WorkOrder[];
  private nextPreviousIndex!: number | null;

  constructor(
    private sampleService: SampleService,
    private workOrderService: WorkOrderService,
    private cd: ChangeDetectorRef,
    private experimentTypeService: ExperimentTypeService,
    private experimentFieldService: ExperimentFieldService,
    private vendorNameService: VendorNameService,
    private datePipe: DatePipe,
    private matDialog: MatDialog,
    private notificationService: NotificationService,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getExperimentTypesAndListenToSampleChangesAndBuildTree();
    this.getOptionsForWorkOrderSelectors();
    this.fixResizing();
    this.workFlowFormControl.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((_) => {
      this.updateWorkFlow();
    });
  }

  compact(): void {
    this.orgChart!.compact(!!((this.compactIndex += 1) % 2))
      .render()
      .fit();
  }

  fit(): void {
    this.orgChart!.fit();
  }

  exportFullImage(): void {
    this.orgChart!.exportImg({ full: true });
  }

  expandAll(): void {
    this.orgChart!.expandAll();
  }

  collapseAll(): void {
    this.orgChart!.collapseAll();
  }

  cancelWorkFlow(): void {
    this.workFlowFormControl.setValue(null);
    this.updateWorkFlow();
  }

  updateWorkFlow(): void {
    if (this.sampleIdFormControl.value!) {
      this.switchTreeAndListChart(this.sampleData, this.workOrdersData);
    }
  }

  private switchTreeAndListChart(sample: SampleGetById, workOrders: WorkOrder[]): void {
    this.clearChartData();
    const selectedIndex = this.getWorkflowSelectedIndex(this.workFlowFormControl.value);
    if (selectedIndex === 0) {
      this.drawTree(sample, workOrders);
    } else if (selectedIndex) {
      this.drawList(sample);
    } else this.workFlowFormControl.setValue(this.simulationType[0].id);
    this.cd.markForCheck();
  }

  private drawList(sample: SampleGetById): void {
    this.showSpinner = true;
    this.buildListTree(sample);
    this.createListChart(this.treeNodes);
    this.expandAll();
    this.fit();
  }

  getSampleNode(sample: SampleGetById): CoreAnalysisWizardNode {
    return {
      [CoreAnalysisWizardEnum.ID]: sample.id,
      [CoreAnalysisWizardEnum.NAME]: sample.name,
      [CoreAnalysisWizardEnum.PARENT_ID]: null,
      [CoreAnalysisWizardEnum.TYPE]: sample.type,
      [CoreAnalysisWizardEnum.WELLNAME]: sample.wellInformation?.name ?? '-',
      [CoreAnalysisWizardEnum.DEPTH]: extractDepthValueFromGetById(sample),
    };
  }

  getWorkflowSelectedIndex(value: string): number | null {
    const index = this.simulationType.findIndex((v) => v.id === value);
    return index < 0 ? null : index;
  }

  private buildListTree(sample: SampleGetById): void {
    const selectedIndex = this.getWorkflowSelectedIndex(this.workFlowFormControl.value);

    const sampleNode = this.getSampleNode(sample);

    let parentId = sampleNode.id;
    let coreAnalysisWizardNodeData;
    if (selectedIndex === 3) {
      this.coreAnalysisWizardPcRi = JSON.parse(JSON.stringify(CoreAnalysisWizardPcRiNodeMock));
      coreAnalysisWizardNodeData = this.coreAnalysisWizardPcRi.map((d, _) => {
        let coreAnalysisNode: CoreAnalysisWizardNode = {
          id: parentId,
        };
        coreAnalysisNode = Object.entries(d).reduce((acc, [key, value]) => {
          acc[key as keyof CoreAnalysisWizardNode] = value;
          return acc;
        }, coreAnalysisNode);
        return coreAnalysisNode;
      });
    } else if (selectedIndex === 1) {
      coreAnalysisWizardNodeData = [...CoreAnalysisWizardMicpNodeMock];
    } else coreAnalysisWizardNodeData = [...CoreAnalysisWizardPTSDNodeMock];

    coreAnalysisWizardNodeData.map((d, index) => {
      d.id = d.id || index.toFixed();
      d.parentId = parentId;
      parentId = d.id;
      return d;
    });
    this.treeNodes = [sampleNode, ...coreAnalysisWizardNodeData];
  }

  createListChart(list: CoreAnalysisWizardNode[]): void {
    if (!this.orgChart) {
      this.orgChart = new OrgChart<CoreAnalysisWizardNode>();
    }
    this.orgChart
      ?.container(this.chartContainer.nativeElement)
      .layout('left')
      .svgWidth(this.chartContainer.nativeElement.offsetWidth)
      .svgHeight(this.chartContainer.nativeElement.offsetHeight)
      .data(list)
      .nodeHeight((_) => 170)
      .nodeWidth((_) => 200)
      .childrenMargin((d) => 90)
      .compactMarginBetween((d) => 65)
      .compactMarginPair((d) => 100)
      .neightbourMargin((a, b) => 50)
      .siblingsMargin((d) => 100)
      .nodeContent((node: any, index, _2, _3) => {
        const headerHeight = '45px';
        const nodeActionButtonWidth = '25px';
        const borderRadius = 5;
        const borderWidth = 1;
        const borderRadiusRight = `${borderRadius - borderWidth}px`;
        const fontUrl = 'fa-regular-400.ttf';
        const dom = Object.keys(node.data).reduce((acc, key) => {
          if (CORE_ANAIYSIS_WIZARD_MICP_LABEL[key]! && key !== CoreAnalysisWizardEnum.NAME) {
            return `${acc}<span class="node-body-text">${CORE_ANAIYSIS_WIZARD_MICP_LABEL[key]}: ${node.data[key]}</span>`;
          }
          return acc;
        }, '');

        setTimeout(() => {
          const selectedIndex = this.getWorkflowSelectedIndex(this.workFlowFormControl.value);
          if (selectedIndex === 3) {
            this.addEditBtnEventListenersPcri(node.id, index);
          } else this.addEditBtnEventListenersMicp(node.id, index);
        });

        return `
        <!DOCTYPE html>
        <html>
        <head>
          <style>
          @font-face {
            font-family: 'Font Awesome 6 Free';
            font-style: normal;
            font-weight: 400;
            font-display: block;
            src: url(${fontUrl}) format("truetype");
          }

          .far, .fa-regular {
            font-family: FontAwesome;
            font-size: 14px;
            font-weight: 400;
          }

          .fa-info-circle{
           font-size: 24px;
          }


          .fa-info-circle::before {
            content: "\\f05a";
          }

          .node-container {
            display: flex;
            width: 100%;
            height: 100%;
            border: ${borderWidth}px solid rgba(0, 0, 0, 0.12);
            border-radius: ${borderRadius}px;
          }

          .node-content {
            width: calc(100% - ${nodeActionButtonWidth})
          }

          .node-header {
            display: flex;
            align-items: center;
            width: 100%;
            height: ${headerHeight};
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          }

          .node-header-text {
            margin-left: 10px;
            color: rgba(0, 0, 0, 0.6);
            width: 70%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .node-body {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: space-evenly;
            width: 100%;
            height: calc(100% - 45px);
          }

          .node-body-text {
            margin-left: 5px;
            color: rgba(0, 0, 0, 0.6);
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            max-width: 170px;
          }

          .node-action-buttons {
            display: flex;
            flex-direction: column;
            width: ${nodeActionButtonWidth};
            border-radius: 0 ${borderRadius}px ${borderRadius}px 0;
            font-size: 22px;
            color: #fafafa;
          }

          .node-action-buttons__item {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--primary-color-seven);
            background-clip: padding-box;
          }

          .node-action-buttons__item:first-of-type {
            border-top-right-radius: ${borderRadiusRight};
          }

          .node-action-buttons__item:last-of-type {
            border-bottom-right-radius: ${borderRadiusRight};
          }

          .node-action-buttons__item:not(:last-of-type) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          }

          .node-action-buttons__item:not(.disabled):hover {
            opacity: 0.9;
          }

          .node-action-buttons__item.disabled {
            background-color: rgba(0, 0, 0, 0.28);
            cursor: default;
          }

        </style>
      </head>

      <body>
        <div class="node-container">
          <!-- NODE CONTENT -->
          <div class="node-content">
              <!-- NODE HEADER -->
              <div class="node-header">
                <span class="node-header-text">${
                  node.data.title ? node.data.title : node.data.name
                }</span> <i class="fa fa-info-circle"></i>
              </div>
                <!-- NODE BODY -->
               <div class="node-body">
                    ${dom}
              </div>

          </div>
           <!-- NODE ACTION BUTTON -->
          <div class="node-action-buttons">
            ${
              node.data.parentId
                ? `
                  <div #nodebutton id="node-edit-btn-${node.id}" class="node-action-buttons__item"><i class="far fa-pen-to-square"></i></div>
                  <div class="node-action-buttons__item disabled"><span>+</span></div>
                `
                : `<div id="node-edit-btn-${node.id}" class="node-action-buttons__item disabled"><span>+</span></div>`
            }
          </div>
        </div>
      </body>
      </html>
        `;
      })
      .buttonContent(({ node, state: _ }) => {
        return `
        <style>
          .button-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          }

          .expand-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% - 15px);
            height: calc(100% - 15px);
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-radius: 50%;
            background-color: #fafafa;
          }
        </style>
        <div class='button-container'>
          <div class='expand-button'><span>${node.children ? '-' : '+'}</span><span>${
          (node.data as any)._directSubordinates
        }</span>
          </div>
        </div>
        `;
      })
      .render();
  }

  private addEditBtnEventListenersMicp(id: string, index: number): void {
    const editBtn = this.document.getElementById(`node-edit-btn-${id}`);

    if (editBtn && !this.editBtnListeners.includes(editBtn) && index > 0) {
      this.micpEditBtnListeners.push(editBtn);
      this.editBtnListeners.push(editBtn as HTMLElement);
      this.eventHandlerMicp(editBtn, index);
      editBtn.addEventListener('mousedown', (e) => {
        e.stopPropagation();
      });
      this.showSpinner = false;
    }
  }

  private eventHandlerMicp(editBtn: HTMLElement, index: number): void {
    editBtn.addEventListener('click', (_) => {
      this.matDialog
        .open(WorkFlowPopupMicpComponent, {
          data: {
            sampleId: this.sampleIdFormControl.value,
            editBtnListeners: this.micpEditBtnListeners,
            isPtsd: this.getWorkflowSelectedIndex(this.workFlowFormControl.value) === 2,
          },
          width: '80%',
          height: '90vh',
          autoFocus: false,
        })
        .afterClosed()
        .pipe(
          filter((dataWorkFlow) => {
            if (dataWorkFlow === null) {
              this.nextPreviousIndex = null;
            }
            return !!dataWorkFlow;
          }),
          this.takeUntilDestroyed(),
        )
        .subscribe((updatedWorkFlow) => {
          this.treeNodes[index].numberOfGPU = updatedWorkFlow.data.simulationSettings.numberOfGpus;
          this.micpEditBtnListeners = [];
          this.orgChart?.data(this.treeNodes).updateNodesState();
          const notificationMessage = 'Micp Work flow updated';
          this.notificationService.notifySuccess(notificationMessage);
        });
    });
  }

  private addEditBtnEventListenersPcri(id: string, index: number): void {
    const editBtn = this.document.getElementById(`node-edit-btn-${id}`);
    if (editBtn && !this.editBtnListeners.includes(editBtn)) {
      this.pcriEditBtnListeners.push(editBtn);
      this.editBtnListeners.push(editBtn as HTMLElement);
      editBtn.addEventListener('click', (_) => {
        this.matDialog
          .open(WorkFlowPopupPcriComponent, {
            data: {
              sampleId: this.sampleData.id as string,
              formIndex: this.nextPreviousIndex ? this.nextPreviousIndex : index,
              formData: this.coreAnalysisWizardPcRi[index - 1],
              editBtnListeners: this.pcriEditBtnListeners,
            },
            width: '800px',
            autoFocus: false,
          })
          .afterClosed()
          .pipe(
            filter((dataWorkFlow) => {
              if (dataWorkFlow === null) {
                this.nextPreviousIndex = null;
              }
              return !!dataWorkFlow;
            }),
            this.takeUntilDestroyed(),
          )
          .subscribe((updatedWorkFlow) => {
            this.nextPreviousIndex = updatedWorkFlow.nextPreviousIndex;
            this.saveBtnDisable = !updatedWorkFlow.data.name;
            this.cd.detectChanges();
            this.loadPcriExperimentdata(
              updatedWorkFlow.experimentTypeListPcri,
              updatedWorkFlow.experimentNames,
            );
            if (
              updatedWorkFlow.data &&
              this.nextPreviousIndex! < this.pcriEditBtnListeners.length
            ) {
              this.pcriEditBtnListeners[this.nextPreviousIndex!].click();
              this.updatePcriWorkFlow(updatedWorkFlow.data, this.nextPreviousIndex! - 1, false);
            } else if (updatedWorkFlow.data) {
              this.updatePcriWorkFlow(updatedWorkFlow.data, this.nextPreviousIndex! - 1, true);
              this.nextPreviousIndex = null;
            } else this.pcriEditBtnListeners[this.nextPreviousIndex!].click();
          });
      });
      editBtn.addEventListener('mousedown', (e) => {
        e.stopPropagation();
      });
      this.showSpinner = false;
    }
  }

  saveWorkFlow(): void {
    let index = 0;
    this.showSpinner = true;
    from(this.coreAnalysisWizardPcRi)
      .pipe(
        concatMap((data) => {
          const payload = new PcriFormShapeImpl(data);
          const workOrderData = new PcriImpl({
            ...payload,
            experimentFields: data.experimentFields!,
            sampleId: this.sampleData.id,
          });
          workOrderData.parentWorkOrderId = data.parentWorkOrderId;
          return this.workOrderService.create(workOrderData as WorkOrder);
        }),
      )
      .subscribe({
        next: (res) => {
          index += 1;
          if (this.coreAnalysisWizardPcRi[index])
            this.coreAnalysisWizardPcRi[index]['parentWorkOrderId'] = res.id;
        },
        error: () => {
          this.showSpinner = false;
          this.cd.detectChanges();
        },
        complete: () => {
          const notificationMessage = 'PCRI Work flow updated';
          this.showSpinner = false;
          this.saveBtnDisable = true;
          this.getWorkOrder(true);
          this.cd.detectChanges();
          this.notificationService.notifySuccess(notificationMessage);
        },
      });
  }

  private getWorkOrder(reDraw: boolean = false): void {
    this.workOrderService
      .getAllBy(this.sampleData.id)
      .pipe(this.takeUntilDestroyed())
      .subscribe((workOrders) => {
        this.workOrdersData = workOrders;
        if (reDraw) this.cancelWorkFlow();
      });
  }

  private updatePcriWorkFlow(updatedWorkFlow: Pcri, index: number, redraw: boolean): void {
    Object.entries(this.coreAnalysisWizardPcRi[index - 1]).forEach(([key, value], _) => {
      const val = updatedWorkFlow[key as keyof Pcri];
      this.coreAnalysisWizardPcRi[index - 1][key as keyof Pcri] = val as typeof value;
      if (key !== 'parentId' && key !== 'title' && key !== 'id') {
        this.treeNodes[index][key as keyof CoreAnalysisWizardNode] = val as typeof value;
        if (key === 'experimentFields') {
          const experimentFields = val as Array<ExperimentField>;
          for (let i = 0; i < experimentFields!.length; i += 1) {
            const fieldName = (experimentFields[i].fieldName.charAt(0).toLowerCase() +
              experimentFields[i].fieldName.slice(1))!;
            if (fieldName in this.treeNodes[index])
              this.treeNodes[index][fieldName as keyof CoreAnalysisWizardNode] = experimentFields[i]
                ?.fieldValue as typeof value;
          }
        }
      }
    });
    this.coreAnalysisWizardPcRi.map((d, _) => {
      d.name = this.coreAnalysisWizardPcRi[index - 1].name;
      return d;
    });
    this.pcriEditBtnListeners = [];
    this.orgChart?.data(this.treeNodes).updateNodesState();
  }

  private loadPcriExperimentdata(
    experimentTypeListPcri: IdName[],
    experimentNames: string[],
  ): void {
    if (this.coreAnalysisWizardPcRi[0].experimentTypeId) {
      return;
    }
    const experimentTypeListPcriName = experimentTypeListPcri.map(({ name }) => name);
    experimentNames.forEach((d: string, i) => {
      const index = experimentTypeListPcriName.findIndex((data) => data === d);
      this.coreAnalysisWizardPcRi[i].experimentTypeId = experimentTypeListPcri[index].id;
      this.experimentFieldService
        .getListBy(experimentTypeListPcri[index].id)
        .subscribe((experimentFields) => {
          if (!this.coreAnalysisWizardPcRi[i].experimentFields!.length)
            this.coreAnalysisWizardPcRi[i].experimentFields = experimentFields;
        });
    });
  }

  private drawTree(sample: SampleGetById, workOrders: WorkOrder[]): void {
    this.workOrders = this.fixWorkOrderExperimentFields(workOrders);
    this.buildTree(sample, workOrders);
    this.createTreeChart(this.treeNodes);
  }

  private getExperimentTypesAndListenToSampleChangesAndBuildTree(): void {
    this.experimentTypeService
      .getAll()
      .pipe(
        tap((experimentTypes) => {
          this.experimentTypes = experimentTypes;
          this.listenToSampleChangesAndBuildTree();
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe();
  }

  private listenToSampleChangesAndBuildTree(): void {
    this.sampleIdFormControl.valueChanges
      .pipe(
        tap((id) => {
          this.showSpinner = true;
          if (!id) {
            this.clearChartData();
            this.showSpinner = false;
          }
        }),
        filter((id) => !!id),
        switchMap((id) =>
          forkJoin([this.sampleService.getById(id), this.workOrderService.getAllBy(id)]),
        ),
        this.takeUntilDestroyed(),
      )
      .subscribe({
        next: ([sample, workOrders]) => {
          this.sampleData = sample;
          this.workOrdersData = workOrders;
          this.switchTreeAndListChart(sample, workOrders);
          this.showSpinner = false;
        },
        error: () => {
          this.showSpinner = false;
        },
      });
  }

  private buildTree(sample: SampleGetById, workOrders: WorkOrder[]): void {
    const workOrderNodes = workOrders.map((workOrder) => {
      const experimentTypeName = workOrder.experimentTypeId
        ? this.experimentTypes.find(
            (experimentType) => experimentType.id === workOrder.experimentTypeId,
          )!.name
        : '-';
      const progressDisplayValue =
        !workOrder.progress && workOrder.progress !== 0 ? '-' : `${workOrder.progress}%`;

      const workOrderCoreAnalysisNode: CoreAnalysisWizardNode = {
        id: workOrder.id!,
        name: workOrder.name,
        parentId: workOrder.parentWorkOrderId || sample.id,
        type: experimentTypeName,
        expectedDate: workOrder.expectDate
          ? (this.datePipe.transform(workOrder.expectDate, 'M/d/yyyy') as string)
          : '-',
        progress: progressDisplayValue,
      };

      return workOrderCoreAnalysisNode;
    });
    const sampleNode = this.getSampleNode(sample);
    this.treeNodes = [sampleNode, ...workOrderNodes];
  }

  private createTreeChart(tree: CoreAnalysisWizardNode[]): void {
    if (!this.orgChart) {
      this.orgChart = new OrgChart<CoreAnalysisWizardNode>();
    }

    this.orgChart
      .container(this.chartContainer.nativeElement)
      .svgHeight(this.chartContainer.nativeElement.offsetHeight)
      .svgWidth(this.chartContainer.nativeElement.offsetWidth)
      .data(tree)
      .nodeHeight((_) => 120)
      .nodeWidth((_) => 200)
      .childrenMargin((_) => 50)
      .compactMarginBetween((_) => 25)
      .compactMarginPair((_) => 50)
      .neightbourMargin((_1, _2) => 25)
      .siblingsMargin((_) => 25)
      .nodeContent((node, _1, _2, _3) => {
        const headerHeight = '45px';
        const nodeActionButtonWidth = '25px';
        const borderRadius = 5;
        const borderWidth = 1;
        const borderRadiusRight = `${borderRadius - borderWidth}px`;
        const fontUrl = 'fa-regular-400.ttf';

        setTimeout(() => {
          this.addEditBtnEventListeners();
        });

        return `
        <!DOCTYPE html>
        <html>
        <head>
          <style>
          @font-face {
            font-family: 'Font Awesome 6 Free';
            font-style: normal;
            font-weight: 400;
            font-display: block;
            src: url(${fontUrl}) format("truetype");
          }

          .far, .fa-regular {
            font-family: 'Font Awesome 6 Free';
            font-size: 14px;
            font-weight: 400;
          }

          .fa-pen-to-square::before {
            content: "\\f044";
          }

          .node-container {
            display: flex;
            width: 100%;
            height: 100%;
            border: ${borderWidth}px solid rgba(0, 0, 0, 0.12);
            border-radius: ${borderRadius}px;
          }

          .node-content {
            width: calc(100% - ${nodeActionButtonWidth})
          }

          .node-action-buttons {
            display: flex;
            flex-direction: column;
            width: ${nodeActionButtonWidth};
            border-radius: 0 ${borderRadius}px ${borderRadius}px 0;
            font-size: 22px;
            color: #fafafa;
          }

          .node-action-buttons__item {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--primary-color-seven);
            background-clip: padding-box;
          }

          .node-action-buttons__item:first-of-type {
            border-top-right-radius: ${borderRadiusRight};
          }

          .node-action-buttons__item:last-of-type {
            border-bottom-right-radius: ${borderRadiusRight};
          }

          .node-action-buttons__item:not(:last-of-type) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          }

          .node-action-buttons__item:not(.disabled):hover {
            opacity: 0.9;
          }

          .node-action-buttons__item.disabled {
            background-color: rgba(0, 0, 0, 0.28);
            cursor: default;
          }

          .node-header {
            display: flex;
            align-items: center;
            width: 100%;
            height: ${headerHeight};
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          }

          .node-header-text {
            margin-left: 10px;
            color: rgba(0, 0, 0, 0.6);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          .node-body {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: space-evenly;
            width: 100%;
            height: calc(100% - 45px);
          }

          .node-body-text {
            margin-left: 5px;
            color: rgba(0, 0, 0, 0.6);
          }
        </style>
      </head>

      <body>
        <div class="node-container">
          <!-- NODE CONTENT -->
          <div class="node-content">
              <!-- NODE HEADER -->
              <div class="node-header">
                <span class="node-header-text">${node.data.name}</span>
              </div>

              <!-- NODE BODY -->
              ${
                node.data.parentId
                  ? `
              <div class="node-body">
                <span class="node-body-text">Type: ${node.data.type}</span>
                <span class="node-body-text">Expected date: ${node.data.expectedDate}</span>
                <span class="node-body-text">Progress: ${node.data.progress}</span>
              </div>
              `
                  : `
              <div class="node-body">
                <span class="node-body-text">Type: ${node.data.type}</span>
                <span class="node-body-text">Well: ${node.data.wellName}</span>
                <span class="node-body-text">Depth: ${node.data.depth}</span>
              </div>
              `
              }
          </div>

          <!-- NODE ACTION BUTTON -->
          <div class="node-action-buttons">
            ${
              node.data.parentId
                ? `
                  <div id="node-edit-btn-${node.id}" class="node-action-buttons__item"><i class="far fa-pen-to-square"></i></div>
                  <div class="node-action-buttons__item disabled"><span>+</span></div>
                `
                : `<div class="node-action-buttons__item disabled"><span>+</span></div>`
            }
          </div>
        </div>
      </body>
      </html>
        `;
      })
      .buttonContent(({ node, state: _ }) => {
        return `
        <style>
          .button-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          }

          .expand-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(100% - 15px);
            height: calc(100% - 15px);
            border: 1px solid rgba(0, 0, 0, 0.12);
            border-radius: 50%;
            background-color: #fafafa;
          }
        </style>
        <div class='button-container'>
          <div class='expand-button'><span>${node.children ? '-' : '+'}</span><span>${
          (node.data as any)._directSubordinates
        }</span>
          </div>
        </div>
        `;
      })
      .render();
  }

  private addEditBtnEventListeners(): void {
    this.workOrders.forEach((wo) => {
      const editBtn = this.document.getElementById(`node-edit-btn-${wo.id}`);

      if (editBtn && !this.editBtnListeners.includes(editBtn)) {
        this.editBtnListeners.push(editBtn);
        editBtn.addEventListener('click', (_) => {
          this.matDialog
            .open<WorkOrderPopupComponent, WorkOrderPopupData, WorkOrder | null>(
              WorkOrderPopupComponent,
              {
                data: {
                  sampleId: wo.sampleId as string,
                  vendorNames: this.vendorNames,
                  experimentTypes: this.experimentTypes,
                  workOrder: wo,
                },
                width: '800px',
                autoFocus: false,
              },
            )
            .afterClosed()
            .pipe(
              filter((edited): edited is WorkOrder => !!edited),
              this.takeUntilDestroyed(),
            )
            .subscribe((updatedWorkOrder) => {
              this.updateWorkOrders(updatedWorkOrder);
              this.updateTreeNodes(updatedWorkOrder);
              const notificationMessage = 'Work order updated';
              this.notificationService.notifySuccess(notificationMessage);
            });
        });

        editBtn.addEventListener('mousedown', (e) => {
          e.stopPropagation();
        });
      }
    });
  }

  private updateWorkOrders(updatedWorkOrder: WorkOrder): void {
    this.getWorkOrder();
    const editedWoIndex = this.workOrders.findIndex(
      (workOrder) => workOrder.id === updatedWorkOrder.id,
    )!;
    const { parentWorkOrderId } = this.workOrders[editedWoIndex];
    this.workOrders[editedWoIndex] = updatedWorkOrder;
    this.workOrders[editedWoIndex].parentWorkOrderId = parentWorkOrderId;
  }

  private updateTreeNodes(updatedWorkOrder: WorkOrder): void {
    const experimentTypeName = updatedWorkOrder!.experimentTypeId
      ? this.experimentTypes.find(
          (experimentType) => experimentType.id === updatedWorkOrder!.experimentTypeId,
        )!.name
      : '-';
    const progressDisplayValue =
      !updatedWorkOrder!.progress && updatedWorkOrder!.progress !== 0
        ? '-'
        : `${updatedWorkOrder!.progress}%`;
    const editedWoTreeIndex = this.treeNodes.findIndex(
      (treeNode) => treeNode.id === updatedWorkOrder.id,
    )!;

    this.treeNodes[editedWoTreeIndex] = {
      id: updatedWorkOrder!.id!,
      name: updatedWorkOrder!.name,
      parentId: updatedWorkOrder!.parentWorkOrderId || updatedWorkOrder!.sampleId!,
      type: experimentTypeName,
      expectedDate: updatedWorkOrder!.expectDate
        ? (this.datePipe.transform(updatedWorkOrder!.expectDate, 'M/d/yyyy') as string)
        : '-',
      progress: progressDisplayValue,
    };
    this.orgChart?.data(this.treeNodes).updateNodesState();
  }

  private getOptionsForWorkOrderSelectors(): void {
    this.vendorNameService
      .getAll()
      .pipe(this.takeUntilDestroyed())
      .subscribe((vendorNames) => {
        this.vendorNames = vendorNames;
      });
  }

  private clearChartData(): void {
    this.treeNodes = [];
    this.chartContainer.nativeElement.textContent = '';
    this.orgChart = null;
  }

  private fixResizing(): void {
    fromEvent(this.window, 'resize')
      .pipe(
        filter(() => !!this.orgChart),
        tap(() => {
          d3.select('.svg-chart-container').attr('style', 'display: none');
        }),
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        this.takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.orgChart!.svgHeight(this.chartContainer.nativeElement.offsetHeight).svgWidth(
          this.chartContainer.nativeElement.offsetWidth,
        );

        d3.select('.svg-chart-container')
          .attr('height', this.chartContainer.nativeElement.offsetHeight)
          .attr('style', 'display: inline');

        this.fit();
      });
  }

  private fixWorkOrderExperimentFields(workOrders: WorkOrder[]): WorkOrder[] {
    return workOrders.map((workOrder) => {
      const { experimentTypeId } = workOrder;

      if (!experimentTypeId) {
        return workOrder;
      }

      const experimentType = this.experimentTypes.find(
        (expType) => expType.id === experimentTypeId,
      )!;

      const isNewExperimentType = Object.values(NewExperimentType).includes(
        experimentType.name as NewExperimentType,
      );

      if (isNewExperimentType) {
        return {
          ...workOrder,
          experimentFields: [...newExperimentTypeFields],
        } as WorkOrder;
      }

      return workOrder;
    });
  }
}

@NgModule({
  declarations: [CoreAnalysisWizardComponent],
  exports: [CoreAnalysisWizardComponent],
  imports: [
    SampleTableSelectorPopupFieldModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MultiSelectModule,
    MatLegacyProgressSpinnerModule,
    MatLegacyTooltipModule,
    NgIf,
  ],
  providers: [WorkOrderService, ExperimentTypeService, VendorNameService],
})
export class CoreAnalysisWizardModule {}
