import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCommonModule } from '@angular/material/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { SampleAccessRequest } from 'src/app/common/access-request-popup/models/sample-access-request.model';

@Component({
  selector: 'app-access-request-popup',
  templateUrl: './access-request-popup.component.html',
  styleUrls: ['./access-request-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessRequestPopupComponent {
  readonly descriptionLimitLength = 128;

  constructor(
    public dialogRef: MatDialogRef<AccessRequestPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SampleAccessRequest,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@NgModule({
  imports: [CommonModule, MatInputModule, MatCommonModule, MatDialogModule, FormsModule],
  declarations: [AccessRequestPopupComponent],
  exports: [AccessRequestPopupComponent],
})
export class AccessRequestPopupModule {}
