import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';

import { IdName } from '@core/models/id-name.model';
import { WorkOrderFormField } from 'src/app/features/work-order/form/field-configs/work-order.field-config';
import { WorkOrderGeneralInformationFormField } from 'src/app/features/work-order/form/field-configs/work-order-general-information.field-config';
import { generateWorkOrderForm } from 'src/app/features/work-order/form/generate-work-order-form.util';
import { WorkOrderFormShape } from 'src/app/features/work-order/models/work-order-form-shape.model';
import {
  WorkOrderFormComponent,
  WorkOrderFormModule,
} from 'src/app/features/work-order/work-order-form/work-order-form.component';

import {
  ThrottleClickDirective,
  ThrottleClickModule,
} from '../../../common/directives/throttle-click.directive';

@Component({
  selector: 'app-existing-work-orders',
  templateUrl: './existing-work-orders.component.html',
  styleUrls: ['./existing-work-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExistingWorkOrdersComponent implements OnChanges {
  @Input() existingWorkOrderList: WorkOrderFormShape[] = [];
  @Input() vendorNameList: IdName[] = [];
  @Input() experimentTypeList: IdName[] = [];
  @Input() sampleId: string | null = null;
  @Input() isEditable = true;

  @Output() save = new EventEmitter<{
    component: WorkOrderFormComponent;
    throttleClick: ThrottleClickDirective;
  }>();

  readonly workOrderFormField = WorkOrderFormField;
  readonly workOrderGeneralInformationFormField = WorkOrderGeneralInformationFormField;
  existingWorkOrderFormGroupList: UntypedFormGroup[] = [];

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    const existingWorkOrderListChanges = simpleChanges['existingWorkOrderList'];
    if (existingWorkOrderListChanges) {
      const currentValueOfExistingWorkOrderList =
        existingWorkOrderListChanges.currentValue as WorkOrderFormShape[];
      this.existingWorkOrderFormGroupList = this.buildWorkOrderFormListBy(
        currentValueOfExistingWorkOrderList,
        this.isEditable,
      );
    }
  }

  update(throttleClick: ThrottleClickDirective, component: WorkOrderFormComponent): void {
    this.save.emit({ component, throttleClick });
  }

  private buildWorkOrderFormListBy(
    existingWorkOrderList: WorkOrderFormShape[],
    isEditable: boolean,
  ): UntypedFormGroup[] {
    return existingWorkOrderList.map((workOrder) => {
      const form = generateWorkOrderForm(this.formBuilder, workOrder);

      if (!isEditable) {
        form.disable({ emitEvent: false });
      }

      return form;
    });
  }
}

@NgModule({
  declarations: [ExistingWorkOrdersComponent],
  imports: [MatExpansionModule, CommonModule, WorkOrderFormModule, ThrottleClickModule],
  exports: [ExistingWorkOrdersComponent],
})
export class ExistingWorkOrdersModule {}
