import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { addError } from '@core/utils/form/add-error.util';
import { removeError } from '@core/utils/form/remove-error.util';

import { DigitalVolumeInfoFormField } from './digital-volume-info-form-field.field-config';

export function digitalVolumeInfoSecondStepRequiredValidator(
  requiredControlPath: string[],
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const requiredControl = control.get(requiredControlPath) as UntypedFormControl;
    const isVerified = !!control.get(DigitalVolumeInfoFormField.PROCESSED_FILE_ID)?.getRawValue();
    const isRequiredControlInvalid = isVerified ? !!Validators.required(requiredControl) : false;
    const isErrorApplied = requiredControl?.errors?.['required'];

    if (isRequiredControlInvalid && !isErrorApplied) {
      addError(requiredControl, 'required');

      return null;
    }

    if (!isRequiredControlInvalid && isErrorApplied) {
      removeError(requiredControl, 'required');
    }

    return null;
  };
}
