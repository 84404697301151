import { Directive, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[appStopPropagationOnClick]',
})
export class StopPropagationOnClickDirective {
  @HostListener('click', ['$event']) stopPropagation(e: MouseEvent): void {
    e.stopPropagation();
  }
}

@NgModule({
  declarations: [StopPropagationOnClickDirective],
  exports: [StopPropagationOnClickDirective],
})
export class StopPropagationOnClickModule {}
