<h1 class="title">Core Analysis Wizard</h1>
<div class="selection-container">
  <div class="fields-line action-items">
    <app-sample-table-selector-popup-field
      [formControl]="sampleIdFormControl"
      [label]="sampleControlDisplayName"
    ></app-sample-table-selector-popup-field>
  </div>

  <div class="fields-line action-items">
    <app-multi-select
      [label]="simulationLabel"
      [formControl]="workFlowFormControl"
      [selectOptions]="simulationType"
      id="simulation-select"
    >
    </app-multi-select>
  </div>
  <div class="fields-line action-items">
    <button class="btn btn-secondary btn-big" id="workflow-cancel-btn" (click)="cancelWorkFlow()">
      Cancel
    </button>
    <button
      class="btn btn-primary btn-big"
      id="workflow-create-btn"
      [disabled]="saveBtnDisable"
      (click)="saveWorkFlow()"
    >
      Save
    </button>
  </div>
</div>

<div class="action-buttons">
  <button
    class="btn btn-primary btn-small"
    [disabled]="!treeNodes.length"
    (click)="expandAll()"
    id="core-analysis-expand-all-btn"
  >
    <i class="fas fa-chevron-down"></i> expand all
  </button>

  <button
    class="btn btn-primary btn-small"
    [disabled]="!treeNodes.length"
    (click)="collapseAll()"
    id="core-analysis-collapse-all-btn"
  >
    <i class="fas fa-chevron-up"></i> collapse all
  </button>

  <button
    class="btn btn-primary btn-small"
    [disabled]="!treeNodes.length"
    (click)="fit()"
    id="core-analysis-fit-btn"
  >
    <i class="fas fa-sync"></i> fit
  </button>

  <button
    class="btn btn-primary btn-small"
    [disabled]="!treeNodes.length"
    (click)="compact()"
    id="core-analysis-compact-btn"
  >
    <i class="fas fa-sitemap"></i> compact
  </button>

  <button
    class="btn btn-primary btn-small"
    [disabled]="!treeNodes.length"
    (click)="exportFullImage()"
    id="core-analysis-export-img-btn"
  >
    <i class="far fa-images"></i> export full img
  </button>
</div>
<mat-spinner
  class="loading-icon"
  *ngIf="showSpinner"
  matSuffix
  [diameter]="100"
  [matTooltip]="'uploading'"
></mat-spinner>
<div class="chart-container" #chartContainer id="chartContainer"></div>
