import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { IdName } from '@core/models/id-name.model';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { Disableable } from '@core/utils/mixins/disableable.mixin';

import { CapitalizeStringPipeModule } from '../pipes/capitalize-string.pipe';

@Component({
  selector: 'app-checkbox-list-field',
  templateUrl: './checkbox-list-field.component.html',
  styleUrls: ['./checkbox-list-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxListFieldComponent
  extends Disableable(Destroyable(Object))
  implements ControlValueAccessor, OnInit
{
  @Input() set checkboxListOptions(options: IdName[]) {
    this._checkboxListOptions = options;
    this.control = new UntypedFormGroup(
      options.reduce((acc, item) => {
        return { ...acc, [item.id]: new UntypedFormControl(false) };
      }, {}),
    );
  }

  get checkboxListOptions(): IdName[] {
    return this._checkboxListOptions;
  }

  control = new UntypedFormGroup({});
  onTouched!: (value: any) => void;
  onChange!: (value: any) => void;

  private _checkboxListOptions: IdName[] = [];

  constructor(
    private ngControl: NgControl,
    private matDialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) {
    super();
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.setUpNgControl();
  }

  writeValue(val: string[]): void {
    if (!val) {
      return;
    }

    this.control.setValue(
      Object.keys(this.control.value).reduce((acc, item) => {
        return { ...acc, [item]: val.includes(item) };
      }, {}),
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.control.valueChanges.subscribe((formGroupValue) =>
      fn(
        Object.entries(formGroupValue)
          .filter(([_, val]) => val)
          .map(([key]) => key),
      ),
    );
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private setUpNgControl(): void {
    this.ngControl.control!.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
  }
}

@NgModule({
  declarations: [CheckboxListFieldComponent],
  exports: [CheckboxListFieldComponent],
  imports: [MatCheckboxModule, CommonModule, ReactiveFormsModule, CapitalizeStringPipeModule],
})
export class CheckboxListFieldModule {}
