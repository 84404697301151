import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { LocationDisplayName } from '../models/location-display-name.model';

@Pipe({
  name: 'locationDisplayName',
})
export class LocationDisplayNamePipe implements PipeTransform {
  transform(input: LocationDisplayName | null): string {
    if (!input) {
      return '';
    }

    const { name, type } = input;
    return [name, type].filter((el) => !!el).join('-');
  }
}

@NgModule({
  declarations: [LocationDisplayNamePipe],
  exports: [LocationDisplayNamePipe],
})
export class LocationDisplayNameModule {}
