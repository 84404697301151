import { DashboardWidgetOptionsPopupTab } from '../enums/dashboard-widget-options-popup-tab.enum';
import { DashboardWidgetOptionsPopupState } from '../models/dashboard-widget-options-popup-state.model';

export const INITIAL_DASHBOARD_WIDGET_OPTIONS_POPUP_STATE_MODEL: DashboardWidgetOptionsPopupState =
  {
    [DashboardWidgetOptionsPopupTab.DATE]: {
      selectedSampleTypeIds: [],
      selectedExperimentTypeIds: [],
      selectedTeamIds: [],
      selectedWellIds: [],
      selectedExperimentStatuses: [],
      selectedStartDate: '',
      selectedEndDate: '',
      selectedDisplay: 'week',
    },
    [DashboardWidgetOptionsPopupTab.TEAM]: {
      selectedSampleTypeIds: [],
      selectedExperimentTypeIds: [],
      selectedWellIds: [],
      selectedExperimentStatuses: [],
      selectedStartDate: '',
      selectedEndDate: '',
    },
    [DashboardWidgetOptionsPopupTab.TEAM_MEMBER]: {
      selectedSampleTypeIds: [],
      selectedExperimentTypeIds: [],
      selectedTeamIds: [],
      selectedWellIds: [],
      selectedExperimentStatuses: [],
      selectedStartDate: '',
      selectedEndDate: '',
    },
    activeTab: DashboardWidgetOptionsPopupTab.DATE,
  };
