import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import {
  WellTableSelectorPopupFieldComponent,
  WellTableSelectorPopupFieldModule,
} from 'src/app/common/well-table-selector-popup-field/well-table-selector-popup-field.component';

import { AsUntypedFormControlModule } from '../../../common/pipes/as-untyped-form-control.pipe';
import { WellInformationFormField } from '../form/sample-check-in-form-field.enum';
import { WELL_INFORMATION_FORM_LABEL } from '../form/sample-check-in-form-labels';

@Component({
  selector: 'app-sample-well-form',
  templateUrl: './sample-well-form.component.html',
  styleUrls: ['./sample-well-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleWellFormComponent {
  @Input() formGroup!: UntypedFormGroup;

  readonly wellInformationFormLabel = WELL_INFORMATION_FORM_LABEL;
  readonly wellInformationFormField = WellInformationFormField;

  @ViewChild(WellTableSelectorPopupFieldComponent)
  wellTableSelectorRef!: WellTableSelectorPopupFieldComponent;

  constructor(private cd: ChangeDetectorRef) {}

  markForCheck(): void {
    this.cd.markForCheck();
  }
}

@NgModule({
  declarations: [SampleWellFormComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    WellTableSelectorPopupFieldModule,
    AsUntypedFormControlModule,
  ],
  exports: [SampleWellFormComponent],
})
export class SampleWellFormModule {}
