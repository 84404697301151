<div [ngSwitch]="notificationType">
  <div *ngSwitchCase="notificationTypeEnum.SampleAccessRequest">
    <app-sample-access-request-notification
      [model]="notification"
    ></app-sample-access-request-notification>
  </div>
  <div *ngSwitchCase="notificationTypeEnum.LocationAccessRequest">
    <app-location-access-request-notification
      [model]="notification"
    ></app-location-access-request-notification>
  </div>
  <div *ngSwitchCase="notificationTypeEnum.DataAccessRequestStatus">
    <app-data-access-request-status-notification
      [model]="notification"
    ></app-data-access-request-status-notification>
  </div>
  <div *ngSwitchDefault>output2</div>
</div>
