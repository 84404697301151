export enum DigitalVolumeInfoVendorInfoFormField {
  ID = 'id',
  VENDOR_NAME = 'vendorName',
  INTERNAL_LAB_FILE_NO = 'internalLabFileNo',
  REPORT_NO = 'reportNo',
  REPORT_DATE = 'reportDate',
  DATA_ANALYSIS_QC_BY_ARAMCO = 'dataAnalysisQcByAramco',
}

export const DIGITAL_VOLUME_INFO_VENDOR_INFO_NAME_TO_DISPLAY: Record<
  DigitalVolumeInfoVendorInfoFormField,
  string
> = {
  [DigitalVolumeInfoVendorInfoFormField.ID]: 'ID',
  [DigitalVolumeInfoVendorInfoFormField.VENDOR_NAME]: 'Vendor name',
  [DigitalVolumeInfoVendorInfoFormField.INTERNAL_LAB_FILE_NO]: 'Internal lab file No',
  [DigitalVolumeInfoVendorInfoFormField.REPORT_NO]: 'Report No',
  [DigitalVolumeInfoVendorInfoFormField.REPORT_DATE]: 'Report date',
  [DigitalVolumeInfoVendorInfoFormField.DATA_ANALYSIS_QC_BY_ARAMCO]: 'Data analyst QC by Aramco',
};
