<div class="app-menu-wrapper" [style.background-image]="backgroundImage">
  <div class="header">
    <div class="left-logo">
      <h1 class="left-h1">{{ appName }}</h1>
      <h2 class="left-h2">{{ appDescription }}</h2>
    </div>

    <div class="right-logo">
      <img [src]="fullLogo" class="logo" />
    </div>
  </div>

  <div class="content">
    <div class="first-line">
      <button class="hexagon-btn" (click)="goToDashboardApp()">
        <div class="hexagon-btn__image-block">
          <img
            class="hexagon-btn__image"
            src="/assets/images/dashboard.png"
            alt="dashboard image"
          />
        </div>
        <div class="hexagon-btn__text-block">
          <h3 class="hexagon-btn__title">Dashboard</h3>
          <span class="hexagon-btn__description">Sample / data statistics</span>
        </div>
      </button>
      <button class="hexagon-btn" (click)="goToAcmeTrackerApp()">
        <div class="hexagon-btn__image-block">
          <img
            class="hexagon-btn__image"
            src="/assets/images/acme_tracker.png"
            alt="acme tracker image"
          />
        </div>
        <div class="hexagon-btn__text-block">
          <h3 class="hexagon-btn__title">ACME Tracker</h3>
          <span class="hexagon-btn__description">Sample / data tracking</span>
        </div>
      </button>
    </div>

    <div class="second-line">
      <button class="hexagon-btn" (click)="goToCoreAnalysisWizardApp()">
        <div class="hexagon-btn__image-block">
          <img
            class="hexagon-btn__image"
            src="/assets/images/core_analysis_wizard.png"
            alt="core analysis wizard image"
          />
        </div>
        <div class="hexagon-btn__text-block">
          <h3 class="hexagon-btn__title" [style.margin-bottom]="0">
            Core Analysis <br />
            Wizard
          </h3>
          <span class="hexagon-btn__description">Experiments and computations workflows</span>
        </div>
      </button>
      <button class="hexagon-btn" (click)="showToBeImplementedMessage('ACME Viewer')">
        <div class="hexagon-btn__image-block">
          <img
            class="hexagon-btn__image"
            src="/assets/images/acme_viewer.png"
            alt="acme viewer image"
          />
        </div>
        <div class="hexagon-btn__text-block">
          <h3 class="hexagon-btn__title">ACME Viewer</h3>
          <span class="hexagon-btn__description">Core data visualization</span>
        </div>
      </button>
      <button class="hexagon-btn" (click)="showToBeImplementedMessage('ACME Studio')">
        <div class="hexagon-btn__image-block">
          <img
            class="hexagon-btn__image"
            src="/assets/images/acme_studio.png"
            alt="acme studio image"
          />
        </div>
        <div class="hexagon-btn__text-block">
          <h3 class="hexagon-btn__title">ACME Studio</h3>
          <span class="hexagon-btn__description">AR / VR project discussion platform</span>
        </div>
      </button>
    </div>
  </div>

  <div class="footer">
    <button
      class="footer__logout-btn"
      mat-button
      (click)="logout()"
      id="application-menu-logout-btn"
    >
      <mat-icon class="footer__logout-btn__icon">logout</mat-icon>
      <span class="sidenav-span">Log out</span>
    </button>
    <h1 class="footer__label">{{ footerText }}</h1>
  </div>
</div>
