<header class="header">
  <h1 class="title">Sample Types</h1>

  <button
    id="sample-types-options-btn"
    class="btn btn-small btn-primary options-btn"
    type="button"
    (click)="openOptions()"
  >
    <i class="fas fa-gear"></i>
    <span class="options-btn__text">Options</span>
  </button>
</header>

<div class="chart-container" #chartContainer>
  <div *ngIf="chart" [chart]="chart"></div>
</div>
