import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';

import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { PASS_ERROR_HEADER, SKIP_TOKEN } from '@core/constants/api.consts';

@Injectable()
export class OktaAuthInterceptor implements HttpInterceptor {
  constructor(public http: HttpClient, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const condition = req.url.includes('token') || req.url.includes('refresh');
    const accessToken: string | undefined = this.oktaAuth.getAccessToken();
    let authRequest = req.clone();
    let shouldThrowOutside = false;

    if (req.headers.has(PASS_ERROR_HEADER)) {
      shouldThrowOutside = true;
    }

    if (!condition && !req.headers.has(SKIP_TOKEN)) {
      authRequest = this.addTokenToRequest(authRequest, accessToken);
    }

    if (req.headers.has(SKIP_TOKEN)) {
      authRequest = req.clone({
        headers: req.headers.delete(SKIP_TOKEN),
      });
    }

    return next
      .handle(authRequest)
      .pipe(catchError((err) => (shouldThrowOutside ? throwError(() => err) : EMPTY)));
  }

  private addTokenToRequest(
    request: HttpRequest<any>,
    token: string | undefined,
  ): HttpRequest<any> {
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
  }
}
