export enum PcriStatusFormField {
  PROGRESS = 'progress',
  COMPLETION_DATE = 'completionDate',
  COMPLETED = 'completed',
}

export const PCRI_STATUS_LABEL: Record<PcriStatusFormField, string> = {
  [PcriStatusFormField.PROGRESS]: 'Progress',
  [PcriStatusFormField.COMPLETION_DATE]: 'Completion date',
  [PcriStatusFormField.COMPLETED]: 'Completed',
};
