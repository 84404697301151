import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { Router, RouterModule } from '@angular/router';

import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { WelcomePageModule } from 'src/app/common/welcome-page/welcome-page.component';
import { AuthenticationService } from 'src/app/services/api/authentication.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupComponent extends Destroyable(Object) {
  constructor(private authenticationService: AuthenticationService, private router: Router) {
    super();
  }

  onSignupClicked([email, password]: [string, string | null]): void {
    this.authenticationService
      .register(email, password!)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => this.router.navigate(['login']));
  }
}

@NgModule({
  declarations: [SignupComponent],
  imports: [RouterModule.forChild([]), MatButtonModule, WelcomePageModule],
  exports: [SignupComponent],
})
export class SignupModule {}
