import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import {
  MatLegacyTabChangeEvent as MatTabChangeEvent,
  MatLegacyTabsModule as MatTabsModule,
} from '@angular/material/legacy-tabs';

import { CheckboxListFieldModule } from '../../../../common/checkbox-list-field/checkbox-list-field.component';
import { StopPropagationOnClickModule } from '../../../../common/directives/stop-propagation-on-click.directive';
import { MultiSelectModule } from '../../../../common/multi-select/multi-select.component';
import { DashboardWidgetOptionsPopupDateTabModule } from '../dashboard-widget-options-popup-date-tab/dashboard-widget-options-popup-date-tab.component';
import { DashboardWidgetOptionsPopupTeamMemberTabModule } from '../dashboard-widget-options-popup-team-member-tab/dashboard-widget-options-popup-team-member-tab.component';
import { DashboardWidgetOptionsPopupTeamTabModule } from '../dashboard-widget-options-popup-team-tab/dashboard-widget-options-popup-team-tab.component';
import { DashboardWidgetOptionsPopupTab } from '../enums/dashboard-widget-options-popup-tab.enum';
import { DashboardWidgetOptionsPopupData } from '../models/dashboard-widget-options-popup-data.model';
import { DashboardWidgetOptionsPopupLists } from '../models/dashboard-widget-options-popup-lists.model';
import { DashboardWidgetOptionsPopupState } from '../models/dashboard-widget-options-popup-state.model';

@Component({
  selector: 'app-dashboard-widget-options-popup',
  templateUrl: './dashboard-widget-options-popup.component.html',
  styleUrls: ['./dashboard-widget-options-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWidgetOptionsPopupComponent {
  readonly dashboardWidgetOptionsPopupTab = DashboardWidgetOptionsPopupTab;
  readonly filterLists: DashboardWidgetOptionsPopupLists;
  readonly filterState: DashboardWidgetOptionsPopupState;
  readonly initialActiveTabIndex: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DashboardWidgetOptionsPopupData,
    private dialogRef: MatDialogRef<
      DashboardWidgetOptionsPopupComponent,
      DashboardWidgetOptionsPopupState
    >,
  ) {
    this.filterLists = data.lists;
    this.filterState = data.state;
    this.initialActiveTabIndex = this.getInitialActiveTabIndex(data.state);
  }

  changeActiveTab({ tab: { textLabel } }: MatTabChangeEvent): void {
    this.filterState.activeTab = textLabel as DashboardWidgetOptionsPopupTab;
  }

  applyFilters(): void {
    this.dialogRef.close(this.filterState);
  }

  private getInitialActiveTabIndex(state: DashboardWidgetOptionsPopupState): number {
    return Object.values(DashboardWidgetOptionsPopupTab).findIndex(
      (enumValue) => enumValue === state.activeTab,
    )!;
  }
}

@NgModule({
  declarations: [DashboardWidgetOptionsPopupComponent],
  exports: [DashboardWidgetOptionsPopupComponent],
  imports: [
    MatDialogModule,
    MatTabsModule,
    MatCheckboxModule,
    CommonModule,
    CheckboxListFieldModule,
    ReactiveFormsModule,
    MultiSelectModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    StopPropagationOnClickModule,
    MatRadioModule,
    FormsModule,
    DashboardWidgetOptionsPopupDateTabModule,
    DashboardWidgetOptionsPopupTeamMemberTabModule,
    DashboardWidgetOptionsPopupTeamTabModule,
  ],
})
export class DashboardWidgetOptionsPopupModule {}
