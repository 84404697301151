import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnInit,
} from '@angular/core';

import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { UserNotificationService } from 'src/app/services/api/user-notification.service';

import { DashboardNotificationModule } from '../../dashboard-notification/dashboard-notification.component';
import { BaseNotification } from '../../dashboard-notification/models/base-notification.model';
import { NotificationType } from '../../dashboard-notification/models/notification-type.enum';
import { DashboardWidgetModule } from '../dashboard-widget/dashboard-widget.component';
import { SampleTypesModule } from '../sample-types/sample-types.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent extends Destroyable(Object) implements OnInit {
  notifications = new Array<BaseNotification>();
  isEachNotificationLoaded = false;
  readonly faArrowsRotate = faArrowsRotate;
  readonly notificationTypes = NotificationType;

  constructor(
    private userNotificationService: UserNotificationService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getNotifications();
  }

  onNotificationsRequest(): void {
    this.userNotificationService.getNotifications();
  }

  private getNotifications(): void {
    this.userNotificationService.notifications
      .pipe(this.takeUntilDestroyed())
      .subscribe((value) => {
        this.notifications = value;
        this.cd.detectChanges();
      });

    this.userNotificationService.isEachNotificationLoaded
      .asObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe((isEachNotificationLoaded) => {
        this.isEachNotificationLoaded = isEachNotificationLoaded;
        this.cd.markForCheck();
      });
  }
}

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    DashboardNotificationModule,
    CommonModule,
    FontAwesomeModule,
    SampleTypesModule,
    DashboardWidgetModule,
  ],
  exports: [DashboardComponent],
})
export class DashboardModule {}
