import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBarcode, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { forkJoin } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { IdName } from '@core/models/id-name.model';
import { Location } from '@core/models/location.model';
import { LocationTreeViewNode } from '@core/models/location-tree-view-node.model';
import { FlatFormValidationMessage } from '@core/utils/form/flat-form-validation-message.class';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { DisabledElementModule } from 'src/app/common/directives/disabled-element.directive';
import { GetAndAssignBarcodeModule } from 'src/app/common/directives/get-and-assign-barcode.directive';
import { PrintBarcodePopupModule } from 'src/app/common/print-barcode-popup/print-barcode-popup.component';
import { TreeViewSelectorPopupFieldModule } from 'src/app/common/tree-view-selector-popup-field/tree-view-selector-popup-field.component';
import { LocationFormField } from 'src/app/features/location/form/location-form-field.enum';
import { LOCATION_FORM_FIELD_ERROR_MESSAGE } from 'src/app/features/location/form/location-form-field-error-message.config';
import { LOCATION_FORM_FIELD_NAME_TO_DISPLAY } from 'src/app/features/location/form/location-form-field-name-to-display.config';
import { qrCodeOrBarcodeIsRequired } from 'src/app/features/location/form/qr-code-or-barcode-is-required.validator';
import { LocationRestrictionService } from 'src/app/features/location/services/location-restriction.service';
import { LocationTypeService } from 'src/app/features/location/services/location-type.service';
import { LocationService } from 'src/app/services/api/location.service';
import { LocationTreeService } from 'src/app/services/api/location-tree.service';

import {
  LocationTreeFilterComponent,
  LocationTreeFilterModule,
} from '../../../common/location-tree-filter/location-tree-filter.component';
import { MultiSelectModule } from '../../../common/multi-select/multi-select.component';
import { LocationDisplayNamePipe } from '../pipes/location-display-name.pipe';

@Component({
  selector: 'app-location-body',
  templateUrl: './location-body.component.html',
  styleUrls: ['./location-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LocationDisplayNamePipe],
})
export class LocationBodyComponent extends Destroyable(Object) implements OnInit {
  @Input() set location(location: Location) {
    this._location = location;

    if (this.isEachSelectorOptionListLoaded) {
      this.initializeForm(location);
    }
  }

  get location(): Location {
    return this._location;
  }

  @Input() isEditable = true;

  readonly faBarcode = faBarcode;
  readonly faQrcode = faQrcode;
  readonly locationFormField = LocationFormField;
  readonly locationFormFieldNameToDisplay = LOCATION_FORM_FIELD_NAME_TO_DISPLAY;
  readonly compareWith = (o1: string | null, o2: string) => o1?.toLowerCase() === o2.toLowerCase();
  readonly locationTreeFilterComponent = LocationTreeFilterComponent;
  readonly locationTreeFilterModule = LocationTreeFilterModule;

  locations: Location[] = [];
  locationTypes: IdName[] = [];
  locationRestrictions: IdName[] = [];
  locationNodes: LocationTreeViewNode[] = [];

  locationForm!: FormGroup;
  flatFormValidationMessage!: FlatFormValidationMessage<typeof LocationFormField>;

  private noRestrictionValue!: IdName;
  private isEachSelectorOptionListLoaded = false;
  private isFormInitialized = false;
  private _location!: Location;
  private previousRestrictions = new Array<string>();

  constructor(
    private locationService: LocationService,
    private locationTreeService: LocationTreeService,
    private locationTypeService: LocationTypeService,
    private locationRestrictionService: LocationRestrictionService,
    public changeDetectorRef: ChangeDetectorRef,
    public locationDisplayNamePipe: LocationDisplayNamePipe,
  ) {
    super();
  }

  get nameFormControl(): FormControl {
    return this.locationForm.get(LocationFormField.NAME) as FormControl<string>;
  }

  get descriptionFormControl(): FormControl {
    return this.locationForm.get(LocationFormField.DESCRIPTION) as FormControl<string>;
  }

  get locationTypeIdFormControl(): FormControl {
    return this.locationForm.get(LocationFormField.LOCATION_TYPE_ID) as FormControl<string>;
  }

  get locationRestrictionIdFormControl(): FormControl {
    return this.locationForm.get(LocationFormField.LOCATION_RESTRICTION_IDS) as FormControl<
      Array<string>
    >;
  }

  get parentLocationIdFormControl(): FormControl {
    return this.locationForm.get(LocationFormField.PARENT_LOCATION_ID) as FormControl<string>;
  }

  get barcodeIdFormControl(): FormControl {
    return this.locationForm.get(LocationFormField.BARCODE_ID) as FormControl<string>;
  }

  get qrCodeIdFormControl(): FormControl {
    return this.locationForm.get(LocationFormField.QR_CODE_ID) as FormControl<string>;
  }

  ngOnInit(): void {
    this.getOptionsForSelectors();
  }

  private createForm(location: Location): void {
    const {
      name,
      description,
      locationTypeId,
      locationRestrictionIds,
      parentLocationId,
      barcodeId,
      qrCodeId,
    } = location;

    this.locationForm = new FormGroup(
      {
        [LocationFormField.NAME]: new FormControl(name, [
          Validators.required,
          Validators.maxLength(128),
        ]),
        [LocationFormField.DESCRIPTION]: new FormControl<string>(description, [
          Validators.maxLength(64),
        ]),
        [LocationFormField.LOCATION_TYPE_ID]: new FormControl<number | null>(locationTypeId, [
          Validators.required,
        ]),
        [LocationFormField.LOCATION_RESTRICTION_IDS]: new FormControl<Array<string>>(
          locationRestrictionIds.length ? locationRestrictionIds : [this.noRestrictionValue.id],
        ),
        [LocationFormField.PARENT_LOCATION_ID]: new FormControl<string | null>(parentLocationId),
        [LocationFormField.BARCODE_ID]: new FormControl<string>(barcodeId, [
          Validators.maxLength(128),
        ]),
        [LocationFormField.QR_CODE_ID]: new FormControl<string>(qrCodeId, [
          Validators.maxLength(128),
        ]),
      },
      [qrCodeOrBarcodeIsRequired],
    );

    if (!this.isEditable) {
      this.locationForm.disable({ emitEvent: false });
    }
  }

  private getOptionsForSelectors(): void {
    forkJoin([
      this.locationService.getAll(),
      this.locationTreeService.getLocationNodes(),
      this.locationTypeService.getAll(),
      this.locationRestrictionService.getAll(),
    ])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([locations, locationNodes, locationTypes, locationRestrictions]) => {
        this.locations = locations;
        this.locationNodes = locationNodes;
        this.locationTypes = locationTypes;
        this.locationRestrictions = locationRestrictions;
        this.noRestrictionValue = this.locationRestrictions.find(
          (type) => type.name === 'No restriction',
        )!;
        this.isEachSelectorOptionListLoaded = true;
        this.updateInitialLocation();

        if (!this.isFormInitialized && this.location) {
          this.initializeForm(this.location);
        }

        this.changeDetectorRef.markForCheck();
      });
  }

  onOptionSelection(): void {
    const selectedOptions = this.locationRestrictionIdFormControl.value;

    if (
      selectedOptions.includes(this.noRestrictionValue!.id) &&
      this.previousRestrictions.length &&
      !this.previousRestrictions.includes(this.noRestrictionValue!.id as string)
    ) {
      this.locationRestrictionIdFormControl.setValue([this.noRestrictionValue!.id]);
    } else {
      const filteredOptions = selectedOptions.filter(
        (option: string) => option !== this.noRestrictionValue!.id,
      );
      this.locationRestrictionIdFormControl.setValue(
        filteredOptions.length ? filteredOptions : [this.noRestrictionValue!.id],
      );
    }

    this.previousRestrictions = this.locationRestrictionIdFormControl.value;
  }

  private setUpValidationMessages(): void {
    this.flatFormValidationMessage = new FlatFormValidationMessage<typeof LocationFormField>(
      this.locationForm,
      LOCATION_FORM_FIELD_NAME_TO_DISPLAY,
      LOCATION_FORM_FIELD_ERROR_MESSAGE,
    );
  }

  private initializeForm(location: Location): void {
    this.createForm(location);
    this.setUpValidationMessages();
    this.isFormInitialized = true;
  }

  private updateInitialLocation(): void {
    if (!this.location.id) {
      this.location.locationRestrictionIds = [this.noRestrictionValue.id];
    }
  }
}

@NgModule({
  declarations: [LocationBodyComponent],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    CommonModule,
    MatSelectModule,
    FontAwesomeModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    PrintBarcodePopupModule,
    GetAndAssignBarcodeModule,
    TreeViewSelectorPopupFieldModule,
    DisabledElementModule,
    MultiSelectModule,
  ],
  providers: [LocationTypeService, LocationRestrictionService],
  exports: [LocationBodyComponent],
})
export class LocationBodyModule {}
