import { WorkOrder } from 'src/app/features/work-order/models/work-order.model';

import { ExperimentField } from './experiment-field.model';

export interface WorkOrderGeneralInformation {
  id: string;
  projectNumber: string;
  description: string;
  proponentEmail: string;
  proponentName: string;
  startDate: string;
  expectedDeliveryDate: string;
}

export interface WorkOrderStatus {
  progress: number | null;
  completionDate: string;
  note: string;
  completed: boolean;
}

export interface WorkOrderVendorInformation {
  vendorName: string;
  vendorContactEmail: string;
  vendorContactName: string;
  vendorContactInfo: string;
}

export interface WorkOrderExperimentDetail {
  experimentType: string;
  experimentName?: string;
  experimentFields?: ExperimentField[];
}

export interface WorkOrderFormShape {
  generalInformation: WorkOrderGeneralInformation;
  workOrderStatus: WorkOrderStatus;
  vendorInformation: WorkOrderVendorInformation;
  experimentDetail: WorkOrderExperimentDetail;
}

export class WorkOrderFormShapeImpl implements WorkOrderFormShape {
  generalInformation: WorkOrderGeneralInformation;
  workOrderStatus: WorkOrderStatus;
  vendorInformation: WorkOrderVendorInformation;
  experimentDetail: WorkOrderExperimentDetail;

  constructor(wo?: WorkOrder) {
    this.generalInformation = {
      id: wo?.id ?? '',
      projectNumber: wo?.name ?? '',
      description: wo?.description ?? '',
      proponentEmail: wo?.proponentEmail ?? '',
      proponentName: wo?.proponentName ?? '',
      startDate: wo?.startDate ?? '',
      expectedDeliveryDate: wo?.expectDate ?? '',
    };
    this.workOrderStatus = {
      progress: wo?.progress ?? null,
      completionDate: wo?.completionDate ?? '',
      note: wo?.note ?? '',
      completed: wo?.completed ?? false,
    };
    this.vendorInformation = {
      vendorName: wo?.vendorName ?? '',
      vendorContactInfo: wo?.vendorContactInfo ?? '',
      vendorContactName: wo?.vendorContactName ?? '',
      vendorContactEmail: wo?.vendorContactEmail ?? '',
    };
    this.experimentDetail = {
      experimentType: wo?.experimentTypeId ?? '',
      experimentFields: wo?.experimentFields ?? [],
    };
  }
}
