<form [formGroup]="formGroup" autocomplete="off">
  <h2 class="section-title">Digital Volume Information</h2>
  <div class="fields-line half-width">
    <mat-form-field appearance="outline">
      <mat-label>
        {{
          digitalvolumeinformationformlabel[digitalVolumeInformationFormField.SAMPLE_FILENAME]
        }}</mat-label
      >
      <mat-select [formControl]="digitalVolumeFileControl" id="sample-filename-input">
        <mat-option *ngFor="let option of digitalVolumeFileList" [value]="option.id">
          {{ option.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="picture-fields-block">
    <div class="picture-column">
      <div class="picture">
        <div
          class="no-image digital-volume-slice-image"
          [style.background-image]="pictureUrl ? 'url(' + pictureUrl + ')' : 'none'"
        >
          <mat-icon *ngIf="!pictureUrl" class="image-icon">image</mat-icon>
        </div>
      </div>
      <div class="digital-volume-information-section">
        <span>Direction</span>

        <mat-form-field appearance="outline">
          <mat-select
            [formControlName]="digitalVolumeInformationFormField.DIRECTION"
            [compareWith]="compareWith"
            id="direction-select"
          >
            <mat-option *ngFor="let option of direction" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-slider
          color="primary"
          [disabled]="false"
          [step]="10"
          [min]="0"
          [max]="maxSize"
          [formControlName]="digitalVolumeInformationFormField.ZOOM"
        >
        </mat-slider>
      </div>

      <div class="fields-line">
        <app-multi-select
          [formControlName]="digitalVolumeInformationFormField.VOLUME_TYPE"
          [label]="digitalvolumeinformationformlabel[digitalVolumeInformationFormField.VOLUME_TYPE]"
          [selectOptions]="volumeTypes"
          id="volume-type-select"
        >
        </app-multi-select>
      </div>
      <div class="fields-line data-format">
        <app-multi-select
          [formControlName]="digitalVolumeInformationFormField.DATA_FORMAT"
          [label]="digitalvolumeinformationformlabel[digitalVolumeInformationFormField.DATA_FORMAT]"
          [selectOptions]="dataFormats"
          id="data-format-select"
        >
        </app-multi-select>
      </div>
    </div>
    <div class="fields">
      <div class="fields-line">
        <mat-form-field>
          <mat-label>
            {{ digitalvolumeinformationformlabel[digitalVolumeInformationFormField.SAMPLE_NAME] }}
          </mat-label>
          <input
            matInput
            [formControlName]="digitalVolumeInformationFormField.SAMPLE_NAME"
            id="sample-name-input"
          />
        </mat-form-field>
      </div>

      <div class="fields-line">
        <app-unit-input
          [formControlName]="digitalVolumeInformationFormField.DEPTH"
          [label]="digitalvolumeinformationformlabel[digitalVolumeInformationFormField.DEPTH]"
          [options]="depthUnits"
          [type]="'number'"
          id="depth-input"
        ></app-unit-input>
      </div>

      <div class="fields-line">
        <app-multi-select
          [formControlName]="digitalVolumeInformationFormField.SAMPLE_TYPE"
          [label]="digitalvolumeinformationformlabel[digitalVolumeInformationFormField.SAMPLE_TYPE]"
          [selectOptions]="sampleTypes"
          id="sample-type-select"
        >
        </app-multi-select>
      </div>

      <div class="fields-line">
        <mat-form-field>
          <mat-label>
            {{
              digitalvolumeinformationformlabel[digitalVolumeInformationFormField.SUBSAMPLE_NUMBER]
            }}
          </mat-label>
          <input
            matInput
            [formControlName]="digitalVolumeInformationFormField.SUBSAMPLE_NUMBER"
            id="subsample-number-input"
          />
        </mat-form-field>
      </div>

      <div class="fields-line">
        <mat-form-field appearance="outline">
          <mat-label>{{
            digitalvolumeinformationformlabel[digitalVolumeInformationFormField.ORIENTATION_ID]
          }}</mat-label>
          <mat-select
            [formControlName]="digitalVolumeInformationFormField.ORIENTATION_ID"
            id="complex-data-uploader-digital-volume-information-orientation-input"
          >
            <mat-option *ngFor="let option of orientationList" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="center-offset-line">
        <span>Center offset</span>
        <div class="center-offset">
          <mat-form-field appearance="outline">
            <mat-label>X</mat-label>
            <input
              matInput
              [type]="'number'"
              [formControlName]="digitalVolumeInformationFormField.ORIGIN_LOCATION_X"
              id="thin-section-information-section-centerOffset-x-input"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Y</mat-label>
            <input
              matInput
              [type]="'number'"
              [formControlName]="digitalVolumeInformationFormField.ORIGIN_LOCATION_Y"
              id="thin-section-information-section-centerOffset-y-input"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Z</mat-label>
            <input
              matInput
              [type]="'number'"
              [formControlName]="digitalVolumeInformationFormField.ORIGIN_LOCATION_Z"
              id="thin-section-information-section-centerOffset-z-input"
            />
          </mat-form-field>
        </div>

        <div class="sample-dimension-unit">
          <app-multi-select
            [formControlName]="digitalVolumeInformationFormField.ORIGIN_LOCATION_UNIT"
            [selectOptions]="depthUnits"
            id="centerOffset-unit-input"
          >
          </app-multi-select>
        </div>
      </div>

      <div class="voxel-size-line">
        <span>Voxel size</span>
        <div class="voxel-size">
          <mat-form-field appearance="outline">
            <mat-label>X</mat-label>
            <input
              matInput
              [type]="'number'"
              [formControlName]="digitalVolumeInformationFormField.VOXEL_SIZE_X"
              id="thin-section-information-section-voxelSize-x-input"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Y</mat-label>
            <input
              matInput
              [type]="'number'"
              [formControlName]="digitalVolumeInformationFormField.VOXEL_SIZE_Y"
              id="thin-section-information-section-voxelSize-y-input"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Z</mat-label>
            <input
              matInput
              [type]="'number'"
              [formControlName]="digitalVolumeInformationFormField.VOXEL_SIZE_Z"
              id="thin-section-information-section-voxelSize-z-input"
            />
          </mat-form-field>
        </div>

        <div class="sample-dimension-unit">
          <app-multi-select
            [formControlName]="digitalVolumeInformationFormField.VOXEL_SIZE_UNIT"
            [selectOptions]="voxelUnits"
            id="voxel-unit-select"
          >
          </app-multi-select>
        </div>
      </div>

      <div class="grid-size-line">
        <span>Grid size</span>
        <div class="grid-size">
          <mat-form-field appearance="outline">
            <mat-label>X</mat-label>
            <input
              matInput
              [type]="'number'"
              [formControlName]="digitalVolumeInformationFormField.GRID_SIZE_X"
              id="thin-section-information-section-gridSize-x-input"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Y</mat-label>
            <input
              matInput
              [type]="'number'"
              [formControlName]="digitalVolumeInformationFormField.GRID_SIZE_Y"
              id="thin-section-information-section-gridSize-y-input"
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Z</mat-label>
            <input
              matInput
              [type]="'number'"
              [formControlName]="digitalVolumeInformationFormField.GRID_SIZE_Z"
              id="thin-section-information-section-gridSize-z-input"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
