import { Component } from '@angular/core';

import { OrganizationService } from './services/api/organization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // NOTE: OrganizationService should be injected here to bootstrap initial organization data retrieving
  constructor(private _: OrganizationService) {}
}
