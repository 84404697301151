import { SimulationSettingsFormField } from './simulation-settings-form-field.enum';

export const SIMULATION_SETTINGS_FORM_LABEL: Record<SimulationSettingsFormField, string> = {
  [SimulationSettingsFormField.SIMULATION_NAME]: 'Simulation name',
  [SimulationSettingsFormField.DESCRIPTION]: 'Description',
  [SimulationSettingsFormField.DIRECTION_X]: 'X',
  [SimulationSettingsFormField.DIRECTION_Y]: 'Y',
  [SimulationSettingsFormField.DIRECTION_Z]: 'Z',
  [SimulationSettingsFormField.FLIP_X]: 'Flip X',
  [SimulationSettingsFormField.FLIP_Y]: 'Flip Y',
  [SimulationSettingsFormField.FLIP_Z]: 'Flip Z',
  [SimulationSettingsFormField.PTSD]: 'PTSD',
  [SimulationSettingsFormField.NUMBER_OF_GPUS]: 'Number of GPUs',
  [SimulationSettingsFormField.NUMBER_OF_CPUS]: 'Number of CPUs',
};
