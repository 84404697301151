import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';

import { addError } from '@core/utils/form/add-error.util';
import { removeError } from '@core/utils/form/remove-error.util';

import { WorkOrderGeneralInformationFormField } from './field-configs/work-order-general-information.field-config';

export const dateLimitsIncorrect = 'dateLimitsIncorrect';

export function dateLimits(formGroup: AbstractControl): ValidationErrors | null {
  const startDateFormControl = formGroup.get(
    WorkOrderGeneralInformationFormField.START_DATE,
  ) as UntypedFormControl;
  const expectedDeliveryDateFormControl = formGroup.get(
    WorkOrderGeneralInformationFormField.EXPECTED_DELIVERY_DATE,
  ) as UntypedFormControl;

  const startDateFormControlErrors = startDateFormControl.errors;
  const expectedDeliveryDateFormControlErrors = expectedDeliveryDateFormControl.errors;

  const isDateInvalid =
    new Date(startDateFormControl.value) > new Date(expectedDeliveryDateFormControl.value);

  const isErrorAppliedAtLeastToOneControl =
    startDateFormControlErrors?.[dateLimitsIncorrect] ||
    expectedDeliveryDateFormControlErrors?.[dateLimitsIncorrect];

  const isErrorAppliedToBothControls =
    startDateFormControlErrors?.[dateLimitsIncorrect] &&
    expectedDeliveryDateFormControlErrors?.[dateLimitsIncorrect];

  if (isDateInvalid && !isErrorAppliedToBothControls) {
    addError(startDateFormControl, dateLimitsIncorrect);
    addError(expectedDeliveryDateFormControl, dateLimitsIncorrect);

    return null;
  }

  if (!isDateInvalid && isErrorAppliedAtLeastToOneControl) {
    removeError(startDateFormControl, dateLimitsIncorrect);
    removeError(expectedDeliveryDateFormControl, dateLimitsIncorrect);
  }

  return null;
}
