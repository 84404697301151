<div class="header">
  <div class="title-wrapper">
    <button
      *ngIf="showBackButton"
      mat-icon-button
      class="back-button"
      matTooltip="Back to samples"
      (click)="backClicked.emit(null)"
      id="sample-header-back-btn"
    >
      <mat-icon>arrow_back_ios</mat-icon>
    </button>

    <h1 class="title">{{ title }}</h1>
  </div>
</div>
