import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { SampleIdDisplayName } from '../models/sample-display-name.interface';

@Pipe({
  name: 'sampleDisplayName',
})
export class SampleDisplayNamePipe implements PipeTransform {
  transform(sample: SampleIdDisplayName | null): string {
    if (!sample) {
      return '';
    }

    const { sampleName, type, depth, wellName } = sample;
    return [sampleName, type, depth.replace(/[^\d.-]/g, ''), wellName]
      .filter((el) => !!el)
      .join('-');
  }
}

@NgModule({
  declarations: [SampleDisplayNamePipe],
  exports: [SampleDisplayNamePipe],
})
export class SampleDisplayNameModule {}
