import { GalleryModule } from 'ng-gallery';
import { filter, finalize, forkJoin, map } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DigitalVolume } from '@core/models/digital-volume.model';
import { IdName } from '@core/models/id-name.model';
import { LaboratoryInformation } from '@core/models/laboratory-information.model';
import { ValidationState } from '@core/models/validation-state.model';
import { WellInformation } from '@core/models/well-information.model';
import {
  INTEGER_ONLY_ERROR_MESSAGE,
  MAX_LENGTH_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
} from '@core/utils/form/error-message.config';
import { FlatFormValidationMessage } from '@core/utils/form/flat-form-validation-message.class';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { DisabledElementModule } from '../../../common/directives/disabled-element.directive';
import { StopPropagationOnClickModule } from '../../../common/directives/stop-propagation-on-click.directive';
import { UploadModule } from '../../../common/directives/upload.directive';
import { AsUntypedFormControlModule } from '../../../common/pipes/as-untyped-form-control.pipe';
import { AsUntypedFormGroupModule } from '../../../common/pipes/as-untyped-form-group.pipe';
import { SampleType } from '../../../common/sample-check-in-menu/enums/sample-type.enum';
import { SampleTableSelectorPopupFieldModule } from '../../../common/sample-table-selector-popup-field/sample-table-selector-popup-field.component';
import { UnitInputField } from '../../../common/unit-input/enums/unit-input-field.enum';
import { UnitInputModule } from '../../../common/unit-input/unit-input.component';
import { ComplexDataUploaderService } from '../../../services/api/complex-data-uploader.service';
import { FileProcessorService } from '../../../services/api/file-processor.service';
import { SampleService } from '../../../services/api/sample.service';
import { SampleTypeService } from '../../../services/api/sample-type.service';
import { UnitAttributeService } from '../../../services/api/unit-attribute.service';
import { NotificationService } from '../../../services/notification.service';
import { WellInformationFormField } from '../../sample/form/sample-check-in-form-field.enum';
import { SampleGetById } from '../../sample/models/sample-get-by-id.model';
import { SampleWellFormModule } from '../../sample/sample-well-form/sample-well-form.component';
import { HAS_INNER_CONTROLS } from '../../work-order/form/has-inner-controls.token';
import { integerOnly } from '../../work-order/form/integer-only.validator';
import { CanDetectChanges } from '../../work-order/models/can-detect-changes.model';
import { HasInnerControls } from '../../work-order/models/has-inner-controls.model';
import {
  DIGITAL_VOLUME_INFO_NAME_TO_DISPLAY,
  DigitalVolumeInfoFormField,
} from '../form/digital-volume-info-form-field.field-config';
import { digitalVolumeInfoSecondStepRequiredValidator } from '../form/digital-volume-info-second-step-required.validator';
import {
  DIGITAL_VOLUME_INFO_VENDOR_INFO_NAME_TO_DISPLAY,
  DigitalVolumeInfoVendorInfoFormField,
} from '../form/digital-volume-info-vendor-info-form-field.field-config';

@Component({
  selector: 'app-complex-data-digital-volume',
  templateUrl: './complex-data-digital-volume.component.html',
  styleUrls: ['./complex-data-digital-volume.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: HAS_INNER_CONTROLS,
      useExisting: ComplexDataDigitalVolumeComponent,
    },
  ],
})
export class ComplexDataDigitalVolumeComponent
  extends Destroyable(Object)
  implements OnInit, HasInnerControls
{
  readonly sampleType = SampleType;
  readonly digitalVolumeInfoFormField = DigitalVolumeInfoFormField;
  readonly digitalVolumeInfoVendorInfoFormField = DigitalVolumeInfoVendorInfoFormField;
  readonly digitalVolumeInfoNameToDisplay = DIGITAL_VOLUME_INFO_NAME_TO_DISPLAY;
  readonly digitalVolumeInfoVendorInfoNameToDisplay =
    DIGITAL_VOLUME_INFO_VENDOR_INFO_NAME_TO_DISPLAY;

  readonly digitalVolumeInformationSectionTypeList = ['xy', 'xz', 'yz'];
  readonly idOfNewFile = 'new-file-id';

  readonly compareWith = (o1: string | null, o2: string) => o1?.toLowerCase() === o2.toLowerCase();
  readonly optionsCompareFn = (option1: string, option2: string): boolean =>
    option1 && option2 ? option1.toLowerCase() === option2.toLowerCase() : option1 === option2;

  @ViewChildren(HAS_INNER_CONTROLS) componentsWhichHasInnerControls!: QueryList<HasInnerControls>;

  sampleTypeList!: IdName[];
  volumeTypeList!: IdName[];
  depthUnitList!: IdName[];
  voxelSizeUnitList!: IdName[];
  digitalVolumeOrientationList!: IdName[];
  sampleOrientationList!: IdName[];
  dataFormatList!: IdName[];
  digitalVolumeFileList: IdName[] = [];

  isVerifyBtnSpinnerVisible = false;
  isUploadBtnSpinnerVisible = false;

  sampleIdControl = new UntypedFormControl('');
  digitalVolumeFileControl = new UntypedFormControl({ value: '', disabled: true });
  wellInfoFormGroup: UntypedFormGroup | null = null;
  sampleInfoFormGroup: UntypedFormGroup | null = null;
  digitalVolumeInfoFormArray = new UntypedFormArray([]);
  sectionSizeFormControl = new UntypedFormControl({ value: 0, disabled: true });
  sectionTypeFormControl = new UntypedFormControl({ value: 'xy', disabled: true });
  selectedDigitalVolumeFileFormControl = new UntypedFormControl('');
  digitalVolumeInfoFormValidationMessageMap = new Map<
    UntypedFormGroup,
    FlatFormValidationMessage<typeof DigitalVolumeInfoFormField>
  >();

  vendorInfoFormValidationMessageMap = new Map<
    UntypedFormGroup,
    FlatFormValidationMessage<typeof DigitalVolumeInfoVendorInfoFormField>
  >();

  selectedDigitalVolumeFile: File | null = null;
  pictureUrlDigitalVolume = '';

  constructor(
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private sampleService: SampleService,
    private sampleTypeService: SampleTypeService,
    private complexDataUploaderService: ComplexDataUploaderService,
    private unitAttributeService: UnitAttributeService,
    private fileProcessorService: FileProcessorService,
  ) {
    super();
  }

  get activeDigitalVolumeInfoFormGroup(): UntypedFormGroup | null {
    if (!this.digitalVolumeInfoFormArray.length) {
      return null;
    }

    const index = this.digitalVolumeInfoFormArray
      .getRawValue()
      .findIndex(
        (digitalVolumeInfo) =>
          digitalVolumeInfo[DigitalVolumeInfoFormField.FILE_ID] ===
          this.selectedDigitalVolumeFileFormControl.value,
      )!;

    return this.digitalVolumeInfoFormArray.at(index) as UntypedFormGroup;
  }

  get activeDigitalVolumeInfoFormValidationMessage(): FlatFormValidationMessage<
    typeof DigitalVolumeInfoFormField
  > | null {
    if (!this.activeDigitalVolumeInfoFormGroup) {
      return null;
    }

    return this.digitalVolumeInfoFormValidationMessageMap.get(
      this.activeDigitalVolumeInfoFormGroup!,
    )!;
  }

  get activeVendorInfoFormValidationMessage(): FlatFormValidationMessage<
    typeof DigitalVolumeInfoVendorInfoFormField
  > | null {
    if (!this.activeDigitalVolumeInfoFormGroup) {
      return null;
    }

    return this.vendorInfoFormValidationMessageMap.get(this.activeDigitalVolumeInfoFormGroup!)!;
  }

  get isVerified(): boolean {
    if (!this.activeDigitalVolumeInfoFormGroup) {
      return false;
    }

    return !!this.activeDigitalVolumeInfoFormGroup
      .get(DigitalVolumeInfoFormField.PROCESSED_FILE_ID)
      ?.getRawValue();
  }

  get isUploaded(): boolean {
    if (!this.activeDigitalVolumeInfoFormGroup) {
      return false;
    }

    return !!this.activeDigitalVolumeInfoFormGroup
      .get(DigitalVolumeInfoFormField.ID)
      ?.getRawValue();
  }

  get componentsWithInnerFormControls(): CanDetectChanges[] {
    return this.componentsWhichHasInnerControls
      ? [
          this,
          ...this.componentsWhichHasInnerControls
            .toArray()
            .reduce(
              (acc, cmp) => [...acc, ...cmp.componentsWithInnerFormControls],
              [] as CanDetectChanges[],
            ),
        ]
      : [this];
  }

  ngOnInit(): void {
    this.initLists();
    this.trackSampleChanges();
    this.trackSectionSizeChanges();
    this.trackSectionTypeChanges();
    this.trackSelectedDigitalVolumeFileChanges();
  }

  handleDigitalVolumeFileSelection(e: Event): void {
    const target = e.target as HTMLInputElement;

    if (target?.files?.length) {
      this.selectedDigitalVolumeFile = target.files.item(0)!;
      this.digitalVolumeFileControl.setValue(this.selectedDigitalVolumeFile.name);
    }
  }

  verify(): void {
    const validationState = this.checkIfFormIsValid();

    if (!validationState.isValid) {
      this.activeDigitalVolumeInfoFormGroup!.markAllAsTouched();
      this.componentsWithInnerFormControls.forEach((cmp) => cmp.detectChanges());
      this.notificationService.notifyError(validationState.errorMessage);
      return;
    }

    const volumeTypeIdControl = this.activeDigitalVolumeInfoFormGroup!.get([
      DigitalVolumeInfoFormField.VOLUME_TYPE_ID,
    ])!;
    const dataFormatIdControl = this.activeDigitalVolumeInfoFormGroup!.get([
      DigitalVolumeInfoFormField.DATA_FORMAT_ID,
    ])!;
    const gridNumberXControl = this.activeDigitalVolumeInfoFormGroup!.get([
      DigitalVolumeInfoFormField.GRID_NUMBER_X,
    ])!;
    const gridNumberYControl = this.activeDigitalVolumeInfoFormGroup!.get([
      DigitalVolumeInfoFormField.GRID_NUMBER_Y,
    ])!;
    const gridNumberZControl = this.activeDigitalVolumeInfoFormGroup!.get([
      DigitalVolumeInfoFormField.GRID_NUMBER_Z,
    ])!;

    const volumeTypeName = this.volumeTypeList.find(
      (volumeType) => volumeType.id === volumeTypeIdControl.getRawValue(),
    )!.name;
    const dataFormatName = this.dataFormatList.find(
      (dataFormat) => dataFormat.id === dataFormatIdControl.getRawValue(),
    )!.name;

    const payload: Parameters<typeof this.fileProcessorService.sliceData>[0] = {
      file: this.selectedDigitalVolumeFile!,
      volumeTypeName,
      dataFormatName,
      gridNumberX: gridNumberXControl.getRawValue()!,
      gridNumberY: gridNumberYControl.getRawValue()!,
      gridNumberZ: gridNumberZControl.getRawValue()!,
    };

    this.changeStatusOfFieldsInvolvedIntoVerification('disable');
    this.selectedDigitalVolumeFileFormControl.disable({ emitEvent: false });
    this.sampleIdControl.disable({ emitEvent: false });
    this.isVerifyBtnSpinnerVisible = true;

    this.fileProcessorService
      .sliceData(payload)
      .pipe(
        finalize(() => {
          this.isVerifyBtnSpinnerVisible = false;
          this.selectedDigitalVolumeFileFormControl.enable({ emitEvent: false });
          this.sampleIdControl.enable({ emitEvent: false });
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe({
        next: ({ rawFileId, processedFileId }) => {
          this.notificationService.notifySuccess('Successfully verified');
          this.digitalVolumeFileList.find((file) => file.id === this.idOfNewFile)!.id = rawFileId;
          this.activeDigitalVolumeInfoFormGroup!.patchValue({
            [DigitalVolumeInfoFormField.FILE_ID]: rawFileId,
            [DigitalVolumeInfoFormField.PROCESSED_FILE_ID]: processedFileId,
          });
          this.sectionSizeFormControl.enable({ emitEvent: false });
          this.sectionTypeFormControl.enable({ emitEvent: false });
          this.activeDigitalVolumeInfoFormGroup!.reset(
            { ...this.activeDigitalVolumeInfoFormGroup!.getRawValue() },
            { emitEvent: false },
          );
          this.loadSlice();
        },
        error: () => {
          this.changeStatusOfFieldsInvolvedIntoVerification('enable');
        },
      });
  }

  clear(): void {
    this.activeDigitalVolumeInfoFormGroup!.reset(
      this.generateDigitalVolumeFormGroup({
        [DigitalVolumeInfoFormField.VOXEL_SIZE_UNIT_ID]: this.voxelSizeUnitList[0].id,
        [DigitalVolumeInfoFormField.ORIGIN_LOCATION_UNIT_ID]: this.depthUnitList[0].id,
        depthUnitId: this.depthUnitList[0].id,
      }).getRawValue(),
    );
  }

  cancel(): void {
    this.digitalVolumeFileList.find(
      (file) =>
        file.id ===
        this.activeDigitalVolumeInfoFormGroup!.get([DigitalVolumeInfoFormField.FILE_ID])!.value,
    )!.id = this.idOfNewFile;
    this.clearPicture();
    this.activeDigitalVolumeInfoFormGroup!.patchValue({
      [DigitalVolumeInfoFormField.FILE_ID]: '',
      [DigitalVolumeInfoFormField.PROCESSED_FILE_ID]: '',
    });
    this.sectionSizeFormControl.setValue(0);
    this.sectionTypeFormControl.setValue('xy');

    this.sectionSizeFormControl.disable({ emitEvent: false });
    this.sectionTypeFormControl.disable({ emitEvent: false });
    this.changeStatusOfFieldsInvolvedIntoVerification('enable');
  }

  upload(): void {
    const validationState = this.checkIfFormIsValid();

    if (!validationState.isValid) {
      this.activeDigitalVolumeInfoFormGroup!.markAllAsTouched();
      this.componentsWithInnerFormControls.forEach((cmp) => cmp.detectChanges());
      this.notificationService.notifyError(validationState.errorMessage);
      return;
    }

    const activeDigitalVolumeFormGroupValue = this.activeDigitalVolumeInfoFormGroup!.getRawValue();
    const payload: DigitalVolume = {
      ...activeDigitalVolumeFormGroupValue,
      depth:
        activeDigitalVolumeFormGroupValue.depth[UnitInputField.FIELD_VALUE] === ''
          ? null
          : Number(activeDigitalVolumeFormGroupValue.depth[UnitInputField.FIELD_VALUE]),
      depthUnitId: activeDigitalVolumeFormGroupValue.depth[UnitInputField.UNIT_ID],
      [DigitalVolumeInfoFormField.FILE_NAME]: this.digitalVolumeFileList.find(
        (file) => activeDigitalVolumeFormGroupValue[DigitalVolumeInfoFormField.FILE_ID] === file.id,
      )!.name,
    };
    this.isUploadBtnSpinnerVisible = true;

    this.complexDataUploaderService
      .saveDigitalVolume(payload, this.sampleIdControl.value)
      .pipe(
        finalize(() => {
          this.isUploadBtnSpinnerVisible = false;
          this.cd.markForCheck();
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe({
        next: (digitalVolume) => {
          this.notificationService.notifySuccess('Digital volume saved');
          this.activeDigitalVolumeInfoFormGroup!.patchValue({
            [DigitalVolumeInfoFormField.ID]: digitalVolume.id,
            [DigitalVolumeInfoFormField.LABORATORY_INFORMATION]: {
              [DigitalVolumeInfoVendorInfoFormField.ID]: digitalVolume.laboratoryInformation?.id,
              [DigitalVolumeInfoVendorInfoFormField.REPORT_DATE]:
                digitalVolume.laboratoryInformation?.reportDate,
            },
          });
        },
      });
  }

  detectChanges(): void {
    this.cd.detectChanges();
  }

  getMaxSliderValue(): number {
    return Math.max(
      Math.floor(
        Number(
          this.activeDigitalVolumeInfoFormGroup!.get(
            DigitalVolumeInfoFormField.GRID_NUMBER_X,
          )!.getRawValue(),
        ) / 10,
      ) *
        10 -
        10,
      0,
    );
  }

  private clearPicture(): void {
    this.pictureUrlDigitalVolume = '';
  }

  private changeStatusOfFieldsInvolvedIntoVerification(action: 'disable' | 'enable'): void {
    const formFieldsInvolvedIntoAction = [
      DigitalVolumeInfoFormField.VOLUME_TYPE_ID,
      DigitalVolumeInfoFormField.DATA_FORMAT_ID,
      DigitalVolumeInfoFormField.GRID_NUMBER_X,
      DigitalVolumeInfoFormField.GRID_NUMBER_Y,
      DigitalVolumeInfoFormField.GRID_NUMBER_Z,
    ];
    formFieldsInvolvedIntoAction.forEach((formField) => {
      this.activeDigitalVolumeInfoFormGroup!.get(formField)![action]({ emitEvent: false });
    });
  }

  private trackSectionSizeChanges(): void {
    this.sectionSizeFormControl.valueChanges
      .pipe(
        filter(() => this.isVerified),
        this.takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.loadSlice();
      });
  }

  private trackSectionTypeChanges(): void {
    this.sectionTypeFormControl.valueChanges
      .pipe(
        filter(() => this.isVerified),
        this.takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.loadSlice();
      });
  }

  private trackSelectedDigitalVolumeFileChanges(): void {
    this.selectedDigitalVolumeFileFormControl.valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.sectionSizeFormControl.setValue(0, { emitEvent: false });
        this.sectionTypeFormControl.setValue('xy', { emitEvent: false });

        if (this.isVerified) {
          this.sectionSizeFormControl.enable({ emitEvent: false });
          this.sectionTypeFormControl.enable({ emitEvent: false });
          this.loadSlice();
        } else {
          this.sectionSizeFormControl.disable({ emitEvent: false });
          this.sectionTypeFormControl.disable({ emitEvent: false });
          this.clearPicture();
        }
      });
  }

  private loadSlice(): void {
    this.fileProcessorService
      .getSliceBy(
        this.activeDigitalVolumeInfoFormGroup!.get([
          DigitalVolumeInfoFormField.PROCESSED_FILE_ID,
        ])!.getRawValue(),
        this.sectionTypeFormControl.value,
        this.sectionSizeFormControl.value,
      )
      .pipe(
        map((bl) => URL.createObjectURL(bl)),
        this.takeUntilDestroyed(),
      )
      .subscribe((objectUrl) => {
        this.pictureUrlDigitalVolume = objectUrl;
        this.cd.markForCheck();
      });
  }

  private checkIfFormIsValid(): ValidationState {
    if (this.activeDigitalVolumeInfoFormGroup!.status === 'VALID') {
      return { isValid: true };
    }

    const validationMessage = this.digitalVolumeInfoFormValidationMessageMap.get(
      this.activeDigitalVolumeInfoFormGroup!,
    )!;

    if (validationMessage.hasError()) {
      return {
        isValid: false,
        errorMessage: validationMessage.getFirstMessage({
          [DigitalVolumeInfoFormField.ORIGIN_LOCATION_X]: 'Center offset X',
          [DigitalVolumeInfoFormField.ORIGIN_LOCATION_Y]: 'Center offset Y',
          [DigitalVolumeInfoFormField.ORIGIN_LOCATION_Z]: 'Center offset Z',
          [DigitalVolumeInfoFormField.VOXEL_SIZE_X]: 'Voxel size X',
          [DigitalVolumeInfoFormField.VOXEL_SIZE_Y]: 'Voxel size Y',
          [DigitalVolumeInfoFormField.VOXEL_SIZE_Z]: 'Voxel size Z',
          [DigitalVolumeInfoFormField.GRID_NUMBER_X]: 'Grid number X',
          [DigitalVolumeInfoFormField.GRID_NUMBER_Y]: 'Grid number Y',
          [DigitalVolumeInfoFormField.GRID_NUMBER_Z]: 'Grid number Z',
        }),
      };
    }

    return {
      isValid: false,
      errorMessage: this.vendorInfoFormValidationMessageMap
        .get(this.activeDigitalVolumeInfoFormGroup!)!
        .getFirstMessage(),
    };
  }

  private trackSampleChanges(): void {
    this.sampleIdControl.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((sampleId) => {
      this.digitalVolumeInfoFormArray = new UntypedFormArray([]);
      this.wellInfoFormGroup = null;
      this.sampleInfoFormGroup = null;
      this.digitalVolumeFileList = [];
      this.digitalVolumeFileControl = new UntypedFormControl({ value: '', disabled: true });
      this.sectionSizeFormControl.setValue(0, { emitEvent: false });
      this.sectionSizeFormControl.disable({ emitEvent: false });
      this.sectionTypeFormControl.setValue('xy', { emitEvent: false });
      this.sectionTypeFormControl.disable({ emitEvent: false });
      this.selectedDigitalVolumeFileFormControl.setValue('', { emitEvent: false });
      this.digitalVolumeInfoFormValidationMessageMap.clear();
      this.vendorInfoFormValidationMessageMap.clear();
      this.selectedDigitalVolumeFile = null;
      this.clearPicture();

      if (sampleId) {
        this.loadSampleRelatedInfo(sampleId);
      }
    });
  }

  private loadSampleRelatedInfo(sampleId: string): void {
    this.loadSampleRelatedWellInfo(sampleId);
    this.loadSampleRelatedDigitalVolumeInfo(sampleId);
    this.trackDigitalVolumeFileChanges();

    this.cd.detectChanges();
  }

  private trackDigitalVolumeFileChanges(): void {
    this.digitalVolumeFileControl.valueChanges.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.digitalVolumeFileList = [
        ...this.digitalVolumeFileList.filter(
          (digitalVolumeFile) => digitalVolumeFile.id !== this.idOfNewFile,
        ),
        { id: this.idOfNewFile, name: this.digitalVolumeFileControl.getRawValue() },
      ];

      const newFileIndex = this.digitalVolumeInfoFormArray
        .getRawValue()
        .findIndex(
          (digitalVolume) => digitalVolume[DigitalVolumeInfoFormField.FILE_ID] === this.idOfNewFile,
        );
      const digitalVolumeFormGroup = this.generateDigitalVolumeFormGroup({
        [DigitalVolumeInfoFormField.VOXEL_SIZE_UNIT_ID]: this.voxelSizeUnitList[0].id,
        [DigitalVolumeInfoFormField.ORIGIN_LOCATION_UNIT_ID]: this.depthUnitList[0].id,
        depthUnitId: this.depthUnitList[0].id,
      });

      if (newFileIndex === -1) {
        this.digitalVolumeInfoFormArray.push(digitalVolumeFormGroup);
      } else {
        this.digitalVolumeInfoFormArray.insert(newFileIndex, digitalVolumeFormGroup);
      }

      this.digitalVolumeInfoFormValidationMessageMap.set(
        this.digitalVolumeInfoFormArray.at(newFileIndex) as UntypedFormGroup,
        new FlatFormValidationMessage(digitalVolumeFormGroup, DIGITAL_VOLUME_INFO_NAME_TO_DISPLAY, {
          ...REQUIRED_ERROR_MESSAGE,
          ...INTEGER_ONLY_ERROR_MESSAGE,
        }),
      );
      this.vendorInfoFormValidationMessageMap.set(
        this.digitalVolumeInfoFormArray.at(newFileIndex) as UntypedFormGroup,
        new FlatFormValidationMessage(
          digitalVolumeFormGroup.get(
            DigitalVolumeInfoFormField.LABORATORY_INFORMATION,
          ) as UntypedFormGroup,
          DIGITAL_VOLUME_INFO_VENDOR_INFO_NAME_TO_DISPLAY,
          {
            ...REQUIRED_ERROR_MESSAGE,
            ...MAX_LENGTH_ERROR_MESSAGE,
          },
        ),
      );
      this.selectedDigitalVolumeFileFormControl.setValue(this.idOfNewFile);
    });
  }

  private initializeDigitalVolumeFileList(digitalVolumeList: DigitalVolume[]): void {
    this.digitalVolumeFileList = digitalVolumeList.map((digitalVolume) => {
      return {
        id: digitalVolume.fileId,
        name: digitalVolume.fileName,
      };
    });
  }

  private initLists(): void {
    forkJoin([
      this.sampleTypeService.getAll(),
      this.complexDataUploaderService.getVolumeTypes(),
      this.complexDataUploaderService.getDataFormats(),
      this.complexDataUploaderService.getOrientations(),
      this.sampleService.getOrientations(),
      this.unitAttributeService.getAll().pipe(map(({ lengthUnits }) => lengthUnits)),
      this.unitAttributeService.getVoxelSize(),
    ])
      .pipe(this.takeUntilDestroyed())
      .subscribe(
        ([
          sampleTypeList,
          volumeTypeList,
          dataFormatList,
          digitalVolumeOrientationList,
          sampleOrientationList,
          depthUnits,
          voxelSizeUnitList,
        ]) => {
          this.sampleTypeList = sampleTypeList;
          this.volumeTypeList = volumeTypeList;
          this.dataFormatList = dataFormatList;
          this.digitalVolumeOrientationList = digitalVolumeOrientationList;
          this.sampleOrientationList = sampleOrientationList;
          this.depthUnitList = depthUnits;
          this.voxelSizeUnitList = voxelSizeUnitList;
        },
      );
  }

  private loadSampleRelatedWellInfo(sampleId: string): void {
    this.sampleService
      .getById(sampleId)
      .pipe(this.takeUntilDestroyed())
      .subscribe((sample) => {
        this.fillWellInfoFormGroup(sample.wellInformation!);
        this.fillSampleInfoFormGroup(sample!);
      });
  }

  private loadSampleRelatedDigitalVolumeInfo(sampleId: string): void {
    this.complexDataUploaderService
      .getDigitalVolumeBy(sampleId)
      .pipe(this.takeUntilDestroyed())
      .subscribe((digitalVolumeList) => {
        digitalVolumeList.forEach((digitalVolume) => {
          const digitalVolumeFormGroup = this.generateDigitalVolumeFormGroup(digitalVolume);

          this.digitalVolumeInfoFormArray.push(digitalVolumeFormGroup);
          this.digitalVolumeInfoFormValidationMessageMap.set(
            this.digitalVolumeInfoFormArray.at(-1) as UntypedFormGroup,
            new FlatFormValidationMessage(
              digitalVolumeFormGroup,
              DIGITAL_VOLUME_INFO_NAME_TO_DISPLAY,
              {
                ...REQUIRED_ERROR_MESSAGE,
                ...INTEGER_ONLY_ERROR_MESSAGE,
              },
            ),
          );
          this.vendorInfoFormValidationMessageMap.set(
            this.digitalVolumeInfoFormArray.at(-1) as UntypedFormGroup,
            new FlatFormValidationMessage(
              digitalVolumeFormGroup.get(
                DigitalVolumeInfoFormField.LABORATORY_INFORMATION,
              ) as UntypedFormGroup,
              DIGITAL_VOLUME_INFO_VENDOR_INFO_NAME_TO_DISPLAY,
              {
                ...REQUIRED_ERROR_MESSAGE,
                ...MAX_LENGTH_ERROR_MESSAGE,
              },
            ),
          );
        });
        this.initializeDigitalVolumeFileList(digitalVolumeList);
        this.selectedDigitalVolumeFileFormControl.setValue(
          digitalVolumeList[digitalVolumeList.length - 1]?.fileId ?? '',
        );

        if (this.isVerified) {
          this.changeStatusOfFieldsInvolvedIntoVerification('disable');
        }

        this.cd.markForCheck();
      });
  }

  private fillWellInfoFormGroup(data: WellInformation): void {
    this.wellInfoFormGroup = new UntypedFormGroup({
      [WellInformationFormField.WELL_ID]: new UntypedFormControl({
        value: data?.id ?? '',
        disabled: true,
      }),
      [WellInformationFormField.WELL_LOCATION]: new UntypedFormControl({
        value: data?.location ?? '',
        disabled: true,
      }),
      [WellInformationFormField.FIELD]: new UntypedFormControl({
        value: data?.field ?? '',
        disabled: true,
      }),
      [WellInformationFormField.RESERVOIR]: new UntypedFormControl({
        value: data?.reservoir ?? '',
        disabled: true,
      }),
    });

    this.cd.markForCheck();
  }

  private fillSampleInfoFormGroup(sample: SampleGetById): void {
    switch (sample.type) {
      case SampleType.PLUG:
        this.sampleInfoFormGroup = new UntypedFormGroup({
          sampleName: new UntypedFormControl({
            value: sample.name ?? '',
            disabled: true,
          }),
          sampleType: new UntypedFormControl({
            value: sample.type ?? '',
            disabled: true,
          }),
          depth: new UntypedFormControl({
            value: {
              [UnitInputField.UNIT_ID]: sample.plugInformation!.depthUnitId ?? '',
              [UnitInputField.FIELD_VALUE]: sample.plugInformation!.depth ?? '',
            },
            disabled: true,
          }),
          orientationId: new UntypedFormControl({
            value: sample.plugInformation!.orientationId ?? '',
            disabled: true,
          }),
        });
        break;

      case SampleType.CORE:
        this.sampleInfoFormGroup = new UntypedFormGroup({
          sampleName: new UntypedFormControl({
            value: sample.name ?? '',
            disabled: true,
          }),
          sampleType: new UntypedFormControl({
            value: sample.type ?? '',
            disabled: true,
          }),
          depthTop: new UntypedFormControl({
            value: {
              [UnitInputField.UNIT_ID]: sample.coreInformation!.depthUnitId ?? '',
              [UnitInputField.FIELD_VALUE]: sample.coreInformation!.depthTop ?? '',
            },
            disabled: true,
          }),
          depthBottom: new UntypedFormControl({
            value: {
              [UnitInputField.UNIT_ID]: sample.coreInformation!.depthUnitId ?? '',
              [UnitInputField.FIELD_VALUE]: sample.coreInformation!.depthBottom ?? '',
            },
            disabled: true,
          }),
        });
        break;

      case SampleType.CUTTINGS:
        this.sampleInfoFormGroup = new UntypedFormGroup({
          sampleName: new UntypedFormControl({
            value: sample.name ?? '',
            disabled: true,
          }),
          sampleType: new UntypedFormControl({
            value: sample.type ?? '',
            disabled: true,
          }),
          depthTop: new UntypedFormControl({
            value: {
              [UnitInputField.UNIT_ID]: sample.cuttingsIntervalInformation!.depthUnitId ?? '',
              [UnitInputField.FIELD_VALUE]: sample.cuttingsIntervalInformation!.depthTop ?? '',
            },
            disabled: true,
          }),
          depthBottom: new UntypedFormControl({
            value: {
              [UnitInputField.UNIT_ID]: sample.cuttingsIntervalInformation!.depthUnitId ?? '',
              [UnitInputField.FIELD_VALUE]: sample.cuttingsIntervalInformation!.depthBottom ?? '',
            },
            disabled: true,
          }),
        });
        break;

      case SampleType.FLUID:
        this.sampleInfoFormGroup = new UntypedFormGroup({
          sampleName: new UntypedFormControl({
            value: sample.name ?? '',
            disabled: true,
          }),
          sampleType: new UntypedFormControl({
            value: sample.type ?? '',
            disabled: true,
          }),
          depth: new UntypedFormControl({
            value: {
              [UnitInputField.UNIT_ID]: sample.fluidInformation!.depthUnitId ?? '',
              [UnitInputField.FIELD_VALUE]: sample.fluidInformation!.depth ?? '',
            },
            disabled: true,
          }),
        });
        break;

      case SampleType.UNCATEGORIZED:
        this.sampleInfoFormGroup = new UntypedFormGroup({
          sampleName: new UntypedFormControl({
            value: sample.name ?? '',
            disabled: true,
          }),
          sampleType: new UntypedFormControl({
            value: sample.type ?? '',
            disabled: true,
          }),
          depth: new UntypedFormControl({
            value: {
              [UnitInputField.UNIT_ID]: sample.uncategorizedInformation!.depthUnitId ?? '',
              [UnitInputField.FIELD_VALUE]: sample.uncategorizedInformation!.depth ?? '',
            },
            disabled: true,
          }),
        });
        break;

      case SampleType.THINSECTION:
        this.sampleInfoFormGroup = new UntypedFormGroup({
          sampleName: new UntypedFormControl({
            value: sample.name ?? '',
            disabled: true,
          }),
          sampleType: new UntypedFormControl({
            value: sample.type ?? '',
            disabled: true,
          }),
          depthTop: new UntypedFormControl({
            value: {
              [UnitInputField.UNIT_ID]: sample.thinSectionInformation!.depthUnitId ?? '',
              [UnitInputField.FIELD_VALUE]: sample.thinSectionInformation!.depthTop ?? '',
            },
            disabled: true,
          }),
          depthBottom: new UntypedFormControl({
            value: {
              [UnitInputField.UNIT_ID]: sample.thinSectionInformation!.depthUnitId ?? '',
              [UnitInputField.FIELD_VALUE]: sample.thinSectionInformation!.depthBottom ?? '',
            },
            disabled: true,
          }),
          orientationId: new UntypedFormControl({
            value: sample.thinSectionInformation!.orientationId ?? '',
            disabled: true,
          }),
        });
        break;

      case SampleType.COMPOSITE:
        this.sampleInfoFormGroup = new UntypedFormGroup({
          sampleName: new UntypedFormControl({
            value: sample.name ?? '',
            disabled: true,
          }),
          sampleType: new UntypedFormControl({
            value: sample.type ?? '',
            disabled: true,
          }),
        });
        break;

      default:
        break;
    }

    this.cd.markForCheck();
  }

  private generateDigitalVolumeFormGroup(
    digitalVolumeInfo: Partial<DigitalVolume>,
  ): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        [DigitalVolumeInfoFormField.ID]: new UntypedFormControl(digitalVolumeInfo.id),
        [DigitalVolumeInfoFormField.FILE_NAME]: new UntypedFormControl(
          digitalVolumeInfo.fileName ?? '',
        ),
        [DigitalVolumeInfoFormField.FILE_ID]: new UntypedFormControl(
          digitalVolumeInfo.fileId ?? null,
        ),
        [DigitalVolumeInfoFormField.PROCESSED_FILE_ID]: new UntypedFormControl(
          digitalVolumeInfo.processedFileId ?? null,
        ),
        [DigitalVolumeInfoFormField.DIGITAL_VOLUME_NAME]: new UntypedFormControl(
          digitalVolumeInfo.digitalVolumeName ?? '',
        ),
        [DigitalVolumeInfoFormField.DEPTH]: new UntypedFormControl({
          [UnitInputField.UNIT_ID]: digitalVolumeInfo.depthUnitId ?? '',
          [UnitInputField.FIELD_VALUE]: digitalVolumeInfo.depth ?? '',
        }),
        [DigitalVolumeInfoFormField.SAMPLE_TYPE_ID]: new UntypedFormControl(
          digitalVolumeInfo.sampleTypeId ?? null,
        ),
        [DigitalVolumeInfoFormField.SUB_SAMPLE_NUMBER]: new UntypedFormControl(
          digitalVolumeInfo.subSampleNumber ?? null,
          [integerOnly],
        ),
        [DigitalVolumeInfoFormField.ORIENTATION_ID]: new UntypedFormControl(
          digitalVolumeInfo.orientationId ?? null,
        ),
        [DigitalVolumeInfoFormField.VOLUME_TYPE_ID]: new UntypedFormControl(
          digitalVolumeInfo.volumeTypeId ?? null,
          [Validators.required],
        ),
        [DigitalVolumeInfoFormField.ORIGIN_LOCATION_X]: new UntypedFormControl(
          digitalVolumeInfo.originLocationX ?? null,
        ),
        [DigitalVolumeInfoFormField.ORIGIN_LOCATION_Y]: new UntypedFormControl(
          digitalVolumeInfo.originLocationY ?? null,
        ),
        [DigitalVolumeInfoFormField.ORIGIN_LOCATION_Z]: new UntypedFormControl(
          digitalVolumeInfo.originLocationZ ?? null,
        ),
        [DigitalVolumeInfoFormField.ORIGIN_LOCATION_UNIT_ID]: new UntypedFormControl(
          digitalVolumeInfo.originLocationUnitId ?? '',
        ),
        [DigitalVolumeInfoFormField.VOXEL_SIZE_X]: new UntypedFormControl(
          digitalVolumeInfo.voxelSizeX ?? null,
        ),
        [DigitalVolumeInfoFormField.VOXEL_SIZE_Y]: new UntypedFormControl(
          digitalVolumeInfo.voxelSizeY ?? null,
        ),
        [DigitalVolumeInfoFormField.VOXEL_SIZE_Z]: new UntypedFormControl(
          digitalVolumeInfo.voxelSizeZ ?? null,
        ),
        [DigitalVolumeInfoFormField.VOXEL_SIZE_UNIT_ID]: new UntypedFormControl(
          digitalVolumeInfo.voxelSizeUnitId ?? '',
        ),
        [DigitalVolumeInfoFormField.GRID_NUMBER_X]: new UntypedFormControl(
          digitalVolumeInfo.gridNumberX ?? null,
          [Validators.required],
        ),
        [DigitalVolumeInfoFormField.GRID_NUMBER_Y]: new UntypedFormControl(
          digitalVolumeInfo.gridNumberY ?? null,
          [Validators.required],
        ),
        [DigitalVolumeInfoFormField.GRID_NUMBER_Z]: new UntypedFormControl(
          digitalVolumeInfo.gridNumberZ ?? null,
          [Validators.required],
        ),
        [DigitalVolumeInfoFormField.DATA_FORMAT_ID]: new UntypedFormControl(
          digitalVolumeInfo.dataFormatId ?? null,
          [Validators.required],
        ),
        [DigitalVolumeInfoFormField.LABORATORY_INFORMATION]: this.generateVendorInfoFormGroup(
          digitalVolumeInfo.laboratoryInformation ?? {},
        ),
      },
      [
        digitalVolumeInfoSecondStepRequiredValidator([
          DigitalVolumeInfoFormField.LABORATORY_INFORMATION,
          DigitalVolumeInfoVendorInfoFormField.VENDOR_NAME,
        ]),
        digitalVolumeInfoSecondStepRequiredValidator([
          DigitalVolumeInfoFormField.DIGITAL_VOLUME_NAME,
        ]),
        digitalVolumeInfoSecondStepRequiredValidator([DigitalVolumeInfoFormField.VOXEL_SIZE_X]),
        digitalVolumeInfoSecondStepRequiredValidator([DigitalVolumeInfoFormField.VOXEL_SIZE_Y]),
        digitalVolumeInfoSecondStepRequiredValidator([DigitalVolumeInfoFormField.VOXEL_SIZE_Z]),
        digitalVolumeInfoSecondStepRequiredValidator([
          DigitalVolumeInfoFormField.ORIGIN_LOCATION_X,
        ]),
        digitalVolumeInfoSecondStepRequiredValidator([
          DigitalVolumeInfoFormField.ORIGIN_LOCATION_Y,
        ]),
        digitalVolumeInfoSecondStepRequiredValidator([
          DigitalVolumeInfoFormField.ORIGIN_LOCATION_Z,
        ]),
      ],
    );
  }

  private generateVendorInfoFormGroup(
    laboratoryInfo: Partial<LaboratoryInformation>,
  ): UntypedFormGroup {
    return new UntypedFormGroup({
      [DigitalVolumeInfoVendorInfoFormField.ID]: new UntypedFormControl(laboratoryInfo?.id ?? null),
      [DigitalVolumeInfoVendorInfoFormField.VENDOR_NAME]: new UntypedFormControl(
        laboratoryInfo?.vendorName ?? '',
        Validators.maxLength(32),
      ),
      [DigitalVolumeInfoVendorInfoFormField.INTERNAL_LAB_FILE_NO]: new UntypedFormControl(
        laboratoryInfo?.internalLabFileNo ?? '',
        Validators.maxLength(100),
      ),
      [DigitalVolumeInfoVendorInfoFormField.REPORT_NO]: new UntypedFormControl(
        laboratoryInfo?.reportNo ?? '',
        Validators.maxLength(100),
      ),
      [DigitalVolumeInfoVendorInfoFormField.REPORT_DATE]: new UntypedFormControl(
        laboratoryInfo?.reportDate ?? '',
      ),
      [DigitalVolumeInfoVendorInfoFormField.DATA_ANALYSIS_QC_BY_ARAMCO]: new UntypedFormControl(
        laboratoryInfo?.dataAnalysisQcByAramco ?? '',
        Validators.maxLength(64),
      ),
    });
  }
}

@NgModule({
  declarations: [ComplexDataDigitalVolumeComponent],
  exports: [ComplexDataDigitalVolumeComponent],
  imports: [
    SampleTableSelectorPopupFieldModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    UploadModule,
    SampleWellFormModule,
    MatSelectModule,
    MatButtonToggleModule,
    UnitInputModule,
    MatSliderModule,
    GalleryModule,
    AsUntypedFormGroupModule,
    AsUntypedFormControlModule,
    StopPropagationOnClickModule,
    DisabledElementModule,
    MatDatepickerModule,
  ],
})
export class ComplexDataDigitalVolumeModule {}
