import {
  BehaviorSubject,
  catchError,
  finalize,
  MonoTypeOperatorFunction,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CollectionViewer, DataSource } from '@angular/cdk/collections';

import { SampleService } from '../../../../services/api/sample.service';
import { LookupSampleService } from '../lookup-sample.service';
import { SampleFiltersRequest } from '../models/sample-filters.model';
import { SampleSearch, SampleSearchResult } from '../models/sample-search.model';

export class SamplesDataSource implements DataSource<SampleSearch> {
  private samplesSubject = new BehaviorSubject<SampleSearch[]>([]);
  private loadingCounterSubject = new BehaviorSubject<number>(0);
  private countSubject = new BehaviorSubject<number>(0);
  private loadSamplesStartedSubject = new Subject<void>();
  private searchByBarcodeStartedSubject = new Subject<void>();

  loadingCounter$ = this.loadingCounterSubject.asObservable();
  count$ = this.countSubject.asObservable();
  loadSamplesStarted$ = this.loadSamplesStartedSubject.asObservable();
  searchByBarcodeStarted$ = this.searchByBarcodeStartedSubject.asObservable();

  get data(): SampleSearch[] {
    return this.samplesSubject.getValue();
  }

  constructor(
    private lookupSampleService: LookupSampleService,
    private sampleService: SampleService,
    private takeUntilDestroyed: <T>() => MonoTypeOperatorFunction<T>,
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<SampleSearch[]> {
    return this.samplesSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.samplesSubject.complete();
    this.loadingCounterSubject.complete();
    this.countSubject.complete();
    this.loadSamplesStartedSubject.complete();
    this.searchByBarcodeStartedSubject.complete();
  }

  loadSamples(filterData: SampleFiltersRequest): void {
    this.addLoadingRequest();
    this.loadSamplesStartedSubject.next();

    this.lookupSampleService
      .search(filterData)
      .pipe(
        catchError(() =>
          of({ data: [], pageIndex: 0, pageSize: 10, count: 0 } as SampleSearchResult),
        ),
        finalize(() => this.removeLoadingRequest()),
        takeUntil(this.loadSamplesStarted$),
        takeUntil(this.searchByBarcodeStarted$),
        this.takeUntilDestroyed(),
      )
      .subscribe((samples) => {
        this.countSubject.next(samples.count);
        this.samplesSubject.next(samples.data);
      });
  }

  searchByBarCode(searchString: string): void {
    this.addLoadingRequest();
    this.searchByBarcodeStartedSubject.next();

    this.sampleService
      .searchByBarcode(searchString)
      .pipe(
        catchError(() => of([] as SampleSearch[])),
        finalize(() => this.removeLoadingRequest()),
        takeUntil(this.loadSamplesStarted$),
        takeUntil(this.searchByBarcodeStarted$),
        this.takeUntilDestroyed(),
      )
      .subscribe((result) => {
        this.countSubject.next(result.length);
        this.samplesSubject.next(result);
      });
  }

  private addLoadingRequest(): void {
    this.loadingCounterSubject.next(this.loadingCounterSubject.getValue() + 1);
  }

  private removeLoadingRequest(): void {
    this.loadingCounterSubject.next(this.loadingCounterSubject.getValue() - 1);
  }
}
