<form [formGroup]="formGroup" autocomplete="off">
  <div class="fields-line">
    <app-well-table-selector-popup-field
      #wellTableSelectorRef
      [formControl]="formGroup.get(wellInformationFormField.WELL_ID)! | asUntypedFormControl"
      [label]="wellInformationFormLabel[wellInformationFormField.WELL_ID]"
      id="well-table-input"
    ></app-well-table-selector-popup-field>
    <mat-form-field>
      <mat-label>
        {{ wellInformationFormLabel[wellInformationFormField.WELL_LOCATION] }}
      </mat-label>
      <input
        matInput
        [formControlName]="wellInformationFormField.WELL_LOCATION"
        id="well-location-input"
      />
    </mat-form-field>
  </div>

  <div class="fields-line">
    <mat-form-field>
      <mat-label>
        {{ wellInformationFormLabel[wellInformationFormField.FIELD] }}
      </mat-label>
      <input matInput [formControlName]="wellInformationFormField.FIELD" id="well-field-input" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>
        {{ wellInformationFormLabel[wellInformationFormField.RESERVOIR] }}
      </mat-label>
      <input
        matInput
        [formControlName]="wellInformationFormField.RESERVOIR"
        id="well-reservoir-input"
      />
    </mat-form-field>
  </div>
</form>
