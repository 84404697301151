import { filter } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
  PipeTransform,
  Type,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { TreeViewNode } from '@core/models/tree-view-node.model';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { Disableable } from '@core/utils/mixins/disableable.mixin';
import { TreeViewSelectorPopupComponent } from 'src/app/common/tree-view-selector-popup-field/tree-view-selector-popup/tree-view-selector-popup.component';

import { DisabledElementModule } from '../directives/disabled-element.directive';
import { StopPropagationOnClickModule } from '../directives/stop-propagation-on-click.directive';

@Component({
  selector: 'app-tree-view-selector-popup-field',
  templateUrl: './tree-view-selector-popup-field.component.html',
  styleUrls: ['./tree-view-selector-popup-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeViewSelectorPopupFieldComponent<T extends TreeViewNode<T>>
  extends Disableable(Destroyable(Object))
  implements ControlValueAccessor, OnInit
{
  @Input() label!: string;
  @Input() required = false;
  @Input() filterComponent: Type<any> | null = null;
  @Input() filterModule: Type<any> | null = null;
  @Input() filterContext: any = null;
  @Input() displayNamePipe: PipeTransform | null = null;
  @Input() searchFieldFilter: ((...args: any) => boolean) | null = null;
  @Input() set treeViewNodeList(treeViewNodeList: T[]) {
    this._treeViewNodeList = treeViewNodeList;
    this.defineSelectedNode(this.control.value);
  }

  get treeViewNodeList(): T[] {
    return this._treeViewNodeList;
  }

  @Input() popupTitle!: string;
  @Input() popupSelectedNodeName!: string;

  control = new UntypedFormControl();
  selectedTreeNode: T | null = null;
  displayWith = () =>
    this.displayNamePipe
      ? this.displayNamePipe.transform(this.selectedTreeNode)
      : this.selectedTreeNode?.name ?? '';

  onTouched!: (value: any) => void;
  onChange!: (value: any) => void;

  private _treeViewNodeList: T[] = [];

  constructor(
    private ngControl: NgControl,
    private matDialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) {
    super();
    ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.setValidators();
    this.setUpNgControl();
  }

  openTreeViewSelectorPopup(): void {
    this.matDialog
      .open(TreeViewSelectorPopupComponent, {
        data: {
          title: this.popupTitle || this.label,
          selectedNodeName: this.popupSelectedNodeName || 'node',
          treeNodes: this.treeViewNodeList,
          treeNodeDisplayNamePipe: this.displayNamePipe,
          filterComponent: this.filterComponent,
          filterModule: this.filterModule,
          searchFieldFilter: this.searchFieldFilter,
          ...(this.filterContext && { filterContext: this.filterContext }),
        },
        minWidth: 800,
        maxHeight: '80vh',
        height: '80vh',
        autoFocus: false,
        restoreFocus: false,
      })
      .afterClosed()
      .pipe(
        filter((selectedTreeNode) => !!selectedTreeNode),
        this.takeUntilDestroyed(),
      )
      .subscribe((selectedTreeNode: T) => {
        this.control.setValue(selectedTreeNode!.id);
        this.selectedTreeNode = selectedTreeNode!;
      });
  }

  clearField(): void {
    this.control.setValue('');
    this.selectedTreeNode = null;
  }

  writeValue(val: string): void {
    this.defineSelectedNode(val);
    this.control.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private setValidators(): void {
    const validators = [
      this.ngControl.control?.validator
        ? this.ngControl.control?.validator
        : Validators.nullValidator,
    ];

    if (this.required) {
      validators.push(Validators.required);
      this.ngControl.control?.setValidators(validators);
      this.ngControl.control?.updateValueAndValidity({ emitEvent: false });
    }

    this.control.setValidators(validators);
    this.control.updateValueAndValidity({ emitEvent: false });
  }

  private setUpNgControl(): void {
    this.ngControl.control!.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
    this.ngControl.control!.reset = (value, options) => {
      this.control.reset(value, options);
      this.selectedTreeNode = null;
      this.cd.markForCheck();
    };
  }

  private defineSelectedNode(val: string): void {
    if (!val || !this.treeViewNodeList.length) {
      this.selectedTreeNode = null;
      return;
    }

    let foundNode = null;
    for (let i = 0; foundNode === null && i < this.treeViewNodeList.length; i += 1) {
      foundNode = this.searchNodeAtTree(this.treeViewNodeList[i], val);
    }

    this.selectedTreeNode = foundNode;
    this.control.setValue(this.control.value, { emitEvent: false });
  }

  private searchNodeAtTree(node: T, value: string): T | null {
    if (node.id.toLowerCase() === value.toLowerCase()) {
      return node;
    }

    if (node.children.length) {
      let result = null;
      for (let i = 0; result === null && i < node.children.length; i += 1) {
        result = this.searchNodeAtTree(node.children[i], value);
      }
      return result;
    }

    return null;
  }
}

@NgModule({
  declarations: [TreeViewSelectorPopupFieldComponent],
  exports: [TreeViewSelectorPopupFieldComponent],
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    CommonModule,
    MatInputModule,
    MatDialogModule,
    DisabledElementModule,
    StopPropagationOnClickModule,
  ],
})
export class TreeViewSelectorPopupFieldModule {}
