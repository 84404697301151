import { PixelSize, ThinSectionImage } from './thin-section-model';

export class ThinSectionImageImpl implements ThinSectionImage {
  id?: string | null;
  file?: File | null;
  fileId?: string | null;
  fileName: string;
  angle: PixelSize;
  polarizationId: string;
  pixelSize: PixelSize;
  rotation: number | null;

  constructor(data?: ThinSectionImage, file: File | null = null) {
    this.id = data?.id ?? null;
    this.file = file !== null ? file : data?.file ?? null;
    this.fileId = data?.fileId ?? null;
    this.fileName = data?.fileName ?? '';
    this.angle = {
      fieldValue: data?.angle.fieldValue ?? null,
      unitId: data?.angle.unitId ?? '',
    };
    this.polarizationId = data?.polarizationId ?? '';
    this.pixelSize = {
      fieldValue: data?.pixelSize.fieldValue ?? null,
      unitId: data?.pixelSize.unitId ?? '',
    };
    this.rotation = data?.rotation ?? null;
  }
}
