import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBarcode, faQrcode } from '@fortawesome/free-solid-svg-icons';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { IdName } from '@core/models/id-name.model';
import { LocationTreeViewNode } from '@core/models/location-tree-view-node.model';
import { DisabledElementModule } from 'src/app/common/directives/disabled-element.directive';
import { GetAndAssignBarcodeModule } from 'src/app/common/directives/get-and-assign-barcode.directive';
import { UploadModule } from 'src/app/common/directives/upload.directive';
import {
  LocationTreeFilterComponent,
  LocationTreeFilterModule,
} from 'src/app/common/location-tree-filter/location-tree-filter.component';
import {
  SampleTableSelectorPopupFieldComponent,
  SampleTableSelectorPopupFieldModule,
} from 'src/app/common/sample-table-selector-popup-field/sample-table-selector-popup-field.component';
import { TreeViewSelectorPopupFieldModule } from 'src/app/common/tree-view-selector-popup-field/tree-view-selector-popup-field.component';
import { UserManagementService } from 'src/app/services/user-management.service';

import { MultiSelectModule } from '../../../common/multi-select/multi-select.component';
import { LocationDisplayNamePipe } from '../../location/pipes/location-display-name.pipe';
import { SAMPLE_ERROR_MESSAGES } from '../consts/sample-error-messages.const';
import { SAMPLE_ERROR_TYPES } from '../consts/sample-error-types.const';
import { SAMPLE_IMAGE_VALIDATORS } from '../consts/sample-image-validators.const';
import { SAMPLE_OPTIONS_COMPARE_FN } from '../consts/sample-options-compare-fn.const';
import { SampleCheckInFormField } from '../form/sample-check-in-form-field.enum';
import { SAMPLE_CHECK_IN_FORM_LABEL } from '../form/sample-check-in-form-labels';

@Component({
  selector: 'app-sample-general-form',
  templateUrl: './sample-general-form.component.html',
  styleUrls: ['./sample-general-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LocationDisplayNamePipe],
})
export class SampleGeneralFormComponent implements OnInit {
  @Input() isReadOnly = false;
  @Input() formGroup!: UntypedFormGroup;
  @Input() picture = new Image();
  @Input() showParentSample = false;
  @Input() showRestriction = false;
  @Input() sampleLocations = new Array<LocationTreeViewNode>();
  @Input() restrictionTypes = new Array<IdName>();

  @ViewChild('parentSampleFieldComponent')
  parentSampleFieldComponent!: SampleTableSelectorPopupFieldComponent | null;

  currentUserEmail = '';

  readonly locationTreeFilterComponent = LocationTreeFilterComponent;
  readonly locationTreeFilterModule = LocationTreeFilterModule;
  readonly sampleCheckInFormField = SampleCheckInFormField;
  readonly optionsCompareFn = SAMPLE_OPTIONS_COMPARE_FN;
  readonly faBarcode = faBarcode;
  readonly faQrcode = faQrcode;
  readonly sampleFormFieldLabel = SAMPLE_CHECK_IN_FORM_LABEL;
  readonly errorTypes = SAMPLE_ERROR_TYPES;
  readonly errorMessages = SAMPLE_ERROR_MESSAGES;
  readonly sampleImageValidators = SAMPLE_IMAGE_VALIDATORS;

  isSamplePhotoChanged = false;

  private previousRestrictions = new Array<string>();

  get nameFormControl(): UntypedFormControl {
    return this.formGroup.get(SampleCheckInFormField.NAME) as UntypedFormControl;
  }

  get barcodeFormControl(): UntypedFormControl {
    return this.formGroup.get(SampleCheckInFormField.BARCODE) as UntypedFormControl;
  }

  get qrcodeFormControl(): UntypedFormControl {
    return this.formGroup.get(SampleCheckInFormField.QRCODE) as UntypedFormControl;
  }

  get descriptionFormControl(): UntypedFormControl {
    return this.formGroup.get(SampleCheckInFormField.DESCRIPTION) as UntypedFormControl;
  }

  get restrictionFormControl(): UntypedFormControl {
    return this.formGroup.get(SampleCheckInFormField.RESTRICTION) as UntypedFormControl;
  }

  constructor(
    private cd: ChangeDetectorRef,
    public locationDisplayNamePipe: LocationDisplayNamePipe,
    private userService: UserManagementService,
  ) {}

  ngOnInit(): void {
    this.currentUserEmail = this.userService.getUserInfo()?.email || '';
    const projectOwnerControl = this.formGroup.controls[this.sampleCheckInFormField.OWNER];

    if (!projectOwnerControl.value) {
      projectOwnerControl.setValue(this.currentUserEmail);
    }
  }

  detectChanges(): void {
    this.cd.detectChanges();
  }

  onSamplePhotoUpload(e: any): void {
    if (e?.target?.files?.length) {
      const [file] = e.target.files;
      this.readBinaryFile(file, (name, data) => this.onSamplePhotoParsed(name, data));
    }
  }

  onOptionSelection(): void {
    const selectedOptions = this.restrictionFormControl.value;
    const [noRestrictionValue] = this.restrictionTypes.filter(
      (type) => type.name === 'No restriction',
    );

    if (
      selectedOptions.includes(noRestrictionValue.id) &&
      this.previousRestrictions.length &&
      !this.previousRestrictions.includes(noRestrictionValue.id as string)
    ) {
      this.restrictionFormControl.setValue([noRestrictionValue.id]);
    } else {
      const filteredOptions = selectedOptions.filter(
        (option: string) => option !== noRestrictionValue.id,
      );
      this.restrictionFormControl.setValue(
        filteredOptions.length ? filteredOptions : [noRestrictionValue.id],
      );
    }

    this.previousRestrictions = this.restrictionFormControl.value;
  }

  private readBinaryFile(file: File, callback: (name: string, data: any) => void): void {
    const fileName = file.name;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      callback(fileName, fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }

  private onSamplePhotoParsed(_name: string, data: string): void {
    this.picture.src = data;
    this.isSamplePhotoChanged = true;
    this.detectChanges();
  }
}

@NgModule({
  declarations: [SampleGeneralFormComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    GetAndAssignBarcodeModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    SampleTableSelectorPopupFieldModule,
    TreeViewSelectorPopupFieldModule,
    DisabledElementModule,
    UploadModule,
    MultiSelectModule,
  ],
  exports: [SampleGeneralFormComponent],
})
export class SampleGeneralFormModule {}
