import { Routes } from '@angular/router';

import { AdminSettingsComponent } from 'src/app/features/admin-settings/admin-settings.component';
import { DashboardComponent } from 'src/app/features/dashboard/dashboard/dashboard.component';
import { LookupLocationComponent } from 'src/app/features/data-access/lookup-location/lookup-location.component';
import { LookupSampleComponent } from 'src/app/features/data-access/lookup-sample/lookup-sample.component';
import { LookupWellComponent } from 'src/app/features/data-access/lookup-well/lookup-well.component';
import { LocationPageComponent } from 'src/app/features/location/location-page.component';
import { OrganizationSettingsComponent } from 'src/app/features/organization-settings/organization-settings.component';
import { CompositeSampleComponent } from 'src/app/features/sample/composite-sample/composite-sample.component';
import { SampleComponent } from 'src/app/features/sample/sample.component';
import { SampleCheckOutComponent } from 'src/app/features/sample/sample-check-out/sample-check-out.component';
import { WellInformationComponent } from 'src/app/features/well-information/well-information.component';

import { AccountSettingsComponent } from '../../features/account-settings/account-settings.component';
import { ApplicationMenuComponent } from '../../features/application-menu/application-menu.component';
import { AppMenuRoutePath } from '../../features/application-menu/enums/app-menu-route-path.enum';
import { ComplexDataUploaderComponent } from '../../features/complex-data-uploader/complex-data-uploader.component';
import { CoreAnalysisWizardComponent } from '../../features/core-analysis-wizard/core-analysis-wizard.component';
import { DataUploaderComponent } from '../../features/data-uploader/data-uploader.component';
import { DigitalMicpSimulationComponent } from '../../features/digital-micp-simulation/digital-micp-simulation.component';
import { SampleReturnComponent } from '../../features/sample/sample-return/sample-return.component';
import { WorkOrderComponent } from '../../features/work-order/work-order.component';

export const contentRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: ApplicationMenuComponent,
  },

  {
    path: AppMenuRoutePath.DASHBOARD_APP,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'location',
        redirectTo: 'location/',
        pathMatch: 'full',
      },
      {
        path: 'location/:id',
        component: LocationPageComponent,
      },
      {
        path: 'data-access',
        children: [
          {
            path: 'sample',
            children: [
              {
                path: '',
                component: LookupSampleComponent,
              },
              {
                path: 'edit/:sampleType/:sampleId',
                component: SampleComponent,
              },
            ],
          },
          {
            path: 'composite-sample',
            children: [
              {
                path: 'edit/:sampleId',
                component: CompositeSampleComponent,
              },
            ],
          },
          {
            path: 'location',
            component: LookupLocationComponent,
          },
          {
            path: 'well',
            children: [
              {
                path: '',
                component: LookupWellComponent,
              },
              {
                path: 'well-information',
                component: WellInformationComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'sample-check-in/:sampleType',
        component: SampleComponent,
      },
      {
        path: 'admin-settings',
        component: AdminSettingsComponent,
      },
      {
        path: 'organization-settings',
        component: OrganizationSettingsComponent,
      },
      {
        path: 'account-settings',
        component: AccountSettingsComponent,
      },
    ],
  },
  {
    path: AppMenuRoutePath.ACME_TRACKER_APP,
    children: [
      {
        path: '',
        redirectTo: 'data-access/sample',
        pathMatch: 'full',
      },
      {
        path: 'location',
        redirectTo: 'location/',
        pathMatch: 'full',
      },
      {
        path: 'location/:id',
        component: LocationPageComponent,
      },
      {
        path: 'data-access',
        children: [
          {
            path: 'sample',
            children: [
              {
                path: '',
                component: LookupSampleComponent,
              },
              {
                path: 'edit/:sampleType/:sampleId',
                component: SampleComponent,
              },
            ],
          },
          {
            path: 'composite-sample',
            children: [
              {
                path: 'edit/:sampleId',
                component: CompositeSampleComponent,
              },
            ],
          },
          {
            path: 'location',
            component: LookupLocationComponent,
          },
          {
            path: 'well',
            children: [
              {
                path: '',
                component: LookupWellComponent,
              },
              {
                path: 'well-information',
                component: WellInformationComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'sample-check-in/:sampleType',
        component: SampleComponent,
      },
      {
        path: 'simulation/micp',
        component: DigitalMicpSimulationComponent,
      },
      {
        path: 'simulation/ptsd',
        component: DigitalMicpSimulationComponent,
      },
      {
        path: 'sample/check-out',
        component: SampleCheckOutComponent,
      },
      {
        path: 'work-order',
        component: WorkOrderComponent,
      },
      {
        path: 'sample/return',
        component: SampleReturnComponent,
      },
      {
        path: 'data-uploader',
        component: DataUploaderComponent,
      },
      {
        path: 'complex-data-uploader',
        component: ComplexDataUploaderComponent,
      },
      {
        path: 'organization-settings',
        component: OrganizationSettingsComponent,
      },
      {
        path: 'admin-settings',
        component: AdminSettingsComponent,
      },
      {
        path: 'account-settings',
        component: AccountSettingsComponent,
      },
    ],
  },
  {
    path: AppMenuRoutePath.CORE_ANALYSIS_WIZARD_APP,
    children: [
      {
        path: '',
        redirectTo: 'core-analysis-wizard',
        pathMatch: 'full',
      },
      {
        path: 'core-analysis-wizard',
        component: CoreAnalysisWizardComponent,
      },
      {
        path: 'organization-settings',
        component: OrganizationSettingsComponent,
      },
      {
        path: 'admin-settings',
        component: AdminSettingsComponent,
      },
      {
        path: 'account-settings',
        component: AccountSettingsComponent,
      },
    ],
  },
];
