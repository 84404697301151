import { BehaviorSubject, EMPTY, merge } from 'rxjs';

import { ComponentType } from '@angular/cdk/portal';
import { NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Compiler,
  Component,
  Inject,
  Injector,
  NgModule,
  NgModuleFactory,
  OnDestroy,
  OnInit,
  PipeTransform,
  Type,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';

import { TreeViewNode } from '@core/models/tree-view-node.model';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { TreeViewSelectorPopupData } from 'src/app/common/tree-view-selector-popup-field/models/tree-view-selector-popup-data.model';
import { LocationSearchField } from 'src/app/features/data-access/lookup-location/enums/location-search-field.enum';

import { AutoFocusModule } from '../../directives/autofocus.directive';
import { LocationTreeFilterComponent } from '../../location-tree-filter/location-tree-filter.component';
import { FilterEventService } from '../../location-tree-filter/services/filter-event.service';
import { TreeViewFilter } from '../models/tree-view-filter.model';
import { TREE_VIEW_FILTER } from '../tokens/tree-view-filter.token';

@Component({
  // TODO: need refactoring
  selector: 'app-tree-view-selector-popup',
  templateUrl: './tree-view-selector-popup.component.html',
  styleUrls: ['./tree-view-selector-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeViewSelectorPopupComponent<T extends TreeViewNode<T>>
  extends Destroyable(Object)
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('filterDialogContent')
  filterDialogContent!: ComponentType<LocationTreeFilterComponent>;

  readonly noDescriptionValue = 'No description';
  readonly searchByOptions: Array<{ field: LocationSearchField; label: string }> = [
    { field: LocationSearchField.NAME, label: 'Location Name' },
    { field: LocationSearchField.DESCRIPTION, label: 'Description' },
  ];

  selectedTreeNode: T | null = null;
  treeControl = new NestedTreeControl<T>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<T>();
  searchNodeControl = new UntypedFormControl('');
  treeViewFilterInjector: Injector | null = null;
  treeViewFilter: TreeViewFilter | null = null;
  treeNodeDisplayNamePipe: PipeTransform | null | undefined = null;
  filterComponent: Type<any> | null = null;
  filterModule: Type<any> | null = null;
  filterModuleFactory!: NgModuleFactory<any>;

  locationSearchBy: LocationSearchField = this.searchByOptions[0].field;

  hasChild = (_: number, node: T) => !!node.children && node.children.length > 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TreeViewSelectorPopupData<T>,
    private dialog: MatDialog,
    private injector: Injector,
    private compiler: Compiler,
    private cd: ChangeDetectorRef,
    private filterEventService: FilterEventService,
  ) {
    super();
  }

  get title(): string {
    return this.data.title;
  }

  get selectedNodeName(): string {
    return this.data.selectedNodeName;
  }

  ngOnInit(): void {
    this.dataSource.data = this.data.treeNodes;
    this.treeControl.dataNodes = this.data.treeNodes;
    this.treeNodeDisplayNamePipe = this.data.treeNodeDisplayNamePipe;
    this.filterComponent = this.data.filterComponent ?? null;
    this.filterModule = this.data.filterModule ?? null;
    if (this.filterComponent && this.filterModule) {
      this.createInjector();
      this.createNgModuleFactory();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.listenToSearchOrFiltersChanges());
  }

  override ngOnDestroy(): void {
    this.filterEventService.clearPickedTypesAndRestrictions();
  }

  hasVisibleChildWhenFilterApplied(node: T): boolean {
    return node.children.some((childNode) => childNode.isVisibleWhenFilterApplied);
  }

  checkIfVisibleWhenFilterApplied(node: T): boolean {
    return !!node.isVisibleWhenFilterApplied;
  }

  selectNode(node: T): void {
    this.selectedTreeNode = node;
  }

  onFilterClick(): void {
    const dialogRef = this.dialog.open(this.filterDialogContent);

    dialogRef
      .afterClosed()
      .pipe(this.takeUntilDestroyed())
      .subscribe((_: boolean) => {
        this.filterEventService.filterPopupClosed.next(_);
      });
  }

  onByFieldChanged(): void {
    this.listenToSearchOrFiltersChanges();
  }

  private listenToSearchOrFiltersChanges(): void {
    const filterChanged$ = this.treeViewFilter?.filterChanged$.asObservable() ?? EMPTY;

    merge(this.searchNodeControl.valueChanges, filterChanged$)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.walkTreeAndSetVisibility(this.dataSource.data);
        if (
          this.searchNodeControl.value ||
          this.treeViewFilter?.filterChanged$.getValue().isFilterApplied
        ) {
          this.treeControl.expandAll();
        } else {
          this.treeControl.collapseAll();
        }
        this.cd.markForCheck();
      });
  }

  private createInjector(): void {
    this.treeViewFilter = {
      context: this.data.filterContext,
      filter: () => true,
      filterChanged$: new BehaviorSubject<{
        isFilterApplied: boolean;
      }>({
        isFilterApplied: false,
      }),
    };

    this.treeViewFilterInjector = Injector.create({
      providers: [
        {
          provide: TREE_VIEW_FILTER,
          useValue: this.treeViewFilter,
        },
      ],
      parent: this.injector,
    });
  }

  private createNgModuleFactory(): void {
    this.filterModuleFactory = this.compiler.compileModuleSync(this.data.filterModule!);
  }

  private walkTreeAndSetVisibility(nodes: T[]): void {
    nodes.forEach((node) => {
      this.setVisibilityState(node);

      if (node.children.length) {
        this.walkTreeAndSetVisibility(node.children);
      }
    });
  }

  private setVisibilityState(node: T): void {
    const isNodeMatchesSearchQuery = this.checkIfNodeMatchesSearchQuery(node);

    if (isNodeMatchesSearchQuery) {
      node.isVisibleWhenFilterApplied = true;
      return;
    }

    const hasChild = !!node.children.length;

    if (!hasChild) {
      node.isVisibleWhenFilterApplied = false;
      return;
    }

    node.isVisibleWhenFilterApplied = this.treeControl
      .getDescendants(node)
      .some((childNode) => this.checkIfNodeMatchesSearchQuery(childNode));
  }

  private includesSearchText(node: T): boolean {
    switch (true) {
      case this.locationSearchBy === this.searchByOptions[0].field:
        return this.data.searchFieldFilter
          ? this.data.searchFieldFilter.call(this, node)
          : node.name.toLowerCase().includes(this.searchNodeControl.value.toLowerCase());

      case this.locationSearchBy === this.searchByOptions[1].field:
        return this.data.searchFieldFilter
          ? this.data.searchFieldFilter.call(this, node)
          : node.description.toLowerCase().includes(this.searchNodeControl.value.toLowerCase());
      default:
        return true;
    }
  }

  private checkIfNodeMatchesSearchQuery(node: T): boolean {
    let isNodeMatched = this.includesSearchText(node);

    if (this.treeViewFilter && isNodeMatched) {
      isNodeMatched = this.treeViewFilter.filter(node);
    }

    return isNodeMatched;
  }
}

@NgModule({
  declarations: [TreeViewSelectorPopupComponent],
  imports: [
    MatDialogModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    CommonModule,
    MatTooltipModule,
    AutoFocusModule,
  ],
})
export class TreeViewSelectorPopupModule {}
