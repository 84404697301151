import { OktaCallbackComponent } from '@okta/okta-angular';

import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { DynamicAuthGuard } from '@core/guards/dynamic-auth.guard';
import { PreventLoginAccessGuard } from '@core/guards/prevent-login-access.guard';
import { AppLevelInfoResolver } from '@core/resolvers/app-level-info.resolver';

import { LoginComponent } from './features/login/login.component';
import { SignupComponent } from './features/signup/signup.component';
import { contentRoutes } from './layout/content/content.routes';
import { LayoutComponent } from './layout/layout.component';

export const oktaCallBackRoute: Route = {
  path: 'login/callback',
  component: OktaCallbackComponent,
};

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [PreventLoginAccessGuard],
  },
  {
    path: 'signup',
    canActivate: [false],
    component: SignupComponent,
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [DynamicAuthGuard],
    resolve: { appLevelInfo: AppLevelInfoResolver },
    children: contentRoutes,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
