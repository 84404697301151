import { Directive, HostListener, Input, NgModule } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { BarcodeControlNames } from 'src/app/common/directives/models/barcode-control-names.model';
import { BarcodeQrCodeService } from 'src/app/services/api/barcode-qr-code.service';

@Directive({
  selector: '[appGetAndAssignBarcode]',
})
export class GetAndAssignBarcodeDirective extends Destroyable(Object) {
  @Input() barcodeControlNames!: BarcodeControlNames;
  @HostListener('click') getAndAssignBarcode(): void {
    this.barcodeQrCodeService
      .getBarcodeQRCode()
      .pipe(this.takeUntilDestroyed())
      .subscribe((barcode) => {
        const { primary, secondary } = this.barcodeControlNames;
        const primaryControl = this.formGroupDirective.form.get(primary)!;
        const secondaryControl = this.formGroupDirective.form.get(secondary)!;

        primaryControl.setValue(barcode);
        secondaryControl.setValue(barcode);
      });
  }

  constructor(
    private barcodeQrCodeService: BarcodeQrCodeService,
    private formGroupDirective: FormGroupDirective,
  ) {
    super();
  }
}

@NgModule({
  declarations: [GetAndAssignBarcodeDirective],
  exports: [GetAndAssignBarcodeDirective],
})
export class GetAndAssignBarcodeModule {}
