import { BehaviorSubject, finalize, Observable, OperatorFunction } from 'rxjs';

import { Directive, NgModule } from '@angular/core';

@Directive({
  selector: '[appThrottleClick]',
  exportAs: 'throttleClick',
})
export class ThrottleClickDirective {
  isNextClickAllowed$ = new BehaviorSubject<boolean>(true);

  allowNextClickAfterFinalized<T>(): OperatorFunction<T, T> {
    return (source: Observable<T>): Observable<T> =>
      source.pipe(finalize(() => this.isNextClickAllowed$.next(true)));
  }
}

@NgModule({
  declarations: [ThrottleClickDirective],
  exports: [ThrottleClickDirective],
})
export class ThrottleClickModule {}
