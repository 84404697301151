import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  API_AUTHENTICATION_ERROR_MESSAGE,
  API_SERVER_ERROR_MESSAGE,
  API_UNKNOWN_ERROR_MESSAGE,
  PASS_ERROR_HEADER,
  TOKEN_EXPIRED_HEADER_NAME,
  USER_NOT_CREATED_ERROR_MESSAGE,
  X_USER_NOT_CREATED_HEADER_NAME,
} from '@core/constants/api.consts';
import { ErrorDetails } from '@core/models/error-details.model';

import { NotificationService } from '../../services/notification.service';

@Injectable()
export class ApiErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private notificationService: NotificationService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let shouldThrowOutside = false;
    let processedRequest = request.clone();

    if (processedRequest.headers.has(PASS_ERROR_HEADER)) {
      processedRequest = request.clone({
        headers: processedRequest.headers.delete(PASS_ERROR_HEADER),
      });

      shouldThrowOutside = true;
    }

    return next.handle(processedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        const isTokenExpired = error.headers.has(TOKEN_EXPIRED_HEADER_NAME);

        if (!isTokenExpired) {
          this.handleError(error);

          return shouldThrowOutside ? throwError(() => error) : EMPTY;
        }

        return throwError(() => error);
      }),
    );
  }

  private handleError(error: HttpErrorResponse): void {
    const errorsDetails: ErrorDetails = typeof error.error === 'object' ? error.error ?? {} : {};
    const isErrorDetails = 'statusCode' in errorsDetails && 'message' in errorsDetails;

    const errorCode = isErrorDetails ? errorsDetails.statusCode : error.status;
    const errorMessage = isErrorDetails ? errorsDetails.message : '';

    const isClientError = errorCode >= 400 && errorCode <= 499;
    const isAuthError = errorCode === 401;
    const isForbiddenError = errorCode === 403;
    const userNotCreated = error.headers.has(X_USER_NOT_CREATED_HEADER_NAME);
    const isServerError = errorCode >= 500;

    let userErrorMessage: string;

    if (isClientError) {
      if (isAuthError) {
        userErrorMessage = errorMessage || API_AUTHENTICATION_ERROR_MESSAGE;
      } else if (isForbiddenError && userNotCreated) {
        userErrorMessage = errorMessage || USER_NOT_CREATED_ERROR_MESSAGE;
        setTimeout(() => {
          this.oktaAuth.signOut();
        }, 2000);
      } else {
        userErrorMessage = errorMessage || API_UNKNOWN_ERROR_MESSAGE;
      }
    } else if (isServerError) {
      userErrorMessage = errorMessage || API_SERVER_ERROR_MESSAGE;
    } else {
      userErrorMessage = errorMessage || API_UNKNOWN_ERROR_MESSAGE;
    }

    this.notificationService.notifyError(userErrorMessage);
  }
}
