import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';
import { ExperimentField } from 'src/app/features/work-order/models/experiment-field.model';

@Injectable()
export class ExperimentFieldService {
  url = `${CORE_MEMORY_API}/ExperimentFields`;

  constructor(private httpClient: HttpClient) {}

  getListBy(experimentTypeId: string): Observable<ExperimentField[]> {
    return this.httpClient.get<ExperimentField[]>(this.url, {
      params: new HttpParams().set('experimentTypeId', experimentTypeId),
    });
  }
}
