import { Location } from '@angular/common';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { LocationTreeViewNode } from '@core/models/location-tree-view-node.model';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { SampleCheckInFormField } from './form/sample-check-in-form-field.enum';
import { SampleFormGroup } from './form/sample-form-group.enum';

export abstract class BaseSample extends Destroyable(Object) {
  isEdit = false;
  sampleFormGroup!: UntypedFormGroup;
  picture = new Image();
  sampleLocations = new Array<LocationTreeViewNode>();
  sampleImageId = '';
  sampleImageOriginId = '';

  get generalFormGroup(): UntypedFormGroup {
    return this.sampleFormGroup.get(SampleFormGroup.GENERAL) as UntypedFormGroup;
  }

  get barcodeFormControl(): UntypedFormControl {
    return this.generalFormGroup.get(SampleCheckInFormField.BARCODE) as UntypedFormControl;
  }

  get qrcodeFormControl(): UntypedFormControl {
    return this.generalFormGroup.get(SampleCheckInFormField.QRCODE) as UntypedFormControl;
  }

  constructor(protected location: Location) {
    super();
  }

  navigateToSampleLookup(): void {
    this.location.back();
  }

  onCancelClicked(): void {
    if (this.isEdit) {
      this.location.back();
    } else {
      this.resetForm();
      this.clearRouteParams();
    }
  }

  protected initComponentData(): void {
    if (this.sampleFormGroup) {
      this.sampleFormGroup.reset();
      this.sampleFormGroup.clearValidators();
    }

    this.picture = new Image();
  }

  protected resetForm(): void {
    if (this.sampleFormGroup) {
      this.sampleFormGroup.reset(undefined, { emitEvent: false });
    }
    this.picture = new Image();
  }

  protected abstract clearRouteParams(): void;
}
