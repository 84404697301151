import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';
import { Location } from '@core/models/location.model';
import { LocationResult } from 'src/app/features/data-access/lookup-location/models/location-result.model';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private url = `${CORE_MEMORY_API}/Locations`;

  constructor(private httpClient: HttpClient) {}

  create(location: Location): Observable<Location> {
    return this.httpClient.post<Location>(this.url, { ...location });
  }

  edit(location: Location): Observable<Location> {
    return this.httpClient.put<Location>(`${this.url}/${location.id}`, { ...location });
  }

  getById(locationId: string): Observable<Location> {
    return this.httpClient.get<Location>(`${this.url}/${locationId}`);
  }

  getAll(): Observable<Location[]> {
    return this.httpClient.get<Location[]>(this.url);
  }

  searchByBarcode(barcodeId: string): Observable<LocationResult[]> {
    return this.httpClient.get<LocationResult[]>(`${this.url}/BarQrCode/${barcodeId}`);
  }
}
