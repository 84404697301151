import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { IdName } from '@core/models/id-name.model';

import { MultiSelectModule } from '../multi-select/multi-select.component';
import { SimulationSettingsFormField } from './form/simulation-settings-form-field.enum';
import { SIMULATION_SETTINGS_FORM_LABEL } from './form/simulation-settings-form-labels';

@Component({
  selector: 'app-simulation-settings',
  templateUrl: './simulation-settings.component.html',
  styleUrls: ['./simulation-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationSettingsComponent {
  @Input() formGroup!: FormGroup;
  @Input() numberOfGpus = new Array<IdName>();
  @Input() numberOfCpus = new Array<IdName>();
  @Input() isPtsd = false;

  readonly simulationSettingsFormField = SimulationSettingsFormField;
  readonly simulationSettingsFormFieldFormLabel = SIMULATION_SETTINGS_FORM_LABEL;

  get simulationNameFormControl(): FormControl {
    return this.formGroup.get('simulationName') as FormControl;
  }
}

@NgModule({
  declarations: [SimulationSettingsComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MultiSelectModule,
    MatIconModule,
    MatButtonToggleModule,
    MatCheckboxModule,
  ],
  exports: [SimulationSettingsComponent],
})
export class SimulationSettingsModule {}
