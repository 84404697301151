<mat-form-field class="unit-input-field" appearance="outline" [formGroup]="control">
  <mat-label>{{ label }}</mat-label>
  <input matInput [formControlName]="unitInputField.FIELD_VALUE" [type]="type" autocomplete="off" />
  <span
    matSuffix
    appStopPropagationOnClick
    appDisabledElement
    [disabled]="control.disabled"
    (click)="selectUnit()"
    id="unit-select-popup-btn"
    [innerHTML]="displayOptions[control.get(unitInputField.UNIT_ID)!.value] | appParseUnit"
  ></span>
  <mat-error
    *ngIf="
      control.get(unitInputField.FIELD_VALUE)!.errors &&
      control.get(unitInputField.FIELD_VALUE)!.touched
    "
    >{{ getErrorMessage() }}</mat-error
  >
</mat-form-field>
