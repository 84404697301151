<form class="form" *ngIf="checkboxListOptions.length" [formGroup]="control">
  <mat-checkbox
    class="checkbox"
    *ngFor="let option of checkboxListOptions"
    color="primary"
    [name]="option.id"
    [formControlName]="option.id"
    >{{ option.name | capitalizeString }}</mat-checkbox
  >
</form>
