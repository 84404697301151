import { LocationPageState } from 'src/app/features/location/state/location-page.state';

export class EditLocationPageState extends LocationPageState {
  override get isActionBtnVisible(): boolean {
    return this.component.isAdmin;
  }

  override get isActionBtnAndPrintVisible(): boolean {
    return this.component.isAdmin;
  }

  override get isCancelBtnVisible(): boolean {
    return this.component.isAdmin;
  }

  override get actionBtnName(): string {
    return 'Save';
  }

  override get title(): string {
    return this.component.isAdmin ? 'Edit Location' : 'Location Details';
  }

  override get saveMethodName(): 'edit' {
    return 'edit';
  }

  override get successfullySavedNotificationMessage(): string {
    return 'Location has been saved';
  }

  override get isEditable(): boolean {
    return this.component.isAdmin;
  }

  override onSuccessSaved(withPrint: boolean): void {
    if (withPrint) {
      this.component.openPrintPopup();
    }

    this.component.initialLocation = {
      id: this.component.initialLocation.id,
      ...this.component.locationBodyComponent.locationForm.value,
    };
    this.component.updateLocationNodes();
  }
}
