import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';
import { LocationTreeViewNode } from '@core/models/location-tree-view-node.model';

@Injectable({
  providedIn: 'root',
})
export class LocationTreeService {
  private url = `${CORE_MEMORY_API}/Locations/nodes`;

  constructor(private httpClient: HttpClient) {}

  getLocationNodes(): Observable<LocationTreeViewNode[]> {
    return this.httpClient.get<LocationTreeViewNode[]>(this.url);
  }
}
