import { Gallery, GalleryModule, GalleryRef, ImageSize, ThumbnailsPosition } from 'ng-gallery';
import { GalleryState } from 'ng-gallery/lib/models/gallery.model';
import { concatMap, finalize, forkJoin, from, map } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { IdName } from '@core/models/id-name.model';
import { WellInformation } from '@core/models/well-information.model';
import { FlatFormValidationMessage } from '@core/utils/form/flat-form-validation-message.class';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { UploadModule } from '../../../common/directives/upload.directive';
import { SampleIdDisplayName } from '../../../common/sample-table-selector-popup-field/models/sample-display-name.interface';
import {
  SampleTableSelectorPopupFieldComponent,
  SampleTableSelectorPopupFieldModule,
} from '../../../common/sample-table-selector-popup-field/sample-table-selector-popup-field.component';
import { UnitInputField } from '../../../common/unit-input/enums/unit-input-field.enum';
import { UnitInputModule } from '../../../common/unit-input/unit-input.component';
import { AttachedFileService } from '../../../services/api/attached-file.service';
import { ComplexDataUploaderService } from '../../../services/api/complex-data-uploader.service';
import { FileProcessorService } from '../../../services/api/file-processor.service';
import { SampleService } from '../../../services/api/sample.service';
import { UnitAttributeService } from '../../../services/api/unit-attribute.service';
import { NotificationService } from '../../../services/notification.service';
import { WellInformationFormField } from '../../sample/form/sample-check-in-form-field.enum';
import { ThinSectionInformation } from '../../sample/models/thin-section-information.model';
import { SampleWellFormModule } from '../../sample/sample-well-form/sample-well-form.component';
import { thinSectionImageMock } from '../mocks/thin-section-mocks';
import {
  DigitalThinSection,
  DigitalThinSectionImage,
  DigitalThinSectionImp,
  ThinSectionImage,
  ThinSectionVendor,
} from '../models/thin-section-model';
import { ThinSectionImageImpl } from '../models/thin-section-shape-model';

@Component({
  selector: 'app-complex-data-thin-section',
  templateUrl: './complex-data-thin-section.component.html',
  styleUrls: ['./complex-data-thin-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplexDataThinSectionComponent
  extends Destroyable(Object)
  implements OnInit, AfterViewInit
{
  readonly compareWith = (o1: string | null, o2: string) => o1?.toLowerCase() === o2.toLowerCase();

  readonly tiffFileType = 'image/tiff';
  readonly fileTypes = ['image/jpeg', 'image/png', this.tiffFileType];

  orientations = new Array<IdName>();
  preservationTypes = new Array<IdName>();
  restrictionTypes = new Array<IdName>();
  lengthUnits: IdName[] = [];
  polarizationList: IdName[] = [];
  pixelSizeUnits: IdName[] = [];
  angleUnits: IdName[] = [];
  experimentTypes: IdName[] = [];
  stainings = new Array<IdName>();
  sectionOrigins = new Array<IdName>();
  sectionTypes = new Array<IdName>();

  galleryRef!: GalleryRef;
  selectedTab = new FormControl(0);
  thinSectionSampleId!: string;
  thinSectionsampleControl = new UntypedFormControl('');
  thinSectionFileName = '';
  thinSectionFileInputState: 'empty' | 'loading' | 'success' | 'error' = 'empty';
  uploadBtnText = 'Upload';
  showSpinner = false;
  thinSectionWellFormGroup!: UntypedFormGroup;
  thinSectionInformationFormGroup!: UntypedFormGroup;
  thinSectionVendorFormGroup!: UntypedFormGroup;
  thinSectionImageFormGroup!: UntypedFormGroup;
  pictureUrlThinSection: File[] = [];
  tifFileList: File[] = [];
  digitalThinSectionArray: ThinSectionImage[] = [];
  vendorInfoArray: ThinSectionVendor[] = [];
  imageFileNameListThinSection: IdName[] = [];
  selectedImageNameThinSection!: string;
  selectedFiles!: FileList;
  currentUploadIndex = 0;

  depthValidationMessage: FlatFormValidationMessage<Record<string, string>> | null = null;
  currentIndexImageCarousel = 0;

  @ViewChild('sampleFieldComponent')
  private sampleFieldComponent!: SampleTableSelectorPopupFieldComponent | null;

  constructor(
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private sampleService: SampleService,
    private unitAttributeService: UnitAttributeService,
    private attachedFileService: AttachedFileService,
    private complexDataUploaderService: ComplexDataUploaderService,
    private fileProcessorService: FileProcessorService,
    public gallery: Gallery,
  ) {
    super();
  }

  ngOnInit() {
    this.initLists();
    this.cd.markForCheck();
  }

  ngAfterViewInit(): void {
    this.thinSectionsampleControl.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((v) => {
      if (v) this.onSampleSelected(this.sampleFieldComponent?.selectedSample!);
      else this.clear();
    });
  }

  extractControl(name: string): UntypedFormControl {
    return this.thinSectionInformationFormGroup.get([name]) as UntypedFormControl;
  }

  nonZeroFieldValueOrNull(unitInputControlName: string): number | null {
    return (
      Number(this.extractControl(unitInputControlName)!.value[UnitInputField.FIELD_VALUE]) || null
    );
  }

  errorMessageFunctionForDepth(fieldName: string): () => string {
    return () => this.depthValidationMessage!.getMessageFor(fieldName);
  }

  onSliderChange(event: GalleryState) {
    this.currentIndexImageCarousel = event.currIndex!;

    if (
      this.thinSectionImageFormGroup.get('fileName')?.value !==
      this.imageFileNameListThinSection[event.currIndex!].id
    ) {
      this.thinSectionImageFormGroup
        .get('fileName')
        ?.setValue(this.imageFileNameListThinSection[event.currIndex!].id);
    }
  }

  SetSliderCurrentIndex(index: number) {
    if (index >= 0 && this.currentIndexImageCarousel !== index) {
      this.galleryRef.set(index);
    }
  }

  handleSelectedFile(e: Event): void {
    const target = e.target as HTMLInputElement;

    if (target?.files?.length) {
      this.selectedFiles = target.files;
      this.thinSectionFileInputState = 'loading';
      this.currentUploadIndex = 0;
      this.startLoadingSelectedFile(this.selectedFiles[0]);
    }
  }

  digitalThinSectionFormToArray(): void {
    if (this.selectedImageNameThinSection) {
      const prevIndex = this.getIndexThinSection(this.selectedImageNameThinSection);
      this.vendorInfoArray[prevIndex] = this.thinSectionVendorFormGroup.getRawValue();
      this.digitalThinSectionArray[prevIndex] = new ThinSectionImageImpl(
        this.thinSectionImageFormGroup.getRawValue(),
        this.digitalThinSectionArray[prevIndex].file,
      );
    }
  }

  clear(sampleFieldReset = false): void {
    this.thinSectionWellFormGroup.reset(undefined, { emitEvent: false });
    this.thinSectionInformationFormGroup.reset(undefined, { emitEvent: false });
    this.thinSectionVendorFormGroup.reset(undefined, { emitEvent: false });
    this.thinSectionImageFormGroup.reset(undefined, { emitEvent: false });

    if (sampleFieldReset) {
      this.thinSectionsampleControl.reset(undefined, { emitEvent: false });
      this.uploadBtnText = 'Upload';
    }

    this.thinSectionFileName = '';
    this.thinSectionFileInputState = 'empty';
    this.digitalThinSectionArray = [];
    this.vendorInfoArray = [];
    this.selectedImageNameThinSection = '';
    this.currentIndexImageCarousel = 0;
    this.imageFileNameListThinSection = [];
    this.pictureUrlThinSection = [];
    this.tifFileList = [];
    this.thinSectionSampleId = '';
    this.currentUploadIndex = 0;
    this.cd.markForCheck();
  }

  save(): void {
    this.digitalThinSectionFormToArray();

    if (!this.checkVendorNameIsEmpty()) {
      this.showSpinner = true;
      this.cd.markForCheck();
      if (this.tifFileList.length) this.uploadOriginalTifFile();
      else this.uploadThinSection();
    }

    if (!this.thinSectionVendorFormGroup.valid) {
      this.thinSectionVendorFormGroup.markAllAsTouched();
      this.notificationService.notifyError('Vendor name should not be empty.');
    }
  }

  onSampleSelected(selectedSample: SampleIdDisplayName): void {
    this.thinSectionSampleId = '';

    if (selectedSample && selectedSample.type === 'thinSection') {
      this.setGalleryConFig();
      this.thinSectionSampleId = selectedSample.id;
      this.loadThinSectionComplexData(selectedSample.id);
    } else {
      this.sampleFieldComponent!.clearField();
      this.notificationService.notifyInfo('Please select the Thin sample');
    }
    this.cd.detectChanges();
  }

  private checkVendorNameIsEmpty(): boolean {
    return this.vendorInfoArray.some((vendorInfo, index) => {
      if (vendorInfo.vendorName === '') this.galleryRef.set(index);

      return vendorInfo.vendorName === '';
    });
  }

  private uploadThinSection(): void {
    const thinSectionComplexData = {
      thinSectionWell: this.thinSectionWellFormGroup.getRawValue(),
      thinSectionInformation: this.thinSectionInformationFormGroup.getRawValue(),
      thinSectionVendor: this.vendorInfoArray,
      thinSectionImage: this.digitalThinSectionArray,
    };

    from(thinSectionComplexData.thinSectionImage)
      .pipe(
        concatMap((data) => {
          if (!data.fileId) {
            return this.attachedFileService.upload(data.file!);
          }

          return this.attachedFileService.getInfo(data.fileId);
        }),
        concatMap((uploadFile, index) => {
          if (!thinSectionComplexData.thinSectionImage[index]['fileId']) {
            thinSectionComplexData.thinSectionImage[index]['fileId'] = uploadFile.id;
          }

          const digitalThinSection = new DigitalThinSectionImp(
            thinSectionComplexData.thinSectionImage[index],
            thinSectionComplexData.thinSectionVendor[index],
          );
          return this.complexDataUploaderService.upload(
            digitalThinSection,
            this.thinSectionSampleId,
          );
        }),
        finalize(() => {
          this.clear(true);
          this.showSpinner = false;
          this.cd.markForCheck();
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.notificationService.notifySuccess('Thin Section successfully uploaded');
      });
  }

  private uploadOriginalTifFile(): void {
    from(this.tifFileList)
      .pipe(
        concatMap((file) => {
          return this.attachedFileService.upload(file);
        }),
        concatMap((uploadFile) => {
          return this.attachedFileService.attachFile(uploadFile.id, this.thinSectionSampleId);
        }),
        finalize(() => {
          this.uploadThinSection();
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe();
  }

  private startLoadingSelectedFile(file: File, uploadedFile = false): void {
    this.thinSectionFileName = file.name;
    const itsDuplicateImage = this.checkDuplicateImageThinSection(file.name);

    if (this.currentUploadIndex < this.selectedFiles.length) {
      if ((this.fileTypes.includes(file.type) && !itsDuplicateImage) || uploadedFile) {
        this.digitalThinSectionFormToArray();
        this.tiffFileLoader(file);
      } else {
        this.thinSectionFileInputState = 'error';
        const errorText = itsDuplicateImage
          ? 'This file already uploaded'
          : 'File type not support';
        this.notificationService.notifyError(errorText);
        this.nextFileLoad();
      }
    }
  }

  private nextFileLoad(): void {
    this.currentUploadIndex += 1;
    if (this.selectedFiles[this.currentUploadIndex])
      this.startLoadingSelectedFile(this.selectedFiles[this.currentUploadIndex]);
  }

  private tiffFileLoader(file: File): void {
    if (file.type === this.tiffFileType) {
      this.fileProcessorService
        .imagePreview(file)
        .pipe(this.takeUntilDestroyed())
        .subscribe((response) => {
          const jpegImage = new File([response], file.name, {
            type: response.type,
          });
          this.tifFileList.push(file);
          this.fileReader(jpegImage);
        });
    } else this.fileReader(file);
  }

  private fileReader(file: File): void {
    this.pictureUrlThinSection.push(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.galleryRef.addImage({
        src: reader.result as string,
        thumb: reader.result as string,
        type: 'image',
      });

      this.selectedImageNameThinSection = file.name;
      this.imageFileNameListThinSection.push({
        id: file.name,
        name: file.name.split('.')[0],
      });
      this.galleryRef.set(this.imageFileNameListThinSection.length - 1);
      this.thinSectionImageFormGroup.get('fileName')?.setValue(this.selectedImageNameThinSection);
      this.thinSectionFileInputState = 'success';
      this.nextFileLoad();
    };
  }

  private setGalleryConFig(): void {
    if (this.galleryRef) {
      this.galleryRef.reset();
      this.clear();
    }

    this.galleryRef = this.gallery.ref('image-gallery');
    this.galleryRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,
      slidingDuration: 0,
      thumbMouseSlidingDisabled: false,
      thumbSlidingDisabled: false,
      mouseSlidingDisabled: false,
      thumbDetached: true,
    });
  }

  private onChangeFileName(): void {
    this.thinSectionImageFormGroup
      .get('fileName')
      ?.valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe((id) => {
        this.SetSliderCurrentIndex(this.getIndexThinSection(id));
        this.loadImageThinSectionFormFields(id);
      });
  }

  private initLists(): void {
    forkJoin([
      this.sampleService.getOrientations(),
      this.sampleService.getPreservationTypes(),
      this.sampleService.getRestrictionTypes(),
      this.unitAttributeService.getAll().pipe(map(({ lengthUnits }) => lengthUnits)),
      this.unitAttributeService.getPolarization(),
      this.unitAttributeService.getPixelSize(),
      this.unitAttributeService.getAngle(),
      this.sampleService.getStainings(),
      this.sampleService.getSectionOrigins(),
      this.sampleService.getSectionTypes(),
    ]).subscribe((data) => {
      [
        this.orientations,
        this.preservationTypes,
        this.restrictionTypes,
        this.lengthUnits,
        this.polarizationList,
        this.pixelSizeUnits,
        this.angleUnits,
        this.stainings,
        this.sectionOrigins,
        this.sectionTypes,
      ] = data;
    });
  }

  private checkDuplicateImageThinSection(id: string): boolean {
    return !(this.getIndexThinSection(id) < 0);
  }

  private loadImageThinSectionFormFields(id: string): void {
    const prevValueIndex = this.getIndexThinSection(this.selectedImageNameThinSection);

    if (prevValueIndex >= 0) {
      const index = this.getIndexThinSection(id);
      this.updateImageFormArrayThinSection(prevValueIndex);
      this.renderDigitalThinSection(this.digitalThinSectionArray[index]);
      this.setValueVendorFromGroupValue(this.vendorInfoArray[index]);
      this.selectedImageNameThinSection = id;
    } else {
      this.digitalThinSectionArray.push(
        new ThinSectionImageImpl(
          this.thinSectionImageFormGroup.getRawValue(),
          this.pictureUrlThinSection[this.digitalThinSectionArray.length],
        ),
      );
      this.vendorInfoArray.push(this.thinSectionVendorFormGroup.getRawValue());
      this.renderDigitalThinSection(thinSectionImageMock, this.selectedImageNameThinSection);
      this.setValueVendorFromGroupValue();
    }

    this.cd.markForCheck();
  }

  private renderDigitalThinSection(data: ThinSectionImage, fileName = ''): void {
    this.setValueDigitalThinSectionForm(data, fileName);
  }

  private getValueDigitalThinSectionFrom(data?: ThinSectionImage, FileName = ''): ThinSectionImage {
    return {
      fileName: FileName !== '' ? FileName : data?.fileName ?? '',
      id: data?.id ?? null,
      fileId: data?.fileId ?? null,
      pixelSize: {
        [UnitInputField.UNIT_ID]:
          data?.pixelSize.unitId ?? this.pixelSizeUnits.length > 0 ? this.pixelSizeUnits[0].id : '',
        [UnitInputField.FIELD_VALUE]: data?.pixelSize.fieldValue ?? null,
      },
      angle: {
        [UnitInputField.UNIT_ID]:
          data?.angle.unitId ?? (this.angleUnits.length > 0 ? this.angleUnits[0].id : ''),
        [UnitInputField.FIELD_VALUE]: data?.angle.fieldValue ?? null,
      },
      polarizationId: data?.polarizationId ?? this.polarizationList[0].id,
      rotation: data?.rotation ?? null,
    };
  }

  private updateImageFormArrayThinSection(prevValueIndex: number): void {
    const imageFileNameListIndex = this.imageFileNameListThinSection.findIndex(
      (d) => d['id'] === this.selectedImageNameThinSection,
    );
    this.vendorInfoArray[prevValueIndex] = this.thinSectionVendorFormGroup.getRawValue();
    this.digitalThinSectionArray[prevValueIndex] = new ThinSectionImageImpl(
      this.thinSectionImageFormGroup.getRawValue(),
      this.digitalThinSectionArray[prevValueIndex].file,
    );
    this.digitalThinSectionArray[prevValueIndex].fileName =
      this.imageFileNameListThinSection[imageFileNameListIndex].id;
  }

  private getIndexThinSection(id: string): number {
    return this.digitalThinSectionArray.findIndex((d) => d['fileName'] === id);
  }

  private loadThinSectionComplexData(sampleId: string): void {
    this.showSpinner = true;
    this.sampleService
      .getById(sampleId)
      .pipe(this.takeUntilDestroyed())
      .subscribe((sample) => {
        this.createWellInformation(sample.wellInformation!);
        this.createThinSectionInformationFromGroup(sample.thinSectionInformation!);
        this.createVendorFromGroup();
        this.createDigitalThinSectionFromGroup();
        this.onChangeFileName();
        this.getUploadedDigitalThinSection();
        this.thinSectionWellFormGroup.disable();
        this.thinSectionInformationFormGroup.disable();
        this.cd.markForCheck();
      });
  }

  private createWellInformation(data: WellInformation): void {
    if (this.thinSectionWellFormGroup) {
      this.setValueWellInformationFormGroup(data);
    } else this.thinSectionWellFormGroup = this.getWellInformationFormGroup(data);
  }

  private setValueWellInformationFormGroup(data?: WellInformation): void {
    this.thinSectionWellFormGroup.patchValue(
      {
        [WellInformationFormField.WELL_ID]: data?.id ?? '',
        [WellInformationFormField.WELL_LOCATION]: data?.location ?? '',
        [WellInformationFormField.FIELD]: data?.field ?? '',
        [WellInformationFormField.RESERVOIR]: data?.reservoir ?? '',
      },
      {
        emitEvent: false,
      },
    );
  }

  private getWellInformationFormGroup(data?: WellInformation): UntypedFormGroup {
    return new UntypedFormGroup({
      [WellInformationFormField.WELL_ID]: new UntypedFormControl(data?.id ?? '', [
        Validators.nullValidator,
        // TODO: need to clarify validation
        // this.sampleType === SampleType.UNCATEGORIZED ? Validators.nullValidator : Validators.required,
      ]),
      [WellInformationFormField.WELL_LOCATION]: new UntypedFormControl({
        value: data?.location ?? '',
        disabled: true,
      }),
      [WellInformationFormField.FIELD]: new UntypedFormControl({
        value: data?.field ?? '',
        disabled: true,
      }),
      [WellInformationFormField.RESERVOIR]: new UntypedFormControl({
        value: data?.reservoir ?? '',
        disabled: true,
      }),
    });
  }

  private createThinSectionInformationFromGroup(data: ThinSectionInformation): void {
    if (!this.thinSectionInformationFormGroup) {
      this.thinSectionInformationFormGroup = new UntypedFormGroup({
        sampleNo: new UntypedFormControl(data?.sampleNo ?? ''),
        depthTop: new UntypedFormControl({
          [UnitInputField.UNIT_ID]: data.depthUnitId ?? 0,
          [UnitInputField.FIELD_VALUE]: data.depthTop ?? 0,
        }),
        depthBottom: new UntypedFormControl({
          [UnitInputField.UNIT_ID]: data.depthUnitId ?? 0,
          [UnitInputField.FIELD_VALUE]: data.depthBottom ?? 0,
        }),
        sectionNumber: new UntypedFormControl(data.sectionNumber ?? ''),
        sectionOriginId: new UntypedFormControl(data.sectionOriginId ?? ''),
        sectionTypeId: new UntypedFormControl(data.sectionTypeId ?? ''),
        phyDimX: new UntypedFormControl(data.phyDimX ?? ''),
        phyDimY: new UntypedFormControl(data.phyDimY ?? ''),
        phyDimZ: new UntypedFormControl(data.phyDimZ ?? ''),
        phyDimLengthUnitId: new UntypedFormControl(data.phyDimLengthUnitId ?? ''),
        stainingId: new UntypedFormControl(data.stainingId ?? ''),
        orientationId: new UntypedFormControl(data.orientationId ?? ''),
      });
    } else this.setValueThinSectionInformationFromGroup(data);
  }

  private setValueThinSectionInformationFromGroup(data: ThinSectionInformation): void {
    this.thinSectionInformationFormGroup.patchValue(
      {
        sampleNo: data?.sampleNo ?? '',
        depthTop: {
          [UnitInputField.UNIT_ID]: data.depthUnitId ?? 0,
          [UnitInputField.FIELD_VALUE]: data.depthTop ?? 0,
        },
        depthBottom: {
          [UnitInputField.UNIT_ID]: data.depthUnitId ?? 0,
          [UnitInputField.FIELD_VALUE]: data.depthBottom ?? 0,
        },
        sectionNumber: data.sectionNumber ?? '',
        sectionOriginId: data.sectionOriginId ?? '',
        sectionTypeId: data.sectionTypeId ?? '',
        phyDimX: data.phyDimX ?? '',
        phyDimY: data.phyDimY ?? '',
        phyDimZ: data.phyDimZ ?? '',
        phyDimLengthUnitId: data.phyDimLengthUnitId ?? '',
        stainingId: data.stainingId ?? '',
        orientationId: data.orientationId ?? '',
      },
      {
        emitEvent: false,
      },
    );
  }

  private createVendorFromGroup(data?: ThinSectionVendor): void {
    if (!this.thinSectionVendorFormGroup) {
      this.thinSectionVendorFormGroup = new UntypedFormGroup({
        id: new UntypedFormControl(data?.id ?? null),
        vendorName: new UntypedFormControl(data?.vendorName ?? '', [Validators.required]),
        internalLabFileNo: new UntypedFormControl(data?.internalLabFileNo ?? ''),
        reportNo: new UntypedFormControl(data?.reportNo ?? ''),
        reportDate: new UntypedFormControl(data?.reportDate ?? ''),
        dataAnalysisQcByAramco: new UntypedFormControl(data?.dataAnalysisQcByAramco ?? ''),
      });
    } else this.setValueVendorFromGroupValue();
  }

  private setValueVendorFromGroupValue(data?: ThinSectionVendor): void {
    this.thinSectionVendorFormGroup.patchValue(
      {
        id: data?.id ?? null,
        vendorName: data?.vendorName ?? '',
        internalLabFileNo: data?.internalLabFileNo ?? '',
        reportNo: data?.reportNo ?? '',
        reportDate: data?.reportDate ?? '',
        dataAnalysisQcByAramco: data?.dataAnalysisQcByAramco ?? '',
      },
      {
        emitEvent: false,
      },
    );
  }

  private getUploadedDigitalThinSection(): void {
    this.complexDataUploaderService
      .getAll(this.thinSectionSampleId)
      .pipe()
      .subscribe((digitalThinSection) => {
        let index = 0;
        from(digitalThinSection)
          .pipe(
            concatMap((t) => {
              return this.attachedFileService.downloadImage(t.fileId);
            }),
            finalize(() => {
              this.showSpinner = false;
              this.cd.markForCheck();
            }),
            this.takeUntilDestroyed(),
          )
          .subscribe((response) => {
            digitalThinSection[index]['file'] = new File(
              [response],
              digitalThinSection[index].fileName,
              {
                type: response.type,
              },
            );
            this.loadUploadedFiles(digitalThinSection, index);

            index += 1;
          });
      });
  }

  private loadUploadedFiles(digitalThinSection: DigitalThinSection[], index: number): void {
    this.uploadBtnText = 'Save';
    this.vendorInfoArray.push(digitalThinSection[index].laboratoryInformation);
    this.setValueVendorFromGroupValue(this.vendorInfoArray[index]);
    this.digitalThinSectionArray.push(
      new DigitalThinSectionImage(digitalThinSection[index], digitalThinSection[index].file!),
    );
    this.renderDigitalThinSection(this.digitalThinSectionArray[index]);
    this.selectedImageNameThinSection = digitalThinSection[index].fileName;
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(digitalThinSection[index]['file']!);
    this.selectedFiles = dataTransfer.files;
    this.currentUploadIndex = 0;
    this.startLoadingSelectedFile(digitalThinSection[index]['file']!, true);
  }

  private createDigitalThinSectionFromGroup(data?: ThinSectionImage): void {
    if (!this.thinSectionImageFormGroup) {
      this.thinSectionImageFormGroup = new UntypedFormGroup({
        id: new UntypedFormControl(data?.id ?? null),
        fileId: new UntypedFormControl(data?.fileId ?? null),
        fileName: new UntypedFormControl(data?.fileName ?? ''),
        pixelSize: new UntypedFormControl({
          [UnitInputField.UNIT_ID]: data?.pixelSize.unitId ?? '',
          [UnitInputField.FIELD_VALUE]: data?.pixelSize.fieldValue ?? '',
        }),
        angle: new UntypedFormControl({
          [UnitInputField.UNIT_ID]: data?.angle.unitId ?? '',
          [UnitInputField.FIELD_VALUE]: data?.angle.fieldValue ?? '',
        }),
        polarizationId: new UntypedFormControl(data?.polarizationId ?? this.polarizationList[0].id),
        rotation: new UntypedFormControl(data?.rotation ?? ''),
      });
    } else this.setValueDigitalThinSectionForm(data);
  }

  private setValueDigitalThinSectionForm(data?: ThinSectionImage, fileName = ''): void {
    this.thinSectionImageFormGroup.patchValue(this.getValueDigitalThinSectionFrom(data, fileName), {
      emitEvent: false,
    });
  }
}

@NgModule({
  declarations: [ComplexDataThinSectionComponent],
  exports: [ComplexDataThinSectionComponent],
  imports: [
    SampleTableSelectorPopupFieldModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    UploadModule,
    SampleWellFormModule,
    MatSelectModule,
    MatButtonToggleModule,
    UnitInputModule,
    MatSliderModule,
    GalleryModule,
    MatDatepickerModule,
  ],
})
export class ComplexDataThinSectionModule {}
