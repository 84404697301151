import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertFileSize',
})
export class ConvertFileSizePipe implements PipeTransform {
  transform(bytes: string): string {
    let bytesNumber = Number(bytes);
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

    let i = 0;

    for (i; bytesNumber > 1024; i += 1) {
      bytesNumber /= 1024;
    }

    return `${bytesNumber.toFixed(2)} ${units[i]}`;
  }
}

@NgModule({
  declarations: [ConvertFileSizePipe],
  exports: [ConvertFileSizePipe],
  imports: [],
})
export class ConvertFileSizeModule {}
