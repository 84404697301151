import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBarcode, faQrcode } from '@fortawesome/free-solid-svg-icons';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { PrintBarcodePopupData } from 'src/app/common/print-barcode-popup/print-barcode-popup-data.model';
import { GenerateBarcodeService } from 'src/app/services/generate-barcode.service';
import { PrintBarcodeService } from 'src/app/services/print-barcode.service';

@Component({
  selector: 'app-print-barcode-popup',
  templateUrl: './print-barcode-popup.component.html',
  styleUrls: ['./print-barcode-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintBarcodePopupComponent {
  readonly faBarcode = faBarcode;
  readonly faQrcode = faQrcode;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PrintBarcodePopupData,
    private generateBarcodeService: GenerateBarcodeService,
    private printBarcodeService: PrintBarcodeService,
  ) {}

  get canPrintBarcode(): boolean {
    return this.data.barcodeId !== '';
  }

  get canPrintQRCode(): boolean {
    return this.data.qrCodeId !== '';
  }

  printBarcode(): void {
    this.generateBarcodeService.generateBarcode(this.data.barcodeId).then((barcodeImg) => {
      this.printBarcodeService.print(barcodeImg);
    });
  }

  printQRCode(): void {
    this.generateBarcodeService.generateQRCode(this.data.qrCodeId).then((qrCodeImg) => {
      this.printBarcodeService.print(qrCodeImg);
    });
  }
}

@NgModule({
  declarations: [PrintBarcodePopupComponent],
  imports: [MatDialogModule, MatTooltipModule, CommonModule, FontAwesomeModule],
})
export class PrintBarcodePopupModule {}
