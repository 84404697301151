<form [formGroup]="formGroup" autocomplete="off">
  <table mat-table [dataSource]="dataSource">
    <ng-container
      *ngFor="let column of tableData[0] | keyvalue; let i = index"
      [matColumnDef]="column.key"
    >
      <th mat-header-cell *matHeaderCellDef>
        {{ tableHeaderLabel[column.key] }}
        <ng-container *ngIf="tableHeaderLabelInfo[column.key]">
          <mat-icon
            class="description-icon"
            matTooltipClass="description-text"
            [matTooltip]="tableHeaderLabelInfo[column.key] || ''"
            >info</mat-icon
          >
        </ng-container>
      </th>
      <td
        mat-cell
        [ngClass]="getClassName(element.value.phaseId, column.key)"
        *matCellDef="let element; let i = index"
        [formGroup]="element"
      >
        <input
          [readonly]="readonlyInput"
          *ngIf="!isDropDown(column.key)"
          matInput
          [formControlName]="column.key"
        />

        <div *ngIf="isDropDown(column.key)" class="sample-dimension-unit">
          <app-multi-select
            [formControlName]="column.key"
            [selectOptions]="tableDropdownOptions[column.key]"
            id="centerOffset-unit-input"
          >
          </app-multi-select>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</form>
