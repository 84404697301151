import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { forkJoin, map } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';

import { DigitalVolume } from '@core/models/digital-volume.model';
import { IdName } from '@core/models/id-name.model';
import { UserInfo } from '@core/models/user-info.model';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { DigitalVolumeInformationModule } from '../../common/digital-volume-information/digital-volume-information.component';
import { DigitalVolumeInformationFormField } from '../../common/digital-volume-information/form/digital-volume-information-form-field.enum';
import { DIGITAL_VOLUME_INFORMATION_FORM_LABEL } from '../../common/digital-volume-information/form/digital-volume-information-form-labels';
import { MultiSelectModule } from '../../common/multi-select/multi-select.component';
import { SampleTableSelectorPopupFieldModule } from '../../common/sample-table-selector-popup-field/sample-table-selector-popup-field.component';
import { SimulationSettingsFormField } from '../../common/simulation-settings/form/simulation-settings-form-field.enum';
import { SIMULATION_SETTINGS_FORM_LABEL } from '../../common/simulation-settings/form/simulation-settings-form-labels';
import { SimulationSettingsModule } from '../../common/simulation-settings/simulation-settings.component';
import { TableComponent, TableComponentModule } from '../../common/table/table.component';
import { TreeViewSelectorPopupFieldModule } from '../../common/tree-view-selector-popup-field/tree-view-selector-popup-field.component';
import { UnitInputField } from '../../common/unit-input/enums/unit-input-field.enum';
import { ComplexDataUploaderService } from '../../services/api/complex-data-uploader.service';
import { FileProcessorService } from '../../services/api/file-processor.service';
import { SampleService } from '../../services/api/sample.service';
import { SampleTypeService } from '../../services/api/sample-type.service';
import { SimulationService } from '../../services/api/simulation.service';
import { UnitAttributeService } from '../../services/api/unit-attribute.service';
import { UserService } from '../../services/api/user.service';
import { NotificationService } from '../../services/notification.service';
import { SampleWellFormModule } from '../sample/sample-well-form/sample-well-form.component';
import { numberOfGpuCpuList, tableDataMock } from './mocks/table.mock';
import { SimulationRun, SimulationRunShape } from './models/micp-simulation.model';

@Component({
  selector: 'app-digital-micp-simulation',
  templateUrl: './digital-micp-simulation.component.html',
  styleUrls: ['./digital-micp-simulation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigitalMicpSimulationComponent extends Destroyable(Object) implements OnInit {
  @Input() hideSampleSearch = false;
  @Input() physicalSampleId = '';
  @Input() isPtsd = false;
  @Output() closeDialogPopup: EventEmitter<void> = new EventEmitter<void>();

  title = 'MICP';
  sampleIdControl = new UntypedFormControl('');
  digitalMicpFormGroup!: FormGroup;
  digitalVolumeInfoFormArray: (DigitalVolume & { sampleId: string })[] = [];
  selectedDigitalVolumeFileFormControl = new UntypedFormControl('');
  digitalVolumeFileList: IdName[] = [];
  segmentedVolumeTypeId = '';
  pictureUrl!: string;
  sampleTypeList!: IdName[];
  volumeTypeList!: IdName[];
  dataFormatList!: IdName[];
  voxelSizeUnitList!: IdName[];
  depthUnitList!: IdName[];
  orientationList!: IdName[];
  userInfo!: UserInfo;
  showSpinner = false;

  tableData = JSON.parse(JSON.stringify(tableDataMock));
  processFileId = '';
  tableHeaderLabelArray: string[] = ['Phase ID', 'Phase type'];
  tableDropdownAttribute = ['phaseType'];
  readonlyInput = true;
  numberOfGpus = numberOfGpuCpuList;
  numberOfCpus = numberOfGpuCpuList;
  tableDropdownOptions: { [key: string]: IdName[] } = {
    phaseType: [
      { id: '1', name: 'Pore' },
      { id: '2', name: 'Grain' },
    ],
  };

  readonly direction = ['xy', 'xz', 'yz'];
  readonly digitalVolumeInformationFormField = DigitalVolumeInformationFormField;
  readonly digitalvolumeinformationformlabel = DIGITAL_VOLUME_INFORMATION_FORM_LABEL;

  readonly simulationSettingsFormField = SimulationSettingsFormField;
  readonly simulationSettingsFormFieldFormLabel = SIMULATION_SETTINGS_FORM_LABEL;

  @ViewChild('tableComponent', { static: false }) tableComponent!: TableComponent;

  get volumeInformationFormGroup(): FormGroup {
    return this.digitalMicpFormGroup.get('volumeInformation') as FormGroup;
  }

  get simulationSettingsFormGroup(): FormGroup {
    return this.digitalMicpFormGroup.get('simulationSettings') as FormGroup;
  }

  get simulationDirectionFormGroup(): FormGroup {
    return this.digitalMicpFormGroup.get('simulationDirection') as FormGroup;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private sampleService: SampleService,
    private sampleTypeService: SampleTypeService,
    private complexDataUploaderService: ComplexDataUploaderService,
    private unitAttributeService: UnitAttributeService,
    private fileProcessorService: FileProcessorService,
    private simulationService: SimulationService,
    private userService: UserService,
    private router: Router,
  ) {
    super();
    this.readUrlCheckPtsd();
  }

  ngOnInit() {
    if (this.isPtsd) this.initPtsd();

    this.showSpinner = true;
    this.initForm();
    this.initLists();
    this.trackSectionSizeChanges();
    this.trackSectionTypesChanges();
    this.getUserinfo();
    this.trackSelectedDigitalVolumeFileChanges();

    this.cd.detectChanges();
  }

  clear(): void {
    this.digitalMicpFormGroup.reset(undefined, { emitEvent: false });
    this.pictureUrl = '';
    this.tableData = JSON.parse(JSON.stringify(tableDataMock));
    this.sampleIdControl.reset();
  }

  save(): void {
    if (!this.digitalMicpFormGroup.valid) {
      this.digitalMicpFormGroup.updateValueAndValidity();
      this.digitalMicpFormGroup.markAllAsTouched();
      this.notificationService.notifyError(this.getFirstMessage());
    } else if (!this.atLeastOneCheckboxChecked() && this.isPtsd) {
      this.notificationService.notifyError('At least one simulation direction should be selected');
    } else {
      const { id } = this.volumeInformationFormGroup.getRawValue();
      const { simulationName, numberOfCpus, numberOfGpus } =
        this.simulationSettingsFormGroup.getRawValue();
      const porePhases: number[] = [];

      this.tableComponent?.dataSource!.data.forEach((data) => {
        if (data.value.phaseType === '1') porePhases.push(Number(data.value.phaseId));
      });

      if (porePhases.length) {
        const simulationDirection = this.getKeysWithTrueValues(
          this.simulationSettingsFormGroup.getRawValue(),
        ).join('');
        const simulation = new SimulationRunShape(
          this.userInfo.email,
          this.sampleIdControl.value,
          this.isPtsd,
          id,
          simulationName,
          porePhases,
          simulationDirection,
          numberOfGpus,
          numberOfCpus,
        );
        this.simulationRun(simulation);
      } else this.notificationService.notifyError('At least one pore phase is required');
    }
  }

  resetForm(tableComponent: TableComponent): void {
    this.digitalMicpFormGroup.markAllAsTouched();
    this.digitalMicpFormGroup.reset(undefined, { emitEvent: false });
    tableComponent.formGroup.markAllAsTouched();
    tableComponent.formGroup.reset(undefined, { emitEvent: false });
  }

  getMaxSliderValue(): number {
    return Math.max(
      Math.floor(Number(this.volumeInformationFormGroup!.get('gridSizeX')!.getRawValue()) / 10) *
        10 -
        10,
      0,
    );
  }

  initForm(): void {
    this.digitalMicpFormGroup = new FormGroup({
      volumeInformation: new FormGroup({
        id: new FormControl(''),
        [this.digitalVolumeInformationFormField.SEGMENT_VOLUME]: new FormControl(''),
        [this.digitalVolumeInformationFormField.SAMPLE_FILENAME]: new FormControl(''),
        [this.digitalVolumeInformationFormField.SAMPLE_NAME]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.DEPTH]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.SAMPLE_TYPE]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.SUBSAMPLE_NUMBER]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.ORIENTATION_ID]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.VOLUME_TYPE]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.DATA_FORMAT]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.ORIGIN_LOCATION_X]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.ORIGIN_LOCATION_Y]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.ORIGIN_LOCATION_Z]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.ORIGIN_LOCATION_UNIT]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.VOXEL_SIZE_X]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.VOXEL_SIZE_Y]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.VOXEL_SIZE_Z]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.VOXEL_SIZE_UNIT]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.GRID_SIZE_X]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.GRID_SIZE_Y]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.GRID_SIZE_Z]: new FormControl({
          value: '',
          disabled: true,
        }),
        [this.digitalVolumeInformationFormField.DIRECTION]: new FormControl(this.direction[0]),
        [this.digitalVolumeInformationFormField.ZOOM]: new FormControl(0),
      }),
      simulationSettings: new FormGroup({
        [this.simulationSettingsFormField.SIMULATION_NAME]: new FormControl(
          '',
          Validators.required,
        ),
        [this.simulationSettingsFormField.DESCRIPTION]: new FormControl(''),
        [this.simulationSettingsFormField.DIRECTION_X]: new FormControl(''),
        [this.simulationSettingsFormField.DIRECTION_Y]: new FormControl(''),
        [this.simulationSettingsFormField.DIRECTION_Z]: new FormControl(true),
        [this.simulationSettingsFormField.FLIP_X]: new FormControl(''),
        [this.simulationSettingsFormField.FLIP_Y]: new FormControl(''),
        [this.simulationSettingsFormField.FLIP_Z]: new FormControl(''),
        [this.simulationSettingsFormField.PTSD]: new FormControl(''),
        [this.simulationSettingsFormField.NUMBER_OF_GPUS]: new FormControl(
          this.isPtsd ? null : '1',
        ),
        [this.simulationSettingsFormField.NUMBER_OF_CPUS]: new FormControl(
          this.isPtsd ? '1' : null,
        ),
      }),
    });
  }

  atLeastOneCheckboxChecked(): boolean {
    return Object.values(this.simulationSettingsFormGroup.getRawValue()).some(
      (checkbox) => checkbox === true,
    );
  }

  private getKeysWithTrueValues(obj: { [key: string]: boolean }): string[] {
    return Object.entries(obj)
      .filter(([key, value]) => value === true)
      .map(([key]) => key);
  }

  private simulationRun(simulation: SimulationRun): void {
    this.simulationService
      .runSimulation(simulation)
      .pipe(this.takeUntilDestroyed())
      .subscribe((result) => {
        if (!result.isError) {
          const successMsg = this.isPtsd
            ? 'Ptsd Simulation successfully started'
            : 'Micp Simulation successfully started';
          this.notificationService.notifySuccess(successMsg);
          this.clear();
        }
        this.closeDialogPopup.emit();
      });
  }

  private readUrlCheckPtsd(): void {
    if (!this.isPtsd) this.isPtsd = this.router.url.includes('ptsd') === true;
  }

  private initPtsd(): void {
    this.title = 'PTSD';
  }

  private getUserinfo(): void {
    this.userService
      .getCurrentUserInfo()
      .pipe(this.takeUntilDestroyed())
      .subscribe((userInfo) => {
        this.userInfo = userInfo;
      });
  }

  private initLists(): void {
    forkJoin([
      this.sampleTypeService.getAll(),
      this.complexDataUploaderService.getVolumeTypes(),
      this.complexDataUploaderService.getDataFormats(),
      this.unitAttributeService.getAll().pipe(map(({ lengthUnits }) => lengthUnits)),
      this.unitAttributeService.getVoxelSize(),
      this.complexDataUploaderService.getOrientations(),
    ])
      .pipe(this.takeUntilDestroyed())
      .subscribe(
        ([
          sampleTypeList,
          volumeTypeList,
          dataFormatList,
          depthUnits,
          voxelSizeUnitList,
          orientationList,
        ]) => {
          this.sampleTypeList = sampleTypeList;
          this.volumeTypeList = volumeTypeList;
          this.dataFormatList = dataFormatList;
          this.depthUnitList = depthUnits;
          this.voxelSizeUnitList = voxelSizeUnitList;
          this.orientationList = orientationList;
          this.segmentedVolumeTypeId = this.volumeTypeList.filter(
            (volumeType) => volumeType.name === 'Segmentation',
          )[0].id;
          this.trackSampleChanges();
        },
      );
  }

  private setDigitalVolumeValue(data?: DigitalVolume): void {
    this.digitalMicpFormGroup.patchValue({
      volumeInformation: {
        id: data?.id ?? '',
        sampleFilename: data?.fileId ?? '',
        fileId: data?.fileId,
        processedFileId: data?.processedFileId,
        sampleName: data?.digitalVolumeName ?? '',
        depth: {
          [UnitInputField.UNIT_ID]: data?.depthUnitId ?? '',
          [UnitInputField.FIELD_VALUE]: data?.depth ?? '',
        },
        orientationId: data?.orientationId ?? '',
        sampleType: data?.sampleTypeId ?? '',
        subSampleNumber: data?.subSampleNumber ?? '',
        volumeType: data?.volumeTypeId ?? '',
        originLocationX: data?.originLocationX ?? '',
        originLocationY: data?.originLocationY ?? '',
        originLocationZ: data?.originLocationZ ?? '',
        originLocationUnitId: data?.originLocationUnitId ?? '1',
        voxelSizeX: data?.voxelSizeX ?? '',
        voxelSizeY: data?.voxelSizeY ?? '',
        voxelSizeZ: data?.voxelSizeZ ?? '',
        voxelSizeUnitId: data?.voxelSizeUnitId ?? '1',
        gridSizeX: data?.gridNumberX ?? '',
        gridSizeY: data?.gridNumberY ?? '',
        gridSizeZ: data?.gridNumberZ ?? '',
        dataFormat: data?.dataFormatId ?? '',
        direction: this.direction[0],
        zoom: 0,
      },
    });
  }

  private trackSampleChanges(): void {
    this.sampleIdControl.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((sampleId) => {
      this.digitalVolumeInfoFormArray = [];
      if (sampleId) this.loadSampleRelatedDigitalVolumeInfo(sampleId);
    });

    if (this.physicalSampleId) this.sampleIdControl.setValue(this.physicalSampleId);
    this.showSpinner = false;
    this.cd.markForCheck();
  }

  private loadSampleRelatedDigitalVolumeInfo(sampleId: string): void {
    this.complexDataUploaderService
      .getDigitalVolumeBy(sampleId)
      .pipe(this.takeUntilDestroyed())
      .subscribe((digitalVolumeList) => {
        const filteredDigitalVolumeList = digitalVolumeList.filter(
          (digitalVolume) => digitalVolume.volumeTypeId === this.segmentedVolumeTypeId,
        );

        this.digitalVolumeInfoFormArray = filteredDigitalVolumeList;

        if (this.digitalVolumeInfoFormArray.length > 0) {
          this.initializeDigitalVolumeFileList(this.digitalVolumeInfoFormArray);
          this.selectedDigitalVolumeFileFormControl.setValue(
            this.digitalVolumeInfoFormArray[this.digitalVolumeInfoFormArray.length - 1]?.fileId ??
              '',
          );
        } else {
          this.notificationService.notifyError(
            'No segmented sample in this Physical sample, Please select another Physical sample',
          );
          this.closeDialogPopup.emit();
        }

        this.cd.markForCheck();
      });
  }

  private initializeDigitalVolumeFileList(
    digitalVolumeList: (DigitalVolume & { sampleId: string })[],
  ): void {
    this.digitalVolumeFileList = digitalVolumeList.map((digitalVolume) => {
      return {
        id: digitalVolume.fileId,
        name: digitalVolume.fileName,
      };
    });
    this.cd.markForCheck();
  }

  private trackSelectedDigitalVolumeFileChanges(): void {
    this.selectedDigitalVolumeFileFormControl.valueChanges
      .pipe(this.takeUntilDestroyed())
      .subscribe((fileId) => {
        const digitalVolume = this.digitalVolumeInfoFormArray.filter(
          (data) => data.fileId === fileId,
        )[0];
        this.processFileId = digitalVolume.processedFileId;
        this.setDigitalVolumeValue(digitalVolume);
        this.phaseProperties(digitalVolume);
        this.loadSlice(this.processFileId);
      });
  }

  private phaseProperties(digitalVolume: DigitalVolume & { sampleId: string }): void {
    if (digitalVolume.phaseProperties?.length) {
      const phaseProperties = digitalVolume!.phaseProperties!.map((p, index) => {
        const { phaseId } = p;
        const { phaseType } = this.tableData[index] ?? this.tableData[0];
        return { phaseId, phaseType };
      });
      this.tableData = phaseProperties;
    } else {
      this.tableData = [];
      this.notificationService.notifyError(
        'Label extraction still in progress. Please check later',
      );
    }
  }

  private loadSlice(processFileId: string): void {
    this.fileProcessorService
      .getSliceBy(
        processFileId,
        this.digitalMicpFormGroup.get('volumeInformation.direction')?.value,
        this.digitalMicpFormGroup.get('volumeInformation.zoom')?.value,
      )
      .pipe(
        map((bl) => URL.createObjectURL(bl)),
        this.takeUntilDestroyed(),
      )
      .subscribe((objectUrl) => {
        this.pictureUrl = objectUrl;
        this.cd.markForCheck();
      });
  }

  private trackSectionSizeChanges(): void {
    this.digitalMicpFormGroup
      .get('volumeInformation.zoom')
      ?.valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.loadSlice(this.processFileId);
        this.cd.markForCheck();
      });
  }

  private trackSectionTypesChanges(): void {
    this.digitalMicpFormGroup
      .get('volumeInformation.direction')
      ?.valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.loadSlice(this.processFileId);
        this.cd.markForCheck();
      });
  }

  private getFirstMessage(): string {
    const controls = {
      ...this.simulationSettingsFormGroup.controls,
    };
    const controlWithError = Object.entries(controls).find(([, control]) => !!control.errors);
    const controlErrors = controlWithError?.[1].errors;

    if (controlErrors?.['required']) {
      return `${
        this.simulationSettingsFormFieldFormLabel[
          controlWithError?.[0] as SimulationSettingsFormField
        ]
      } should not be empty.`;
    }

    return 'Unhandled validation error';
  }
}

@NgModule({
  declarations: [DigitalMicpSimulationComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    TreeViewSelectorPopupFieldModule,
    MultiSelectModule,
    MatExpansionModule,
    SampleWellFormModule,
    DigitalVolumeInformationModule,
    SimulationSettingsModule,
    TableComponentModule,
    SampleTableSelectorPopupFieldModule,
    MatDialogModule,
    MatLegacyProgressSpinnerModule,
    MatTooltipModule,
  ],
  providers: [MatDialogRef],
  exports: [DigitalMicpSimulationComponent],
})
export class DigitalMicpSimulationModule {}
