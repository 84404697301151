import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';
import { IdName } from '@core/models/id-name.model';

@Injectable()
export class ExperimentTypeService {
  private url = `${CORE_MEMORY_API}/ExperimentTypes`;

  constructor(private httpClient: HttpClient) {}

  getAll(isDashboardWidget = false): Observable<IdName[]> {
    return this.httpClient.get<IdName[]>(`${this.url}/${isDashboardWidget}`);
  }
}
