import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import {
  CompositeSampleComponent,
  CompositeSampleModule,
} from 'src/app/features/sample/composite-sample/composite-sample.component';

@Component({
  selector: 'app-composite-sample-popup',
  templateUrl: './composite-sample-popup.component.html',
  styleUrls: ['./composite-sample-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompositeSamplePopupComponent {
  @ViewChild(CompositeSampleComponent)
  compositeSampleComponent!: CompositeSampleComponent;

  isSaveBtnSpinnerVisible = false;

  constructor(public dialogRef: MatDialogRef<CompositeSamplePopupComponent>) {}

  initiateSaving(): void {
    this.isSaveBtnSpinnerVisible = true;
    this.compositeSampleComponent.submitForm(this);
  }

  savingFinished(): void {
    this.isSaveBtnSpinnerVisible = false;
  }

  onCompositeSampleCreated(id: any): void {
    this.dialogRef.close(id);
  }
}

@NgModule({
  declarations: [CompositeSamplePopupComponent],
  imports: [
    MatDialogModule,
    CompositeSampleModule,
    MatIconModule,
    MatLegacyProgressSpinnerModule,
    NgIf,
  ],
  exports: [CompositeSamplePopupComponent],
})
export class CompositeSamplePopupModule {}
