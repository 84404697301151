<div class="search-wrapper">
  <div class="search-by-wrapper">
    <mat-form-field>
      <mat-label>Search By</mat-label>
      <mat-select
        [(ngModel)]="filterData.filterType"
        (ngModelChange)="onByFieldChanged($event)"
        id="well-search-by-filter-select"
      >
        <mat-option *ngFor="let field of searchByFields" [value]="field.value">
          {{ field.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <app-search-input
    class="app-search-input"
    [label]="'Type to search'"
    [placeholder]="searchPlaceholder"
    [value]="filterData.searchString"
    (searchEvent)="onSearch($event)"
    [showBarcodeIcon]="false"
    id="well-search-input"
  ></app-search-input>
  <div class="filter-button-wrapper" *ngIf="!isSelector"></div>
</div>

<div class="table-wrapper">
  <div class="spinner-container" *ngIf="dataSource.loadingCounter$ | async">
    <mat-spinner
      class="loading-icon"
      matSuffix
      [diameter]="150"
      [matTooltip]="'Loading...'"
    ></mat-spinner>
  </div>

  <table
    class="table custom-table"
    mat-table
    matSort
    [dataSource]="dataSource"
    [matSortActive]="filterData.sortColumnName"
    [matSortDirection]="filterData.sortDirection"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <ng-container matColumnDef="field">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Field</th>
      <td mat-cell *matCellDef="let row">{{ row.field }}</td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
      <td mat-cell *matCellDef="let row">{{ row.location }}</td>
    </ng-container>

    <ng-container matColumnDef="reservoir">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Reservoir</th>
      <td mat-cell *matCellDef="let row">{{ row.reservoir }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      class="{{ isSelector ? '' : 'details-hover' }}"
      [class.selected]="row.id === selectedWell?.id"
      [class.is-selector]="isSelector"
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="onWellRowClick(row)"
    ></tr>

    <tr class="mat-row table-row" *matNoDataRow>
      <td class="mat-cell" colspan="5">
        {{
          !dataSource.data.length
            ? 'No data. Start typing in the search box above'
            : 'No data matching the filter value'
        }}
      </td>
    </tr>
  </table>

  <mat-card>
    <button
      *ngIf="!isSelector"
      mat-button
      (click)="onCreateWellClick()"
      class="btn create"
      id="well-create-btn"
    >
      <mat-icon>add</mat-icon>
      <span>Create Well</span>
    </button>

    <mat-paginator
      class="mat-paginator"
      aria-label="Select page"
      [length]="(dataSource.count$ | async) || 0"
      [pageIndex]="filterData.page - 1"
      [pageSize]="filterData.pageSize"
      [pageSizeOptions]="[5, 10, 20, 30]"
    >
    </mat-paginator>
  </mat-card>
</div>
