<header class="header">
  <h1 class="title">{{ isKpi ? 'KPI' : 'Work Orders' }}</h1>

  <button
    id="dashboard-work-orders-options-btn"
    class="btn btn-small btn-primary options-btn"
    type="button"
    (click)="openOptionsPopup()"
  >
    <i class="fas fa-gear"></i>
    <span class="options-btn__text">Options</span>
  </button>
</header>

<div class="chart-container" #chartContainer>
  <div *ngIf="chart" [chart]="chart"></div>
</div>
