import { filter } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSortModule } from '@angular/material/sort';
import { Router } from '@angular/router';

import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { AccessRequestPopupComponent } from 'src/app/common/access-request-popup/access-request-popup.component';
import { SampleAccessRequest } from 'src/app/common/access-request-popup/models/sample-access-request.model';
import { UserNotificationService } from 'src/app/services/api/user-notification.service';
import { NotificationService } from 'src/app/services/notification.service';

import { ReplaceEmptyStringModule } from '../../../common/pipes/replace-empty-string.pipe';
import { StateName } from '../../data-access/lookup-sample/enums/access-status.enum';
import { RelatedSamplesInfo } from '../models/related-samples-info.model';

@Component({
  selector: 'app-sample-related-table',
  templateUrl: './sample-related-table.component.html',
  styleUrls: ['./sample-related-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleRelatedTableComponent extends Destroyable(Object) {
  @Input() relatedSamples = new Array<RelatedSamplesInfo>();
  @Input() isReadOnly = false;

  readonly stateName = StateName;
  readonly displayedColumns: Array<keyof RelatedSamplesInfo> = [
    'wellName',
    'type',
    'relationship',
    'depth',
    'sampleName',
    'owner',
    'description',
    'stateName',
  ];

  private get routerAppPrefix(): string {
    return `/${/(?<=\/)(.*?)(?=\/)/.exec(this.router.url)?.[0]}`;
  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private userNotificationService: UserNotificationService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  navigateToSample(sample: RelatedSamplesInfo): void {
    if (
      (sample.stateName === StateName.OWN || sample.stateName === StateName.APPROVED) &&
      !this.isReadOnly
    ) {
      this.router.navigate([
        `${this.routerAppPrefix}/data-access/sample/edit/${sample.type}/${sample.id}`,
      ]);
    }
  }

  onRequestClick(event: Event, sample: RelatedSamplesInfo): void {
    event.stopPropagation();
    this.openDialog(sample);
  }

  private openDialog(sample: RelatedSamplesInfo): void {
    const dialogRef = this.dialog.open(AccessRequestPopupComponent, {
      width: '400px',
      data: {
        sampleId: sample.id,
        sampleComplexName: this.getSampleComplexName(sample),
        owner: sample.owner,
        description: '',
      } as SampleAccessRequest,
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((accessRequest) => !!accessRequest),
        this.takeUntilDestroyed(),
      )
      .subscribe((accessRequest) => this.onAccessRequest(accessRequest, sample));
  }

  private onAccessRequest(sampleData: SampleAccessRequest, sample: RelatedSamplesInfo): void {
    this.userNotificationService
      .postSampleAccessRequest(sampleData.sampleId, sampleData.description)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess(`Access request sent successfully`);
        sample.stateName = StateName.PENDING;
        this.cd.detectChanges();
      });
  }

  private getSampleComplexName(sample: RelatedSamplesInfo): string {
    return `${sample.sampleName}-${sample.type}-${sample.depth.replace(/[^\d.-]/g, '')}-${
      sample.wellName
    }`;
  }
}

@NgModule({
  declarations: [SampleRelatedTableComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    ReplaceEmptyStringModule,
  ],
  exports: [SampleRelatedTableComponent],
})
export class SampleRelatedTableModule {}
