<div class="filter-block">
  <h2 class="filter-block__title">Sample Types</h2>

  <app-checkbox-list-field
    [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.DATE].selectedSampleTypeIds"
    [checkboxListOptions]="filterLists.sampleTypeList"
  ></app-checkbox-list-field>
</div>

<div class="filter-block">
  <h2 class="filter-block__title">Experiment types</h2>

  <app-multi-select
    class="half-width"
    label="Experiment types"
    [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.DATE].selectedExperimentTypeIds"
    [selectOptions]="filterLists.experimentTypeList"
    [multiSelect]="true"
  ></app-multi-select>
</div>

<div class="filter-block">
  <h2 class="filter-block__title">Teams</h2>

  <app-multi-select
    class="half-width"
    label="Teams"
    [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.DATE].selectedTeamIds"
    [selectOptions]="filterLists.teamList"
    [multiSelect]="true"
    [returnIdValue]="false"
  ></app-multi-select>
</div>

<div class="filter-block">
  <h2 class="filter-block__title">Status</h2>

  <app-checkbox-list-field
    [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.DATE].selectedExperimentStatuses"
    [checkboxListOptions]="filterLists.experimentStatusList"
  ></app-checkbox-list-field>
</div>

<div class="filter-block">
  <h2 class="filter-block__title">Wells</h2>

  <app-multi-select
    class="half-width"
    label="Wells"
    [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.DATE].selectedWellIds"
    [selectOptions]="filterLists.wellList"
    [multiSelect]="true"
  ></app-multi-select>
</div>

<div class="filter-block">
  <h2 class="filter-block__title">Duration</h2>

  <div class="fields-line">
    <mat-form-field appearance="outline" class="date-picker">
      <mat-label>Start date</mat-label>
      <input
        id="dashboard-kpi-options-popup-date-tab-start-date-input"
        readonly
        matInput
        [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.DATE].selectedStartDate"
        [matDatepicker]="dateTabStartDatePicker"
        (click)="dateTabStartDatePicker.open()"
        (focus)="dateTabStartDatePicker.open()"
      />
      <div matSuffix class="date-picker-action-buttons">
        <mat-datepicker-toggle [for]="dateTabStartDatePicker"></mat-datepicker-toggle>
        <mat-icon
          id="dashboard-kpi-options-popup-date-tab-start-date-clear-input-button"
          appStopPropagationOnClick
          appClearObjectProperty
          [object]="filterState[dashboardWidgetOptionsPopupTab.DATE]"
          [prop]="'selectedStartDate'"
          >close</mat-icon
        >
      </div>
      <mat-datepicker #dateTabStartDatePicker>
        <mat-datepicker-actions>
          <button
            id="dashboard-work-order-options-popup-date-tab-start-date-cancel-btn"
            class="btn btn-secondary btn-small"
            matDatepickerCancel
          >
            Cancel
          </button>
          <button
            id="dashboard-work-order-options-popup-date-tab-start-date-apply-btn"
            class="btn btn-primary btn-small"
            matDatepickerApply
          >
            Apply
          </button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="date-picker">
      <mat-label>End date</mat-label>
      <input
        id="dashboard-work-order-options-popup-date-tab-end-date-input"
        readonly
        matInput
        [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.DATE].selectedEndDate"
        [matDatepicker]="dateTabEndDatePicker"
        (click)="dateTabEndDatePicker.open()"
        (focus)="dateTabEndDatePicker.open()"
      />
      <div matSuffix class="date-picker-action-buttons">
        <mat-datepicker-toggle [for]="dateTabEndDatePicker"></mat-datepicker-toggle>
        <mat-icon
          id="dashboard-work-order-options-popup-date-tab-end-date-clear-input-button"
          appStopPropagationOnClick
          appClearObjectProperty
          [object]="filterState[dashboardWidgetOptionsPopupTab.DATE]"
          [prop]="'selectedEndDate'"
          >close</mat-icon
        >
      </div>
      <mat-datepicker #dateTabEndDatePicker>
        <mat-datepicker-actions>
          <button
            id="dashboard-work-order-options-popup-date-tab-end-date-cancel-btn"
            class="btn btn-secondary btn-small"
            matDatepickerCancel
          >
            Cancel
          </button>
          <button
            id="dashboard-work-order-options-popup-date-tab-end-date-apply-btn"
            class="btn btn-primary btn-small"
            matDatepickerApply
          >
            Apply
          </button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>
  </div>
</div>

<div class="filter-block">
  <h2 class="filter-block__title">Display</h2>

  <mat-radio-group
    color="primary"
    [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.DATE].selectedDisplay"
  >
    <mat-radio-button
      *ngFor="let display of filterLists.displayList"
      [id]="'dashboard-work-order-options-popup-date-tab-display-' + display.name + '-option'"
      [value]="display.id"
      >{{ display.name }}</mat-radio-button
    >
  </mat-radio-group>
</div>
