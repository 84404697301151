<table class="experiment-data-table">
  <thead>
    <tr class="header-row" *ngFor="let row of table.head">
      <th
        class="header-cell"
        [class.header-col-span-cell]="cell.colSpan !== 1"
        *ngFor="let cell of row.cells"
        [colSpan]="cell.colSpan"
        [rowSpan]="cell.rowSpan"
      >
        {{ cell.value }}
      </th>
    </tr>
  </thead>

  <tbody>
    <tr class="body-row" *ngFor="let row of table.body">
      <td
        class="body-cell"
        *ngFor="let cell of row.cells"
        [colSpan]="cell.colSpan"
        [rowSpan]="cell.rowSpan"
      >
        {{ cell.value }}
      </td>
    </tr>
  </tbody>
</table>
