<mat-dialog-content>
  <app-pcri-form
    [title]="currentFormTitle"
    [formData]="formData"
    [experimentTypeList]="experimentTypes"
    #appPcriForm
  ></app-pcri-form>
</mat-dialog-content>

<mat-dialog-actions>
  <div>
    <button
      *ngIf="formIndex > 1 && formIndex <= editBtnListeners.length - 1"
      class="btn btn-primary btn-big"
      (click)="previousForm()"
    >
      Previous
    </button>
  </div>

  <div>
    <button class="btn btn-secondary btn-big" [mat-dialog-close]="null">Close</button>
    <button
      class="btn btn-primary btn-big"
      [disabled]="!appPcriForm?.dynamicExperimentTemplateComponent?.experimentFields?.length"
      appThrottleClick
      #throttleClick="throttleClick"
      (click)="save(throttleClick, appPcriForm)"
    >
      Apply
    </button>
  </div>
</mat-dialog-actions>
