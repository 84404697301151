<color-picker
  [style.background-color]="colorWithChar"
  [cpOKButton]="true"
  [cpOKButtonText]="'Select'"
  [colorPicker]="colorWithChar"
  (colorPickerSelect)="onChange($event)"
  cpOutputFormat="hex"
  [cpSaveClickOutside]="false"
  [cpCancelButton]="true"
  cpOKButtonClass="btn btn-primary btn-small"
  cpCancelButtonClass="btn btn-secondary btn-small"
></color-picker>
