import { AfterViewInit, Directive, ElementRef, NgModule } from '@angular/core';

@Directive({
  selector: '[appAutofocusElement]',
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => this.el.nativeElement.focus());
  }
}

@NgModule({
  declarations: [AutoFocusDirective],
  exports: [AutoFocusDirective],
})
export class AutoFocusModule {}
