import { SortDirection } from '@angular/material/sort';

import { DropdownOptionExtended } from '@core/models/dropdown-option-extended.model';
import { StringValues } from '@core/types/string-values.type';

import { LocationTypeRestrictionFilters } from './location-type-restriction-filters.model';

export interface LocationFilters extends LocationTypeRestrictionFilters {
  locationFilterBy: {
    filterType: string;
    searchString: string;
    sortColumnName: string;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
  };
}

export class LocationFiltersImpl {
  locationTypes: Array<DropdownOptionExtended>;
  restrictionTypes: Array<DropdownOptionExtended>;
  locationFilterBy: {
    filterType: string;
    searchString: string;
    sortColumnName: string;
    sortDirection: SortDirection;
    page: number;
    pageSize: number;
  };

  constructor(locationFilters: StringValues<LocationFilters>) {
    this.locationTypes = locationFilters.locationTypes.map((locationType) => ({
      ...locationType,
      checked: true,
    }));
    this.restrictionTypes = locationFilters.restrictionTypes
      ? locationFilters.restrictionTypes.map((restrictionType) => ({
          ...restrictionType,
          checked: true,
        }))
      : [];
    this.locationFilterBy = {
      filterType: locationFilters.locationFilterBy.filterType,
      searchString: locationFilters.locationFilterBy.searchString,
      sortColumnName: locationFilters.locationFilterBy.sortColumnName,
      sortDirection: locationFilters.locationFilterBy.sortDirection as SortDirection,
      page: Number(locationFilters.locationFilterBy.page),
      pageSize: Number(locationFilters.locationFilterBy.pageSize),
    };
  }
}
