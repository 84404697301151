<h1 matDialogTitle>{{ title }}</h1>

<div class="search-wrapper">
  <div class="search-by-wrapper">
    <mat-form-field>
      <mat-label>Search By</mat-label>
      <mat-select
        [(ngModel)]="locationSearchBy"
        id="location-search-by-filter-select"
        (ngModelChange)="onByFieldChanged()"
      >
        <mat-option *ngFor="let option of searchByOptions" [value]="option.field">
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-form-field class="search-field">
    <mat-label class="search-field__label"
      ><mat-icon class="search-field__label__icon">search</mat-icon
      ><span class="search-field__label__text">Search</span></mat-label
    >
    <input
      type="text"
      matInput
      appAutofocusElement
      [formControl]="searchNodeControl"
      autocomplete="off"
      id="tree-view-popup-selector-input"
    />
  </mat-form-field>
  <div class="search-spacer"></div>
  <div class="filter-button-wrapper">
    <button
      class="btn btn-square btn-transparent"
      (click)="onFilterClick()"
      id="location-filter-btn"
    >
      <mat-icon>tune</mat-icon>
    </button>
  </div>
</div>

<ng-template #filterDialogContent>
  <h2 matDialogTitle>Filter locations</h2>
  <ng-container *ngIf="filterComponent && treeViewFilterInjector">
    <ng-container
      *ngComponentOutlet="
        filterComponent;
        injector: treeViewFilterInjector;
        ngModuleFactory: filterModuleFactory
      "
    ></ng-container>
  </ng-container>
  <mat-dialog-actions class="filter-dialog-actions">
    <button mat-button class="btn btn-secondary" [mat-dialog-close]="null">Close</button>
    <button mat-button class="btn btn-primary" [mat-dialog-close]="true">Apply</button>
  </mat-dialog-actions>
</ng-template>

<mat-dialog-content>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree">
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodeToggle
      [class.invisible]="!checkIfVisibleWhenFilterApplied(node)"
      [class.is-selected]="selectedTreeNode?.id === node.id"
      (click)="selectNode(node)"
    >
      {{ treeNodeDisplayNamePipe ? treeNodeDisplayNamePipe.transform(node) : node.name }}
      <mat-icon
        class="description-icon"
        matTooltipClass="description-text"
        [matTooltip]="node.description || noDescriptionValue"
        >info</mat-icon
      >
    </mat-tree-node>

    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div
        class="mat-tree-node"
        [class.invisible]="!checkIfVisibleWhenFilterApplied(node)"
        [class.is-selected]="selectedTreeNode?.id === node.id"
        (click)="selectNode(node)"
      >
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.name"
          class="toggle-button"
          [class.hidden]="!hasVisibleChildWhenFilterApplied(node)"
          id="tree-view-selector-expand-btn"
        >
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        {{ treeNodeDisplayNamePipe ? treeNodeDisplayNamePipe.transform(node) : node.name }}
        <mat-icon
          class="description-icon"
          matTooltipClass="description-text"
          [matTooltip]="node.description || noDescriptionValue"
          >info</mat-icon
        >
      </div>

      <div
        [class.invisible]="!treeControl.isExpanded(node) || !checkIfVisibleWhenFilterApplied(node)"
        role="group"
      >
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</mat-dialog-content>

<mat-dialog-actions>
  <span class="selected-tree-node"
    >Selected {{ selectedNodeName }}:
    {{
      treeNodeDisplayNamePipe
        ? treeNodeDisplayNamePipe.transform(selectedTreeNode)
        : selectedTreeNode?.name || '[no value]'
    }}</span
  >

  <button
    class="btn btn-secondary btn-big"
    [mat-dialog-close]="null"
    id="tree-view-popup-close-btn"
  >
    Close
  </button>
  <button
    class="btn btn-primary btn-big"
    [mat-dialog-close]="selectedTreeNode"
    [disabled]="!selectedTreeNode"
    id="tree-view-popup-apply-btn"
  >
    Select
  </button>
</mat-dialog-actions>
