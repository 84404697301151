import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { CACHE_KEYS } from '@core/constants/cache-keys.const';
import { HEX_COLOR_REGEX } from '@core/constants/consts';
import { Organization } from '@core/models/organization.model';
import { ValidationState } from '@core/models/validation-state.model';
import { FlatFormValidationMessage } from '@core/utils/form/flat-form-validation-message.class';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { ColorSpectrumPickerModule } from 'src/app/common/color-spectrum-picker/color-spectrum-picker.component';
import { UploadModule } from 'src/app/common/directives/upload.directive';
import { OrganizationService } from 'src/app/services/api/organization.service';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationService } from 'src/app/services/notification.service';

import { OrganizationFormField } from './models/organization-form-field.model';
import { ORGANIZATION_FORM_FIELD_ERROR_MESSAGE } from './models/organization-form-field-error-message.const';
import { ORGANIZATION_FORM_FIELD_NAME_TO_DISPLAY } from './models/organization-form-field-name-to-display.const';

@Component({
  selector: 'app-organization-settings',
  templateUrl: './organization-settings.component.html',
  styleUrls: ['./organization-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSettingsComponent extends Destroyable(Object) implements OnInit {
  organization!: Organization;
  formGroup!: FormGroup<any>;
  flatFormValidationMessage!: FlatFormValidationMessage<typeof OrganizationFormField>;

  organizationFormField = OrganizationFormField;

  get appNameFormControl(): FormControl {
    return this.formGroup.get(OrganizationFormField.APP_NAME) as FormControl<string>;
  }

  get appSloganFormControl(): FormControl {
    return this.formGroup.get(OrganizationFormField.APP_SLOGAN) as FormControl<string>;
  }

  get appDescriptionFormControl(): FormControl {
    return this.formGroup.get(OrganizationFormField.APP_DESCRIPTION) as FormControl<string>;
  }

  get organizationNameFormControl(): FormControl {
    return this.formGroup.get(OrganizationFormField.ORGANIZATION_NAME) as FormControl<string>;
  }

  get primaryColorFormControl(): FormControl {
    return this.formGroup.get(OrganizationFormField.PRIMARY_COLOR) as FormControl<string>;
  }

  get secondaryColorFormControl(): FormControl {
    return this.formGroup.get(OrganizationFormField.SECONDARY_COLOR) as FormControl<string>;
  }

  get landingPageBackgroundImagePathFormControl(): FormControl {
    return this.formGroup.get(OrganizationFormField.LANDING_PAGE_IMAGE_PATH) as FormControl<string>;
  }

  get organizationFullIconImagePathFormControl(): FormControl {
    return this.formGroup.get(
      OrganizationFormField.ORGANIZATION_FULL_ICON_PATH,
    ) as FormControl<string>;
  }

  get organizationSmallIconImagePathFormControl(): FormControl {
    return this.formGroup.get(
      OrganizationFormField.ORGANIZATION_SMALL_ICON_PATH,
    ) as FormControl<string>;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private organizationService: OrganizationService,
    private cacheService: CacheService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  initFormGroup(): void {
    this.formGroup = new FormGroup({
      [OrganizationFormField.APP_NAME]: new FormControl(this.organization.appName, [
        Validators.required,
        Validators.maxLength(64),
      ]),
      [OrganizationFormField.APP_SLOGAN]: new FormControl(this.organization.appSlogan, [
        Validators.required,
        Validators.maxLength(128),
      ]),
      [OrganizationFormField.APP_DESCRIPTION]: new FormControl(this.organization.appDescription, [
        Validators.required,
        Validators.maxLength(256),
      ]),
      [OrganizationFormField.ORGANIZATION_NAME]: new FormControl(
        this.organization.organizationName,
        [Validators.required, Validators.maxLength(64)],
      ),
      [OrganizationFormField.PRIMARY_COLOR]: new FormControl(this.organization.primaryColor, [
        Validators.required,
        Validators.pattern(HEX_COLOR_REGEX),
      ]),
      [OrganizationFormField.SECONDARY_COLOR]: new FormControl(this.organization.secondaryColor, [
        Validators.required,
        Validators.pattern(HEX_COLOR_REGEX),
      ]),
      [OrganizationFormField.LANDING_PAGE_IMAGE_PATH]: new FormControl(
        this.organization.landingPageBackgroundImagePath,
        [Validators.required],
      ),
      [OrganizationFormField.ORGANIZATION_FULL_ICON_PATH]: new FormControl(
        this.organization.organizationFullIconImagePath,
        [Validators.required],
      ),
      [OrganizationFormField.ORGANIZATION_SMALL_ICON_PATH]: new FormControl(
        this.organization.organizationSmallIconImagePath,
        [Validators.required],
      ),
    });

    this.setUpValidationMessages();
  }

  init(): void {
    this.organizationService
      .getCurrentOrganization()
      .pipe(this.takeUntilDestroyed())
      .subscribe((response) => {
        this.organization = response;
        this.initFormGroup();
        this.cd.detectChanges();
      });
  }

  onColorSelected(color: string, formControl: FormControl): void {
    formControl.setValue(color);
  }

  apply(): void {
    this.cd.detectChanges();
    const validationState = this.checkIfFormIsValid();

    if (!validationState.isValid) {
      this.notificationService.notifyError(validationState.errorMessage);
      return;
    }

    this.organizationService
      .updateCurrentOrganization(this.organization.id, this.formGroup.getRawValue())
      .pipe(this.takeUntilDestroyed())
      .subscribe((organization: Organization) => {
        this.notificationService.notifySuccess('Organization settings are updated');
        this.cacheService.deleteFromCache(CACHE_KEYS.CURRENT_ORGANIZATION);
        this.organizationService.currentOrganizationData = organization;
        this.organizationService.applyOrganization();
      });
  }

  cancel(): void {
    this.initFormGroup();
  }

  uploadFile(image: any, formControl: FormControl): void {
    if (image?.target?.files?.length) {
      const [file] = image.target.files;
      this.readBinaryFile(file, (name, data) => this.onSamplePhotoParsed(name, data, formControl));
    }
  }

  private setUpValidationMessages(): void {
    this.flatFormValidationMessage = new FlatFormValidationMessage<typeof OrganizationFormField>(
      this.formGroup,
      ORGANIZATION_FORM_FIELD_NAME_TO_DISPLAY,
      ORGANIZATION_FORM_FIELD_ERROR_MESSAGE,
    );
  }

  private checkIfFormIsValid(): ValidationState {
    if (this.formGroup.status === 'VALID') {
      return { isValid: true };
    }

    return {
      isValid: false,
      errorMessage: this.flatFormValidationMessage.getFirstMessage(),
    };
  }

  private readBinaryFile(file: File, callback: (name: string, data: any) => void): void {
    const fileName = file.name;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      callback(fileName, fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }

  private onSamplePhotoParsed(_name: string, data: string, formControl: FormControl): void {
    formControl.setValue(data);
    this.cd.detectChanges();
  }
}

@NgModule({
  declarations: [OrganizationSettingsComponent],
  exports: [OrganizationSettingsComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    ColorSpectrumPickerModule,
    UploadModule,
  ],
  providers: [],
})
export class OrganizationSettingsModule {}
