import { DigitalVolumeInformationFormField } from './digital-volume-information-form-field.enum';

export const DIGITAL_VOLUME_INFORMATION_FORM_LABEL: Record<
  DigitalVolumeInformationFormField,
  string
> = {
  [DigitalVolumeInformationFormField.SEGMENT_VOLUME]: 'Search segmented volume',
  [DigitalVolumeInformationFormField.SAMPLE_FILENAME]: 'Sample filename',
  [DigitalVolumeInformationFormField.SAMPLE_NAME]: 'Sample name',
  [DigitalVolumeInformationFormField.DEPTH]: 'Depth',
  [DigitalVolumeInformationFormField.SAMPLE_TYPE]: 'Sample type',
  [DigitalVolumeInformationFormField.SUBSAMPLE_NUMBER]: 'Subsample number',
  [DigitalVolumeInformationFormField.ORIENTATION_ID]: 'Orientation',
  [DigitalVolumeInformationFormField.VOLUME_TYPE]: 'Volume type',
  [DigitalVolumeInformationFormField.ORIGIN_LOCATION_X]: 'originLocationX',
  [DigitalVolumeInformationFormField.ORIGIN_LOCATION_Y]: 'originLocationY',
  [DigitalVolumeInformationFormField.ORIGIN_LOCATION_Z]: 'originLocationZ',
  [DigitalVolumeInformationFormField.ORIGIN_LOCATION_UNIT]: 'originLocationUnitId',
  [DigitalVolumeInformationFormField.VOXEL_SIZE_X]: 'voxelSizeX',
  [DigitalVolumeInformationFormField.VOXEL_SIZE_Y]: 'voxelSizeY',
  [DigitalVolumeInformationFormField.VOXEL_SIZE_Z]: 'voxelSizeZ',
  [DigitalVolumeInformationFormField.VOXEL_SIZE_UNIT]: 'voxelSizeUnitId',
  [DigitalVolumeInformationFormField.GRID_SIZE_X]: 'gridSizeX',
  [DigitalVolumeInformationFormField.GRID_SIZE_Y]: 'gridSizeY',
  [DigitalVolumeInformationFormField.GRID_SIZE_Z]: 'gridSizeZ',
  [DigitalVolumeInformationFormField.VOXEL_SIZE]: 'Voxel size',
  [DigitalVolumeInformationFormField.VOXEL_UNIT]: 'Unit',
  [DigitalVolumeInformationFormField.GRID_SIZE]: 'grid size',
  [DigitalVolumeInformationFormField.DIRECTION]: 'Direction',
  [DigitalVolumeInformationFormField.ZOOM]: 'Zoom',
  [DigitalVolumeInformationFormField.ZOOMUNIT]: 'Zoom unit',
  [DigitalVolumeInformationFormField.DATA_FORMAT]: 'Data format',
};
