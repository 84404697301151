import {
  SAMPLE_TYPE_INFO_CLASS_MAP,
  SAMPLE_TYPE_INFO_KEY_MAP,
} from '@core/constants/sample.consts';
import { DefaultSampleType } from '@core/types/default-sample-type.type';
import { ThinSectionInformation } from 'src/app/features/sample/models/thin-section-information.model';

import { SampleType } from '../../../common/sample-check-in-menu/enums/sample-type.enum';
import { CoreInformation } from '../../../features/sample/models/core-information.model';
import { CuttingsIntervalInformation } from '../../../features/sample/models/cuttings-interval-information.model';
import { FluidInformation } from '../../../features/sample/models/fluid-information.model';
import { PlugInformation } from '../../../features/sample/models/plug-information.model';
import { SampleGetById } from '../../../features/sample/models/sample-get-by-id.model';
import { UncategorizedInformation } from '../../../features/sample/models/uncategorized-information.model';

export function extractDepthValueFromGetById(sample: SampleGetById): string {
  if (sample.type === SampleType.COMPOSITE) {
    return '';
  }

  const sampleType = sample.type as DefaultSampleType;
  const sampleInfoKey = SAMPLE_TYPE_INFO_KEY_MAP[sampleType];
  // @ts-ignore
  const sampleInfo = new SAMPLE_TYPE_INFO_CLASS_MAP[sampleType](sample[sampleInfoKey]!);

  if (
    sampleInfo instanceof PlugInformation ||
    sampleInfo instanceof FluidInformation ||
    sampleInfo instanceof UncategorizedInformation
  ) {
    return String(sampleInfo.depth);
  }

  if (
    sampleInfo instanceof CoreInformation ||
    sampleInfo instanceof CuttingsIntervalInformation ||
    sampleInfo instanceof ThinSectionInformation
  ) {
    return `${sampleInfo.depthTop}-${sampleInfo.depthBottom}`;
  }

  return '';
}
