import { PcriFormShape } from 'src/app/features/pcri-form/models/pcri-form-shape.model';

import { CoreAnalysisWizardEnum } from '../../core-analysis-wizard/core-analysis-wizard.enum';
import { ExperimentField } from '../../work-order/models/experiment-field.model';

export interface Pcri {
  [CoreAnalysisWizardEnum.ID]?: string;
  [CoreAnalysisWizardEnum.PARENT_ID]?: string;
  [CoreAnalysisWizardEnum.TITLE]?: string;
  [CoreAnalysisWizardEnum.NAME]?: string;
  [CoreAnalysisWizardEnum.DESCRIPTION]?: string;
  [CoreAnalysisWizardEnum.PROPONENT_EMAIL]?: string;
  [CoreAnalysisWizardEnum.PROPONENT_NAME]?: string;
  [CoreAnalysisWizardEnum.START_DATE]?: string;
  [CoreAnalysisWizardEnum.EXPECT_DATE]?: string;
  [CoreAnalysisWizardEnum.SAMPLE_ID]?: string;
  [CoreAnalysisWizardEnum.PROGRESS]?: number | null;
  [CoreAnalysisWizardEnum.COMPLETION_DATE]?: string;
  [CoreAnalysisWizardEnum.COMPLETED]?: boolean;
  [CoreAnalysisWizardEnum.CLEANING_METHOD]?: string;
  [CoreAnalysisWizardEnum.EFFLUENT_COLOR]?: string;
  [CoreAnalysisWizardEnum.BRINE_CONDITION]?: string;
  [CoreAnalysisWizardEnum.FRF]?: string;
  [CoreAnalysisWizardEnum.M]?: string;
  [CoreAnalysisWizardEnum.KW]?: string;
  [CoreAnalysisWizardEnum.PCRI]?: string;
  [CoreAnalysisWizardEnum.SATURATION_POINT]?: string;
  [CoreAnalysisWizardEnum.NOTE]?: string;
  [CoreAnalysisWizardEnum.EXPERIMENT_FIELDS]?: ExperimentField[];
  [CoreAnalysisWizardEnum.EXPERIMENT_TYPE_ID]?: string;
  [CoreAnalysisWizardEnum.PARENT_WORK_ORDER_ID]?: string;
}

export class PcriImpl implements Pcri {
  [CoreAnalysisWizardEnum.ID]?: string;
  [CoreAnalysisWizardEnum.PARENT_ID]?: string;
  [CoreAnalysisWizardEnum.NAME]?: string;
  [CoreAnalysisWizardEnum.DESCRIPTION]?: string;
  [CoreAnalysisWizardEnum.PROPONENT_EMAIL]?: string;
  [CoreAnalysisWizardEnum.PROPONENT_NAME]?: string;
  [CoreAnalysisWizardEnum.START_DATE]?: string;
  [CoreAnalysisWizardEnum.EXPECT_DATE]?: string;
  [CoreAnalysisWizardEnum.SAMPLE_ID]?: string;
  [CoreAnalysisWizardEnum.PROGRESS]?: number | null;
  [CoreAnalysisWizardEnum.COMPLETION_DATE]?: string;
  [CoreAnalysisWizardEnum.COMPLETED]?: boolean;
  [CoreAnalysisWizardEnum.CLEANING_METHOD]?: string;
  [CoreAnalysisWizardEnum.EFFLUENT_COLOR]?: string;
  [CoreAnalysisWizardEnum.BRINE_CONDITION]?: string;
  [CoreAnalysisWizardEnum.FRF]?: string;
  [CoreAnalysisWizardEnum.M]?: string;
  [CoreAnalysisWizardEnum.KW]?: string;
  [CoreAnalysisWizardEnum.PCRI]?: string;
  [CoreAnalysisWizardEnum.SATURATION_POINT]?: string;
  [CoreAnalysisWizardEnum.NOTE]?: string;
  [CoreAnalysisWizardEnum.EXPERIMENT_FIELDS]?: ExperimentField[];
  [CoreAnalysisWizardEnum.EXPERIMENT_TYPE_ID]?: string;
  [CoreAnalysisWizardEnum.PARENT_WORK_ORDER_ID]?: string;

  constructor(
    expandedForm: PcriFormShape & {
      sampleId: string | null;
      experimentFields: ExperimentField[];
      parentWorkOrderId?: string | null;
    },
  ) {
    this.id = expandedForm.generalInformation.id || undefined;
    this.name = expandedForm.generalInformation.name;
    this.proponentEmail = expandedForm.generalInformation.proponentEmail;
    this.proponentName = expandedForm.generalInformation.proponentName;
    this.description = expandedForm.generalInformation.description;
    this.startDate = expandedForm.generalInformation.startDate;
    this.expectDate = expandedForm.generalInformation.expectDate;

    this.progress = expandedForm.status.progress;
    this.completionDate = expandedForm.status.completionDate;
    this.completed = expandedForm.status.completed;

    this.experimentTypeId = expandedForm.experimentDetail.experimentType;
    this.experimentFields = expandedForm.experimentFields || undefined;
    this.sampleId = expandedForm.sampleId || undefined;
    this.parentWorkOrderId = expandedForm.parentWorkOrderId || undefined;
  }
}
