<div class="action-btns-container" *ngIf="!isReadOnly">
  <button
    class="btn btn-primary upload-btn"
    type="button"
    appUpload
    [ext]="extensions"
    (filePicked)="uploadFile($event)"
    [disabled]="isFileUploading"
    #uploadDirective="uploadDirective"
  >
    <span class="upload-btn__text">Upload file</span
    ><mat-icon class="upload-btn__icon" fontSet="fas" fontIcon="fa-upload"></mat-icon>
  </button>

  <button
    *ngIf="isReloadBtnVisible"
    class="btn btn-primary reload-btn"
    type="button"
    (click)="getTableData()"
  >
    <span class="reload-btn__text">Reload</span
    ><mat-icon class="reload-btn__icon" fontSet="fas" fontIcon="fa-rotate-right"></mat-icon>
  </button>
</div>

<span class="loader" *ngIf="isFileUploading || isGettingTableInfo"></span>

<div class="table-wrapper">
  <table mat-table [dataSource]="this.dataSource" class="table custom-table">
    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef>Filename</th>
      <td mat-cell *matCellDef="let row">
        {{ row.fileName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let row">{{ row.createdAt | date: 'yyyy-dd-MM' }}</td>
    </ng-container>
    <ng-container matColumnDef="size">
      <th mat-header-cell *matHeaderCellDef>Size</th>
      <td mat-cell *matCellDef="let row">{{ row.size | convertFileSize }}</td>
    </ng-container>
    <ng-container matColumnDef="fileType">
      <th mat-header-cell *matHeaderCellDef>File type</th>
      <td mat-cell *matCellDef="let row">{{ row.fileType }}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let row">
        <span
          class="description-text"
          *ngIf="isReadOnly || pickedId !== row.fileId"
          (click)="pickedId = row.fileId"
          >{{ row.description ? row.description : '&nbsp;' }}</span
        >
        <mat-form-field
          *ngIf="!isReadOnly && pickedId === row.fileId"
          class="table-form-field edit-description"
        >
          <input matInput [value]="row.description" #description />
          <div matSuffix class="input-actions">
            <mat-icon
              class="save-btn__icon"
              fontSet="fas"
              fontIcon="fa-check"
              (click)="saveEdit(row.fileId, description.value)"
            ></mat-icon>
            <mat-icon
              class="cancel-btn__icon"
              fontSet="fas"
              fontIcon="fa-xmark"
              (click)="cancelEdit()"
            ></mat-icon>
          </div>
        </mat-form-field>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="actions-row">
        <button
          mat-icon-button
          class="download-btn__icon"
          (click)="downloadFile(row.fileId, row.fileName)"
        >
          <mat-icon fontSet="fas" fontIcon="fa-download"></mat-icon>
        </button>
        <button
          mat-icon-button
          class="delete-btn__icon"
          [disabled]="isReadOnly || row.fileType !== fileType.ATTACHED"
          (click)="deleteFile(row.fileId)"
        >
          <mat-icon fontSet="fas" fontIcon="fa-trash-can"></mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="simulationStatus">
      <td mat-cell *matCellDef="let row" class="simulation-status-row" colspan="6">
        <ng-container *ngIf="row.status === 'Failed'">
          <span class="simulation-status-row__error">
            Error happened during {{ row.simulationType | uppercase }} simulation ({{
              row.simulationName
            }})
          </span>
        </ng-container>

        <ng-container *ngIf="row.status === 'InProgress'">
          <span class="simulation-status-row__in-progress">
            {{ row.simulationType | uppercase }} simulation ({{ row.simulationName }}) in
            progress...
          </span>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['simulationStatus']; when: isSimulationStatusRow"
    ></tr>
    <tr *matNoDataRow class="mat-row table-row">
      <td colspan="7" class="mat-cell">
        <p>No files attached.</p>
      </td>
    </tr>
  </table>
</div>
