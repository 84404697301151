import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';
import { IdName } from '@core/models/id-name.model';

@Injectable({
  providedIn: 'root',
})
export class UnitAttributeService {
  private url = `${CORE_MEMORY_API}/UnitAttributes`;
  private polarizationUrl = `${this.url}/polarization`;
  private pixelSizeUrl = `${this.url}/pixelSize`;
  private voxelSizeUrl = `${this.url}/voxelSize`;
  private angleUrl = `${this.url}/angle`;

  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<{ lengthUnits: IdName[] }> {
    return this.httpClient.get<{ lengthUnits: IdName[] }>(this.url);
  }

  getPolarization(): Observable<IdName[]> {
    return this.httpClient.get<IdName[]>(this.polarizationUrl);
  }

  getPixelSize(): Observable<IdName[]> {
    return this.httpClient.get<IdName[]>(this.pixelSizeUrl);
  }

  getAngle(): Observable<IdName[]> {
    return this.httpClient.get<IdName[]>(this.angleUrl);
  }

  getVoxelSize(): Observable<IdName[]> {
    return this.httpClient.get<IdName[]>(this.voxelSizeUrl);
  }
}
