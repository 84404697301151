import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';
import { SampleSearchResult } from 'src/app/features/data-access/lookup-sample/models/sample-search.model';

import { SampleFiltersRequest } from './models/sample-filters.model';

@Injectable()
export class LookupSampleService {
  constructor(private httpClient: HttpClient) {}
  private url = `${CORE_MEMORY_API}/samples`;

  search(filters: SampleFiltersRequest): Observable<SampleSearchResult> {
    let parameters = new HttpParams()
      .set('searchString', filters.searchString)
      .set('filterType', filters.filterType)
      .set('sortColumnName', filters.sortColumnName)
      .set('sortDirection', filters.sortDirection)
      .set('page', filters.page)
      .set('pageSize', filters.pageSize)
      .appendAll({ StateName: filters.stateName })
      .appendAll({ Wells: filters.wells })
      .appendAll({ SampleAvailability: filters.sampleAvailability })
      .appendAll({ SampleTypes: filters.sampleTypes.map((x) => x.id) });

    if (Number(filters.minDepth) || Number(filters.maxDepth)) {
      parameters = parameters.append('minDepth', filters.minDepth!);
      parameters = parameters.append('minDepthUnitId', filters.minDepthUnitId);
      parameters = parameters.append('maxDepth', filters.maxDepth!);
      parameters = parameters.append('maxDepthUnitId', filters.maxDepthUnitId);
    }

    return this.httpClient.get<SampleSearchResult>(`${this.url}/GetFiltered`, {
      params: parameters,
    });
  }
}
