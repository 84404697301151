import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

import { LookupWellModule } from 'src/app/features/data-access/lookup-well/lookup-well.component';

import { WellDisplayNameModule } from '../pipes/well-display-name.pipe';

@Component({
  selector: 'app-well-table-selector-popup',
  templateUrl: './well-table-selector-popup.component.html',
  styleUrls: ['./well-table-selector-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WellTableSelectorPopupComponent {}

@NgModule({
  declarations: [WellTableSelectorPopupComponent],
  exports: [WellTableSelectorPopupComponent],
  imports: [MatDialogModule, LookupWellModule, WellDisplayNameModule],
})
export class WellTableSelectorPopupModule {}
