<div class="title">
  <!-- Temporally solution (for demo) of colorized titles -->
  <span
    [class.is-approved]="model.message.includes('approved by')"
    [class.is-rejected]="model.message.includes('rejected by')"
    [class.is-pending]="model.message.includes('currently pending')"
    >{{ model.title }}</span
  >
</div>
<div class="description">
  <span>Description:</span>
  {{ model.message }}
</div>
<div class="createdAt">
  <span>Created:</span>
  {{ model.createdAt | date: 'M/d/yyyy hh:mm:ss a' }}
</div>
<div class="actions">
  <button
    *ngIf="!model.message.includes('currently pending')"
    class="btn btn-primary btn-small"
    (click)="dismiss()"
    id="data-access-request-notification-btn"
  >
    Dismiss
  </button>

  <button
    *ngIf="model.message.includes('currently pending')"
    class="btn btn-primary btn-small"
    (click)="cancel()"
    id="data-access-request-cancel-btn"
  >
    Cancel
  </button>
</div>
