<ng-container *ngIf="dynamicFieldFormGroup" [formGroup]="dynamicFieldFormGroup">
  <div class="dynamic-fields-container" [ngClass]="hideExperimentType ? 'no-border' : ''">
    <ng-container
      *ngFor="let field of experimentFields; let i = index; let even = even; let last = last"
    >
      <ng-container *ngTemplateOutlet="fieldTemplate; context: { $implicit: field }"></ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #fieldTemplate let-experimentConstant>
  <ng-container [ngSwitch]="true">
    <ng-container *ngSwitchCase="experimentConstant.fieldType === renderType.HEADLINE_STRING">
      <h1 class="field-headline">{{ experimentConstant.fieldDisplayName }}</h1>
    </ng-container>
    <ng-container
      *ngSwitchCase="
        experimentConstant.fieldType === renderType.INPUT_FLOAT_BOX ||
        experimentConstant.fieldType === renderType.INPUT_STRING_BOX ||
        experimentConstant.fieldType === renderType.INPUT_TEXT_BOX ||
        experimentConstant.fieldType === renderType.INPUT_INT_BOX
      "
    >
      <ng-container *ngIf="experimentConstant.unitOptions.length; else inputWithoutUnit">
        <app-unit-input
          [formControl]="extractConstantControl(experimentConstant.fieldName)"
          [label]="experimentConstant.fieldDisplayName"
          [dialogTitle]="experimentConstant.fieldDisplayName + ' unit'"
          [options]="experimentConstant.unitOptions"
          [type]="experimentConstant.fieldType | inputType"
          [defaultUnitId]="experimentConstant.unitDefault"
          [required]="experimentConstant.required"
          [formFieldName]="experimentConstant.fieldName"
          [getErrorMessage]="makeCallBack(experimentConstant.fieldName)"
        >
          ></app-unit-input
        >
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="experimentConstant.fieldType === renderType.DROPDOWN_BOX">
      <mat-form-field appearance="outline">
        <mat-label>{{ experimentConstant.fieldDisplayName }}</mat-label>
        <mat-select
          [formControl]="extractConstantControl(experimentConstant.fieldName)"
          id="dynamic-experiment-select"
        >
          <mat-option *ngFor="let option of experimentConstant.fieldOptions" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="experimentFieldsValidationMessage!.getErrorsBy(experimentConstant.fieldName)"
          >{{
            experimentFieldsValidationMessage!.getMessageFor(experimentConstant.fieldName)
          }}</mat-error
        >
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="experimentConstant.fieldType === renderType.DATE_BOX">
      <mat-form-field appearance="outline">
        <mat-label>{{ experimentConstant.fieldDisplayName }}</mat-label>
        <input
          matInput
          [formControl]="extractConstantControl(experimentConstant.fieldName)"
          [matDatepicker]="datePicker"
          id="dynamic-experiment-datepicker-input"
        />
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker>
          <mat-datepicker-actions>
            <button
              class="btn btn-secondary btn-small"
              matDatepickerCancel
              id="dynamic-experiment-cancel-btn"
            >
              Cancel
            </button>
            <button
              class="btn btn-primary btn-small"
              matDatepickerApply
              id="dynamic-experiment-apply-btn"
            >
              Apply
            </button>
          </mat-datepicker-actions>
        </mat-datepicker>
        <mat-error
          *ngIf="experimentFieldsValidationMessage!.getErrorsBy(experimentConstant.fieldName)"
          >{{
            experimentFieldsValidationMessage!.getMessageFor(experimentConstant.fieldName)
          }}</mat-error
        >
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="experimentConstant.fieldType === renderType.POPUP_BOX">
      <app-sample-table-selector-popup-field
        [formControl]="extractConstantControl(experimentConstant.fieldName)"
        [label]="experimentConstant.fieldDisplayName"
        [getErrorMessage]="getErrorMessageForPopupField(experimentConstant)"
      ></app-sample-table-selector-popup-field>
    </ng-container>
  </ng-container>

  <ng-template #inputWithoutUnit>
    <mat-form-field appearance="outline">
      <mat-label>{{ experimentConstant.fieldDisplayName }}</mat-label>
      <input
        matInput
        [formControl]="extractConstantControl(experimentConstant.fieldName)"
        [type]="experimentConstant.fieldType | inputType"
        id="dynamic-experiment-field-name-input"
      />
      <mat-error
        *ngIf="experimentFieldsValidationMessage!.getErrorsBy(experimentConstant.fieldName)"
        >{{
          experimentFieldsValidationMessage!.getMessageFor(experimentConstant.fieldName)
        }}</mat-error
      >
    </mat-form-field>
  </ng-template>
</ng-template>
