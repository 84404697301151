<h1 class="title">{{ title }} Simulation</h1>
<form
  [formGroup]="digitalMicpFormGroup"
  autocomplete="off"
  (keydown.enter)="$event.preventDefault()"
>
  <app-sample-table-selector-popup-field
    *ngIf="!hideSampleSearch"
    class="half-width"
    [formControl]="sampleIdControl"
    [label]="'Search physical sample'"
  ></app-sample-table-selector-popup-field>

  <div class="micp-form" *ngIf="this.digitalVolumeInfoFormArray.length">
    <app-digital-volume-information
      [formGroup]="volumeInformationFormGroup"
      [sampleTypes]="sampleTypeList"
      [volumeTypes]="volumeTypeList"
      [dataFormats]="dataFormatList"
      [voxelUnits]="voxelSizeUnitList"
      [depthUnits]="depthUnitList"
      [pictureUrl]="pictureUrl"
      [maxSize]="getMaxSliderValue()"
      [digitalVolumeFileList]="digitalVolumeFileList"
      [digitalVolumeFileControl]="selectedDigitalVolumeFileFormControl"
      [orientationList]="orientationList"
      [direction]="direction"
    ></app-digital-volume-information>
    <app-table
      *ngIf="tableData.length"
      #tableComponent
      [formGroup]="volumeInformationFormGroup"
      [tableData]="tableData"
      [tableDropdownAttribute]="tableDropdownAttribute"
      [tableDropdownOptions]="tableDropdownOptions"
      [readonlyInput]="readonlyInput"
      [tableHeaderLabelArray]="tableHeaderLabelArray"
    ></app-table>
    <app-simulation-settings
      *ngIf="tableData.length"
      [formGroup]="simulationSettingsFormGroup"
      [numberOfGpus]="numberOfGpus"
      [numberOfCpus]="numberOfCpus"
      [isPtsd]="isPtsd"
    ></app-simulation-settings>
  </div>
</form>

<mat-spinner
  class="loading-icon"
  *ngIf="showSpinner"
  matSuffix
  [diameter]="100"
  [matTooltip]="'loading'"
></mat-spinner>
<div class="action-btns" *ngIf="!hideSampleSearch && tableData.length">
  <button class="btn btn-secondary btn-big" (click)="clear()">Cancel</button>
  <button class="btn btn-primary btn-big" (click)="save()">Submit</button>
</div>
