import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const REQUIRED_FIELDS_ERROR_CODE = 'requiredFields';

export const REQUIRED_ONE_TRUE: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const formGroup = control as UntypedFormGroup;
  const nameControlPairs = Object.entries(formGroup.controls);
  const isOneTrue = nameControlPairs.some(([_, ctrl]) => Boolean(ctrl.value));
  return isOneTrue ? null : { requiredOneTrue: true };
};

export function requiredFields(
  fieldNames: string[],
  fieldLabels: Record<string, string>,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as UntypedFormGroup;
    const nameControlPairs = Object.entries(formGroup.controls).filter(([name, _]) =>
      fieldNames.includes(name),
    );
    const hasSomeValue = nameControlPairs.some(([_, ctrl]) => !!ctrl.value);
    if (hasSomeValue) {
      return null;
    }

    const fields = fieldNames.map((name) => fieldLabels[name]).join(' or ');
    return {
      [REQUIRED_FIELDS_ERROR_CODE]: `${fields} should not be empty`,
    };
  };
}
