import { debounceTime, filter as filterFunc, first, merge, tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import {
  MatLegacyPaginator as MatPaginator,
  MatLegacyPaginatorModule as MatPaginatorModule,
} from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { DEFAULT_DEBOUNCE_TIME } from '@core/constants/consts';
import { DropdownOptionExtended } from '@core/models/dropdown-option-extended.model';
import { IdName } from '@core/models/id-name.model';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import {
  AccessRequestPopupComponent,
  AccessRequestPopupModule,
} from 'src/app/common/access-request-popup/access-request-popup.component';
import { SampleAccessRequest } from 'src/app/common/access-request-popup/models/sample-access-request.model';
import { SampleType } from 'src/app/common/sample-check-in-menu/enums/sample-type.enum';
import { SampleCheckInMenuModule } from 'src/app/common/sample-check-in-menu/sample-check-in-menu.component';
import { SearchInputModule } from 'src/app/common/search-input/search-input.component';
import { SampleTypeService } from 'src/app/services/api/sample-type.service';
import { UserNotificationService } from 'src/app/services/api/user-notification.service';
import { NotificationService } from 'src/app/services/notification.service';

import { ReplaceEmptyStringModule } from '../../../common/pipes/replace-empty-string.pipe';
import { SampleService } from '../../../services/api/sample.service';
import { INITIAL_SAMPLE_FILTER_STATE } from './consts/initial-sample-filter-state.const';
import { StateName } from './enums/access-status.enum';
import { LookupSampleService } from './lookup-sample.service';
import {
  LookupSampleFilterComponent,
  LookupSampleFilterModule,
} from './lookup-sample-filter/lookup-sample-filter.component';
import { SampleFilters, SampleFiltersRequest } from './models/sample-filters.model';
import { SampleSearch } from './models/sample-search.model';
import { SamplesDataSource } from './utils/samples-data-source.class';

@Component({
  selector: 'app-lookup-sample',
  templateUrl: './lookup-sample.component.html',
  styleUrls: ['./lookup-sample.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookupSampleComponent extends Destroyable(Object) implements AfterViewInit, OnInit {
  @Input() isSelector = false;
  @Input() allowedSampleTypes?: string[];

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  readonly stateName = StateName;

  searchPlaceholder: 'Sample name' | 'Email' | 'Well name' = 'Sample name';
  selectedSample: SampleSearch | null = null;
  sampleTypes: IdName[] = [];
  isViewInitialized = false;

  dataSource!: SamplesDataSource;
  filterData: SampleFilters = JSON.parse(JSON.stringify(INITIAL_SAMPLE_FILTER_STATE));

  searchByFields = [
    { label: 'Sample Name', value: 'byName' },
    { label: 'Well Name', value: 'byWellName' },
    { label: 'Owner', value: 'byOwner' },
  ];

  displayedColumns: Array<keyof SampleSearch> = [
    'wellName',
    'type',
    'depth',
    'sampleName',
    'locationName',
    'owner',
    'description',
    'stateName',
  ];

  private get routerAppPrefix(): string {
    return `/${/(?<=\/)(.*?)(?=\/)/.exec(this.router.url)?.[0]}`;
  }

  private get isEveryFilterOptionsLoaded(): boolean {
    return !!this.sampleTypes.length;
  }

  constructor(
    private lookupSampleService: LookupSampleService,
    private sampleService: SampleService,
    private sampleTypeService: SampleTypeService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private userNotificationService: UserNotificationService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataSource = new SamplesDataSource(
      this.lookupSampleService,
      this.sampleService,
      this.takeUntilDestroyed,
    );

    if (!this.isSelector) {
      this.initializeFilterData();
    }

    this.sampleTypeService
      .getAll()
      .pipe(this.takeUntilDestroyed())
      .subscribe((response) => {
        this.sampleTypes = response.map((x) => ({ id: x.id, name: x.name }));
        this.assignSampleTypesToFilterData();
        this.applySearch();
      });
  }

  ngAfterViewInit(): void {
    this.isViewInitialized = true;
    setTimeout(() => this.applySearch());
    this.setupSortAndPaginationChangeListeners();
  }

  onSearch(term: string): void {
    this.filterData.searchString = term.trim();
    this.applySearch(true);
  }

  searchByBarcode(value: string): void {
    if (!value) {
      this.notificationService.notifyInfo('Search field should not be empty');
      return;
    }

    this.resetToDefaultAndSetSearchString(value);
    this.dataSource.searchByBarCode(this.filterData.searchString);
  }

  onRequestClick(event: Event, sample: SampleSearch): void {
    event.stopPropagation();
    this.openDialog(sample);
  }

  onSampleRowClick(sample: SampleSearch): void {
    if (this.hasAccessToSample(sample)) {
      if (this.isSelector) {
        this.selectSample(sample);
        return;
      }

      this.navigateToEditSamplePage(sample);
    }
  }

  onFilterClick(): void {
    const dialogRef = this.dialog.open(LookupSampleFilterComponent, {
      width: '50%',
      data: {
        ...this.filterData,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filterFunc((accessRequest) => !!accessRequest),
        this.takeUntilDestroyed(),
      )
      .subscribe((data: SampleFilters) => {
        this.filterData = data;
        this.applySearch(true);
      });
  }

  onByFieldChanged(index: string): void {
    this.setPlaceholder(index);
    this.applySearch(true);
  }

  navigateToSampleCheckInPage(type: SampleType): void {
    this.router.navigate([this.routerAppPrefix, 'sample-check-in', type]);
  }

  isSampleTypeAllowedForSelect(sample: SampleSearch): boolean {
    return !this.allowedSampleTypes || this.allowedSampleTypes.includes(sample.type);
  }

  hasAccessToSample(sample: SampleSearch): boolean {
    return sample.stateName === StateName.OWN || sample.stateName === StateName.APPROVED;
  }

  private setupSortAndPaginationChangeListeners(): void {
    const sortChange$ = this.sort.sortChange.pipe(
      tap(() => {
        this.paginator.pageIndex = 0;
        this.filterData = {
          ...this.filterData,
          page: this.paginator.pageIndex + 1,
        };
      }),
    );
    const pageChange$ = this.paginator.page.pipe(debounceTime(DEFAULT_DEBOUNCE_TIME));

    merge(sortChange$, pageChange$)
      .pipe(
        tap(() => {
          this.applySearch();
        }),
      )
      .subscribe();
  }

  private selectSample(sample: SampleSearch): void {
    if (this.isSampleTypeAllowedForSelect(sample)) {
      this.selectedSample = sample;
    }
  }

  private navigateToEditSamplePage(sample: SampleSearch): void {
    if (sample.type === SampleType.COMPOSITE) {
      this.router.navigate([
        `${this.routerAppPrefix}/data-access/composite-sample/edit`,
        sample.id,
      ]);
    } else {
      this.router.navigate([
        `${this.routerAppPrefix}/data-access/sample/edit`,
        sample.type,
        sample.id,
      ]);
    }
  }

  private setPlaceholder(index: string): void {
    switch (index) {
      case 'byWellName':
        this.searchPlaceholder = 'Well name';
        break;
      case 'byOwner':
        this.searchPlaceholder = 'Email';
        break;
      default:
        this.searchPlaceholder = 'Sample name';
        break;
    }
  }

  private initializeFilterData(): void {
    this.route.queryParamMap.pipe(first(), this.takeUntilDestroyed()).subscribe((data) => {
      const term = data.get('searchString');
      const params = {
        filterType: data.get('filterType'),
        sortColumnName: data.get('sortColumnName'),
        sortDirection: data.get('sortDirection'),
        page: Number(data.get('page')),
        pageSize: Number(data.get('pageSize')),
        minDepthUnitId: data.get('minDepthUnitId'),
        maxDepthUnitId: data.get('maxDepthUnitId'),
        minDepth: data.get('minDepth'),
        maxDepth: data.get('maxDepth'),
        wells: data.get('wells'),
        stateName: data.get('stateName'),
        sampleTypes: data.get('sampleTypes'),
        sampleAvailability: data.get('sampleAvailability'),
      };

      if (params.page && params.pageSize) {
        this.filterData = {
          ...this.filterData,
          page: params.page,
          pageSize: params.pageSize,
        };
      }

      if (term) {
        this.filterData = {
          ...this.filterData,
          searchString: term,
        };
      }

      if (params.filterType) {
        this.filterData = {
          ...this.filterData,
          filterType: params.filterType,
        };
      }

      if (params.sortColumnName && params.sortDirection) {
        this.filterData = {
          ...this.filterData,
          sortDirection: params.sortDirection as SortDirection,
          sortColumnName: params.sortColumnName,
        };
      }
    });
  }

  private applySearch(removeSortingAndPagination = false): void {
    if (!this.isViewInitialized || !this.isEveryFilterOptionsLoaded) {
      return;
    }

    this.filterData = {
      ...this.filterData,
      sortColumnName:
        removeSortingAndPagination || this.sort.direction === '' ? 'noSort' : this.sort.active,
      sortDirection: removeSortingAndPagination ? '' : this.sort.direction,
      pageSize: this.paginator.pageSize,
      page: removeSortingAndPagination ? 1 : this.paginator.pageIndex + 1,
    };

    const filterData: SampleFiltersRequest = {
      filterType: this.filterData.filterType,
      searchString: this.filterData.searchString,
      sortColumnName: this.filterData.sortColumnName,
      sortDirection: this.filterData.sortDirection || 'asc',
      page: this.filterData.page,
      pageSize: this.filterData.pageSize,
      minDepthUnitId: this.filterData.minDepthUnitId,
      maxDepthUnitId: this.filterData.maxDepthUnitId,
      minDepth: this.filterData.minDepth,
      maxDepth: this.filterData.maxDepth,
      wells: this.filterData.wells,
      stateName: Object.keys(this.filterData.stateName).filter((key) => {
        const value = this.filterData.stateName[key as keyof typeof this.filterData.stateName];
        return value === true;
      }),
      sampleTypes: this.filterData.sampleTypes,
      sampleAvailability: Object.keys(this.filterData.sampleAvailability).filter((key) => {
        const value =
          this.filterData.sampleAvailability[
            key as keyof typeof this.filterData.sampleAvailability
          ];
        return value === true;
      }),
    };

    if (!filterData.sampleTypes.length) {
      filterData.sampleTypes = this.sampleTypes as Array<DropdownOptionExtended>;
    }

    filterData.sampleTypes = filterData.sampleTypes.filter((x) => x.checked);

    if (!this.isSelector) {
      this.updateQueryParams();
    }

    this.dataSource.loadSamples(filterData);
  }

  private openDialog(sample: SampleSearch): void {
    const dialogRef = this.dialog.open(AccessRequestPopupComponent, {
      width: '400px',
      data: {
        sampleId: sample.id,
        sampleComplexName: this.getSampleComplexName(sample),
        owner: sample.owner,
        description: '',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filterFunc((accessRequest) => !!accessRequest),
        this.takeUntilDestroyed(),
      )
      .subscribe((accessRequest) => this.onAccessRequest(accessRequest));
  }

  private onAccessRequest(sampleData: SampleAccessRequest): void {
    this.userNotificationService
      .postSampleAccessRequest(sampleData.sampleId, sampleData.description)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess(`Access request sent successfully`);

        const accessSample = this.dataSource.data.find(
          (sample) => sample.id === sampleData.sampleId,
        );
        accessSample!.stateName = StateName.PENDING;
        this.cd.detectChanges();
      });
  }

  private getSampleComplexName(sample: SampleSearch): string {
    return `${sample.sampleName}-${sample.type}-${sample.depth.replace(/[^\d.-]/g, '')}-${
      sample.wellName
    }`;
  }

  private updateQueryParams(): void {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams['searchString'] = this.filterData.searchString;
    urlTree.queryParams['filterType'] = this.filterData.filterType;
    urlTree.queryParams['sortColumnName'] = this.filterData.sortColumnName;
    urlTree.queryParams['sortDirection'] = this.filterData.sortDirection;
    urlTree.queryParams['page'] = this.filterData.page;
    urlTree.queryParams['pageSize'] = this.filterData.pageSize;
    urlTree.queryParams['minDepthUnitId'] = this.filterData.minDepthUnitId;
    urlTree.queryParams['maxDepthUnitId'] = this.filterData.maxDepthUnitId;
    urlTree.queryParams['minDepth'] = this.filterData.minDepth;
    urlTree.queryParams['maxDepth'] = this.filterData.maxDepth;
    urlTree.queryParams['well'] = this.filterData.wells;
    urlTree.queryParams['stateName'] = this.filterData.stateName;
    urlTree.queryParams['sampleAvailability'] = this.filterData.sampleAvailability;
    urlTree.queryParams['sampleTypes'] = this.filterData.sampleTypes.map((type) => type.id);

    this.router.navigateByUrl(urlTree);
  }

  private assignSampleTypesToFilterData(): void {
    this.filterData.sampleTypes = this.sampleTypes.map((x: IdName) => ({
      checked: this.filterData.sampleTypes.find((t) => t.name === x.name)?.checked ?? true,
      id: x.id,
      name: x.name,
    }));
  }

  private resetToDefaultAndSetSearchString(value: string): void {
    this.filterData = JSON.parse(JSON.stringify(INITIAL_SAMPLE_FILTER_STATE));
    this.assignSampleTypesToFilterData();

    if (!this.isSelector) {
      this.updateQueryParams();
    }

    this.filterData.searchString = value;
  }
}

@NgModule({
  imports: [
    CommonModule,
    SearchInputModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatTableModule,
    FormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    AccessRequestPopupModule,
    LookupSampleFilterModule,
    SampleCheckInMenuModule,
    ReplaceEmptyStringModule,
    MatLegacyProgressSpinnerModule,
  ],
  declarations: [LookupSampleComponent],
  exports: [LookupSampleComponent],
  providers: [LookupSampleService],
})
export class LookupSampleModule {}
