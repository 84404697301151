import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { PrintBarcodeMenuModule } from 'src/app/common/print-barcode-menu/print-barcode-menu.component';
import { SampleType } from 'src/app/common/sample-check-in-menu/enums/sample-type.enum';
import { SampleCheckInMenuModule } from 'src/app/common/sample-check-in-menu/sample-check-in-menu.component';

@Component({
  selector: 'app-sample-actions',
  templateUrl: './sample-actions.component.html',
  styleUrls: ['./sample-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleActionsComponent {
  @Input() showSampleCheckInMenu = false;
  @Input() sampleCheckInMenuOptions = new Array<SampleType>();
  @Input() showPrintBarcodeMenu = false;
  @Input() barcode = '';
  @Input() qrCode = '';
  @Input() saveButtonLabel = 'Save';
  @Output() sampleCheckInOptionSelected = new EventEmitter<SampleType>();
  @Output() cancelClicked = new EventEmitter();
  @Output() saveClicked = new EventEmitter();

  readonly sample = 'Sample';
  isSaveBtnSpinnerVisible = false;

  constructor(private cd: ChangeDetectorRef) {}

  initiateSaving(): void {
    this.isSaveBtnSpinnerVisible = true;
    this.saveClicked.emit();
  }

  savingFinished(): void {
    this.isSaveBtnSpinnerVisible = false;
    this.cd.markForCheck();
  }
}

@NgModule({
  declarations: [SampleActionsComponent],
  imports: [
    CommonModule,
    PrintBarcodeMenuModule,
    SampleCheckInMenuModule,
    MatIconModule,
    MatLegacyProgressSpinnerModule,
  ],
  exports: [SampleActionsComponent],
})
export class SampleActionsModule {}
