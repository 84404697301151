<mat-form-field class="tree-view-selector-popup-field" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [formControl]="control"
    [matAutocomplete]="auto"
    (focus)="openTreeViewSelectorPopup()"
    readonly
    autocomplete="off"
    id="tree-view-selector-input"
  />
  <mat-icon
    data-ref="close-icon"
    matSuffix
    appStopPropagationOnClick
    appDisabledElement
    [disabled]="control.disabled"
    (click)="clearField()"
    id="tree-view-clear-btn"
    >close</mat-icon
  >
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith">
    <mat-option *ngFor="let treeNode of [selectedTreeNode]" [value]="treeNode?.id">
      {{ treeNode?.name ?? '' }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control!.errors && control!.getError('required')">{{
    control.getError('required') ? 'Field is required' : ''
  }}</mat-error>
</mat-form-field>
