import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';

import { WellSearchResult } from './models/well-search.model';
import { WellSearchRequest } from './models/well-search-request.model';

@Injectable()
export class LookupWellsService {
  private url = `${CORE_MEMORY_API}/Wells/getFiltered`;

  constructor(private httpClient: HttpClient) {}

  search(searchObject: WellSearchRequest): Observable<WellSearchResult> {
    let parameters = new HttpParams()
      .set('searchString', searchObject.searchString)
      .set('filterType', searchObject.filterType)
      .set('sortColumnName', searchObject.sortColumnName)
      .set('sortDirection', searchObject.sortDirection || 'asc')
      .set('page', searchObject.page)
      .set('pageSize', searchObject.pageSize);

    if (searchObject.sortDirection) {
      parameters = parameters.set('sortDirection', searchObject.sortDirection);
    }

    return this.httpClient.get<WellSearchResult>(this.url, { params: parameters });
  }
}
