import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';
import { IdName } from '@core/models/id-name.model';

import { CacheService } from '../../../services/cache.service';

@Injectable()
export class LocationTypeService {
  private url = `${CORE_MEMORY_API}/Locations/types`;

  constructor(private httpClient: HttpClient, private cacheService: CacheService) {}

  getAll(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      'locationTypes',
      this.httpClient.get<IdName[]>(this.url),
    );
  }
}
