import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyPaginator, MatLegacyPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTableDataSource, MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { IdName } from '@core/models/id-name.model';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { MultiSelectModule } from '../multi-select/multi-select.component';
import { Table } from './models/table.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent extends Destroyable(Object) implements OnInit, AfterViewInit {
  @Input() tableData!: Table[];
  @Input() tableDropdownAttribute!: string[];
  @Input() tableDropdownOptions!: { [key: string]: IdName[] };
  @Input() readonlyInput = false;
  @Input() tableHeaderLabelArray!: string[];

  tableHeaderLabel: Record<string, string> = {};
  tableHeaderLabelInfo: Record<string, string> = {};
  tableColumns: string[] = [];
  dataSource!: MatLegacyTableDataSource<any>;
  rows: FormArray = this.fb.array([]);
  formGroup: FormGroup = this.fb.group({
    table: this.rows,
  });

  colorClassName: { [key: string]: string } = { 0: 'black', 1: 'green' };

  @ViewChild(MatLegacyPaginator) paginator!: MatLegacyPaginator;

  get rawFormValue(): FormDataEntryValue {
    return this.removeTableEmptyRow();
  }

  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    Object.keys(this.tableData[0]).forEach((key: string, index: number) => {
      const text = this.tableHeaderLabelArray[index].split('*');
      this.tableColumns.push(key);
      this.tableHeaderLabel[key] = text[0] || '';
      this.tableHeaderLabelInfo[key] = text[1] || '';
    });
    this.initForm();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.rows.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((_) => {
      this.checkEmptyRow(this.rows);
    });
  }

  isDropDown(key: string): boolean {
    return this.tableDropdownAttribute.includes(key);
  }

  getClassName(id: string, key: string): string {
    return key === 'phaseId' ? this.colorClassName[id] : '';
  }

  private initForm(): void {
    this.tableData.forEach((data) => {
      this.rows.push(this.addRowControls(data));
    });
    this.dataSource = new MatLegacyTableDataSource(
      (this.formGroup.get('table') as FormArray).controls,
    );
    this.dataSource.paginator = this.paginator;
  }

  private checkEmptyRow(data: FormArray): void {
    let isRowEmpty = false;
    const { pageSize } = this.paginator;
    const rowLength = data.controls.length;

    if (rowLength < pageSize) {
      return;
    }
    for (let i = rowLength - pageSize; i < rowLength; i += 1) {
      isRowEmpty = !data.controls[i].pristine;
      if (!isRowEmpty) {
        break;
      }
    }
    if (isRowEmpty) this.addNewRow();
  }

  private addRowControls(row: Table | null): FormGroup {
    const controls: Record<string, FormControl> = {};
    this.tableColumns.map((key) => {
      controls[key] = new FormControl(row ? row[key] : '');
      return controls;
    });
    const formGroup = this.fb.group(controls);
    if (row) formGroup.markAsDirty();
    return formGroup;
  }

  private addNewRow(): void {
    const { pageSize } = this.paginator;
    const rowlength = pageSize - (this.dataSource.data.length % pageSize);
    for (let i = 0; i < rowlength; i += 1) {
      this.rows.push(this.addRowControls(null));
    }
    this.dataSource = new MatLegacyTableDataSource(
      (this.formGroup.get('table') as FormArray).controls,
    );
    this.dataSource.paginator = this.paginator;
  }

  private removeTableEmptyRow(): FormDataEntryValue {
    const formArray = this.formGroup.getRawValue().table;
    return formArray.filter((row: FormDataEntryValue) => !this.isEmptyRow(row));
  }

  private isEmptyRow(row: FormDataEntryValue) {
    return Object.values(row).every((value) => {
      if (!value) {
        return true;
      }
      return false;
    });
  }
}

@NgModule({
  declarations: [TableComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MultiSelectModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSliderModule,
    CdkTableModule,
    MatLegacyTableModule,
    MatLegacyPaginatorModule,
    MatLegacyTooltipModule,
  ],
  exports: [TableComponent],
})
export class TableComponentModule {}
