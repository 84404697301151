<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <button
    *ngIf="canPrintBarcode"
    class="print-button"
    matTooltip="Print Barcode"
    (click)="printBarcode()"
    id="print-barcode-popup-btn"
  >
    <fa-icon [icon]="faBarcode" size="5x"></fa-icon>
  </button>
  <button
    *ngIf="canPrintQRCode"
    class="print-button"
    matTooltip="Print QR Code"
    (click)="printQRCode()"
    id="print-qr-popup-btn"
  >
    <fa-icon [icon]="faQrcode" size="5x"></fa-icon>
  </button>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button
    class="btn btn-primary btn-big"
    [mat-dialog-close]="true"
    cdkFocusInitial
    id="print-barcode-popup-cancel-btn"
  >
    Close
  </button>
</mat-dialog-actions>
