<div mat-dialog-title class="title">
  <h1>Filter</h1>
  <button
    class="btn btn-secondary btn-small"
    (click)="clearFilters()"
    id="location-clear-filters-btn"
  >
    Clear Filters
  </button>
</div>
<div mat-dialog-content>
  <div [formGroup]="formGroup" class="filter-fields-line">
    <div class="drop-down-box" *ngIf="formGroup && locationTypes.length">
      <app-multi-select
        [formControlName]="locationTypesFormName"
        [label]="locationFormFieldNameToDisplay[locationFormField.LOCATION_TYPE_ID]"
        [selectOptions]="locationTypes"
        [multiSelect]="true"
        [returnIdValue]="false"
        id="location-types-select"
        name="locationTypesControl"
        ngDefaultControl
      >
      </app-multi-select>
      <mat-error *ngIf="hasErrors(locationTypesFormName)">
        {{ getFormErrorMessage(locationTypesFormName) }}
      </mat-error>
    </div>

    <div class="drop-down-box" *ngIf="formGroup && locationRestrictions.length">
      <app-multi-select
        [formControlName]="restrictionTypesFormName"
        [label]="locationFormFieldNameToDisplay[locationFormField.LOCATION_RESTRICTION_IDS]"
        [selectOptions]="locationRestrictions"
        [multiSelect]="true"
        [returnIdValue]="false"
        (selectionChange)="onOptionSelection()"
        id="location-restriction-select"
        name="locationRestrictionsControl"
        ngDefaultControl
      >
      </app-multi-select>
      <mat-error *ngIf="hasErrors(restrictionTypesFormName)">
        {{ getFormErrorMessage(restrictionTypesFormName) }}
      </mat-error>
    </div>
  </div>
</div>
<div mat-dialog-actions class="actions">
  <button
    mat-button
    [mat-dialog-close]="undefined"
    class="btn btn-secondary"
    id="location-cancel-filters-btn"
  >
    Cancel
  </button>
  <button
    mat-button
    [disabled]="!canApply"
    (click)="apply()"
    cdkFocusInitial
    class="btn btn-primary"
    id="location-apply-filters-btn"
  >
    Apply
  </button>
</div>
