<form class="work-order-form" [formGroup]="workOrderFormGroup" autocomplete="off">
  <mat-accordion multi>
    <mat-expansion-panel
      [formGroupName]="workOrderFormField.GENERAL_INFORMATION"
      [expanded]="expandFirst"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>{{
          workOrderNameToDisplay[workOrderFormField.GENERAL_INFORMATION]
        }}</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="fields-line">
        <mat-form-field appearance="outline">
          <mat-label>{{
            workOrderGeneralInformationNameToDisplay[
              workOrderGeneralInformationFormField.PROJECT_NUMBER
            ]
          }}</mat-label>
          <input
            matInput
            [formControlName]="workOrderGeneralInformationFormField.PROJECT_NUMBER"
            id="work-order-project-number-input"
          />
          <mat-hint align="end"
            >{{
              workOrderFormGroup.get([
                workOrderFormField.GENERAL_INFORMATION,
                workOrderGeneralInformationFormField.PROJECT_NUMBER
              ])?.value.length
            }}/64</mat-hint
          >
          <mat-error
            *ngIf="
              workOrderGeneralInformationValidationMessage.getErrorsBy(
                workOrderGeneralInformationFormField.PROJECT_NUMBER
              )
            "
            >{{
              workOrderGeneralInformationValidationMessage.getMessageFor(
                workOrderGeneralInformationFormField.PROJECT_NUMBER
              )
            }}</mat-error
          >
        </mat-form-field>
      </div>

      <div class="fields-line">
        <mat-form-field appearance="outline">
          <mat-label>{{
            workOrderGeneralInformationNameToDisplay[
              workOrderGeneralInformationFormField.PROPONENT_EMAIL
            ]
          }}</mat-label>
          <input
            matInput
            [formControlName]="workOrderGeneralInformationFormField.PROPONENT_EMAIL"
            id="work-order-proponent-email-input"
          />
          <mat-hint align="end"
            >{{
              workOrderFormGroup.get([
                workOrderFormField.GENERAL_INFORMATION,
                workOrderGeneralInformationFormField.PROPONENT_EMAIL
              ])?.value.length
            }}/128</mat-hint
          >
          <mat-error
            *ngIf="
              workOrderGeneralInformationValidationMessage.getErrorsBy(
                workOrderGeneralInformationFormField.PROPONENT_EMAIL
              )
            "
            >{{
              workOrderGeneralInformationValidationMessage.getMessageFor(
                workOrderGeneralInformationFormField.PROPONENT_EMAIL
              )
            }}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{
            workOrderGeneralInformationNameToDisplay[
              workOrderGeneralInformationFormField.PROPONENT_NAME
            ]
          }}</mat-label>
          <input
            matInput
            [formControlName]="workOrderGeneralInformationFormField.PROPONENT_NAME"
            id="work-order-proponent-name-input"
          />
          <mat-hint align="end"
            >{{
              workOrderFormGroup.get([
                workOrderFormField.GENERAL_INFORMATION,
                workOrderGeneralInformationFormField.PROPONENT_NAME
              ])?.value.length
            }}/128</mat-hint
          >
          <mat-error
            *ngIf="
              workOrderGeneralInformationValidationMessage.getErrorsBy(
                workOrderGeneralInformationFormField.PROPONENT_NAME
              )
            "
            >{{
              workOrderGeneralInformationValidationMessage.getMessageFor(
                workOrderGeneralInformationFormField.PROPONENT_NAME
              )
            }}</mat-error
          >
        </mat-form-field>
      </div>

      <div class="fields-line">
        <mat-form-field appearance="outline">
          <mat-label>{{
            workOrderGeneralInformationNameToDisplay[
              workOrderGeneralInformationFormField.DESCRIPTION
            ]
          }}</mat-label>
          <input
            matInput
            [formControlName]="workOrderGeneralInformationFormField.DESCRIPTION"
            id="work-order-description-input"
          />
          <mat-hint align="end"
            >{{
              workOrderFormGroup.get([
                workOrderFormField.GENERAL_INFORMATION,
                workOrderGeneralInformationFormField.DESCRIPTION
              ])?.value.length
            }}/128</mat-hint
          >
          <mat-error
            *ngIf="
              workOrderGeneralInformationValidationMessage.getErrorsBy(
                workOrderGeneralInformationFormField.DESCRIPTION
              )
            "
            >{{
              workOrderGeneralInformationValidationMessage.getMessageFor(
                workOrderGeneralInformationFormField.DESCRIPTION
              )
            }}</mat-error
          >
        </mat-form-field>
      </div>

      <div class="fields-line">
        <mat-form-field appearance="outline" class="date-picker">
          <mat-label>{{
            workOrderGeneralInformationNameToDisplay[
              workOrderGeneralInformationFormField.START_DATE
            ]
          }}</mat-label>
          <input
            readonly
            matInput
            [formControlName]="workOrderGeneralInformationFormField.START_DATE"
            [matDatepicker]="startDatePicker"
            (click)="startDatePicker.open()"
            (focus)="startDatePicker.open()"
            id="work-order-start-date-input"
          />
          <div matSuffix class="date-picker-action-buttons">
            <mat-datepicker-toggle [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-icon
              appStopPropagationOnClick
              appDisabledElement
              [disabled]="
                workOrderFormGroup.get([
                  workOrderFormField.GENERAL_INFORMATION,
                  workOrderGeneralInformationFormField.START_DATE
                ])!.disabled
              "
              (click)="
                clearDate(
                  workOrderFormGroup.get([
                    workOrderFormField.GENERAL_INFORMATION,
                    workOrderGeneralInformationFormField.START_DATE
                  ])!
                )
              "
              >close</mat-icon
            >
          </div>
          <mat-datepicker #startDatePicker>
            <mat-datepicker-actions>
              <button
                class="btn btn-secondary btn-small"
                matDatepickerCancel
                id="work-order-start-date-cancel-btn"
              >
                Cancel
              </button>
              <button
                class="btn btn-primary btn-small"
                matDatepickerApply
                id="work-order-start-date-apply-btn"
              >
                Apply
              </button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error
            *ngIf="
              workOrderGeneralInformationValidationMessage.getErrorsBy(
                workOrderGeneralInformationFormField.START_DATE
              )
            "
            >{{
              workOrderGeneralInformationValidationMessage.getMessageFor(
                workOrderGeneralInformationFormField.START_DATE
              )
            }}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="date-picker">
          <mat-label>{{
            workOrderGeneralInformationNameToDisplay[
              workOrderGeneralInformationFormField.EXPECTED_DELIVERY_DATE
            ]
          }}</mat-label>
          <input
            readonly
            matInput
            [formControlName]="workOrderGeneralInformationFormField.EXPECTED_DELIVERY_DATE"
            [matDatepicker]="expectedDeliveryDatePicker"
            (click)="expectedDeliveryDatePicker.open()"
            (focus)="expectedDeliveryDatePicker.open()"
            id="work-order-delivery-date-input"
          />
          <div matSuffix class="date-picker-action-buttons">
            <mat-datepicker-toggle [for]="expectedDeliveryDatePicker"></mat-datepicker-toggle>
            <mat-icon
              appStopPropagationOnClick
              appDisabledElement
              [disabled]="
                workOrderFormGroup.get([
                  workOrderFormField.GENERAL_INFORMATION,
                  workOrderGeneralInformationFormField.EXPECTED_DELIVERY_DATE
                ])!.disabled
              "
              (click)="
                clearDate(
                  workOrderFormGroup.get([
                    workOrderFormField.GENERAL_INFORMATION,
                    workOrderGeneralInformationFormField.EXPECTED_DELIVERY_DATE
                  ])!
                )
              "
              >close</mat-icon
            >
          </div>
          <mat-datepicker #expectedDeliveryDatePicker>
            <mat-datepicker-actions>
              <button
                class="btn btn-secondary btn-small"
                matDatepickerCancel
                id="work-order-delivery-date-cancel-btn"
              >
                Cancel
              </button>
              <button
                class="btn btn-primary btn-small"
                matDatepickerApply
                id="work-order-delivery-date-apply-btn"
              >
                Apply
              </button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error
            *ngIf="
              workOrderGeneralInformationValidationMessage.getErrorsBy(
                workOrderGeneralInformationFormField.EXPECTED_DELIVERY_DATE
              )
            "
            >{{
              workOrderGeneralInformationValidationMessage.getMessageFor(
                workOrderGeneralInformationFormField.EXPECTED_DELIVERY_DATE
              )
            }}</mat-error
          >
        </mat-form-field>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [formGroupName]="workOrderFormField.WORK_ORDER_STATUS">
      <mat-expansion-panel-header>
        <mat-panel-title>{{
          workOrderNameToDisplay[workOrderFormField.WORK_ORDER_STATUS]
        }}</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="fields-line">
        <mat-form-field appearance="outline" class="progress-field">
          <mat-label>{{
            workOrderStatusNameToDisplay[workOrderStatusFormField.PROGRESS]
          }}</mat-label>
          <input
            type="number"
            matInput
            [formControlName]="workOrderStatusFormField.PROGRESS"
            id="work-order-progress-input"
          />
          <div
            matSuffix
            appDisabledElement
            [disabled]="
              workOrderFormGroup.get([
                workOrderFormField.WORK_ORDER_STATUS,
                workOrderStatusFormField.PROGRESS
              ])!.disabled
            "
          >
            %
          </div>
          <mat-error
            *ngIf="workOrderStatusValidationMessage.getErrorsBy(workOrderStatusFormField.PROGRESS)"
            >{{
              workOrderStatusValidationMessage.getMessageFor(workOrderStatusFormField.PROGRESS)
            }}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="date-picker">
          <mat-label>{{
            workOrderStatusNameToDisplay[workOrderStatusFormField.COMPLETION_DATE]
          }}</mat-label>
          <input
            readonly
            matInput
            [formControlName]="workOrderStatusFormField.COMPLETION_DATE"
            [matDatepicker]="completionDatePicker"
            (click)="completionDatePicker.open()"
            (focus)="completionDatePicker.open()"
            id="work-order-completion-date-input"
          />
          <div matSuffix class="date-picker-action-buttons">
            <mat-datepicker-toggle [for]="completionDatePicker"></mat-datepicker-toggle>
            <mat-icon
              appDisabledElement
              appStopPropagationOnClick
              [disabled]="
                workOrderFormGroup.get([
                  workOrderFormField.WORK_ORDER_STATUS,
                  workOrderStatusFormField.COMPLETION_DATE
                ])!.disabled
              "
              (click)="
                clearDate(
                  workOrderFormGroup.get([
                    workOrderFormField.WORK_ORDER_STATUS,
                    workOrderStatusFormField.COMPLETION_DATE
                  ])!
                )
              "
              >close</mat-icon
            >
          </div>
          <mat-datepicker #completionDatePicker>
            <mat-datepicker-actions>
              <button
                class="btn btn-secondary btn-small"
                matDatepickerCancel
                id="work-order-completion-date-cancel-btn"
              >
                Cancel
              </button>
              <button
                class="btn btn-primary btn-small"
                matDatepickerApply
                id="work-order-completion-date-apply-btn"
              >
                Apply
              </button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error
            *ngIf="
              workOrderStatusValidationMessage.getErrorsBy(workOrderStatusFormField.COMPLETION_DATE)
            "
            >{{
              workOrderStatusValidationMessage.getMessageFor(
                workOrderStatusFormField.COMPLETION_DATE
              )
            }}</mat-error
          >
        </mat-form-field>
      </div>

      <div class="fields-line">
        <mat-form-field appearance="outline">
          <mat-label>{{ workOrderStatusNameToDisplay[workOrderStatusFormField.NOTE] }}</mat-label>
          <input
            matInput
            [formControlName]="workOrderStatusFormField.NOTE"
            id="work-order-note-input"
          />
          <mat-hint align="end"
            >{{
              workOrderFormGroup.get([
                workOrderFormField.WORK_ORDER_STATUS,
                workOrderStatusFormField.NOTE
              ])?.value.length
            }}/256</mat-hint
          >
          <mat-error
            *ngIf="workOrderStatusValidationMessage.getErrorsBy(workOrderStatusFormField.NOTE)"
            >{{
              workOrderStatusValidationMessage.getMessageFor(workOrderStatusFormField.NOTE)
            }}</mat-error
          >
        </mat-form-field>
      </div>

      <div class="fields-line">
        <mat-checkbox
          color="primary"
          class="checkbox"
          [formControlName]="workOrderStatusFormField.COMPLETED"
          >{{ workOrderStatusNameToDisplay[workOrderStatusFormField.COMPLETED] }}</mat-checkbox
        >
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [formGroupName]="workOrderFormField.VENDOR_INFORMATION">
      <mat-expansion-panel-header>
        <mat-panel-title>{{
          workOrderNameToDisplay[workOrderFormField.VENDOR_INFORMATION]
        }}</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="fields-line">
        <mat-form-field appearance="outline">
          <mat-label>{{
            workOrderVendorInformationNameToDisplay[workOrderVendorInformationFormField.VENDOR_NAME]
          }}</mat-label>
          <input
            matInput
            [formControlName]="workOrderVendorInformationFormField.VENDOR_NAME"
            id="work-order-vendor-lab-id-input"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{
            workOrderVendorInformationNameToDisplay[
              workOrderVendorInformationFormField.VENDOR_CONTACT_EMAIL
            ]
          }}</mat-label>
          <input
            matInput
            [formControlName]="workOrderVendorInformationFormField.VENDOR_CONTACT_EMAIL"
            id="work-order-vendor-contact-email-input"
          />
          <mat-hint align="end"
            >{{
              workOrderFormGroup.get([
                workOrderFormField.VENDOR_INFORMATION,
                workOrderVendorInformationFormField.VENDOR_CONTACT_EMAIL
              ])?.value.length
            }}/128</mat-hint
          >
          <mat-error
            *ngIf="
              workOrderVendorInformationValidationMessage.getErrorsBy(
                workOrderVendorInformationFormField.VENDOR_CONTACT_EMAIL
              )
            "
            >{{
              workOrderVendorInformationValidationMessage.getMessageFor(
                workOrderVendorInformationFormField.VENDOR_CONTACT_EMAIL
              )
            }}</mat-error
          >
        </mat-form-field>
      </div>

      <div class="fields-line">
        <mat-form-field appearance="outline">
          <mat-label>{{
            workOrderVendorInformationNameToDisplay[
              workOrderVendorInformationFormField.VENDOR_CONTACT_NAME
            ]
          }}</mat-label>
          <input
            matInput
            [formControlName]="workOrderVendorInformationFormField.VENDOR_CONTACT_NAME"
            id="work-order-vendor-contact-name-input"
          />
          <mat-hint align="end"
            >{{
              workOrderFormGroup.get([
                workOrderFormField.VENDOR_INFORMATION,
                workOrderVendorInformationFormField.VENDOR_CONTACT_NAME
              ])?.value.length
            }}/64</mat-hint
          >
          <mat-error
            *ngIf="
              workOrderVendorInformationValidationMessage.getErrorsBy(
                workOrderVendorInformationFormField.VENDOR_CONTACT_NAME
              )
            "
            >{{
              workOrderVendorInformationValidationMessage.getMessageFor(
                workOrderVendorInformationFormField.VENDOR_CONTACT_NAME
              )
            }}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{
            workOrderVendorInformationNameToDisplay[
              workOrderVendorInformationFormField.VENDOR_CONTACT_INFO
            ]
          }}</mat-label>
          <input
            matInput
            [formControlName]="workOrderVendorInformationFormField.VENDOR_CONTACT_INFO"
            id="work-order-vendor-contact-info-input"
          />
          <mat-hint align="end"
            >{{
              workOrderFormGroup.get([
                workOrderFormField.VENDOR_INFORMATION,
                workOrderVendorInformationFormField.VENDOR_CONTACT_INFO
              ])?.value.length
            }}/256</mat-hint
          >
          <mat-error
            *ngIf="
              workOrderVendorInformationValidationMessage.getErrorsBy(
                workOrderVendorInformationFormField.VENDOR_CONTACT_INFO
              )
            "
            >{{
              workOrderVendorInformationValidationMessage.getMessageFor(
                workOrderVendorInformationFormField.VENDOR_CONTACT_INFO
              )
            }}</mat-error
          >
        </mat-form-field>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [formGroupName]="workOrderFormField.EXPERIMENT_DETAIL">
      <mat-expansion-panel-header>
        <mat-panel-title>{{
          workOrderNameToDisplay[workOrderFormField.EXPERIMENT_DETAIL]
        }}</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="fields-line half-width">
        <mat-form-field appearance="outline">
          <mat-label>{{
            workOrderExperimentDetailNameToDisplay[
              workOrderExperimentDetailFormField.EXPERIMENT_TYPE
            ]
          }}</mat-label>
          <mat-select
            [formControlName]="workOrderExperimentDetailFormField.EXPERIMENT_TYPE"
            id="work-order-experiment-type-select"
          >
            <mat-option
              *ngFor="let experimentType of experimentTypeList"
              [value]="experimentType.id"
            >
              {{ experimentType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <app-dynamic-experiment-template
        [workOrderFormGroup]="workOrderFormGroup"
        [defaultExperimentFields]="defaultExperimentFields"
        [experimentTypeList]="experimentTypeList"
      ></app-dynamic-experiment-template>
    </mat-expansion-panel>
  </mat-accordion>
</form>
