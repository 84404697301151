import { AbstractControl, ValidationErrors } from '@angular/forms';

import { SampleFormGroup } from './sample-form-group.enum';

export const uniqueErrorName = 'unique';

export function unique(control: AbstractControl): ValidationErrors | null {
  const compositeValue = control.get(SampleFormGroup.COMPOSITE)?.value;

  if (compositeValue.length !== new Set(compositeValue).size) {
    return { [uniqueErrorName]: true };
  }

  return null;
}
