import { forkJoin, map } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgModule,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import {
  MatLegacyListModule as MatListModule,
  MatLegacySelectionList as MatSelectionList,
} from '@angular/material/legacy-list';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { IdName } from '@core/models/id-name.model';
import {
  MAX_ERROR_MESSAGE,
  MIN_OR_ZERO_ERROR_MESSAGE,
} from '@core/utils/form/error-message.config';
import { FlatFormValidationMessage } from '@core/utils/form/flat-form-validation-message.class';
import { REQUIRED_ONE_TRUE } from '@core/utils/form/validator.config';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { SynchronizeDepthUnitsModule } from 'src/app/common/directives/synchronize-depth-units.directive';
import { CapitalizeStringPipeModule } from 'src/app/common/pipes/capitalize-string.pipe';
import { UnitInputField } from 'src/app/common/unit-input/enums/unit-input-field.enum';
import { UnitInputModule } from 'src/app/common/unit-input/unit-input.component';
import { UnitAttributeService } from 'src/app/services/api/unit-attribute.service';
import { WellsService } from 'src/app/services/api/wells.service';

import { INITIAL_SAMPLE_FILTER_STATE } from '../consts/initial-sample-filter-state.const';
import { SampleFilters } from '../models/sample-filters.model';
import { WellSearch } from './models/well-search';

@Component({
  selector: 'app-lookup-sample-filter',
  templateUrl: './lookup-sample-filter.component.html',
  styleUrls: ['./lookup-sample-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookupSampleFilterComponent extends Destroyable(Object) implements OnInit {
  readonly minDepthFieldName = 'minDepth';
  readonly maxDepthFieldName = 'maxDepth';

  wellList = new Array<WellSearch>();
  lengthUnits: IdName[] = [];
  wellSearchTerm = '';
  dataObject!: SampleFilters;
  depthFormGroup!: UntypedFormGroup;
  sampleTypeFormGroup!: UntypedFormGroup;
  stateNameFormGroup!: UntypedFormGroup;
  sampleAvailabilityFormGroup!: UntypedFormGroup;

  private stateNameLabels: Record<string, string> = {
    own: 'Own',
    noAccess: 'No Access',
    approved: 'Approved',
    rejected: 'Rejected',
    pending: 'Pending',
  };

  private sampleAvailabilityLabels: Record<string, string> = {
    checkedIn: 'Checked-in',
    checkedOut: 'Checked-out',
  };

  private depthValidationMessage: FlatFormValidationMessage<Record<string, string>> | null = null;

  private initialSampleTypeControlValues!: Record<string, boolean>;

  constructor(
    public dialogRef: MatDialogRef<LookupSampleFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SampleFilters,
    private wellsService: WellsService,
    private unitAttributeService: UnitAttributeService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get stateNameOptions(): IdName[] {
    return Object.keys(this.stateNameLabels).map(
      (id) => ({ id, name: this.stateNameLabels[id] } as IdName),
    );
  }

  get sampleAvailabilityOptions(): IdName[] {
    return Object.keys(this.sampleAvailabilityLabels).map(
      (id) => ({ id, name: this.sampleAvailabilityLabels[id] } as IdName),
    );
  }

  get canApply(): boolean {
    return (
      this.depthFormGroup.valid &&
      (this.sampleTypeFormGroup.valid || this.sampleTypeFormGroup.disabled) &&
      (this.stateNameFormGroup.valid || this.stateNameFormGroup.disabled) &&
      (this.sampleAvailabilityFormGroup.valid || this.sampleAvailabilityFormGroup.disabled)
    );
  }

  ngOnInit(): void {
    this.initData();
    this.getOptionsForSelectors();
  }

  onWellSearchChanged(e: string): void {
    this.wellList.forEach((well) => {
      well.isHidden = !well.name.toLowerCase().includes(e.toLowerCase());
    });
  }

  onClearSearch(): void {
    this.wellSearchTerm = '';

    this.wellList.forEach((well) => {
      well.isHidden = false;
    });
  }

  clearFilters(list: MatSelectionList): void {
    list.selectedOptions.clear();
    this.resetFilter();
  }

  apply(): void {
    this.dataObject.minDepth =
      this.depthFormGroup.get([this.minDepthFieldName])!.value[UnitInputField.FIELD_VALUE] || 0;
    this.dataObject.maxDepth =
      this.depthFormGroup.get([this.maxDepthFieldName])!.value[UnitInputField.FIELD_VALUE] || 0;
    this.dataObject.maxDepthUnitId = this.depthFormGroup.get([this.minDepthFieldName])!.value[
      UnitInputField.UNIT_ID
    ];
    this.dataObject.minDepthUnitId = this.depthFormGroup.get([this.minDepthFieldName])!.value[
      UnitInputField.UNIT_ID
    ];
    this.dataObject.sampleTypes.forEach((sampleType) => {
      sampleType.checked = this.sampleTypeFormGroup.value[sampleType.id];
    });

    Object.assign(this.dataObject.stateName, this.stateNameFormGroup.value);
    Object.assign(this.dataObject.sampleAvailability, this.sampleAvailabilityFormGroup.value);
    this.dialogRef.close(this.dataObject);
  }

  errorMessageFunctionFor(fieldName: string): () => string {
    return () => this.depthValidationMessage!.getMessageFor(fieldName);
  }

  extractDepthControl(name: string): UntypedFormControl {
    return this.depthFormGroup.get([name]) as UntypedFormControl;
  }

  getSampleTypeErrorMessage(): string {
    return this.sampleTypeFormGroup.hasError('requiredOneTrue')
      ? 'At least one sample type must be selected'
      : '';
  }

  getAccessStatusErrorMessage(): string {
    return this.stateNameFormGroup.hasError('requiredOneTrue')
      ? 'At least one access status must be selected'
      : '';
  }

  getSampleAvailabilityErrorMessage(): string {
    return this.sampleAvailabilityFormGroup.hasError('requiredOneTrue')
      ? 'At least one sample availability option must be selected'
      : '';
  }

  nonZeroFieldValueOrNull(unitInputControlName: string): number | null {
    return (
      Number(this.extractDepthControl(unitInputControlName)!.value[UnitInputField.FIELD_VALUE]) ||
      null
    );
  }

  private initData(): void {
    this.dataObject = JSON.parse(JSON.stringify(this.data));
    this.initDepthFormGroup();
    this.initSampleTypeFormGroup();
    this.initAccessStatusFormGroup();
    this.initSampleAvailabilityFormGroup();
  }

  private initDepthFormGroup(): void {
    this.depthFormGroup = new UntypedFormGroup({
      minDepth: new UntypedFormControl({
        [UnitInputField.FIELD_VALUE]: this.dataObject?.minDepth || 0,
        [UnitInputField.UNIT_ID]: this.dataObject?.minDepthUnitId || '',
      }),
      maxDepth: new UntypedFormControl({
        [UnitInputField.FIELD_VALUE]: this.dataObject?.maxDepth || 0,
        [UnitInputField.UNIT_ID]: this.dataObject?.maxDepthUnitId || '',
      }),
    });

    this.depthValidationMessage = new FlatFormValidationMessage(
      this.depthFormGroup,
      {
        [this.minDepthFieldName]: 'Min Depth',
        [this.maxDepthFieldName]: 'Max Depth',
      },
      {
        ...MIN_OR_ZERO_ERROR_MESSAGE,
        ...MAX_ERROR_MESSAGE,
      },
    );
  }

  private initSampleTypeFormGroup(): void {
    const controls = this.dataObject.sampleTypes.reduce<Record<string, AbstractControl>>(
      (prev, curr) => ({ ...prev, [curr.id]: new UntypedFormControl(curr.checked) }),
      {},
    );

    this.sampleTypeFormGroup = new UntypedFormGroup(controls, REQUIRED_ONE_TRUE);

    this.initialSampleTypeControlValues = this.dataObject.sampleTypes.reduce<
      Record<string, boolean>
    >((prev, curr) => ({ ...prev, [curr.id]: true }), {});
  }

  private initAccessStatusFormGroup(): void {
    const stateNameKeyValuePairs = Object.entries(this.dataObject.stateName);
    const controls = Object.fromEntries(
      stateNameKeyValuePairs.map(([key, val]) => [key, new UntypedFormControl(val)]),
    );

    this.stateNameFormGroup = new UntypedFormGroup(controls, REQUIRED_ONE_TRUE);
  }

  private initSampleAvailabilityFormGroup(): void {
    const sampleAvailabilityKeyValuePairs = Object.entries(this.dataObject.sampleAvailability);
    const controls = Object.fromEntries(
      sampleAvailabilityKeyValuePairs.map(([key, val]) => [key, new UntypedFormControl(val)]),
    );
    this.sampleAvailabilityFormGroup = new UntypedFormGroup(controls, REQUIRED_ONE_TRUE);
  }

  private resetFilter(): void {
    const { filterType, sampleTypes, searchString, ...initialSampleFilterPopupState } = JSON.parse(
      JSON.stringify(INITIAL_SAMPLE_FILTER_STATE),
    ) as SampleFilters;
    this.dataObject = { ...this.dataObject, ...initialSampleFilterPopupState };
    this.depthFormGroup.reset();

    if (this.sampleTypeFormGroup.enabled) {
      this.sampleTypeFormGroup.reset(this.initialSampleTypeControlValues);
    }

    if (this.stateNameFormGroup.enabled) {
      this.stateNameFormGroup.reset(this.dataObject.stateName);
    }

    if (this.sampleAvailabilityFormGroup.enabled) {
      this.sampleAvailabilityFormGroup.reset(this.dataObject.sampleAvailability);
    }
  }

  private getOptionsForSelectors(): void {
    forkJoin([
      this.wellsService.getWells(),
      this.unitAttributeService.getAll().pipe(map(({ lengthUnits }) => lengthUnits)),
    ])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([wells, lengthUnits]) => {
        this.wellList = wells
          .map((item) => ({ id: item.id, name: item.name.toLowerCase(), isHidden: false }))
          .sort((item1, item2) => this.sort(item1.name, item2.name));
        this.lengthUnits = lengthUnits;
        this.cd.markForCheck();
      });
  }

  private sort(item1: string, item2: string): number {
    if (item1 > item2) {
      return 1;
    }

    if (item1 < item2) {
      return -1;
    }

    return 0;
  }
}

@NgModule({
  declarations: [LookupSampleFilterComponent],
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatListModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    MatCheckboxModule,
    MatIconModule,
    CommonModule,
    UnitInputModule,
    SynchronizeDepthUnitsModule,
    MatTooltipModule,
    CapitalizeStringPipeModule,
  ],
  exports: [LookupSampleFilterComponent],
})
export class LookupSampleFilterModule {}
