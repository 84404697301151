import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { TokenStorageService } from '../../services/token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  private get isUserAuthenticated(): boolean {
    return !!this.tokenStorageService.getAccessToken();
  }

  constructor(private tokenStorageService: TokenStorageService, private router: Router) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.isUserAuthenticated) {
      return true;
    }

    this.router.navigate(['login']);
    return false;
  }
}
