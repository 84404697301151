<h1 class="title">Work order</h1>

<div class="fields-line">
  <app-sample-table-selector-popup-field
    [formControl]="sampleIdFormControl"
    [label]="sampleControlDisplayName"
    [getErrorMessage]="getSampleIdErrorMessage.bind(this)"
  ></app-sample-table-selector-popup-field>

  <div class="create-composite-sample-btn-container">
    <button
      class="btn btn-primary btn-wider create-composite-sample-btn"
      type="button"
      (click)="createCompositeSample()"
      id="work-order-create-composite-btn"
    >
      Create composite sample
    </button>
  </div>
</div>

<ng-container *ngIf="{ workOrderList: existingWorkOrders$ | async } as sub">
  <app-work-order-logical-block title="Existing work orders" *ngIf="sub.workOrderList?.length">
    <app-existing-work-orders
      [existingWorkOrderList]="sub.workOrderList!"
      [vendorNameList]="vendorNames"
      [experimentTypeList]="experimentTypes"
      [sampleId]="sampleIdFormControl.value"
      (save)="save($event.throttleClick, $event.component)"
    ></app-existing-work-orders>
  </app-work-order-logical-block>
</ng-container>

<app-work-order-logical-block
  *ngFor="let workOrderFormGroup of workOrderFormArrayControls; let i = index"
  [title]="
    workOrderFormGroup.get([
      workOrderFormField.GENERAL_INFORMATION,
      workOrderGeneralInformationFormField.ID
    ])!.value
      ? workOrderFormGroup.get([
          workOrderFormField.GENERAL_INFORMATION,
          workOrderGeneralInformationFormField.PROJECT_NUMBER
        ])!.value
      : 'New experiment #' + (i + 1)
  "
>
  <app-work-order-form
    [workOrderFormGroup]="workOrderFormGroup"
    [vendorNameList]="vendorNames"
    [experimentTypeList]="experimentTypes"
    [sampleId]="sampleIdFormControl.value"
    #appWorkOrderForm
  ></app-work-order-form>

  <div class="work-order-actions">
    <div class="form-manipulation-buttons">
      <button
        class="btn btn-secondary btn-big"
        (click)="resetForm(appWorkOrderForm)"
        id="work-order-cancel-btn"
      >
        Cancel
      </button>
      <button
        class="btn btn-primary btn-big"
        appThrottleClick
        #throttleClick="throttleClick"
        (click)="save(throttleClick, appWorkOrderForm)"
        id="work-order-save-btn"
      >
        {{
          workOrderFormGroup.get([
            workOrderFormField.GENERAL_INFORMATION,
            workOrderGeneralInformationFormField.ID
          ])!.value
            ? 'Save'
            : 'Create'
        }}
      </button>
    </div>
  </div>
</app-work-order-logical-block>

<div class="actions">
  <div class="secondary-btn">
    <button
      class="btn btn-secondary add-work-order-btn"
      [disabled]="!canAddNextWorkOrder"
      (click)="addWorkOrderForm()"
      id="work-order-add-btn"
    >
      <mat-icon class="add-icon">add</mat-icon>
      Next experiment
    </button>
  </div>
</div>
