<h1 matDialogTitle>Edit work order</h1>

<mat-dialog-content>
  <app-work-order-form
    [workOrderFormGroup]="workOrderFormGroup"
    [vendorNameList]="vendorNames"
    [experimentTypeList]="experimentTypes"
    [sampleId]="sampleId"
    [expandFirst]="true"
    [defaultExperimentFields]="experimentFields"
    #appWorkOrderForm
  ></app-work-order-form>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="btn btn-secondary btn-big" [mat-dialog-close]="null">Close</button>
  <button
    class="btn btn-primary btn-big"
    id="work-order-popup-save-btn"
    appThrottleClick
    #throttleClick="throttleClick"
    (click)="save(throttleClick, appWorkOrderForm)"
  >
    Save
  </button>
</mat-dialog-actions>
