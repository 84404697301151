import { ChangeDetectionStrategy, Component, NgModule, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { UserInfo } from '@core/models/user-info.model';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { UserService } from '../../../services/api/user.service';
import { NotificationService } from '../../../services/notification.service';
import { UserManagementService } from '../../../services/user-management.service';
import { AccountInformationFormField } from '../form/account-information-form-field.enum';

@Component({
  selector: 'app-account-information-form',
  templateUrl: './account-information-form.component.html',
  styleUrls: ['./account-information-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountInformationFormComponent extends Destroyable(Object) implements OnInit {
  readonly accountInformationFormField = AccountInformationFormField;
  accountInformationFormGroup!: UntypedFormGroup;

  constructor(
    private userManagementService: UserManagementService,
    private userService: UserService,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  get userInfo(): Partial<UserInfo> {
    const userInfo = this.userManagementService.getUserInfo()!;
    return {
      [AccountInformationFormField.EMAIL]: userInfo.email ?? '',
      [AccountInformationFormField.USER_NAME]: userInfo.username ?? '',
      [AccountInformationFormField.EMPLOYEE_ID]: userInfo.employeeId ?? '',
      [AccountInformationFormField.BATCH_ID]: userInfo.batchId ?? '',
      [AccountInformationFormField.FIRST_NAME]: userInfo.firstName ?? '',
      [AccountInformationFormField.LAST_NAME]: userInfo.lastName ?? '',
      [AccountInformationFormField.CONTACT_NUMBER]: userInfo.contactNumber ?? '',
      [AccountInformationFormField.TEAM]: userInfo.team ?? '',
      [AccountInformationFormField.OFFICE_ADDRESS]: userInfo.address ?? '',
    };
  }

  ngOnInit(): void {
    this.createAccountInformationForm(this.userInfo);
  }

  resetAccountInformationForm(userInfo: Partial<UserInfo>): void {
    this.accountInformationFormGroup.reset(userInfo);
  }

  updateAccountInformation(): void {
    if (this.accountInformationFormGroup.invalid) {
      this.accountInformationFormGroup.markAllAsTouched();
      this.notificationService.notifyError('Account information form is not valid');
    }

    this.userService
      .updateAccountInformation(
        this.userManagementService.getUserInfo()!.id,
        this.accountInformationFormGroup.getRawValue(),
      )
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        const currentUserInfo = this.userManagementService.getUserInfo();
        this.userManagementService.addUserInfo({
          ...currentUserInfo,
          ...this.accountInformationFormGroup.getRawValue(),
        } as UserInfo);
        this.notificationService.notifySuccess('Account information has been successfully updated');
      });

    this.accountInformationFormGroup.markAsPristine();
  }

  private createAccountInformationForm(userInfo: Partial<UserInfo>): void {
    this.accountInformationFormGroup = this.formBuilder.group({
      [AccountInformationFormField.EMAIL]: [
        {
          value: userInfo.email,
          disabled: true,
        },
        [Validators.required, Validators.email],
      ],
      [AccountInformationFormField.USER_NAME]: [userInfo.username],
      [AccountInformationFormField.EMPLOYEE_ID]: [userInfo.employeeId],
      [AccountInformationFormField.BATCH_ID]: [userInfo.batchId],
      [AccountInformationFormField.FIRST_NAME]: [userInfo.firstName],
      [AccountInformationFormField.LAST_NAME]: [userInfo.lastName],
      [AccountInformationFormField.CONTACT_NUMBER]: [userInfo.contactNumber],
      [AccountInformationFormField.TEAM]: [userInfo.team],
      [AccountInformationFormField.OFFICE_ADDRESS]: [userInfo.address],
    });
  }
}

@NgModule({
  declarations: [AccountInformationFormComponent],
  exports: [AccountInformationFormComponent],
  imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule],
})
export class AccountInformationFormModule {}
