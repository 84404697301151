import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBarcode, faPrint, faQrcode } from '@fortawesome/free-solid-svg-icons';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { GenerateBarcodeService } from 'src/app/services/generate-barcode.service';
import { PrintBarcodeService } from 'src/app/services/print-barcode.service';

@Component({
  selector: 'app-print-barcode-menu',
  templateUrl: './print-barcode-menu.component.html',
  styleUrls: ['./print-barcode-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintBarcodeMenuComponent {
  @Input() barcodeValue: string | null = '';
  @Input() qrCodeValue: string | null = '';
  @Input() printName: string | null = '';

  readonly faBarcode = faBarcode;
  readonly faQrcode = faQrcode;
  readonly faPrint = faPrint;

  constructor(
    private generateBarcodeService: GenerateBarcodeService,
    private printBarcodeService: PrintBarcodeService,
  ) {}

  printBarcode(): void {
    this.generateBarcodeService
      .generateBarcode(this.barcodeValue!)
      .then((barcodeImg) => this.printBarcodeService.print(barcodeImg));
  }

  printQRCode(): void {
    this.generateBarcodeService
      .generateQRCode(this.qrCodeValue!)
      .then((qrCodeImg) => this.printBarcodeService.print(qrCodeImg));
  }
}
@NgModule({
  declarations: [PrintBarcodeMenuComponent],
  imports: [FontAwesomeModule, MatButtonModule, MatIconModule, MatMenuModule, CommonModule],
  exports: [PrintBarcodeMenuComponent],
})
export class PrintBarcodeMenuModule {}
