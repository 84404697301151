import {
  BehaviorSubject,
  catchError,
  finalize,
  MonoTypeOperatorFunction,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CollectionViewer, DataSource } from '@angular/cdk/collections';

import { LocationService } from '../../../../services/api/location.service';
import { LocationFilters } from '../models/location-filters.model';
import { LocationResult } from '../models/location-result.model';
import { SearchLocationResult } from '../models/search-location-result.model';
import { LocationsLookupService } from '../services/locations-lookup.service';

export class LocationsDataSource implements DataSource<LocationResult> {
  private locationsSubject = new BehaviorSubject<LocationResult[]>([]);
  private loadingCounterSubject = new BehaviorSubject<number>(0);
  private countSubject = new BehaviorSubject<number>(0);
  private loadLocationsStartedSubject = new Subject<void>();
  private searchByBarcodeStartedSubject = new Subject<void>();

  loadingCounter$ = this.loadingCounterSubject.asObservable();
  count$ = this.countSubject.asObservable();
  loadLocationsStarted$ = this.loadLocationsStartedSubject.asObservable();
  searchByBarcodeStarted$ = this.searchByBarcodeStartedSubject.asObservable();

  get data(): LocationResult[] {
    return this.locationsSubject.getValue();
  }

  constructor(
    private locationsLookupService: LocationsLookupService,
    private locationService: LocationService,
    private takeUntilDestroyed: <T>() => MonoTypeOperatorFunction<T>,
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<LocationResult[]> {
    return this.locationsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.locationsSubject.complete();
    this.loadingCounterSubject.complete();
    this.countSubject.complete();
    this.loadLocationsStartedSubject.complete();
    this.searchByBarcodeStartedSubject.complete();
  }

  loadLocations(filterData: LocationFilters): void {
    this.addLoadingRequest();
    this.loadLocationsStartedSubject.next();

    this.locationsLookupService
      .search(filterData)
      .pipe(
        catchError(() =>
          of({ data: [], pageIndex: 0, pageSize: 10, count: 0 } as SearchLocationResult),
        ),
        finalize(() => this.removeLoadingRequest()),
        takeUntil(this.loadLocationsStarted$),
        takeUntil(this.searchByBarcodeStarted$),
        this.takeUntilDestroyed(),
      )
      .subscribe((locations) => {
        this.countSubject.next(locations.count);
        this.locationsSubject.next(locations.data);
      });
  }

  searchByBarCode(searchString: string): void {
    this.addLoadingRequest();
    this.searchByBarcodeStartedSubject.next();

    this.locationService
      .searchByBarcode(searchString)
      .pipe(
        catchError(() => of([] as LocationResult[])),
        finalize(() => this.removeLoadingRequest()),
        takeUntil(this.loadLocationsStarted$),
        takeUntil(this.searchByBarcodeStarted$),
        this.takeUntilDestroyed(),
      )
      .subscribe((result) => {
        this.countSubject.next(result.length);
        this.locationsSubject.next(result);
      });
  }

  private addLoadingRequest(): void {
    this.loadingCounterSubject.next(this.loadingCounterSubject.getValue() + 1);
  }

  private removeLoadingRequest(): void {
    this.loadingCounterSubject.next(this.loadingCounterSubject.getValue() - 1);
  }
}
