import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API, FILE_SERVICE_API, PASS_ERROR_HEADER } from '@core/constants/api.consts';
import {
  AttachedFile,
  AttachedFileUploadedResponse,
} from 'src/app/features/sample/models/attached-file.model';

@Injectable({
  providedIn: 'root',
})
export class AttachedFileService {
  private readonly samplesUrl = `${CORE_MEMORY_API}/Samples`;
  private readonly filesUrl = `${FILE_SERVICE_API}/files`;

  constructor(private httpClient: HttpClient) {}

  getAll(sampleId: string): Observable<Array<AttachedFile>> {
    return this.httpClient.get<Array<AttachedFile>>(`${this.samplesUrl}/${sampleId}/files`);
  }

  upload(file: File): Observable<AttachedFileUploadedResponse> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', file.type);

    return this.httpClient.post<AttachedFileUploadedResponse>(`${this.filesUrl}`, formData);
  }

  getInfo(fileId: string): Observable<AttachedFileUploadedResponse> {
    return this.httpClient.get<AttachedFileUploadedResponse>(`${this.filesUrl}/info/${fileId}`);
  }

  attachFile(fileId: string, sampleId: string): Observable<null> {
    return this.httpClient.post<null>(`${this.samplesUrl}/${sampleId}/files`, {
      UploadedFileId: fileId,
    });
  }

  download(fileId: string): Observable<ArrayBuffer> {
    return this.httpClient.get(`${this.filesUrl}/${fileId}`, {
      responseType: 'arraybuffer',
    });
  }

  downloadImage(fileId: string): Observable<Blob> {
    return this.httpClient.get(`${this.filesUrl}/${fileId}`, {
      responseType: 'blob',
    });
  }

  delete(fileId: string): Observable<null> {
    return this.httpClient.delete<null>(`${this.filesUrl}/${fileId}`);
  }

  detachFromSample(fileId: string, sampleId: string): Observable<null> {
    return this.httpClient.delete<null>(`${this.samplesUrl}/${sampleId}/files/${fileId}`);
  }

  editDescription(fileId: string, sampleId: string, description: string): Observable<null> {
    return this.httpClient.put<null>(`${this.samplesUrl}/${sampleId}/files/${fileId}`, {
      description,
    });
  }

  uploadSampleImage(
    sampleImage: File,
  ): Observable<{ sampleImageId: string; sampleImageOriginId: string }> {
    const formData = new FormData();
    formData.append('file', sampleImage);

    return this.httpClient.post<{ sampleImageId: string; sampleImageOriginId: string }>(
      `${this.filesUrl}/SampleImage`,
      formData,
      { headers: { [PASS_ERROR_HEADER]: '' } },
    );
  }
}
