import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule, OnInit } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { Router, RouterModule } from '@angular/router';

import { USE_OKTA_AUTH_TOKEN } from '@core/tokens/use-okta-auth.token';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { WelcomePageModule } from 'src/app/common/welcome-page/welcome-page.component';
import { AuthenticationService } from 'src/app/services/api/authentication.service';
import { UserNotificationService } from 'src/app/services/api/user-notification.service';
import { UserManagementService } from 'src/app/services/user-management.service';

import { UserService } from '../../services/api/user.service';
import { TokenStorageService } from '../../services/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends Destroyable(Object) implements OnInit {
  get isOktaAuthUsed(): boolean {
    return this.useOktaAuth;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private tokenStorageService: TokenStorageService,
    private userManagementService: UserManagementService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private userNotificationService: UserNotificationService,
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
  ) {
    super();
  }

  ngOnInit(): void {
    this.tokenStorageService.removeTokens();
    this.userManagementService.removeUserInfo();
    this.dialog.closeAll();
  }

  login([email, password]: [string, string | null]): void {
    if (this.isOktaAuthUsed) {
      this.oktaAuth.signInWithRedirect();
      return;
    }

    this.authenticationService
      .login(email, password!)
      .pipe(this.takeUntilDestroyed())
      .subscribe((token) => {
        this.tokenStorageService.saveTokens(token);
        this.router.navigate(['/home']);
      });
  }
}

@NgModule({
  declarations: [LoginComponent],
  imports: [
    RouterModule.forChild([]),
    MatButtonModule,
    MatDialogModule,
    WelcomePageModule,
    CommonModule,
  ],
  exports: [LoginComponent],
})
export class LoginModule {}
