<div class="filter-block">
  <h2 class="filter-block__title">Sample Types</h2>

  <app-checkbox-list-field
    [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.TEAM].selectedSampleTypeIds"
    [checkboxListOptions]="filterLists.sampleTypeList"
  ></app-checkbox-list-field>
</div>

<div class="filter-block">
  <h2 class="filter-block__title">Experiment types</h2>

  <app-multi-select
    class="half-width"
    label="Experiment types"
    [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.TEAM].selectedExperimentTypeIds"
    [selectOptions]="filterLists.experimentTypeList"
    [multiSelect]="true"
  ></app-multi-select>
</div>

<div class="filter-block">
  <h2 class="filter-block__title">Status</h2>

  <app-checkbox-list-field
    [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.TEAM].selectedExperimentStatuses"
    [checkboxListOptions]="filterLists.experimentStatusList"
  ></app-checkbox-list-field>
</div>

<div class="filter-block">
  <h2 class="filter-block__title">Wells</h2>

  <app-multi-select
    class="half-width"
    label="Wells"
    [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.TEAM].selectedWellIds"
    [selectOptions]="filterLists.wellList"
    [multiSelect]="true"
  ></app-multi-select>
</div>

<div class="filter-block">
  <h2 class="filter-block__title">Duration</h2>

  <div class="fields-line">
    <mat-form-field appearance="outline" class="date-picker">
      <mat-label>Start date</mat-label>
      <input
        id="dashboard-work-order-options-popup-team-tab-start-date-input"
        readonly
        matInput
        [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.TEAM].selectedStartDate"
        [matDatepicker]="teamTabStartDatePicker"
        (click)="teamTabStartDatePicker.open()"
        (focus)="teamTabStartDatePicker.open()"
      />
      <div matSuffix class="date-picker-action-buttons">
        <mat-datepicker-toggle [for]="teamTabStartDatePicker"></mat-datepicker-toggle>
        <mat-icon
          id="dashboard-work-order-options-popup-team-tab-start-date-clear-input-button"
          appStopPropagationOnClick
          appClearObjectProperty
          [object]="filterState[dashboardWidgetOptionsPopupTab.TEAM]"
          [prop]="'selectedStartDate'"
          >close</mat-icon
        >
      </div>
      <mat-datepicker #teamTabStartDatePicker>
        <mat-datepicker-actions>
          <button
            id="dashboard-work-order-options-popup-team-tab-start-date-cancel-btn"
            class="btn btn-secondary btn-small"
            matDatepickerCancel
          >
            Cancel
          </button>
          <button
            id="dashboard-work-order-options-popup-team-tab-start-date-apply-btn"
            class="btn btn-primary btn-small"
            matDatepickerApply
          >
            Apply
          </button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="date-picker">
      <mat-label>End date</mat-label>
      <input
        id="dashboard-work-order-options-popup-team-tab-end-date-input"
        readonly
        matInput
        [(ngModel)]="filterState[dashboardWidgetOptionsPopupTab.TEAM].selectedEndDate"
        [matDatepicker]="teamTabEndDatePicker"
        (click)="teamTabEndDatePicker.open()"
        (focus)="teamTabEndDatePicker.open()"
      />
      <div matSuffix class="date-picker-action-buttons">
        <mat-datepicker-toggle [for]="teamTabEndDatePicker"></mat-datepicker-toggle>
        <mat-icon
          id="dashboard-work-order-options-popup-team-tab-end-date-clear-input-button"
          appStopPropagationOnClick
          appClearObjectProperty
          [object]="filterState[dashboardWidgetOptionsPopupTab.TEAM]"
          [prop]="'selectedEndDate'"
          >close</mat-icon
        >
      </div>
      <mat-datepicker #teamTabEndDatePicker>
        <mat-datepicker-actions>
          <button
            id="dashboard-work-order-options-popup-team-tab-end-date-cancel-btn"
            class="btn btn-secondary btn-small"
            matDatepickerCancel
          >
            Cancel
          </button>
          <button
            id="dashboard-work-order-options-popup-team-tab-end-date-apply-btn"
            class="btn btn-primary btn-small"
            matDatepickerApply
          >
            Apply
          </button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>
  </div>
</div>
