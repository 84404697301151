import { Chart, ChartModule } from 'angular-highcharts';
import { SeriesOptionsType } from 'highcharts';
import { forkJoin } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { IdName } from '@core/models/id-name.model';
import { UserTeam } from '@core/models/user-team.model';
import { WellsService } from 'src/app/services/api/wells.service';

import { formatDate } from '../../../core/utils/common/format-date.util';
import { Destroyable } from '../../../core/utils/mixins/destroyable.mixin';
import { SampleTypeService } from '../../../services/api/sample-type.service';
import { UserService } from '../../../services/api/user.service';
import { ExperimentTypeService } from '../../work-order/services/experiment-type.service';
import { WorkOrderService } from '../../work-order/services/work-order.service';
import { DashboardFilterResult } from '../models/dashboard-filter-result.model';
import { DashboardKpiSeriesColor } from './consts/dashboard-kpi-series-colors.const';
import { DASHBOARD_WIDGET_OPTIONS_POPUP_DISPLAY_LIST } from './consts/dashboard-widget-options-popup-display-list.const';
import { DASHBOARD_WIDGET_OPTIONS_POPUP_EXPERIMENT_STATUS_LIST } from './consts/dashboard-widget-options-popup-experiment-statuses.const';
import { DashboardWorkOrderSeriesColors } from './consts/dashboard-work-order-series-colors.const';
import { INITIAL_DASHBOARD_WIDGET_OPTIONS_POPUP_STATE_MODEL } from './consts/initial-dashboard-widget-options-popup-state.const';
import { DashboardWidgetOptionsPopupComponent } from './dashboard-widget-options-popup/dashboard-widget-options-popup.component';
import { DashboardWidgetOptionsPopupTab } from './enums/dashboard-widget-options-popup-tab.enum';
import { DashboardWidgetFilter } from './models/dashboard-widget-filter.model';
import { DashboardWidgetOptionsPopupData } from './models/dashboard-widget-options-popup-data.model';
import { DashboardWidgetOptionsPopupLists } from './models/dashboard-widget-options-popup-lists.model';
import { DashboardWidgetOptionsPopupState } from './models/dashboard-widget-options-popup-state.model';
import { DashboardWidgetFilterService } from './services/dashboard-widget-filter.service';

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWidgetComponent extends Destroyable(Object) implements OnInit {
  @Input() isKpi!: boolean;
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;

  private readonly activeTabToInteger: Record<string, number> = {
    [DashboardWidgetOptionsPopupTab.DATE]: 0,
    [DashboardWidgetOptionsPopupTab.TEAM]: 1,
    [DashboardWidgetOptionsPopupTab.TEAM_MEMBER]: 2,
  };

  private readonly displayToInteger: Record<string, number> = {
    day: 0,
    week: 1,
    month: 2,
  };

  filterPopupState!: DashboardWidgetOptionsPopupState;
  filterPopupLists!: DashboardWidgetOptionsPopupLists;
  chart!: Chart | null;
  filterData!: DashboardWidgetFilter;
  filterResult!: DashboardFilterResult[];

  constructor(
    private matDialog: MatDialog,
    private workOrderService: WorkOrderService,
    private cd: ChangeDetectorRef,
    private experimentTypeService: ExperimentTypeService,
    private dashboardWidgetFilterService: DashboardWidgetFilterService,
    private sampleTypeService: SampleTypeService,
    private userService: UserService,
    private wellsService: WellsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setInitialPopupState();
    this.getFilterPopupLists();
    this.fixResizing();
  }

  openOptionsPopup(): void {
    const initialFilterPopupState = JSON.parse(JSON.stringify(this.filterPopupState));
    this.matDialog
      .open<
        DashboardWidgetOptionsPopupComponent,
        DashboardWidgetOptionsPopupData,
        DashboardWidgetOptionsPopupState
      >(DashboardWidgetOptionsPopupComponent, {
        data: {
          state: this.filterPopupState,
          lists: this.filterPopupLists,
        },
        minWidth: 800,
        maxHeight: '80vh',
        height: '80vh',
        autoFocus: false,
      })
      .afterClosed()
      .pipe(this.takeUntilDestroyed())
      .subscribe((filterState) => {
        if (filterState) {
          this.filterAndGroupDataAndBuildChart(filterState!);
        } else {
          this.filterPopupState = initialFilterPopupState;
        }
      });
  }

  private fixResizing(): void {
    const resizeObserver = new ResizeObserver(() => {
      if (!this.chart?.ref) {
        return;
      }

      this.chart!.ref.setSize(
        this.chartContainer.nativeElement.offsetWidth,
        this.chartContainer.nativeElement.offsetHeight,
      );
    });
    resizeObserver.observe(this.chartContainer.nativeElement);
  }

  private filterAndGroupDataAndBuildChart(filterState: DashboardWidgetOptionsPopupState): void {
    const pickedDisplay: number | null =
      filterState.activeTab === DashboardWidgetOptionsPopupTab.DATE
        ? this.displayToInteger[filterState.Date.selectedDisplay]
        : null;

    const filterData: DashboardWidgetFilter = {
      isKpi: this.isKpi,
      activeTab: this.activeTabToInteger[filterState.activeTab],
      startDate: formatDate(filterState[filterState.activeTab].selectedStartDate, true),
      endDate: formatDate(filterState[filterState.activeTab].selectedEndDate, false, true),
      display: pickedDisplay,
      teams: this.dashboardWidgetFilterService.getTeamsObject(filterState, this.filterPopupLists),
      sampleTypes: this.dashboardWidgetFilterService.getSampleTypesObject(
        filterState,
        this.filterPopupLists,
      ),
      experimentTypes: this.dashboardWidgetFilterService.getExperimentTypesObject(
        filterState,
        this.filterPopupLists,
      ),
      status: {
        completed:
          filterState[filterState.activeTab].selectedExperimentStatuses.includes('completed'),
        ongoing: filterState[filterState.activeTab].selectedExperimentStatuses.includes('ongoing'),
        ongoingDelay:
          filterState[filterState.activeTab].selectedExperimentStatuses.includes('ongoing - delay'),
      },
      wells: this.dashboardWidgetFilterService.getWellsObject(filterState, this.filterPopupLists),
    };

    this.workOrderService
      .getFilteredWorkOrders(filterData)
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.filterResult = data;
        this.buildChart(this.filterResult, filterState);
      });
  }

  private buildChart(
    filterResult: DashboardFilterResult[],
    filterState: DashboardWidgetOptionsPopupState,
  ): void {
    const colors = this.isKpi ? DashboardKpiSeriesColor : DashboardWorkOrderSeriesColors;
    const categories = filterResult.map((resData) => resData.groupKey);
    const series = this.isKpi
      ? (DASHBOARD_WIDGET_OPTIONS_POPUP_EXPERIMENT_STATUS_LIST.filter(
          (el) =>
            !this.filterPopupState[this.filterPopupState.activeTab].selectedExperimentStatuses
              .length ||
            this.filterPopupState[
              this.filterPopupState.activeTab
            ].selectedExperimentStatuses.includes(el.id),
        ).map((el) => {
          return {
            name: el.id,
            data: filterResult.map(
              (resData) =>
                resData.value
                  .filter((val) => val.displayName === el.name)
                  .map((res) => res.count) || null,
            ),
            type: 'column',
            color: colors[el.id],
          };
        }) as SeriesOptionsType[])
      : (this.filterPopupLists.experimentTypeList
          .filter(
            (el) =>
              !this.filterPopupState[this.filterPopupState.activeTab].selectedExperimentTypeIds
                .length ||
              this.filterPopupState[
                this.filterPopupState.activeTab
              ].selectedExperimentTypeIds.includes(el.id),
          )
          .map((el) => {
            return {
              name: el.name,
              data: filterResult.map(
                (resData) =>
                  resData.value
                    .filter((val) => val.displayName === el.name)
                    .map((res) => res.count) || null,
              ),
              type: 'column',
              color: colors[el.id],
            };
          }) as SeriesOptionsType[]);

    const titleMatcher: Record<string, string> = {
      day: 'Days',
      week: 'Weeks',
      month: 'Months',
      [DashboardWidgetOptionsPopupTab.TEAM]: 'Teams',
      [DashboardWidgetOptionsPopupTab.TEAM_MEMBER]: 'Users',
    };
    const xTitleText =
      filterState.activeTab === DashboardWidgetOptionsPopupTab.DATE
        ? titleMatcher[filterState[DashboardWidgetOptionsPopupTab.DATE].selectedDisplay]
        : titleMatcher[filterState.activeTab];

    this.chart = new Chart({
      chart: {
        type: 'column',
        height: this.chartContainer.nativeElement.offsetHeight,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        categories,
        title: {
          text: xTitleText,
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Count (items)',
        },
        stackLabels: {
          enabled: false,
        },
      },
      legend: {
        enabled: this.isKpi,
        align: 'right',
        x: 0,
        verticalAlign: 'top',
        y: -10,
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
          events: {
            legendItemClick: () => false,
          },
        },
      },
      series,
    });
    this.cd.markForCheck();
  }

  private setInitialPopupState(): void {
    const sixMonthAgoDate = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString();
    const todayDate = new Date().toISOString();

    this.filterPopupState = {
      ...INITIAL_DASHBOARD_WIDGET_OPTIONS_POPUP_STATE_MODEL,
      [DashboardWidgetOptionsPopupTab.DATE]: {
        ...INITIAL_DASHBOARD_WIDGET_OPTIONS_POPUP_STATE_MODEL[DashboardWidgetOptionsPopupTab.DATE],
        selectedStartDate: sixMonthAgoDate,
        selectedEndDate: todayDate,
      },
      [DashboardWidgetOptionsPopupTab.TEAM]: {
        ...INITIAL_DASHBOARD_WIDGET_OPTIONS_POPUP_STATE_MODEL[DashboardWidgetOptionsPopupTab.DATE],
        selectedStartDate: sixMonthAgoDate,
        selectedEndDate: todayDate,
      },
      [DashboardWidgetOptionsPopupTab.TEAM_MEMBER]: {
        ...INITIAL_DASHBOARD_WIDGET_OPTIONS_POPUP_STATE_MODEL[DashboardWidgetOptionsPopupTab.DATE],
        selectedStartDate: sixMonthAgoDate,
        selectedEndDate: todayDate,
      },
    };
  }

  private getFilterPopupLists(): void {
    forkJoin([
      this.sampleTypeService.getAll(),
      this.experimentTypeService.getAll(true),
      this.userService.getUserTeams(),
      this.wellsService.getWells(),
    ])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([sampleTypes, experimentTypes, teams, wells]) => {
        this.filterPopupLists = {
          sampleTypeList: sampleTypes.filter((sampleType) => sampleType.name !== 'composite'),
          experimentTypeList: experimentTypes,
          teamList: teams.map((idTeam: UserTeam) => {
            return { id: idTeam.id, name: idTeam.team } as IdName;
          }),
          wellList: wells,
          experimentStatusList: DASHBOARD_WIDGET_OPTIONS_POPUP_EXPERIMENT_STATUS_LIST,
          displayList: DASHBOARD_WIDGET_OPTIONS_POPUP_DISPLAY_LIST,
        };
        this.filterAndGroupDataAndBuildChart(this.filterPopupState);
      });
  }
}

@NgModule({
  declarations: [DashboardWidgetComponent],
  imports: [CommonModule, ChartModule],
  exports: [DashboardWidgetComponent],
})
export class DashboardWidgetModule {}
