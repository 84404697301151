<form class="form" *ngIf="locationForm" [formGroup]="locationForm" autocomplete="off">
  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>{{ locationFormFieldNameToDisplay[locationFormField.NAME] }}</mat-label>
      <input matInput [formControl]="nameFormControl" id="location-name-btn" />
      <mat-hint align="end">{{ nameFormControl!.value.length }}/128</mat-hint>
      <mat-error *ngIf="nameFormControl!.errors">{{
        flatFormValidationMessage.getMessageFor(locationFormField.NAME)
      }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ locationFormFieldNameToDisplay[locationFormField.DESCRIPTION] }}</mat-label>
      <input matInput [formControl]="descriptionFormControl" id="location-description-btn" />
      <mat-hint align="end">{{ descriptionFormControl!.value.length }}/64</mat-hint>
      <mat-error *ngIf="descriptionFormControl!.errors">{{
        flatFormValidationMessage.getMessageFor(locationFormField.DESCRIPTION)
      }}</mat-error>
    </mat-form-field>
  </div>

  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>{{
        locationFormFieldNameToDisplay[locationFormField.LOCATION_TYPE_ID]
      }}</mat-label>
      <mat-select
        [formControl]="locationTypeIdFormControl"
        [compareWith]="compareWith"
        id="location-type-select"
      >
        <mat-option *ngFor="let locationType of locationTypes" [value]="locationType.id">
          {{ locationType.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="locationTypeIdFormControl!.errors">{{
        flatFormValidationMessage.getMessageFor(locationFormField.LOCATION_TYPE_ID)
      }}</mat-error>
    </mat-form-field>

    <app-multi-select
      [formControl]="locationRestrictionIdFormControl"
      [label]="locationFormFieldNameToDisplay[locationFormField.LOCATION_RESTRICTION_IDS]"
      [selectOptions]="locationRestrictions"
      [multiSelect]="true"
      (selectionChange)="onOptionSelection()"
      id="location-restriction-select"
    >
    </app-multi-select>
  </div>

  <div class="fields-line">
    <app-tree-view-selector-popup-field
      [formControl]="parentLocationIdFormControl"
      [label]="locationFormFieldNameToDisplay[locationFormField.PARENT_LOCATION_ID]"
      [treeViewNodeList]="locationNodes"
      [filterComponent]="locationTreeFilterComponent"
      [filterModule]="locationTreeFilterModule"
      [displayNamePipe]="locationDisplayNamePipe"
      [popupTitle]="'Select parent location'"
      [popupSelectedNodeName]="'location'"
      id="location-parent-input"
    >
    </app-tree-view-selector-popup-field>
  </div>

  <div class="fields-line barcode-fields">
    <mat-form-field appearance="outline">
      <mat-label>{{ locationFormFieldNameToDisplay[locationFormField.BARCODE_ID] }}</mat-label>
      <input
        data-ref="barcode-id-input"
        matInput
        [formControl]="barcodeIdFormControl"
        id="location-barcode-input"
      />
      <fa-icon
        [icon]="faBarcode"
        matSuffix
        appGetAndAssignBarcode
        appDisabledElement
        [disabled]="!isEditable"
        [barcodeControlNames]="{
          primary: locationFormField.BARCODE_ID,
          secondary: locationFormField.QR_CODE_ID
        }"
        id="location-barcode-btn"
      ></fa-icon>
      <mat-hint align="end">{{ barcodeIdFormControl!.value.length }}/128</mat-hint>
      <mat-error *ngIf="barcodeIdFormControl!.errors">{{
        flatFormValidationMessage.getMessageFor(locationFormField.BARCODE_ID)
      }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ locationFormFieldNameToDisplay[locationFormField.QR_CODE_ID] }}</mat-label>
      <input matInput [formControl]="qrCodeIdFormControl" id="location-qr-input" />
      <fa-icon
        [icon]="faQrcode"
        matSuffix
        appDisabledElement
        [disabled]="!isEditable"
        appGetAndAssignBarcode
        [barcodeControlNames]="{
          primary: locationFormField.QR_CODE_ID,
          secondary: locationFormField.BARCODE_ID
        }"
        id="location-qr-btn"
      ></fa-icon>
      <mat-hint align="end">{{ qrCodeIdFormControl!.value.length }}/128</mat-hint>
      <mat-error *ngIf="qrCodeIdFormControl!.errors">{{
        flatFormValidationMessage.getMessageFor(locationFormField.QR_CODE_ID)
      }}</mat-error>
    </mat-form-field>
  </div>
</form>
