<form [formGroup]="formGroup" autocomplete="off">
  <div class="row-with-picture">
    <div class="column">
      <div class="picture-container">
        <div
          class="sample-photo"
          *ngIf="!!picture.src"
          [style.background-image]="'url(' + picture.src + ')'"
        ></div>
        <div class="no-image" *ngIf="!picture.src">
          <mat-icon class="image-icon">image</mat-icon>
        </div>
        <div *ngIf="!isReadOnly">
          <button
            class="upload-btn"
            appUpload
            [ext]="'.jpeg,.jpg,.png'"
            [extErrorMessage]="'Application accepts only JPG, JPEG and PNG image formats'"
            [validators]="sampleImageValidators"
            (filePicked)="onSamplePhotoUpload($event)"
            id="image-upload-btn"
          >
            <span class="upload-btn-text" *ngIf="!picture.src">Upload image</span>
            <mat-icon class="image-upload-icon">file_upload</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="column">
      <div class="row">
        <mat-form-field>
          <mat-label>
            {{ sampleFormFieldLabel[sampleCheckInFormField.NAME] }}
          </mat-label>
          <input
            matInput
            [formControlName]="sampleCheckInFormField.NAME"
            id="sample-check-in-input"
          />
          <mat-hint align="end">{{ nameFormControl?.value?.length || 0 }}/128</mat-hint>
          <mat-error
            *ngIf="nameFormControl!.errors && nameFormControl!.hasError(errorTypes.required)"
          >
            {{ errorMessages.required }}
          </mat-error>
          <mat-error
            *ngIf="nameFormControl!.errors && nameFormControl!.hasError(errorTypes.length)"
          >
            {{ errorMessages.length }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <app-tree-view-selector-popup-field
          [formControlName]="sampleCheckInFormField.SAMPLE_LOCATION"
          [label]="sampleFormFieldLabel[sampleCheckInFormField.SAMPLE_LOCATION]"
          [required]="true"
          [treeViewNodeList]="sampleLocations"
          [filterComponent]="locationTreeFilterComponent"
          [filterModule]="locationTreeFilterModule"
          [displayNamePipe]="locationDisplayNamePipe"
          id="sample-location-input"
        ></app-tree-view-selector-popup-field>
      </div>
      <div *ngIf="showParentSample" class="row">
        <app-sample-table-selector-popup-field
          #parentSampleFieldComponent
          [formControlName]="sampleCheckInFormField.PARENT_SAMPLE"
          [label]="sampleFormFieldLabel[sampleCheckInFormField.PARENT_SAMPLE]"
          id="sample-parent-input"
        ></app-sample-table-selector-popup-field>
      </div>
      <div *ngIf="showRestriction" class="row">
        <app-multi-select
          [formControlName]="sampleCheckInFormField.RESTRICTION"
          [label]="sampleFormFieldLabel[sampleCheckInFormField.RESTRICTION]"
          [selectOptions]="restrictionTypes"
          [multiSelect]="true"
          id="sample-restriction-select"
          (selectionChange)="onOptionSelection()"
        >
        </app-multi-select>
      </div>
      <div class="row barcode-field">
        <mat-form-field>
          <mat-label>
            {{ sampleFormFieldLabel[sampleCheckInFormField.QRCODE] }}
          </mat-label>
          <input matInput [formControlName]="sampleCheckInFormField.QRCODE" id="sample-qr-input" />
          <fa-icon
            [icon]="faQrcode"
            matSuffix
            appGetAndAssignBarcode
            appDisabledElement
            [disabled]="isReadOnly"
            [barcodeControlNames]="{
              primary: sampleCheckInFormField.QRCODE,
              secondary: sampleCheckInFormField.BARCODE
            }"
            id="sample-general-qr-code-btn"
          ></fa-icon>

          <mat-hint align="end"> {{ qrcodeFormControl?.value?.length || 0 }}/128 </mat-hint>
          <mat-error
            *ngIf="qrcodeFormControl!.errors && qrcodeFormControl!.hasError(errorTypes.length)"
          >
            {{ errorMessages.length }}
          </mat-error>
          <mat-error
            *ngIf="qrcodeFormControl!.errors && qrcodeFormControl!.hasError(errorTypes.required)"
          >
            {{ errorMessages.required }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row barcode-field">
        <mat-form-field>
          <mat-label>
            {{ sampleFormFieldLabel[sampleCheckInFormField.BARCODE] }}
          </mat-label>
          <input
            matInput
            [formControlName]="sampleCheckInFormField.BARCODE"
            id="sample-barcode-input"
          />
          <fa-icon
            [icon]="faBarcode"
            matSuffix
            appGetAndAssignBarcode
            appDisabledElement
            [disabled]="isReadOnly"
            [barcodeControlNames]="{
              primary: sampleCheckInFormField.BARCODE,
              secondary: sampleCheckInFormField.QRCODE
            }"
            id="sample-general-barcode-code-btn"
          ></fa-icon>
          <mat-hint align="end"> {{ barcodeFormControl?.value?.length || 0 }}/128 </mat-hint>
          <mat-error
            *ngIf="barcodeFormControl!.errors && barcodeFormControl!.hasError(errorTypes.length)"
          >
            {{ errorMessages.length }}
          </mat-error>
          <mat-error
            *ngIf="barcodeFormControl!.errors && barcodeFormControl!.hasError(errorTypes.required)"
          >
            {{ errorMessages.required }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="column-half-width padding-right">
    <div class="row">
      <mat-form-field>
        <mat-label>
          {{ sampleFormFieldLabel[sampleCheckInFormField.OWNER] }}
        </mat-label>
        <input matInput [formControlName]="sampleCheckInFormField.OWNER" id="sample-owner-input" />
      </mat-form-field>
    </div>
  </div>
  <div class="column-half-width padding-left">
    <div class="row">
      <mat-form-field>
        <mat-label>
          {{ sampleFormFieldLabel[sampleCheckInFormField.CUSTODIAN] }}
        </mat-label>
        <input
          matInput
          [formControlName]="sampleCheckInFormField.CUSTODIAN"
          id="sample-custodian-input"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <mat-form-field class="textarea-container">
      <mat-label>
        {{ sampleFormFieldLabel[sampleCheckInFormField.DESCRIPTION] }}
      </mat-label>
      <textarea
        matInput
        [formControlName]="sampleCheckInFormField.DESCRIPTION"
        id="sample-description-input"
      ></textarea>
      <mat-hint align="end"> {{ descriptionFormControl?.value?.length || 0 }}/32 </mat-hint>
      <mat-error
        *ngIf="
          descriptionFormControl!.errors && descriptionFormControl!.hasError(errorTypes.length)
        "
      >
        {{ errorMessages.length }}
      </mat-error>
    </mat-form-field>
  </div>
</form>
