export const DashboardWorkOrderSeriesColors: Record<string, string> = {
  '1': 'var(--primary-color-eight)',
  '2': 'var(--primary-color-nine)',
  '3': '#00B2F9',
  '4': '#000000',
  '5': '#FF8F00',
  '6': '#35b067',
  '7': '#b8ad26',
  '8': '#6e1d72',
  '9': '#acf6ee',
  '10': '#bd0796',
  '11': '#1d349d',
  '12': '#8edabb',
  '13': '#af7bee',
  '14': '#b0b019',
  '15': '#9b9cdb',
  '16': '#dba1b5',
  '17': '#2631fa',
  '18': '#d159ed',
  '19': '#462d03',
  '20': '#ba4b3e',
  '21': '#8e8e91',
  '22': '#33ff2c',
  '23': '#455659',
  '24': '#ecc01c',
};
