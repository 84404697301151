import { filter } from 'rxjs';

import { CommonModule, formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  MatLegacyPaginator as MatPaginator,
  MatLegacyPaginatorModule as MatPaginatorModule,
} from '@angular/material/legacy-paginator';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSort, MatSortModule } from '@angular/material/sort';

import { SampleAccessAction } from '../../../common/access-request-popup/models/sample-access-action.model';
import { ConfirmationPopupComponent } from '../../../common/confirmation-popup/confirmation-popup.component';
import { Destroyable } from '../../../core/utils/mixins/destroyable.mixin';
import { UserNotificationService } from '../../../services/api/user-notification.service';
import { NotificationService } from '../../../services/notification.service';
import { StateName } from '../../data-access/lookup-sample/enums/access-status.enum';
import { SampleAccessInfo } from '../models/sample-access-info.model';
import { SampleAccessOptions } from '../models/sample-access-options.model';

@Component({
  selector: 'app-sample-access-table',
  templateUrl: './sample-access-table.component.html',
  styleUrls: ['./sample-access-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleAccessTableComponent extends Destroyable(Object) {
  @Input() sampleAccess = new Array<SampleAccessInfo>();
  @Input() isReadOnly = false;
  @Input() isOwner = false;

  readonly displayedColumns: string[] = ['user', 'grantDate', 'status', 'actions'];
  readonly sampleAccessAction = SampleAccessAction;
  dialogOption!: string;

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  constructor(
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private userNotificationService: UserNotificationService,
    private notificationService: NotificationService,
  ) {
    super();
  }

  openDialog(accessData: SampleAccessInfo, accessDialogOption: SampleAccessAction): void {
    switch (accessDialogOption) {
      case SampleAccessAction.REJECT:
        this.dialogOption = 'Are you sure you want to deny access?';
        break;
      case SampleAccessAction.APPROVE:
        this.dialogOption = 'Are you sure you want to grant access?';
        break;
      case SampleAccessAction.REVOKE:
        this.dialogOption = 'Are you sure you want to revoke access?';
        break;

      default:
        break;
    }
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '400px',
      data: {
        action: this.dialogOption,
        buttonName: 'Confirm',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((sampleAccessData) => !!sampleAccessData),
        this.takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.onSampleAccessRequest(accessData, accessDialogOption);
      });
  }

  private onSampleAccessRequest(
    accessOption: SampleAccessInfo,
    accessDialogOption: SampleAccessAction,
  ): void {
    const sampleAccessOptions: SampleAccessOptions = {
      [SampleAccessAction.REJECT]: {
        status: SampleAccessAction.REJECT,
        message: 'Access request has been denied',
      },
      [SampleAccessAction.APPROVE]: {
        status: SampleAccessAction.APPROVE,
        message: 'Access request has been successfully granted',
      },
      [SampleAccessAction.REVOKE]: {
        status: SampleAccessAction.REVOKE,
        message: 'Access request has been successfully revoked',
      },
    };

    if (accessDialogOption === SampleAccessAction.APPROVE) {
      this.userNotificationService
        .updateSampleAccessRequestStatus(accessOption.id, StateName.APPROVED)
        .pipe(this.takeUntilDestroyed())
        .subscribe(() => {
          accessOption.grantDate = formatDate(new Date(), 'mediumDate', 'en-US');
          accessOption.status = sampleAccessOptions[accessDialogOption].status;
          this.notificationService.notifySuccess(sampleAccessOptions[accessDialogOption].message);
          this.cd.markForCheck();
        });
    }

    if (
      accessDialogOption === SampleAccessAction.REJECT ||
      accessDialogOption === SampleAccessAction.REVOKE
    ) {
      this.userNotificationService
        .updateSampleAccessRequestStatus(accessOption.id, StateName.REJECTED)
        .pipe(this.takeUntilDestroyed())
        .subscribe(() => {
          accessOption.grantDate = '';
          accessOption.status = sampleAccessOptions[accessDialogOption].status;
          this.sampleAccess = this.sampleAccess.filter(
            (access) => access.status !== SampleAccessAction.REJECT,
          );
          this.notificationService.notifySuccess(sampleAccessOptions[accessDialogOption].message);
          this.cd.markForCheck();
        });
    }
  }
}

@NgModule({
  declarations: [SampleAccessTableComponent],
  imports: [CommonModule, MatPaginatorModule, MatSortModule, MatTableModule],
  exports: [SampleAccessTableComponent],
})
export class SampleAccessTableModule {}
