import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { UserNotification } from '@core/models/user-notification.model';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { ConfirmationPopupComponent } from '../../../common/confirmation-popup/confirmation-popup.component';
import { UserNotificationService } from '../../../services/api/user-notification.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-data-access-request-status-notification',
  templateUrl: './data-access-request-status-notification.component.html',
  styleUrls: ['./data-access-request-status-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataAccessRequestStatusNotificationComponent extends Destroyable(Object) {
  @Input() title = 'Sample request status';
  @Input() model!: UserNotification;

  constructor(
    private userNotificationService: UserNotificationService,
    private notificationService: NotificationService,
    private matDialog: MatDialog,
  ) {
    super();
  }

  dismiss(): void {
    this.userNotificationService
      .dismissDataAccessStatusNotification(this.model.id)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess('Message has been dismissed');
        this.userNotificationService.getNotifications();
      });
  }

  cancel(): void {
    this.matDialog
      .open(ConfirmationPopupComponent, {
        data: {
          buttonName: 'Yes',
          cancelButtonName: 'No',
          action: 'Are you sure you want to cancel the request ',
        },
        width: '800px',
        autoFocus: false,
      })
      .afterClosed()
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        if (data) {
          this.cancelNotificationRequest();
        }
      });
  }

  cancelNotificationRequest(): void {
    this.userNotificationService
      .cancelNotificationRequestStatus(this.model.id)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_d) => {
        this.notificationService.notifySuccess('Request has been canceled');
        this.userNotificationService.getNotifications();
      });
  }
}
@NgModule({
  declarations: [DataAccessRequestStatusNotificationComponent],
  imports: [CommonModule],
  exports: [DataAccessRequestStatusNotificationComponent],
})
export class DataAccessRequestStatusNotificationModule {}
