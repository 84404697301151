export class CoreInformation {
  depthTop: number;
  depthBottom: number;
  depthUnitId: string;
  preservationId: string;

  constructor(coreInformation: CoreInformation) {
    this.depthTop = coreInformation.depthTop;
    this.depthBottom = coreInformation.depthBottom;
    this.depthUnitId = coreInformation.depthUnitId;
    this.preservationId = coreInformation.preservationId;
  }
}
