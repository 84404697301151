import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgModule,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

import { NotificationService } from '../../services/notification.service';
import { UploadFileValidator } from './models/upload-file-validator.model';

@Directive({
  selector: '[appUpload]',
  exportAs: 'uploadDirective',
})
export class UploadDirective implements OnChanges, OnInit {
  @Input() ext = '';
  @Input() multipleFile = false;
  @Input() extErrorMessage = 'Unsupported file format';
  @Input() validators: UploadFileValidator[] = [];
  uploadElement: any;
  buttonElement: any;

  @Output() filePicked = new EventEmitter();

  constructor(
    private buttonRef: ElementRef,
    private renderer2: Renderer2,
    private notificationService: NotificationService,
  ) {
    this.buttonElement = this.buttonRef;
    this.setupUploadInput();
  }

  ngOnInit() {
    if (this.multipleFile) this.resetInput();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ext'].currentValue) {
      this.renderer2.setAttribute(this.uploadElement, 'accept', this.ext || '');
    }
  }

  resetInput(): void {
    this.renderer2.removeChild(
      (this.buttonElement as ElementRef).nativeElement.parentElement,
      this.uploadElement,
    );
    this.setupUploadInput();
    this.renderer2.setAttribute(this.uploadElement, 'accept', this.ext || '');
  }

  @HostListener('click', ['$event'])
  private onParentClicked(_: Event): void {
    //* HACK: HACK */: Security hack (by default input[type="file"] elementRef cannot be clicked from the code)
    const up = this.uploadElement.parentElement.querySelector('input[type="file"]');
    up?.click();
    _.preventDefault();
  }

  private onUploadClicked(event: Event): void {
    const file = (event.target as HTMLInputElement).files!.item(0)!;
    const fileParts = file.name.split('.');
    const fileExt = `.${fileParts[fileParts.length - 1]}`;

    if (
      this.ext &&
      !this.ext.split(',').some((ext) => ext.toLowerCase() === fileExt.toLowerCase())
    ) {
      this.notificationService.notifyError(this.extErrorMessage);
      return;
    }

    const notPassedValidator = this.validators.find((validator) => !validator.validate(file));

    if (notPassedValidator) {
      this.notificationService.notifyError(notPassedValidator.errorMessage);
      return;
    }

    this.filePicked.emit(event);
    this.resetInput();
  }

  private setupUploadInput(): void {
    this.uploadElement = this.renderer2.createElement('input');
    this.renderer2.setAttribute(this.uploadElement, 'type', 'file');
    if (this.multipleFile) {
      this.renderer2.setAttribute(this.uploadElement, 'multiple', 'multiple');
    }
    this.renderer2.setAttribute(this.uploadElement, 'name', 'upload-input');
    this.renderer2.setAttribute(this.uploadElement, 'accept', this.ext || '');
    this.renderer2.setStyle(this.uploadElement, 'display', 'none');
    this.renderer2.listen(this.uploadElement, 'change', ($event) => this.onUploadClicked($event));
    this.renderer2.appendChild(
      (this.buttonElement as ElementRef).nativeElement.parentElement,
      this.uploadElement,
    );
  }
}

@NgModule({
  declarations: [UploadDirective],
  exports: [UploadDirective],
})
export class UploadModule {}
