import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { fromEvent, startWith } from 'rxjs';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgModule,
  OnInit,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';

import { FOOTER_TEXT } from '@core/constants/consts';
import { USE_OKTA_AUTH_TOKEN } from '@core/tokens/use-okta-auth.token';
import { WINDOW } from '@core/tokens/window.token';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { OrganizationService } from '../../services/api/organization.service';
import { UserNotificationService } from '../../services/api/user-notification.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { UserManagementService } from '../../services/user-management.service';
import { AppMenuRoutePath } from './enums/app-menu-route-path.enum';
import {
  NotImplementedInformationPopupComponent,
  NotImplementedInformationPopupModule,
} from './not-implemented-information-popup/not-implemented-information-popup.component';

@Component({
  selector: 'app-application-menu',
  templateUrl: './application-menu.component.html',
  styleUrls: ['./application-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationMenuComponent extends Destroyable(Object) implements OnInit {
  readonly footerText = FOOTER_TEXT;
  appName: string = '';
  appDescription: string = '';
  backgroundImage: string = '';
  fullLogo: string = '';

  constructor(
    private organizationService: OrganizationService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private userNotificationService: UserNotificationService,
    private tokenStorageService: TokenStorageService,
    private userManagementService: UserManagementService,
    private matDialog: MatDialog,
    @Inject(WINDOW) private window: Window,
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeOrganizationInfo();
    this.fixResizing();
  }

  goToDashboardApp(): void {
    this.router.navigateByUrl(AppMenuRoutePath.DASHBOARD_APP);
  }

  goToAcmeTrackerApp(): void {
    this.router.navigateByUrl(AppMenuRoutePath.ACME_TRACKER_APP);
  }

  goToCoreAnalysisWizardApp(): void {
    this.router.navigateByUrl(AppMenuRoutePath.CORE_ANALYSIS_WIZARD_APP);
  }

  logout(): void {
    this.userManagementService.removeUserInfo();
    this.userNotificationService.resetNotifications();

    if (this.useOktaAuth) {
      this.oktaAuth.signOut();
    } else {
      this.tokenStorageService.removeTokens();
      this.router.navigate(['login']);
    }
  }

  showToBeImplementedMessage(appName: string): void {
    this.matDialog.open(NotImplementedInformationPopupComponent, {
      width: '400px',
      height: '175px',
      data: `${appName} is coming!`,
    });
  }

  private fixResizing(): void {
    fromEvent(this.window, 'resize')
      .pipe(startWith(null), this.takeUntilDestroyed())
      .subscribe(() => {
        const defaultHexagonBtnHeightInPx = 350;
        const headerAndFooterHeightInPx = 244;
        const defaultHexagonMenuHeightInPx = defaultHexagonBtnHeightInPx * 1.68;
        const minimalDefaultHexagonMenuHeightInPx =
          headerAndFooterHeightInPx + defaultHexagonMenuHeightInPx;
        if (this.window.innerHeight > minimalDefaultHexagonMenuHeightInPx) {
          this.setHexagonProperties(defaultHexagonBtnHeightInPx);
        } else {
          const calculatedHexagonHeightInPx =
            (this.window.innerHeight - headerAndFooterHeightInPx) / 2;
          this.setHexagonProperties(calculatedHexagonHeightInPx);
        }
      });
  }

  private setHexagonProperties(hexagonHeightInPx: number): void {
    const defaultHexagonBtnHeightInPx = 350;
    const root = document.documentElement;
    root.style.setProperty('--hexagon-btn-height', `${hexagonHeightInPx}px`);
    root.style.setProperty(
      '--hexagon-btn-width',
      `${hexagonHeightInPx - hexagonHeightInPx / 14}px`,
    );
    root.style.setProperty('--hexagon-btn-margin', `${hexagonHeightInPx / 14}px`);
    root.style.setProperty(
      '--hexagon-btn__content-scale',
      `${hexagonHeightInPx / defaultHexagonBtnHeightInPx}`,
    );
    root.style.setProperty('overflow', 'auto');
  }

  private initializeOrganizationInfo(): void {
    this.organizationService
      .getOrganizations()
      .pipe(this.takeUntilDestroyed())
      .subscribe((organizations) => {
        if (organizations.length) {
          this.appName = organizations[0].appName;
          this.appDescription = organizations[0].appDescription;
          this.backgroundImage = `url("${organizations[0].landingPageBackgroundImagePath}")`;
          this.fullLogo = organizations[0].organizationFullIconImagePath;
          this.changeDetectorRef.markForCheck();
        }
      });
  }
}

@NgModule({
  declarations: [ApplicationMenuComponent],
  exports: [ApplicationMenuComponent],
  imports: [MatButtonModule, MatIconModule, MatDialogModule, NotImplementedInformationPopupModule],
})
export class ApplicationMenuModule {}
