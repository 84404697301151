import { saveAs } from 'file-saver';
import { concatMap, filter, finalize, map } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FILE_ATTACH_BLOCK_EXT } from '@core/constants/file-extensions.consts';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { AttachedFileService } from 'src/app/services/api/attached-file.service';

import {
  ConfirmationPopupComponent,
  ConfirmationPopupModule,
} from '../../../common/confirmation-popup/confirmation-popup.component';
import { UploadModule } from '../../../common/directives/upload.directive';
import { ConvertFileSizeModule } from '../../../common/pipes/convert-filesize.pipe';
import { SimulationService } from '../../../services/api/simulation.service';
import { NotificationService } from '../../../services/notification.service';
import { FileType } from '../enums/file-type.enum';
import { AttachedFile } from '../models/attached-file.model';
import { SimulationStatus } from '../models/simulation-status.model';

@Component({
  selector: 'app-attached-files-block',
  templateUrl: './attached-files-block.component.html',
  styleUrls: ['./attached-files-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachedFilesBlockComponent extends Destroyable(Object) implements OnInit {
  @Input() isReadOnly = false;
  @Input() sampleId!: string;

  readonly fileType = FileType;
  readonly displayedColumns = [
    'fileName',
    'createdAt',
    'size',
    'fileType',
    'description',
    'actions',
  ];

  readonly isSimulationStatusRow = (index: number, item: AttachedFile | SimulationStatus) =>
    'attached' in item;

  isFileUploading = false;
  isGettingTableInfo = false;
  pickedId: string | null = null;
  dataSource!: MatTableDataSource<AttachedFile | SimulationStatus>;
  simulationStatusList: SimulationStatus[] = [];

  extensions = FILE_ATTACH_BLOCK_EXT.toString();

  private file: File | null = null;
  private attachedFiles = new Array<AttachedFile>();

  get isReloadBtnVisible(): boolean {
    return this.simulationStatusList.some((status) => status.status === 'InProgress');
  }

  constructor(
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    private attachedFileService: AttachedFileService,
    private simulationService: SimulationService,
    private notificationService: NotificationService,
  ) {
    super();
    this.dataSource = new MatTableDataSource([...this.attachedFiles, ...this.simulationStatusList]);
  }

  ngOnInit(): void {
    this.getTableData();
  }

  uploadFile(e: Event): void {
    const target = e.target as HTMLInputElement;
    if (target?.files?.length) {
      this.file = target.files.item(0)!;
      this.isFileUploading = true;

      this.attachedFileService
        .upload(this.file)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: (result: any) => {
            this.attachFileToSample(result.id, this.sampleId);
          },
          error: (error) => {
            this.notificationService.notifyError(
              `File wasn't uploaded.
              Reason: ${error.message}
              `,
            );
          },
          complete: () => {
            this.isFileUploading = false;
            this.cd.detectChanges();
          },
        });
    }
  }

  downloadFile(fileId: string, fileName: string): void {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '400px',
      data: {
        action: 'Are you sure you want to download this file?',
        buttonName: 'Download',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((attachedFile) => !!attachedFile),
        this.takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.attachedFileService
          .download(fileId)
          .pipe(this.takeUntilDestroyed())
          .subscribe((file) => {
            const blob: any = new Blob([file], { type: 'text/json; charset=utf-8' });
            saveAs(blob, fileName);
            this.notificationService.notifySuccess('File downloaded successfully');
            this.cd.markForCheck();
          });
      });
  }

  deleteFile(id: string): void {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '400px',
      data: {
        action: 'Are you sure you want to delete this file?',
        buttonName: 'Delete',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((attachedFile) => !!attachedFile),
        this.takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.attachedFileService
          .delete(id)
          .pipe(this.takeUntilDestroyed())
          .subscribe(() => {
            this.detachFileFromSample(id, this.sampleId);
          });
      });
  }

  saveEdit(id: string, description: string): void {
    this.attachedFiles = this.attachedFiles.map((file) =>
      file.fileId === id ? { ...file, description } : file,
    );
    this.pickedId = null;

    this.attachedFileService
      .editDescription(id, this.sampleId, description)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess("File's description updated successfully");
        this.getTableData();
      });
  }

  cancelEdit(): void {
    this.pickedId = null;
  }

  getTableData(): void {
    this.isGettingTableInfo = true;
    this.simulationService
      .getSimulationStatuses(this.sampleId)
      .pipe(
        concatMap((statuses) =>
          this.attachedFileService
            .getAll(this.sampleId)
            .pipe(
              map<AttachedFile[], [AttachedFile[], SimulationStatus[]]>((files) => [
                files,
                statuses,
              ]),
            ),
        ),
        finalize(() => {
          this.isGettingTableInfo = false;
          this.cd.markForCheck();
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe(([files, statuses]) => {
        this.attachedFiles = files;
        this.simulationStatusList = statuses.filter(
          (status) => !(status.status === 'Finished' && status.attached),
        );
        this.dataSource.data = [...this.attachedFiles, ...this.simulationStatusList];
      });
  }

  private attachFileToSample(fileId: string, sampleId: string): void {
    this.attachedFileService
      .attachFile(fileId, sampleId)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_) => {
        this.notificationService.notifySuccess('File uploaded successfully');
        this.getTableData();
      });
  }

  private detachFileFromSample(fileId: string, sampleId: string): void {
    this.attachedFileService
      .detachFromSample(fileId, sampleId)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_) => {
        this.getTableData();
        this.notificationService.notifySuccess('File deleted successfully');
        this.cd.markForCheck();
      });
  }
}

@NgModule({
  declarations: [AttachedFilesBlockComponent],
  imports: [
    CommonModule,
    UploadModule,
    MatIconModule,
    MatTableModule,
    MatFormFieldModule,
    MatDialogModule,
    FormsModule,
    MatInputModule,
    ConfirmationPopupModule,
    BrowserAnimationsModule,
    ConvertFileSizeModule,
  ],
  exports: [AttachedFilesBlockComponent],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: { action: '', buttonName: '' } }],
})
export class AttachedFilesModule {}
