import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';

import { IdName } from '@core/models/id-name.model';

import { MultiSelectModule } from '../multi-select/multi-select.component';
import { UnitInputModule } from '../unit-input/unit-input.component';
import { DigitalVolumeInformationFormField } from './form/digital-volume-information-form-field.enum';
import { DIGITAL_VOLUME_INFORMATION_FORM_LABEL } from './form/digital-volume-information-form-labels';

@Component({
  selector: 'app-digital-volume-information',
  templateUrl: './digital-volume-information.component.html',
  styleUrls: ['./digital-volume-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigitalVolumeInformationComponent {
  @Input() formGroup!: FormGroup;
  @Input() sampleTypes = new Array<IdName>();
  @Input() volumeTypes = new Array<IdName>();
  @Input() dataFormats = new Array<IdName>();
  @Input() orientationList = new Array<IdName>();
  @Input() voxelUnits = new Array<IdName>();
  @Input() depthUnits = new Array<IdName>();
  @Input() direction = new Array<string>();
  @Input() pictureUrl!: string;
  @Input() maxSize!: number;
  @Input() digitalVolumeFileList!: IdName[];
  @Input() digitalVolumeFileControl = new UntypedFormControl('');

  readonly digitalVolumeInformationFormField = DigitalVolumeInformationFormField;
  readonly digitalvolumeinformationformlabel = DIGITAL_VOLUME_INFORMATION_FORM_LABEL;
  readonly compareWith = (o1: string | null, o2: string) => o1?.toLowerCase() === o2.toLowerCase();

  constructor(private cd: ChangeDetectorRef) {}

  markForCheck(): void {
    this.cd.markForCheck();
  }
}

@NgModule({
  declarations: [DigitalVolumeInformationComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MultiSelectModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSliderModule,
    UnitInputModule,
    MatLegacySelectModule,
  ],
  exports: [DigitalVolumeInformationComponent],
})
export class DigitalVolumeInformationModule {}
