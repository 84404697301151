import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { Well } from '@core/models/well.model';

@Pipe({
  name: 'wellDisplayName',
})
export class WellDisplayNamePipe implements PipeTransform {
  transform(well: Well | null): string {
    if (!well) {
      return '';
    }

    const { name, field, location, reservoir } = well;
    return [name, field, location, reservoir].filter((el) => !!el).join('-');
  }
}

@NgModule({
  declarations: [WellDisplayNamePipe],
  exports: [WellDisplayNamePipe],
})
export class WellDisplayNameModule {}
