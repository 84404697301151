<h1 matDialogTitle>Search and select well</h1>

<mat-dialog-content>
  <app-lookup-well [isSelector]="true" #appLookupWell></app-lookup-well>
</mat-dialog-content>

<mat-dialog-actions>
  <span class="selected-well-info"
    >Selected well: {{ (appLookupWell.selectedWell | wellDisplayName) || '[no value]' }}</span
  >
  <button
    class="btn btn-secondary btn-big"
    [mat-dialog-close]="null"
    id="well-table-selector-close-btn"
  >
    Close
  </button>
  <button
    class="btn btn-primary btn-big"
    [mat-dialog-close]="appLookupWell.selectedWell"
    [disabled]="!appLookupWell.selectedWell"
    id="well-table-selector-apply-btn"
  >
    Apply
  </button>
</mat-dialog-actions>
