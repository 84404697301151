import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgModule,
  ViewChild,
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import {
  DigitalMicpSimulationComponent,
  DigitalMicpSimulationModule,
} from '../../digital-micp-simulation/digital-micp-simulation.component';

@Component({
  selector: 'app-work-flow-popup-micp',
  templateUrl: './work-flow-popup-micp.component.html',
  styleUrls: ['./work-flow-popup-micp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkFlowPopupMicpComponent extends Destroyable(Object) implements AfterViewInit {
  @ViewChild('micpSimulationComponent')
  micpSimulationComponent!: DigitalMicpSimulationComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<WorkFlowPopupMicpComponent>,
  ) {
    super();
  }

  ngAfterViewInit() {
    this.micpSimulationComponent.closeDialogPopup.pipe(this.takeUntilDestroyed()).subscribe(() => {
      this.matDialogRef.close();
    });
  }

  save(component: DigitalMicpSimulationComponent): void {
    component.save();
  }
}

@NgModule({
  declarations: [WorkFlowPopupMicpComponent],
  exports: [WorkFlowPopupMicpComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    DigitalMicpSimulationModule,
    MatLegacyProgressSpinnerModule,
  ],
})
export class WorkFlowPopupMicpModule {}
