<div *ngIf="formGroup" [formGroup]="formGroup" class="filter-fields-line">
  <div [formGroupName]="locationFormField.LOCATION_TYPE_ID" name="locationTypesGroup">
    <app-multi-select
      [formControl]="locationTypeIdFormControl"
      [label]="locationFormFieldNameToDisplay[locationFormField.LOCATION_TYPE_ID]"
      [selectOptions]="locationTypes"
      [multiSelect]="true"
      [returnIdValue]="false"
      id="location-types-select"
      name="locationTypesControl"
      ngDefaultControl
    >
    </app-multi-select>
  </div>

  <div
    [formGroupName]="locationFormField.LOCATION_RESTRICTION_IDS"
    name="locationRestrictionsGroup"
  >
    <app-multi-select
      [formControl]="locationRestrictionIdFormControl"
      [label]="locationFormFieldNameToDisplay[locationFormField.LOCATION_RESTRICTION_IDS]"
      [selectOptions]="locationRestrictions"
      [multiSelect]="true"
      [returnIdValue]="false"
      (selectionChange)="onOptionSelection()"
      id="location-restriction-select"
      name="locationRestrictionsControl"
      ngDefaultControl
    >
    </app-multi-select>
  </div>
</div>
