import { forkJoin } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgModule,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

import { IdName } from '@core/models/id-name.model';
import { UserTeam } from '@core/models/user-team.model';
import { CheckboxListFieldModule } from 'src/app/common/checkbox-list-field/checkbox-list-field.component';
import { SampleTypeService } from 'src/app/services/api/sample-type.service';

import { MultiSelectModule } from '../../../../common/multi-select/multi-select.component';
import { Destroyable } from '../../../../core/utils/mixins/destroyable.mixin';
import { UserService } from '../../../../services/api/user.service';
import { SampleTypesChartFilters } from './models/sample-types-chart-filters.model';

@Component({
  selector: 'app-sample-types-chart-filter',
  templateUrl: './sample-types-chart-filter.component.html',
  styleUrls: ['./sample-types-chart-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleTypesChartFilterComponent extends Destroyable(Object) implements OnInit {
  dataObject!: SampleTypesChartFilters;
  sampleTypeFormGroup!: UntypedFormGroup;
  availabilityFormGroup!: UntypedFormGroup;
  displayFormGroup!: UntypedFormGroup;
  teamsFormGroup!: UntypedFormGroup;
  durabilityFormGroup!: UntypedFormGroup;
  displayOptions!: IdName[];
  teamsOptions!: IdName[];
  sampleTypesOptions!: IdName[];
  pickedDisplay = 'week';

  private readonly sampleAvailabilityLabels: Record<string, string> = {
    checkedIn: 'Checked-in',
    checkedOut: 'Checked-out',
  };

  private readonly displayLabels: Record<string, string> = {
    day: 'Day',
    week: 'Week',
    month: 'Month',
  };

  get sampleAvailabilityOptions(): IdName[] {
    return Object.keys(this.dataObject.sampleAvailability).map(
      (id) => ({ id, name: this.sampleAvailabilityLabels[id] } as IdName),
    );
  }

  constructor(
    public dialogRef: MatDialogRef<SampleTypesChartFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SampleTypesChartFilters,
    private cd: ChangeDetectorRef,
    private userService: UserService,
    private sampleTypeService: SampleTypeService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initData();
  }

  initOptions(): void {
    this.displayOptions = Object.keys(this.dataObject.display).map(
      (id) => ({ id, name: this.displayLabels[id] } as IdName),
    );

    this.pickedDisplay =
      (Object.keys(this.dataObject.display) as (keyof typeof this.dataObject.display)[]).find(
        (key) => this.dataObject.display[key],
      ) ?? '';

    forkJoin([this.userService.getUserTeams(), this.sampleTypeService.getAll()])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([teams, sampleTypes]) => {
        this.teamsOptions = teams.map((idTeam: UserTeam) => {
          return { id: idTeam.id, name: idTeam.team } as IdName;
        });
        this.sampleTypesOptions = sampleTypes.filter(
          (sampleType) => sampleType.name !== 'composite',
        );
        this.cd.markForCheck();
      });
  }

  apply(): void {
    this.dataObject.sampleTypesIds = this.sampleTypeFormGroup.value['sampleType'];

    this.dataObject.teamsIds = this.teamsFormGroup.value['teams'];

    Object.assign(this.dataObject.sampleAvailability, this.availabilityFormGroup.value);

    Object.keys(this.dataObject.display).forEach((key) => {
      (this.dataObject.display as Record<string, boolean>)[key] = false;
    });

    (this.dataObject.display as Record<string, boolean>)[
      this.displayFormGroup.get('display')?.value
    ] = true;

    Object.assign(this.dataObject.duration, this.durabilityFormGroup.value);
    this.dialogRef.close(this.dataObject);
  }

  private initData(): void {
    this.dataObject = JSON.parse(JSON.stringify(this.data));
    this.initOptions();
    this.initTeamsGroup();
    this.initSampleTypeFormGroup();
    this.initAvailabilityGroup();
    this.initDisplayGroup();
    this.initDurabilityGroup();
  }

  private initSampleTypeFormGroup(): void {
    this.sampleTypeFormGroup = new UntypedFormGroup({
      sampleType: new UntypedFormControl(
        this.dataObject.sampleTypesIds ? [...this.dataObject.sampleTypesIds] : [],
      ),
    });
  }

  private initTeamsGroup(): void {
    this.teamsFormGroup = new UntypedFormGroup({
      teams: new UntypedFormControl(this.dataObject.teamsIds ? [...this.dataObject.teamsIds] : []),
    });
  }

  private initAvailabilityGroup(): void {
    const sampleTypeAvailabilityKeyValuePairs = Object.entries(this.dataObject.sampleAvailability);
    const controls = Object.fromEntries(
      sampleTypeAvailabilityKeyValuePairs.map(([key, val]) => [key, new UntypedFormControl(val)]),
    );

    this.availabilityFormGroup = new UntypedFormGroup(controls);
  }

  private initDisplayGroup(): void {
    this.displayFormGroup = new UntypedFormGroup({
      display: new UntypedFormControl(this.pickedDisplay),
    });
  }

  private initDurabilityGroup(): void {
    const durabilityKeyValuePairs = Object.entries(this.dataObject.duration);
    const controls = Object.fromEntries(
      durabilityKeyValuePairs.map(([key, val]) => [key, new UntypedFormControl(val)]),
    );

    this.durabilityFormGroup = new UntypedFormGroup(controls);
  }
}

@NgModule({
  declarations: [SampleTypesChartFilterComponent],
  imports: [
    MatDialogModule,
    MatCheckboxModule,
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MultiSelectModule,
    CheckboxListFieldModule,
  ],
  exports: [SampleTypesChartFilterComponent],
})
export class SampleTypesChartFilterModule {}
