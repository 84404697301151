import { Observable, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataUrlBlobCodecService {
  dataUrlToFile(dataUrl: string): File {
    const extensionIndexEnd = dataUrl.indexOf(';');
    const extensionPrefix = 'data:image/';
    const extension = dataUrl.slice(extensionPrefix.length, extensionIndexEnd);
    const fileName = `sample-image.${extension}`;

    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n) {
      n -= 1;
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  }

  blobToDataURL(blob: Blob): Observable<string> {
    const subject = new Subject<string>();
    const fileReader = new FileReader();
    fileReader.addEventListener('load', (_) => subject.next(fileReader.result as string));
    fileReader.readAsDataURL(blob);

    return subject.asObservable();
  }
}
