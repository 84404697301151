<div class="grid">
  <div class="cell">
    <h1 class="title">
      Notifications
      <button
        class="btn btn-square btn-transparent"
        (click)="onNotificationsRequest()"
        id="dashboard-refresh-btn"
      >
        <fa-icon [icon]="faArrowsRotate"> </fa-icon>
      </button>
    </h1>
    <div class="content">
      <ng-container
        *ngIf="notifications.length || !isEachNotificationLoaded; else noNotificationsMessage"
      >
        <app-dashboard-notification
          *ngFor="let notification of notifications"
          [notificationType]="notification.type"
          [notification]="notification.message"
        ></app-dashboard-notification>
      </ng-container>

      <ng-template #noNotificationsMessage>
        <div class="no-notifications-container">
          <h2>There are no notifications</h2>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="cell">
    <app-dashboard-widget [isKpi]="true"></app-dashboard-widget>
  </div>
  <div class="cell">
    <app-dashboard-widget [isKpi]="false"></app-dashboard-widget>
  </div>
  <div class="cell">
    <app-sample-types></app-sample-types>
  </div>
  <div class="cell col-span-2">
    <h1 class="title">Simulations</h1>
  </div>
</div>
