<h1 class="title">Data Uploader</h1>

<div class="half-width input-file">
  <mat-form-field appearance="outline" class="input-file__field">
    <mat-label>Input file</mat-label>
    <input matInput disabled [(ngModel)]="fileName" />
    <mat-spinner
      class="loading-icon"
      *ngIf="fileInputState === 'loading'"
      matSuffix
      [diameter]="18"
      [matTooltip]="'uploading'"
    ></mat-spinner>
    <mat-icon
      class="success-icon"
      *ngIf="fileInputState === 'success'"
      matSuffix
      [matTooltip]="'upload success'"
      >done</mat-icon
    >
    <mat-icon
      class="error-icon"
      *ngIf="fileInputState === 'error'"
      matSuffix
      [matTooltip]="'upload failed'"
      >clear</mat-icon
    >
  </mat-form-field>

  <div class="upload-btn-container input-file__btn">
    <button
      class="btn btn-primary upload-btn"
      type="button"
      appUpload
      [ext]="'.xlsx'"
      [extErrorMessage]="'Application accepts only XLSX file format'"
      (filePicked)="uploadFile($event)"
      #uploadDirective="uploadDirective"
    >
      <span class="upload-btn__text">Upload file</span
      ><mat-icon class="upload-btn__icon" fontSet="fas" fontIcon="fa-cloud-arrow-up"></mat-icon>
    </button>
  </div>
</div>

<div class="error-msg mat-error" *ngIf="this.errorMsg">{{ this.errorMsg }}</div>

<ng-container *ngIf="tableData.data.length">
  <div>
    <h2 class="report-title">Report</h2>
  </div>

  <div class="table-wrapper">
    <table class="table custom-table" mat-table [dataSource]="tableData">
      <ng-container matColumnDef="sampleName">
        <th mat-header-cell *matHeaderCellDef>Sample</th>
        <td mat-cell *matCellDef="let row">
          {{ row.sampleName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let row">
          {{ row.type }}
        </td>
      </ng-container>

      <ng-container matColumnDef="depth">
        <th mat-header-cell *matHeaderCellDef>Depth</th>
        <td mat-cell *matCellDef="let row">
          {{ row.depth }}
        </td>
      </ng-container>

      <ng-container matColumnDef="wellName">
        <th mat-header-cell *matHeaderCellDef>Well</th>
        <td mat-cell *matCellDef="let row">
          {{ row.wellName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td
          mat-cell
          *matCellDef="let row"
          [class.success]="row.status === reportStatus.SUCCESS"
          [class.failed]="row.status === reportStatus.FAILED"
        >
          {{ row.status }}
          <mat-icon
            *ngIf="row.status === reportStatus.FAILED"
            class="errors-info"
            matTooltipClass="description-text"
            matTooltip="{{ prefixMessage }} {{ row.errorFields | stringArrayToString }}"
            matTooltipClass="data-uploader-error-tooltip"
            >info</mat-icon
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="messages">
        <th mat-header-cell *matHeaderCellDef>Info</th>
        <td mat-cell *matCellDef="let row">
          {{ row.experimentTypeName + (row.messages.length ? ':' : '') }}
          <ng-container *ngFor="let infoItem of row.messages; let i = index"
            ><ng-container *ngIf="!i"><br /></ng-container> {{ infoItem }} <br />
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-card>
      <mat-paginator
        class="mat-paginator"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        [pageSize]="10"
        aria-label="Select page"
      ></mat-paginator>
    </mat-card>
  </div>

  <div class="action-btns">
    <button
      id="data-uploader-print-report-btn"
      class="btn btn-secondary btn-big print-report-btn"
      (click)="print()"
    >
      <mat-icon>print</mat-icon>
      <span>Print report</span>
    </button>

    <button
      id="data-uploader-clear-btn"
      class="btn btn-secondary btn-big"
      (click)="clear(uploadDirective)"
    >
      Clear
    </button>
    <button
      id="data-uploader-apply-btn"
      class="btn btn-primary btn-big"
      [disabled]="isApplyButtonDisabled"
      (click)="onApplyClicked()"
    >
      Apply
    </button>
  </div>
</ng-container>
