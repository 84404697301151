import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';
import { MatCommonModule } from '@angular/material/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';

import { ConfirmPopupData } from './models/confirm-popup-data.model';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationPopupComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmPopupData) {}
}
@NgModule({
  declarations: [ConfirmationPopupComponent],
  imports: [CommonModule, MatDialogModule, MatCommonModule],
  exports: [ConfirmationPopupComponent],
})
export class ConfirmationPopupModule {}
