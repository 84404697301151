<div *ngFor="let formControl of formControls; let i = index" class="field-wrapper">
  <app-sample-table-selector-popup-field
    [disabled]="isSampleDetails || isReadOnly"
    class="field"
    [formControl]="formControl"
    [label]="'Sample ' + (i + 1)"
    [allowedSampleTypes]="plugSampleTypeOnly"
    (changesSelectedSample)="checkDuplicateSample($event, i)"
  ></app-sample-table-selector-popup-field>

  <button
    *ngIf="i && !isSampleDetails && !isReadOnly"
    class="btn delete"
    matTooltip="Remove sample"
    (click)="removeControl(i)"
  >
    <fa-icon [icon]="faTrashCan"></fa-icon>
  </button>
</div>
<div *ngIf="!isSampleDetails && !isReadOnly">
  <button mat-button class="btn add" (click)="addControl('')">
    <mat-icon>add</mat-icon>
    <span>Add sample</span>
  </button>
</div>
