import { DatePipe } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { WorkOrderDisplayName } from '../models/work-order-display-name.model';

@Pipe({
  name: 'workOrderDisplayName',
})
export class WorkOrderDisplayNamePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(input: WorkOrderDisplayName | null): string {
    if (!input) {
      return '';
    }

    const { name, experimentTypeName, startDate } = input;
    const formattedDate = startDate ? this.datePipe.transform(startDate, 'M/d/yyyy') : startDate;
    return [name, experimentTypeName, formattedDate].filter((el) => !!el).join('-');
  }
}

@NgModule({
  declarations: [WorkOrderDisplayNamePipe],
  exports: [WorkOrderDisplayNamePipe],
})
export class WorkOrderDisplayNameModule {}
