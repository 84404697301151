export enum PcriGeneralInformationFormField {
  ID = 'id',
  PROJECT_NUMBER = 'name',
  PROPONENT_EMAIL = 'proponentEmail',
  PROPONENT_NAME = 'proponentName',
  DESCRIPTION = 'description',
  START_DATE = 'startDate',
  EXPECTED_DELIVERY_DATE = 'expectDate',
}

export const PCRI_GENERAL_INFORMATION_LABEL: Record<PcriGeneralInformationFormField, string> = {
  [PcriGeneralInformationFormField.PROJECT_NUMBER]: 'Project number',
  [PcriGeneralInformationFormField.ID]: 'Work order ID',
  [PcriGeneralInformationFormField.DESCRIPTION]: 'Description',
  [PcriGeneralInformationFormField.PROPONENT_EMAIL]: 'Proponent Email',
  [PcriGeneralInformationFormField.PROPONENT_NAME]: 'Proponent name',
  [PcriGeneralInformationFormField.START_DATE]: 'Start date',
  [PcriGeneralInformationFormField.EXPECTED_DELIVERY_DATE]: 'Expected delivery date',
};
