import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FILE_SERVICE_API, PASS_ERROR_HEADER } from '@core/constants/api.consts';

@Injectable({
  providedIn: 'root',
})
export class FileProcessorService {
  private url = `${FILE_SERVICE_API}/FileProcessor`;

  constructor(private httpClient: HttpClient) {}

  sliceData({
    file,
    volumeTypeName,
    dataFormatName,
    gridNumberX,
    gridNumberY,
    gridNumberZ,
  }: {
    file: File;
    volumeTypeName: string;
    dataFormatName: string;
    gridNumberX: string;
    gridNumberY: string;
    gridNumberZ: string;
  }): Observable<{ rawFileId: string; processedFileId: string }> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileType', volumeTypeName);
    formData.append('dataType', dataFormatName);
    formData.append('nx', gridNumberX);
    formData.append('ny', gridNumberY);
    formData.append('nz', gridNumberZ);

    return this.httpClient.post<{ rawFileId: string; processedFileId: string }>(
      `${this.url}/slice-data`,
      formData,
      { headers: { [PASS_ERROR_HEADER]: '' } },
    );
  }

  getSliceBy(fileId: string, plane: 'xy' | 'xz' | 'yz', index: number): Observable<Blob> {
    return this.httpClient.get(`${this.url}/slice`, {
      params: new HttpParams({ fromObject: { fileId, plane, index } }),
      responseType: 'blob',
    });
  }

  imagePreview(file: File): Observable<Blob> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<Blob>(`${this.url}/image-preview`, formData, {
      responseType: 'blob' as 'json',
    });
  }
}
