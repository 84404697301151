import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { UploadModule } from 'src/app/common/directives/upload.directive';

@Component({
  selector: 'app-sample-header',
  templateUrl: './sample-header.component.html',
  styleUrls: ['./sample-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleHeaderComponent {
  @Input() title!: string;
  @Input() showBackButton = false;
  @Output() backClicked = new EventEmitter();
  @Output() filePicked = new EventEmitter();

  onFilePicked(e: any): void {
    if (e?.target?.files?.length) {
      this.filePicked.emit(e.target.files[0]);
    }
  }
}
@NgModule({
  declarations: [SampleHeaderComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule, UploadModule],
  exports: [SampleHeaderComponent],
})
export class SampleHeaderModule {}
