import { UploadFileValidator } from '../../../common/directives/models/upload-file-validator.model';

export const SAMPLE_IMAGE_VALIDATORS: UploadFileValidator[] = [
  {
    validate: (file: File): boolean => {
      const oneGigaByteInBytes = 1024 * 1024 * 1024;
      return file.size <= oneGigaByteInBytes;
    },
    errorMessage: 'Sample image larger than 1GB is not allowed',
  },
];
