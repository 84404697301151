import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';

import { addError } from '@core/utils/form/add-error.util';
import { removeError } from '@core/utils/form/remove-error.util';
import { LocationFormField } from 'src/app/features/location/form/location-form-field.enum';

export const barcodeOrQRCodeRequired = 'barcodeOrQRCodeRequired';

export function qrCodeOrBarcodeIsRequired(formGroup: AbstractControl): ValidationErrors | null {
  const barcodeIdFormControl = formGroup.get(LocationFormField.BARCODE_ID) as UntypedFormControl;
  const qrCodeIdFormControl = formGroup.get(LocationFormField.QR_CODE_ID) as UntypedFormControl;

  const isBarcodeIdEmpty = barcodeIdFormControl.value === '';
  const isQRCodeIdEmpty = qrCodeIdFormControl.value === '';

  const barcodeIdFormControlErrors = barcodeIdFormControl.errors;
  const qrCodeIdFormControlErrors = qrCodeIdFormControl.errors;

  const isInvalid = isBarcodeIdEmpty && isQRCodeIdEmpty;
  const isErrorApplied =
    barcodeIdFormControlErrors?.[barcodeOrQRCodeRequired] ||
    qrCodeIdFormControlErrors?.[barcodeOrQRCodeRequired];

  if (isInvalid && !isErrorApplied) {
    addError(barcodeIdFormControl, barcodeOrQRCodeRequired);
    addError(qrCodeIdFormControl, barcodeOrQRCodeRequired);

    return null;
  }

  if (!isInvalid && isErrorApplied) {
    removeError(barcodeIdFormControl, barcodeOrQRCodeRequired);
    removeError(qrCodeIdFormControl, barcodeOrQRCodeRequired);
  }

  return null;
}
