import { forkJoin, map, Observer, switchMap, tap } from 'rxjs';

import { CommonModule, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';

import { EMAIL_REGEX } from '@core/constants/consts';
import { IdName } from '@core/models/id-name.model';
import { ValidationState } from '@core/models/validation-state.model';
import { throttleClick } from '@core/utils/decorators/throttle-click.decorator';
import { REQUIRED_FIELDS_ERROR_CODE, requiredFields } from '@core/utils/form/validator.config';
import { ExperimentDataService } from 'src/app/services/api/experiment-data.service';
import { LocationTreeService } from 'src/app/services/api/location-tree.service';
import { SampleService } from 'src/app/services/api/sample.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserManagementService } from 'src/app/services/user-management.service';

import { ThrottleClickDirective } from '../../../common/directives/throttle-click.directive';
import { UnitInputField } from '../../../common/unit-input/enums/unit-input-field.enum';
import { AttachedFileService } from '../../../services/api/attached-file.service';
import { DataUrlBlobCodecService } from '../../../services/data-url-blob-codec.service';
import { ExistingWorkOrdersModule } from '../../work-order/existing-work-orders/existing-work-orders.component';
import {
  NewExperimentType,
  newExperimentTypeFields,
} from '../../work-order/mocks/experiment-type-temporary.mock';
import { ExperimentField } from '../../work-order/models/experiment-field.model';
import { WorkOrderImpl } from '../../work-order/models/work-order.model';
import {
  WorkOrderFormShape,
  WorkOrderFormShapeImpl,
} from '../../work-order/models/work-order-form-shape.model';
import { WorkOrderDisplayNamePipe } from '../../work-order/pipes/work-order-display-name.pipe';
import { ExperimentTypeService } from '../../work-order/services/experiment-type.service';
import { VendorNameService } from '../../work-order/services/vendor-name.service';
import { WorkOrderService } from '../../work-order/services/work-order.service';
import { WorkOrderFormComponent } from '../../work-order/work-order-form/work-order-form.component';
import { AttachedFilesModule } from '../attached-files-block/attached-files-block.component';
import { BaseSample } from '../base-sample.class';
import { ExperimentDataRenderTableModule } from '../experiment-data-render-table/experiment-data-render-table.component';
import { SampleCheckInFormField } from '../form/sample-check-in-form-field.enum';
import { SAMPLE_CHECK_IN_FORM_LABEL } from '../form/sample-check-in-form-labels';
import { SampleFormGroup } from '../form/sample-form-group.enum';
import { unique, uniqueErrorName } from '../form/unique-sample.validator';
import { CompositeSample } from '../models/composite-sample.model';
import { ExperimentData } from '../models/experiment-data.model';
import { SampleAccessInfo } from '../models/sample-access-info.model';
import { SampleGetById } from '../models/sample-get-by-id.model';
import { SampleAccessTableModule } from '../sample-access-table/sample-access-table.component';
import { SampleActionsModule } from '../sample-actions/sample-actions.component';
import { SampleCompositeFormModule } from '../sample-composite-form/sample-composite-form.component';
import {
  SampleGeneralFormComponent,
  SampleGeneralFormModule,
} from '../sample-general-form/sample-general-form.component';
import { SampleHeaderModule } from '../sample-header/sample-header.component';

@Component({
  selector: 'app-composite-sample',
  templateUrl: './composite-sample.component.html',
  styleUrls: ['./composite-sample.component.scss'],
  providers: [WorkOrderDisplayNamePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompositeSampleComponent extends BaseSample implements OnInit {
  @Input() embedded = false;
  @Input() isReadOnly = false;
  @Input() sample!: CompositeSample;
  @Output() compositeSampleCreated = new EventEmitter<string>();

  sampleId!: string | undefined;
  formArray!: UntypedFormControl[];
  sampleAccess = new Array<SampleAccessInfo>();
  isOwner!: boolean;
  vendorNames: IdName[] = [];
  experimentTypes: IdName[] = [];
  existingWorkOrders: WorkOrderFormShape[] = [];
  experimentDataList: ExperimentData[] = [];

  private currentUser: string | undefined;

  @ViewChild(SampleGeneralFormComponent)
  private sampleGeneralFormRef!: SampleGeneralFormComponent;

  get compositeFormArray(): UntypedFormArray {
    return this.sampleFormGroup.get(SampleFormGroup.COMPOSITE) as UntypedFormArray;
  }

  constructor(
    protected override location: Location,
    private sampleService: SampleService,
    private attachedFileService: AttachedFileService,
    private locationTreeService: LocationTreeService,
    private notificationService: NotificationService,
    private userManagementService: UserManagementService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private workOrderService: WorkOrderService,
    private vendorNameService: VendorNameService,
    private experimentTypeService: ExperimentTypeService,
    private dataUrlBlobCodecService: DataUrlBlobCodecService,
    private experimentDataService: ExperimentDataService,
  ) {
    super(location);
  }

  ngOnInit(): void {
    if (this.sample) {
      this.sampleId = this.sample.id;
      this.prepareEditData();
    } else {
      this.handleRouteParams();
    }
  }

  submitForm(sampleActionsComponent: { savingFinished(): void }): void {
    if (this.sampleFormGroup.valid) {
      const composite = this.mapFormValue(this.sampleFormGroup.getRawValue());
      const sampleImage = this.picture.src
        ? this.dataUrlBlobCodecService.dataUrlToFile(this.picture.src)
        : null;

      if (this.sampleId) {
        this.editSampleHandler(composite, sampleImage, sampleActionsComponent);
      } else {
        this.createSampleHandler(composite, sampleImage, sampleActionsComponent);
      }
    } else {
      this.sampleFormGroup.markAllAsTouched();
      const validationErrorMessage = this.getFirstMessage();
      this.notificationService.notifyError(validationErrorMessage);
      sampleActionsComponent.savingFinished();
    }
  }

  private getAdditionalInfo(): void {
    if (!this.sampleId) {
      return;
    }

    this.experimentDataService
      .getAllBy(this.sampleId)
      .pipe(this.takeUntilDestroyed())
      .subscribe((experimentDataList) => {
        this.experimentDataList = experimentDataList;
        this.filterEmptyTable(experimentDataList);
        this.cd.markForCheck();
      });
  }

  private filterEmptyTable(experimentDataList: ExperimentData[]): void {
    this.experimentDataList = experimentDataList.filter((expData) => {
      return expData.tables[0].body.length;
    });
  }

  private editSampleHandler(
    composite: CompositeSample,
    sampleImage: File | null,
    sampleActionsComponent: { savingFinished(): void },
  ): void {
    const updateCompositeObserver: Partial<Observer<CompositeSample>> = {
      next: (_) => {
        this.notificationService.notifySuccess(
          `Composite sample "${composite.name}" has been successfully updated`,
        );
        sampleActionsComponent.savingFinished();
        this.navigateToSampleLookup();
      },
      error: () => {
        sampleActionsComponent.savingFinished();
        this.cd.markForCheck();
      },
    };

    if (sampleImage && this.sampleGeneralFormRef.isSamplePhotoChanged) {
      this.attachedFileService
        .uploadSampleImage(sampleImage)
        .pipe(
          switchMap(({ sampleImageId, sampleImageOriginId }) => {
            return this.sampleService.updateComposite(
              {
                ...composite,
                sampleImageId,
                sampleImageOriginId,
              },
              this.sampleId!,
            );
          }),
          this.takeUntilDestroyed(),
        )
        .subscribe(updateCompositeObserver);

      return;
    }

    this.sampleService
      .updateComposite(composite, this.sampleId!)
      .pipe(this.takeUntilDestroyed())
      .subscribe(updateCompositeObserver);
  }

  private createSampleHandler(
    composite: CompositeSample,
    sampleImage: File | null,
    sampleActionsComponent: { savingFinished(): void },
  ): void {
    const createCompositeObserver: Partial<Observer<CompositeSample>> = {
      next: (response) => {
        this.notificationService.notifySuccess(
          `Composite sample "${composite.name}" has been successfully created`,
        );
        this.compositeSampleCreated.emit(response.id);
        sampleActionsComponent.savingFinished();
      },
      error: () => {
        sampleActionsComponent.savingFinished();
        this.cd.markForCheck();
      },
    };

    if (sampleImage) {
      this.attachedFileService
        .uploadSampleImage(sampleImage)
        .pipe(
          switchMap(({ sampleImageId, sampleImageOriginId }) => {
            return this.sampleService.createComposite({
              ...composite,
              sampleImageId,
              sampleImageOriginId,
            });
          }),
          this.takeUntilDestroyed(),
        )
        .subscribe(createCompositeObserver);

      return;
    }

    this.sampleService
      .createComposite(composite)
      .pipe(this.takeUntilDestroyed())
      .subscribe(createCompositeObserver);
  }

  protected override initComponentData(): void {
    super.initComponentData();

    this.locationTreeService
      .getLocationNodes()
      .pipe(this.takeUntilDestroyed())
      .subscribe((sampleLocations) => {
        this.sampleLocations = sampleLocations;

        if (this.isEdit || this.isReadOnly) {
          this.initFormsWithModel();
        } else {
          this.initForms();
        }

        this.setupExistingWorkOrdersBlock();
        this.getAdditionalInfo();
      });
  }

  protected override clearRouteParams(): void {}

  private handleRouteParams(): void {
    this.isEdit = this.route.routeConfig?.path?.startsWith('edit') ?? false;

    this.route.paramMap.pipe(this.takeUntilDestroyed()).subscribe((params) => {
      this.resetForm();

      this.sampleId = params.get('sampleId') || undefined;

      if (this.sampleId) {
        this.prepareEditData();
      } else {
        this.initComponentData();
      }
    });
  }

  private prepareEditData(): void {
    this.sampleService
      .getById(this.sampleId as string)
      .pipe(this.takeUntilDestroyed())
      .subscribe((sample) => {
        this.sample = this.mapSampleToCompositeSample(sample);
        this.sampleImageId = sample.sampleImageId;
        this.sampleImageOriginId = sample.sampleImageOriginId;
        this.sampleAccess = sample.sampleAccesses;
        this.currentUser = this.userManagementService.getUserInfo()?.email;
        this.isOwner = sample.owner === this.currentUser;
        this.formArray = this.sample.componentSamplesIds.map((componentId) => {
          return new UntypedFormControl(
            { value: componentId, disabled: this.isReadOnly },
            Validators.required,
          );
        });
        this.initComponentData();
      });
  }

  private mapSampleToCompositeSample(sample: SampleGetById): CompositeSample {
    return {
      barcodeId: sample.barcodeId,
      description: sample.description,
      id: sample.id,
      locationId: sample.locationId,
      custodian: sample.custodian,
      name: sample.name,
      owner: sample.owner,
      qrCodeId: sample.qrCodeId,
      sampleImageId: sample.sampleImageId,
      sampleImageOriginId: sample.sampleImageOriginId,
      componentSamplesIds: sample.relatedSamples.map((item) => item.id),
    };
  }

  private initForms(): void {
    this.sampleFormGroup = new UntypedFormGroup(
      {
        [SampleFormGroup.GENERAL]: new UntypedFormGroup(
          {
            [SampleCheckInFormField.NAME]: new UntypedFormControl('', [
              Validators.required,
              Validators.maxLength(128),
            ]),
            [SampleCheckInFormField.BARCODE]: new UntypedFormControl('', [
              Validators.maxLength(128),
              Validators.required,
            ]),
            [SampleCheckInFormField.QRCODE]: new UntypedFormControl('', [
              Validators.maxLength(128),
              Validators.required,
            ]),
            [SampleCheckInFormField.DESCRIPTION]: new UntypedFormControl('', [
              Validators.maxLength(32),
            ]),
            [SampleCheckInFormField.SAMPLE_LOCATION]: new UntypedFormControl(''),
            [SampleCheckInFormField.OWNER]: new UntypedFormControl('', [Validators.required]),
            [SampleCheckInFormField.CUSTODIAN]: new UntypedFormControl(''),
          },
          requiredFields(
            [SampleCheckInFormField.BARCODE, SampleCheckInFormField.QRCODE],
            SAMPLE_CHECK_IN_FORM_LABEL,
          ),
        ),
        [SampleFormGroup.COMPOSITE]: new UntypedFormArray([], [Validators.minLength(2)]),
      },
      { validators: unique },
    );

    this.cd.detectChanges();
  }

  private initFormsWithModel(): void {
    if (this.sample.sampleImageId) {
      this.loadSampleImage(this.sample);
    }

    this.sampleFormGroup = new UntypedFormGroup(
      {
        [SampleFormGroup.GENERAL]: new UntypedFormGroup(
          {
            [SampleCheckInFormField.NAME]: new UntypedFormControl(
              { value: this.sample[SampleCheckInFormField.NAME], disabled: this.isReadOnly },
              [Validators.required, Validators.maxLength(128)],
            ),
            [SampleCheckInFormField.BARCODE]: new UntypedFormControl(
              { value: this.sample.barcodeId, disabled: this.isReadOnly },
              [Validators.maxLength(128), Validators.required],
            ),
            [SampleCheckInFormField.QRCODE]: new UntypedFormControl(
              { value: this.sample.qrCodeId, disabled: this.isReadOnly },
              [Validators.maxLength(128), Validators.required],
            ),
            [SampleCheckInFormField.DESCRIPTION]: new UntypedFormControl(
              { value: this.sample[SampleCheckInFormField.DESCRIPTION], disabled: this.isReadOnly },

              [Validators.maxLength(32)],
            ),
            [SampleCheckInFormField.SAMPLE_LOCATION]: new UntypedFormControl(
              { value: this.sample.locationId, disabled: this.isReadOnly },
              [Validators.required],
            ),
            [SampleCheckInFormField.OWNER]: new UntypedFormControl(
              { value: this.sample.owner, disabled: this.isEdit || this.isReadOnly },
              [Validators.required, Validators.pattern(EMAIL_REGEX)],
            ),
            [SampleCheckInFormField.CUSTODIAN]: new UntypedFormControl(
              {
                value: this.sample.custodian,
                disabled: this.isReadOnly,
              },
              [Validators.pattern(EMAIL_REGEX)],
            ),
          },
          requiredFields(
            [SampleCheckInFormField.BARCODE, SampleCheckInFormField.QRCODE],
            SAMPLE_CHECK_IN_FORM_LABEL,
          ),
        ),
        [SampleFormGroup.COMPOSITE]: new UntypedFormArray(
          [...this.formArray],
          [Validators.minLength(2)],
        ),
      },
      { validators: unique },
    );
    this.cd.detectChanges();
  }

  private loadSampleImage(sample: CompositeSample): void {
    this.attachedFileService
      .downloadImage(sample.sampleImageId)
      .pipe(
        switchMap((blob: Blob) => this.dataUrlBlobCodecService.blobToDataURL(blob)),
        this.takeUntilDestroyed(),
      )
      .subscribe((sampleImageUrl) => {
        this.picture.src = sampleImageUrl;
        this.sampleGeneralFormRef.detectChanges();
      });
  }

  private getFirstMessage(): string {
    const controlWithError = Object.entries(this.generalFormGroup.controls).find(
      ([, control]) => !!control.errors,
    );
    let controlErrors = controlWithError?.[1].errors;

    if (controlErrors?.['required']) {
      return `${
        SAMPLE_CHECK_IN_FORM_LABEL[controlWithError?.[0] as SampleCheckInFormField]
      } should not be empty.`;
    }
    if (controlErrors?.['maxlength']) {
      return `The ${
        SAMPLE_CHECK_IN_FORM_LABEL[controlWithError?.[0] as SampleCheckInFormField]
      } input should contain less than ${
        controlErrors?.['maxlength'].requiredLength
      } characters or equal`;
    }
    if (controlErrors?.['pattern']) {
      return `${
        SAMPLE_CHECK_IN_FORM_LABEL[controlWithError?.[0] as SampleCheckInFormField]
      } should be in the correct email format`;
    }
    if (this.generalFormGroup.hasError(REQUIRED_FIELDS_ERROR_CODE)) {
      return this.generalFormGroup.getError(REQUIRED_FIELDS_ERROR_CODE);
    }

    const controlIndex = this.compositeFormArray.controls.findIndex((control) => !!control.errors);
    if (controlIndex >= 0) {
      controlErrors = this.compositeFormArray.controls[controlIndex].errors;

      if (controlErrors?.['required']) {
        return `Sample ${controlIndex + 1} should not be empty.`;
      }
    }

    const formArrayError = this.compositeFormArray.errors;
    if (formArrayError?.['minlength']) {
      return `You need to provide at least ${formArrayError?.['minlength']?.['requiredLength']} samples`;
    }
    const formError = this.sampleFormGroup.errors;
    if (formError?.[uniqueErrorName]) {
      return 'You need to provide unique samples';
    }
    return 'Unhandled validation error';
  }

  private mapFormValue(value: Record<string, any>): CompositeSample {
    const generalFormValue = value[SampleFormGroup.GENERAL];
    return {
      name: generalFormValue[SampleCheckInFormField.NAME],
      qrCodeId: generalFormValue[SampleCheckInFormField.QRCODE],
      barcodeId: generalFormValue[SampleCheckInFormField.BARCODE],
      locationId: generalFormValue[SampleCheckInFormField.SAMPLE_LOCATION],
      description: generalFormValue[SampleCheckInFormField.DESCRIPTION],
      custodian: generalFormValue[SampleCheckInFormField.CUSTODIAN],
      componentSamplesIds: [...(value[SampleFormGroup.COMPOSITE] || [])],
      owner: generalFormValue[SampleCheckInFormField.OWNER],
      sampleImageId: this.sampleImageId,
      sampleImageOriginId: this.sampleImageOriginId,
    };
  }

  private setupExistingWorkOrdersBlock(): void {
    if (!this.sampleId) {
      return;
    }

    forkJoin([this.vendorNameService.getAll(), this.experimentTypeService.getAll()])
      .pipe(
        tap((options) => {
          [this.vendorNames, this.experimentTypes] = options;
        }),
        switchMap(() => this.workOrderService.getAllBy(this.sampleId!)),
        map((workOrders) => workOrders.map((workOrder) => new WorkOrderFormShapeImpl(workOrder))),
        map((workOrders) =>
          workOrders.map((workOrder) => {
            const experimentTypeId = workOrder.experimentDetail.experimentType;

            if (!experimentTypeId) {
              return workOrder;
            }

            const experimentType = this.experimentTypes.find(
              (expType) => expType.id === experimentTypeId,
            )!;

            workOrder.experimentDetail['experimentName'] = experimentType.name;

            const isNewExperimentType = Object.values(NewExperimentType).includes(
              experimentType.name as NewExperimentType,
            );

            if (isNewExperimentType) {
              return {
                ...workOrder,
                experimentDetail: {
                  ...workOrder.experimentDetail,
                  experimentFields: [...newExperimentTypeFields],
                },
              };
            }

            return workOrder;
          }),
        ),
        this.takeUntilDestroyed(),
      )
      .subscribe((existingWorkOrders) => {
        this.existingWorkOrders = existingWorkOrders;
        this.cd.markForCheck();
      });
  }

  @throttleClick()
  save(throttleClickDirective: ThrottleClickDirective, component: WorkOrderFormComponent): void {
    const validationState = this.checkIfFormIsValid(component);

    if (!validationState.isValid) {
      component.workOrderFormGroup.markAllAsTouched();
      component.componentsWithInnerFormControls.forEach((cmp) => cmp.detectChanges());
      this.notificationService.notifyError(validationState.errorMessage);
      throttleClickDirective.isNextClickAllowed$.next(true);
      return;
    }

    const experimentFields = this.getExperimentFields(component);
    const payload = new WorkOrderImpl({
      ...component.workOrderFormGroup.getRawValue(),
      sampleId: this.sampleId,
      experimentFields,
    });

    this.workOrderService
      .update(payload)
      .pipe(throttleClickDirective.allowNextClickAfterFinalized(), this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess('Work order updated');
        this.cd.markForCheck();
        component.detectChanges();
      });
  }

  private checkIfFormIsValid(component: WorkOrderFormComponent): ValidationState {
    if (component.workOrderFormGroup.status === 'VALID') {
      return { isValid: true };
    }

    const validationMessage = component.dynamicValidationMessageList.find((valMessage) =>
      valMessage.hasError(),
    )!;

    return {
      isValid: false,
      errorMessage: validationMessage.getFirstMessage(),
    };
  }

  private getExperimentFields(component: WorkOrderFormComponent): ExperimentField[] {
    const { experimentFieldsMap, dynamicFieldFormGroup } =
      component.dynamicExperimentTemplateComponent;

    const experimentFields = Object.values(experimentFieldsMap).map((experimentField) => {
      const fieldValue = dynamicFieldFormGroup.value[experimentField.fieldName];

      if (fieldValue === null || typeof fieldValue === 'string' || typeof fieldValue === 'number') {
        return { ...experimentField, fieldValue };
      }

      return {
        ...experimentField,
        fieldValue: fieldValue[UnitInputField.FIELD_VALUE],
        unitValue: fieldValue[UnitInputField.UNIT_ID],
      };
    });

    return experimentFields;
  }
}

@NgModule({
  declarations: [CompositeSampleComponent],
  providers: [WorkOrderService, VendorNameService, ExperimentTypeService],
  exports: [CompositeSampleComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    ReactiveFormsModule,
    SampleActionsModule,
    SampleCompositeFormModule,
    SampleGeneralFormModule,
    SampleHeaderModule,
    SampleAccessTableModule,
    ExistingWorkOrdersModule,
    AttachedFilesModule,
    ExperimentDataRenderTableModule,
  ],
})
export class CompositeSampleModule {}
