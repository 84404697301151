import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { forkJoin, Observable } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnInit,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { IdName } from '@core/models/id-name.model';
import { LocationTreeViewNode } from '@core/models/location-tree-view-node.model';
import { FlatFormValidationMessage } from '@core/utils/form/flat-form-validation-message.class';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { ConfirmationPopupComponent } from 'src/app/common/confirmation-popup/confirmation-popup.component';
import {
  LocationTreeFilterComponent,
  LocationTreeFilterModule,
} from 'src/app/common/location-tree-filter/location-tree-filter.component';
import { SampleTableSelectorPopupFieldModule } from 'src/app/common/sample-table-selector-popup-field/sample-table-selector-popup-field.component';
import { TreeViewSelectorPopupFieldModule } from 'src/app/common/tree-view-selector-popup-field/tree-view-selector-popup-field.component';
import { LocationTreeService } from 'src/app/services/api/location-tree.service';
import { SampleService } from 'src/app/services/api/sample.service';
import { NotificationService } from 'src/app/services/notification.service';

import { LocationDisplayNamePipe } from '../../location/pipes/location-display-name.pipe';
import { CompositeSampleModule } from '../composite-sample/composite-sample.component';
import { SAMPLE_ERROR_MESSAGES } from '../consts/sample-error-messages.const';
import { SAMPLE_ERROR_TYPES } from '../consts/sample-error-types.const';
import { SAMPLE_OPTIONS_COMPARE_FN } from '../consts/sample-options-compare-fn.const';
import { SampleMovementStatus } from '../enums/sample-movement-status.enum';
import { SampleCheckOutFormField } from '../form/sample-check-out-form-field.enum';
import { SAMPLE_CHECK_OUT_ERROR_MESSAGE_CONFIG } from '../form/sample-check-out-form-field-error-message.config';
import { SAMPLE_CHECK_OUT_FORM_LABEL } from '../form/sample-check-out-form-labels';
import { SampleCheckOut } from '../models/sample-check-out.model';
import { SampleModule } from '../sample.component';
import { SampleHeaderModule } from '../sample-header/sample-header.component';

@Component({
  selector: 'app-sample-check-out',
  templateUrl: './sample-check-out.component.html',
  styleUrls: ['./sample-check-out.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [LocationDisplayNamePipe],
})
export class SampleCheckOutComponent extends Destroyable(Object) implements OnInit {
  readonly sampleCheckOutFormField = SampleCheckOutFormField;
  readonly sampleFormFieldLabel = SAMPLE_CHECK_OUT_FORM_LABEL;
  readonly locationTreeFilterComponent = LocationTreeFilterComponent;
  readonly locationTreeFilterModule = LocationTreeFilterModule;
  readonly optionsCompareFn = SAMPLE_OPTIONS_COMPARE_FN;
  readonly errorTypes = SAMPLE_ERROR_TYPES;
  readonly errorMessages = SAMPLE_ERROR_MESSAGES;

  private readonly otherValue = 'Other';

  mainFormGroup!: UntypedFormGroup;
  sampleCheckoutValidationMessage!: FlatFormValidationMessage<typeof SampleCheckOutFormField>;
  sampleLocations = new Array<LocationTreeViewNode>();
  purposes = new Array<IdName>();
  sampleId!: string;
  sample: any;

  get sampleIdFormControl(): UntypedFormControl {
    return this.mainFormGroup.get(SampleCheckOutFormField.SAMPLE_ID) as UntypedFormControl;
  }

  get purposeFormControl(): UntypedFormControl {
    return this.mainFormGroup.get(SampleCheckOutFormField.PURPOSE) as UntypedFormControl;
  }

  get moreFormControl(): UntypedFormControl {
    return this.mainFormGroup.get(SampleCheckOutFormField.MORE) as UntypedFormControl;
  }

  constructor(
    private sampleService: SampleService,
    private locationTreeService: LocationTreeService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    public locationDisplayNamePipe: LocationDisplayNamePipe,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.setUpValidationMessages();
    this.initComponentData();
  }

  onSamplePicked(sampleId: string): void {
    this.sampleId = '';

    if (sampleId) {
      this.sampleService
        .getById(sampleId)
        .pipe(this.takeUntilDestroyed())
        .subscribe((sample) => {
          this.sample = sample;
          this.sampleId = sampleId;
          this.cd.detectChanges();
        });
    }
  }

  resetForm(): void {
    this.mainFormGroup.reset();
    this.sample = null;
    this.sampleId = '';
    this.cd.detectChanges();
  }

  onPurposeChanged(change: string): void {
    if (change === this.otherValue) {
      this.mainFormGroup.controls[SampleCheckOutFormField.MORE].addValidators(Validators.required);
    } else {
      this.mainFormGroup.controls[SampleCheckOutFormField.MORE].removeValidators(
        Validators.required,
      );
    }

    this.mainFormGroup.controls[SampleCheckOutFormField.MORE].updateValueAndValidity();
  }

  validateAndCheckoutSample(): void {
    if (this.mainFormGroup.invalid) {
      this.mainFormGroup.markAllAsTouched();
      const validationErrorMessage = this.sampleCheckoutValidationMessage.getFirstMessage();
      this.notificationService.notifyError(validationErrorMessage);
      return;
    }

    const model = this.mainFormGroup.getRawValue();
    const checkoutModel: SampleCheckOut = {
      sampleId: model.sampleId,
      locationId: model.locationId,
      purpose: !model.purpose || model.purpose === this.otherValue ? model.more : model.purpose,
      note: model.note,
    };

    if (this.sample.movementStatus === SampleMovementStatus.CHECKOUT) {
      this.dialog
        .open(ConfirmationPopupComponent, {
          width: '400px',
          data: {
            action: `Sample is already checked out by ${this.sample.checkOutUser}. Do you want to proceed?`,
            buttonName: 'Proceed',
          },
        })
        .afterClosed()
        .subscribe((data) => {
          if (data) {
            this.checkOutSample(checkoutModel);
          }
        });
    } else {
      this.checkOutSample(checkoutModel);
    }
  }

  private checkOutSample(checkoutModel: SampleCheckOut): void {
    this.sampleService
      .checkOut(checkoutModel)
      .pipe(this.takeUntilDestroyed())
      .subscribe((_) => {
        this.notificationService.notifySuccess(`Sample has been successfully checked out`);
        this.resetForm();
      });
  }

  private setUpValidationMessages(): void {
    this.sampleCheckoutValidationMessage = new FlatFormValidationMessage(
      this.mainFormGroup,
      SAMPLE_CHECK_OUT_FORM_LABEL,
      SAMPLE_CHECK_OUT_ERROR_MESSAGE_CONFIG,
    );
  }

  private initForm(): void {
    this.mainFormGroup = new UntypedFormGroup({
      [SampleCheckOutFormField.SAMPLE_ID]: new UntypedFormControl(''),
      [SampleCheckOutFormField.LOCATION_ID]: new UntypedFormControl(''),
      [SampleCheckOutFormField.PURPOSE]: new UntypedFormControl('', [Validators.required]),
      [SampleCheckOutFormField.MORE]: new UntypedFormControl('', []),
      [SampleCheckOutFormField.NOTE]: new UntypedFormControl('', [Validators.max(256)]),
    });
  }

  private initComponentData(): void {
    this.prepareLists()
      .pipe(this.takeUntilDestroyed())
      .subscribe((data: [LocationTreeViewNode[], string[]]) => {
        [this.sampleLocations] = data;
        this.purposes = data[1].map((purpose) => ({ id: purpose, name: purpose }));

        this.purposes.push({ name: this.otherValue, id: this.otherValue });
        this.mainFormGroup.controls[SampleCheckOutFormField.PURPOSE].setValue('');
        this.cd.detectChanges();
      });
  }

  private prepareLists(): Observable<[LocationTreeViewNode[], string[]]> {
    return forkJoin([
      this.locationTreeService.getLocationNodes(),
      this.sampleService.getCheckoutPurposes(),
    ]);
  }
}

@NgModule({
  declarations: [SampleCheckOutComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    SampleTableSelectorPopupFieldModule,
    CompositeSampleModule,
    TreeViewSelectorPopupFieldModule,
    SampleHeaderModule,
    SampleModule,
  ],
  exports: [SampleCheckOutComponent],
})
export class SampleCheckOutModule {}
