import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { filter } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  MatLegacyPaginator as MatPaginator,
  MatLegacyPaginatorModule as MatPaginatorModule,
} from '@angular/material/legacy-paginator';
import {
  MatLegacyTableDataSource as MatTableDataSource,
  MatLegacyTableModule as MatTableModule,
} from '@angular/material/legacy-table';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';

import { USE_OKTA_AUTH_TOKEN } from '@core/tokens/use-okta-auth.token';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { AdminSettingsService } from 'src/app/services/api/admin-settings.service';
import { UserManagementService } from 'src/app/services/user-management.service';

import { UserService } from '../../services/api/user.service';
import { NotificationService } from '../../services/notification.service';
import {
  AdminSettingsNewPasswordPopupComponent,
  AdminSettingsNewPasswordPopupModule,
} from './admin-settings-new-password-popup/admin-settings-new-password-popup.component';
import { AdminSettingsNewUserPopupComponent } from './admin-settings-new-user-popup/admin-settings-new-user-popup.component';
import { AdminSettingsNewUser } from './models/admin-settings-new-user.model';
import { AdminSettingsUser } from './models/admin-settings-user.model';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSettingsComponent extends Destroyable(Object) implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.tableData.sortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
      const value = data[sortHeaderId];
      return typeof value === 'string' ? value.toLocaleLowerCase() : value;
    };

    this.tableData.sort = sort;
  }

  public readonly createNewUserButtonText = 'Create User';

  users!: Array<AdminSettingsUser>;
  tableData = new MatTableDataSource<AdminSettingsUser>();
  displayedColumns: Array<string> = this.useOktaAuth
    ? ['email', 'firstName', 'lastName', 'team', 'lastActive', 'position', 'admin', 'manager']
    : [
        'email',
        'firstName',
        'lastName',
        'team',
        'lastActive',
        'position',
        'admin',
        'manager',
        'actions',
      ];

  constructor(
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private adminSettingsService: AdminSettingsService,
    private userService: UserService,
    private notificationService: NotificationService,
    private userManagementService: UserManagementService,
    private router: Router,
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.userManagementService.isAdmin) {
      this.router.navigateByUrl('/');
    } else {
      this.initData();
    }
  }

  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.cd.detectChanges();
  }

  onChangePasswordClick(user: AdminSettingsUser): void {
    const dialogRef = this.dialog.open<AdminSettingsNewPasswordPopupComponent>(
      AdminSettingsNewPasswordPopupComponent,
      {
        width: '400px',
        data: user,
      },
    );

    dialogRef
      .afterClosed()
      .pipe(
        this.takeUntilDestroyed(),
        filter((x) => !!x),
      )
      .subscribe((password: string) => {
        this.changeUserPassword(user, password);
      });
  }

  onCreateNewUserClick(): void {
    const dialogRef = this.dialog.open<AdminSettingsNewUserPopupComponent>(
      AdminSettingsNewUserPopupComponent,
      {
        width: '400px',
      },
    );

    dialogRef
      .afterClosed()
      .pipe(
        this.takeUntilDestroyed(),
        filter((x) => !!x),
      )
      .subscribe((user: AdminSettingsNewUser) => {
        this.adminSettingsService
          .createNewUser(user)
          .pipe(this.takeUntilDestroyed())
          .subscribe(() => {
            this.notificationService.notifySuccess(`User ${user.email} is successfully created`);

            this.initData();
            this.cd.detectChanges();
          });
      });
  }

  private initData(): void {
    this.userService
      .getUsers()
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.users = data;
        this.tableData.data = this.users;
        this.cd.detectChanges();
      });
  }

  private changeUserPassword(user: AdminSettingsUser, password: string): void {
    this.adminSettingsService
      .changePassword(user.email, password)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess('Password set successfully');
      });
  }
}

@NgModule({
  declarations: [AdminSettingsComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatPaginatorModule,
    MatCardModule,
    MatTooltipModule,
    AdminSettingsNewPasswordPopupModule,
  ],
  providers: [AdminSettingsService],
  exports: [AdminSettingsComponent],
})
export class AdminSettingsModule {}
