<form class="pcri-form" [formGroup]="pcriFormGroup" autocomplete="off">
  <mat-accordion multi>
    <mat-expansion-panel [formGroupName]="pcriFormField.GENERAL_INFORMATION" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ title }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="fields-line">
        <mat-form-field appearance="outline">
          <mat-label>{{
            pcriGeneralInformationLabel[pcriGeneralInformationFormField.PROJECT_NUMBER]
          }}</mat-label>
          <input
            matInput
            [formControlName]="pcriGeneralInformationFormField.PROJECT_NUMBER"
            id="pcri-project-number-input"
          />
          <mat-hint align="end"
            >{{
              pcriFormGroup.get([
                pcriFormField.GENERAL_INFORMATION,
                pcriGeneralInformationFormField.PROJECT_NUMBER
              ])?.value.length
            }}/64</mat-hint
          >
          <mat-error
            *ngIf="
              pcriGeneralInformationValidationMessage.getErrorsBy(
                pcriGeneralInformationFormField.PROJECT_NUMBER
              )
            "
            >{{
              pcriGeneralInformationValidationMessage.getMessageFor(
                pcriGeneralInformationFormField.PROJECT_NUMBER
              )
            }}</mat-error
          >
        </mat-form-field>
      </div>

      <div class="fields-line">
        <mat-form-field appearance="outline">
          <mat-label>{{
            pcriGeneralInformationLabel[pcriGeneralInformationFormField.PROPONENT_EMAIL]
          }}</mat-label>
          <input
            matInput
            [formControlName]="pcriGeneralInformationFormField.PROPONENT_EMAIL"
            id="pcri-proponent-email-input"
          />
          <mat-hint align="end"
            >{{
              pcriFormGroup.get([
                pcriFormField.GENERAL_INFORMATION,
                pcriGeneralInformationFormField.PROPONENT_EMAIL
              ])?.value.length
            }}/128</mat-hint
          >
          <mat-error
            *ngIf="
              pcriGeneralInformationValidationMessage.getErrorsBy(
                pcriGeneralInformationFormField.PROPONENT_EMAIL
              )
            "
            >{{
              pcriGeneralInformationValidationMessage.getMessageFor(
                pcriGeneralInformationFormField.PROPONENT_EMAIL
              )
            }}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{
            pcriGeneralInformationLabel[pcriGeneralInformationFormField.PROPONENT_NAME]
          }}</mat-label>
          <input
            matInput
            [formControlName]="pcriGeneralInformationFormField.PROPONENT_NAME"
            id="pcri-proponent-name-input"
          />
          <mat-hint align="end"
            >{{
              pcriFormGroup.get([
                pcriFormField.GENERAL_INFORMATION,
                pcriGeneralInformationFormField.PROPONENT_NAME
              ])?.value.length
            }}/128</mat-hint
          >
          <mat-error
            *ngIf="
              pcriGeneralInformationValidationMessage.getErrorsBy(
                pcriGeneralInformationFormField.PROPONENT_NAME
              )
            "
            >{{
              pcriGeneralInformationValidationMessage.getMessageFor(
                pcriGeneralInformationFormField.PROPONENT_NAME
              )
            }}</mat-error
          >
        </mat-form-field>
      </div>

      <div class="fields-line">
        <mat-form-field appearance="outline" class="date-picker">
          <mat-label>{{
            pcriGeneralInformationLabel[pcriGeneralInformationFormField.START_DATE]
          }}</mat-label>
          <input
            readonly
            matInput
            [formControlName]="pcriGeneralInformationFormField.START_DATE"
            [matDatepicker]="startDatePicker"
            (click)="startDatePicker.open()"
            (focus)="startDatePicker.open()"
            id="pcri-start-date-input"
          />
          <div matSuffix class="date-picker-action-buttons">
            <mat-datepicker-toggle [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-icon
              appStopPropagationOnClick
              (click)="
                clearDate(
                  pcriFormGroup.get([
                    pcriFormField.GENERAL_INFORMATION,
                    pcriGeneralInformationFormField.START_DATE
                  ])!
                )
              "
              >close</mat-icon
            >
          </div>
          <mat-datepicker #startDatePicker>
            <mat-datepicker-actions>
              <button
                class="btn btn-secondary btn-small"
                matDatepickerCancel
                id="pcri-start-date-cancel-btn"
              >
                Cancel
              </button>
              <button
                class="btn btn-primary btn-small"
                matDatepickerApply
                id="pcri-start-date-apply-btn"
              >
                Apply
              </button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error
            *ngIf="
              pcriGeneralInformationValidationMessage.getErrorsBy(
                pcriGeneralInformationFormField.START_DATE
              )
            "
            >{{
              pcriGeneralInformationValidationMessage.getMessageFor(
                pcriGeneralInformationFormField.START_DATE
              )
            }}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="date-picker">
          <mat-label>{{
            pcriGeneralInformationLabel[pcriGeneralInformationFormField.EXPECTED_DELIVERY_DATE]
          }}</mat-label>
          <input
            readonly
            matInput
            [formControlName]="pcriGeneralInformationFormField.EXPECTED_DELIVERY_DATE"
            [matDatepicker]="expectedDeliveryDatePicker"
            (click)="expectedDeliveryDatePicker.open()"
            (focus)="expectedDeliveryDatePicker.open()"
            id="pcri-delivery-date-input"
          />
          <div matSuffix class="date-picker-action-buttons">
            <mat-datepicker-toggle [for]="expectedDeliveryDatePicker"></mat-datepicker-toggle>
            <mat-icon
              appStopPropagationOnClick
              (click)="
                clearDate(
                  pcriFormGroup.get([
                    pcriFormField.GENERAL_INFORMATION,
                    pcriGeneralInformationFormField.EXPECTED_DELIVERY_DATE
                  ])!
                )
              "
              >close</mat-icon
            >
          </div>
          <mat-datepicker #expectedDeliveryDatePicker>
            <mat-datepicker-actions>
              <button
                class="btn btn-secondary btn-small"
                matDatepickerCancel
                id="pcri-delivery-date-cancel-btn"
              >
                Cancel
              </button>
              <button
                class="btn btn-primary btn-small"
                matDatepickerApply
                id="pcri-delivery-date-apply-btn"
              >
                Apply
              </button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error
            *ngIf="
              pcriGeneralInformationValidationMessage.getErrorsBy(
                pcriGeneralInformationFormField.EXPECTED_DELIVERY_DATE
              )
            "
            >{{
              pcriGeneralInformationValidationMessage.getMessageFor(
                pcriGeneralInformationFormField.EXPECTED_DELIVERY_DATE
              )
            }}</mat-error
          >
        </mat-form-field>
      </div>

      <div class="fields-line">
        <mat-form-field appearance="outline">
          <mat-label>{{
            pcriGeneralInformationLabel[pcriGeneralInformationFormField.DESCRIPTION]
          }}</mat-label>
          <input
            matInput
            [formControlName]="pcriGeneralInformationFormField.DESCRIPTION"
            id="pcri-description-input"
          />
          <mat-hint align="end"
            >{{
              pcriFormGroup.get([
                pcriFormField.GENERAL_INFORMATION,
                pcriGeneralInformationFormField.DESCRIPTION
              ])?.value.length
            }}/128</mat-hint
          >
          <mat-error
            *ngIf="
              pcriGeneralInformationValidationMessage.getErrorsBy(
                pcriGeneralInformationFormField.DESCRIPTION
              )
            "
            >{{
              pcriGeneralInformationValidationMessage.getMessageFor(
                pcriGeneralInformationFormField.DESCRIPTION
              )
            }}</mat-error
          >
        </mat-form-field>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [formGroupName]="pcriFormField.EXPERIMENT_DETAIL" [expanded]="true">
      <div class="hide">
        <mat-form-field appearance="outline">
          <mat-label>{{
            pcriExperimentInformationLabel[pcriExperimentInformationFormField.EXPERIMENT_TYPE]
          }}</mat-label>
          <mat-select
            [formControlName]="pcriExperimentInformationFormField.EXPERIMENT_TYPE"
            id="work-order-experiment-type-select"
          >
            <mat-option
              *ngFor="let experimentType of experimentTypeList"
              [value]="experimentType.id"
            >
              {{ experimentType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <app-dynamic-experiment-template
        [workOrderFormGroup]="pcriFormGroup"
        [experimentTypeList]="experimentTypeList"
        [hideExperimentType]="true"
      ></app-dynamic-experiment-template>
    </mat-expansion-panel>

    <mat-expansion-panel [formGroupName]="pcriFormField.STATUS" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Status </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="fields-line">
        <mat-form-field appearance="outline" class="progress-field">
          <mat-label>{{ pcriStatusLabel[pcriStatusFormField.PROGRESS] }}</mat-label>
          <input
            type="number"
            matInput
            [formControlName]="pcriStatusFormField.PROGRESS"
            id="pcri-progress-input"
          />
          <div matSuffix>%</div>
          <mat-error
            *ngIf="pcriStatusValidationMessage.getErrorsBy(pcriStatusFormField.PROGRESS)"
            >{{
              pcriStatusValidationMessage.getMessageFor(pcriStatusFormField.PROGRESS)
            }}</mat-error
          >
        </mat-form-field>

        <mat-form-field appearance="outline" class="date-picker">
          <mat-label>{{ pcriStatusLabel[pcriStatusFormField.COMPLETION_DATE] }}</mat-label>
          <input
            readonly
            matInput
            [formControlName]="pcriStatusFormField.COMPLETION_DATE"
            [matDatepicker]="completionDatePicker"
            (click)="completionDatePicker.open()"
            (focus)="completionDatePicker.open()"
            id="pcri-completion-date-input"
          />
          <div matSuffix class="date-picker-action-buttons">
            <mat-datepicker-toggle [for]="completionDatePicker"></mat-datepicker-toggle>
            <mat-icon
              appStopPropagationOnClick
              (click)="
                clearDate(
                  pcriFormGroup.get([pcriFormField.STATUS, pcriStatusFormField.COMPLETION_DATE])!
                )
              "
              >close</mat-icon
            >
          </div>
          <mat-datepicker #completionDatePicker>
            <mat-datepicker-actions>
              <button
                class="btn btn-secondary btn-small"
                matDatepickerCancel
                id="pcri-completion-date-cancel-btn"
              >
                Cancel
              </button>
              <button
                class="btn btn-primary btn-small"
                matDatepickerApply
                id="pcri-completion-date-apply-btn"
              >
                Apply
              </button>
            </mat-datepicker-actions>
          </mat-datepicker>
          <mat-error
            *ngIf="pcriStatusValidationMessage.getErrorsBy(pcriStatusFormField.COMPLETION_DATE)"
            >{{
              pcriStatusValidationMessage.getMessageFor(pcriStatusFormField.COMPLETION_DATE)
            }}</mat-error
          >
        </mat-form-field>
      </div>

      <div class="fields-line">
        <mat-checkbox
          color="primary"
          class="checkbox"
          [formControlName]="pcriStatusFormField.COMPLETED"
          >{{ pcriStatusLabel[pcriStatusFormField.COMPLETED] }}</mat-checkbox
        >
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</form>
