import { SampleType } from '../../../common/sample-check-in-menu/enums/sample-type.enum';
import {
  CoreSampleInformationFormField,
  CuttingsSampleInformationFormField,
  FluidSampleInformationFormField,
  PlugSampleInformationFormField,
  SampleCheckInFormField,
  ThinSectionSampleInformationFormField,
  UncategorizedSampleInformationFormField,
  WellInformationFormField,
} from './sample-check-in-form-field.enum';

export const SAMPLE_CHECK_IN_FORM_LABEL: Record<SampleCheckInFormField, string> = {
  [SampleCheckInFormField.NAME]: 'Sample name',
  [SampleCheckInFormField.SAMPLE_LOCATION]: 'Sample location',
  [SampleCheckInFormField.PARENT_SAMPLE]: 'Parent sample',
  [SampleCheckInFormField.RESTRICTION]: 'Restriction',
  [SampleCheckInFormField.BARCODE]: 'Barcode',
  [SampleCheckInFormField.QRCODE]: 'QR code',
  [SampleCheckInFormField.DESCRIPTION]: 'Description',
  [SampleCheckInFormField.OWNER]: 'Project owner',
  [SampleCheckInFormField.CUSTODIAN]: 'Sample custodian',
};

export const PLUG_SAMPLE_INFORMATION_FORM_LABEL: Record<PlugSampleInformationFormField, string> = {
  [PlugSampleInformationFormField.DEPTH]: 'Depth',
  [PlugSampleInformationFormField.ORIENTATION]: 'Orientation',
  [PlugSampleInformationFormField.PRESERVATION_TYPE]: 'Preservation type',
};

export const CORE_SAMPLE_INFORMATION_FORM_LABEL: Record<CoreSampleInformationFormField, string> = {
  [CoreSampleInformationFormField.DEPTH_TOP]: 'Depth top',
  [CoreSampleInformationFormField.DEPTH_BOTTOM]: 'Depth bottom',
  [CoreSampleInformationFormField.PRESERVATION_TYPE]: 'Preservation type',
};

export const CUTTINGS_SAMPLE_INFORMATION_FORM_LABEL: Record<
  CuttingsSampleInformationFormField,
  string
> = {
  [CuttingsSampleInformationFormField.DEPTH_TOP]: 'Depth top',
  [CuttingsSampleInformationFormField.DEPTH_BOTTOM]: 'Depth bottom',
  [CuttingsSampleInformationFormField.PRESERVATION_TYPE]: 'Preservation type',
};

export const FLUID_SAMPLE_INFORMATION_FORM_LABEL: Record<FluidSampleInformationFormField, string> =
  {
    [FluidSampleInformationFormField.DEPTH]: 'Depth ',
  };

export const THIN_SECTION_SAMPLE_INFORMATION_FORM_LABEL: Record<
  ThinSectionSampleInformationFormField,
  string
> = {
  [ThinSectionSampleInformationFormField.SECTION_TYPE]: 'Section type',
  [ThinSectionSampleInformationFormField.DEPTH_TOP]: 'Depth top',
  [ThinSectionSampleInformationFormField.SECTION_DIMENSION_X]: 'Section dimension X',
  [ThinSectionSampleInformationFormField.SECTION_DIMENSION_Y]: 'Section dimension Y',
  [ThinSectionSampleInformationFormField.SECTION_DIMENSION_Z]: 'Section dimension Z',
  [ThinSectionSampleInformationFormField.SECTION_DIMENSION_UNIT_ID]: 'Section dimension unit',
  [ThinSectionSampleInformationFormField.DEPTH_BOTTOM]: 'Depth bottom',
  [ThinSectionSampleInformationFormField.STAINING]: 'Staining',
  [ThinSectionSampleInformationFormField.SECTION_NUMBER]: 'Section number',
  [ThinSectionSampleInformationFormField.ORIENTATION]: 'Orientation',
  [ThinSectionSampleInformationFormField.SECTION_ORIGIN]: 'Section origin',
};

export const UNCATEGORIZED_SAMPLE_INFORMATION_FORM_LABEL: Record<
  UncategorizedSampleInformationFormField,
  string
> = {
  [UncategorizedSampleInformationFormField.DEPTH]: 'Depth ',
};

export const WELL_INFORMATION_FORM_LABEL: Record<WellInformationFormField, string> = {
  [WellInformationFormField.WELL_ID]: 'Well name',
  [WellInformationFormField.WELL_LOCATION]: 'Well location',
  [WellInformationFormField.FIELD]: 'Field',
  [WellInformationFormField.RESERVOIR]: 'Reservoir',
};

export const ALL_FORM_LABELS = {
  ...SAMPLE_CHECK_IN_FORM_LABEL,
  ...PLUG_SAMPLE_INFORMATION_FORM_LABEL,
  ...CORE_SAMPLE_INFORMATION_FORM_LABEL,
  ...CUTTINGS_SAMPLE_INFORMATION_FORM_LABEL,
  ...FLUID_SAMPLE_INFORMATION_FORM_LABEL,
  ...THIN_SECTION_SAMPLE_INFORMATION_FORM_LABEL,
  ...UNCATEGORIZED_SAMPLE_INFORMATION_FORM_LABEL,
  ...WELL_INFORMATION_FORM_LABEL,
};

export const SAMPLE_TYPE_LABEL: Record<SampleType, string> = {
  [SampleType.PLUG]: 'plug',
  [SampleType.CORE]: 'core',
  [SampleType.CUTTINGS]: 'cuttings',
  [SampleType.FLUID]: 'fluid',
  [SampleType.UNCATEGORIZED]: 'uncategorized',
  [SampleType.THINSECTION]: 'thin section',
  [SampleType.COMPOSITE]: 'composite',
};
