<mat-form-field>
  <mat-label>
    {{ label }}
  </mat-label>
  <mat-select
    [multiple]="multiSelect"
    [formControl]="control"
    [compareWith]="optionsCompareFn"
    id="multi-select"
    [required]="requiredField"
    (selectionChange)="onOptionSelection()"
  >
    <mat-option *ngIf="clearOption"></mat-option>
    <mat-option
      *ngFor="let selectOption of selectOptions"
      [value]="returnIdValue ? selectOption.id : selectOption.name"
    >
      {{ selectOption.name }}
    </mat-option>
  </mat-select>

  <mat-error *ngIf="control!.errors && control!.getError('required')"
    >{{ label }} should not be empty</mat-error
  >
</mat-form-field>
