import { DefaultSampleType } from '@core/types/default-sample-type.type';

import { SampleType } from '../../common/sample-check-in-menu/enums/sample-type.enum';
import { SampleInfoKey } from '../../features/sample/enums/sample-info-key.enum';
import { CoreInformation } from '../../features/sample/models/core-information.model';
import { CuttingsIntervalInformation } from '../../features/sample/models/cuttings-interval-information.model';
import { FluidInformation } from '../../features/sample/models/fluid-information.model';
import { PlugInformation } from '../../features/sample/models/plug-information.model';
import { ThinSectionInformation } from '../../features/sample/models/thin-section-information.model';
import { UncategorizedInformation } from '../../features/sample/models/uncategorized-information.model';

export const SAMPLE_TYPE_INFO_KEY_MAP: Record<DefaultSampleType, SampleInfoKey> = {
  [SampleType.CORE]: SampleInfoKey.CORE_INFORMATION,
  [SampleType.PLUG]: SampleInfoKey.PLUG_INFORMATION,
  [SampleType.CUTTINGS]: SampleInfoKey.CUTTINGS_INTERVAL_INFORMATION,
  [SampleType.FLUID]: SampleInfoKey.FLUID_INFORMATION,
  [SampleType.UNCATEGORIZED]: SampleInfoKey.UNCATEGORIZED_INFORMATION,
  [SampleType.THINSECTION]: SampleInfoKey.THIN_SECTION_INFORMATION,
};

export const SAMPLE_TYPE_INFO_CLASS_MAP: Record<
  DefaultSampleType,
  | typeof CoreInformation
  | typeof PlugInformation
  | typeof CuttingsIntervalInformation
  | typeof FluidInformation
  | typeof UncategorizedInformation
  | typeof ThinSectionInformation
> = {
  [SampleType.CORE]: CoreInformation,
  [SampleType.PLUG]: PlugInformation,
  [SampleType.CUTTINGS]: CuttingsIntervalInformation,
  [SampleType.FLUID]: FluidInformation,
  [SampleType.UNCATEGORIZED]: UncategorizedInformation,
  [SampleType.THINSECTION]: ThinSectionInformation,
};
