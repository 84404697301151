import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API, PASS_ERROR_HEADER } from '@core/constants/api.consts';

import { Report } from '../models/report.model';

@Injectable()
export class FileVerificationService {
  private url = `${CORE_MEMORY_API}/DataUploader`;
  private verifyUrl = `${this.url}/verify`;
  private uploadUrl = `${this.url}/upload`;

  constructor(private httpClient: HttpClient) {}

  parseFile(file: File): Observable<Report[]> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', file.type);

    return this.httpClient.post<Report[]>(this.verifyUrl, formData, {
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }

  submit(file: File): Observable<null> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', file.type);

    return this.httpClient.post<null>(this.uploadUrl, formData);
  }
}
