import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppConfigProvider } from '@core/providers/app-config.provider';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

fetch('assets/config/app.config.json').then(async (response) => {
  const appConfigProvider = new AppConfigProvider(await response.json());

  platformBrowserDynamic([{ provide: AppConfigProvider, useValue: appConfigProvider }])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
