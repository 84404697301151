import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { SampleType } from 'src/app/common/sample-check-in-menu/enums/sample-type.enum';
import { SampleTableSelectorPopupFieldModule } from 'src/app/common/sample-table-selector-popup-field/sample-table-selector-popup-field.component';

import { SampleIdDisplayName } from '../../../common/sample-table-selector-popup-field/models/sample-display-name.interface';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-sample-composite-form',
  templateUrl: './sample-composite-form.component.html',
  styleUrls: ['./sample-composite-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleCompositeFormComponent implements OnInit {
  @Input() formArray!: UntypedFormArray;
  @Input() isSampleDetails = false;
  @Input() isReadOnly = false;

  readonly faTrashCan = faTrashCan;
  readonly plugSampleTypeOnly = [SampleType.PLUG];

  get formControls(): UntypedFormControl[] {
    return this.formArray.controls as UntypedFormControl[];
  }

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    if (!this.isSampleDetails && !this.isReadOnly) {
      this.addControl('');
    } else {
      this.formArray.disable();
    }
  }

  addControl(value: string): void {
    this.formArray.push(new UntypedFormControl(value, Validators.required));
  }

  removeControl(index: number): void {
    this.formArray.removeAt(index);
  }

  checkDuplicateSample(selectedSample: SampleIdDisplayName, index: number): void {
    const selectedArray = this.removeEmptyValue(this.formArray.value);
    const uniqueArray = new Set(selectedArray).size;
    if (selectedArray.length !== uniqueArray) {
      this.resetFormValue(index);
      this.notificationService.notifyError('Same sample should not be selected twice');
    }
  }

  removeEmptyValue(formValue: Array<string>): string[] {
    return formValue.filter((x) => x !== '');
  }

  resetFormValue(index: number): void {
    (<UntypedFormArray>this.formArray).at(index).patchValue('');
  }
}

@NgModule({
  declarations: [SampleCompositeFormComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ReactiveFormsModule,
    SampleTableSelectorPopupFieldModule,
  ],
  exports: [SampleCompositeFormComponent],
})
export class SampleCompositeFormModule {}
