import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API, PASS_ERROR_HEADER } from '@core/constants/api.consts';
import { WorkOrder } from 'src/app/features/work-order/models/work-order.model';

import { DashboardWidgetFilter } from '../../dashboard/dashboard-widget/models/dashboard-widget-filter.model';
import { DashboardFilterResult } from '../../dashboard/models/dashboard-filter-result.model';

@Injectable()
export class WorkOrderService {
  private url = `${CORE_MEMORY_API}/WorkOrders`;

  constructor(private httpClient: HttpClient) {}

  getAllBy(sampleId: string): Observable<WorkOrder[]> {
    return this.httpClient.get<WorkOrder[]>(this.url, {
      params: new HttpParams().set('sampleId', sampleId),
    });
  }

  create(workOrder: WorkOrder): Observable<{ id: string }> {
    return this.httpClient.post<{ id: string }>(this.url, workOrder, {
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }

  update(workOrder: WorkOrder): Observable<null> {
    return this.httpClient.put<null>(`${this.url}/${workOrder.id}`, workOrder);
  }

  getFilteredWorkOrders(filterData: DashboardWidgetFilter): Observable<DashboardFilterResult[]> {
    let parameters = new HttpParams()
      .set('Kpi', filterData.isKpi)
      .set('Tab', filterData.activeTab)
      .set('StartDate', filterData.startDate)
      .set('EndDate', filterData.endDate)
      .appendAll(filterData.sampleTypes)
      .appendAll(filterData.experimentTypes)
      .set('Status.Completed', filterData.status.completed)
      .set('Status.OngoingDelay', filterData.status.ongoingDelay)
      .set('Status.Ongoing', filterData.status.ongoing)
      .appendAll(filterData.wells);

    if (filterData.display) {
      parameters = parameters.append('Display', filterData.display);
    }

    if (filterData.teams) {
      parameters = parameters.appendAll(filterData.teams);
    }

    return this.httpClient.get<DashboardFilterResult[]>(`${this.url}/filter`, {
      params: parameters,
    });
  }
}
