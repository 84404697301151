import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';

import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { UserNotificationService } from '../../../services/api/user-notification.service';
import { NotificationService } from '../../../services/notification.service';
import { StateName } from '../../data-access/lookup-sample/enums/access-status.enum';
import { SampleAccessRequestNotification } from '../models/sample-access-request-notification.model';

@Component({
  selector: 'app-sample-access-request-notification',
  templateUrl: './sample-access-request-notification.component.html',
  styleUrls: ['./sample-access-request-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleAccessRequestNotificationComponent extends Destroyable(Object) {
  @Input() title = 'Sample request';
  @Input() model!: SampleAccessRequestNotification;

  constructor(
    private userNotificationService: UserNotificationService,
    private notificationService: NotificationService,
  ) {
    super();
  }

  approve(): void {
    this.userNotificationService
      .updateSampleAccessRequestStatus(this.model.id, StateName.APPROVED)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess('Access request has been approved');
        this.userNotificationService.getNotifications();
      });
  }

  reject(): void {
    this.userNotificationService
      .updateSampleAccessRequestStatus(this.model.id, StateName.REJECTED)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess('Access request has been rejected');
        this.userNotificationService.getNotifications();
      });
  }
}

@NgModule({
  declarations: [SampleAccessRequestNotificationComponent],
  imports: [CommonModule],
  exports: [SampleAccessRequestNotificationComponent],
})
export class SampleAccessRequestNotificationModule {}
