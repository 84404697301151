<h1 matDialogTitle>Sample plot options</h1>
<mat-dialog-content>
  <h3 class="checkbox-name top">Sample Types</h3>
  <div
    *ngIf="sampleTypeFormGroup && sampleTypesOptions?.length"
    [formGroup]="sampleTypeFormGroup"
    class="field-wrapper"
  >
    <div class="row multi-select">
      <app-checkbox-list-field
        [formControlName]="'sampleType'"
        [checkboxListOptions]="sampleTypesOptions"
      >
      </app-checkbox-list-field>
    </div>
  </div>

  <h3 class="checkbox-name">Team</h3>
  <div *ngIf="teamsFormGroup" [formGroup]="teamsFormGroup" class="field-wrapper">
    <div class="row multi-select">
      <app-multi-select
        [formControlName]="'teams'"
        [multiSelect]="true"
        [label]="'Teams'"
        [selectOptions]="teamsOptions"
        [returnIdValue]="false"
      >
      </app-multi-select>
    </div>
  </div>

  <h3 class="checkbox-name top">Status</h3>
  <div
    *ngIf="availabilityFormGroup && dataObject.sampleAvailability"
    [formGroup]="availabilityFormGroup"
    class="field-wrapper"
  >
    <div class="row multi-select">
      <mat-checkbox
        *ngFor="let availability of sampleAvailabilityOptions; let i = index"
        [formControlName]="availability.id"
        [color]="'primary'"
        [id]="'sample-type-status-' + i"
        >{{ availability.name }}
      </mat-checkbox>
    </div>
  </div>

  <h3 class="checkbox-name">Duration</h3>
  <div
    class="checkbox-duration-container"
    *ngIf="durabilityFormGroup && dataObject.duration"
    [formGroup]="durabilityFormGroup"
  >
    <mat-form-field appearance="outline" class="date-picker">
      <mat-label>Start Date</mat-label>
      <input
        readonly
        matInput
        formControlName="startDate"
        [matDatepicker]="startDatePicker"
        (click)="startDatePicker.open()"
        (focus)="startDatePicker.open()"
        id="sample-types-start-date-input"
      />
      <div matSuffix class="date-picker-action-buttons">
        <mat-datepicker-toggle [for]="startDatePicker"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #startDatePicker>
        <mat-datepicker-actions>
          <button
            class="btn btn-secondary btn-small"
            matDatepickerCancel
            id="sample-types-start-date-cancel-btn"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary btn-small"
            matDatepickerApply
            id="sample-types-start-date-apply-btn"
          >
            Apply
          </button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline" class="date-picker">
      <mat-label>End Date</mat-label>
      <input
        readonly
        matInput
        [matDatepicker]="endDatePicker"
        formControlName="endDate"
        (click)="endDatePicker.open()"
        (focus)="endDatePicker.open()"
        id="sample-types-end-date-input"
      />
      <div matSuffix class="date-picker-action-buttons">
        <mat-datepicker-toggle [for]="endDatePicker"></mat-datepicker-toggle>
      </div>
      <mat-datepicker #endDatePicker>
        <mat-datepicker-actions>
          <button
            class="btn btn-secondary btn-small"
            matDatepickerCancel
            id="sample-types-end-date-cancel-btn"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary btn-small"
            matDatepickerApply
            id="sample-types-end-date-apply-btn"
          >
            Apply
          </button>
        </mat-datepicker-actions>
      </mat-datepicker>
    </mat-form-field>
  </div>

  <h3 class="checkbox-name top">Display</h3>
  <div
    *ngIf="displayFormGroup && dataObject.display"
    [formGroup]="displayFormGroup"
    class="field-wrapper"
  >
    <div class="row radio-btns">
      <mat-radio-group formControlName="display" [color]="'primary'">
        <mat-radio-button
          class="radio-btn"
          *ngFor="let display of displayOptions"
          [value]="display.id"
          >{{ display.name }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    class="btn btn-secondary"
    mat-button
    [mat-dialog-close]="undefined"
    id="sample-types-chart-filter-cancel-btn"
  >
    Cancel
  </button>
  <button
    class="btn btn-primary"
    mat-button
    [mat-dialog-close]="data"
    (click)="apply()"
    cdkFocusInitial
    id="sample-types-chart-filter-confirm-btn"
  >
    Apply
  </button>
</mat-dialog-actions>
