import { Injectable } from '@angular/core';

import { UserInfo } from '@core/models/user-info.model';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private readonly userInfoStorageKey = 'userInfo';

  get isAdmin(): boolean {
    return this.getUserInfo()!.admin;
  }

  addUserInfo(userInfo: UserInfo): void {
    localStorage.setItem(this.userInfoStorageKey, JSON.stringify(userInfo));
  }

  getUserInfo(): UserInfo | undefined {
    const userInfo = localStorage.getItem(this.userInfoStorageKey);
    return userInfo ? JSON.parse(userInfo) : undefined;
  }

  removeUserInfo(): void {
    localStorage.removeItem(this.userInfoStorageKey);
  }
}
