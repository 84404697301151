import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ObjectDeepnessConverterService {
  convertToFlatObject(data: any): Record<string, any> {
    const flatObject = {};

    this.buildFlatObject(flatObject, data);

    return flatObject;
  }

  convertToDeepObject(target: Record<string, any>): Record<string, any> {
    const result = {} as Record<string, any>;

    Object.keys(target).forEach((key) => {
      const nestedKeys = key.split('.');
      const branch = nestedKeys.slice(0, nestedKeys.length - 1);

      let currentTarget = result;

      for (let i = 0; i < branch.length; i += 1) {
        const subKey = nestedKeys[i];
        const subSubKey = nestedKeys[i + 1];
        const isArray = !!subKey.match(/\[\d*\]/);
        const isContainArray = !!subSubKey.match(/\[\d*\]/);

        const currentKey = isArray ? Number(subKey.slice(1, -1)) : subKey;

        if (currentTarget[currentKey] === undefined) {
          if (isContainArray) {
            currentTarget[currentKey] = [];
          } else {
            currentTarget[currentKey] = {};
          }
        }

        currentTarget = currentTarget[currentKey];
      }

      const leaf = nestedKeys[nestedKeys.length - 1];
      const isLeafArray = !!leaf.match(/\[\d*\]/);
      const correctedLeaf = isLeafArray ? Number(leaf.slice(1, -1)) : leaf;

      currentTarget[correctedLeaf] = target[key];
    });

    return result;
  }

  private buildFlatObject(object: Record<string, any>, data: any, parentKey?: string): void {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        this.buildFlatObject(
          object,
          data[key],
          parentKey ? `${parentKey}${Array.isArray(data) ? `.[${key}]` : `.${key}`}` : key,
        );
      });
    } else {
      object[parentKey!] = data == null ? '' : data;
    }
  }
}
