import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import {
  MatLegacyPaginator as MatPaginator,
  MatLegacyPaginatorModule as MatPaginatorModule,
} from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import {
  MatLegacyTableDataSource as MatTableDataSource,
  MatLegacyTableModule as MatTableModule,
} from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { StringArrayToStringPipeModule } from 'src/app/common/pipes/string-array-to-string.pipe';

import { UploadDirective, UploadModule } from '../../common/directives/upload.directive';
import { NotificationService } from '../../services/notification.service';
import { ReportStatus } from './enums/report-status.enum';
import { Report } from './models/report.model';
import { FileVerificationService } from './services/file-verification.service';

@Component({
  selector: 'app-data-uploader',
  templateUrl: './data-uploader.component.html',
  styleUrls: ['./data-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataUploaderComponent extends Destroyable(Object) implements AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  readonly reportStatus = ReportStatus;

  file: File | null = null;
  fileName = '';
  fileInputState: 'empty' | 'loading' | 'success' | 'error' = 'empty';
  tableData = new MatTableDataSource<Report>();
  reportData: Report[] = [];
  errorMsg!: string;
  displayedColumns: Array<keyof Report> = [
    'sampleName',
    'type',
    'depth',
    'wellName',
    'status',
    'messages',
  ];

  readonly prefixMessage = 'Please check following field(s): ';

  private popup: Window | null = null;

  get isApplyButtonDisabled(): boolean {
    return this.tableData.data.some((reportItem) => reportItem.status === ReportStatus.FAILED);
  }

  constructor(
    private cd: ChangeDetectorRef,
    private fileVerificationService: FileVerificationService,
    private notificationService: NotificationService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.tableData.paginator = this.paginator;
    this.cd.detectChanges();
  }

  uploadFile(e: Event): void {
    const target = e.target as HTMLInputElement;
    this.errorMsg = '';
    if (target?.files?.length) {
      this.file = target.files.item(0)!;
      this.fileInputState = 'loading';
      this.fileName = this.file.name;

      this.fileVerificationService
        .parseFile(this.file)
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: (reportData) => {
            this.fileInputState = 'success';
            this.tableData.data = reportData;
            this.reportData = reportData;
            this.cd.markForCheck();
          },
          error: (d) => {
            this.errorMsg = d.error.message;
            this.fileInputState = 'error';
            this.cd.markForCheck();
          },
        });
    }
  }

  onApplyClicked(): void {
    this.fileVerificationService
      .submit(this.file!)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess('File successfully verified!');
      });
  }

  clear(uploadDirective: UploadDirective): void {
    this.tableData.data = [];
    this.fileName = '';
    this.file = null;
    this.fileInputState = 'empty';
    uploadDirective.resetInput();
    this.cd.markForCheck();
  }

  print(): void {
    if (this.popup) {
      this.popup.close();
    }

    this.popup = window.open('', 'Print report', 'popup');

    const reportDataHtml = this.getReportHtmlString();
    this.popup!.document.body.insertAdjacentHTML('beforeend', reportDataHtml);
    this.addCssRulesToReport();

    this.popup!.focus();
    this.popup!.print();
  }

  private getReportHtmlString(): string {
    return `
        <table>
          <thead>
            <tr>
              <th>Sample</th>
              <th>Type</th>
              <th>Depth</th>
              <th>Well</th>
              <th>Status</th>
              <th>Info</th>
            </tr>
          </thead>
          <tbody>
            ${this.reportData.reduce((acc, reportDataItem) => {
              return `${acc}<tr>
                <td>${reportDataItem.sampleName}</td>
                <td>${reportDataItem.type}</td>
                <td>${reportDataItem.depth}</td>
                <td>${reportDataItem.wellName}</td>
                <td class="${
                  reportDataItem.status === ReportStatus.SUCCESS ? 'success' : 'failed'
                }">${reportDataItem.status}</td>
                <td>
                  ${reportDataItem.experimentTypeName + (reportDataItem.messages.length ? ':' : '')}
                  ${this.extractInfoCellContent(reportDataItem.messages)}
                </td>
              </tr>`;
            }, '')}
          </tbody>
        </table>
      `;
  }

  private extractInfoCellContent(messages: string[]): string {
    return messages.reduce((info, msg, currentIndex) => {
      return (currentIndex ? `${info}<br />` : '<br />') + msg;
    }, '');
  }

  private addCssRulesToReport(): void {
    const styleEl = this.popup!.document.createElement('style');
    this.popup!.document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet!;
    styleSheet.insertRule('table { width: 100% }', styleSheet.cssRules.length);
    styleSheet.insertRule('table tr th { text-align: start }', styleSheet.cssRules.length);
    styleSheet.insertRule('table td.success { color: #32b877 }', styleSheet.cssRules.length);
    styleSheet.insertRule('table td.failed { color: #c71a2f }', styleSheet.cssRules.length);
    styleSheet.insertRule(
      `
        table, th, td {
          border: 1px solid black;
          border-collapse: collapse;
        }
      `,
      styleSheet.cssRules.length,
    );
  }
}

@NgModule({
  declarations: [DataUploaderComponent],
  exports: [DataUploaderComponent],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    UploadModule,
    MatIconModule,
    MatProgressSpinnerModule,
    CommonModule,
    MatTooltipModule,
    FormsModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule,
    StringArrayToStringPipeModule,
  ],
  providers: [FileVerificationService],
})
export class DataUploaderModule {}
