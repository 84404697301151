import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';

import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { UserNotificationService } from '../../../services/api/user-notification.service';
import { NotificationService } from '../../../services/notification.service';
import { LocationState } from '../enums/location-state.enum';
import { LocationAccessRequestNotification } from '../models/location-access-request-notification.model';

@Component({
  selector: 'app-location-access-request-notification',
  templateUrl: './location-access-request-notification.component.html',
  styleUrls: ['./location-access-request-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationAccessRequestNotificationComponent extends Destroyable(Object) {
  @Input() title = 'Location request';
  @Input() model!: LocationAccessRequestNotification;

  constructor(
    private userNotificationService: UserNotificationService,
    private notificationService: NotificationService,
  ) {
    super();
  }

  approve(): void {
    this.userNotificationService
      .updateLocationAccessRequestStatus(this.model.id, LocationState.CREATED)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess('Access request has been approved');
        this.userNotificationService.getNotifications();
      });
  }

  reject(): void {
    this.userNotificationService
      .updateLocationAccessRequestStatus(this.model.id, LocationState.REJECTED)
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess('Access request has been rejected');
        this.userNotificationService.getNotifications();
      });
  }
}

@NgModule({
  declarations: [LocationAccessRequestNotificationComponent],
  exports: [LocationAccessRequestNotificationComponent],
  imports: [CommonModule],
})
export class LocationAccessRequestNotificationModule {}
