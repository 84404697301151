import { Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AdminSettingsModule } from 'src/app/features/admin-settings/admin-settings.component';
import { DashboardModule } from 'src/app/features/dashboard/dashboard/dashboard.component';
import { LookupLocationModule } from 'src/app/features/data-access/lookup-location/lookup-location.component';
import { LookupSampleModule } from 'src/app/features/data-access/lookup-sample/lookup-sample.component';
import { LookupWellModule } from 'src/app/features/data-access/lookup-well/lookup-well.component';
import { LocationPageModule } from 'src/app/features/location/location-page.component';
import { SampleModule } from 'src/app/features/sample/sample.component';
import { WorkOrderModule } from 'src/app/features/work-order/work-order.component';

import { DataUploaderModule } from '../../features/data-uploader/data-uploader.component';
import { DigitalMicpSimulationModule } from '../../features/digital-micp-simulation/digital-micp-simulation.component';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent {}

@NgModule({
  declarations: [ContentComponent],
  imports: [
    RouterModule.forChild([]),
    DashboardModule,
    LookupSampleModule,
    LookupLocationModule,
    LookupWellModule,
    SampleModule,
    WorkOrderModule,
    LocationPageModule,
    DataUploaderModule,
    AdminSettingsModule,
    DigitalMicpSimulationModule,
  ],
  providers: [],
  exports: [ContentComponent],
})
export class ContentModule {}
