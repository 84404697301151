import { ChangeDetectionStrategy, Component, Inject, NgModule, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { IdName } from '@core/models/id-name.model';
import { ValidationState } from '@core/models/validation-state.model';
import { throttleClick } from '@core/utils/decorators/throttle-click.decorator';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import {
  ThrottleClickDirective,
  ThrottleClickModule,
} from '../../../common/directives/throttle-click.directive';
import { UnitInputField } from '../../../common/unit-input/enums/unit-input-field.enum';
import { NotificationService } from '../../../services/notification.service';
import { generateWorkOrderForm } from '../../work-order/form/generate-work-order-form.util';
import { ExperimentField } from '../../work-order/models/experiment-field.model';
import { WorkOrderImpl } from '../../work-order/models/work-order.model';
import { WorkOrderFormShapeImpl } from '../../work-order/models/work-order-form-shape.model';
import {
  WorkOrderDisplayNameModule,
  WorkOrderDisplayNamePipe,
} from '../../work-order/pipes/work-order-display-name.pipe';
import { WorkOrderService } from '../../work-order/services/work-order.service';
import {
  WorkOrderFormComponent,
  WorkOrderFormModule,
} from '../../work-order/work-order-form/work-order-form.component';
import { WorkOrderPopupData } from '../models/work-order-popup-data.model';

@Component({
  selector: 'app-work-order-popup',
  templateUrl: './work-order-popup.component.html',
  styleUrls: ['./work-order-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WorkOrderDisplayNamePipe],
})
export class WorkOrderPopupComponent extends Destroyable(Object) implements OnInit {
  workOrderFormGroup!: UntypedFormGroup;
  vendorNames!: IdName[];
  experimentTypes!: IdName[];
  experimentFields!: ExperimentField[];
  sampleId!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WorkOrderPopupData,
    private formBuilder: UntypedFormBuilder,
    private workOrderService: WorkOrderService,
    private notificationService: NotificationService,
    private matDialogRef: MatDialogRef<WorkOrderPopupComponent>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.workOrderFormGroup = generateWorkOrderForm(
      this.formBuilder,
      new WorkOrderFormShapeImpl(this.data.workOrder),
    );
    this.vendorNames = this.data.vendorNames;
    this.experimentTypes = this.data.experimentTypes;
    this.experimentFields = this.data.workOrder.experimentFields ?? [];
    this.sampleId = this.data.sampleId;
  }

  @throttleClick()
  save(throttleClickDirective: ThrottleClickDirective, component: WorkOrderFormComponent): void {
    const validationState = this.checkIfFormIsValid(component);

    if (!validationState.isValid) {
      component.workOrderFormGroup.markAllAsTouched();
      component.componentsWithInnerFormControls.forEach((cmp) => cmp.detectChanges());
      this.notificationService.notifyError(validationState.errorMessage);
      throttleClickDirective.isNextClickAllowed$.next(true);
      return;
    }

    const experimentFields = this.getExperimentFields(component);
    const payload = new WorkOrderImpl({
      ...component.workOrderFormGroup.getRawValue(),
      sampleId: this.sampleId,
      experimentFields,
    });

    this.workOrderService
      .update(payload)
      .pipe(throttleClickDirective.allowNextClickAfterFinalized(), this.takeUntilDestroyed())
      .subscribe(() => {
        this.matDialogRef.close(payload);
      });
  }

  private checkIfFormIsValid(component: WorkOrderFormComponent): ValidationState {
    if (component.workOrderFormGroup.status === 'VALID') {
      return { isValid: true };
    }

    const validationMessage = component.dynamicValidationMessageList.find((valMessage) =>
      valMessage.hasError(),
    )!;

    return {
      isValid: false,
      errorMessage: validationMessage.getFirstMessage(),
    };
  }

  private getExperimentFields(component: WorkOrderFormComponent): ExperimentField[] {
    const { experimentFieldsMap, dynamicFieldFormGroup } =
      component.dynamicExperimentTemplateComponent;

    const experimentFields = Object.values(experimentFieldsMap).map((experimentField) => {
      const fieldValue = dynamicFieldFormGroup.value[experimentField.fieldName];

      if (fieldValue === null || typeof fieldValue === 'string' || typeof fieldValue === 'number') {
        return { ...experimentField, fieldValue };
      }

      return {
        ...experimentField,
        fieldValue: fieldValue[UnitInputField.FIELD_VALUE],
        unitValue: fieldValue[UnitInputField.UNIT_ID],
      };
    });

    return experimentFields;
  }
}

@NgModule({
  declarations: [WorkOrderPopupComponent],
  exports: [WorkOrderPopupComponent],
  imports: [WorkOrderFormModule, MatDialogModule, ThrottleClickModule, WorkOrderDisplayNameModule],
})
export class WorkOrderPopupModule {}
