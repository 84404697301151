export class Location {
  id?: string;
  name: string;
  description: string;
  locationTypeId: number | null;
  locationRestrictionIds: string[];
  parentLocationId: string | null;
  barcodeId: string;
  qrCodeId: string;

  constructor(location?: Location) {
    this.id = location?.id ?? undefined;
    this.name = location?.name ?? '';
    this.description = location?.description ?? '';
    this.locationTypeId = location?.locationTypeId ?? null;
    this.locationRestrictionIds = location?.locationRestrictionIds ?? [];
    this.parentLocationId = location?.parentLocationId ?? null;
    this.barcodeId = location?.barcodeId ?? '';
    this.qrCodeId = location?.qrCodeId ?? '';
  }
}
