import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API, PASS_ERROR_HEADER } from '@core/constants/api.consts';
import { DigitalVolume } from '@core/models/digital-volume.model';
import { IdName } from '@core/models/id-name.model';

import { DigitalThinSection } from '../../features/complex-data-uploader/models/thin-section-model';

@Injectable({
  providedIn: 'root',
})
export class ComplexDataUploaderService {
  private readonly url = `${CORE_MEMORY_API}/ComplexDataUploader`;

  constructor(private httpClient: HttpClient) {}

  upload(digitalThinSection: DigitalThinSection, sampleId: string): Observable<null> {
    return this.httpClient.post<null>(
      `${this.url}/digitalThinSection/${sampleId}`,
      digitalThinSection,
    );
  }

  getAll(sampleId: string): Observable<DigitalThinSection[]> {
    return this.httpClient.get<DigitalThinSection[]>(`${this.url}/digitalThinSection/${sampleId}`);
  }

  getVolumeTypes(): Observable<IdName[]> {
    return this.httpClient.get<IdName[]>(`${this.url}/VolumeTypes`);
  }

  getDataFormats(): Observable<IdName[]> {
    return this.httpClient.get<IdName[]>(`${this.url}/dataFormats`);
  }

  getOrientations(): Observable<IdName[]> {
    return this.httpClient.get<IdName[]>(`${this.url}/digitalVolumeOrientations`);
  }

  getDigitalVolumeBy(sampleId: string): Observable<(DigitalVolume & { sampleId: string })[]> {
    return this.httpClient.get<(DigitalVolume & { sampleId: string })[]>(
      `${this.url}/digitalVolume/${sampleId}`,
    );
  }

  saveDigitalVolume(payload: DigitalVolume, sampleId: string): Observable<DigitalVolume> {
    return this.httpClient.post<DigitalVolume>(`${this.url}/digitalVolume/${sampleId}`, payload, {
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }
}
