import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';
import { AdminSettingsNewUser } from 'src/app/features/admin-settings/models/admin-settings-new-user.model';

@Injectable({
  providedIn: 'root',
})
export class AdminSettingsService {
  private url = `${CORE_MEMORY_API}/Authentication`;

  constructor(private httpClient: HttpClient) {}

  changePassword(userEmail: string, newPassword: string): Observable<null> {
    return this.httpClient.put<null>(`${this.url}/forceChangePassword`, {
      userEmail,
      newPassword,
    });
  }

  createNewUser(user: AdminSettingsNewUser): Observable<null> {
    return this.httpClient.post<null>(`${this.url}/createUser`, user);
  }
}
