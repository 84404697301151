import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { UnitPopupData } from 'src/app/common/unit-input/models/unit-popup-data.model';
import { ParseUnitModule } from 'src/app/common/unit-input/parse-unit.pipe';

@Component({
  selector: 'app-unit-popup',
  templateUrl: './unit-popup.component.html',
  styleUrls: ['./unit-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitPopupComponent extends Destroyable(Object) implements OnInit {
  selectedUnitOption = new UntypedFormControl('');

  constructor(@Inject(MAT_DIALOG_DATA) public data: UnitPopupData) {
    super();
  }

  ngOnInit(): void {
    this.selectedUnitOption.setValue(this.data.currentValue, { emitEvent: false });
  }
}

@NgModule({
  declarations: [UnitPopupComponent],
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    CommonModule,
    ReactiveFormsModule,
    ParseUnitModule,
  ],
})
export class UnitPopupModule {}
