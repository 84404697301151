import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { debounceTime, filter, Subject } from 'rxjs';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCommonModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { DEFAULT_DEBOUNCE_TIME } from '@core/constants/consts';

import { AutoFocusModule } from '../directives/autofocus.directive';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
  faBarcode = faBarcode;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() value = '';

  @Input() showBarcodeIcon = true;
  @Output() searchEvent = new EventEmitter<string>();
  @Output() barcodeClick = new EventEmitter<string>();

  private debounceSubject = new Subject<string>();
  private isBarcodeClickedRecently = false;

  ngOnInit(): void {
    this.initDebounceKeyUp();
  }

  applyChange(value: string): void {
    this.value = value;
    this.debounceSubject.next(this.value);
  }

  onBarcodeClicked(): void {
    this.barcodeClick.emit(this.value);
    this.isBarcodeClickedRecently = true;

    setTimeout(() => {
      this.isBarcodeClickedRecently = false;
    }, DEFAULT_DEBOUNCE_TIME);
  }

  private initDebounceKeyUp(): void {
    this.debounceSubject
      .pipe(
        debounceTime(DEFAULT_DEBOUNCE_TIME),
        filter(() => !this.isBarcodeClickedRecently),
      )
      .subscribe((value) => this.searchEvent.emit(value));
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatCommonModule,
    FontAwesomeModule,
    AutoFocusModule,
  ],
  declarations: [SearchInputComponent],
  exports: [SearchInputComponent],
})
export class SearchInputModule {}
