import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgModule,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from '@core/constants/consts';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

import { AdminSettingsUser } from '../models/admin-settings-user.model';

@Component({
  selector: 'app-admin-settings-new-password-popup',
  templateUrl: './admin-settings-new-password-popup.component.html',
  styleUrls: ['./admin-settings-new-password-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSettingsNewPasswordPopupComponent extends Destroyable(Object) implements OnInit {
  readonly passwordMinLength = PASSWORD_MIN_LENGTH;
  hidePassword = true;
  passwordForm = new UntypedFormGroup({
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(PASSWORD_MIN_LENGTH),
      Validators.pattern(PASSWORD_REGEX),
    ]),
  });

  get passwordControl(): UntypedFormControl {
    return this.passwordForm.get('password') as UntypedFormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<AdminSettingsNewPasswordPopupComponent>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: AdminSettingsUser,
  ) {
    super();
  }

  ngOnInit(): void {
    this.passwordForm.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((_) => {
      this.cd.detectChanges();
    });
  }

  submit(): void {
    this.dialogRef.close(this.passwordControl.value);
  }

  getPasswordErrorMessage(): string {
    if (this.passwordControl.hasError('required')) {
      return 'You must enter a value';
    }

    if (this.passwordControl.hasError('minlength')) {
      return `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`;
    }

    if (this.passwordControl.hasError('pattern')) {
      return 'Password must have at least one digit (0-9)';
    }

    return '';
  }
}

@NgModule({
  declarations: [AdminSettingsNewPasswordPopupComponent],
  imports: [
    FontAwesomeModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [AdminSettingsNewPasswordPopupComponent],
})
export class AdminSettingsNewPasswordPopupModule {}
