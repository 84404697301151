import { combineLatest } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgModule,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { IdName } from '@core/models/id-name.model';
import { LocationTreeViewNode } from '@core/models/location-tree-view-node.model';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { LocationFormField } from 'src/app/features/location/form/location-form-field.enum';
import { LOCATION_FORM_FIELD_NAME_TO_DISPLAY } from 'src/app/features/location/form/location-form-field-name-to-display.config';

import { LocationRestrictionService } from '../../features/location/services/location-restriction.service';
import { LocationTypeService } from '../../features/location/services/location-type.service';
import { MultiSelectModule } from '../multi-select/multi-select.component';
import { TreeViewFilter } from '../tree-view-selector-popup-field/models/tree-view-filter.model';
import { TREE_VIEW_FILTER } from '../tree-view-selector-popup-field/tokens/tree-view-filter.token';
import { FilterEventService } from './services/filter-event.service';

@Component({
  selector: 'app-location-tree-filter',
  templateUrl: './location-tree-filter.component.html',
  styleUrls: ['./location-tree-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationTreeFilterComponent extends Destroyable(Object) implements OnInit {
  readonly locationTypesFieldName = 'locationTypes';
  readonly locationRestrictionsFieldName = 'locationRestrictions';
  readonly locationFormField = LocationFormField;
  readonly locationFormFieldNameToDisplay = LOCATION_FORM_FIELD_NAME_TO_DISPLAY;
  private noRestrictionValue: IdName = {
    id: '',
    name: 'No restriction',
  };

  private previousRestrictions = new Array<string>();

  allowedLocationTypes: string[] = [];
  allowedLocationRestrictions: string[] = [];

  formGroup!: UntypedFormGroup;

  locationTypes!: IdName[];
  locationRestrictions!: IdName[];

  get locationTypeIdFormControl(): FormControl {
    return this.formGroup.get(LocationFormField.LOCATION_TYPE_ID) as FormControl<string>;
  }

  get locationRestrictionIdFormControl(): FormControl {
    return this.formGroup.get(LocationFormField.LOCATION_RESTRICTION_IDS) as FormControl<
      Array<string>
    >;
  }

  constructor(
    private locationTypeService: LocationTypeService,
    private locationRestrictionService: LocationRestrictionService,
    @Inject(TREE_VIEW_FILTER) private treeViewFilter: TreeViewFilter,
    private cd: ChangeDetectorRef,
    private filterEventService: FilterEventService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getFilterDataOptions();
    this.treeViewFilter.filter = this.filter.bind(this);
    this.filterEventService.filterPopupClosed.subscribe((_: boolean) => {
      if (_) {
        this.applyRestrictionsAndTypesToFilter();
      }
    });
  }

  onOptionSelection(): void {
    const selectedOptions = this.locationRestrictionIdFormControl.value;

    if (
      selectedOptions.includes(this.noRestrictionValue.name) &&
      this.previousRestrictions.length &&
      !this.previousRestrictions.includes(this.noRestrictionValue.name as string)
    ) {
      this.locationRestrictionIdFormControl.setValue([this.noRestrictionValue.name]);
    } else {
      const filteredOptions = selectedOptions.filter(
        (option: string) => option !== this.noRestrictionValue.name,
      );
      this.locationRestrictionIdFormControl.setValue(
        filteredOptions.length ? filteredOptions : ['No restriction'],
      );
    }

    this.previousRestrictions = this.locationRestrictionIdFormControl.value;
  }

  private filter(node: LocationTreeViewNode): boolean {
    let isFiltrationPassed = true;

    if (this.allowedLocationTypes.length) {
      isFiltrationPassed = this.allowedLocationTypes.includes(node.type);
    }

    if (isFiltrationPassed && this.allowedLocationRestrictions.length) {
      isFiltrationPassed = !!this.allowedLocationRestrictions.filter((allowedRestriction) =>
        node.restrictions.includes(allowedRestriction),
      ).length;
    }

    return isFiltrationPassed;
  }

  private getFilterDataOptions(): void {
    combineLatest([this.locationTypeService.getAll(), this.locationRestrictionService.getAll()])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([locationTypes, locationRestrictions]) => {
        this.locationTypes = locationTypes;
        this.locationRestrictions = locationRestrictions;
        if (!this.locationRestrictions.some((e) => e.name === this.noRestrictionValue.name)) {
          this.locationRestrictions.push(this.noRestrictionValue);
        }
        this.buildForm();
        this.listenToFormChanges();
        this.cd.markForCheck();
      });
  }

  private buildForm(): void {
    this.formGroup = new UntypedFormGroup({
      [LocationFormField.LOCATION_TYPE_ID]: new UntypedFormControl(
        this.filterEventService.pickedLocationTypes.length
          ? [...this.filterEventService.pickedLocationTypes]
          : [],
        [Validators.required],
      ),
      [LocationFormField.LOCATION_RESTRICTION_IDS]: new UntypedFormControl(
        this.filterEventService.pickedLocationRestrictions.length
          ? [...this.filterEventService.pickedLocationRestrictions]
          : [],
        [Validators.required],
      ),
    });
  }

  private listenToFormChanges(): void {
    this.listenToTypesChanges();
    this.listenToRestrictionsChanges();
  }

  private listenToTypesChanges(): void {
    this.formGroup
      .get(LocationFormField.LOCATION_TYPE_ID)!
      .valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.filterEventService.pickedLocationTypes = this.locationTypeIdFormControl.getRawValue();
      });
  }

  private listenToRestrictionsChanges(): void {
    this.formGroup
      .get(LocationFormField.LOCATION_RESTRICTION_IDS)!
      .valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.filterEventService.pickedLocationRestrictions =
          this.locationRestrictionIdFormControl.getRawValue();
      });
  }

  private applyRestrictionsAndTypesToFilter(): void {
    if (this.filterEventService.pickedLocationRestrictions.length) {
      this.allowedLocationRestrictions = this.filterEventService.pickedLocationRestrictions;
    }

    if (this.filterEventService.pickedLocationTypes.length) {
      this.allowedLocationTypes = this.filterEventService.pickedLocationTypes;
    }

    this.emitFilterChange();
  }

  private emitFilterChange(): void {
    const isFilterApplied =
      !!this.allowedLocationTypes.length || !!this.allowedLocationRestrictions.length;
    this.treeViewFilter.filterChanged$.next({ isFilterApplied });
  }
}

@NgModule({
  declarations: [LocationTreeFilterComponent],
  exports: [LocationTreeFilterComponent],
  imports: [CommonModule, MultiSelectModule, ReactiveFormsModule, FormsModule],
})
export class LocationTreeFilterModule {}
