<div class="top-btns" [class.top-btns__minimized]="!opened">
  <button
    mat-button
    class="sidenav-button"
    [class.minimized]="!opened"
    routerLink="/home"
    id="home-btn"
  >
    <mat-icon fontSet="fas" fontIcon="fa-house"></mat-icon>
    <span *ngIf="opened" class="sidenav-span">Home</span>
  </button>

  <button
    mat-icon-button
    [class.sidenav-button-menu]="!opened"
    [class.sidenav-button-close]="opened"
    (click)="toggle()"
    class="menu-toggle-icon"
    id="menu-toggle-btn"
  >
    <mat-icon *ngIf="!opened" class="sidenav-icon">menu</mat-icon>
    <mat-icon *ngIf="opened" class="sidenav-icon opened">close</mat-icon>
  </button>
</div>

<ng-container [ngSwitch]="opened">
  <img *ngSwitchCase="true" [src]="fullLogo" class="sidenav-full-logo" />
  <img *ngSwitchCase="false" [src]="smallLogo" class="sidenav-small-logo" />
</ng-container>
<mat-divider class="sidenav-divider"></mat-divider>

<button
  *ngIf="isDashboardApp"
  mat-button
  class="sidenav-button"
  [class.minimized]="!opened"
  [routerLink]="routerAppPrefix + '/dashboard'"
  routerLinkActive="active"
  id="dashboard-btn"
>
  <mat-icon class="sidenav-icon">dashboard</mat-icon>
  <div class="notifications-counter" *ngIf="notificationsCount">
    <div class="circle">
      <span class="counter">{{ notificationsCount }}</span>
    </div>
  </div>
  <span *ngIf="opened" class="sidenav-span">Dashboard</span>
</button>

<ng-container *ngIf="isDashboardApp || isAcmeTrackerApp">
  <mat-accordion *ngIf="opened">
    <mat-expansion-panel [expanded]="true" routerLinkActive="active-parent">
      <mat-expansion-panel-header class="{{ opened ? 'opened-sidebar' : 'closed-sidebar' }}">
        <mat-panel-title>
          <mat-icon class="sidenav-icon">search</mat-icon>
          <span class="accordion-title">Data access</span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <button
        mat-button
        class="sidenav-button sidenav-button-nested"
        [routerLink]="routerAppPrefix + '/data-access/sample'"
        routerLinkActive="active"
        id="sample-btn"
      >
        <span class="sidenav-span">Sample</span>
      </button>

      <button
        mat-button
        class="sidenav-button sidenav-button-nested"
        [routerLink]="routerAppPrefix + '/data-access/location'"
        routerLinkActive="active"
        id="location-btn"
      >
        <span class="sidenav-span">Location</span>
      </button>

      <button
        mat-button
        class="sidenav-button sidenav-button-nested"
        [routerLink]="routerAppPrefix + '/data-access/well'"
        routerLinkActive="active"
        id="well-btn"
      >
        <span class="sidenav-span">Well</span>
      </button>
    </mat-expansion-panel>
  </mat-accordion>

  <button
    mat-button
    *ngIf="!opened"
    class="sidenav-button minimized"
    routerLinkActive="active"
    (click)="toggle()"
    id="data-access-btn"
  >
    <mat-icon class="sidenav-icon">search</mat-icon>
    <a [routerLink]="routerAppPrefix + '/data-access/sample'"></a>
    <a [routerLink]="routerAppPrefix + '/data-access/location'"></a>
    <a [routerLink]="routerAppPrefix + '/data-access/well'"></a>
  </button>
</ng-container>

<ng-container *ngIf="isAcmeTrackerApp">
  <mat-accordion *ngIf="opened">
    <mat-expansion-panel [expanded]="true" routerLinkActive="active-parent">
      <mat-expansion-panel-header class="{{ opened ? 'opened-sidebar' : 'closed-sidebar' }}">
        <mat-panel-title>
          <mat-icon class="sidenav-icon">format_indent_increase</mat-icon>
          <span class="accordion-title"> Sample check-in </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <button
        mat-button
        class="sidenav-button sidenav-button-nested"
        [routerLink]="routerAppPrefix + '/sample-check-in/plug'"
        routerLinkActive="active"
        id="plug-btn"
      >
        <span *ngIf="opened" class="sidenav-span">Plug</span>
      </button>
      <button
        mat-button
        class="sidenav-button sidenav-button-nested"
        [routerLink]="routerAppPrefix + '/sample-check-in/core'"
        routerLinkActive="active"
        id="core-btn"
      >
        <span *ngIf="opened" class="sidenav-span">Core</span>
      </button>
      <button
        mat-button
        class="sidenav-button sidenav-button-nested"
        [routerLink]="routerAppPrefix + '/sample-check-in/cuttings'"
        routerLinkActive="active"
        id="cuttings-btn"
      >
        <span *ngIf="opened" class="sidenav-span">Cuttings</span>
      </button>
      <button
        mat-button
        class="sidenav-button sidenav-button-nested"
        [routerLink]="routerAppPrefix + '/sample-check-in/fluid'"
        routerLinkActive="active"
        id="fluid-btn"
      >
        <span *ngIf="opened" class="sidenav-span">Fluid</span>
      </button>
      <button
        mat-button
        class="sidenav-button sidenav-button-nested"
        [routerLink]="routerAppPrefix + '/sample-check-in/thinSection'"
        routerLinkActive="active"
        id="thinsection-btn"
      >
        <span *ngIf="opened" class="sidenav-span">Thin Section</span>
      </button>
      <button
        mat-button
        class="sidenav-button sidenav-button-nested"
        [routerLink]="routerAppPrefix + '/sample-check-in/uncategorized'"
        routerLinkActive="active"
        id="uncategorized-btn"
      >
        <span *ngIf="opened" class="sidenav-span">Uncategorized</span>
      </button>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion *ngIf="opened">
    <mat-expansion-panel [expanded]="true" routerLinkActive="active-parent">
      <mat-expansion-panel-header class="{{ opened ? 'opened-sidebar' : 'closed-sidebar' }}">
        <mat-panel-title>
          <mat-icon class="sidenav-icon">format_indent_increase</mat-icon>
          <span class="accordion-title"> Simulation </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <button
        mat-button
        class="sidenav-button sidenav-button-nested"
        [routerLink]="routerAppPrefix + '/simulation/micp'"
        routerLinkActive="active"
        id="micp-btn"
      >
        <span *ngIf="opened" class="sidenav-span">MICP</span>
      </button>

      <button
        mat-button
        class="sidenav-button sidenav-button-nested"
        [routerLink]="routerAppPrefix + '/simulation/ptsd'"
        routerLinkActive="active"
        id="ptsd-btn"
      >
        <span *ngIf="opened" class="sidenav-span">PTSD</span>
      </button>
    </mat-expansion-panel>
  </mat-accordion>

  <button
    mat-button
    *ngIf="!opened"
    class="sidenav-button minimized"
    routerLinkActive="active"
    (click)="toggle()"
    id="check-in-btn"
  >
    <mat-icon class="sidenav-icon">format_indent_increase</mat-icon>
    <a [routerLink]="routerAppPrefix + '/sample-check-in/plug'"></a>
    <a [routerLink]="routerAppPrefix + '/sample-check-in/core'"></a>
    <a [routerLink]="routerAppPrefix + '/sample-check-in/cuttings'"></a>
    <a [routerLink]="routerAppPrefix + '/sample-check-in/fluid'"></a>
    <a [routerLink]="routerAppPrefix + '/sample-check-in/uncategorized'"></a>
    <a [routerLink]="routerAppPrefix + '/sample-check-in/thinSection'"></a>
  </button>

  <button
    mat-button
    class="sidenav-button"
    [class.minimized]="!opened"
    [routerLink]="routerAppPrefix + '/sample/check-out'"
    routerLinkActive="active"
    id="check-out-btn"
  >
    <mat-icon class="sidenav-icon">person</mat-icon>
    <span *ngIf="opened" class="sidenav-span">Sample Check-out</span>
  </button>

  <button
    mat-button
    class="sidenav-button"
    [class.minimized]="!opened"
    [routerLink]="routerAppPrefix + '/sample/return'"
    routerLinkActive="active"
    id="return-btn"
  >
    <mat-icon class="sidenav-icon">person</mat-icon>
    <span *ngIf="opened" class="sidenav-span">Sample Return</span>
  </button>

  <button
    mat-button
    class="sidenav-button"
    [class.minimized]="!opened"
    [routerLink]="routerAppPrefix + '/work-order'"
    routerLinkActive="active"
    id="work-order-btn"
  >
    <mat-icon class="sidenav-icon">file_copy</mat-icon>
    <span *ngIf="opened" class="sidenav-span">Work order</span>
  </button>

  <button
    mat-button
    class="sidenav-button"
    [class.minimized]="!opened"
    [routerLink]="routerAppPrefix + '/data-uploader'"
    routerLinkActive="active"
    id="data-uploader-btn"
  >
    <mat-icon fontSet="fas" fontIcon="fa-cloud-arrow-up"></mat-icon>
    <span *ngIf="opened" class="sidenav-span">Data Uploader</span>
  </button>

  <button
    mat-button
    class="sidenav-button"
    [class.minimized]="!opened"
    [routerLink]="routerAppPrefix + '/complex-data-uploader'"
    routerLinkActive="active"
    id="complex-data-uploader-btn"
  >
    <mat-icon fontSet="fas" fontIcon="fa-cloud-arrow-up"></mat-icon>
    <span *ngIf="opened" class="sidenav-span">Complex Data Uploader</span>
  </button>
</ng-container>

<ng-container *ngIf="isCoreAnalysisWizardApp">
  <button
    mat-button
    class="sidenav-button"
    [class.minimized]="!opened"
    [routerLink]="routerAppPrefix + '/core-analysis-wizard'"
    routerLinkActive="active"
    id="core-analysis-wizard-btn"
  >
    <mat-icon fontSet="fa" fontIcon="fa-wand-magic-sparkles"></mat-icon>
    <span *ngIf="opened" class="sidenav-span">Core Analysis Wizard</span>
  </button>
</ng-container>

<span class="sidenav-spacer"></span>

<button
  *ngIf="isAdmin"
  mat-button
  class="sidenav-button"
  [class.minimized]="!opened"
  [routerLink]="routerAppPrefix + '/admin-settings'"
  routerLinkActive="active"
  id="admin-settings-btn"
>
  <mat-icon class="sidenav-icon">person</mat-icon>
  <span *ngIf="opened" class="sidenav-span">Admin settings</span>
</button>

<button
  *ngIf="isAdmin"
  mat-button
  class="sidenav-button"
  [class.minimized]="!opened"
  [routerLink]="routerAppPrefix + '/organization-settings'"
  routerLinkActive="active"
  id="organization-settings-btn"
>
  <mat-icon class="sidenav-icon">person</mat-icon>
  <span *ngIf="opened" class="sidenav-span">Organization settings</span>
</button>

<button
  mat-button
  class="sidenav-button"
  [class.minimized]="!opened"
  [routerLink]="routerAppPrefix + '/account-settings'"
  routerLinkActive="active"
  id="user-email-btn"
>
  <mat-icon fontSet="fa" fontIcon="fa-address-card"></mat-icon>
  <span *ngIf="opened" class="sidenav-span">{{ userEmail }} </span>
</button>

<button
  mat-button
  class="sidenav-button"
  [class.minimized]="!opened"
  (click)="logout()"
  id="logout-btn"
>
  <mat-icon class="sidenav-icon">logout</mat-icon>
  <span *ngIf="opened" class="sidenav-span">Log out</span>
</button>
