<div class="left-panel" [style.background-image]="backgroundImage">
  <h1 class="left-h1">{{ appName }}</h1>
  <h2 class="left-h2">{{ appDescription }}</h2>
</div>
<div class="right-panel">
  <img [src]="fullLogo" class="logo" />
  <div class="login-form" (keydown)="enterSubmit($event)">
    <h3 class="right-h3">{{ pageName }}</h3>
    <h4 class="right-h4">Welcome to {{ appName }}</h4>
    <h5 class="right-h5">{{ appSlogan }}</h5>

    <ng-container *ngIf="!isOktaAuthUsed">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput [formControl]="emailAddress" id="email-input" />
        <mat-error *ngIf="emailAddress.invalid">{{ getEmailAddressErrorMessage() }}</mat-error>
      </mat-form-field>

      <mat-form-field
        *ngIf="showPasswordField"
        appearance="outline"
        [hintLabel]="enforcePasswordPolicy ? 'Min ' + passwordMinLength + ' characters' : ''"
      >
        <mat-label>Password</mat-label>
        <input
          matInput
          [formControl]="password"
          [type]="hidePassword ? 'password' : 'text'"
          id="password-input"
        />
        <button
          mat-icon-button
          matSuffix
          (click)="hidePassword = !hidePassword"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hidePassword"
          id="hide-password-btn"
        >
          <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-hint align="end">{{ password.value?.length || 0 }}</mat-hint>
        <mat-error *ngIf="password.invalid"> {{ getPasswordErrorMessage() }}</mat-error>
      </mat-form-field>

      <mat-checkbox *ngIf="showRememberMeCheckbox" [formControl]="rememberMe">
        Remember me
      </mat-checkbox>
    </ng-container>

    <button
      class="btn btn-primary btn-big login-btn"
      id="login-btn"
      type="button"
      color="primary"
      (click)="onButtonClick()"
      [disabled]="
        !isOktaAuthUsed && (emailAddress.invalid || (showPasswordField && password.invalid))
      "
    >
      {{ buttonLabel }}
    </button>
    <div class="actions">
      <ng-content></ng-content>
    </div>
  </div>
  <span class="spacer"></span>
  <footer class="footer">{{ footerText }}</footer>
</div>
