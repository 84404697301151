import { ChangeDetectionStrategy, Component, HostBinding, Input, NgModule } from '@angular/core';

@Component({
  selector: 'app-work-order-logical-block',
  templateUrl: './work-order-logical-block.component.html',
  styleUrls: ['./work-order-logical-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkOrderLogicalBlockComponent {
  @Input() title = '';
  @HostBinding('attr.title') _title = '';
}

@NgModule({
  declarations: [WorkOrderLogicalBlockComponent],
  exports: [WorkOrderLogicalBlockComponent],
})
export class WorkOrderLogicalBlockModule {}
