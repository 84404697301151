import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ComplexDataDigitalVolumeModule } from './complex-data-digital-volume/complex-data-digital-volume.component';
import { ComplexDataThinSectionModule } from './complex-data-thin-section/complex-data-thin-section.component';

@Component({
  selector: 'app-complex-data-uploader',
  templateUrl: './complex-data-uploader.component.html',
  styleUrls: ['./complex-data-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComplexDataUploaderComponent {
  selectedTab = new FormControl(0);
}

@NgModule({
  declarations: [ComplexDataUploaderComponent],
  exports: [ComplexDataUploaderComponent],
  imports: [
    MatTabsModule,
    MatProgressSpinnerModule,
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonToggleModule,
    ComplexDataDigitalVolumeModule,
    ComplexDataThinSectionModule,
  ],
})
export class ComplexDataUploaderModule {}
