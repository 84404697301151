<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    appAutofocusElement
    [placeholder]="placeholder"
    [ngModel]="value"
    (ngModelChange)="applyChange($event)"
    id="search-input"
  />
  <fa-icon
    *ngIf="showBarcodeIcon"
    [icon]="faBarcode"
    (click)="onBarcodeClicked()"
    id="barcode-btn"
  ></fa-icon>
</mat-form-field>
