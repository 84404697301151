import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';

import { UnitInputField } from '../enums/unit-input-field.enum';

export function pierceUnitInputValidator(validatorFn: ValidatorFn): ValidatorFn {
  return (c: AbstractControl) => {
    if (typeof c.value === 'object') {
      const control = new UntypedFormControl(c.value?.[UnitInputField.FIELD_VALUE]);
      return validatorFn(control);
    }

    return validatorFn(c);
  };
}
