import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgModule,
  OnInit,
  Output,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import {
  EMAIL_REGEX,
  FOOTER_TEXT,
  PASSWORD_MIN_LENGTH,
  PASSWORD_REGEX,
} from '@core/constants/consts';
import { USE_OKTA_AUTH_TOKEN } from '@core/tokens/use-okta-auth.token';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { OrganizationService } from 'src/app/services/api/organization.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomePageComponent extends Destroyable(Object) implements OnInit {
  readonly passwordMinLength = PASSWORD_MIN_LENGTH;
  readonly emailAddressLocalStorageKey = 'userEmailAddress';
  readonly footerText = FOOTER_TEXT;

  @Input() buttonLabel = '';
  @Input() enforcePasswordPolicy = false;
  @Input() pageName = '';
  @Input() showPasswordField = true;
  @Input() showRememberMeCheckbox = true;
  @Output() buttonClicked = new EventEmitter<[string, string | null]>();

  appName = '';
  appDescription = '';
  appSlogan = '';
  backgroundImage = '';
  fullLogo = '';
  emailAddress = new UntypedFormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]);
  password = new UntypedFormControl('', [Validators.required]);

  hidePassword = true;
  rememberMe = new UntypedFormControl(false);

  get isOktaAuthUsed(): boolean {
    return this.useOktaAuth;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private organizationService: OrganizationService,
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initializeButtonLabel();
    this.initializePasswordValidators();
    this.initializeOrganizationInfo();
    this.initializeUserInfo();
    this.organizationService.currentOrganizationDataChangedSubject.subscribe((_) =>
      this.initializeOrganizationInfo(),
    );

    this.changeDetectorRef.detectChanges();
  }

  getEmailAddressErrorMessage(): string {
    if (this.emailAddress.hasError('required')) {
      return 'You must enter a value';
    }

    return this.emailAddress.hasError('pattern') ? 'Not a valid email' : '';
  }

  getPasswordErrorMessage(): string {
    if (this.password.hasError('required')) {
      return 'You must enter a value';
    }

    if (this.password.hasError('minlength')) {
      return `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`;
    }

    if (this.password.hasError('pattern')) {
      return 'Password must have at least one digit (0-9)';
    }

    return '';
  }

  onButtonClick(): void {
    if (!this.isOktaAuthUsed) {
      if (this.showRememberMeCheckbox) {
        if (this.rememberMe.value) {
          localStorage.setItem(
            this.emailAddressLocalStorageKey,
            JSON.stringify(this.emailAddress.value),
          );
        } else {
          localStorage.removeItem(this.emailAddressLocalStorageKey);
        }
      }
    }

    this.buttonClicked.emit([
      this.emailAddress.value,
      this.showPasswordField ? this.password.value : null,
    ]);
  }

  enterSubmit(event: any): void {
    if (event.keyCode === 13) this.onButtonClick();
  }

  private initializeButtonLabel(): void {
    if (!this.buttonLabel && this.pageName) {
      this.buttonLabel = this.pageName;
    }
  }

  private initializePasswordValidators(): void {
    if (this.enforcePasswordPolicy) {
      this.password.addValidators([
        Validators.minLength(PASSWORD_MIN_LENGTH),
        Validators.pattern(PASSWORD_REGEX),
      ]);
    }
  }

  private initializeOrganizationInfo(): void {
    if (this.organizationService.currentOrganizationData) {
      this.appName = this.organizationService.currentOrganizationData.appName;
      this.appDescription = this.organizationService.currentOrganizationData.appDescription;
      this.appSlogan = this.organizationService.currentOrganizationData.appSlogan;
      this.backgroundImage = `url("${this.organizationService.currentOrganizationData.landingPageBackgroundImagePath}")`;
      this.fullLogo =
        this.organizationService.currentOrganizationData.organizationFullIconImagePath;
      this.changeDetectorRef.detectChanges();
    }
  }

  private initializeUserInfo(): void {
    if (this.showRememberMeCheckbox) {
      const storedEmailAddress = localStorage.getItem(this.emailAddressLocalStorageKey);
      if (storedEmailAddress) {
        this.emailAddress.setValue(JSON.parse(storedEmailAddress));
      }
    }
  }
}

@NgModule({
  declarations: [WelcomePageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [WelcomePageComponent],
})
export class WelcomePageModule {}
