import { DigitalVolumeInformation } from '../models/digital-volume-model';
import { ThinSectionImage } from '../models/thin-section-model';

export const thinSectionImageMock: ThinSectionImage = {
  file: null,
  fileName: '',
  angle: { fieldValue: null, unitId: null },
  polarizationId: null,
  pixelSize: { fieldValue: null, unitId: null },
  rotation: null,
};

export const digitalVolumeImageMock: DigitalVolumeInformation = {
  image: '',
  verify: false,
  sampleFileName: '',
  sampleName: 'Name1',
  depth: { fieldValue: '1', unitId: '1' },
  sampleType: '1',
  subSampleNumber: '1233453',
  volumeType: '1',
  centerOffsetX: 12,
  centerOffsetY: 23,
  centerOffsetZ: 12,
  centerOffsetUnitId: '1',
  voxelSizeX: 12,
  voxelSizeY: 23,
  voxelSizeZ: 12,
  voxelSizeUnitId: '1',
  gridSizeX: 34,
  gridSizeY: 12,
  gridSizeZ: 43,
  dataFormat: '1',
  sectionType: '1',
  sectionSize: 100,
  orientation: '1',
};
