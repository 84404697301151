<div class="table-wrapper">
  <table mat-table matSort [dataSource]="sampleAccess" class="table custom-table">
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
      <td mat-cell *matCellDef="let row">{{ row.user }}</td>
    </ng-container>
    <ng-container matColumnDef="grantDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Grant date</th>
      <td mat-cell *matCellDef="let row">{{ row.grantDate | date: 'mediumDate' }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
      <td mat-cell *matCellDef="let row">
        <button
          *ngIf="row.status === sampleAccessAction.APPROVE"
          [disabled]="!isOwner || isReadOnly"
          class="btn btn-success btn-small"
          (click)="openDialog(row, sampleAccessAction.REVOKE)"
          id="sample-access-revoke-btn"
        >
          Revoke
        </button>
        <button
          *ngIf="
            row.status === sampleAccessAction.PENDING ||
            row.status === sampleAccessAction.REJECT ||
            row.status === sampleAccessAction.REVOKE
          "
          [disabled]="!isOwner || isReadOnly"
          class="btn btn-success btn-small"
          (click)="openDialog(row, sampleAccessAction.APPROVE)"
          id="sample-access-grant-btn"
        >
          Grant
        </button>
        <button
          *ngIf="row.status === sampleAccessAction.PENDING"
          [disabled]="!isOwner || isReadOnly"
          class="btn btn-success btn-small"
          (click)="openDialog(row, sampleAccessAction.REJECT)"
          id="sample-access-deny-btn"
        >
          Deny
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.access]="row.stateName === isOwner && !isReadOnly"
    ></tr>
    <tr *matNoDataRow class="mat-row table-row">
      <td colspan="7" class="mat-cell">
        <p>No access information.</p>
      </td>
    </tr>
  </table>
</div>
