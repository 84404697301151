import { filter } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NgControl,
  ReactiveFormsModule,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { Disableable } from '@core/utils/mixins/disableable.mixin';
import { WellSearch } from 'src/app/features/data-access/lookup-well/models/well-search.model';

import { WellsService } from '../../services/api/wells.service';
import { DisabledElementModule } from '../directives/disabled-element.directive';
import { StopPropagationOnClickModule } from '../directives/stop-propagation-on-click.directive';
import { WellDisplayNameModule, WellDisplayNamePipe } from './pipes/well-display-name.pipe';
import { WellTableSelectorPopupComponent } from './well-table-selector-popup/well-table-selector-popup.component';

@Component({
  selector: 'app-well-table-selector-popup-field',
  templateUrl: './well-table-selector-popup-field.component.html',
  styleUrls: ['./well-table-selector-popup-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WellDisplayNamePipe],
})
export class WellTableSelectorPopupFieldComponent
  extends Disableable(Destroyable(Object))
  implements ControlValueAccessor, OnInit
{
  @Input() label!: string;
  @Input() getErrorMessage: () => string = () => '';

  control = new UntypedFormControl();
  selectedWell: WellSearch | null = null;

  wellNameDisplayFn = (): string => this.selectedWell?.name || '';
  onTouched!: (value: any) => void;
  onChange!: (value: any) => void;

  constructor(
    private ngControl: NgControl,
    private matDialog: MatDialog,
    private cd: ChangeDetectorRef,
    private wellsService: WellsService,
  ) {
    super();
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.setValidators();
    this.setUpNgControl();
  }

  clearField(): void {
    this.control.setValue(null);
    this.selectedWell = null;
  }

  openWellTableSelectorPopup(): void {
    this.matDialog
      .open<WellTableSelectorPopupComponent, any, WellSearch | null>(
        WellTableSelectorPopupComponent,
        {
          minWidth: 1000,
          minHeight: 500,
          height: '80%',
          autoFocus: false,
          restoreFocus: false,
        },
      )
      .afterClosed()
      .pipe(
        filter((selectedWell) => !!selectedWell),
        this.takeUntilDestroyed(),
      )
      .subscribe((selectedWell) => {
        this.control.setValue(selectedWell!.id);
        this.selectedWell = selectedWell!;
      });
  }

  writeValue(val: string): void {
    this.defineSelectedWell(val);
    this.control.setValue(val, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private setValidators(): void {
    const validators = this.ngControl.control?.validator
      ? this.ngControl.control?.validator
      : Validators.nullValidator;
    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }

  private setUpNgControl(): void {
    this.ngControl.control!.markAsTouched = () => {
      this.control.markAsTouched();
      this.cd.markForCheck();
    };
    this.ngControl.control!.reset = (value, options) => {
      this.control.reset(value, options);
      this.selectedWell = null;
    };
  }

  private defineSelectedWell(val: string | null): void {
    if (!val) {
      this.selectedWell = null;
      return;
    }

    this.wellsService
      .getById(val)
      .pipe(this.takeUntilDestroyed())
      .subscribe((well) => {
        this.selectedWell = well;
        this.control.setValue(this.control.value, { emitEvent: false });
        this.cd.markForCheck();
      });
  }
}

@NgModule({
  declarations: [WellTableSelectorPopupFieldComponent],
  exports: [WellTableSelectorPopupFieldComponent],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    CommonModule,
    DisabledElementModule,
    StopPropagationOnClickModule,
    WellDisplayNameModule,
    MatDialogModule,
  ],
})
export class WellTableSelectorPopupFieldModule {}
