<div mat-dialog-title class="title">
  <h1>Set new password</h1>
</div>
<div mat-dialog-content>
  <form class="form" *ngIf="passwordForm" [formGroup]="passwordForm" autocomplete="off">
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        matInput
        [formControlName]="'password'"
        [type]="hidePassword ? 'password' : 'text'"
        id="password-input"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hidePassword = !hidePassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hidePassword"
        id="hide-password-btn"
      >
        <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-hint align="end"
        >{{ passwordControl.value?.length || 0 }}/{{ passwordMinLength }}</mat-hint
      >
      <mat-error *ngIf="passwordControl.invalid"> {{ getPasswordErrorMessage() }}</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="actions">
  <button
    mat-button
    [mat-dialog-close]="undefined"
    class="btn btn-secondary"
    id="new-password-cancel-filters-btn"
  >
    Cancel
  </button>
  <button
    mat-button
    [disabled]="!passwordForm.valid"
    (click)="submit()"
    cdkFocusInitial
    class="btn btn-primary"
    id="new-password-apply-filters-btn"
  >
    Confirm
  </button>
</div>
