import { IdName } from '@core/models/id-name.model';

import { Pcri } from '../../pcri-form/models/pcri.model';
import { CoreAnalysisWizardEnum } from '../core-analysis-wizard.enum';
import { CoreAnalysisWizardNode } from '../models/core-analysis-wizard-node.model';

export const SimulationTypeMocks: IdName[] = [
  {
    id: 'a831fe14-8f39-ed11-ae83-2818781ce845',
    name: 'Sample Experiments',
  },
  {
    id: 'd831fe14-8f39-ed11-ae83-2818781ce845',
    name: 'MICP simulation',
  },
  {
    id: 'p831fe14-8f39-ed11-ae83-2818781ce845',
    name: 'PTSD simulation',
  },
  {
    id: 'b831fe14-8f39-ed11-ae83-2818781ce845',
    name: 'PC RI',
  },
];

export const CoreAnalysisWizardMicpNodeMock: CoreAnalysisWizardNode[] = [
  {
    id: '3',
    name: 'MICP simulation',
    type: 'MICP LBM',
    parentId: null,
    numberOfGPU: '1',
  },
];

export const CoreAnalysisWizardPTSDNodeMock: CoreAnalysisWizardNode[] = [
  {
    id: '3',
    name: 'PTSD simulation',
    type: 'PTSD LBM',
    parentId: null,
    numberOfCPU: '1',
  },
];

export const CoreAnalysisWizardPcRiNodeMock: Pcri[] = [
  {
    [CoreAnalysisWizardEnum.ID]: '',
    [CoreAnalysisWizardEnum.PARENT_ID]: '',
    [CoreAnalysisWizardEnum.TITLE]: 'Soxhlet cleaning',
    [CoreAnalysisWizardEnum.CLEANING_METHOD]: '',
    [CoreAnalysisWizardEnum.EFFLUENT_COLOR]: '',
    [CoreAnalysisWizardEnum.NAME]: '',
    [CoreAnalysisWizardEnum.DESCRIPTION]: '',
    [CoreAnalysisWizardEnum.PROPONENT_EMAIL]: '',
    [CoreAnalysisWizardEnum.PROPONENT_NAME]: '',
    [CoreAnalysisWizardEnum.START_DATE]: '',
    [CoreAnalysisWizardEnum.EXPECT_DATE]: '',
    [CoreAnalysisWizardEnum.NOTE]: '',
    [CoreAnalysisWizardEnum.PROGRESS]: null,
    [CoreAnalysisWizardEnum.COMPLETION_DATE]: '',
    [CoreAnalysisWizardEnum.COMPLETED]: false,
    [CoreAnalysisWizardEnum.EXPERIMENT_FIELDS]: [],
    [CoreAnalysisWizardEnum.EXPERIMENT_TYPE_ID]: '',
  },
  {
    [CoreAnalysisWizardEnum.ID]: '',
    [CoreAnalysisWizardEnum.PARENT_ID]: '',
    [CoreAnalysisWizardEnum.TITLE]: 'Flow thru cleaning',
    [CoreAnalysisWizardEnum.EFFLUENT_COLOR]: '',
    [CoreAnalysisWizardEnum.NAME]: '',
    [CoreAnalysisWizardEnum.DESCRIPTION]: '',
    [CoreAnalysisWizardEnum.PROPONENT_EMAIL]: '',
    [CoreAnalysisWizardEnum.PROPONENT_NAME]: '',
    [CoreAnalysisWizardEnum.START_DATE]: '',
    [CoreAnalysisWizardEnum.EXPECT_DATE]: '',
    [CoreAnalysisWizardEnum.NOTE]: '',
    [CoreAnalysisWizardEnum.PROGRESS]: null,
    [CoreAnalysisWizardEnum.COMPLETION_DATE]: '',
    [CoreAnalysisWizardEnum.COMPLETED]: false,
    [CoreAnalysisWizardEnum.EXPERIMENT_FIELDS]: [],
    [CoreAnalysisWizardEnum.EXPERIMENT_TYPE_ID]: '',
  },
  {
    [CoreAnalysisWizardEnum.ID]: '',
    [CoreAnalysisWizardEnum.PARENT_ID]: '',
    [CoreAnalysisWizardEnum.TITLE]: 'Brine preparation and sample saturation',
    [CoreAnalysisWizardEnum.BRINE_CONDITION]: '',
    [CoreAnalysisWizardEnum.NOTE]: '',
    [CoreAnalysisWizardEnum.NAME]: '',
    [CoreAnalysisWizardEnum.DESCRIPTION]: '',
    [CoreAnalysisWizardEnum.PROPONENT_EMAIL]: '',
    [CoreAnalysisWizardEnum.PROPONENT_NAME]: '',
    [CoreAnalysisWizardEnum.START_DATE]: '',
    [CoreAnalysisWizardEnum.EXPECT_DATE]: '',
    [CoreAnalysisWizardEnum.PROGRESS]: null,
    [CoreAnalysisWizardEnum.COMPLETION_DATE]: '',
    [CoreAnalysisWizardEnum.COMPLETED]: false,
    [CoreAnalysisWizardEnum.EXPERIMENT_FIELDS]: [],
    [CoreAnalysisWizardEnum.EXPERIMENT_TYPE_ID]: '',
  },
  {
    [CoreAnalysisWizardEnum.ID]: '',
    [CoreAnalysisWizardEnum.PARENT_ID]: '',
    [CoreAnalysisWizardEnum.TITLE]: 'Establish initial condition',
    [CoreAnalysisWizardEnum.NOTE]: '',
    [CoreAnalysisWizardEnum.NAME]: '',
    [CoreAnalysisWizardEnum.DESCRIPTION]: '',
    [CoreAnalysisWizardEnum.PROPONENT_EMAIL]: '',
    [CoreAnalysisWizardEnum.PROPONENT_NAME]: '',
    [CoreAnalysisWizardEnum.START_DATE]: '',
    [CoreAnalysisWizardEnum.EXPECT_DATE]: '',
    [CoreAnalysisWizardEnum.PROGRESS]: null,
    [CoreAnalysisWizardEnum.COMPLETION_DATE]: '',
    [CoreAnalysisWizardEnum.COMPLETED]: false,
    [CoreAnalysisWizardEnum.EXPERIMENT_FIELDS]: [],
    [CoreAnalysisWizardEnum.EXPERIMENT_TYPE_ID]: '',
  },
  {
    [CoreAnalysisWizardEnum.ID]: '',
    [CoreAnalysisWizardEnum.PARENT_ID]: '',
    [CoreAnalysisWizardEnum.TITLE]: 'Measure FRF, m and Kw',
    [CoreAnalysisWizardEnum.FRF]: '',
    [CoreAnalysisWizardEnum.M]: '',
    [CoreAnalysisWizardEnum.KW]: '',
    [CoreAnalysisWizardEnum.NAME]: '',
    [CoreAnalysisWizardEnum.DESCRIPTION]: '',
    [CoreAnalysisWizardEnum.PROPONENT_EMAIL]: '',
    [CoreAnalysisWizardEnum.PROPONENT_NAME]: '',
    [CoreAnalysisWizardEnum.START_DATE]: '',
    [CoreAnalysisWizardEnum.EXPECT_DATE]: '',
    [CoreAnalysisWizardEnum.PROGRESS]: null,
    [CoreAnalysisWizardEnum.COMPLETION_DATE]: '',
    [CoreAnalysisWizardEnum.COMPLETED]: false,
    [CoreAnalysisWizardEnum.EXPERIMENT_FIELDS]: [],
    [CoreAnalysisWizardEnum.EXPERIMENT_TYPE_ID]: '',
  },
  {
    [CoreAnalysisWizardEnum.ID]: '',
    [CoreAnalysisWizardEnum.PARENT_ID]: '',
    [CoreAnalysisWizardEnum.TITLE]: 'PcRI drainage',
    [CoreAnalysisWizardEnum.PCRI]: '',
    [CoreAnalysisWizardEnum.SATURATION_POINT]: '',
    [CoreAnalysisWizardEnum.NOTE]: '',
    [CoreAnalysisWizardEnum.NAME]: '',
    [CoreAnalysisWizardEnum.DESCRIPTION]: '',
    [CoreAnalysisWizardEnum.PROPONENT_EMAIL]: '',
    [CoreAnalysisWizardEnum.PROPONENT_NAME]: '',
    [CoreAnalysisWizardEnum.START_DATE]: '',
    [CoreAnalysisWizardEnum.EXPECT_DATE]: '',
    [CoreAnalysisWizardEnum.PROGRESS]: null,
    [CoreAnalysisWizardEnum.COMPLETION_DATE]: '',
    [CoreAnalysisWizardEnum.COMPLETED]: false,
    [CoreAnalysisWizardEnum.EXPERIMENT_FIELDS]: [],
    [CoreAnalysisWizardEnum.EXPERIMENT_TYPE_ID]: '',
  },
  {
    [CoreAnalysisWizardEnum.ID]: '',
    [CoreAnalysisWizardEnum.PARENT_ID]: '',
    [CoreAnalysisWizardEnum.TITLE]: 'PcRI spontaneous imbibition',
    [CoreAnalysisWizardEnum.PCRI]: '',
    [CoreAnalysisWizardEnum.NOTE]: '',
    [CoreAnalysisWizardEnum.NAME]: '',
    [CoreAnalysisWizardEnum.DESCRIPTION]: '',
    [CoreAnalysisWizardEnum.PROPONENT_EMAIL]: '',
    [CoreAnalysisWizardEnum.PROPONENT_NAME]: '',
    [CoreAnalysisWizardEnum.START_DATE]: '',
    [CoreAnalysisWizardEnum.EXPECT_DATE]: '',
    [CoreAnalysisWizardEnum.PROGRESS]: null,
    [CoreAnalysisWizardEnum.COMPLETION_DATE]: '',
    [CoreAnalysisWizardEnum.COMPLETED]: false,
    [CoreAnalysisWizardEnum.EXPERIMENT_FIELDS]: [],
    [CoreAnalysisWizardEnum.EXPERIMENT_TYPE_ID]: '',
  },
  {
    [CoreAnalysisWizardEnum.ID]: '',
    [CoreAnalysisWizardEnum.PARENT_ID]: '',
    [CoreAnalysisWizardEnum.TITLE]: 'PcRI forced imbibition',
    [CoreAnalysisWizardEnum.PCRI]: '',
    [CoreAnalysisWizardEnum.NOTE]: '',
    [CoreAnalysisWizardEnum.NAME]: '',
    [CoreAnalysisWizardEnum.DESCRIPTION]: '',
    [CoreAnalysisWizardEnum.PROPONENT_EMAIL]: '',
    [CoreAnalysisWizardEnum.PROPONENT_NAME]: '',
    [CoreAnalysisWizardEnum.START_DATE]: '',
    [CoreAnalysisWizardEnum.EXPECT_DATE]: '',
    [CoreAnalysisWizardEnum.PROGRESS]: null,
    [CoreAnalysisWizardEnum.COMPLETION_DATE]: '',
    [CoreAnalysisWizardEnum.COMPLETED]: false,
    [CoreAnalysisWizardEnum.EXPERIMENT_FIELDS]: [],
    [CoreAnalysisWizardEnum.EXPERIMENT_TYPE_ID]: '',
  },
];
