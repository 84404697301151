import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrintBarcodeService {
  popup: Window | null = null;

  print(image: HTMLImageElement): void {
    if (this.popup) {
      this.popup.close();
    }

    this.popup = window.open('', 'Print Barcode', 'popup');
    this.popup!.document.body.appendChild(image);
    this.popup!.focus();
    this.popup!.print();
  }
}
