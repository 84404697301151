import {
  EMAIL_ERROR_MESSAGE,
  ErrorMessageConfig,
  MAX_LENGTH_ERROR_MESSAGE,
  NUMERIC_RANGE_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
} from '@core/utils/form/error-message.config';

import { dateLimitsIncorrect } from './date-limits.validator';

export const DATE_LIMITS_ERROR_MESSAGE: ErrorMessageConfig = {
  [dateLimitsIncorrect]: () => `Start date should be earlier or equal to expected delivery date`,
};

export const WORK_ORDER_ERROR_MESSAGE_CONFIG: ErrorMessageConfig = {
  ...REQUIRED_ERROR_MESSAGE,
  ...MAX_LENGTH_ERROR_MESSAGE,
  ...EMAIL_ERROR_MESSAGE,
  ...DATE_LIMITS_ERROR_MESSAGE,
  ...NUMERIC_RANGE_ERROR_MESSAGE,
};
