import {
  ErrorMessageConfig,
  MAX_LENGTH_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
} from '@core/utils/form/error-message.config';
import { barcodeOrQRCodeRequired } from 'src/app/features/location/form/qr-code-or-barcode-is-required.validator';

export const LOCATION_FORM_FIELD_ERROR_MESSAGE: ErrorMessageConfig = {
  ...REQUIRED_ERROR_MESSAGE,
  ...MAX_LENGTH_ERROR_MESSAGE,
  [barcodeOrQRCodeRequired]: () => 'Either barcode or qr code should not be empty',
};
