<div class="search-wrapper" [class.is-selector]="isSelector">
  <div class="search-by-wrapper">
    <mat-form-field>
      <mat-label>Search By</mat-label>
      <mat-select
        [(ngModel)]="locationFilters.locationFilterBy.filterType"
        (ngModelChange)="onSearchByChanged($event)"
        id="location-search-by-filter-select"
      >
        <mat-option *ngFor="let option of searchByOptions" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <app-search-input
    class="app-search-input"
    [label]="'Scan or type to search'"
    [placeholder]="'Location'"
    (searchEvent)="onSearch($event)"
    [value]="locationFilters.locationFilterBy.searchString"
    (barcodeClick)="searchByBarcode($event)"
    id="location-search-input"
  ></app-search-input>
  <span class="search-spacer"></span>
  <div class="filter-button-wrapper">
    <button
      class="btn btn-square btn-transparent"
      (click)="onFilterClick()"
      id="location-filter-btn"
    >
      <mat-icon>tune</mat-icon>
    </button>
  </div>
</div>

<div class="table-wrapper" [class.is-selector]="isSelector">
  <div class="spinner-container" *ngIf="dataSource.loadingCounter$ | async">
    <mat-spinner
      class="loading-icon"
      matSuffix
      [diameter]="150"
      [matTooltip]="'Loading...'"
    ></mat-spinner>
  </div>

  <table
    class="table custom-table"
    mat-table
    matSort
    [dataSource]="dataSource"
    [matSortActive]="locationFilters.locationFilterBy.sortColumnName"
    [matSortDirection]="locationFilters.locationFilterBy.sortDirection"
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let row">{{ row.type }}</td>
    </ng-container>
    <ng-container matColumnDef="restrictions">
      <th class="column-restrictions" mat-header-cell *matHeaderCellDef>Restrictions</th>
      <td class="column-restrictions" mat-cell *matCellDef="let row">
        {{ row.restrictions.join(', ') }}
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let row">{{ row.description }}</td>
    </ng-container>
    <ng-container matColumnDef="parentLocation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent Location</th>
      <td mat-cell *matCellDef="let row">{{ row.parentLocation }}</td>
    </ng-container>
    <ng-container matColumnDef="qrCodeId">
      <th class="column-actions" mat-header-cell *matHeaderCellDef mat-sort-header>QR Code</th>
      <td class="column-actions" mat-cell *matCellDef="let row">
        <button
          *ngIf="row.qrCodeId?.length"
          class="print-button"
          matTooltip="Print QR Code"
          (click)="printQRCode(row.qrCodeId, $event)"
          id="print-qr-btn"
        >
          <fa-icon [icon]="faQrcode"></fa-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="barcodeId">
      <th class="column-actions" mat-header-cell *matHeaderCellDef mat-sort-header>Barcode</th>
      <td class="column-actions" mat-cell *matCellDef="let row">
        <button
          *ngIf="row.barcodeId?.length"
          class="print-button"
          matTooltip="Print Barcode"
          (click)="printBarcode(row.barcodeId, $event)"
          id="print-barcode-btn"
        >
          <fa-icon [icon]="faBarcode"></fa-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="details-hover"
      (click)="navigateToEditLocationPage(row)"
    ></tr>
    <tr *matNoDataRow class="mat-row table-row">
      <td colspan="7" class="mat-cell">
        {{
          !dataSource.data.length
            ? 'No data. Start typing in the search box above'
            : 'No data matching the filter value'
        }}
      </td>
    </tr>
  </table>

  <mat-card>
    <button
      mat-button
      (click)="navigateToCreateLocationPage()"
      class="btn create"
      id="location-action-btn"
    >
      <mat-icon>add</mat-icon>
      <span>{{ createLocationButtonText }}</span>
    </button>

    <mat-paginator
      class="mat-paginator"
      aria-label="Select page"
      [length]="(dataSource.count$ | async) || 0"
      [pageIndex]="locationFilters.locationFilterBy.page - 1"
      [pageSize]="locationFilters.locationFilterBy.pageSize"
      [pageSizeOptions]="[5, 10, 20, 30]"
    ></mat-paginator>
  </mat-card>
</div>
