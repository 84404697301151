export enum WorkOrderFormField {
  GENERAL_INFORMATION = 'generalInformation',
  WORK_ORDER_STATUS = 'workOrderStatus',
  VENDOR_INFORMATION = 'vendorInformation',
  EXPERIMENT_DETAIL = 'experimentDetail',
}

export const WORK_ORDER_NAME_TO_DISPLAY: Record<WorkOrderFormField, string> = {
  [WorkOrderFormField.GENERAL_INFORMATION]: 'General Information',
  [WorkOrderFormField.WORK_ORDER_STATUS]: 'Work Order Status',
  [WorkOrderFormField.VENDOR_INFORMATION]: 'Vendor Information',
  [WorkOrderFormField.EXPERIMENT_DETAIL]: 'Experiment Detail',
};
