export enum AccountInformationFormField {
  EMAIL = 'email',
  USER_NAME = 'username',
  EMPLOYEE_ID = 'employeeId',
  BATCH_ID = 'batchId',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  CONTACT_NUMBER = 'contactNumber',
  TEAM = 'team',
  OFFICE_ADDRESS = 'address',
}
