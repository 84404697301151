import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';

import { RenderTable } from '../models/render-table.model';

@Component({
  selector: 'app-experiment-data-render-table',
  templateUrl: './experiment-data-render-table.component.html',
  styleUrls: ['./experiment-data-render-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperimentDataRenderTableComponent {
  @Input() table!: RenderTable;
}

@NgModule({
  declarations: [ExperimentDataRenderTableComponent],
  exports: [ExperimentDataRenderTableComponent],
  imports: [NgForOf],
})
export class ExperimentDataRenderTableModule {}
