<h1 mat-dialog-title>Confirm action</h1>
<div mat-dialog-content>{{ data.action }}</div>
<div mat-dialog-actions>
  <button
    class="btn btn-secondary"
    mat-button
    [mat-dialog-close]="undefined"
    id="confirmation-popup-cancel-btn"
  >
    {{ data.cancelButtonName ? data.cancelButtonName : 'Cancel' }}
  </button>
  <button
    class="btn btn-primary"
    mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    id="confirmation-popup-confirm-btn"
  >
    {{ data.buttonName }}
  </button>
</div>
