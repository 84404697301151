import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgModule,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';

import { IdName } from '@core/models/id-name.model';
import { LocationRestrictionService } from 'src/app/features/location/services/location-restriction.service';
import { LocationTypeService } from 'src/app/features/location/services/location-type.service';

import { MultiSelectModule } from '../../../../common/multi-select/multi-select.component';
import { LocationFormField } from '../../../location/form/location-form-field.enum';
import { LOCATION_FORM_FIELD_NAME_TO_DISPLAY } from '../../../location/form/location-form-field-name-to-display.config';
import { LocationFilterData } from '../models/location-filter-data.model';

@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationFilterComponent implements OnInit {
  readonly restrictionTypesFormName = 'restrictionTypes';
  readonly locationTypesFormName = 'locationTypes';
  readonly locationFormField = LocationFormField;
  readonly locationFormFieldNameToDisplay = LOCATION_FORM_FIELD_NAME_TO_DISPLAY;
  readonly formFieldNames: Record<string, string> = {
    [this.restrictionTypesFormName]: 'restriction',
    [this.locationTypesFormName]: 'location type',
  };

  locationTypes!: IdName[];
  locationRestrictions!: IdName[];
  formGroup!: UntypedFormGroup;

  private previousRestrictions = new Array<string>();
  private defaultFormValues!: Record<string, string[]>;
  private noRestrictionValue!: IdName;

  get canApply(): boolean {
    return this.formGroup.valid;
  }

  get locationTypesFormControl(): FormControl {
    return this.formGroup.get(this.locationTypesFormName) as FormControl<string>;
  }

  get locationRestrictionsFormControl(): FormControl {
    return this.formGroup.get(this.restrictionTypesFormName) as FormControl<Array<string>>;
  }

  constructor(
    public dialogRef: MatDialogRef<LocationFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LocationFilterData,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initializeData();
    this.onOptionSelection();
  }

  clearFilters(): void {
    this.locationTypesFormControl.reset(this.defaultFormValues[this.locationTypesFormName]);
    this.locationRestrictionsFormControl.reset(
      this.defaultFormValues[this.restrictionTypesFormName],
    );
    this.cd.detectChanges();
  }

  getFormErrorMessage(formName: string): string {
    const formControl = this.getFormControl(formName);

    return formControl.hasError('requiredOneTrue')
      ? `At least one ${this.formFieldNames[formName]} must be selected`
      : '';
  }

  hasErrors(formName: string): boolean {
    const formControl = this.getFormControl(formName);
    return !!formControl.errors;
  }

  apply(): void {
    const locationTypeFormValues = this.locationTypesFormControl.value;
    const restrictionTypeFormValues = this.locationRestrictionsFormControl.value;
    const responseData = {
      locationTypes: [
        ...this.locationTypes.filter((x) => {
          return locationTypeFormValues.includes(x.name);
        }),
      ],
      locationRestrictions: [
        ...this.locationRestrictions.filter((x) => restrictionTypeFormValues.includes(x.name)),
      ],
    };
    this.dialogRef.close(responseData);
  }

  onOptionSelection(): void {
    const selectedOptions = this.locationRestrictionsFormControl.value;

    if (
      selectedOptions.includes(this.noRestrictionValue.name) &&
      this.previousRestrictions.length &&
      !this.previousRestrictions.includes(this.noRestrictionValue.name as string)
    ) {
      this.locationRestrictionsFormControl.setValue([this.noRestrictionValue.name]);
    } else {
      const filteredOptions = selectedOptions.filter(
        (option: string) => option !== this.noRestrictionValue.name,
      );
      this.locationRestrictionsFormControl.setValue(
        filteredOptions.length ? filteredOptions : ['No restriction'],
      );
    }

    this.previousRestrictions = this.locationRestrictionsFormControl.value;
  }

  private getFormControl(formName: string): FormControl {
    return formName === this.restrictionTypesFormName
      ? this.locationRestrictionsFormControl
      : this.locationTypesFormControl;
  }

  private initializeData(): void {
    this.locationRestrictions = [...this.data.restrictionTypes];
    this.locationTypes = [...this.data.locationTypes];
    this.noRestrictionValue = this.locationRestrictions.find(
      (type) => type.name === 'No restriction',
    )!;
    this.defaultFormValues = {
      [this.restrictionTypesFormName]: this.getDefaultValues(
        this.locationRestrictions.filter((d) => d.name !== this.noRestrictionValue.name),
      ),
      [this.locationTypesFormName]: this.getDefaultValues(this.locationTypes),
    };
    const restrictionTypeValue = this.data.currentValues.restrictionTypes.map((d) => d.name);
    const locationTypeValue = this.data.currentValues.locationTypes.map((d) => d.name);
    this.formGroup = new UntypedFormGroup({
      [this.restrictionTypesFormName]: new UntypedFormControl(
        restrictionTypeValue.length ? restrictionTypeValue : [],
        [Validators.required],
      ),
      [this.locationTypesFormName]: new UntypedFormControl(
        locationTypeValue.length ? locationTypeValue : [],
        [Validators.required],
      ),
    });

    this.cd.detectChanges();
  }

  private getDefaultValues(options: IdName[]): string[] {
    return options.map((d) => d.name);
  }
}

@NgModule({
  declarations: [LocationFilterComponent],
  imports: [
    FontAwesomeModule,
    MatDialogModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MultiSelectModule,
  ],
  exports: [LocationFilterComponent],
  providers: [LocationTypeService, LocationRestrictionService],
})
export class LocationFilterModule {}
