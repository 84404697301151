import { BehaviorSubject, forkJoin, map, Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API, NOTIFICATION_API } from '@core/constants/api.consts';
import { UserNotification } from '@core/models/user-notification.model';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { BaseNotification } from 'src/app/features/dashboard-notification/models/base-notification.model';
import { NotificationType } from 'src/app/features/dashboard-notification/models/notification-type.enum';
import { SampleAccessRequestNotification } from 'src/app/features/dashboard-notification/models/sample-access-request-notification.model';

import { LocationState } from '../../features/dashboard-notification/enums/location-state.enum';
import { LocationAccessRequestNotification } from '../../features/dashboard-notification/models/location-access-request-notification.model';
import { StateName } from '../../features/data-access/lookup-sample/enums/access-status.enum';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationService extends Destroyable(Object) {
  private sampleAccessUrl = `${CORE_MEMORY_API}/SamplesAccess`;
  private locationRequestsUrl = `${CORE_MEMORY_API}/Locations/requests`;
  private locationUpdateStateUrl = `${CORE_MEMORY_API}/Locations/updateState`;
  private notificationsUrl = `${NOTIFICATION_API}/Notifications`;

  notifications = new BehaviorSubject<Array<BaseNotification>>([]);
  isEachNotificationLoaded = new BehaviorSubject(false);

  constructor(private httpClient: HttpClient) {
    super();
  }

  getNotifications(): void {
    const sampleAccessNotifications$ = this._getSampleAccessNotifications().pipe(
      map((sampleAccessNotifications) =>
        sampleAccessNotifications.map((el) => ({
          type: NotificationType.SampleAccessRequest,
          message: el,
        })),
      ),
    );
    const locationAccessRequestNotifications$ = this._getLocationAccessRequestNotifications().pipe(
      map((locationAccessRequestNotifications) =>
        locationAccessRequestNotifications.map((el) => ({
          type: NotificationType.LocationAccessRequest,
          message: el,
        })),
      ),
    );
    const notifications$ = this._getNotifications().pipe(
      map((notifications) =>
        notifications.map((el) => ({
          type: NotificationType.DataAccessRequestStatus,
          message: el,
        })),
      ),
    );

    forkJoin([sampleAccessNotifications$, locationAccessRequestNotifications$, notifications$])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([dataAccessNotifications, locationAccessRequestNotifications, notifications]) => {
        this.notifications.next(
          [
            ...dataAccessNotifications,
            ...locationAccessRequestNotifications,
            ...notifications,
          ].sort(
            (n1, n2) =>
              new Date(n2.message.createdAt).getTime() - new Date(n1.message.createdAt).getTime(),
          ),
        );
        this.isEachNotificationLoaded.next(true);
      });
  }

  resetNotifications(): void {
    this.notifications.next([]);
    this.isEachNotificationLoaded.next(false);
  }

  postSampleAccessRequest(sampleId: string, requestorMessage: string): Observable<null> {
    return this.httpClient.post<null>(this.sampleAccessUrl, {
      sampleId,
      requestorMessage,
    });
  }

  updateSampleAccessRequestStatus(
    sampleId: string,
    stateName: StateName.APPROVED | StateName.REJECTED,
  ): Observable<null> {
    return this.httpClient.put<null>(`${this.sampleAccessUrl}/${sampleId}`, {
      stateName,
    });
  }

  updateLocationAccessRequestStatus(id: string, state: LocationState): Observable<null> {
    return this.httpClient.put<null>(`${this.locationUpdateStateUrl}/${id}`, { state });
  }

  dismissDataAccessStatusNotification(id: string): Observable<null> {
    return this.httpClient.delete<null>(`${this.notificationsUrl}/${id}`);
  }

  cancelNotificationRequestStatus(id: string): Observable<null> {
    return this.httpClient.delete<null>(`${this.notificationsUrl}/${id}`, {
      params: new HttpParams().set('option', 'cancel'),
    });
  }

  private _getSampleAccessNotifications(): Observable<SampleAccessRequestNotification[]> {
    return this.httpClient.get<SampleAccessRequestNotification[]>(this.sampleAccessUrl);
  }

  private _getLocationAccessRequestNotifications(): Observable<
    LocationAccessRequestNotification[]
  > {
    return this.httpClient.get<LocationAccessRequestNotification[]>(this.locationRequestsUrl);
  }

  private _getNotifications(): Observable<UserNotification[]> {
    return this.httpClient.get<UserNotification[]>(this.notificationsUrl);
  }
}
