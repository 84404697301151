import { CoreAnalysisWizardEnum } from './core-analysis-wizard.enum';

export const CORE_ANAIYSIS_WIZARD_MICP_LABEL: { [key: string]: string } = {
  [CoreAnalysisWizardEnum.NAME]: 'Name',
  [CoreAnalysisWizardEnum.TYPE]: 'Type',
  [CoreAnalysisWizardEnum.WELLNAME]: 'Well',
  [CoreAnalysisWizardEnum.DEPTH]: 'Depth',
  [CoreAnalysisWizardEnum.SIZE]: 'Size',
  [CoreAnalysisWizardEnum.RESOLUTION]: 'Resolution',
  [CoreAnalysisWizardEnum.NUMBER_OF_GPU]: 'Number of GPUs',
  [CoreAnalysisWizardEnum.NUMBER_OF_CPU]: 'Number of CPUs',
  [CoreAnalysisWizardEnum.CLEANING_METHOD]: 'Cleaning method',
  [CoreAnalysisWizardEnum.EFFLUENT_COLOR]: 'Effluent color',
  [CoreAnalysisWizardEnum.NOTE]: 'Note',
  [CoreAnalysisWizardEnum.BRINE_CONDITION]: 'Brine condition',
  [CoreAnalysisWizardEnum.FRF]: 'FRF',
  [CoreAnalysisWizardEnum.M]: 'm',
  [CoreAnalysisWizardEnum.KW]: 'Kw',
  [CoreAnalysisWizardEnum.PCRI]: 'PcRI index',
  [CoreAnalysisWizardEnum.SATURATION_POINT]: 'Saturation points',
};
