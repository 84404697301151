<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <mat-form-field appearance="outline" class="full-width-input">
    <mat-label>Select unit</mat-label>
    <mat-select [formControl]="selectedUnitOption" id="unit-popup-select">
      <mat-select-trigger>
        <div
          class="superscript-value-space"
          [innerHTML]="data.displayOptions[selectedUnitOption.value] | appParseUnit"
        ></div>
      </mat-select-trigger>
      <mat-option
        *ngFor="let unitOption of data.options"
        [value]="unitOption.id"
        [innerHTML]="unitOption.name | appParseUnit"
      >
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="btn btn-secondary btn-big" [mat-dialog-close]="null" id="unit-popup-close-btn">
    Close
  </button>
  <button
    class="btn btn-primary btn-big"
    [mat-dialog-close]="selectedUnitOption.value"
    id="unit-popup-apply-btn"
  >
    Apply
  </button>
</mat-dialog-actions>
