import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';
import { IdName } from '@core/models/id-name.model';

import { CacheService } from '../cache.service';

@Injectable({
  providedIn: 'root',
})
export class SampleTypeService {
  private url = `${CORE_MEMORY_API}/samples/types`;

  constructor(private httpClient: HttpClient, private cacheService: CacheService) {}

  getAll(): Observable<IdName[]> {
    return this.cacheService.getCachedObservable(
      'sampleTypes',
      this.httpClient.get<IdName[]>(this.url),
    );
  }
}
