import { map, Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';
import { BarcodeQrCode } from '@core/models/barcode-qr-code.model';

@Injectable({
  providedIn: 'root',
})
export class BarcodeQrCodeService {
  private url = `${CORE_MEMORY_API}/barcodeQRCode`;

  constructor(private httpClient: HttpClient) {}

  getBarcodeQRCode(): Observable<string> {
    return this.httpClient
      .get<BarcodeQrCode>(this.url)
      .pipe(map((barcodeQRCode) => barcodeQRCode.barcode));
  }
}
