import { Chart, ChartModule } from 'angular-highcharts';
import { SeriesOptionsType } from 'highcharts';
import { filter as filterFunc, forkJoin } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { formatDate } from '@core/utils/common/format-date.util';
import { SampleTypeService } from 'src/app/services/api/sample-type.service';

import { CapitalizeStringPipe } from '../../../common/pipes/capitalize-string.pipe';
import { IdName } from '../../../core/models/id-name.model';
import { UserTeam } from '../../../core/models/user-team.model';
import { Destroyable } from '../../../core/utils/mixins/destroyable.mixin';
import { SampleService } from '../../../services/api/sample.service';
import { UserService } from '../../../services/api/user.service';
import { DashboardFilterResult } from '../models/dashboard-filter-result.model';
import { INITIAL_SAMPLE_TYPE_CHART_STATE } from './sample-types-chart-filter/consts/initial-sample-type-chart-filter.const';
import { SampleTypesFilter } from './sample-types-chart-filter/models/sample-type-filters.model';
import { SampleTypesChartFilters } from './sample-types-chart-filter/models/sample-types-chart-filters.model';
import {
  SampleTypesChartFilterComponent,
  SampleTypesChartFilterModule,
} from './sample-types-chart-filter/sample-types-chart-filter.component';

@Component({
  selector: 'app-sample-types',
  templateUrl: './sample-types.component.html',
  styleUrls: ['./sample-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleTypesComponent extends Destroyable(Object) implements OnInit {
  @ViewChild('chartContainer', { static: true }) chartContainer!: ElementRef;

  chart!: Chart;
  pickedTypesArray!: string[];
  sampleTypes!: IdName[];
  teams!: IdName[];
  filterResult!: SampleTypesFilter[];
  xAxisTitle: 'Weeks' | 'Days' | 'Months' = 'Weeks';
  filterResultData!: DashboardFilterResult[];
  filterData: SampleTypesChartFilters = JSON.parse(JSON.stringify(INITIAL_SAMPLE_TYPE_CHART_STATE));

  private readonly seriesColor: Record<string, string> = {
    cuttings: 'var(--primary-color-eight)',
    core: 'var(--primary-color-nine)',
    fluids: '#00B2F9',
    uncategorized: '#000000',
    plug: '#FF8F00',
    thinSection: 'var(--primary-color-seven)',
  };

  private readonly displayId: Record<string, number> = {
    day: 0,
    week: 1,
    month: 2,
  };

  constructor(
    private matDialog: MatDialog,
    private cd: ChangeDetectorRef,
    private sampleService: SampleService,
    private userService: UserService,
    private sampleTypeService: SampleTypeService,
    private capitalizeStringPipe: CapitalizeStringPipe,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setInitialFilters();
    this.getInitialData();
    this.fixResizing();
  }

  buildChart(filterResultData: DashboardFilterResult[]): void {
    const series = this.sampleTypes
      .filter(
        (sampleTypes) =>
          !this.filterData.sampleTypesIds.length ||
          this.filterData.sampleTypesIds.includes(sampleTypes.id),
      )
      .map((el) => {
        return {
          name: this.capitalizeStringPipe.transform(el.name),
          data: filterResultData.map(
            (resData) =>
              resData.value.filter((val) => val.displayName === el.name).map((res) => res.count) ||
              null,
          ),
          color: this.seriesColor[el.name],
          type: 'column',
        };
      }) as unknown as SeriesOptionsType[];

    switch (true) {
      case this.filterData.display.day:
        this.xAxisTitle = 'Days';
        break;
      case this.filterData.display.month:
        this.xAxisTitle = 'Months';
        break;
      default:
        this.xAxisTitle = 'Weeks';
    }

    const categories = filterResultData.map((resData) => resData.groupKey);

    this.chart = new Chart({
      chart: {
        type: 'column',
        height: this.chartContainer.nativeElement.offsetHeight,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      legend: {
        align: 'right',
        x: 0,
        verticalAlign: 'top',
        y: -10,
        floating: true,
        backgroundColor: 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false,
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      },
      xAxis: {
        categories,
        labels: {
          format: '{value:%b %e}',
        },
        title: {
          text: this.xAxisTitle,
        },
      },
      yAxis: {
        labels: {
          enabled: true,
        },
        title: {
          text: 'Count (items)',
        },
        stackLabels: {
          enabled: false,
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
          events: {
            legendItemClick: () => false,
          },
        },
      },
      series,
    });
    this.cd.markForCheck();
  }

  private fixResizing(): void {
    const resizeObserver = new ResizeObserver(() => {
      if (!this.chart?.ref) {
        return;
      }
      this.chart!.ref.setSize(
        this.chartContainer.nativeElement.offsetWidth,
        this.chartContainer.nativeElement.offsetHeight,
      );
    });
    resizeObserver.observe(this.chartContainer.nativeElement);
  }

  openOptions(): void {
    const dialogRef = this.matDialog.open(SampleTypesChartFilterComponent, {
      minWidth: 800,
      maxHeight: '80vh',
      height: '80vh',
      autoFocus: false,
      data: {
        ...this.filterData,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        filterFunc((filterData) => !!filterData),
        this.takeUntilDestroyed(),
      )
      .subscribe((data: SampleTypesChartFilters) => {
        this.filterData = data;
        this.applyFilters();
      });
  }

  applyFilters(): void {
    const pickedDisplay =
      (Object.keys(this.filterData.display) as (keyof typeof this.filterData.display)[]).find(
        (key) => this.filterData.display[key],
      ) ?? '';

    const sampleTypesSearchObj: { [key: string]: string } = {};

    this.sampleTypes
      .filter(
        (sampleTypes) =>
          !this.filterData.sampleTypesIds.length ||
          this.filterData.sampleTypesIds.includes(sampleTypes.id),
      )
      .forEach((item, index) => {
        sampleTypesSearchObj[`SampleTypes[${index}].id`] = item.id;
      });

    const teamsArray = this.teams
      .filter(
        (team) => !this.filterData.teamsIds.length || this.filterData.teamsIds.includes(team.name),
      )
      .map((item) => item.name);

    const filterData: SampleTypesFilter = {
      startDate: formatDate(this.filterData.duration.startDate, true),
      endDate: formatDate(this.filterData.duration.endDate, false, true),
      display: this.displayId[pickedDisplay],
      teams: teamsArray,
      sampleTypes: sampleTypesSearchObj,
      sampleAvailability: {
        checkedIn: this.filterData.sampleAvailability.checkedIn,
        checkedOut: this.filterData.sampleAvailability.checkedOut,
      },
    };

    this.sampleService
      .getFilteredSamples(filterData)
      .pipe(this.takeUntilDestroyed())
      .subscribe((data) => {
        this.filterResultData = data;
        this.buildChart(this.filterResultData);
      });
  }

  private getInitialData(): void {
    forkJoin([this.userService.getUserTeams(), this.sampleTypeService.getAll()])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([teams, sampleTypes]) => {
        this.teams = teams.map((idTeam: UserTeam) => {
          return { id: idTeam.id, name: idTeam.team } as IdName;
        });
        this.sampleTypes = sampleTypes.filter((sampleType) => sampleType.name !== 'composite');
        this.applyFilters();
      });
  }

  private setInitialFilters(): void {
    const sixMonthAgoDate = new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString();
    const todaysDate = new Date().toISOString();
    this.filterData.duration.startDate = sixMonthAgoDate;
    this.filterData.duration.endDate = todaysDate;
  }
}

@NgModule({
  declarations: [SampleTypesComponent],
  imports: [ChartModule, SampleTypesChartFilterModule, MatIconModule, CommonModule],
  exports: [SampleTypesComponent],
})
export class SampleTypesModule {}
