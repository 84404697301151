import { Observable, shareReplay } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private _cache: Map<string, Observable<any>> = new Map();

  getCachedObservable<T>(key: string, value: Observable<T>): Observable<T> {
    if (!this._cache.has(key)) {
      this._cache.set(key, value.pipe(shareReplay(1)));
    }

    return this._cache.get(key) as Observable<T>;
  }

  deleteFromCache(key: string): void {
    if (this._cache.has(key)) {
      this._cache.delete(key);
    }
  }
}
