<mat-form-field class="well-form-field" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [formControl]="control"
    autocomplete="off"
    readonly
    [matAutocomplete]="auto"
    (focus)="openWellTableSelectorPopup()"
    id="well-table-popup-input"
  />
  <mat-icon
    data-ref="close-icon"
    matSuffix
    appStopPropagationOnClick
    appDisabledElement
    [disabled]="control.disabled"
    (click)="clearField()"
    id="well-table-popup-btn"
    >close</mat-icon
  >
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="wellNameDisplayFn">
    <mat-option *ngFor="let well of [selectedWell]" [value]="well?.id">
      {{ well | wellDisplayName }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control.errors">{{ getErrorMessage() }}</mat-error>
  <mat-error *ngIf="control!.errors && control!.getError('required')">
    {{ 'Field is required' }}
  </mat-error>
</mat-form-field>
