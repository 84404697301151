import { WellInformation } from '@core/models/well-information.model';

import { ThinSectionInformation } from '../../sample/models/thin-section-information.model';

export interface ThinSectionVendor {
  id?: string | null;
  vendorName: string;
  internalLabFileNo: string;
  reportNo: string;
  reportDate: Date | null;
  dataAnalysisQcByAramco: string;
}

export interface PixelSize {
  fieldValue: number | null;
  unitId: string | null;
}

export interface AcquistionAngle {
  fieldValue: number | null;
  unitId: string | null;
}

export interface ThinSectionImage {
  id?: string | null;
  file?: File | null;
  fileId?: string | null;
  fileName: string;
  angle: AcquistionAngle;
  polarizationId: string | null;
  pixelSize: PixelSize;
  rotation: number | null;
}

export interface ThinsectionComplexData {
  thinSectionWellFormGroup: WellInformation;
  thinSectionInformationFormGroup: ThinSectionInformation;
  thinSectionVendorFormGroup: ThinSectionVendor;
  thinSectionImageFormGroup: ThinSectionImage;
}

export interface DigitalThinSection {
  id?: string | null;
  file?: File;
  fileName: string;
  fileId: string;
  laboratoryInformation: ThinSectionVendor;
  angle: number | null;
  angleUnitId: string;
  polarizationId: string | null;
  pixelSize: number | null;
  pixelSizeUnitId: string;
  rotation: number | null;
}

export class DigitalThinSectionImp {
  id?: string | null;
  fileName: string;
  fileId: string;
  laboratoryInformation: ThinSectionVendor;

  angle: number | null;
  angleUnitId: string;
  polarizationId: string | null;
  pixelSize: number | null;
  pixelSizeUnitId: string;
  rotation: number | null;

  constructor(data?: ThinSectionImage, v?: ThinSectionVendor) {
    this.id = data?.id ?? null;
    this.fileName = data?.fileName ?? '';
    this.fileId = data?.fileId ?? '';
    this.laboratoryInformation = {
      id: v?.id ?? null,
      vendorName: v?.vendorName ?? '',
      internalLabFileNo: v?.internalLabFileNo ?? '',
      reportNo: v?.reportNo ?? '',
      reportDate: v?.reportDate ? v?.reportDate : null,
      dataAnalysisQcByAramco: v?.dataAnalysisQcByAramco ?? '',
    };
    this.angle = data?.angle.fieldValue ? Number(data?.angle.fieldValue) : null;
    this.angleUnitId = data?.angle.unitId ?? '';
    this.polarizationId = data?.polarizationId || null;
    this.pixelSize = data?.pixelSize.fieldValue ? Number(data?.pixelSize.fieldValue) : null;
    this.pixelSizeUnitId = data?.pixelSize.unitId ?? '';
    this.rotation = data?.rotation ?? null;
  }
}

export class DigitalThinSectionImage implements ThinSectionImage {
  id?: string | null;
  file?: File | null;
  fileId?: string;
  fileName: string;
  angle: PixelSize;
  polarizationId: string | null;
  pixelSize: PixelSize;
  rotation: number | null;

  constructor(data?: DigitalThinSection, image: File | null = null) {
    this.id = data?.id ?? null;
    this.file = image !== null ? image : data?.file ?? null;
    this.fileId = data?.fileId ?? '';
    this.fileName = data?.fileName ?? '';
    this.angle = {
      fieldValue: data?.angle ?? null,
      unitId: data?.angleUnitId ?? '',
    };
    this.polarizationId = data?.polarizationId ?? null;
    this.pixelSize = {
      fieldValue: data?.pixelSize ?? null,
      unitId: data?.pixelSizeUnitId ?? '',
    };
    this.rotation = data?.rotation ?? null;
  }
}
