import { distinctUntilChanged, map, Observable, of, shareReplay, switchMap } from 'rxjs';

import { CommonModule, Location as AngularLocation } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ActivatedRoute } from '@angular/router';

import { Location } from '@core/models/location.model';
import { ValidationState } from '@core/models/validation-state.model';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { PrintBarcodeMenuModule } from 'src/app/common/print-barcode-menu/print-barcode-menu.component';
import {
  PrintBarcodePopupComponent,
  PrintBarcodePopupModule,
} from 'src/app/common/print-barcode-popup/print-barcode-popup.component';
import { PrintBarcodePopupData } from 'src/app/common/print-barcode-popup/print-barcode-popup-data.model';
import { LocationFormField } from 'src/app/features/location/form/location-form-field.enum';
import {
  LocationBodyComponent,
  LocationBodyModule,
} from 'src/app/features/location/location-body/location-body.component';
import { CreateLocationPageState } from 'src/app/features/location/state/create-location-page.state';
import { EditLocationPageState } from 'src/app/features/location/state/edit-location-page.state';
import { LocationPageState } from 'src/app/features/location/state/location-page.state';
import { LocationService } from 'src/app/services/api/location.service';
import { NotificationService } from 'src/app/services/notification.service';

import { LocationTreeService } from '../../services/api/location-tree.service';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-location-page',
  templateUrl: './location-page.component.html',
  styleUrls: ['./location-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationPageComponent extends Destroyable(Object) implements OnInit {
  @ViewChild(LocationBodyComponent) locationBodyComponent!: LocationBodyComponent;

  isAdmin!: boolean;
  state!: LocationPageState;
  initialLocation!: Location;
  readonly printName = 'Location';
  readonly locationFormField = LocationFormField;
  private routeParamId$!: Observable<string>;

  constructor(
    private notificationService: NotificationService,
    private locationService: LocationService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private location: AngularLocation,
    private locationTreeService: LocationTreeService,
    private userManagementService: UserManagementService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.isAdmin = this.userManagementService.isAdmin;
    this.defineRouteParamIdListener();
    this.trackLocationPageState();
    this.trackCurrentLocation();
  }

  save(withPrint = false): void {
    const validationState = this.checkIfFormIsValid();

    if (!validationState.isValid) {
      this.locationBodyComponent.locationForm.markAllAsTouched();
      this.locationBodyComponent.changeDetectorRef.detectChanges();
      this.notificationService.notifyError(validationState.errorMessage);
      return;
    }

    this.locationService[this.state.saveMethodName]({
      id: this.initialLocation.id,
      ...this.locationBodyComponent.locationForm.value,
    })
      .pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        this.notificationService.notifySuccess(this.state.successfullySavedNotificationMessage);
        this.state.onSuccessSaved(withPrint);
      });
  }

  resetForm(): void {
    const {
      name,
      description,
      locationTypeId,
      locationRestrictionIds,
      parentLocationId,
      barcodeId,
      qrCodeId,
    } = this.initialLocation;

    this.locationBodyComponent.locationForm.reset({
      [LocationFormField.NAME]: name,
      [LocationFormField.DESCRIPTION]: description,
      [LocationFormField.LOCATION_TYPE_ID]: locationTypeId,
      [LocationFormField.LOCATION_RESTRICTION_IDS]: locationRestrictionIds,
      [LocationFormField.PARENT_LOCATION_ID]: parentLocationId,
      [LocationFormField.BARCODE_ID]: barcodeId,
      [LocationFormField.QR_CODE_ID]: qrCodeId,
    });
  }

  openPrintPopup(): void {
    this.matDialog.open<PrintBarcodePopupComponent, PrintBarcodePopupData>(
      PrintBarcodePopupComponent,
      {
        data: {
          title: 'Print barcode',
          barcodeId: this.locationBodyComponent.barcodeIdFormControl.value,
          qrCodeId: this.locationBodyComponent.qrCodeIdFormControl.value,
        },
      },
    );
  }

  navigateBack(): void {
    this.location.back();
  }

  updateLocationNodes(): void {
    this.locationTreeService
      .getLocationNodes()
      .pipe(this.takeUntilDestroyed())
      .subscribe((locationNodes) => {
        this.locationBodyComponent.locationNodes = locationNodes;
        this.locationBodyComponent.changeDetectorRef.markForCheck();
      });
  }

  private checkIfFormIsValid(): ValidationState {
    if (this.locationBodyComponent.locationForm.status === 'VALID') {
      return { isValid: true };
    }

    return {
      isValid: false,
      errorMessage: this.locationBodyComponent.flatFormValidationMessage.getFirstMessage(),
    };
  }

  private transitionTo(state: LocationPageState): void {
    this.state = state;
    this.state.setComponent(this);
  }

  private defineRouteParamIdListener(): void {
    this.routeParamId$ = this.route.params.pipe(
      map((params) => params['id']),
      shareReplay(1),
    );
  }

  private trackLocationPageState(): void {
    this.routeParamId$
      .pipe(
        map((locationId) => !!locationId),
        distinctUntilChanged(),
        this.takeUntilDestroyed(),
      )
      .subscribe((isEditState) => {
        if (isEditState) {
          this.transitionTo(new EditLocationPageState());
        } else {
          this.transitionTo(new CreateLocationPageState());
        }
      });
  }

  private trackCurrentLocation(): void {
    this.routeParamId$
      .pipe(
        switchMap((locationId) =>
          locationId ? this.locationService.getById(locationId) : of(new Location()),
        ),
        this.takeUntilDestroyed(),
      )
      .subscribe((location) => {
        this.initialLocation = new Location(location);
        this.cd.markForCheck();
      });
  }
}

@NgModule({
  declarations: [LocationPageComponent],
  imports: [
    LocationBodyModule,
    PrintBarcodePopupModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    PrintBarcodeMenuModule,
  ],
  exports: [LocationPageComponent],
})
export class LocationPageModule {}
