import { Directive, HostBinding, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[appPreventPasswordAutoFilling]',
})
export class PreventPasswordAutoFillingDirective {
  @HostBinding('readonly') get isReadonly(): boolean {
    return this.readonly;
  }

  @HostListener('focus') removeReadonlyFlag(): void {
    this.readonly = false;
  }

  private readonly = true;
}

@NgModule({
  declarations: [PreventPasswordAutoFillingDirective],
  exports: [PreventPasswordAutoFillingDirective],
})
export class PreventPasswordAutoFillingModule {}
