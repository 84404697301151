<form [ngSwitch]="sampleType" [formGroup]="formGroup" autocomplete="off">
  <ng-container *ngSwitchCase="SAMPLE_TYPE.PLUG">
    <div class="fields-line">
      <app-unit-input
        [formControl]="extractControl(plugSampleInformationFormField.DEPTH)"
        [label]="plugSampleInformationFormLabel[plugSampleInformationFormField.DEPTH]"
        [formFieldName]="plugSampleInformationFormField.DEPTH"
        [getErrorMessage]="errorMessageFunctionForDepth(plugSampleInformationFormField.DEPTH)"
        [required]="true"
        [options]="lengthUnits"
        [type]="'number'"
        id="plug-depth-input"
      ></app-unit-input>

      <mat-form-field>
        <mat-label>
          {{ plugSampleInformationFormLabel[plugSampleInformationFormField.ORIENTATION] }}
        </mat-label>
        <mat-select
          [formControlName]="plugSampleInformationFormField.ORIENTATION"
          [compareWith]="optionsCompareFn"
          id="plug-orientation-select"
        >
          <mat-option *ngFor="let orientation of orientations" [value]="orientation.id">
            {{ orientation.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="fields-line">
      <mat-form-field>
        <mat-label>
          {{ plugSampleInformationFormLabel[plugSampleInformationFormField.PRESERVATION_TYPE] }}
        </mat-label>
        <mat-select
          [formControlName]="plugSampleInformationFormField.PRESERVATION_TYPE"
          [compareWith]="optionsCompareFn"
          id="plug-preservation-type-select"
        >
          <mat-option
            *ngFor="let preservationType of preservationTypes"
            [value]="preservationType.id"
          >
            {{ preservationType.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            preservationTypeFormControl!.errors &&
            preservationTypeFormControl!.hasError(errorTypes.required)
          "
        >
          {{ errorMessages.required }}
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="SAMPLE_TYPE.CORE">
    <div class="fields-line">
      <app-unit-input
        #coreDepthTop
        [formControl]="extractControl(coreSampleInformationFormField.DEPTH_TOP)"
        [label]="coreSampleInformationFormLabel[coreSampleInformationFormField.DEPTH_TOP]"
        [formFieldName]="coreSampleInformationFormField.DEPTH_TOP"
        [required]="true"
        [options]="lengthUnits"
        [type]="'number'"
        [max]="nonZeroFieldValueOrNull(coreSampleInformationFormField.DEPTH_BOTTOM)"
        [getErrorMessage]="errorMessageFunctionForDepth(coreSampleInformationFormField.DEPTH_TOP)"
        appSynchronizeDepthUnits
        [config]="{
          primaryControlName: coreSampleInformationFormField.DEPTH_TOP,
          secondaryComponents: [coreDepthBottom]
        }"
        id="core-depth-top-input"
      ></app-unit-input>

      <app-unit-input
        #coreDepthBottom
        [formControl]="extractControl(coreSampleInformationFormField.DEPTH_BOTTOM)"
        [label]="coreSampleInformationFormLabel[coreSampleInformationFormField.DEPTH_BOTTOM]"
        [formFieldName]="coreSampleInformationFormField.DEPTH_BOTTOM"
        [required]="true"
        [options]="lengthUnits"
        [type]="'number'"
        [min]="nonZeroFieldValueOrNull(coreSampleInformationFormField.DEPTH_TOP)"
        [getErrorMessage]="
          errorMessageFunctionForDepth(coreSampleInformationFormField.DEPTH_BOTTOM)
        "
        appSynchronizeDepthUnits
        [config]="{
          primaryControlName: coreSampleInformationFormField.DEPTH_BOTTOM,
          secondaryComponents: [coreDepthTop]
        }"
        id="core-depth-bottom-input"
      ></app-unit-input>
    </div>

    <div class="fields-line">
      <mat-form-field>
        <mat-label>
          {{ coreSampleInformationFormLabel[coreSampleInformationFormField.PRESERVATION_TYPE] }}
        </mat-label>
        <mat-select
          [formControlName]="coreSampleInformationFormField.PRESERVATION_TYPE"
          [compareWith]="optionsCompareFn"
          id="core-preservation-type-input"
        >
          <mat-option
            *ngFor="let preservationType of preservationTypes"
            [value]="preservationType.id"
          >
            {{ preservationType.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            preservationTypeFormControl!.errors &&
            preservationTypeFormControl!.hasError(errorTypes.required)
          "
        >
          {{ errorMessages.required }}
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="SAMPLE_TYPE.CUTTINGS">
    <div class="fields-line">
      <app-unit-input
        #cuttingsDepthTop
        [formControl]="extractControl(cuttingsSampleInformationFormField.DEPTH_TOP)"
        [label]="cuttingsSampleInformationFormLabel[cuttingsSampleInformationFormField.DEPTH_TOP]"
        [formFieldName]="cuttingsSampleInformationFormField.DEPTH_TOP"
        [required]="true"
        [options]="lengthUnits"
        [type]="'number'"
        [max]="nonZeroFieldValueOrNull(cuttingsSampleInformationFormField.DEPTH_BOTTOM)"
        [getErrorMessage]="
          errorMessageFunctionForDepth(cuttingsSampleInformationFormField.DEPTH_TOP)
        "
        appSynchronizeDepthUnits
        [config]="{
          primaryControlName: cuttingsSampleInformationFormField.DEPTH_TOP,
          secondaryComponents: [cuttingsDepthBottom]
        }"
        id="cuttings-depth-top-input"
      ></app-unit-input>

      <app-unit-input
        #cuttingsDepthBottom
        [formControl]="extractControl(cuttingsSampleInformationFormField.DEPTH_BOTTOM)"
        [label]="
          cuttingsSampleInformationFormLabel[cuttingsSampleInformationFormField.DEPTH_BOTTOM]
        "
        [formFieldName]="cuttingsSampleInformationFormField.DEPTH_BOTTOM"
        [required]="true"
        [options]="lengthUnits"
        [type]="'number'"
        [min]="nonZeroFieldValueOrNull(cuttingsSampleInformationFormField.DEPTH_TOP)"
        [getErrorMessage]="
          errorMessageFunctionForDepth(cuttingsSampleInformationFormField.DEPTH_BOTTOM)
        "
        appSynchronizeDepthUnits
        [config]="{
          primaryControlName: cuttingsSampleInformationFormField.DEPTH_BOTTOM,
          secondaryComponents: [cuttingsDepthTop]
        }"
        id="cuttings-depth-bottom-input"
      ></app-unit-input>
    </div>

    <div class="fields-line">
      <mat-form-field>
        <mat-label>
          {{
            cuttingsSampleInformationFormLabel[cuttingsSampleInformationFormField.PRESERVATION_TYPE]
          }}
        </mat-label>
        <mat-select
          [formControlName]="cuttingsSampleInformationFormField.PRESERVATION_TYPE"
          [compareWith]="optionsCompareFn"
          id="cuttings-preservation-type-input"
        >
          <mat-option
            *ngFor="let preservationType of preservationTypes"
            [value]="preservationType.id"
          >
            {{ preservationType.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            preservationTypeFormControl!.errors &&
            preservationTypeFormControl!.hasError(errorTypes.required)
          "
        >
          {{ errorMessages.required }}
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="SAMPLE_TYPE.FLUID">
    <div class="fields-line">
      <app-unit-input
        [formControl]="extractControl(fluidSampleInformationFormField.DEPTH)"
        [label]="fluidSampleInformationFormLabel[fluidSampleInformationFormField.DEPTH]"
        [formFieldName]="fluidSampleInformationFormField.DEPTH"
        [required]="true"
        [getErrorMessage]="errorMessageFunctionForDepth(fluidSampleInformationFormField.DEPTH)"
        [options]="lengthUnits"
        [type]="'number'"
        id="fluid-depth-input"
      ></app-unit-input>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="SAMPLE_TYPE.UNCATEGORIZED">
    <div class="fields-line">
      <app-unit-input
        [formControl]="extractControl(uncategorizedSampleInformationFormField.DEPTH)"
        [label]="
          uncategorizedSampleInformationFormLabel[uncategorizedSampleInformationFormField.DEPTH]
        "
        [formFieldName]="uncategorizedSampleInformationFormField.DEPTH"
        [getErrorMessage]="
          errorMessageFunctionForDepth(uncategorizedSampleInformationFormField.DEPTH)
        "
        [options]="lengthUnits"
        [type]="'number'"
        id="uncategorized-depth-input"
      ></app-unit-input>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="SAMPLE_TYPE.THINSECTION">
    <div class="fields-line">
      <app-unit-input
        #thinSectionDepthBottom
        [formControl]="extractControl(thinSectionSampleInformationFormField.DEPTH_BOTTOM)"
        [label]="'Bottom depth'"
        [formFieldName]="thinSectionSampleInformationFormField.DEPTH_BOTTOM"
        [required]="true"
        [options]="lengthUnits"
        [type]="'number'"
        [min]="nonZeroFieldValueOrNull(thinSectionSampleInformationFormField.DEPTH_TOP)"
        [getErrorMessage]="
          errorMessageFunctionForDepth(thinSectionSampleInformationFormField.DEPTH_BOTTOM)
        "
        appSynchronizeDepthUnits
        [config]="{
          primaryControlName: thinSectionSampleInformationFormField.DEPTH_BOTTOM,
          secondaryComponents: [thinSectionDepthTop]
        }"
        id="complex-data-uploader-thin-section-information-bottom-depth-input"
      ></app-unit-input>

      <app-unit-input
        #thinSectionDepthTop
        [formControl]="extractControl(thinSectionSampleInformationFormField.DEPTH_TOP)"
        [label]="'Top depth'"
        [formFieldName]="thinSectionSampleInformationFormField.DEPTH_TOP"
        [required]="true"
        [options]="lengthUnits"
        [type]="'number'"
        [max]="nonZeroFieldValueOrNull(thinSectionSampleInformationFormField.DEPTH_BOTTOM)"
        [getErrorMessage]="
          errorMessageFunctionForDepth(thinSectionSampleInformationFormField.DEPTH_TOP)
        "
        appSynchronizeDepthUnits
        [config]="{
          primaryControlName: thinSectionSampleInformationFormField.DEPTH_TOP,
          secondaryComponents: [thinSectionDepthBottom]
        }"
        id="complex-data-uploader-thin-section-information-top-depth-input"
      ></app-unit-input>
    </div>

    <div class="fields-line">
      <mat-form-field appearance="outline">
        <mat-label>Section number</mat-label>
        <input
          matInput
          [formControlName]="thinSectionSampleInformationFormField.SECTION_NUMBER"
          id="complex-data-uploader-thin-section-information-section-number-input"
        />
        <mat-error
          *ngIf="
            sectionNumberFormControl!.errors &&
            sectionNumberFormControl!.hasError(errorTypes.required)
          "
        >
          {{ errorMessages.required }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Section origin</mat-label>
        <mat-select
          [formControlName]="thinSectionSampleInformationFormField.SECTION_ORIGIN"
          id="complex-data-uploader-thin-section-information-section-origin-input"
        >
          <mat-option *ngFor="let option of sectionOrigins" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="fields-line">
      <mat-form-field appearance="outline">
        <mat-label>Section type</mat-label>
        <mat-select
          [formControlName]="thinSectionSampleInformationFormField.SECTION_TYPE"
          id="complex-data-uploader-thin-section-information-section-type-input"
        >
          <mat-option *ngFor="let option of sectionTypes" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Staining</mat-label>
        <mat-select
          [formControlName]="thinSectionSampleInformationFormField.STAINING"
          id="complex-data-uploader-thin-section-information-staining-input"
        >
          <mat-option *ngFor="let option of stainings" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="fields-line half-width">
      <mat-form-field appearance="outline">
        <mat-label>Orientation</mat-label>
        <mat-select
          [formControlName]="thinSectionSampleInformationFormField.ORIENTATION"
          id="complex-data-uploader-thin-section-information-polarization-input"
        >
          <mat-option *ngFor="let option of orientations" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="fields-line">
      <div class="section-dimension-line">
        <span>Section dimension</span>
        <div class="section-dimensions">
          <mat-form-field appearance="outline">
            <mat-label>X</mat-label>
            <input
              matInput
              [type]="'number'"
              [formControlName]="thinSectionSampleInformationFormField.SECTION_DIMENSION_X"
              id="thin-section-information-section-dimension-x-input"
            />
            <mat-error
              class="dimension-error"
              *ngIf="phyXFormControl!.errors && phyXFormControl!.hasError(errorTypes.required)"
            >
              {{ errorMessages.required }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Y</mat-label>
            <input
              matInput
              [type]="'number'"
              [formControlName]="thinSectionSampleInformationFormField.SECTION_DIMENSION_Y"
              id="thin-section-information-section-dimension-y-input"
            />
            <mat-error
              class="dimension-error"
              *ngIf="phyYFormControl!.errors && phyYFormControl!.hasError(errorTypes.required)"
            >
              {{ errorMessages.required }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Z</mat-label>
            <input
              matInput
              [type]="'number'"
              [formControlName]="thinSectionSampleInformationFormField.SECTION_DIMENSION_Z"
              id="thin-section-information-section-dimension-z-input"
            />
            <mat-error
              class="dimension-error"
              *ngIf="phyZFormControl!.errors && phyZFormControl!.hasError(errorTypes.required)"
            >
              {{ errorMessages.required }}
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class="sample-dimension-unit">
          <mat-select
            [formControlName]="thinSectionSampleInformationFormField.SECTION_DIMENSION_UNIT_ID"
            [compareWith]="compareWith"
            id="complex-data-uploader-thin-section-information-section-dimension-unit-input"
          >
            <mat-option *ngFor="let option of lengthUnits" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
</form>
