<app-welcome-page
  pageName="Signup"
  [enforcePasswordPolicy]="true"
  [showRememberMeCheckbox]="false"
  (buttonClicked)="onSignupClicked($event)"
>
  <span>
    Already have an account?
    <a mat-button routerLink="/login" color="primary">Sign in</a>
    .
  </span>
</app-welcome-page>
