import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Input, NgModule, OnInit, Output } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { Router, RouterModule } from '@angular/router';

import { USE_OKTA_AUTH_TOKEN } from '@core/tokens/use-okta-auth.token';
import { WINDOW } from '@core/tokens/window.token';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { OrganizationService } from 'src/app/services/api/organization.service';
import { UserNotificationService } from 'src/app/services/api/user-notification.service';

import { AppMenuRoutePath } from '../../features/application-menu/enums/app-menu-route-path.enum';
import { TokenStorageService } from '../../services/token-storage.service';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent extends Destroyable(Object) implements OnInit {
  @Input() opened = true;
  @Output() openedChange = new EventEmitter<boolean>();
  notificationsCount = 0;
  fullLogo = '';
  smallLogo = '';
  userEmail!: string;
  isAdmin = false;

  private readonly maxNotifications = 99;

  get isDashboardApp(): boolean {
    return this.router.url.startsWith(`/${AppMenuRoutePath.DASHBOARD_APP}`);
  }

  get isAcmeTrackerApp(): boolean {
    return this.router.url.startsWith(`/${AppMenuRoutePath.ACME_TRACKER_APP}`);
  }

  get isCoreAnalysisWizardApp(): boolean {
    return this.router.url.startsWith(`/${AppMenuRoutePath.CORE_ANALYSIS_WIZARD_APP}`);
  }

  get routerAppPrefix(): string {
    return `/${/(?<=\/)(.*?)(?=\/)/.exec(this.router.url)?.[0]}`;
  }

  constructor(
    private userNotificationService: UserNotificationService,
    private organizationService: OrganizationService,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private userManagementService: UserManagementService,
    @Inject(WINDOW) private window: Window,
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
  ) {
    super();
  }

  ngOnInit(): void {
    this.userEmail = this.userManagementService.getUserInfo()!.email;
    this.isAdmin = this.userManagementService.isAdmin;
    this.organizationService
      .getOrganizations()
      .pipe(this.takeUntilDestroyed())
      .subscribe((organizations) => {
        if (organizations.length) {
          this.fullLogo = organizations[0].organizationFullIconImagePath;
          this.smallLogo = organizations[0].organizationSmallIconImagePath;
        }
      });

    if (this.window.history.state.navigationId === 1) {
      this.userNotificationService.getNotifications();
    }

    this.userNotificationService.notifications
      .pipe(this.takeUntilDestroyed())
      .subscribe((value) => {
        this.notificationsCount =
          value.length > this.maxNotifications ? this.maxNotifications : value.length;
      });
  }

  logout(): void {
    this.userManagementService.removeUserInfo();
    this.userNotificationService.resetNotifications();

    if (this.useOktaAuth) {
      this.oktaAuth.signOut();
    } else {
      this.tokenStorageService.removeTokens();
      this.router.navigate(['login']);
    }
  }

  toggle(): void {
    this.opened = !this.opened;
    this.openedChange.emit(this.opened);
  }
}

@NgModule({
  declarations: [SidenavComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatExpansionModule,
    RouterModule.forChild([]),
  ],
  exports: [SidenavComponent],
})
export class SidenavModule {}
