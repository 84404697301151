import { OktaAuthGuard } from '@okta/okta-angular';
import { Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthGuardService } from '@core/guards/auth-guard.service';
import { USE_OKTA_AUTH_TOKEN } from '@core/tokens/use-okta-auth.token';

@Injectable({
  providedIn: 'root',
})
export class DynamicAuthGuard implements CanActivate {
  constructor(
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
    private authGuardService: AuthGuardService,
    private oktaAuthGuard: OktaAuthGuard,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.useOktaAuth
      ? this.oktaAuthGuard.canActivate(route, state)
      : this.authGuardService.canActivate(route, state);
  }
}
