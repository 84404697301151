import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { WorkOrderFormShape } from 'src/app/features/work-order/models/work-order-form-shape.model';

import { dateLimits } from './date-limits.validator';
import { numericRange } from './numeric-range.validator';

export function generateWorkOrderForm(
  formBuilder: UntypedFormBuilder,
  { generalInformation, workOrderStatus, vendorInformation, experimentDetail }: WorkOrderFormShape,
): UntypedFormGroup {
  return formBuilder.group({
    generalInformation: formBuilder.group(
      {
        id: [generalInformation.id],
        projectNumber: [
          generalInformation.projectNumber,
          [Validators.required, Validators.maxLength(64)],
        ],
        description: [generalInformation.description, [Validators.maxLength(128)]],
        proponentEmail: [
          generalInformation.proponentEmail,
          [Validators.maxLength(128), Validators.email],
        ],
        proponentName: [generalInformation.proponentName, [Validators.maxLength(128)]],
        startDate: [generalInformation.startDate],
        expectedDeliveryDate: [generalInformation.expectedDeliveryDate],
      },
      { validators: dateLimits },
    ),
    workOrderStatus: formBuilder.group({
      progress: [workOrderStatus.progress, [numericRange(0, 100)]],
      completionDate: [workOrderStatus.completionDate],
      note: [workOrderStatus.note, [Validators.maxLength(256)]],
      completed: [workOrderStatus.completed],
    }),
    vendorInformation: formBuilder.group({
      vendorName: [vendorInformation.vendorName],
      vendorContactEmail: [
        vendorInformation.vendorContactEmail,
        [Validators.maxLength(128), Validators.email],
      ],
      vendorContactName: [vendorInformation.vendorContactName, [Validators.maxLength(64)]],
      vendorContactInfo: [vendorInformation.vendorContactInfo, [Validators.maxLength(256)]],
    }),
    experimentDetail: formBuilder.group({
      experimentType: [experimentDetail.experimentType],
    }),
  });
}
