<div class="wrapper">
  <ng-container *ngIf="showSampleCheckInMenu">
    <app-sample-check-in-menu
      label="Create Child Sample"
      [menuItems]="sampleCheckInMenuOptions"
      (menuItemClick)="sampleCheckInOptionSelected.emit($event)"
    >
    </app-sample-check-in-menu>
  </ng-container>
  <ng-container *ngIf="showPrintBarcodeMenu">
    <app-print-barcode-menu
      [barcodeValue]="barcode"
      [qrCodeValue]="qrCode"
      [printName]="sample"
    ></app-print-barcode-menu>
  </ng-container>
  <div class="actions">
    <button class="btn btn-secondary btn-big" (click)="cancelClicked.emit()" id="sample-cancel-btn">
      Cancel
    </button>
    <button
      class="btn btn-primary btn-big btn-with-spinner"
      (click)="initiateSaving()"
      [disabled]="isSaveBtnSpinnerVisible"
      id="sample-save-btn"
    >
      {{ saveButtonLabel }}
      <mat-icon *ngIf="isSaveBtnSpinnerVisible"
        ><mat-spinner diameter="20"> </mat-spinner
      ></mat-icon>
    </button>
  </div>
</div>
