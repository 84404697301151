import { Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { API_SERVER_URL_TOKEN } from '@core/tokens/api-server-url.token';

@Injectable()
export class BaseApiUrlInterceptor implements HttpInterceptor {
  private readonly baseApiUrl = `${this.apiServerUrl}`; // corememory-api/api`;

  constructor(@Inject(API_SERVER_URL_TOKEN) private readonly apiServerUrl: string) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const apiRequest = request.clone({ url: this.baseApiUrl + request.url });
    return next.handle(apiRequest);
  }
}
