<h1 matDialogTitle>Work order plot options</h1>

<mat-dialog-content>
  <mat-tab-group
    (selectedTabChange)="changeActiveTab($event)"
    [selectedIndex]="initialActiveTabIndex"
  >
    <mat-tab [label]="dashboardWidgetOptionsPopupTab.DATE">
      <app-dashboard-widget-options-popup-date-tab
        [filterState]="filterState"
        [filterLists]="filterLists"
      ></app-dashboard-widget-options-popup-date-tab>
    </mat-tab>

    <mat-tab [label]="dashboardWidgetOptionsPopupTab.TEAM">
      <app-dashboard-widget-options-popup-team-tab
        [filterState]="filterState"
        [filterLists]="filterLists"
      ></app-dashboard-widget-options-popup-team-tab>
    </mat-tab>

    <mat-tab [label]="dashboardWidgetOptionsPopupTab.TEAM_MEMBER">
      <app-dashboard-widget-options-popup-team-member-tab
        [filterState]="filterState"
        [filterLists]="filterLists"
      ></app-dashboard-widget-options-popup-team-member-tab>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    id="dashboard-work-order-options-popup-cancel-btn"
    class="btn btn-secondary"
    [mat-dialog-close]="null"
  >
    Cancel
  </button>

  <button
    id="dashboard-work-order-options-popup-apply-btn"
    class="btn btn-primary"
    (click)="applyFilters()"
  >
    Apply
  </button>
</mat-dialog-actions>
