export interface PhaseProperties {
  connectedX: boolean;
  connectedY: boolean;
  connectedZ: boolean;
  phaseId: number;
  voxelCount: number;
  phaseType: string;
  [index: string]: string | number | boolean | null;
}

export interface DigitalVolumeMicp {
  phaseProperties?: PhaseProperties;
}

export interface SimulationRun {
  userName: string;
  sampleId: string;
  digitalVolumeId: string;
  simulationName: string;
  porePhases: number[];
  numberOfGpu?: number;
  numberOfCpu?: number;
}

export interface SimulationRunResult {
  isError: boolean;
  message: string;
  statusCode: number;
  data: {
    task_id: string;
    engine_name: string;
    user_name: string;
  };
}

export class SimulationRunShape implements SimulationRun {
  userName: string;
  sampleId: string;
  digitalVolumeId: string;
  simulationName: string;
  porePhases: number[];
  numberOfGpu?: number;
  numberOfCpu?: number;
  simulationType?: string;
  simulationDirection?: string;

  constructor(
    userName: string,
    sampleId: string,
    isPtsd: boolean,
    id: string,
    simulationName: string,
    porePhases: number[],
    simulationDirection: string,
    numberOfGpu: number,
    numberOfCpu: number,
  ) {
    this.userName = userName;
    this.sampleId = sampleId;
    this.digitalVolumeId = id;
    this.simulationName = simulationName;
    this.porePhases = porePhases;
    this.numberOfGpu = numberOfGpu ? Number(numberOfGpu) : 0;
    this.numberOfCpu = numberOfCpu ? Number(numberOfCpu) : 0;
    this.simulationType = isPtsd ? 'ptsd' : 'micp';
    this.simulationDirection = simulationDirection;
  }
}
