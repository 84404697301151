export enum FileType {
  ATTACHED = 'Attached',
  SAMPLE_IMAGE = 'SampleImage',
  DIGITAL_VOLUME = 'DigitalVolume',
  DIGITAL_THIN_SECTION = 'DigitalThinSection',
  MICP_SIMULATION_LOG = 'MICPSimulationLog',
  MICP_SIMULATION_RESULTS = 'MICPSimulationResults',
  PTSD_SIMULATION_LOG = 'PTSDSimulationLog',
  PTSD_SIMULATION_RESULTS = 'PTSDSimulationResults',
}
