import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { SampleType } from 'src/app/common/sample-check-in-menu/enums/sample-type.enum';

import { CapitalizeStringPipeModule } from '../pipes/capitalize-string.pipe';

@Component({
  selector: 'app-sample-check-in-menu',
  templateUrl: './sample-check-in-menu.component.html',
  styleUrls: ['./sample-check-in-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleCheckInMenuComponent {
  @Input() label = 'Sample Check-in';
  @Input() menuItems: SampleType[] = [
    SampleType.PLUG,
    SampleType.CORE,
    SampleType.CUTTINGS,
    SampleType.FLUID,
    SampleType.UNCATEGORIZED,
    SampleType.THINSECTION,
  ];

  @Output() menuItemClick = new EventEmitter<SampleType>();
}

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    CapitalizeStringPipeModule,
  ],
  declarations: [SampleCheckInMenuComponent],
  exports: [SampleCheckInMenuComponent],
})
export class SampleCheckInMenuModule {}
