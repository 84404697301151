import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appParseUnit',
})
export class ParseUnitPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) {
      return '';
    }

    const caret = '^';
    const indexOfCaret = value.indexOf(caret);

    if (indexOfCaret === -1) {
      return value;
    }

    const base = value.slice(0, indexOfCaret);
    const exponent = value.slice(indexOfCaret + 1);

    return `${base}<sup>${exponent}</sup>`;
  }
}

@NgModule({
  declarations: [ParseUnitPipe],
  exports: [ParseUnitPipe],
})
export class ParseUnitModule {}
