export enum SimulationSettingsFormField {
  SIMULATION_NAME = 'simulationName',
  DESCRIPTION = 'description',
  DIRECTION_X = 'x',
  DIRECTION_Y = 'y',
  DIRECTION_Z = 'z',
  FLIP_X = 'X',
  FLIP_Y = 'Y',
  FLIP_Z = 'Z',
  PTSD = 'p',
  NUMBER_OF_GPUS = 'numberOfGpus',
  NUMBER_OF_CPUS = 'numberOfCpus',
}
