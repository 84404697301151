import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';

import { ExperimentData } from '../../features/sample/models/experiment-data.model';

@Injectable({
  providedIn: 'root',
})
export class ExperimentDataService {
  private readonly url = `${CORE_MEMORY_API}/Samples/RawData`;

  constructor(private httpClient: HttpClient) {}

  getAllBy(sampleId: string): Observable<ExperimentData[]> {
    return this.httpClient.get<ExperimentData[]>(this.url, {
      params: new HttpParams().set('sampleId', sampleId),
    });
  }
}
