import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { PcriFormShape } from '../models/pcri-form-shape.model';
import { dateLimits } from './date-limits.validator';
import { numericRange } from './numeric-range.validator';

export function generatePcriForm(
  formBuilder: FormBuilder,
  { generalInformation, status, experimentDetail }: PcriFormShape,
): FormGroup {
  return formBuilder.group({
    generalInformation: formBuilder.group(
      {
        id: [generalInformation.id],
        name: [
          { value: generalInformation.name, disabled: generalInformation.name },
          [Validators.required, Validators.maxLength(64)],
        ],
        description: [generalInformation.description, [Validators.maxLength(128)]],
        proponentEmail: [
          generalInformation.proponentEmail,
          [Validators.maxLength(128), Validators.email],
        ],
        proponentName: [generalInformation.proponentName, [Validators.maxLength(128)]],
        startDate: [generalInformation.startDate],
        expectDate: [generalInformation.expectDate],
      },
      { validators: dateLimits },
    ),
    status: formBuilder.group({
      progress: [status.progress, [numericRange(0, 100)]],
      completionDate: [status.completionDate],
      completed: [status.completed],
    }),
    experimentDetail: formBuilder.group({
      experimentType: [experimentDetail.experimentType],
    }),
  });
}
