import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API, PASS_ERROR_HEADER } from '@core/constants/api.consts';
import { IdName } from '@core/models/id-name.model';
import { DashboardFilterResult } from 'src/app/features/dashboard/models/dashboard-filter-result.model';
import { SampleTypesFilter } from 'src/app/features/dashboard/sample-types/sample-types-chart-filter/models/sample-type-filters.model';
import { CompositeSample } from 'src/app/features/sample/models/composite-sample.model';
import { SampleCheckOut } from 'src/app/features/sample/models/sample-check-out.model';

import { SampleType } from '../../common/sample-check-in-menu/enums/sample-type.enum';
import { SampleSearch } from '../../features/data-access/lookup-sample/models/sample-search.model';
import { ReturnSampleRequest } from '../../features/sample/models/return-sample-request.model';
import { SampleGetById } from '../../features/sample/models/sample-get-by-id.model';
import { SampleSaveParticular } from '../../features/sample/models/sample-save-particular.model';

@Injectable({
  providedIn: 'root',
})
export class SampleService {
  private url = `${CORE_MEMORY_API}/samples`;
  public orientationsUrl = `${this.url}/orientations`;
  public preservationsTypesUrl = `${this.url}/preservationtypes`;
  public limitationUrl = `${this.url}/limitations`;
  public purposesUrl = `${this.url}/checkout/purposes`;
  private compositeUrl = `${this.url}/composite`;
  private checkoutUrl = `${this.url}/checkout`;
  private returnUrl = `${this.url}/return`;
  private filterUrl = `${this.url}/filter`;
  private sectionTypesUrl = `${this.url}/sectionTypes`;
  private sectionOriginsUrl = `${this.url}/sectionOrigins`;
  private stainingsUrl = `${this.url}/stainings`;

  constructor(private httpClient: HttpClient) {}

  getById(sampleId: string): Observable<SampleGetById> {
    return this.httpClient.get<SampleGetById>(`${this.url}/${sampleId}`);
  }

  update(
    sampleId: string,
    sampleType: SampleType,
    sampleSaveParticular: SampleSaveParticular,
  ): Observable<null> {
    return this.httpClient.put<null>(
      `${this.url}/${sampleType}/${sampleId}`,
      sampleSaveParticular,
      {
        headers: { [PASS_ERROR_HEADER]: '' },
      },
    );
  }

  create(
    sampleSaveParticular: SampleSaveParticular,
    sampleType: SampleType,
  ): Observable<SampleSaveParticular> {
    return this.httpClient.post<SampleSaveParticular>(
      `${this.url}/${sampleType}`,
      sampleSaveParticular,
      {
        headers: { [PASS_ERROR_HEADER]: '' },
      },
    );
  }

  searchByBarcode(barcodeId: string): Observable<SampleSearch[]> {
    return this.httpClient.get<SampleSearch[]>(`${this.url}/BarQrCode/${barcodeId}`);
  }

  getOrientations(): Observable<Array<IdName>> {
    return this.httpClient.get<Array<IdName>>(this.orientationsUrl);
  }

  getPreservationTypes(): Observable<Array<IdName>> {
    return this.httpClient.get<Array<IdName>>(this.preservationsTypesUrl);
  }

  getRestrictionTypes(): Observable<Array<IdName>> {
    return this.httpClient.get<Array<IdName>>(this.limitationUrl);
  }

  getCheckoutPurposes(): Observable<Array<string>> {
    return this.httpClient.get<Array<string>>(this.purposesUrl);
  }

  getSectionTypes(): Observable<Array<IdName>> {
    return this.httpClient.get<Array<IdName>>(this.sectionTypesUrl);
  }

  getSectionOrigins(): Observable<Array<IdName>> {
    return this.httpClient.get<Array<IdName>>(this.sectionOriginsUrl);
  }

  getStainings(): Observable<Array<IdName>> {
    return this.httpClient.get<Array<IdName>>(this.stainingsUrl);
  }

  checkOut(model: SampleCheckOut): Observable<void> {
    return this.httpClient.post<void>(this.checkoutUrl, model);
  }

  createComposite(compositeSample: CompositeSample): Observable<CompositeSample> {
    return this.httpClient.post<CompositeSample>(this.compositeUrl, compositeSample, {
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }

  updateComposite(compositeSample: CompositeSample, sampleId: string): Observable<CompositeSample> {
    return this.httpClient.put<CompositeSample>(
      `${this.compositeUrl}/${sampleId}`,
      compositeSample,
      {
        headers: { [PASS_ERROR_HEADER]: '' },
      },
    );
  }

  returnRequest(returnSample: ReturnSampleRequest): Observable<ReturnSampleRequest> {
    return this.httpClient.post<ReturnSampleRequest>(`${this.returnUrl}`, returnSample);
  }

  getFilteredSamples(filteredData: SampleTypesFilter): Observable<DashboardFilterResult[]> {
    const parameters = new HttpParams()
      .set('StartDate', filteredData.startDate)
      .set('EndDate', filteredData.endDate)
      .set('Display', filteredData.display)
      .appendAll({ Teams: filteredData.teams })
      .appendAll(filteredData.sampleTypes)
      .set('SampleAvailability.CheckedIn', filteredData.sampleAvailability.checkedIn)
      .set('SampleAvailability.CheckedOut', filteredData.sampleAvailability.checkedOut);

    return this.httpClient.get<Array<DashboardFilterResult>>(`${this.filterUrl}/types`, {
      params: parameters,
    });
  }
}
