import { LocationPageState } from 'src/app/features/location/state/location-page.state';

export class CreateLocationPageState extends LocationPageState {
  override get isActionBtnVisible(): boolean {
    return true;
  }

  override get isActionBtnAndPrintVisible(): boolean {
    return this.component.isAdmin;
  }

  override get isCancelBtnVisible(): boolean {
    return true;
  }

  override get actionBtnName(): string {
    return this.component.isAdmin ? 'Create' : 'Request';
  }

  override get title(): string {
    return this.component.isAdmin ? 'Create Location' : 'Request Location';
  }

  override get saveMethodName(): 'create' {
    return 'create';
  }

  override get successfullySavedNotificationMessage(): string {
    return this.component.isAdmin ? 'Location has been created' : 'Location has been requested';
  }

  override onSuccessSaved(withPrint: boolean): void {
    if (withPrint) {
      this.component.openPrintPopup();
    }

    this.component.resetForm();
    this.component.updateLocationNodes();
  }

  override get isEditable(): boolean {
    return true;
  }
}
