import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';

import { IdName } from '@core/models/id-name.model';
import { FlatFormValidationMessage } from '@core/utils/form/flat-form-validation-message.class';
import { StopPropagationOnClickModule } from 'src/app/common/directives/stop-propagation-on-click.directive';

import { UnitInputModule } from '../../common/unit-input/unit-input.component';
import {
  DynamicExperimentTemplateComponent,
  DynamicExperimentTemplateModule,
} from '../work-order/dynamic-experiment-template/dynamic-experiment-template.component';
import { HAS_INNER_CONTROLS } from '../work-order/form/has-inner-controls.token';
import { WORK_ORDER_ERROR_MESSAGE_CONFIG } from '../work-order/form/work-order-form-field-error-message.config';
import { CanDetectChanges } from '../work-order/models/can-detect-changes.model';
import { HasInnerControls } from '../work-order/models/has-inner-controls.model';
import { Pcri } from './models/pcri.model';
import { PcriFormShapeImpl } from './models/pcri-form-shape.model';
import { PCRI_LABEL, PcriFormField } from './utils/field-configs/pcri.field-config';
import {
  PCRI_EXPERIMENT_INFORMATION_LABEL,
  PcriExperimentInformationFormField,
} from './utils/field-configs/pcri-experiment-information.field-config';
import {
  PCRI_GENERAL_INFORMATION_LABEL,
  PcriGeneralInformationFormField,
} from './utils/field-configs/pcri-general-information.field-config';
import {
  PCRI_STATUS_LABEL,
  PcriStatusFormField,
} from './utils/field-configs/pcri-status.field-config';
import { generatePcriForm } from './utils/generate-pcri-form.util';

@Component({
  selector: 'app-pcri-form',
  templateUrl: './pcri-form.component.html',
  styleUrls: ['./pcri-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PcriFormComponent implements OnInit {
  @Input() pcriFormGroup!: FormGroup;
  @Input() title!: string;
  @Input() formData!: Pcri;
  @Input() experimentTypeList: IdName[] = [];

  @ViewChild(DynamicExperimentTemplateComponent)
  dynamicExperimentTemplateComponent!: DynamicExperimentTemplateComponent;

  @ViewChildren(HAS_INNER_CONTROLS) componentsWhichHasInnerControls!: QueryList<HasInnerControls>;

  constructor(private formBuilder: FormBuilder, private cd: ChangeDetectorRef) {}

  readonly pcriFormField = PcriFormField;
  readonly pcriGeneralInformationFormField = PcriGeneralInformationFormField;
  readonly pcriStatusFormField = PcriStatusFormField;
  readonly pcriExperimentInformationFormField = PcriExperimentInformationFormField;

  readonly pcriLabel = PCRI_LABEL;
  readonly pcriGeneralInformationLabel = PCRI_GENERAL_INFORMATION_LABEL;

  readonly pcriExperimentInformationLabel = PCRI_EXPERIMENT_INFORMATION_LABEL;
  readonly pcriStatusLabel = PCRI_STATUS_LABEL;

  pcriGeneralInformationValidationMessage!: FlatFormValidationMessage<
    typeof PcriGeneralInformationFormField
  >;

  pcriStatusValidationMessage!: FlatFormValidationMessage<typeof PcriStatusFormField>;

  validationMessageList!: FlatFormValidationMessage<any>[];

  get dynamicValidationMessageList() {
    return this.validationMessageList;
  }

  get componentsWithInnerFormControls(): CanDetectChanges[] {
    return this.componentsWhichHasInnerControls
      ? [
          this,
          ...this.componentsWhichHasInnerControls
            .toArray()
            .reduce(
              (acc, cmp) => [...acc, ...cmp.componentsWithInnerFormControls],
              [] as CanDetectChanges[],
            ),
        ]
      : [this];
  }

  detectChanges(): void {
    this.cd.detectChanges();
  }

  ngOnInit() {
    this.addPcriForm();
    this.setUpValidationMessages();
    this.validationMessageList = [
      this.pcriGeneralInformationValidationMessage,
      this.pcriStatusValidationMessage,
    ];
  }

  addPcriForm() {
    this.pcriFormGroup = generatePcriForm(this.formBuilder, new PcriFormShapeImpl(this.formData));
  }

  clearDate(control: AbstractControl): void {
    control!.setValue('');
  }

  private setUpValidationMessages(): void {
    this.pcriGeneralInformationValidationMessage = new FlatFormValidationMessage(
      this.pcriFormGroup.get(PcriFormField.GENERAL_INFORMATION) as FormGroup,
      PCRI_GENERAL_INFORMATION_LABEL,
      WORK_ORDER_ERROR_MESSAGE_CONFIG,
    );

    this.pcriStatusValidationMessage = new FlatFormValidationMessage(
      this.pcriFormGroup.get(PcriFormField.STATUS) as FormGroup,
      PCRI_STATUS_LABEL,
      WORK_ORDER_ERROR_MESSAGE_CONFIG,
    );
  }
}

@NgModule({
  declarations: [PcriFormComponent],
  exports: [PcriFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatExpansionModule,
    UnitInputModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    MatCheckboxModule,
    StopPropagationOnClickModule,
    DynamicExperimentTemplateModule,
    MatLegacySelectModule,
  ],
})
export class PcriFormModule {}
