import { Subject } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FilterEventService {
  filterPopupClosed = new Subject<boolean>();
  pickedLocationTypes: string[] = [];
  pickedLocationRestrictions: string[] = [];

  clearPickedTypesAndRestrictions(): void {
    this.pickedLocationTypes = [];
    this.pickedLocationRestrictions = [];
  }
}
