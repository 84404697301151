import { ExperimentField } from '../../work-order/models/experiment-field.model';
import { Pcri } from './pcri.model';

export interface GeneralInformation {
  id: string;
  name: string;
  description: string;
  proponentEmail: string;
  proponentName: string;
  startDate: string;
  expectDate: string;
}

export interface Status {
  progress: number | null;
  completionDate: string;
  completed: boolean;
}

export interface ExperimentDetail {
  experimentType?: string;
  experimentFields?: ExperimentField[];
}

export interface PcriFormShape {
  generalInformation: GeneralInformation;
  status: Status;
  experimentDetail: ExperimentDetail;
}

export class PcriFormShapeImpl implements PcriFormShape {
  generalInformation: GeneralInformation;
  status: Status;
  experimentDetail: ExperimentDetail;

  constructor(data?: Pcri) {
    this.generalInformation = {
      id: data?.id ?? '',
      name: data?.name ?? '',
      description: data?.description ?? '',
      proponentEmail: data?.proponentEmail ?? '',
      proponentName: data?.proponentName ?? '',
      startDate: data?.startDate ?? '',
      expectDate: data?.expectDate ?? '',
    };
    this.status = {
      progress: data?.progress ?? null,
      completionDate: data?.completionDate ?? '',
      completed: data?.completed ?? false,
    };
    this.experimentDetail = {
      experimentType: data?.experimentTypeId ?? '',
      experimentFields: data?.experimentFields ?? [],
    };
  }
}
