import { CommonModule, Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  NgModule,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ActivatedRoute } from '@angular/router';

import { ComponentAction } from '@core/enums/component-action.enum';
import { WellInformation } from '@core/models/well-information.model';
import { FlatFormValidationMessage } from '@core/utils/form/flat-form-validation-message.class';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { NotificationService } from 'src/app/services/notification.service';

import { WellFormField } from './form/well-form-field.enum';
import { WELL_FORM_FIELD_NAME_TO_DISPLAY } from './form/well-form-field-name-to-display.config';
import { WellInformationService } from './services/well-information.service';

@Component({
  selector: 'app-well-information',
  templateUrl: './well-information.component.html',
  styleUrls: ['./well-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WellInformationComponent extends Destroyable(Object) implements OnInit {
  readonly wellFormField = WellFormField;
  readonly wellFormFieldNameToDisplay = WELL_FORM_FIELD_NAME_TO_DISPLAY;
  readonly componentAction = ComponentAction;

  action = ComponentAction.Create;
  wellInformation!: WellInformation;
  wellFormGroup!: UntypedFormGroup;
  flatFormValidationMessage!: FlatFormValidationMessage<typeof WellFormField>;

  constructor(
    private wellInformationService: WellInformationService,
    private route: ActivatedRoute,
    private toastService: NotificationService,
    private cd: ChangeDetectorRef,
    private location: Location,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.handleWellFromRoute();
  }

  get nameFormControl(): UntypedFormControl {
    return this.wellFormGroup.get(this.wellFormField.NAME) as UntypedFormControl;
  }

  onSubmitClicked(): void {
    const result: WellInformation = this.wellFormGroup.getRawValue();
    if (this.action === ComponentAction.Create) {
      this.wellInformationService
        .createWell(result)
        .pipe(this.takeUntilDestroyed())
        .subscribe((_) => {
          this.toastService.notifySuccess(`Well ${result.name} is successfully created`);
          this.navigateBack();
        });
    } else {
      this.wellInformation = { ...result, id: this.wellInformation.id };
      this.wellInformationService
        .editWell(this.wellInformation)
        .pipe(this.takeUntilDestroyed())
        .subscribe((_) => {
          this.toastService.notifySuccess(`Well ${result.name} is successfully updated`);
          this.navigateBack();
        });
    }
  }

  onCancelClicked(): void {
    this.setFormValues(
      this.wellInformation ?? {
        name: '',
        field: '',
        location: '',
        reservoir: '',
      },
    );
    this.navigateBack();
  }

  navigateBack(): void {
    this.location.back();
  }

  private handleWellFromRoute(): void {
    this.route.paramMap.pipe(this.takeUntilDestroyed()).subscribe((data) => {
      const dataFromRoute = JSON.parse(data.get('data') as string);

      if (dataFromRoute) {
        this.action = ComponentAction.Edit;
        this.wellInformation = { ...dataFromRoute, name: dataFromRoute.name };
        this.setFormValues(this.wellInformation);
      } else {
        this.action = ComponentAction.Create;
        this.setFormValues({
          name: '',
          field: '',
          location: '',
          reservoir: '',
        });
      }
      this.cd.detectChanges();
    });
  }

  private initForm(): void {
    this.wellFormGroup = new UntypedFormGroup({
      [this.wellFormField.NAME]: new UntypedFormControl('', [Validators.required]),
      [this.wellFormField.FIELD]: new UntypedFormControl(null),
      [this.wellFormField.LOCATION]: new UntypedFormControl(null),
      [this.wellFormField.RESERVOIR]: new UntypedFormControl(null),
    });
  }

  private setFormValues(model: WellInformation): void {
    this.wellFormGroup.setValue({
      [this.wellFormField.NAME]: model.name ?? '',
      [this.wellFormField.FIELD]: model.field ?? '',
      [this.wellFormField.LOCATION]: model.location ?? '',
      [this.wellFormField.RESERVOIR]: model.reservoir ?? '',
    });
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [WellInformationService],
  declarations: [WellInformationComponent],
  exports: [WellInformationComponent],
})
export class WellInformationModule {}
