<app-sample-header [title]="'Check Out Sample'"></app-sample-header>
<form [formGroup]="mainFormGroup" autocomplete="off">
  <div class="fields-line">
    <div class="field-wrapper">
      <app-sample-table-selector-popup-field
        [formControlName]="sampleCheckOutFormField.SAMPLE_ID"
        [label]="sampleFormFieldLabel[sampleCheckOutFormField.SAMPLE_ID]"
        [getErrorMessage]="
          sampleCheckoutValidationMessage.getMessageFor.bind(
            sampleCheckoutValidationMessage,
            sampleCheckOutFormField.SAMPLE_ID
          )
        "
        [required]="true"
        (ngModelChange)="onSamplePicked($event)"
      ></app-sample-table-selector-popup-field>
    </div>

    <div class="field-wrapper">
      <app-tree-view-selector-popup-field
        [formControlName]="sampleCheckOutFormField.LOCATION_ID"
        [label]="sampleFormFieldLabel[sampleCheckOutFormField.LOCATION_ID]"
        [treeViewNodeList]="sampleLocations"
        [filterComponent]="locationTreeFilterComponent"
        [filterModule]="locationTreeFilterModule"
        [displayNamePipe]="locationDisplayNamePipe"
      ></app-tree-view-selector-popup-field>
    </div>
  </div>
  <div class="fields-line">
    <div class="field-wrapper">
      <mat-form-field>
        <mat-label>
          {{ sampleFormFieldLabel[sampleCheckOutFormField.PURPOSE] }}
        </mat-label>
        <mat-select
          [formControlName]="sampleCheckOutFormField.PURPOSE"
          [compareWith]="optionsCompareFn"
          (valueChange)="onPurposeChanged($event)"
          id="sample-check-out-purpose-select"
        >
          <mat-option *ngFor="let purpose of purposes" [value]="purpose.id">
            {{ purpose.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="purposeFormControl!.errors && purposeFormControl!.hasError(errorTypes.required)"
        >
          {{ errorMessages.required }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="field-wrapper">
      <mat-form-field>
        <mat-label>
          {{ sampleFormFieldLabel[sampleCheckOutFormField.MORE] }}
        </mat-label>
        <input
          matInput
          [formControlName]="sampleCheckOutFormField.MORE"
          id="sample-check-out-more-input"
        />
        <mat-error
          *ngIf="moreFormControl!.errors && moreFormControl!.hasError(errorTypes.required)"
        >
          {{ errorMessages.required }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="fields-line">
    <mat-form-field class="textarea-container">
      <mat-label>
        {{ sampleFormFieldLabel[sampleCheckOutFormField.NOTE] }}
      </mat-label>
      <textarea
        matInput
        [formControlName]="sampleCheckOutFormField.NOTE"
        id="sample-check-out-note-input"
      ></textarea>
    </mat-form-field>
  </div>
</form>

<div class="sample-details-wrapper">
  <div class="actions">
    <button
      class="btn btn-secondary btn-big"
      (click)="resetForm()"
      id="sample-check-out-cancel-btn"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary btn-big"
      (click)="validateAndCheckoutSample()"
      id="sample-check-out-confirm-btn"
    >
      Check-out
    </button>
  </div>

  <ng-container *ngIf="sampleId">
    <hr />
    <div [ngSwitch]="sample.type">
      <div *ngSwitchCase="'composite'">
        <app-composite-sample [sample]="sample" [isReadOnly]="true"></app-composite-sample>
      </div>
      <div *ngSwitchDefault>
        <app-sample [isReadOnly]="true" [sample]="sample"></app-sample>
      </div>
    </div>
  </ng-container>
</div>
