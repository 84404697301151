import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceEmptyString',
})
export class ReplaceEmptyStringPipe implements PipeTransform {
  transform(value: any, replaceText = 'N/A'): string {
    if (value === '') {
      return replaceText;
    }

    return value;
  }
}

@NgModule({
  imports: [],
  declarations: [ReplaceEmptyStringPipe],
  exports: [ReplaceEmptyStringPipe],
})
export class ReplaceEmptyStringModule {}
