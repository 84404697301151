import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';

import { USE_OKTA_AUTH_TOKEN } from '@core/tokens/use-okta-auth.token';

import { AccountInformationFormModule } from './account-information-form/account-information-form.component';
import { ChangePasswordFormModule } from './change-password-form/change-password-form.component';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSettingsComponent {
  readonly hideChangePassword = true;

  get isOktaAuthUsed(): boolean {
    return this.useOktaAuth;
  }

  constructor(@Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean) {}
}

@NgModule({
  declarations: [AccountSettingsComponent],
  exports: [AccountSettingsComponent],
  imports: [CommonModule, AccountInformationFormModule, ChangePasswordFormModule],
})
export class AccountSettingsModule {}
