import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { RenderType } from 'src/app/features/work-order/enums/render-type.enum';

@Pipe({
  name: 'inputType',
})
export class InputTypePipe implements PipeTransform {
  transform(renderType: RenderType): 'text' | 'number' {
    const renderTypeInputTypeMap: Partial<Record<RenderType, 'text' | 'number'>> = {
      [RenderType.INPUT_FLOAT_BOX]: 'number',
      [RenderType.INPUT_INT_BOX]: 'number',
      [RenderType.INPUT_STRING_BOX]: 'text',
      [RenderType.INPUT_TEXT_BOX]: 'text',
    };

    return renderTypeInputTypeMap[renderType] ?? 'text';
  }
}

@NgModule({
  declarations: [InputTypePipe],
  exports: [InputTypePipe],
})
export class InputTypeModule {}
