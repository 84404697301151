import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgModule,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-color-spectrum-picker',
  templateUrl: './color-spectrum-picker.component.html',
  styleUrls: ['./color-spectrum-picker.component.scss'],
  providers: [ColorPickerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorSpectrumPickerComponent implements OnChanges {
  @Output() colorSelected = new EventEmitter<string>();
  @Input() selectedColor!: string;

  colorWithChar = '';

  constructor(private cpService: ColorPickerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedColor']) {
      this.colorWithChar = `#${changes['selectedColor'].currentValue}`;
    }
  }

  onChange(color: string): void {
    const fixedColor = color.replace('#', '');
    this.colorSelected.emit(fixedColor);
  }
}

@NgModule({
  declarations: [ColorSpectrumPickerComponent],
  exports: [ColorSpectrumPickerComponent],
  imports: [CommonModule, ColorPickerModule, ReactiveFormsModule, FormsModule],
  providers: [ColorPickerService],
})
export class ColorSpectrumPickerModule {}
