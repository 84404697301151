<form [formGroup]="accountInformationFormGroup" autocomplete="off">
  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input
        matInput
        [formControlName]="accountInformationFormField.EMAIL"
        id="account-information-email-input"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Username</mat-label>
      <input
        matInput
        [formControlName]="accountInformationFormField.USER_NAME"
        id="account-information-name-input"
      />
    </mat-form-field>
  </div>

  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>Employee ID</mat-label>
      <input
        matInput
        [formControlName]="accountInformationFormField.EMPLOYEE_ID"
        id="account-information-employee-id-input"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Batch ID</mat-label>
      <input
        matInput
        [formControlName]="accountInformationFormField.BATCH_ID"
        id="account-information-batch-id-input"
      />
    </mat-form-field>
  </div>

  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>First name</mat-label>
      <input
        matInput
        [formControlName]="accountInformationFormField.FIRST_NAME"
        id="account-information-first-name-input"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Last name</mat-label>
      <input
        matInput
        [formControlName]="accountInformationFormField.LAST_NAME"
        id="account-information-last-name-input"
      />
    </mat-form-field>
  </div>

  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>Contact number</mat-label>
      <input
        matInput
        [formControlName]="accountInformationFormField.CONTACT_NUMBER"
        id="account-information-contact-number-input"
      />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Team</mat-label>
      <input
        matInput
        [formControlName]="accountInformationFormField.TEAM"
        id="account-information-team-input"
      />
    </mat-form-field>
  </div>

  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>Office address</mat-label>
      <input
        matInput
        [formControlName]="accountInformationFormField.OFFICE_ADDRESS"
        id="account-information-address-input"
      />
    </mat-form-field>
  </div>
</form>
