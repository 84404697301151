<h1 class="title">Organization settings</h1>

<form class="form" *ngIf="formGroup" [formGroup]="formGroup" autocomplete="off">
  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Application name' }}</mat-label>
      <input matInput [formControl]="appNameFormControl" id="app-name-input" />
      <mat-hint align="end">{{ appNameFormControl!.value.length }}/64</mat-hint>
      <mat-error *ngIf="appNameFormControl!.errors">{{
        flatFormValidationMessage.getMessageFor(organizationFormField.APP_NAME)
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Organization name' }}</mat-label>
      <input matInput [formControl]="organizationNameFormControl" id="organization-name-input" />
      <mat-hint align="end">{{ organizationNameFormControl!.value.length }}/64</mat-hint>
      <mat-error *ngIf="organizationNameFormControl!.errors">{{
        flatFormValidationMessage.getMessageFor(organizationFormField.ORGANIZATION_NAME)
      }}</mat-error>
    </mat-form-field>
  </div>
  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Slogan' }}</mat-label>
      <input matInput [formControl]="appSloganFormControl" id="slogan-name-input" />
      <mat-hint align="end">{{ appSloganFormControl!.value.length }}/64</mat-hint>
      <mat-error *ngIf="appSloganFormControl!.errors">{{
        flatFormValidationMessage.getMessageFor(organizationFormField.APP_SLOGAN)
      }}</mat-error>
    </mat-form-field>
  </div>
  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Description' }}</mat-label>
      <textarea
        matInput
        [formControl]="appDescriptionFormControl"
        id="description-input"
      ></textarea>
      <mat-hint align="end">{{ appDescriptionFormControl!.value.length }}/256</mat-hint>
      <mat-error *ngIf="appDescriptionFormControl!.errors">{{
        flatFormValidationMessage.getMessageFor(organizationFormField.APP_DESCRIPTION)
      }}</mat-error>
    </mat-form-field>
  </div>
  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Primary color' }}</mat-label>
      <input matInput [formControl]="primaryColorFormControl" id="primary-color-input" />
      <app-color-spectrum-picker
        matSuffix
        [selectedColor]="primaryColorFormControl!.value"
        (colorSelected)="onColorSelected($event, primaryColorFormControl)"
      ></app-color-spectrum-picker>
      <mat-error *ngIf="primaryColorFormControl!.errors">{{
        flatFormValidationMessage.getMessageFor(organizationFormField.PRIMARY_COLOR)
      }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Secondary color' }}</mat-label>
      <input matInput [formControl]="secondaryColorFormControl" id="secondary-color-input" />
      <app-color-spectrum-picker
        matSuffix
        [selectedColor]="secondaryColorFormControl!.value"
        (colorSelected)="onColorSelected($event, secondaryColorFormControl)"
      ></app-color-spectrum-picker>
      <mat-error *ngIf="secondaryColorFormControl!.errors">{{
        flatFormValidationMessage.getMessageFor(organizationFormField.SECONDARY_COLOR)
      }}</mat-error>
    </mat-form-field>
  </div>
  <div class="fields-line image-container">
    <div class="left">
      <div class="top image-block">
        <div class="title">Full icon image</div>
        <div
          class="image"
          [style.background-image]="'url(' + organizationFullIconImagePathFormControl.value + ')'"
        >
          <div class="upload">
            <button
              class="btn btn-primary upload-btn"
              type="button"
              appUpload
              [ext]="'.jpeg,.jpg,.png'"
              [extErrorMessage]="'Application accepts only JPG, JPEG and PNG image formats'"
              (filePicked)="uploadFile($event, organizationFullIconImagePathFormControl)"
            >
              <span class="upload-btn__text">Upload</span>
            </button>
          </div>
        </div>
      </div>
      <div class="bottom image-block">
        <div class="title">Small icon image</div>
        <div
          class="image"
          [style.background-image]="'url(' + organizationSmallIconImagePathFormControl.value + ')'"
        >
          <div class="upload">
            <button
              class="btn btn-primary upload-btn"
              type="button"
              appUpload
              [ext]="'.jpeg,.jpg,.png'"
              [extErrorMessage]="'Application accepts only JPG, JPEG and PNG image formats'"
              (filePicked)="uploadFile($event, organizationSmallIconImagePathFormControl)"
            >
              <span class="upload-btn__text">Upload</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="right image-block">
      <div class="title">Landing page background image</div>
      <div
        class="image"
        [style.background-image]="'url(' + landingPageBackgroundImagePathFormControl.value + ')'"
      >
        <div class="upload">
          <button
            class="btn btn-primary upload-btn"
            type="button"
            appUpload
            [ext]="'.jpeg,.jpg,.png'"
            [extErrorMessage]="'Application accepts only JPG, JPEG and PNG image formats'"
            (filePicked)="uploadFile($event, landingPageBackgroundImagePathFormControl)"
          >
            <span class="upload-btn__text">Upload</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="actions">
    <button class="btn btn-secondary btn-big" (click)="cancel()">Cancel</button>
    <button class="btn btn-primary btn-big" (click)="apply()">Apply</button>
  </div>
</form>
