<div class="title-wrapper">
  <button
    mat-icon-button
    data-ref="back-button"
    class="back-button"
    matTooltip="Back to locations"
    (click)="navigateBack()"
    id="location-back-btn"
  >
    <mat-icon class="icon-back">arrow_back_ios</mat-icon>
  </button>
  <h1 class="title">{{ state.title }}</h1>
</div>

<app-location-body
  *ngIf="initialLocation"
  [location]="initialLocation"
  [isEditable]="state.isEditable"
></app-location-body>

<div class="actions">
  <app-print-barcode-menu
    [barcodeValue]="
      locationBodyComponent?.locationForm?.getRawValue()[locationFormField.BARCODE_ID]
    "
    [qrCodeValue]="locationBodyComponent?.locationForm?.getRawValue()[locationFormField.QR_CODE_ID]"
    [printName]="printName"
  ></app-print-barcode-menu>
  <div class="main-buttons">
    <button
      *ngIf="state.isCancelBtnVisible"
      class="btn btn-secondary btn-big"
      (click)="navigateBack()"
      id="location-cancel-btn"
    >
      Cancel
    </button>
    <button
      *ngIf="state.isActionBtnVisible"
      class="btn btn-primary btn-big"
      (click)="save()"
      id="location-create-btn"
    >
      {{ state.actionBtnName }}
    </button>
    <button
      *ngIf="state.isActionBtnAndPrintVisible"
      data-ref="action-and-print-button"
      class="btn btn-primary btn-big"
      (click)="save(true)"
      id="location-create-print-btn"
    >
      {{ state.actionBtnName }} and print
    </button>
  </div>
</div>
