import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';

import { DataAccessRequestStatusNotificationModule } from './data-access-request-status-notification/data-access-request-status-notification.component';
import { LocationAccessRequestNotificationModule } from './location-access-request-notification/location-access-request-notification.component';
import { NotificationType } from './models/notification-type.enum';
import { SampleAccessRequestNotificationModule } from './sample-access-request-notification/sample-access-request-notification.component';

@Component({
  selector: 'app-dashboard-notification',
  templateUrl: './dashboard-notification.component.html',
  styleUrls: ['./dashboard-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardNotificationComponent {
  @Input() notificationType = NotificationType.SampleAccessRequest;
  @Input() notification!: any;

  readonly notificationTypeEnum = NotificationType;
}

@NgModule({
  declarations: [DashboardNotificationComponent],
  imports: [
    CommonModule,
    SampleAccessRequestNotificationModule,
    DataAccessRequestStatusNotificationModule,
    LocationAccessRequestNotificationModule,
  ],
  exports: [DashboardNotificationComponent],
})
export class DashboardNotificationModule {}
