<h1 class="title">Admin settings</h1>

<div class="table-wrapper">
  <table
    class="table custom-table"
    mat-table
    [dataSource]="tableData"
    matSort
    matSortActive="email"
    matSortDirection="asc"
  >
    <ng-container matColumnDef="email">
      <th class="email" mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td class="email" mat-cell *matCellDef="let row">{{ row.email }}</td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th class="firstName" mat-header-cell *matHeaderCellDef mat-sort-header>First name</th>
      <td class="firstName" mat-cell *matCellDef="let row">{{ row.firstName }}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th class="lastName" mat-header-cell *matHeaderCellDef mat-sort-header>Last name</th>
      <td class="lastName" mat-cell *matCellDef="let row">{{ row.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="team">
      <th class="team" mat-header-cell *matHeaderCellDef mat-sort-header>Team</th>
      <td class="team" mat-cell *matCellDef="let row">{{ row.team }}</td>
    </ng-container>

    <ng-container matColumnDef="lastActive">
      <th class="lastActive" mat-header-cell *matHeaderCellDef mat-sort-header>Last active</th>
      <td class="lastActive" mat-cell *matCellDef="let row">
        {{ row.lastActive | date: 'M/d/yyyy hh:mm:ss a' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="position">
      <th class="position" mat-header-cell *matHeaderCellDef mat-sort-header>Position</th>
      <td class="position" class="firstName" mat-cell *matCellDef="let row">{{ row.position }}</td>
    </ng-container>

    <ng-container matColumnDef="admin">
      <th class="admin" mat-header-cell *matHeaderCellDef mat-sort-header>Admin</th>
      <td class="admin" mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.admin">check-small</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="manager">
      <th class="manager" mat-header-cell *matHeaderCellDef mat-sort-header>Manager</th>
      <td class="manager" mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.manager">check-small</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th class="actions" mat-header-cell *matHeaderCellDef></th>
      <td class="actions" mat-cell *matCellDef="let row">
        <button
          matTooltip="Change password"
          matTooltipPosition="above"
          class="btn btn-secondary btn-small"
          (click)="onChangePasswordClick(row)"
        >
          <mat-icon>password</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row table-row" *matNoDataRow>
      <td class="mat-cell" colspan="9">
        {{
          !users?.length
            ? 'No data. Start typing in the search box above'
            : 'No data matching the filter value'
        }}
      </td>
    </tr>
  </table>

  <mat-card>
    <button
      mat-button
      (click)="onCreateNewUserClick()"
      class="btn create"
      id="create-new-user-action-btn"
    >
      <mat-icon>add</mat-icon>
      <span>{{ createNewUserButtonText }}</span>
    </button>

    <mat-paginator
      class="mat-paginator"
      [pageSizeOptions]="[5, 10, 20, 50, 100]"
      [pageSize]="10"
      aria-label="Select page"
    ></mat-paginator>
  </mat-card>
</div>
