import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API, PASS_ERROR_HEADER } from '@core/constants/api.consts';
import { ChangePassword } from '@core/models/change-password.model';
import { Token } from '@core/models/token.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private url = `${CORE_MEMORY_API}/Authentication`;
  private loginUrl = `${this.url}/login`;
  private registerUrl = `${this.url}/register`;
  private changePasswordUrl = `${this.url}/changepassword`;
  private refreshTokenUrl = `${this.url}/refreshToken`;

  constructor(private httpClient: HttpClient) {}

  login(email: string, password: string): Observable<Token> {
    return this.httpClient.post<Token>(this.loginUrl, { email, password });
  }

  register(email: string, password: string): Observable<null> {
    return this.httpClient.post<null>(this.registerUrl, { email, password });
  }

  refresh(accessToken: string, refreshToken: string): Observable<Token> {
    return this.httpClient.post<Token>(
      this.refreshTokenUrl,
      {
        accessToken,
        refreshToken,
      },
      {
        headers: { [PASS_ERROR_HEADER]: '' },
      },
    );
  }

  changePassword(payload: ChangePassword): Observable<null> {
    return this.httpClient.put<null>(this.changePasswordUrl, payload, {
      headers: { [PASS_ERROR_HEADER]: '' },
    });
  }
}
