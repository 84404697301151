import { IdName } from '@core/models/id-name.model';

import { RenderType } from '../enums/render-type.enum';
import { ExperimentField } from '../models/experiment-field.model';

export enum ExistingExperimentType {
  RELATIVE_PERMEABILITY_UNSTEADY_STATE = 'Relative Permeability Unsteady State',
  CAPILLARY_PRESSURE_POROUS_PLATE_AND_ELECTRICAL_PROPERTY = 'Capillary Pressure Porous Plate and Electrical Property',
  CAPILLARY_PRESSURE_MICP = 'Capillary Pressure MICP',
  NMR_T_1 = 'NMR T1',
  NMR_T_2 = 'NMR T2',
  WETTABILITY_AMOTT_HARVEY = 'Wettability Amott-Harvey',
  FORMATION_FACTOR_SINGLE_STRESS = 'Formation Factor Single Stress',
  ROUTINE_CORE_ANALYSIS_RCA = 'Routine Core Analysis (RCA)',
}

export enum ChangedExperimentType {
  SCAL_USS_RELATIVE_PERMEABILITY = 'SCAL - USS relative permeability',
  SCAL_POROUS_PLATE_PC = 'SCAL - Porous plate Pc',
  SCAL_MERCURY_INJECTION_PC = 'SCAL - Mercury injection Pc',
  SCAL_NMR_T_1 = 'SCAL - NMR T1',
  SCAL_NMR_T_2 = 'SCAL - NMR T2',
  SCAL_AMOTT_WETTABILITY = 'SCAL - Amott wettability',
  SCAL_FRF_SINGLE_STRESS = 'SCAL - FRF Single stress',
  RCAL_ROUTINE_CORE_ANALYSIS = 'RCAL - Routine core analysis',
}

export enum NewExperimentType {
  CCA_CORE_GAMMA = 'CCA - Core gamma',
  CCA_CORE_HANDLING_AND_PROCESSING = 'CCA - Core handling and processing',
  CCA_CORE_LAYOUT_MARKING = 'CCA - Core layout marking',
  CCA_DATA_GENERATION = 'CCA - Data generation',
  CCA_GRAIN_DENSITY = 'CCA - Grain density',
  CCA_OIL_AND_WATER_SATURATION = 'CCA - Oil and water saturation',
  CCA_PLUGGING_VERTICAL_AND_HORIZONTAL_PLUG = 'CCA - Plugging (vertical and horizontal plug)',
  CCA_POROSITY_AND_PERMEABILITY = 'CCA - Porosity and permeability',
  CCA_WHOLE_CORE_CT_SCAN = 'CCA - Whole core CT-scan',
  SCAL_CENTRIFUGE_PC = 'SCAL - Centrifuge Pc',
  SCAL_ELECTRICAL_PROPERTIES_M_AND_N = 'SCAL - Electrical properties (m & n)',
  SCAL_IFT_CONTACT_ANGLE = 'SCAL - IFT/Contact angle',
  SCAL_LIQUID_PERM = 'SCAL - Liquid perm',
  SCAL_PLUG_CT_SCAN = 'SCAL - Plug CT-scan',
  SCAL_ROCK_MECHANICS = 'SCAL - Rock mechanics',
  SCAL_SS_RELATIVE_PERMEABILITY = 'SCAL - SS relative permeability',
  SCAL_UNCONVENTIONAL_PERMEABILITY = 'SCAL - Unconventional permeability',
}

export const experimentTypeMap: Record<ExistingExperimentType, ChangedExperimentType> = {
  [ExistingExperimentType.RELATIVE_PERMEABILITY_UNSTEADY_STATE]:
    ChangedExperimentType.SCAL_USS_RELATIVE_PERMEABILITY,
  [ExistingExperimentType.CAPILLARY_PRESSURE_POROUS_PLATE_AND_ELECTRICAL_PROPERTY]:
    ChangedExperimentType.SCAL_POROUS_PLATE_PC,
  [ExistingExperimentType.CAPILLARY_PRESSURE_MICP]: ChangedExperimentType.SCAL_MERCURY_INJECTION_PC,
  [ExistingExperimentType.NMR_T_1]: ChangedExperimentType.SCAL_NMR_T_1,
  [ExistingExperimentType.NMR_T_2]: ChangedExperimentType.SCAL_NMR_T_2,
  [ExistingExperimentType.WETTABILITY_AMOTT_HARVEY]: ChangedExperimentType.SCAL_AMOTT_WETTABILITY,
  [ExistingExperimentType.FORMATION_FACTOR_SINGLE_STRESS]:
    ChangedExperimentType.SCAL_FRF_SINGLE_STRESS,
  [ExistingExperimentType.ROUTINE_CORE_ANALYSIS_RCA]:
    ChangedExperimentType.RCAL_ROUTINE_CORE_ANALYSIS,
};

export const minHardcodedExperimentTypeId = '1';
export const maxHardcodedExperimentTypeId = '17';

export const hardcodedExperimentTypes: IdName[] = [
  {
    id: minHardcodedExperimentTypeId,
    name: NewExperimentType.CCA_CORE_GAMMA,
  },
  {
    id: '2',
    name: NewExperimentType.CCA_CORE_HANDLING_AND_PROCESSING,
  },
  {
    id: '3',
    name: NewExperimentType.CCA_CORE_LAYOUT_MARKING,
  },
  {
    id: '4',
    name: NewExperimentType.CCA_DATA_GENERATION,
  },
  {
    id: '5',
    name: NewExperimentType.CCA_GRAIN_DENSITY,
  },
  {
    id: '6',
    name: NewExperimentType.CCA_OIL_AND_WATER_SATURATION,
  },
  {
    id: '7',
    name: NewExperimentType.CCA_PLUGGING_VERTICAL_AND_HORIZONTAL_PLUG,
  },
  {
    id: '8',
    name: NewExperimentType.CCA_POROSITY_AND_PERMEABILITY,
  },
  {
    id: '9',
    name: NewExperimentType.CCA_WHOLE_CORE_CT_SCAN,
  },
  {
    id: '10',
    name: NewExperimentType.SCAL_CENTRIFUGE_PC,
  },
  {
    id: '11',
    name: NewExperimentType.SCAL_ELECTRICAL_PROPERTIES_M_AND_N,
  },
  {
    id: '12',
    name: NewExperimentType.SCAL_IFT_CONTACT_ANGLE,
  },
  {
    id: '13',
    name: NewExperimentType.SCAL_LIQUID_PERM,
  },
  {
    id: '14',
    name: NewExperimentType.SCAL_PLUG_CT_SCAN,
  },
  {
    id: '15',
    name: NewExperimentType.SCAL_ROCK_MECHANICS,
  },
  {
    id: '16',
    name: NewExperimentType.SCAL_SS_RELATIVE_PERMEABILITY,
  },
  {
    id: maxHardcodedExperimentTypeId,
    name: NewExperimentType.SCAL_UNCONVENTIONAL_PERMEABILITY,
  },
];

export const experimentTypeOrder: Record<NewExperimentType | ChangedExperimentType, number> = {
  [NewExperimentType.CCA_CORE_GAMMA]: 0,
  [NewExperimentType.CCA_CORE_HANDLING_AND_PROCESSING]: 1,
  [NewExperimentType.CCA_CORE_LAYOUT_MARKING]: 2,
  [NewExperimentType.CCA_DATA_GENERATION]: 3,
  [NewExperimentType.CCA_GRAIN_DENSITY]: 4,
  [NewExperimentType.CCA_OIL_AND_WATER_SATURATION]: 5,
  [NewExperimentType.CCA_PLUGGING_VERTICAL_AND_HORIZONTAL_PLUG]: 6,
  [NewExperimentType.CCA_POROSITY_AND_PERMEABILITY]: 7,
  [NewExperimentType.CCA_WHOLE_CORE_CT_SCAN]: 8,
  [ChangedExperimentType.RCAL_ROUTINE_CORE_ANALYSIS]: 9,
  [ChangedExperimentType.SCAL_AMOTT_WETTABILITY]: 10,
  [NewExperimentType.SCAL_CENTRIFUGE_PC]: 11,
  [NewExperimentType.SCAL_ELECTRICAL_PROPERTIES_M_AND_N]: 12,
  [ChangedExperimentType.SCAL_FRF_SINGLE_STRESS]: 13,
  [NewExperimentType.SCAL_IFT_CONTACT_ANGLE]: 14,
  [NewExperimentType.SCAL_LIQUID_PERM]: 15,
  [ChangedExperimentType.SCAL_MERCURY_INJECTION_PC]: 16,
  [ChangedExperimentType.SCAL_NMR_T_1]: 17,
  [ChangedExperimentType.SCAL_NMR_T_2]: 18,
  [NewExperimentType.SCAL_PLUG_CT_SCAN]: 19,
  [ChangedExperimentType.SCAL_POROUS_PLATE_PC]: 20,
  [NewExperimentType.SCAL_ROCK_MECHANICS]: 21,
  [NewExperimentType.SCAL_SS_RELATIVE_PERMEABILITY]: 22,
  [NewExperimentType.SCAL_UNCONVENTIONAL_PERMEABILITY]: 23,
  [ChangedExperimentType.SCAL_USS_RELATIVE_PERMEABILITY]: 24,
};

export const newExperimentTypeFields: ExperimentField[] = [
  {
    fieldType: RenderType.INPUT_STRING_BOX,
    fieldName: 'comment',
    unitValue: '',
    fieldPosition: 0,
    fieldValue: '',
    fieldDisplayName: 'Comment',
    required: false,
    fieldOptions: [],
    fieldDefaultOption: '',
    unitDefault: '',
    unitName: '',
    unitOptions: [],
  },
];
