import { ChangeDetectionStrategy, Component, Inject, NgModule, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
} from '@angular/material/legacy-dialog';

import { LookupSampleModule } from 'src/app/features/data-access/lookup-sample/lookup-sample.component';

import { SampleDisplayNameModule } from '../pipes/sample-display-name.pipe';

@Component({
  selector: 'app-sample-table-selector-popup',
  templateUrl: './sample-table-selector-popup.component.html',
  styleUrls: ['./sample-table-selector-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SampleTableSelectorPopupComponent implements OnInit {
  allowedSampleTypes?: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    const dataObject = JSON.parse(JSON.stringify(this.data));
    this.allowedSampleTypes = dataObject?.allowedSampleTypes;
  }
}

@NgModule({
  imports: [MatDialogModule, LookupSampleModule, SampleDisplayNameModule],
  declarations: [SampleTableSelectorPopupComponent],
})
export class SampleTableSelectorPopupModule {}
