import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AbstractControl, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { IdName } from '@core/models/id-name.model';
import { FlatFormValidationMessage } from '@core/utils/form/flat-form-validation-message.class';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import {
  DynamicExperimentTemplateComponent,
  DynamicExperimentTemplateModule,
} from 'src/app/features/work-order/dynamic-experiment-template/dynamic-experiment-template.component';
import {
  WORK_ORDER_NAME_TO_DISPLAY,
  WorkOrderFormField,
} from 'src/app/features/work-order/form/field-configs/work-order.field-config';
import {
  WORK_ORDER_EXPERIMENT_DETAIL_NAME_TO_DISPLAY,
  WorkOrderExperimentDetailFormField,
} from 'src/app/features/work-order/form/field-configs/work-order-experiment-detail.field-config';
import {
  WORK_ORDER_GENERAL_INFORMATION_NAME_TO_DISPLAY,
  WorkOrderGeneralInformationFormField,
} from 'src/app/features/work-order/form/field-configs/work-order-general-information.field-config';
import {
  WORK_ORDER_VENDOR_INFORMATION_NAME_TO_DISPLAY,
  WorkOrderVendorInformationFormField,
} from 'src/app/features/work-order/form/field-configs/work-order-vendor-information.field-config';
import { HAS_INNER_CONTROLS } from 'src/app/features/work-order/form/has-inner-controls.token';
import { WORK_ORDER_ERROR_MESSAGE_CONFIG } from 'src/app/features/work-order/form/work-order-form-field-error-message.config';
import { CanDetectChanges } from 'src/app/features/work-order/models/can-detect-changes.model';
import { ExperimentField } from 'src/app/features/work-order/models/experiment-field.model';
import { HasInnerControls } from 'src/app/features/work-order/models/has-inner-controls.model';

import { DisabledElementModule } from '../../../common/directives/disabled-element.directive';
import { StopPropagationOnClickModule } from '../../../common/directives/stop-propagation-on-click.directive';
import { TreeViewSelectorPopupFieldModule } from '../../../common/tree-view-selector-popup-field/tree-view-selector-popup-field.component';
import {
  WORK_ORDER_STATUS_NAME_TO_DISPLAY,
  WorkOrderStatusFormField,
} from '../form/field-configs/work-order-status.field-config';

@Component({
  selector: 'app-work-order-form',
  templateUrl: './work-order-form.component.html',
  styleUrls: ['./work-order-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: HAS_INNER_CONTROLS,
      useExisting: WorkOrderFormComponent,
    },
  ],
})
export class WorkOrderFormComponent
  extends Destroyable(Object)
  implements OnInit, HasInnerControls
{
  @Input() workOrderFormGroup!: UntypedFormGroup;
  @Input() vendorNameList: IdName[] = [];
  @Input() experimentTypeList: IdName[] = [];
  @Input() defaultExperimentFields: ExperimentField[] = [];
  @Input() sampleId: string | null = null;
  @Input() expandFirst = false;

  @ViewChild(DynamicExperimentTemplateComponent)
  dynamicExperimentTemplateComponent!: DynamicExperimentTemplateComponent;

  @ViewChildren(HAS_INNER_CONTROLS) componentsWhichHasInnerControls!: QueryList<HasInnerControls>;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  readonly workOrderFormField = WorkOrderFormField;
  readonly workOrderGeneralInformationFormField = WorkOrderGeneralInformationFormField;
  readonly workOrderStatusFormField = WorkOrderStatusFormField;
  readonly workOrderExperimentDetailFormField = WorkOrderExperimentDetailFormField;
  readonly workOrderVendorInformationFormField = WorkOrderVendorInformationFormField;

  readonly workOrderNameToDisplay = WORK_ORDER_NAME_TO_DISPLAY;
  readonly workOrderGeneralInformationNameToDisplay =
    WORK_ORDER_GENERAL_INFORMATION_NAME_TO_DISPLAY;

  readonly workOrderExperimentDetailNameToDisplay = WORK_ORDER_EXPERIMENT_DETAIL_NAME_TO_DISPLAY;
  readonly workOrderVendorInformationNameToDisplay = WORK_ORDER_VENDOR_INFORMATION_NAME_TO_DISPLAY;
  readonly workOrderStatusNameToDisplay = WORK_ORDER_STATUS_NAME_TO_DISPLAY;

  workOrderValidationMessage!: FlatFormValidationMessage<typeof WorkOrderFormField>;
  workOrderExperimentDetailValidationMessage!: FlatFormValidationMessage<
    typeof WorkOrderExperimentDetailFormField
  >;

  workOrderGeneralInformationValidationMessage!: FlatFormValidationMessage<
    typeof WorkOrderGeneralInformationFormField
  >;

  workOrderStatusValidationMessage!: FlatFormValidationMessage<typeof WorkOrderStatusFormField>;

  workOrderVendorInformationValidationMessage!: FlatFormValidationMessage<
    typeof WorkOrderVendorInformationFormField
  >;

  validationMessageList!: FlatFormValidationMessage<any>[];
  experimentConstants: ExperimentField[] = [];

  get dynamicValidationMessageList() {
    const { experimentFieldsValidationMessage } = this.dynamicExperimentTemplateComponent;
    return experimentFieldsValidationMessage
      ? [
          this.workOrderGeneralInformationValidationMessage,
          this.workOrderStatusValidationMessage,
          this.workOrderVendorInformationValidationMessage,
          experimentFieldsValidationMessage,
          this.workOrderExperimentDetailValidationMessage,
          this.workOrderValidationMessage,
        ]
      : this.validationMessageList;
  }

  get componentsWithInnerFormControls(): CanDetectChanges[] {
    return this.componentsWhichHasInnerControls
      ? [
          this,
          ...this.componentsWhichHasInnerControls
            .toArray()
            .reduce(
              (acc, cmp) => [...acc, ...cmp.componentsWithInnerFormControls],
              [] as CanDetectChanges[],
            ),
        ]
      : [this];
  }

  detectChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.setUpValidationMessages();
    this.validationMessageList = [
      this.workOrderGeneralInformationValidationMessage,
      this.workOrderStatusValidationMessage,
      this.workOrderVendorInformationValidationMessage,
      this.workOrderExperimentDetailValidationMessage,
      this.workOrderValidationMessage,
    ];
  }

  clearDate(control: AbstractControl): void {
    control!.setValue('');
  }

  private setUpValidationMessages(): void {
    this.workOrderValidationMessage = new FlatFormValidationMessage(
      this.workOrderFormGroup,
      WORK_ORDER_NAME_TO_DISPLAY,
      WORK_ORDER_ERROR_MESSAGE_CONFIG,
    );

    this.workOrderGeneralInformationValidationMessage = new FlatFormValidationMessage(
      this.workOrderFormGroup.get(WorkOrderFormField.GENERAL_INFORMATION) as UntypedFormGroup,
      WORK_ORDER_GENERAL_INFORMATION_NAME_TO_DISPLAY,
      WORK_ORDER_ERROR_MESSAGE_CONFIG,
    );

    this.workOrderStatusValidationMessage = new FlatFormValidationMessage(
      this.workOrderFormGroup.get(WorkOrderFormField.WORK_ORDER_STATUS) as UntypedFormGroup,
      WORK_ORDER_STATUS_NAME_TO_DISPLAY,
      WORK_ORDER_ERROR_MESSAGE_CONFIG,
    );

    this.workOrderVendorInformationValidationMessage = new FlatFormValidationMessage(
      this.workOrderFormGroup.get(WorkOrderFormField.VENDOR_INFORMATION) as UntypedFormGroup,
      WORK_ORDER_VENDOR_INFORMATION_NAME_TO_DISPLAY,
      WORK_ORDER_ERROR_MESSAGE_CONFIG,
    );

    this.workOrderExperimentDetailValidationMessage = new FlatFormValidationMessage(
      this.workOrderFormGroup.get(WorkOrderFormField.EXPERIMENT_DETAIL) as UntypedFormGroup,
      WORK_ORDER_EXPERIMENT_DETAIL_NAME_TO_DISPLAY,
      WORK_ORDER_ERROR_MESSAGE_CONFIG,
    );
  }
}

@NgModule({
  declarations: [WorkOrderFormComponent],
  imports: [
    ReactiveFormsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    CommonModule,
    DynamicExperimentTemplateModule,
    TreeViewSelectorPopupFieldModule,
    MatIconModule,
    MatCheckboxModule,
    DisabledElementModule,
    StopPropagationOnClickModule,
  ],
  exports: [WorkOrderFormComponent],
  providers: [],
})
export class WorkOrderFormModule {}
