export enum WorkOrderStatusFormField {
  PROGRESS = 'progress',
  COMPLETION_DATE = 'completionDate',
  NOTE = 'note',
  COMPLETED = 'completed',
}

export const WORK_ORDER_STATUS_NAME_TO_DISPLAY: Record<WorkOrderStatusFormField, string> = {
  [WorkOrderStatusFormField.PROGRESS]: 'Progress',
  [WorkOrderStatusFormField.COMPLETION_DATE]: 'Completion date',
  [WorkOrderStatusFormField.NOTE]: 'Note',
  [WorkOrderStatusFormField.COMPLETED]: 'Completed',
};
