<form [formGroup]="changePasswordFormGroup" autocomplete="off">
  <div class="fields-line">
    <mat-form-field appearance="outline" [hintLabel]="'Min ' + passwordMinLength + ' characters'">
      <mat-label>Old password</mat-label>
      <input
        matInput
        [formControl]="oldPasswordFormControl"
        [type]="hideOldPassword ? 'password' : 'text'"
        appPreventPasswordAutoFilling
        [autocomplete]="'new-password'"
        id="change-password-old-password-input"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hideOldPassword = !hideOldPassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hideOldPassword"
        id="change-password-hide-old-btn"
      >
        <mat-icon>{{ hideOldPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-hint align="end"
        >{{ oldPasswordFormControl.value?.length || 0 }}/{{ passwordMinLength }}</mat-hint
      >
      <mat-error *ngIf="oldPasswordFormControl.invalid">
        {{ getPasswordErrorMessage(oldPasswordFormControl) }}</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline" [hintLabel]="'Min ' + passwordMinLength + ' characters'">
      <mat-label>New password</mat-label>
      <input
        matInput
        [formControl]="newPasswordFormControl"
        [type]="hideNewPassword ? 'password' : 'text'"
        appPreventPasswordAutoFilling
        id="change-password-new-password-input"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="hideNewPassword = !hideNewPassword"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hideNewPassword"
        id="change-password-hide-new-btn"
      >
        <mat-icon>{{ hideNewPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-hint align="end"
        >{{ newPasswordFormControl.value?.length || 0 }}/{{ passwordMinLength }}</mat-hint
      >
      <mat-error *ngIf="newPasswordFormControl.invalid">
        {{ getPasswordErrorMessage(newPasswordFormControl) }}</mat-error
      >
    </mat-form-field>
  </div>
</form>
