import { OrganizationFormField } from './organization-form-field.model';

export const ORGANIZATION_FORM_FIELD_NAME_TO_DISPLAY: Record<OrganizationFormField, string> = {
  [OrganizationFormField.APP_NAME]: 'Application name',
  [OrganizationFormField.ORGANIZATION_NAME]: 'Organization name',
  [OrganizationFormField.APP_SLOGAN]: 'Slogan',
  [OrganizationFormField.APP_DESCRIPTION]: 'Description',
  [OrganizationFormField.PRIMARY_COLOR]: 'Primary color',
  [OrganizationFormField.SECONDARY_COLOR]: 'Secondary color',
  [OrganizationFormField.ORGANIZATION_FULL_ICON_PATH]: 'Full icon image',
  [OrganizationFormField.ORGANIZATION_SMALL_ICON_PATH]: 'Small icon image',
  [OrganizationFormField.LANDING_PAGE_IMAGE_PATH]: 'Landing page background image',
};
