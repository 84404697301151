export enum RenderType {
  INPUT_FLOAT_BOX = 'INPUT_FLOAT_BOX',
  DROPDOWN_BOX = 'DROPDOWN_BOX',
  POPUP_BOX = 'POPUP_BOX',
  HEADLINE_STRING = 'HEADLINE_STRING',
  INPUT_INT_BOX = 'INPUT_INT_BOX',
  INPUT_TEXT_BOX = 'INPUT_TEXT_BOX',
  INPUT_STRING_BOX = 'INPUT_STRING_BOX',
  DATE_BOX = 'DATE_BOX',
}
