<ng-container [formGroup]="formGroup">
  <h2 class="section-title">Simulation settings</h2>
  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>
        {{
          simulationSettingsFormFieldFormLabel[simulationSettingsFormField.SIMULATION_NAME]
        }}</mat-label
      >
      <input
        matInput
        [formControlName]="simulationSettingsFormField.SIMULATION_NAME"
        id="simulation-name-filename-input"
      />
      <mat-error
        *ngIf="simulationNameFormControl.errors && simulationNameFormControl!.getError('required')"
        >{{ simulationNameFormControl.getError('required') ? 'Field is required' : '' }}</mat-error
      >
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        {{
          simulationSettingsFormFieldFormLabel[simulationSettingsFormField.DESCRIPTION]
        }}</mat-label
      >
      <input
        matInput
        [formControlName]="simulationSettingsFormField.DESCRIPTION"
        id="description-filename-input"
      />
    </mat-form-field>
  </div>
  <div class="numberOfGpu" *ngIf="!isPtsd">
    <span>{{
      simulationSettingsFormFieldFormLabel[simulationSettingsFormField.NUMBER_OF_GPUS]
    }}</span>
    <div class="numberOfGpu-options">
      <app-multi-select
        [formControlName]="simulationSettingsFormField.NUMBER_OF_GPUS"
        [selectOptions]="numberOfGpus"
        id="Gpu-unit-select"
      >
      </app-multi-select>
    </div>
  </div>

  <ng-container *ngIf="isPtsd">
    <div class="simualation-direction-container">
      <span>Simulation direction</span>
      <div class="checkboxes">
        <mat-checkbox
          [formControlName]="simulationSettingsFormField.DIRECTION_X"
          id="direction-x"
          >{{
            simulationSettingsFormFieldFormLabel[simulationSettingsFormField.DIRECTION_X]
          }}</mat-checkbox
        >

        <mat-checkbox
          [formControlName]="simulationSettingsFormField.DIRECTION_Y"
          id="direction-y"
          >{{
            simulationSettingsFormFieldFormLabel[simulationSettingsFormField.DIRECTION_Y]
          }}</mat-checkbox
        >
        <mat-checkbox
          [formControlName]="simulationSettingsFormField.DIRECTION_Z"
          id="direction-z"
          >{{
            simulationSettingsFormFieldFormLabel[simulationSettingsFormField.DIRECTION_Z]
          }}</mat-checkbox
        >

        <mat-checkbox [formControlName]="simulationSettingsFormField.FLIP_X" id="flip-x">{{
          simulationSettingsFormFieldFormLabel[simulationSettingsFormField.FLIP_X]
        }}</mat-checkbox>
        <mat-checkbox [formControlName]="simulationSettingsFormField.FLIP_Y" id="flip-y">{{
          simulationSettingsFormFieldFormLabel[simulationSettingsFormField.FLIP_Y]
        }}</mat-checkbox>
        <mat-checkbox [formControlName]="simulationSettingsFormField.FLIP_Z" id="flip-z">{{
          simulationSettingsFormFieldFormLabel[simulationSettingsFormField.FLIP_Z]
        }}</mat-checkbox>
        <mat-checkbox [formControlName]="simulationSettingsFormField.PTSD" id="ptsd">{{
          simulationSettingsFormFieldFormLabel[simulationSettingsFormField.PTSD]
        }}</mat-checkbox>
      </div>
    </div>

    <div class="numberOfCpu">
      <span>{{
        simulationSettingsFormFieldFormLabel[simulationSettingsFormField.NUMBER_OF_CPUS]
      }}</span>
      <div class="numberOfCpu-options">
        <app-multi-select
          [formControlName]="simulationSettingsFormField.NUMBER_OF_CPUS"
          [selectOptions]="numberOfGpus"
          id="Cpu-unit-select"
        >
        </app-multi-select>
      </div>
    </div>
  </ng-container>
</ng-container>
