<h1 class="title">Data uploader - complex data type</h1>

<mat-tab-group
  class="complex-data-digital-volume-tab-group"
  [selectedIndex]="0"
  (selectedIndexChange)="selectedTab.setValue($event)"
>
  <mat-tab [label]="'Thin section'">
    <app-complex-data-thin-section #appThinSection></app-complex-data-thin-section>
  </mat-tab>

  <mat-tab [label]="'Digital volume'">
    <app-complex-data-digital-volume #appDigitalVolume></app-complex-data-digital-volume>
  </mat-tab>
</mat-tab-group>

<div class="action-btns">
  <ng-container *ngIf="selectedTab.value === 0 && appThinSection.digitalThinSectionArray.length">
    <button
      class="btn btn-secondary btn-big"
      (click)="appThinSection.clear(true)"
      id="complex-data-uploader-cancel-btn"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary btn-big"
      [disabled]="appThinSection.digitalThinSectionArray.length === 0"
      (click)="appThinSection.save()"
      id="complex-data-uploader-upload-btn"
    >
      {{ appThinSection.uploadBtnText }}
    </button>
  </ng-container>

  <ng-container
    *ngIf="selectedTab.value === 1 && appDigitalVolume.digitalVolumeInfoFormArray.length"
  >
    <ng-container *ngIf="!appDigitalVolume.isVerified">
      <button
        class="btn btn-primary btn-big"
        id="complex-data-clear-digital-volume-btn"
        [disabled]="appDigitalVolume.isVerifyBtnSpinnerVisible"
        (click)="appDigitalVolume.clear()"
      >
        Clear
      </button>

      <button
        class="btn btn-primary btn-big btn-with-spinner"
        id="complex-data-verify-digital-volume-btn"
        [disabled]="appDigitalVolume.isVerifyBtnSpinnerVisible"
        (click)="appDigitalVolume.verify()"
      >
        Verify
        <mat-spinner *ngIf="appDigitalVolume.isVerifyBtnSpinnerVisible" [diameter]="20">
        </mat-spinner>
      </button>
    </ng-container>

    <ng-container *ngIf="appDigitalVolume.isVerified">
      <button
        *ngIf="!appDigitalVolume.isUploaded"
        class="btn btn-primary btn-big"
        id="complex-data-cancel-digital-volume-btn"
        [disabled]="appDigitalVolume.isUploadBtnSpinnerVisible"
        (click)="appDigitalVolume.cancel()"
      >
        Cancel
      </button>

      <button
        class="btn btn-primary btn-big btn-with-spinner"
        id="complex-data-upload-digital-volume-btn"
        [disabled]="appDigitalVolume.isUploadBtnSpinnerVisible"
        (click)="appDigitalVolume.upload()"
      >
        {{ appDigitalVolume.isUploaded ? 'Save' : 'Upload' }}
        <mat-spinner *ngIf="appDigitalVolume.isUploadBtnSpinnerVisible" [diameter]="20">
        </mat-spinner>
      </button>
    </ng-container>
  </ng-container>
</div>
