import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'asUntypedFormGroup',
})
export class AsUntypedFormGroupPipe implements PipeTransform {
  transform(value: AbstractControl<any>): UntypedFormGroup {
    return value as UntypedFormGroup;
  }
}

@NgModule({
  declarations: [AsUntypedFormGroupPipe],
  exports: [AsUntypedFormGroupPipe],
  imports: [],
})
export class AsUntypedFormGroupModule {}
