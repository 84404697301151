<app-sample-table-selector-popup-field
  class="half-width"
  [formControl]="thinSectionsampleControl"
  [label]="'Search physical sample'"
  #sampleFieldComponent
></app-sample-table-selector-popup-field>
<ng-container *ngIf="thinSectionInformationFormGroup && thinSectionSampleId">
  <h2 class="section-title">Well Information</h2>
  <app-sample-well-form [formGroup]="thinSectionWellFormGroup"> </app-sample-well-form>

  <h2 class="section-title">Thin Section Information</h2>
  <div [formGroup]="thinSectionInformationFormGroup">
    <div class="fields">
      <div class="fields-line">
        <mat-form-field appearance="outline">
          <mat-label>Sample no</mat-label>
          <input
            matInput
            [formControlName]="'sampleNo'"
            id="complex-data-uploader-thin-section-information-sample-no-input"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Section type</mat-label>
          <mat-select
            [formControlName]="'sectionTypeId'"
            id="complex-data-uploader-thin-section-information-section-type-input"
          >
            <mat-option *ngFor="let option of sectionTypes" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="fields-line">
        <app-unit-input
          #thinSectionDepthTop
          [formControl]="extractControl('depthTop')"
          [label]="'Top depth'"
          [formFieldName]="'depthTop'"
          [options]="lengthUnits"
          [type]="'number'"
          appSynchronizeDepthUnits
          id="complex-data-uploader-thin-section-information-top-depth-input"
        ></app-unit-input>

        <div class="section-dimension-line">
          <span>Section dimension</span>
          <div class="section-dimensions">
            <mat-form-field appearance="outline">
              <mat-label>X</mat-label>
              <input
                matInput
                [type]="'number'"
                [formControlName]="'phyDimX'"
                id="thin-section-information-section-dimension-x-input"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Y</mat-label>
              <input
                matInput
                [type]="'number'"
                [formControlName]="'phyDimY'"
                id="thin-section-information-section-dimension-y-input"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Z</mat-label>
              <input
                matInput
                [type]="'number'"
                [formControlName]="'phyDimZ'"
                id="thin-section-information-section-dimension-z-input"
              />
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline" class="sample-dimension-unit">
            <mat-select
              [formControlName]="'phyDimLengthUnitId'"
              [compareWith]="compareWith"
              id="complex-data-uploader-thin-section-information-section-dimension-unit-input"
            >
              <mat-option *ngFor="let option of lengthUnits" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="fields-line">
        <app-unit-input
          #thinSectionDepthBottom
          [formControl]="extractControl('depthBottom')"
          [label]="'Bottom depth'"
          [formFieldName]="'depthBottom'"
          [options]="lengthUnits"
          [type]="'number'"
          appSynchronizeDepthUnits
          id="complex-data-uploader-thin-section-information-bottom-depth-input"
        ></app-unit-input>

        <mat-form-field appearance="outline">
          <mat-label>Staining</mat-label>
          <mat-select
            [formControlName]="'stainingId'"
            id="complex-data-uploader-thin-section-information-staining-input"
          >
            <mat-option *ngFor="let option of stainings" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="fields-line">
        <mat-form-field appearance="outline">
          <mat-label>Section number</mat-label>
          <input
            matInput
            [formControlName]="'sectionNumber'"
            id="complex-data-uploader-thin-section-information-section-number-input"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Orientation</mat-label>
          <mat-select
            [formControlName]="'orientationId'"
            id="complex-data-uploader-thin-section-information-orientation-input"
          >
            <mat-option *ngFor="let option of orientations" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="fields-line half-width">
        <mat-form-field appearance="outline">
          <mat-label>Section origin</mat-label>
          <mat-select
            [formControlName]="'sectionOriginId'"
            id="complex-data-uploader-thin-section-information-section-origin-input"
          >
            <mat-option *ngFor="let option of sectionOrigins" [value]="option.id">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <hr />

  <div class="spinner-container" *ngIf="showSpinner">
    <mat-spinner
      class="loading-icon"
      matSuffix
      [diameter]="100"
      [matTooltip]="'uploading'"
    ></mat-spinner>
  </div>

  <div class="half-width input-file">
    <mat-form-field appearance="outline" class="input-file__field">
      <mat-label>Thin section file</mat-label>
      <input matInput disabled [(ngModel)]="thinSectionFileName" />
      <mat-spinner
        class="loading-icon"
        *ngIf="thinSectionFileInputState === 'loading'"
        matSuffix
        [diameter]="18"
        [matTooltip]="'uploading'"
      ></mat-spinner>
      <mat-icon
        class="success-icon"
        *ngIf="thinSectionFileInputState === 'success'"
        matSuffix
        [matTooltip]="'upload success'"
        >done</mat-icon
      >
      <mat-icon
        class="error-icon"
        *ngIf="thinSectionFileInputState === 'error'"
        matSuffix
        [matTooltip]="'upload failed'"
        >clear</mat-icon
      >
    </mat-form-field>

    <div class="upload-btn-container input-file__btn">
      <button
        class="btn btn-primary upload-btn"
        type="button"
        appUpload
        [ext]="'.jpg,.png,.tif,.tiff'"
        [multipleFile]="true"
        (filePicked)="handleSelectedFile($event)"
      >
        <span class="upload-btn__text">Select files</span>
      </button>
    </div>
  </div>
  <div [formGroup]="thinSectionImageFormGroup">
    <h2 class="section-title">
      {{ selectedImageNameThinSection ? selectedImageNameThinSection.split('.')[0] : 'Image' }}
      Information
    </h2>
    <div class="fields-line half-width">
      <mat-form-field appearance="outline">
        <mat-label>Selected Image filename</mat-label>
        <mat-select
          [formControlName]="'fileName'"
          id="complex-data-uploader-thin-section-information-image-file-name-input"
        >
          <mat-option *ngFor="let option of imageFileNameListThinSection" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="picture-fields-block">
      <div class="picture">
        <gallery
          id="image-gallery"
          (indexChange)="onSliderChange($event)"
          [counter]="false"
        ></gallery>
      </div>
      <div class="fields">
        <div class="fields-line">
          <app-unit-input
            [formControlName]="'angle'"
            [label]="'Acquistion angle'"
            [options]="angleUnits"
            [type]="'number'"
            id="complex-data-uploader-thin-section-information-acquistion-angle-input"
          ></app-unit-input>
        </div>

        <div class="fields-line">
          <mat-form-field appearance="outline">
            <mat-label>Polarization</mat-label>
            <mat-select
              [formControlName]="'polarizationId'"
              id="complex-data-uploader-thin-section-information-polarization-input"
            >
              <mat-option *ngFor="let option of polarizationList" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="fields-line">
          <app-unit-input
            [formControlName]="'pixelSize'"
            [label]="'Pixel size'"
            [options]="pixelSizeUnits"
            [type]="'number'"
            id="complex-data-uploader-thin-section-information-pixel-size-input"
          ></app-unit-input>
        </div>
        <div class="fields-line">
          <mat-form-field appearance="outline">
            <mat-label>Rotation</mat-label>
            <input
              matInput
              type="number"
              [formControlName]="'rotation'"
              id="complex-data-uploader-digital-volume-rotation-no-input"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <h2 class="section-title vendor-title">Vendor Information</h2>
  <div [formGroup]="thinSectionVendorFormGroup">
    <div class="fields-line">
      <mat-form-field appearance="outline">
        <mat-label>Vendor name</mat-label>
        <input
          matInput
          [formControlName]="'vendorName'"
          id="complex-data-uploader-thin-section-vendor-name-input"
        />
        <mat-error
          *ngIf="
            thinSectionVendorFormGroup.get('vendorName')!.errors &&
            thinSectionVendorFormGroup.get('vendorName')!.getError('required')
          "
        >
          {{ 'Field is required' }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Internal lab file No</mat-label>
        <input
          matInput
          [formControlName]="'internalLabFileNo'"
          id="complex-data-uploader-thin-section-internal-lab-file-no-input"
        />
      </mat-form-field>
    </div>

    <div class="fields-line">
      <mat-form-field appearance="outline">
        <mat-label>Report No</mat-label>
        <input
          matInput
          [formControlName]="'reportNo'"
          id="complex-data-uploader-thin-section-report-no-input"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Report date</mat-label>
        <input
          matInput
          [matDatepicker]="startDatePicker"
          [formControlName]="'reportDate'"
          (click)="startDatePicker.open()"
          (focus)="startDatePicker.open()"
          id="complex-data-uploader-thin-section-report-date-no-input"
        />
        <mat-datepicker #startDatePicker>
          <mat-datepicker-actions>
            <button
              class="btn btn-secondary btn-small"
              matDatepickerCancel
              id="sample-types-start-date-cancel-btn"
            >
              Cancel
            </button>
            <button
              class="btn btn-primary btn-small"
              matDatepickerApply
              id="sample-types-start-date-apply-btn"
            >
              Apply
            </button>
          </mat-datepicker-actions>
        </mat-datepicker>
      </mat-form-field>
    </div>

    <div class="fields-line half-width">
      <mat-form-field appearance="outline">
        <mat-label>Data analyst QC by Aramco</mat-label>
        <input
          matInput
          [formControlName]="'dataAnalysisQcByAramco'"
          id="complex-data-uploader-thin-section-data-analyst-qc-by-aramco-input"
        />
      </mat-form-field>
    </div>
  </div>
</ng-container>
