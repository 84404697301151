<ng-container *ngIf="isHome; else appLayout">
  <app-application-menu></app-application-menu>
</ng-container>

<ng-template #appLayout>
  <mat-sidenav-container class="sidenav-container" autosize>
    <mat-sidenav
      class="sidenav"
      [class.sidenav__minimized]="!sidenavOpened"
      mode="side"
      opened
      disableClose
      (keydown.escape)="closeSidenav()"
    >
      <app-sidenav [(opened)]="sidenavOpened"></app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
      <app-content></app-content>
      <footer class="footer">{{ footerText }}</footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>
