import { NotificationsService, NotificationType } from 'angular2-notifications';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private notificationsService: NotificationsService) {}

  notifySuccess(content: string): void {
    this.notify('Success', content, NotificationType.Success);
  }

  notifyError(content: string): void {
    this.notify('Error', content, NotificationType.Error);
  }

  notifyInfo(content: string): void {
    this.notify('Info', content, NotificationType.Info);
  }

  notifyWarn(content: string): void {
    this.notify('Warn', content, NotificationType.Warn);
  }

  notify(title: string, content: string, type: NotificationType): void {
    this.notificationsService.create(title, content, type);
  }
}
