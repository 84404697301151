import { map } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { IdName } from '@core/models/id-name.model';
import { ValidationState } from '@core/models/validation-state.model';
import { throttleClick } from '@core/utils/decorators/throttle-click.decorator';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import {
  ThrottleClickDirective,
  ThrottleClickModule,
} from 'src/app/common/directives/throttle-click.directive';
import { PcriFormComponent, PcriFormModule } from 'src/app/features/pcri-form/pcri-form.component';
import { NotificationService } from 'src/app/services/notification.service';

import { UnitInputField } from '../../../common/unit-input/enums/unit-input-field.enum';
import { Pcri, PcriImpl } from '../../pcri-form/models/pcri.model';
import { PcriExperimentInformationFormField } from '../../pcri-form/utils/field-configs/pcri-experiment-information.field-config';
import { RenderType } from '../../work-order/enums/render-type.enum';
import { ExperimentField } from '../../work-order/models/experiment-field.model';
import { WorkOrder } from '../../work-order/models/work-order.model';
import { ExperimentFieldService } from '../../work-order/services/experiment-field.service';
import { ExperimentTypeService } from '../../work-order/services/experiment-type.service';
import { VendorNameService } from '../../work-order/services/vendor-name.service';
import { WorkOrderService } from '../../work-order/services/work-order.service';

@Component({
  selector: 'app-work-flow-popup-pcri',
  templateUrl: './work-flow-popup-pcri.component.html',
  styleUrls: ['./work-flow-popup-pcri.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkFlowPopupPcriComponent extends Destroyable(Object) implements OnInit {
  formIndex!: number;
  sampleId!: string;
  formData: Pcri = {};
  currentForm: { [key: string]: boolean } = {};
  vendorNames: IdName[] = [];
  experimentTypes: IdName[] = [];
  experimentTypeListPcri: IdName[] = [];
  currentFormTitle: string = '';
  editBtnListeners: HTMLElement[] = [];
  experimentNames: string[] = [
    'Soxhlet cleaning',
    'Flow thru cleaning',
    'Brine preparation and sample saturation',
    'Establish initial condition',
    'Measure formation resistivity factor, m and Kw',
    'Capillary pressure resistivity index PcRI drainage',
    'Capillary pressure resistivity index PcRI spontaneous imbibition',
    'Capillary pressure resistivity index PcRI forced imbibition',
  ];

  formTitleArray: string[] = [
    'Soxhlet cleaning',
    'Flow thru cleaning',
    'Brine preparation and sample saturation',
    'Establish initial condition',
    'Measure formation resistivity factor, m and Kw',
    'Capillary pressure resistivity index PcRI drainage',
    'Capillary pressure resistivity index PcRI spontaneous imbibition',
    'Capillary pressure resistivity index PcRI forced imbibition',
  ];

  @ViewChild('appPcriForm') pcriFormComponent!: PcriFormComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<WorkFlowPopupPcriComponent>,
    private notificationService: NotificationService,
    private experimentFieldService: ExperimentFieldService,
    private workOrderService: WorkOrderService,
    private vendorNameService: VendorNameService,
    private experimentTypeService: ExperimentTypeService,
  ) {
    super();
  }

  ngOnInit() {
    this.formIndex = this.data.formIndex;
    this.sampleId = this.data.sampleId;
    this.formData = this.data.formData;
    this.editBtnListeners = this.data.editBtnListeners;
    this.getOptionsForSelectorsAndSetUpExistingWorkOrdersStream(this.pcriFormComponent);
    this.updateCurrentForm();
  }

  previousForm(): void {
    this.matDialogRef.close({
      nextPreviousIndex: this.formIndex - 1,
      data: '',
    });
  }

  updateCurrentForm(): void {
    this.currentFormTitle = this.formTitleArray[this.formIndex - 1];
  }

  @throttleClick()
  save(throttleClickDirective: ThrottleClickDirective, component: PcriFormComponent): void {
    const payload = this.formValidation(throttleClickDirective, component);
    if (payload) {
      this.matDialogRef.close({
        experimentNames: this.experimentNames,
        experimentTypeListPcri: this.experimentTypeListPcri,
        nextPreviousIndex: this.formIndex + 1,
        data: payload,
      });
    }
  }

  experimentData(): WorkOrder {
    return {
      sampleId: this.sampleId,
      name: 'WO123123333',
      proponentEmail: 'user@mail.ua',
      proponentName: 'name',
      description: 'Description',
      startDate: '2023-03-07T22:00:00.000Z',
      expectDate: '2023-03-30T21:00:00.000Z',
      progress: 1,
      completionDate: '2023-03-29T21:00:00.000Z',
      note: 'note',
      completed: false,
      vendorName: '',
      vendorContactEmail: '',
      vendorContactName: '',
      vendorContactInfo: '',
      experimentTypeId: '018ceffa-20bc-ed11-a8de-000d3a73e96a',
      experimentFields: [
        {
          fieldType: RenderType.INPUT_TEXT_BOX,
          fieldName: 'Experiment',
          fieldDisplayName: 'Experiment information',
          fieldDefaultOption: 'null',
          fieldOptions: [],
          fieldValue: '',
          unitName: 'null',
          unitValue: 'null',
          unitDefault: 'null',
          unitOptions: [],
          required: false,
          fieldPosition: 0,
        },
        {
          fieldType: RenderType.INPUT_TEXT_BOX,
          fieldName: 'CleaningMethod',
          fieldDisplayName: 'Cleaning method',
          fieldDefaultOption: 'null',
          fieldOptions: [],
          fieldValue: 'CleaningMethod test 1',
          unitName: 'null',
          unitValue: 'null',
          unitDefault: 'null',
          unitOptions: [],
          required: false,
          fieldPosition: 1,
        },
        {
          fieldType: RenderType.INPUT_TEXT_BOX,
          fieldName: 'EffluentColor',
          fieldDisplayName: 'Effluent color',
          fieldDefaultOption: 'null',
          fieldOptions: [],
          fieldValue: 'Effluent color test 1',
          unitName: 'null',
          unitValue: 'null',
          unitDefault: 'null',
          unitOptions: [],
          required: false,
          fieldPosition: 2,
        },
        {
          fieldType: RenderType.INPUT_TEXT_BOX,
          fieldName: 'Note',
          fieldDisplayName: 'Note',
          fieldDefaultOption: 'null',
          fieldOptions: [],
          fieldValue: 'Note test 1',
          unitName: 'null',
          unitValue: 'null',
          unitDefault: 'null',
          unitOptions: [],
          required: false,
          fieldPosition: 3,
        },
      ],
    };
  }

  getOptionsForSelectorsAndSetUpExistingWorkOrdersStream(component: PcriFormComponent): void {
    this.experimentTypeService
      .getAll()
      .pipe(
        map((experimentTypes) =>
          experimentTypes.filter((type: IdName) => {
            if (this.experimentNames.includes(type.name)) this.experimentTypeListPcri.push(type);
            return this.experimentNames[this.formIndex - 1] === type.name;
          }),
        ),

        this.takeUntilDestroyed(),
      )
      .subscribe((experimentTypes) => {
        this.experimentTypes = experimentTypes;
        if (this.formData.experimentFields?.length) {
          this.pcriFormComponent?.dynamicExperimentTemplateComponent.buildDynamicFields(
            this.formData.experimentFields,
          );
        } else {
          this.pcriFormComponent?.dynamicExperimentTemplateComponent.experimentDetailFormGroup
            .get([PcriExperimentInformationFormField.EXPERIMENT_TYPE])
            ?.setValue(this.experimentTypes[0].id);
        }
        this.pcriFormComponent.detectChanges();
      });
  }

  private formValidation(
    throttleClickDirective: ThrottleClickDirective,
    component: PcriFormComponent,
  ): Pcri | null {
    const validationState = this.checkIfFormIsValid(component);

    if (!validationState.isValid) {
      component.pcriFormGroup.markAllAsTouched();
      component.componentsWithInnerFormControls.forEach((cmp) => cmp.detectChanges());
      this.notificationService.notifyError(validationState.errorMessage);
      throttleClickDirective.isNextClickAllowed$.next(true);
      return null;
    }
    const experimentFields = this.getExperimentFields(component);
    const payload = new PcriImpl({
      ...component.pcriFormGroup.getRawValue(),
      sampleId: this.sampleId,
      experimentFields,
    });
    return payload;
  }

  private getExperimentFields(component: PcriFormComponent): ExperimentField[] {
    const { experimentFieldsMap, dynamicFieldFormGroup } =
      component.dynamicExperimentTemplateComponent;

    const experimentFields = Object.values(experimentFieldsMap).map((experimentField) => {
      const fieldValue = dynamicFieldFormGroup.value[experimentField.fieldName];

      if (fieldValue === null || typeof fieldValue === 'string' || typeof fieldValue === 'number') {
        return { ...experimentField, fieldValue };
      }

      return {
        ...experimentField,
        fieldValue: fieldValue[UnitInputField.FIELD_VALUE],
        unitValue: fieldValue[UnitInputField.UNIT_ID],
      };
    });

    return experimentFields;
  }

  private checkIfFormIsValid(component: PcriFormComponent): ValidationState {
    if (component.pcriFormGroup.status === 'VALID') {
      return { isValid: true };
    }

    const validationMessage = component.dynamicValidationMessageList.find((valMessage) =>
      valMessage.hasError(),
    )!;

    return {
      isValid: false,
      errorMessage: validationMessage.getFirstMessage(),
    };
  }
}

@NgModule({
  declarations: [WorkFlowPopupPcriComponent],
  exports: [WorkFlowPopupPcriComponent],
  imports: [CommonModule, MatDialogModule, PcriFormModule, ThrottleClickModule],
  providers: [VendorNameService, ExperimentTypeService, ExperimentFieldService],
})
export class WorkFlowPopupPcriModule {}
