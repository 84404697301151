export enum DigitalVolumeInformationFormField {
  SEGMENT_VOLUME = 'segmentVolume',
  SAMPLE_FILENAME = 'sampleFilename',
  SAMPLE_NAME = 'sampleName',
  DEPTH = 'depth',
  SAMPLE_TYPE = 'sampleType',
  SUBSAMPLE_NUMBER = 'subsampleNumber',
  ORIENTATION_ID = 'orientationId',
  VOLUME_TYPE = 'volumeType',
  DATA_FORMAT = 'dataFormat',
  ORIGIN_LOCATION_X = 'originLocationX',
  ORIGIN_LOCATION_Y = 'originLocationY',
  ORIGIN_LOCATION_Z = 'originLocationZ',
  ORIGIN_LOCATION_UNIT = 'originLocationUnitId',
  VOXEL_SIZE_X = 'voxelSizeX',
  VOXEL_SIZE_Y = 'voxelSizeY',
  VOXEL_SIZE_Z = 'voxelSizeZ',
  VOXEL_SIZE_UNIT = 'voxelSizeUnitId',
  GRID_SIZE_X = 'gridSizeX',
  GRID_SIZE_Y = 'gridSizeY',
  GRID_SIZE_Z = 'gridSizeZ',
  VOXEL_SIZE = 'voxelSize',
  VOXEL_UNIT = 'voxelUnit',
  GRID_SIZE = 'gridSize',
  DIRECTION = 'direction',
  ZOOM = 'zoom',
  ZOOMUNIT = 'zoomUnit',
}
