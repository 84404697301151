import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringArrayToString',
})
export class StringArrayToStringPipe implements PipeTransform {
  transform(value: Array<string>): string {
    return value.join(', ');
  }
}

@NgModule({
  imports: [],
  declarations: [StringArrayToStringPipe],
  exports: [StringArrayToStringPipe],
})
export class StringArrayToStringPipeModule {}
