import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgModule,
  OnInit,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import {
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

import { EMAIL_REGEX, PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from '@core/constants/consts';
import { USE_OKTA_AUTH_TOKEN } from '@core/tokens/use-okta-auth.token';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';

@Component({
  selector: 'app-admin-settings-new-user-popup',
  templateUrl: './admin-settings-new-user-popup.component.html',
  styleUrls: ['./admin-settings-new-user-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSettingsNewUserPopupComponent extends Destroyable(Object) implements OnInit {
  readonly passwordMinLength = PASSWORD_MIN_LENGTH;
  hidePassword = true;

  mainFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
    password: new UntypedFormControl(
      '',
      this.isOktaAuthUsed
        ? Validators.nullValidator
        : [
            Validators.required,
            Validators.minLength(PASSWORD_MIN_LENGTH),
            Validators.pattern(PASSWORD_REGEX),
          ],
    ),
    manager: new UntypedFormControl(false),
    admin: new UntypedFormControl(false),
  });

  get emailControl(): UntypedFormControl {
    return this.mainFormGroup.get('email') as UntypedFormControl;
  }

  get passwordControl(): UntypedFormControl {
    return this.mainFormGroup.get('password') as UntypedFormControl;
  }

  get isOktaAuthUsed(): boolean {
    return this.useOktaAuth;
  }

  constructor(
    public dialogRef: MatDialogRef<AdminSettingsNewUserPopupComponent>,
    private cd: ChangeDetectorRef,
    @Inject(USE_OKTA_AUTH_TOKEN) private useOktaAuth: boolean,
  ) {
    super();
  }

  ngOnInit(): void {
    this.mainFormGroup.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((_) => {
      this.cd.detectChanges();
    });
  }

  submit(): void {
    this.dialogRef.close(this.mainFormGroup.value);
  }

  getPasswordErrorMessage(): string {
    if (this.passwordControl.hasError('required')) {
      return 'You must enter a value';
    }

    if (this.passwordControl.hasError('minlength')) {
      return `Password must be at least ${PASSWORD_MIN_LENGTH} characters long`;
    }

    if (this.passwordControl.hasError('pattern')) {
      return 'Password must have at least one digit (0-9)';
    }

    return '';
  }

  getEmailErrorMessage(): string {
    if (this.emailControl.hasError('required')) {
      return 'You must enter a value';
    }

    if (this.emailControl.hasError('pattern')) {
      return 'Email is invalid';
    }

    return '';
  }
}

@NgModule({
  declarations: [AdminSettingsNewUserPopupComponent],
  imports: [
    FontAwesomeModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [AdminSettingsNewUserPopupComponent],
})
export class AdminSettingsNewUserPopupModule {}
