import { SampleFilters } from '../models/sample-filters.model';

export const INITIAL_SAMPLE_FILTER_STATE: SampleFilters = {
  filterType: 'byName',
  searchString: '',
  stateName: { own: true, noAccess: true, approved: true, rejected: true, pending: true },
  sampleAvailability: { checkedIn: true, checkedOut: true },
  sampleTypes: [],
  wells: [],
  maxDepth: 0,
  minDepth: 0,
  minDepthUnitId: '',
  maxDepthUnitId: '',
  sortColumnName: 'noSort',
  sortDirection: '',
  page: 1,
  pageSize: 10,
};
