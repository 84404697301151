import { of, switchMap } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

import { IdName } from '@core/models/id-name.model';
import {
  INTEGER_ONLY_ERROR_MESSAGE,
  REQUIRED_ERROR_MESSAGE,
  TEXT_ONLY_ERROR_MESSAGE,
} from '@core/utils/form/error-message.config';
import { FlatFormValidationMessage } from '@core/utils/form/flat-form-validation-message.class';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { UnitInputField } from 'src/app/common/unit-input/enums/unit-input-field.enum';
import { UnitInputModule } from 'src/app/common/unit-input/unit-input.component';
import { RenderType } from 'src/app/features/work-order/enums/render-type.enum';
import { WorkOrderFormField } from 'src/app/features/work-order/form/field-configs/work-order.field-config';
import { WorkOrderExperimentDetailFormField } from 'src/app/features/work-order/form/field-configs/work-order-experiment-detail.field-config';
import { HAS_INNER_CONTROLS } from 'src/app/features/work-order/form/has-inner-controls.token';
import { CanDetectChanges } from 'src/app/features/work-order/models/can-detect-changes.model';
import { ExperimentField } from 'src/app/features/work-order/models/experiment-field.model';
import { HasInnerControls } from 'src/app/features/work-order/models/has-inner-controls.model';
import { InputTypeModule } from 'src/app/features/work-order/pipes/input-type.pipe';
import { ExperimentFieldService } from 'src/app/features/work-order/services/experiment-field.service';

import { SampleTableSelectorPopupFieldModule } from '../../../common/sample-table-selector-popup-field/sample-table-selector-popup-field.component';
import { integerOnly } from '../form/integer-only.validator';
import { textOnly } from '../form/text-only.validator';
import {
  NewExperimentType,
  newExperimentTypeFields,
} from '../mocks/experiment-type-temporary.mock';

@Component({
  selector: 'app-dynamic-experiment-template',
  templateUrl: './dynamic-experiment-template.component.html',
  styleUrls: ['./dynamic-experiment-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: HAS_INNER_CONTROLS,
      useExisting: DynamicExperimentTemplateComponent,
    },
  ],
})
export class DynamicExperimentTemplateComponent extends Destroyable(Object) implements OnInit {
  @Input() workOrderFormGroup!: UntypedFormGroup;
  @Input() set defaultExperimentFields(experimentFields: ExperimentField[]) {
    this.buildDynamicFields(experimentFields, this.experimentDetailFormGroup.disabled);
  }

  @Input() experimentTypeList: IdName[] = [];
  @Input() hideExperimentType = false;

  @ViewChildren(HAS_INNER_CONTROLS) componentsWhichHasInnerControls!: QueryList<HasInnerControls>;

  readonly dynamicFieldName = 'dynamicFields';
  readonly workOrderFormField = WorkOrderFormField;
  readonly renderType = RenderType;

  experimentFieldsValidationMessage: FlatFormValidationMessage<{ [key: string]: string }> | null =
    null;

  experimentFields!: ExperimentField[];
  experimentFieldsMap: Record<string, ExperimentField> = {};

  constructor(
    private experimentFieldService: ExperimentFieldService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  get dynamicFieldFormGroup(): UntypedFormGroup {
    return this.experimentDetailFormGroup.get([this.dynamicFieldName]) as UntypedFormGroup;
  }

  get experimentDetailFormGroup(): UntypedFormGroup {
    return this.workOrderFormGroup.get([WorkOrderFormField.EXPERIMENT_DETAIL]) as UntypedFormGroup;
  }

  get componentsWithInnerFormControls(): CanDetectChanges[] {
    return this.componentsWhichHasInnerControls
      ? [
          this,
          ...this.componentsWhichHasInnerControls
            .toArray()
            .reduce(
              (acc, cmp) => [...acc, ...cmp.componentsWithInnerFormControls],
              [] as CanDetectChanges[],
            ),
        ]
      : [this];
  }

  ngOnInit(): void {
    this.transformFormAccordingToExperimentTypeChanges();
  }

  extractConstantControl(name: string): UntypedFormControl {
    return this.experimentDetailFormGroup.get([this.dynamicFieldName, name]) as UntypedFormControl;
  }

  detectChanges(): void {
    this.cd.detectChanges();
  }

  makeCallBack(fieldName: string): () => string {
    return () => this.experimentFieldsValidationMessage!.getMessageFor(fieldName);
  }

  getErrorMessageForPopupField(experimentConstant: ExperimentField): () => string {
    return () =>
      this.experimentFieldsValidationMessage!.getMessageFor(experimentConstant.fieldName);
  }

  buildDynamicFields(experimentFields: ExperimentField[], isDisabled = false): void {
    experimentFields.sort((f1, f2) => f1.fieldPosition - f2.fieldPosition);
    this.experimentFields = experimentFields;
    this.experimentFieldsMap = Object.fromEntries(
      experimentFields.map((field) => [field.fieldName, field]),
    );

    this.removeDynamicFieldsIfExist();
    this.experimentFieldsValidationMessage = null;

    if (experimentFields.length) {
      this.addDynamicFieldsToTheForm(experimentFields, isDisabled);
      this.defineValidationMessageForDynamicFields(experimentFields);
    }

    this.cd.markForCheck();
  }

  private transformFormAccordingToExperimentTypeChanges(): void {
    this.experimentDetailFormGroup
      .get([WorkOrderExperimentDetailFormField.EXPERIMENT_TYPE])!
      .valueChanges.pipe(
        switchMap((experimentTypeId) => {
          if (!experimentTypeId) {
            return of([]);
          }

          const experimentType = this.experimentTypeList.find(
            (expType) => expType.id === experimentTypeId,
          )!;

          const isNewExperimentType = Object.values(NewExperimentType).includes(
            experimentType?.name as NewExperimentType,
          );

          if (isNewExperimentType) {
            return of([...newExperimentTypeFields]);
          }

          return this.experimentFieldService.getListBy(experimentTypeId);
        }),
        this.takeUntilDestroyed(),
      )
      .subscribe((experimentFields) => {
        this.buildDynamicFields(experimentFields);
      });
  }

  private defineValidationMessageForDynamicFields(experimentFields: ExperimentField[]): void {
    this.experimentFieldsValidationMessage = new FlatFormValidationMessage(
      this.dynamicFieldFormGroup,
      Object.fromEntries(
        experimentFields.map((field) => [field.fieldName, field.fieldDisplayName]),
      ),
      { ...REQUIRED_ERROR_MESSAGE, ...TEXT_ONLY_ERROR_MESSAGE, ...INTEGER_ONLY_ERROR_MESSAGE },
    );
  }

  private removeDynamicFieldsIfExist(): void {
    if (this.experimentDetailFormGroup.contains(this.dynamicFieldName)) {
      this.experimentDetailFormGroup.removeControl(this.dynamicFieldName, {
        emitEvent: false,
      });
    }
  }

  private addDynamicFieldsToTheForm(
    experimentFields: ExperimentField[],
    isDisabled: boolean,
  ): void {
    this.experimentDetailFormGroup.addControl(this.dynamicFieldName, new UntypedFormGroup({}), {
      emitEvent: false,
    });
    const dynamicFieldsGroup = this.experimentDetailFormGroup.get(
      this.dynamicFieldName,
    ) as UntypedFormGroup;
    experimentFields.forEach((experimentField) => {
      const validators = this.generateDynamicFieldValidatorsArray(experimentField);

      if (experimentField.unitOptions.length) {
        dynamicFieldsGroup.addControl(
          experimentField.fieldName,
          new UntypedFormControl(
            {
              value: {
                [UnitInputField.UNIT_ID]: experimentField.unitValue ?? '',
                [UnitInputField.FIELD_VALUE]: experimentField.fieldValue ?? '',
              },
              disabled: isDisabled,
            },
            validators,
          ),
        );
      } else {
        let { fieldValue } = experimentField;
        if (
          experimentField.fieldType === RenderType.DROPDOWN_BOX &&
          experimentField.fieldDefaultOption &&
          !experimentField.fieldValue
        ) {
          fieldValue = experimentField.fieldDefaultOption;
        }

        dynamicFieldsGroup.addControl(
          experimentField.fieldName,
          new UntypedFormControl(
            { value: fieldValue ?? '', disabled: isDisabled },
            experimentField.required ? [...validators, Validators.required] : [...validators],
          ),
        );
      }
    });
  }

  private generateDynamicFieldValidatorsArray(experimentField: ExperimentField): ValidatorFn[] {
    const validators = [];

    if (experimentField.fieldType === RenderType.INPUT_TEXT_BOX) {
      validators.push(textOnly);
    }

    if (experimentField.fieldType === RenderType.INPUT_INT_BOX) {
      validators.push(integerOnly);
    }

    return validators;
  }
}

@NgModule({
  declarations: [DynamicExperimentTemplateComponent],
  exports: [DynamicExperimentTemplateComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    UnitInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    InputTypeModule,
    MatDatepickerModule,
    SampleTableSelectorPopupFieldModule,
  ],
  providers: [ExperimentFieldService],
})
export class DynamicExperimentTemplateModule {}
