import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBarcode, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { debounceTime, filter, forkJoin, merge, tap } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgModule,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import {
  MatLegacyPaginator as MatPaginator,
  MatLegacyPaginatorModule as MatPaginatorModule,
} from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { DEFAULT_DEBOUNCE_TIME } from '@core/constants/consts';
import { IdName } from '@core/models/id-name.model';
import { Location } from '@core/models/location.model';
import { StringValues } from '@core/types/string-values.type';
import { Destroyable } from '@core/utils/mixins/destroyable.mixin';
import { SearchInputModule } from 'src/app/common/search-input/search-input.component';
import { GenerateBarcodeService } from 'src/app/services/generate-barcode.service';
import { PrintBarcodeService } from 'src/app/services/print-barcode.service';

import { LocationService } from '../../../services/api/location.service';
import { NotificationService } from '../../../services/notification.service';
import { ObjectDeepnessConverterService } from '../../../services/object-deepness-converter.service';
import { UserManagementService } from '../../../services/user-management.service';
import { LocationRestrictionService } from '../../location/services/location-restriction.service';
import { LocationTypeService } from '../../location/services/location-type.service';
import { LocationSearchField } from './enums/location-search-field.enum';
import {
  LocationFilterComponent,
  LocationFilterModule,
} from './location-filter/location-filter.component';
import { LocationFilterData } from './models/location-filter-data.model';
import { LocationFilters, LocationFiltersImpl } from './models/location-filters.model';
import { LocationResult } from './models/location-result.model';
import { LocationsLookupService } from './services/locations-lookup.service';
import { LocationsDataSource } from './utils/locations-data-source.class';

@Component({
  selector: 'app-lookup-location',
  templateUrl: './lookup-location.component.html',
  styleUrls: ['./lookup-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LookupLocationComponent extends Destroyable(Object) implements OnInit, AfterViewInit {
  @Input() isSelector = false;

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  readonly faQrcode = faQrcode;
  readonly faBarcode = faBarcode;
  readonly searchByOptions = [
    { label: 'Location Name', value: 'byName' },
    { label: 'Parent', value: 'byParent' },
    { label: 'Description', value: 'byDescription' },
    { label: 'Code', value: 'byCode' },
  ];

  readonly displayedColumns: Array<keyof LocationResult> = [
    'name',
    'type',
    'restrictions',
    'description',
    'parentLocation',
    'qrCodeId',
    'barcodeId',
  ];

  private noRestrictionValue: IdName = {
    id: '',
    name: 'No restriction',
  };

  createLocationButtonText!: string;

  locationRestrictions: IdName[] = [];
  locationTypes: IdName[] = [];
  selectedLocation: Location | null = null;
  isViewInitialized = false;

  dataSource!: LocationsDataSource;
  locationFilters: LocationFilters = {
    locationFilterBy: {
      filterType: 'byName',
      searchString: '',
      sortColumnName: 'noSort',
      sortDirection: '',
      page: 1,
      pageSize: 10,
    },
    locationTypes: [],
    restrictionTypes: [],
  };

  private get routerAppPrefix(): string {
    return `/${/(?<=\/)(.*?)(?=\/)/.exec(this.router.url)?.[0]}`;
  }

  private get isEveryFilterOptionsLoaded(): boolean {
    return !!this.locationRestrictions.length && !!this.locationTypes.length;
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private locationTypeService: LocationTypeService,
    private locationRestrictionService: LocationRestrictionService,
    private locationsLookupService: LocationsLookupService,
    private locationService: LocationService,
    private generateBarcodeService: GenerateBarcodeService,
    private printBarcodeService: PrintBarcodeService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private userManagementService: UserManagementService,
    private objectDeepnessConverterService: ObjectDeepnessConverterService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.dataSource = new LocationsDataSource(
      this.locationsLookupService,
      this.locationService,
      this.takeUntilDestroyed,
    );
    this.defineCreateLocationButtonText();
    this.initializeFilterOptionsAndState();
  }

  ngAfterViewInit(): void {
    this.isViewInitialized = true;
    setTimeout(() => this.applySearch());
    this.setupSortAndPaginationChangeListeners();
  }

  onSearchByChanged(_: LocationSearchField): void {
    this.applySearch(true);
  }

  onSearch(term: string): void {
    this.locationFilters.locationFilterBy.searchString = term.trim();
    this.applySearch(true);
  }

  searchByBarcode(searchValue: string): void {
    if (!searchValue) {
      this.notificationService.notifyInfo('Search field should not be empty');
      return;
    }

    this.resetToDefaultAndSetSearchString(searchValue);
    this.dataSource.searchByBarCode(this.locationFilters.locationFilterBy.searchString);
  }

  onFilterClick(): void {
    const dialogRef = this.dialog.open<LocationFilterComponent, LocationFilterData>(
      LocationFilterComponent,
      {
        width: '50%',
        data: {
          locationTypes: [...this.locationTypes],
          restrictionTypes: [...this.locationRestrictions],
          currentValues: {
            restrictionTypes: this.locationFilters.restrictionTypes,
            locationTypes: this.locationFilters.locationTypes,
          },
        },
      },
    );

    dialogRef
      .afterClosed()
      .pipe(
        this.takeUntilDestroyed(),
        filter((x) => !!x),
      )
      .subscribe(({ locationTypes, locationRestrictions }) => {
        this.locationFilters.locationTypes = locationTypes;
        this.locationFilters.restrictionTypes = locationRestrictions;
        this.changeDetectorRef.detectChanges();
        this.applySearch(true);
      });
  }

  printQRCode(value: string, e: Event): void {
    e.stopPropagation();
    this.generateBarcodeService
      .generateQRCode(value)
      .then((qrCodeImg) => this.printBarcodeService.print(qrCodeImg));
  }

  printBarcode(value: string, e: Event): void {
    e.stopPropagation();
    this.generateBarcodeService
      .generateBarcode(value)
      .then((barcodeImg) => this.printBarcodeService.print(barcodeImg));
  }

  navigateToCreateLocationPage(): void {
    this.router.navigate([this.routerAppPrefix, 'location']);
  }

  navigateToEditLocationPage(location: Location): void {
    if (this.isSelector) {
      this.selectedLocation = location;
      return;
    }

    this.router.navigate([this.routerAppPrefix, 'location', location.id]);
  }

  private setupSortAndPaginationChangeListeners(): void {
    const sortChange$ = this.sort.sortChange.pipe(
      tap(() => {
        this.paginator.pageIndex = 0;
        this.locationFilters = {
          ...this.locationFilters,
          locationFilterBy: {
            ...this.locationFilters.locationFilterBy,
            page: this.paginator.pageIndex + 1,
          },
        };
      }),
    );
    const pageChange$ = this.paginator.page.pipe(debounceTime(DEFAULT_DEBOUNCE_TIME));

    merge(sortChange$, pageChange$)
      .pipe(
        tap(() => {
          this.applySearch();
        }),
      )
      .subscribe();
  }

  private defineCreateLocationButtonText(): void {
    this.createLocationButtonText = this.userManagementService.isAdmin
      ? 'Create Location'
      : 'Request Location';
  }

  private initializeFilterOptionsAndState(): void {
    forkJoin([this.locationTypeService.getAll(), this.locationRestrictionService.getAll()])
      .pipe(this.takeUntilDestroyed())
      .subscribe(([types, restrictions]) => {
        this.locationRestrictions = restrictions;
        if (!this.locationRestrictions.some((e) => e.name === this.noRestrictionValue.name)) {
          this.locationRestrictions.push(this.noRestrictionValue);
        }
        this.locationTypes = types;
        this.initializeFilterState();
        this.applySearch();
      });
  }

  private applySearch(removeSortingAndPagination = false): void {
    if (!this.isViewInitialized || !this.isEveryFilterOptionsLoaded) {
      return;
    }

    this.locationFilters = {
      ...this.locationFilters,
      locationFilterBy: {
        ...this.locationFilters.locationFilterBy,
        sortColumnName:
          removeSortingAndPagination || this.sort.direction === '' ? 'noSort' : this.sort.active,
        sortDirection: removeSortingAndPagination ? '' : this.sort.direction,
        pageSize: this.paginator.pageSize,
        page: removeSortingAndPagination ? 1 : this.paginator.pageIndex + 1,
      },
    };

    const locationFilters: LocationFilters = {
      ...this.locationFilters,
      locationFilterBy: {
        ...this.locationFilters.locationFilterBy,
        sortDirection: this.locationFilters.locationFilterBy.sortDirection || 'asc',
      },
    };

    this.updateFilterQueryParams();
    this.dataSource.loadLocations(locationFilters);
  }

  private updateFilterQueryParams(): void {
    const queryParams = this.objectDeepnessConverterService.convertToFlatObject({
      ...this.locationFilters,
    });

    this.router.navigate([], { queryParams });
  }

  private initializeFilterState(): void {
    const { queryParams } = this.route.snapshot;

    if (Object.keys(queryParams).length) {
      this.locationFilters = new LocationFiltersImpl(
        this.objectDeepnessConverterService.convertToDeepObject(
          queryParams,
        ) as StringValues<LocationFilters>,
      );
    } else {
      this.locationFilters.locationTypes = [...this.locationTypes];
      this.locationFilters.restrictionTypes = [
        ...this.locationRestrictions.filter((d) => d.name !== this.noRestrictionValue.name),
      ];
    }
  }

  private resetToDefaultAndSetSearchString(searchValue: string): void {
    this.locationFilters = {
      locationFilterBy: {
        filterType: 'byName',
        searchString: '',
        sortColumnName: 'noSort',
        sortDirection: '',
        page: 1,
        pageSize: 10,
      },
      locationTypes: [...this.locationTypes],
      restrictionTypes: [
        ...this.locationRestrictions.filter((d) => d.name !== this.noRestrictionValue.name),
      ],
    };
    this.updateFilterQueryParams();
    this.locationFilters.locationFilterBy.searchString = searchValue;
  }
}

@NgModule({
  declarations: [LookupLocationComponent],
  imports: [
    FontAwesomeModule,
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatCardModule,
    SearchInputModule,
    LocationFilterModule,
    MatLegacyProgressSpinnerModule,
  ],
  providers: [LocationTypeService, LocationRestrictionService, LocationsLookupService],
  exports: [LookupLocationComponent],
})
export class LookupLocationModule {}
