import { Injectable } from '@angular/core';

import { DashboardWidgetOptionsPopupTab } from '../enums/dashboard-widget-options-popup-tab.enum';
import { DashboardWidgetOptionsPopupLists } from '../models/dashboard-widget-options-popup-lists.model';
import { DashboardWidgetOptionsPopupState } from '../models/dashboard-widget-options-popup-state.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardWidgetFilterService {
  getTeamsObject(
    filterState: DashboardWidgetOptionsPopupState,
    filterPopupLists: DashboardWidgetOptionsPopupLists,
  ): { [key: string]: string } | null {
    const teamsObj: { [key: string]: string } | null = {};

    if (
      filterState.activeTab === DashboardWidgetOptionsPopupTab.DATE &&
      filterState.Date.selectedTeamIds.length
    ) {
      filterPopupLists.teamList
        .filter((team) => filterState.Date.selectedTeamIds.includes(team.name))
        .forEach((item, index) => {
          teamsObj[`Teams[${index}].team`] = item.name;
        });

      return teamsObj;
    }

    if (
      filterState.activeTab === DashboardWidgetOptionsPopupTab.TEAM_MEMBER &&
      filterState['Team member'].selectedTeamIds.length
    ) {
      filterPopupLists.teamList
        .filter((team) => filterState['Team member'].selectedTeamIds.includes(team.name))
        .forEach((item, index) => {
          teamsObj[`Teams[${index}].team`] = item.name;
        });

      return teamsObj;
    }

    if (filterState.activeTab === DashboardWidgetOptionsPopupTab.TEAM) {
      return null;
    }

    return teamsObj;
  }

  getSampleTypesObject(
    filterState: DashboardWidgetOptionsPopupState,
    filterPopupLists: DashboardWidgetOptionsPopupLists,
  ): { [key: string]: string } {
    const sampleTypesObj: { [key: string]: string } = {};
    filterPopupLists.sampleTypeList
      .filter(
        (sampleTypes) =>
          !filterState[filterState.activeTab].selectedSampleTypeIds.length ||
          filterState[filterState.activeTab].selectedSampleTypeIds.includes(sampleTypes.id),
      )
      .forEach((item, index) => {
        sampleTypesObj[`SampleTypes[${index}].id`] = item.id;
      });

    return sampleTypesObj;
  }

  getWellsObject(
    filterState: DashboardWidgetOptionsPopupState,
    filterPopupLists: DashboardWidgetOptionsPopupLists,
  ): { [key: string]: string } {
    const wellsObj: { [key: string]: string } = {};
    if (filterState[filterState.activeTab].selectedWellIds.length) {
      filterPopupLists.wellList
        .filter((well) => filterState[filterState.activeTab].selectedWellIds.includes(well.id))
        .forEach((item, index) => {
          wellsObj[`Wells[${index}].id`] = item.id;
        });
    }

    return wellsObj;
  }

  getExperimentTypesObject(
    filterState: DashboardWidgetOptionsPopupState,
    filterPopupLists: DashboardWidgetOptionsPopupLists,
  ): { [key: string]: string } {
    const experimentTypesObj: { [key: string]: string } = {};
    if (filterState[filterState.activeTab].selectedExperimentTypeIds.length) {
      filterPopupLists.experimentTypeList
        .filter((experimentType) =>
          filterState[filterState.activeTab].selectedExperimentTypeIds.includes(experimentType.id),
        )
        .forEach((item, index) => {
          experimentTypesObj[`ExperimentTypes[${index}].id`] = item.id;
        });
    }

    return experimentTypesObj;
  }
}
