export class PlugInformation {
  depth: number;
  depthUnitId: string;
  orientationId: string;
  preservationId: string;

  constructor(plugInformation: PlugInformation) {
    this.depth = plugInformation.depth;
    this.depthUnitId = plugInformation.depthUnitId;
    this.orientationId = plugInformation.orientationId;
    this.preservationId = plugInformation.preservationId;
  }
}
