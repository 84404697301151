import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Pipe({
  name: 'asUntypedFormControl',
})
export class AsUntypedFormControlPipe implements PipeTransform {
  transform(value: AbstractControl<any>): UntypedFormControl {
    return value as UntypedFormControl;
  }
}

@NgModule({
  declarations: [AsUntypedFormControlPipe],
  exports: [AsUntypedFormControlPipe],
  imports: [],
})
export class AsUntypedFormControlModule {}
