import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';
import { IdName } from '@core/models/id-name.model';
import { Well } from '@core/models/well.model';

@Injectable({
  providedIn: 'root',
})
export class WellsService {
  private readonly url = `${CORE_MEMORY_API}/Wells`;

  constructor(private httpClient: HttpClient) {}

  getWells(): Observable<IdName[]> {
    return this.httpClient.get<IdName[]>(this.url);
  }

  getById(id: string): Observable<Well> {
    return this.httpClient.get<Well>(`${this.url}/${id}`);
  }
}
