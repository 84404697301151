import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { addError } from '@core/utils/form/add-error.util';
import { removeError } from '@core/utils/form/remove-error.util';

export const sameValue = 'sameValue';

export function sameValueNotAllowed(fieldNames: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controls = fieldNames.map((fieldName) => control.get(fieldName) as UntypedFormControl);

    const isError = controls.every((c) => c.value === controls[0].value);
    const isErrorApplied = controls.some((c) => !!c.errors?.[sameValue]);

    if (isError && !isErrorApplied) {
      controls.forEach((c) => {
        addError(c, sameValue);
      });
      return null;
    }

    if (!isError && isErrorApplied) {
      controls.forEach((c) => {
        removeError(c, sameValue);
      });
    }

    return null;
  };
}
