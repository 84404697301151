import { Code128Options } from 'jsbarcode';
import * as JsBarcode from 'jsbarcode';
import { QRCodeToDataURLOptions } from 'qrcode';
import * as QRCode from 'qrcode';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GenerateBarcodeService {
  generateBarcode(value: string): Promise<HTMLImageElement> {
    const barcodeImage = new Image();
    const options: Code128Options = {
      format: 'CODE128',
      width: 3,
      height: 100,
    };

    return new Promise<HTMLImageElement>((resolve, _) => {
      barcodeImage.addEventListener(
        'load',
        () => {
          resolve(barcodeImage);
        },
        { once: true },
      );
      JsBarcode(barcodeImage, value, options);
    });
  }

  generateQRCode(value: string): Promise<HTMLImageElement> {
    const qrCodeImage = new Image();
    const options: QRCodeToDataURLOptions = {
      type: 'image/png',
      margin: 4,
      scale: 10,
      errorCorrectionLevel: 'M',
    };

    return QRCode.toDataURL(value, options)
      .then((imageUrl) => {
        return new Promise<HTMLImageElement>((resolve, _) => {
          qrCodeImage.addEventListener(
            'load',
            () => {
              resolve(qrCodeImage);
            },
            { once: true },
          );
          qrCodeImage.src = imageUrl;
        });
      })
      .catch((_) => {
        return Promise.reject(new Error('QR Code generation failed'));
      });
  }
}
