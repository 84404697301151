import { NgIf } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router } from '@angular/router';

import { FOOTER_TEXT } from '@core/constants/consts';

import { ApplicationMenuModule } from '../features/application-menu/application-menu.component';
import { ContentModule } from './content/content.component';
import { SidenavModule } from './sidenav/sidenav.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  readonly footerText = FOOTER_TEXT;
  sidenavOpened = true;

  get isHome(): boolean {
    return this.router.url === '/home';
  }

  constructor(private router: Router) {}

  closeSidenav(): void {
    this.sidenavOpened = false;
  }
}

@NgModule({
  declarations: [LayoutComponent],
  imports: [MatSidenavModule, ContentModule, SidenavModule, NgIf, ApplicationMenuModule],
  exports: [LayoutComponent],
})
export class LayoutModule {}
