import { ExperimentField } from 'src/app/features/work-order/models/experiment-field.model';
import { WorkOrderFormShape } from 'src/app/features/work-order/models/work-order-form-shape.model';

export interface WorkOrder {
  id?: string;
  name: string;
  parentWorkOrderId?: string;
  proponentEmail: string;
  proponentName: string;
  experimentTypeId: string;
  vendorName: string;
  description: string;
  startDate: string;
  expectDate: string;
  vendorContactEmail: string;
  vendorContactName: string;
  vendorContactInfo: string;
  workOrderStatusId?: string; // not represented at mockup yet
  sampleId?: string;
  progress: number | null;
  completionDate: string;
  note: string;
  completed: boolean;
  experimentFields?: ExperimentField[];
}

export class WorkOrderImpl implements WorkOrder {
  id?: string;
  name: string;
  proponentEmail: string;
  proponentName: string;
  experimentTypeId: string;
  vendorName: string;
  description: string;
  startDate: string;
  expectDate: string;
  vendorContactEmail: string;
  vendorContactName: string;
  vendorContactInfo: string;
  sampleId?: string;
  progress: number | null;
  completionDate: string;
  note: string;
  completed: boolean;
  experimentFields?: ExperimentField[];

  constructor(
    expandedForm: WorkOrderFormShape & {
      sampleId: string | null;
      experimentFields: ExperimentField[];
    },
  ) {
    this.id = expandedForm.generalInformation.id || undefined;
    this.name = expandedForm.generalInformation.projectNumber;
    this.proponentEmail = expandedForm.generalInformation.proponentEmail;
    this.proponentName = expandedForm.generalInformation.proponentName;
    this.description = expandedForm.generalInformation.description;
    this.startDate = expandedForm.generalInformation.startDate;
    this.expectDate = expandedForm.generalInformation.expectedDeliveryDate;

    this.progress = expandedForm.workOrderStatus.progress;
    this.completionDate = expandedForm.workOrderStatus.completionDate;
    this.note = expandedForm.workOrderStatus.note;
    this.completed = expandedForm.workOrderStatus.completed;

    this.vendorName = expandedForm.vendorInformation.vendorName;
    this.vendorContactEmail = expandedForm.vendorInformation.vendorContactEmail;
    this.vendorContactName = expandedForm.vendorInformation.vendorContactName;
    this.vendorContactInfo = expandedForm.vendorInformation.vendorContactInfo;

    this.experimentTypeId = expandedForm.experimentDetail.experimentType;

    this.sampleId = expandedForm.sampleId || undefined;
    this.experimentFields = expandedForm.experimentFields || undefined;
  }
}
