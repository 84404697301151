<div class="title" mat-dialog-title>
  <h1>Filter</h1>
  <button
    class="btn btn-secondary btn-small"
    (click)="clearFilters(selectList)"
    id="sample-clear-filters-btn"
  >
    Clear Filters
  </button>
</div>

<div mat-dialog-content>
  <h3>Well Name</h3>
  <div class="filter-well-wrapper">
    <input
      type="text"
      placeholder="Type to filter wells"
      [(ngModel)]="wellSearchTerm"
      (ngModelChange)="onWellSearchChanged($event)"
      id="sample-well-name-filter-input"
    />
    <button
      *ngIf="!!wellSearchTerm"
      (click)="onClearSearch()"
      id="sample-well-name-clear-filter-btn"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-selection-list #selectList [(ngModel)]="dataObject.wells">
    <mat-list-option
      [color]="'primary'"
      [value]="well.id"
      [style.display]="well.isHidden ? 'none' : 'block'"
      *ngFor="let well of wellList"
    >
      {{ well.name }}
    </mat-list-option>
  </mat-selection-list>

  <h3>Depth</h3>
  <div *ngIf="depthFormGroup && lengthUnits.length" class="row depth" [formGroup]="depthFormGroup">
    <app-unit-input
      #minDepthInput
      [formControl]="extractDepthControl(minDepthFieldName)"
      [options]="lengthUnits"
      [type]="'number'"
      [formFieldName]="minDepthFieldName"
      label="Min Depth"
      [max]="nonZeroFieldValueOrNull(maxDepthFieldName)"
      [getErrorMessage]="errorMessageFunctionFor(minDepthFieldName)"
      appSynchronizeDepthUnits
      [config]="{
        primaryControlName: minDepthFieldName,
        secondaryComponents: [maxDepthInput]
      }"
      id="sample-min-depth-input"
    ></app-unit-input>

    <app-unit-input
      #maxDepthInput
      [formControl]="extractDepthControl(maxDepthFieldName)"
      [options]="lengthUnits"
      [type]="'number'"
      [formFieldName]="maxDepthFieldName"
      label="Max Depth"
      [min]="nonZeroFieldValueOrNull(minDepthFieldName)"
      [isHigherValueAsZeroAllowed]="true"
      [getErrorMessage]="errorMessageFunctionFor(maxDepthFieldName)"
      appSynchronizeDepthUnits
      [config]="{
        primaryControlName: maxDepthFieldName,
        secondaryComponents: [minDepthInput]
      }"
      id="sample-max-depth-input"
    ></app-unit-input>
  </div>

  <h3>Sample Type</h3>
  <div
    *ngIf="sampleTypeFormGroup && dataObject.sampleTypes.length"
    [formGroup]="sampleTypeFormGroup"
    class="field-wrapper"
  >
    <div class="row checkboxes">
      <mat-checkbox
        *ngFor="let sampleType of dataObject.sampleTypes; let i = index"
        [formControlName]="sampleType.id"
        [color]="'primary'"
        [id]="'sample-type-checkbox-' + i"
        >{{ sampleType.name | capitalizeString }}
      </mat-checkbox>
    </div>
    <mat-error *ngIf="sampleTypeFormGroup.errors">{{ getSampleTypeErrorMessage() }}</mat-error>
  </div>
  <hr />

  <h3>Access Status</h3>
  <div
    *ngIf="stateNameFormGroup && dataObject.stateName"
    [formGroup]="stateNameFormGroup"
    class="field-wrapper"
  >
    <div class="row checkboxes">
      <mat-checkbox
        *ngFor="let stateName of stateNameOptions; let i = index"
        [formControlName]="stateName.id"
        [color]="'primary'"
        id="sample-access-status-checkbox"
        [id]="'sample-access-status-checkbox-' + i"
      >
        {{ stateName.name }}
      </mat-checkbox>
    </div>
    <mat-error *ngIf="stateNameFormGroup.errors">{{ getAccessStatusErrorMessage() }}</mat-error>
  </div>

  <h3>Availability</h3>
  <div
    *ngIf="sampleAvailabilityFormGroup && dataObject.sampleAvailability"
    [formGroup]="sampleAvailabilityFormGroup"
    class="field-wrapper"
  >
    <div class="row checkboxes availability">
      <mat-checkbox
        *ngFor="let sampleAvailability of sampleAvailabilityOptions; let i = index"
        [formControlName]="sampleAvailability.id"
        [color]="'primary'"
        id="sample-availability-status-checkbox"
        [id]="'sample-availability-status-checkbox-' + i"
      >
        {{ sampleAvailability.name }}
      </mat-checkbox>
    </div>
    <mat-error *ngIf="sampleAvailabilityFormGroup.errors">{{
      getSampleAvailabilityErrorMessage()
    }}</mat-error>
  </div>
</div>

<div class="actions" mat-dialog-actions>
  <button
    class="btn btn-secondary cancel-btn"
    mat-button
    [mat-dialog-close]="undefined"
    id="sample-cancel-filters-btn"
  >
    Cancel
  </button>
  <button
    class="btn btn-primary submit-btn"
    mat-button
    [disabled]="!canApply"
    (click)="apply()"
    cdkFocusInitial
    id="sample-apply-filters-btn"
  >
    Apply
  </button>
</div>
