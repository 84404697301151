<app-sample-table-selector-popup-field
  class="half-width"
  [formControl]="sampleIdControl"
  [label]="'Search physical sample'"
></app-sample-table-selector-popup-field>

<ng-container *ngIf="wellInfoFormGroup">
  <h2 class="section-title">Well Information</h2>

  <app-sample-well-form [formGroup]="wellInfoFormGroup"> </app-sample-well-form>
</ng-container>

<ng-container *ngIf="sampleInfoFormGroup">
  <h2 class="section-title">Sample Information</h2>

  <form
    [formGroup]="sampleInfoFormGroup"
    [ngSwitch]="sampleInfoFormGroup!.get('sampleType')!.getRawValue()"
  >
    <div class="fields-line">
      <mat-form-field appearance="outline">
        <mat-label>Sample name</mat-label>
        <input
          matInput
          formControlName="sampleName"
          id="complex-data-uploader-digital-volume-sample-info-sample-name-input"
        />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Sample type</mat-label>
        <input
          matInput
          formControlName="sampleType"
          id="complex-data-uploader-digital-volume-sample-info-sample-type-input"
        />
      </mat-form-field>
    </div>

    <ng-container *ngSwitchCase="sampleType.PLUG">
      <div class="fields-line">
        <app-unit-input
          [formControl]="sampleInfoFormGroup!.get('depth')! | asUntypedFormControl"
          [label]="'Depth'"
          [formFieldName]="'depth'"
          [options]="depthUnitList"
          [type]="'number'"
          id="complex-data-uploader-digital-volume-sample-plug-info-depth-unit-input"
        ></app-unit-input>

        <mat-form-field>
          <mat-label> Orientation </mat-label>

          <mat-select
            [formControlName]="'orientationId'"
            [compareWith]="optionsCompareFn"
            id="complex-data-uploader-digital-volume-sample-plug-info-orientation-select"
          >
            <mat-option *ngFor="let orientation of sampleOrientationList" [value]="orientation.id">
              {{ orientation.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="sampleType.CORE">
      <div class="fields-line">
        <app-unit-input
          [formControl]="sampleInfoFormGroup!.get('depthTop')! | asUntypedFormControl"
          [label]="'Depth top'"
          [formFieldName]="'depthTop'"
          [options]="depthUnitList"
          [type]="'number'"
          id="complex-data-uploader-digital-volume-sample-core-info-depth-top-unit-input"
        ></app-unit-input>

        <app-unit-input
          [formControl]="sampleInfoFormGroup!.get('depthBottom')! | asUntypedFormControl"
          [label]="'Depth bottom'"
          [formFieldName]="'depthBottom'"
          [options]="depthUnitList"
          [type]="'number'"
          id="complex-data-uploader-digital-volume-sample-core-info-depth-bottom-unit-input"
        ></app-unit-input>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="sampleType.CUTTINGS">
      <div class="fields-line">
        <app-unit-input
          [formControl]="sampleInfoFormGroup!.get('depthTop')! | asUntypedFormControl"
          [label]="'Depth top'"
          [formFieldName]="'depthTop'"
          [options]="depthUnitList"
          [type]="'number'"
          id="complex-data-uploader-digital-volume-sample-cuttings-info-depth-top-unit-input"
        ></app-unit-input>

        <app-unit-input
          [formControl]="sampleInfoFormGroup!.get('depthBottom')! | asUntypedFormControl"
          [label]="'Depth bottom'"
          [formFieldName]="'depthBottom'"
          [options]="depthUnitList"
          [type]="'number'"
          id="complex-data-uploader-digital-volume-sample-cuttings-info-depth-bottom-unit-input"
        ></app-unit-input>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="sampleType.FLUID">
      <div class="fields-line">
        <app-unit-input
          [formControl]="sampleInfoFormGroup!.get('depth')! | asUntypedFormControl"
          [label]="'Depth'"
          [formFieldName]="'depth'"
          [options]="depthUnitList"
          [type]="'number'"
          id="complex-data-uploader-digital-volume-sample-fluid-info-depth-unit-input"
        ></app-unit-input>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="sampleType.UNCATEGORIZED">
      <div class="fields-line">
        <app-unit-input
          [formControl]="sampleInfoFormGroup!.get('depth')! | asUntypedFormControl"
          [label]="'Depth'"
          [formFieldName]="'depth'"
          [options]="depthUnitList"
          [type]="'number'"
          id="complex-data-uploader-digital-volume-sample-uncategorized-info-depth-unit-input"
        ></app-unit-input>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="sampleType.THINSECTION">
      <div class="fields-line">
        <app-unit-input
          [formControl]="sampleInfoFormGroup!.get('depthTop')! | asUntypedFormControl"
          [label]="'Depth top'"
          [formFieldName]="'depthTop'"
          [options]="depthUnitList"
          [type]="'number'"
          id="complex-data-uploader-digital-volume-sample-thin-section-info-depth-top-unit-input"
        ></app-unit-input>

        <app-unit-input
          [formControl]="sampleInfoFormGroup!.get('depthBottom')! | asUntypedFormControl"
          [label]="'Depth bottom'"
          [formFieldName]="'depthBottom'"
          [options]="depthUnitList"
          [type]="'number'"
          id="complex-data-uploader-digital-volume-sample-thin-section-info-depth-bottom-unit-input"
        ></app-unit-input>
      </div>

      <div class="fields-line">
        <mat-form-field>
          <mat-label> Orientation </mat-label>

          <mat-select
            [formControlName]="'orientationId'"
            [compareWith]="optionsCompareFn"
            id="complex-data-uploader-digital-volume-sample-thin-section-info-orientation-select"
          >
            <mat-option *ngFor="let orientation of sampleOrientationList" [value]="orientation.id">
              {{ orientation.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>
  </form>
</ng-container>

<ng-container *ngIf="!!sampleInfoFormGroup && !!wellInfoFormGroup">
  <hr />

  <div class="half-width input-file">
    <mat-form-field appearance="outline" class="input-file__field">
      <mat-label>Digital volume file</mat-label>
      <input matInput [formControl]="digitalVolumeFileControl" />
    </mat-form-field>

    <div class="upload-btn-container input-file__btn">
      <button
        class="btn btn-primary upload-btn"
        type="button"
        appUpload
        [ext]="'.zip'"
        [disabled]="isVerifyBtnSpinnerVisible || isUploadBtnSpinnerVisible"
        (filePicked)="handleDigitalVolumeFileSelection($event)"
      >
        <span class="upload-btn__text">Select file</span>
      </button>
    </div>
  </div>
</ng-container>

<ng-container
  *ngIf="
    digitalVolumeInfoFormArray?.length &&
    activeDigitalVolumeInfoFormGroup &&
    activeDigitalVolumeInfoFormValidationMessage
  "
>
  <h2 class="section-title">Digital Volume Information</h2>

  <div [formGroup]="activeDigitalVolumeInfoFormGroup!">
    <div class="fields-line half-width">
      <mat-form-field appearance="outline">
        <mat-label>Sample filename</mat-label>
        <mat-select
          [formControl]="selectedDigitalVolumeFileFormControl"
          id="complex-data-uploader-digital-volume-information-sample-file-name-input"
        >
          <mat-option *ngFor="let option of digitalVolumeFileList" [value]="option.id">
            {{ option.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="picture-fields-block">
      <div class="picture-column">
        <div class="picture">
          <div
            class="no-image digital-volume-slice-image"
            [style.background-image]="
              pictureUrlDigitalVolume ? 'url(' + pictureUrlDigitalVolume + ')' : 'none'
            "
          >
            <mat-icon *ngIf="!pictureUrlDigitalVolume" class="image-icon">image</mat-icon>
          </div>
        </div>

        <div class="slice-settings-block">
          <span>Section</span>

          <mat-form-field appearance="outline">
            <mat-select
              [disabled]="!isVerified"
              [formControl]="sectionTypeFormControl"
              [compareWith]="compareWith"
              id="complex-data-uploader-digital-volume-information-section-type-input"
            >
              <mat-option
                *ngFor="let option of digitalVolumeInformationSectionTypeList"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-slider
            color="primary"
            [disabled]="!isVerified"
            [step]="10"
            [min]="0"
            [max]="getMaxSliderValue()"
            [formControl]="sectionSizeFormControl"
          >
          </mat-slider>
        </div>

        <div class="fields-line">
          <mat-form-field appearance="outline">
            <mat-label>{{
              digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.VOLUME_TYPE_ID]
            }}</mat-label>
            <mat-select
              [formControlName]="digitalVolumeInfoFormField.VOLUME_TYPE_ID"
              id="complex-data-uploader-digital-volume-information-volume-type-input"
            >
              <mat-option *ngFor="let option of volumeTypeList" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>

            <mat-error
              *ngIf="
                activeDigitalVolumeInfoFormValidationMessage!.getErrorsBy(
                  digitalVolumeInfoFormField.VOLUME_TYPE_ID
                )
              "
              >{{
                activeDigitalVolumeInfoFormValidationMessage!.getMessageFor(
                  digitalVolumeInfoFormField.VOLUME_TYPE_ID
                )
              }}</mat-error
            >
          </mat-form-field>
        </div>

        <div class="fields-line">
          <mat-form-field appearance="outline">
            <mat-label>{{
              digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.DATA_FORMAT_ID]
            }}</mat-label>
            <mat-select
              [formControlName]="digitalVolumeInfoFormField.DATA_FORMAT_ID"
              id="complex-data-uploader-digital-volume-information-data-format-input"
            >
              <mat-option *ngFor="let option of dataFormatList" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>

            <mat-error
              *ngIf="
                activeDigitalVolumeInfoFormValidationMessage!.getErrorsBy(
                  digitalVolumeInfoFormField.DATA_FORMAT_ID
                )
              "
              >{{
                activeDigitalVolumeInfoFormValidationMessage!.getMessageFor(
                  digitalVolumeInfoFormField.DATA_FORMAT_ID
                )
              }}</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <div class="fields">
        <div class="fields-line">
          <mat-form-field appearance="outline">
            <mat-label>{{
              digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.DIGITAL_VOLUME_NAME]
            }}</mat-label>
            <input
              matInput
              [formControlName]="digitalVolumeInfoFormField.DIGITAL_VOLUME_NAME"
              id="complex-data-uploader-digital-volume-information-sample-name-input"
              [required]="isVerified"
            />

            <mat-error
              *ngIf="
                activeDigitalVolumeInfoFormValidationMessage!.getErrorsBy(
                  digitalVolumeInfoFormField.DIGITAL_VOLUME_NAME
                )
              "
              >{{
                activeDigitalVolumeInfoFormValidationMessage!.getMessageFor(
                  digitalVolumeInfoFormField.DIGITAL_VOLUME_NAME
                )
              }}</mat-error
            >
          </mat-form-field>
        </div>

        <div class="fields-line">
          <app-unit-input
            [formControl]="
              activeDigitalVolumeInfoFormGroup!.get(digitalVolumeInfoFormField.DEPTH)!
                | asUntypedFormControl
            "
            [formFieldName]="digitalVolumeInfoFormField.DEPTH"
            [label]="'Depth'"
            [options]="depthUnitList"
            [type]="'number'"
            id="complex-data-uploader-digital-volume-information-depth-input"
          ></app-unit-input>
        </div>

        <div class="fields-line">
          <mat-form-field appearance="outline">
            <mat-label>{{
              digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.SAMPLE_TYPE_ID]
            }}</mat-label>
            <mat-select
              [formControlName]="digitalVolumeInfoFormField.SAMPLE_TYPE_ID"
              id="complex-data-uploader-digital-volume-information-sample-type-input"
            >
              <mat-option *ngFor="let option of sampleTypeList" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="fields-line">
          <mat-form-field appearance="outline">
            <mat-label>{{
              digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.SUB_SAMPLE_NUMBER]
            }}</mat-label>
            <input
              type="number"
              matInput
              [formControlName]="digitalVolumeInfoFormField.SUB_SAMPLE_NUMBER"
              id="complex-data-uploader-digital-volume-information-sub-sample-number-input"
            />

            <mat-error
              *ngIf="
                activeDigitalVolumeInfoFormValidationMessage!.getErrorsBy(
                  digitalVolumeInfoFormField.SUB_SAMPLE_NUMBER
                )
              "
              >{{
                activeDigitalVolumeInfoFormValidationMessage!.getMessageFor(
                  digitalVolumeInfoFormField.SUB_SAMPLE_NUMBER
                )
              }}</mat-error
            >
          </mat-form-field>
        </div>

        <div class="fields-line">
          <mat-form-field appearance="outline">
            <mat-label>{{
              digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.ORIENTATION_ID]
            }}</mat-label>
            <mat-select
              [formControlName]="digitalVolumeInfoFormField.ORIENTATION_ID"
              id="complex-data-uploader-digital-volume-information-orientation-input"
            >
              <mat-option *ngFor="let option of digitalVolumeOrientationList" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="center-offset-line">
          <span>Center offset</span>
          <div class="center-offset">
            <mat-form-field appearance="outline">
              <mat-label>{{
                digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.ORIGIN_LOCATION_X]
              }}</mat-label>
              <input
                matInput
                type="number"
                [formControlName]="digitalVolumeInfoFormField.ORIGIN_LOCATION_X"
                id="thin-section-information-section-centerOffset-x-input"
                [required]="isVerified"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{
                digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.ORIGIN_LOCATION_Y]
              }}</mat-label>
              <input
                matInput
                type="number"
                [formControlName]="digitalVolumeInfoFormField.ORIGIN_LOCATION_Y"
                id="thin-section-information-section-centerOffset-y-input"
                [required]="isVerified"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{
                digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.ORIGIN_LOCATION_Z]
              }}</mat-label>
              <input
                matInput
                type="number"
                [formControlName]="digitalVolumeInfoFormField.ORIGIN_LOCATION_Z"
                id="thin-section-information-section-centerOffset-z-input"
                [required]="isVerified"
              />
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline" class="digital-volume-dimension-unit">
            <mat-select
              [formControlName]="digitalVolumeInfoFormField.ORIGIN_LOCATION_UNIT_ID"
              [compareWith]="compareWith"
              id="complex-data-uploader-digital-volume-information-centerOffset-unit-input"
            >
              <mat-option *ngFor="let option of depthUnitList" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="voxel-size-line">
          <span>Voxel size</span>

          <div class="voxel-size">
            <mat-form-field appearance="outline">
              <mat-label>{{
                digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.VOXEL_SIZE_X]
              }}</mat-label>
              <input
                matInput
                type="number"
                [formControlName]="digitalVolumeInfoFormField.VOXEL_SIZE_X"
                id="thin-section-information-section-voxelSize-x-input"
                [required]="isVerified"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{
                digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.VOXEL_SIZE_Y]
              }}</mat-label>
              <input
                matInput
                type="number"
                [formControlName]="digitalVolumeInfoFormField.VOXEL_SIZE_Y"
                id="thin-section-information-section-voxelSize-y-input"
                [required]="isVerified"
              />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{
                digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.VOXEL_SIZE_Z]
              }}</mat-label>
              <input
                matInput
                type="number"
                [formControlName]="digitalVolumeInfoFormField.VOXEL_SIZE_Z"
                id="thin-section-information-section-voxelSize-z-input"
                [required]="isVerified"
              />
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline" class="digital-volume-dimension-unit">
            <mat-select
              [formControlName]="digitalVolumeInfoFormField.VOXEL_SIZE_UNIT_ID"
              [compareWith]="compareWith"
              id="complex-data-uploader-digital-volume-information-voxel-size-unit-input"
            >
              <mat-option *ngFor="let option of voxelSizeUnitList" [value]="option.id">
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="grid-number-line">
          <span>Grid number</span>
          <div class="grid-number">
            <mat-form-field appearance="outline">
              <mat-label>{{
                digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.GRID_NUMBER_X]
              }}</mat-label>
              <input
                matInput
                type="number"
                [formControlName]="digitalVolumeInfoFormField.GRID_NUMBER_X"
                id="thin-section-information-section-grid-number-x-input"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{
                digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.GRID_NUMBER_Y]
              }}</mat-label>
              <input
                matInput
                type="number"
                [formControlName]="digitalVolumeInfoFormField.GRID_NUMBER_Y"
                id="thin-section-information-section-grid-number-y-input"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{
                digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.GRID_NUMBER_Z]
              }}</mat-label>
              <input
                matInput
                type="number"
                [formControlName]="digitalVolumeInfoFormField.GRID_NUMBER_Z"
                id="thin-section-information-section-grid-number-z-input"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h2 class="section-title">
    {{ digitalVolumeInfoNameToDisplay[digitalVolumeInfoFormField.LABORATORY_INFORMATION] }}
  </h2>

  <div
    [formGroup]="
      activeDigitalVolumeInfoFormGroup!.get(digitalVolumeInfoFormField.LABORATORY_INFORMATION)!
        | asUntypedFormGroup
    "
  >
    <div class="fields-line">
      <mat-form-field appearance="outline">
        <mat-label>{{
          digitalVolumeInfoVendorInfoNameToDisplay[digitalVolumeInfoVendorInfoFormField.VENDOR_NAME]
        }}</mat-label>
        <input
          matInput
          [formControlName]="digitalVolumeInfoVendorInfoFormField.VENDOR_NAME"
          id="complex-data-uploader-digital-volume-vendor-name-input"
          [required]="isVerified"
        />

        <mat-hint align="end"
          >{{
            activeDigitalVolumeInfoFormGroup.get([
              digitalVolumeInfoFormField.LABORATORY_INFORMATION,
              digitalVolumeInfoVendorInfoFormField.VENDOR_NAME
            ])?.value?.length || 0
          }}/32</mat-hint
        >

        <mat-error
          *ngIf="
            activeVendorInfoFormValidationMessage!.getErrorsBy(
              digitalVolumeInfoVendorInfoFormField.VENDOR_NAME
            )
          "
          >{{
            activeVendorInfoFormValidationMessage!.getMessageFor(
              digitalVolumeInfoVendorInfoFormField.VENDOR_NAME
            )
          }}</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{
          digitalVolumeInfoVendorInfoNameToDisplay[
            digitalVolumeInfoVendorInfoFormField.INTERNAL_LAB_FILE_NO
          ]
        }}</mat-label>
        <input
          matInput
          [formControlName]="digitalVolumeInfoVendorInfoFormField.INTERNAL_LAB_FILE_NO"
          id="complex-data-uploader-digital-volume-internal-lab-file-no-input"
        />

        <mat-hint align="end"
          >{{
            activeDigitalVolumeInfoFormGroup.get([
              digitalVolumeInfoFormField.LABORATORY_INFORMATION,
              digitalVolumeInfoVendorInfoFormField.INTERNAL_LAB_FILE_NO
            ])?.value?.length || 0
          }}/100</mat-hint
        >

        <mat-error
          *ngIf="
            activeVendorInfoFormValidationMessage!.getErrorsBy(
              digitalVolumeInfoVendorInfoFormField.INTERNAL_LAB_FILE_NO
            )
          "
          >{{
            activeVendorInfoFormValidationMessage!.getMessageFor(
              digitalVolumeInfoVendorInfoFormField.INTERNAL_LAB_FILE_NO
            )
          }}</mat-error
        >
      </mat-form-field>
    </div>

    <div class="fields-line">
      <mat-form-field appearance="outline">
        <mat-label>{{
          digitalVolumeInfoVendorInfoNameToDisplay[digitalVolumeInfoVendorInfoFormField.REPORT_NO]
        }}</mat-label>
        <input
          matInput
          [formControlName]="digitalVolumeInfoVendorInfoFormField.REPORT_NO"
          id="complex-data-uploader-digital-volume-report-no-input"
        />

        <mat-hint align="end"
          >{{
            activeDigitalVolumeInfoFormGroup.get([
              digitalVolumeInfoFormField.LABORATORY_INFORMATION,
              digitalVolumeInfoVendorInfoFormField.REPORT_NO
            ])?.value?.length || 0
          }}/100</mat-hint
        >

        <mat-error
          *ngIf="
            activeVendorInfoFormValidationMessage!.getErrorsBy(
              digitalVolumeInfoVendorInfoFormField.REPORT_NO
            )
          "
          >{{
            activeVendorInfoFormValidationMessage!.getMessageFor(
              digitalVolumeInfoVendorInfoFormField.REPORT_NO
            )
          }}</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline" class="date-picker">
        <mat-label>{{
          digitalVolumeInfoVendorInfoNameToDisplay[digitalVolumeInfoVendorInfoFormField.REPORT_DATE]
        }}</mat-label>
        <input
          readonly
          matInput
          [formControlName]="digitalVolumeInfoVendorInfoFormField.REPORT_DATE"
          [matDatepicker]="reportDatePicker"
          (click)="reportDatePicker.open()"
          (focus)="reportDatePicker.open()"
          id="complex-data-uploader-digital-volume-report-date-input"
        />
        <div matSuffix class="date-picker-action-buttons">
          <mat-datepicker-toggle [for]="reportDatePicker"></mat-datepicker-toggle>
          <mat-icon
            appStopPropagationOnClick
            appDisabledElement
            (click)="
              activeDigitalVolumeInfoFormGroup!
                .get([
                  digitalVolumeInfoFormField.LABORATORY_INFORMATION,
                  digitalVolumeInfoVendorInfoFormField.REPORT_DATE
                ])!
                .setValue('')
            "
            >close</mat-icon
          >
        </div>
        <mat-datepicker #reportDatePicker>
          <mat-datepicker-actions>
            <button
              class="btn btn-secondary btn-small"
              matDatepickerCancel
              id="digital-volume-vendor-info-report-date-cancel-btn"
            >
              Cancel
            </button>
            <button
              class="btn btn-primary btn-small"
              matDatepickerApply
              id="digital-volume-vendor-info-report-date-apply-btn"
            >
              Apply
            </button>
          </mat-datepicker-actions>
        </mat-datepicker>
      </mat-form-field>
    </div>

    <div class="fields-line half-width">
      <mat-form-field appearance="outline">
        <mat-label>{{
          digitalVolumeInfoVendorInfoNameToDisplay[
            digitalVolumeInfoVendorInfoFormField.DATA_ANALYSIS_QC_BY_ARAMCO
          ]
        }}</mat-label>
        <input
          matInput
          [formControlName]="digitalVolumeInfoVendorInfoFormField.DATA_ANALYSIS_QC_BY_ARAMCO"
          id="complex-data-uploader-digital-volume-data-analyst-qc-by-aramco-input"
        />

        <mat-hint align="end"
          >{{
            activeDigitalVolumeInfoFormGroup.get([
              digitalVolumeInfoFormField.LABORATORY_INFORMATION,
              digitalVolumeInfoVendorInfoFormField.DATA_ANALYSIS_QC_BY_ARAMCO
            ])?.value?.length || 0
          }}/64</mat-hint
        >

        <mat-error
          *ngIf="
            activeVendorInfoFormValidationMessage!.getErrorsBy(
              digitalVolumeInfoVendorInfoFormField.DATA_ANALYSIS_QC_BY_ARAMCO
            )
          "
          >{{
            activeVendorInfoFormValidationMessage!.getMessageFor(
              digitalVolumeInfoVendorInfoFormField.DATA_ANALYSIS_QC_BY_ARAMCO
            )
          }}</mat-error
        >
      </mat-form-field>
    </div>
  </div>
</ng-container>
