import { Table } from '../../../common/table/models/table.model';

export const tableDataMock: Table[] = [
  {
    phaseId: null,
    phaseType: '1',
  },
  {
    phaseId: null,
    phaseType: '2',
  },
];

export const numberOfGpuCpuList = [
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
];
