<h1 mat-dialog-title>Request for access</h1>
<div mat-dialog-content>
  <mat-form-field class="full-width-input" appearance="outline">
    <mat-label>Sample</mat-label>
    <input
      matInput
      disabled
      [(ngModel)]="data.sampleComplexName"
      id="request-access-sample-input"
    />
  </mat-form-field>
  <mat-form-field class="full-width-input" appearance="outline">
    <mat-label>Owner</mat-label>
    <input matInput disabled [(ngModel)]="data.owner" id="request-access-owner-input" />
  </mat-form-field>
  <mat-form-field class="full-width-input full-width-input-last" appearance="outline">
    <mat-label>Enter request description</mat-label>
    <textarea
      matInput
      [(ngModel)]="data.description"
      id="request-access-description-input"
    ></textarea>
    <mat-hint align="end" [class.error]="data.description.length > descriptionLimitLength"
      >{{ data.description.length || 0 }}/{{ descriptionLimitLength }}</mat-hint
    >
  </mat-form-field>
</div>
<div class="actions" mat-dialog-actions>
  <button
    class="btn btn-secondary"
    mat-button
    [mat-dialog-close]="undefined"
    id="request-access-cancel-btn"
  >
    Cancel
  </button>
  <button
    class="btn btn-primary"
    mat-button
    [disabled]="data.description.length > descriptionLimitLength"
    [mat-dialog-close]="data"
    cdkFocusInitial
    id="request-access-send-btn"
  >
    Send
  </button>
</div>
