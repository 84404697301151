<h1 class="title">Account settings</h1>

<div class="account-settings-logical-block">
  <app-account-information-form #accountInformationFormComponent></app-account-information-form>

  <div class="account-settings-logical-block__actions">
    <div class="form-manipulation-buttons">
      <button
        data-ref="reset-account-info-btn"
        class="btn btn-secondary btn-big"
        (click)="
          accountInformationFormComponent.resetAccountInformationForm(
            accountInformationFormComponent.userInfo
          )
        "
        id="account-settings-information-cancel-btn"
      >
        Cancel
      </button>
      <button
        data-ref="save-account-info-btn"
        class="btn btn-primary btn-big"
        (click)="accountInformationFormComponent.updateAccountInformation()"
        id="account-settings-information-save-btn"
        [disabled]="accountInformationFormComponent.accountInformationFormGroup.pristine"
      >
        Save
      </button>
    </div>
  </div>
</div>

<div *ngIf="!isOktaAuthUsed && !hideChangePassword" class="account-settings-logical-block">
  <h1 class="title">Change password</h1>

  <app-change-password-form #changePasswordFormComponent></app-change-password-form>

  <div class="account-settings-logical-block__actions">
    <div class="form-manipulation-buttons">
      <button
        data-ref="reset-change-password-form-btn"
        class="btn btn-secondary btn-big"
        (click)="changePasswordFormComponent.resetChangePasswordForm()"
        id="account-settings-change-password-cancel-btn"
      >
        Cancel
      </button>
      <button
        data-ref="change-password-btn"
        class="btn btn-primary btn-big"
        (click)="changePasswordFormComponent.changePassword()"
        id="account-settings-change-password-save-btn"
      >
        Save
      </button>
    </div>
  </div>
</div>
