import { AbstractControl } from '@angular/forms';

import { isEmpty } from '@core/utils/common/is-empty.util';

export function removeError(control: AbstractControl, errorName: string): void {
  if (!control.errors) {
    return;
  }

  const filteredErrors = Object.entries(control.errors).reduce((acc, [errKey, errValue]) => {
    const isErrorToRemove = errKey === errorName;
    return { ...acc, ...(!isErrorToRemove && { [errKey]: errValue }) };
  }, {});

  const validFilteredErrorsValue = isEmpty(filteredErrors) ? null : filteredErrors;

  control.setErrors(validFilteredErrorsValue);
}
