<h1 matDialogTitle>Search and select sample</h1>

<mat-dialog-content>
  <app-lookup-sample
    [isSelector]="true"
    [allowedSampleTypes]="allowedSampleTypes"
    #appLookupSample
  ></app-lookup-sample>
</mat-dialog-content>

<mat-dialog-actions>
  <span class="selected-sample-info"
    >Selected sample:
    {{ (appLookupSample.selectedSample | sampleDisplayName) || '[no value]' }}</span
  >
  <button
    class="btn btn-secondary btn-big"
    [mat-dialog-close]="null"
    id="sample-table-selector-close-btn"
  >
    Close
  </button>
  <button
    class="btn btn-primary btn-big"
    [mat-dialog-close]="appLookupSample.selectedSample"
    [disabled]="!appLookupSample.selectedSample"
    id="sample-table-selector-apply-btn"
  >
    Select
  </button>
</mat-dialog-actions>
