export enum WorkOrderGeneralInformationFormField {
  ID = 'id',
  PROJECT_NUMBER = 'projectNumber',
  PROPONENT_EMAIL = 'proponentEmail',
  PROPONENT_NAME = 'proponentName',
  DESCRIPTION = 'description',
  START_DATE = 'startDate',
  EXPECTED_DELIVERY_DATE = 'expectedDeliveryDate',
}

export const WORK_ORDER_GENERAL_INFORMATION_NAME_TO_DISPLAY: Record<
  WorkOrderGeneralInformationFormField,
  string
> = {
  [WorkOrderGeneralInformationFormField.PROJECT_NUMBER]: 'Project number',
  [WorkOrderGeneralInformationFormField.ID]: 'Work order ID',
  [WorkOrderGeneralInformationFormField.DESCRIPTION]: 'Description',
  [WorkOrderGeneralInformationFormField.PROPONENT_EMAIL]: 'Proponent Email',
  [WorkOrderGeneralInformationFormField.PROPONENT_NAME]: 'Proponent name',
  [WorkOrderGeneralInformationFormField.START_DATE]: 'Start date',
  [WorkOrderGeneralInformationFormField.EXPECTED_DELIVERY_DATE]: 'Expected delivery date',
};
