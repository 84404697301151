import { LocationPageComponent } from 'src/app/features/location/location-page.component';

export abstract class LocationPageState {
  protected component!: LocationPageComponent;

  setComponent(component: LocationPageComponent): void {
    this.component = component;
  }

  abstract get isActionBtnVisible(): boolean;
  abstract get isActionBtnAndPrintVisible(): boolean;
  abstract get isCancelBtnVisible(): boolean;
  abstract get actionBtnName(): string;
  abstract get title(): string;
  abstract get saveMethodName(): 'create' | 'edit';
  abstract get successfullySavedNotificationMessage(): string;
  abstract get isEditable(): boolean;

  abstract onSuccessSaved(withPrint: boolean): void;
}
