import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';
import { AccountInformation } from '@core/models/account-information.model';
import { UserInfo } from '@core/models/user-info.model';
import { UserTeam } from '@core/models/user-team.model';

import { AdminSettingsUser } from '../../features/admin-settings/models/admin-settings-user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url = `${CORE_MEMORY_API}/Users`;
  constructor(private httpClient: HttpClient) {}

  getUsers(): Observable<Array<AdminSettingsUser>> {
    return this.httpClient.get<Array<AdminSettingsUser>>(this.url);
  }

  getCurrentUserInfo(): Observable<UserInfo> {
    return this.httpClient.get<UserInfo>(`${this.url}/currentUser`);
  }

  updateAccountInformation(userId: string, payload: AccountInformation): Observable<null> {
    return this.httpClient.put<null>(`${this.url}/${userId}`, payload);
  }

  getUserTeams(): Observable<UserTeam[]> {
    return this.httpClient.get<UserTeam[]>(`${this.url}/teams`);
  }
}
