import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CORE_MEMORY_API } from '@core/constants/api.consts';

import { LocationFilters } from '../models/location-filters.model';
import { SearchLocationResult } from '../models/search-location-result.model';

@Injectable()
export class LocationsLookupService {
  private url = `${CORE_MEMORY_API}/Locations/GetFiltered`;

  constructor(private httpClient: HttpClient) {}

  search(filters: LocationFilters): Observable<SearchLocationResult> {
    const parameters = new HttpParams()
      .set('searchString', filters.locationFilterBy.searchString)
      .set('filterType', filters.locationFilterBy.filterType)
      .set('sortColumnName', filters.locationFilterBy.sortColumnName)
      .set('sortDirection', filters.locationFilterBy.sortDirection || 'asc')
      .set('page', filters.locationFilterBy.page)
      .set('pageSize', filters.locationFilterBy.pageSize)
      .appendAll({ Type: filters.locationTypes.map((x) => x.id) })
      .appendAll({ Restriction: filters.restrictionTypes.map((x) => x.id) });

    return this.httpClient.get<SearchLocationResult>(this.url, { params: parameters });
  }
}
