<div class="title-wrapper">
  <button
    mat-icon-button
    class="back-button"
    matTooltip="Back to wells"
    (click)="navigateBack()"
    id="well-back-btn"
  >
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <h1 class="title">{{ action }} Well</h1>
</div>
<form class="form" [formGroup]="wellFormGroup">
  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>{{ wellFormFieldNameToDisplay[wellFormField.NAME] }}</mat-label>
      <input matInput required [formControlName]="wellFormField.NAME" id="well-name-input" />
      <mat-error *ngIf="nameFormControl!.errors">{{ 'Well Name field cannot be empty' }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ wellFormFieldNameToDisplay[wellFormField.FIELD] }}</mat-label>
      <input matInput [formControlName]="wellFormField.FIELD" id="well-field-input" />
    </mat-form-field>
  </div>

  <div class="fields-line">
    <mat-form-field appearance="outline">
      <mat-label>{{ wellFormFieldNameToDisplay[wellFormField.LOCATION] }}</mat-label>
      <input matInput [formControlName]="wellFormField.LOCATION" id="well-location-input" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ wellFormFieldNameToDisplay[wellFormField.RESERVOIR] }}</mat-label>
      <input matInput [formControlName]="wellFormField.RESERVOIR" id="well-reservoir-input" />
    </mat-form-field>
  </div>
</form>

<div class="actions">
  <button class="btn btn-secondary btn-big" (click)="onCancelClicked()" id="well-cancel-btn">
    Cancel
  </button>
  <button
    class="btn btn-primary btn-big"
    [disabled]="
      !nameFormControl.value || (action !== componentAction.Create && this.wellFormGroup.pristine)
    "
    cdkFocusInitial
    (click)="onSubmitClicked()"
    id="well-save-btn"
  >
    {{ action === componentAction.Create ? 'Create' : 'Save' }}
  </button>
</div>
